import { formatDate, formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import * as dayjs_ from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

const dayjs = dayjs_;

@Injectable({
  providedIn: 'root'
})
export class Formatter {
  yearMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  constructor() {
    dayjs.extend(utc);
  }

  formatWithCurrency(amount, currency) {
    if (amount !== null && currency !== null) {
      return `${amount.toLocaleString()} ${currency}`;
    } else if (amount !== null && currency == null) {
      return amount.toLocaleString();
    }
    return '';
  }

  formatTwoDecimalWithCurrency(n: number, currencyCode: string) {
    return `${formatNumber(n, 'en-US', '1.0-2')} ${currencyCode === null || currencyCode === undefined ? '' : currencyCode}`;
  }

  formatTwoDecimalWithPercent(n: number, percentSign: string = '%') {
    return `${formatNumber(n, 'en-US', '1.0-2')}${percentSign}`;
  }

  roundOff(value) {
    value = value || 0;
    return Math.round((value + 0.00001) * 100) / 100;
  }

  roundOffToWhole(value: number) {
    return Math.round(value);
  }

  formatWithCurrencyAndRoundOff(amount, currency, showNAForZero = false) {
    if (!amount && showNAForZero) {
      return 'N/A';
    }
    const formatted = Number(this.roundOff(parseFloat(amount)).toFixed(2));
    const formattedToLocale = formatted.toLocaleString(undefined, { minimumFractionDigits: 2 });
    return this.formatWithCurrency(formattedToLocale, currency);
  }

  formatAndRoundOff(amount) {
    return this.roundOff(parseFloat(amount)).toLocaleString();
  }

  transformToDateFormat(date, format, locale) {
    return formatDate(date, format, locale);
  }

  transformToLocalDateTz(date, format, withTz) {
    return withTz ? this.transformToLocalDate(date, format) + this.getLocalTimeZoneAbbr() : this.transformToLocalDate(date, format);
  }

  transformToLocalDate(date, format) {
    return dayjs(date).isValid()
      ? dayjs
          .utc(date)
          .local()
          .format(format)
      : date;
  }

  getLocalTimeZoneAbbr() {
    const abbr = new Date().toTimeString().match(new RegExp('[A-Z](?!.*[(])', 'g'));
    return abbr ? abbr.join('') : 'Local Time';
  }

  transformToDayAndMonth(date) {
    const month = new Date(date).getMonth();
    return `${this.transformToDateSuffix(date)} ${this.yearMonths[month]}`;
  }

  transformToDateSuffix(date) {
    date = new Date(date);
    const day = date.getDate();
    let suffix = 'th';
    if (day === 1 || day === 21 || day === 31) {
      suffix = 'st';
    } else if (day === 2 || day === 22) {
      suffix = 'nd';
    } else if (day === 3 || day === 23) {
      suffix = 'rd';
    }
    return day + suffix;
  }

  getUtcDate() {
    const date = new Date();
    const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return utcDate;
  }

  getDate(dateTime: Date) {
    const date = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
    return date;
  }
}
