import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
// import { environmentDefault } from '../../../../../common/src/lib/environments/environment';
import { configurationReducer, ConfigurationState } from '@mint-libs/configuration';
import { Filters } from '../filters/filters.model';
import { filtersReducer, FilterState } from '../filters/state/filters.reducer';
import { userProfileReducer, UserProfileState } from '../user-profile/state/user-profile.reducer';
import { MeasureTypeMappingState } from '../measure-type-mapping/models/measure-type-mapping.model';
import { measureTypeMappingReducer } from '../measure-type-mapping/state/measure-type-mapping.reducer';

export interface ContextState {
  userProfile: UserProfileState;
  configuration: ConfigurationState;
  measureTypeMapping: MeasureTypeMappingState;
  filters: FilterState;
}

export const reducers: ActionReducerMap<ContextState> = {
  userProfile: userProfileReducer,
  configuration: configurationReducer,
  filters: filtersReducer,
  measureTypeMapping: measureTypeMappingReducer,
};

export const metaReducers: MetaReducer<ContextState>[] = [];
