import { Component, Input, OnInit, Inject } from '@angular/core';
import { CagrService } from '../cagr.service';
import { DatePipe } from '@angular/common';
import { CagrAttainmentDetails } from 'src/app/shared/models/cagr-account-details.model';
import { SharedService } from '../../shared/shared.service';
import { Formatter } from '@mint-libs/common';
import { DataSource } from '../../shared/models/data-source.model';
import { environment } from '../../../environments/environment';
import { UserProfileService } from 'src/app/core/context/user-profile.service';
import { ENVIRONMENT_CONFIG } from '@mint-libs/common'; // Temporary

@Component({
  selector: 'mint-cagr-seller',
  templateUrl: './cagr-seller.component.html',
  styleUrls: ['./cagr-seller.component.scss']
})
export class CagrSellerComponent implements OnInit {
  @Input() isDemoMode3;
  @Input() cagrParticipation;
  cagrParticipationId: any;
  revMapName: any;
  participationStartYear: any;
  participationEndYear: any;
  isBonusPaymentAvailable: boolean;
  public accounts: any;
  public attainmentDetails: any;
  public attainmentDetailsGrid: any;
  public productsServicesData: any;
  public productlistperPCC: any;
  public pccList: any;
  public totalCloudGrowthGrid: any;
  public totalCloudGrowthChart: any;
  public awardsDataSet: any;
  showAccount360: boolean = environment.showAccount360;
  gridView = false;
  isattainmentDetailsLoaded = false;
  isAccountsLoaded = false;
  isAwardsUnlockedLoaded = false;
  isProductsServicesLoaded = false;
  activeTabId = this.gridView ? 'cagr_grid' : 'cagr_chart';
  multipleAccountData: any;
  isMultipleAccountLoaded = false;
  showNewFeaturesBanner = true;
  isLoaded = false;
  awardsUnlockedCount = 0;
  activeTabIdRevNtarget: any;
  accountsCount = 0;
  totalCloudGrowthId = 0;
  showBonusPayoutInfo: boolean;

  tooltipList = [
    {
      name: 'Dynamics 365',
      text: 'Meet the three year Dynamics 365, and PowerApps and Flow Billed Revenue target'
    },
    {
      name: 'Modern Workplace',
      text: 'Meet the three year MW Cloud Billed Revenue target or the Teams Active Usage target'
    },
    {
      name: 'Total ACR',
      text: 'Meet the three year ACR target'
    }
  ];

  chartGridViewTab = [
    {
      title: ` <span>
      <svg class="view__icon"  width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 9V5.53845H2.2V9H0ZM2.93333 9V2.76923H5.13333V9H2.93333ZM8.8 2.76923H11V9H8.8V2.76923ZM5.86667
      9V0H8.06667V9H5.86667Z"
          fill="#828282"
        />
      </svg>
    </span>
    Chart view`,
      id: 'cagr_chart'
    },
    {
      title: `<span>
      <svg class="view__icon"  width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H11V3.46153H0V0ZM0 9V5.53845H11V9H0Z" fill="#828282" />
      </svg>
    </span>
    Grid view`,
      id: 'cagr_grid'
    }
  ];

  constructor(
    private cagrService: CagrService,
    private datePipe: DatePipe,
    private formatter: Formatter,
    public sharedService: SharedService,
    private userProfileService: UserProfileService,
    @Inject(ENVIRONMENT_CONFIG) envConfig
  ) {
    this.showBonusPayoutInfo = envConfig.showBonusPayoutInfo;
  }

  ngOnInit(): void {
    this.cagrParticipationId = this.cagrParticipation.cagrParticipationId;
    this.revMapName = this.cagrParticipation.revMapName;
    this.participationStartYear = this.cagrParticipation.participationStartYear;
    this.participationEndYear = this.cagrParticipation.participationEndYear;
    this.BindAccount(this.cagrParticipationId, this.revMapName);
    this.GetPccAttainmentDetails(this.cagrParticipationId, this.revMapName);
    this.GetProductsServicesData(this.cagrParticipationId, this.revMapName);
  }

  GetProductsServicesData(cagrParticipationId, RevMapName) {
    this.cagrService.getCagrProductsServicesData(cagrParticipationId, RevMapName).subscribe(results => {
      this.productsServicesData = results;
      this.isProductsServicesLoaded = true;
    });
  }
  BindAccount(cagrParticipationId, RevMapName) {
    this.accounts = null;
    this.cagrService.getCagrAccounts(cagrParticipationId).subscribe((results: any) => {
      this.accounts = results;
      this.isAccountsLoaded = true;
      this.accountsCount = results.length;
      if (results && results.length > 1) {
        this.GetMultipleAccountsData(cagrParticipationId, RevMapName);
      }
    });
  }
  GetMultipleAccountsData(cagrParticipationId, RevMapName) {
    this.cagrService.getCagrMultipleAccountsData(cagrParticipationId, RevMapName).subscribe(results => {
      this.multipleAccountData = results;
      this.isMultipleAccountLoaded = true;
      this.activeTabIdRevNtarget = Array.isArray(this.multipleAccountData) && this.multipleAccountData.length > 0 ? this.multipleAccountData[0].Name : '';
    });
  }
  GetPccAttainmentDetails(cagrParticipationId, RevMapName) {
    this.cagrService.getTotalCloudGrowthId(cagrParticipationId).subscribe(id => {
      this.totalCloudGrowthId = Number(id);
      this.cagrService.getCagrPccAttainmentDetails(cagrParticipationId, RevMapName).subscribe(results => {
        this.BindPccAttainmentDetailsforChart(cagrParticipationId, results);
        this.BindPccAttainmentDetailsforGrid(results);
        this.isattainmentDetailsLoaded = true;
      });
    });
  }
  formatDataSource(elem) {
    elem.SourceLastRefreshTime = this.datePipe.transform(elem.SourceLastRefreshTime, 'MMM d, yyyy h:mm a z');
    elem.SourceNextRefreshTime = this.datePipe.transform(elem.SourceNextRefreshTime, 'MMM d, yyyy h:mm a z');
    return elem;
  }
  BindPccAttainmentDetailsforGrid(attainmentDetails) {
    const arr = new Array<CagrAttainmentDetails>();
    const totalCloudGrowthArr = new Array<CagrAttainmentDetails>();
    let index = 0;
    attainmentDetails.forEach(element => {
      let a = new CagrAttainmentDetails();
      a.pCC = new Array<CagrAttainmentDetails>();
      // need to change ((element.PCC[0].Name !== 'Total Cloud Growth' )) check once we have an identifier for 'Total Cloud Growth'
      if (element.PCC.length === 1 && element.PCC[0].PCCId === this.totalCloudGrowthId) {
        a.isTotalCloudGrowth = true;
        a.actuals = this.formatter.formatAndRoundOff(element.PCC[0].Actuals);
        a.attainment = element.PCC[0].Attainment.toString();
        a.currency = element.PCC[0].Currency;
        a.locked = element.PCC[0].LockStatus;
        a.name = element.PCC[0].Name;
        a.target = this.formatter.formatAndRoundOff(element.PCC[0].Target);
        a.startFiscalYr = element.PCC[0].StartFiscalYear;
        a.endFiscalYr = element.PCC[0].EndFiscalYear;
        a.dataSource = element.PCC[0].DataSource && element.PCC[0].DataSource.DataSource ? this.formatDataSource(element.PCC[0].DataSource.DataSource[0]) : new DataSource();
        a.pCGRowSpan = -1;
        a.pCGname = null;
        a.pCGId = null;
        a.pCC = null;
        a.pCCId = element.PCC[0].PCCId;
        a.pccNameGrid = null;
        a.actualAdjustment = this.formatter.formatAndRoundOff(element.PCC[0].ActualAdjustment);
        a.quotaAdjustment = this.formatter.formatAndRoundOff(element.PCC[0].QuotaAdjustment);
        totalCloudGrowthArr.push(a);
        this.totalCloudGrowthGrid = totalCloudGrowthArr;
      } else if (element.PCC.length === 1) {
        a.isTotalCloudGrowth = false;
        a.actuals = this.formatter.formatAndRoundOff(element.PCC[0].Actuals);
        a.attainment = element.PCC[0].Attainment.toString();
        a.currency = element.PCC[0].Currency;
        a.locked = element.PCC[0].LockStatus;
        a.name = element.PCC[0].Name;
        a.pCCId = element.PCC[0].PCCId;
        a.target = this.formatter.formatAndRoundOff(element.PCC[0].Target);
        a.startFiscalYr = element.PCC[0].StartFiscalYear;
        a.endFiscalYr = element.PCC[0].EndFiscalYear;
        a.dataSource = element.PCC[0].DataSource && element.PCC[0].DataSource.DataSource ? this.formatDataSource(element.PCC[0].DataSource.DataSource[0]) : new DataSource();
        a.pCGRowSpan = -1;
        a.pCGname = null;
        a.pCGId = null;
        a.pCC = null;
        a.pccNameGrid = null;
        a.tooltip = this.tooltipList[index++].text;
        a.actualAdjustment = this.formatter.formatAndRoundOff(element.PCC[0].ActualAdjustment);
        a.quotaAdjustment = this.formatter.formatAndRoundOff(element.PCC[0].QuotaAdjustment);
        arr.push(a);
      } else if (element.PCC.length > 1) {
        a.isTotalCloudGrowth = false;
        a.name = element.PCGName;
        a.pCGId = element.PCGId;
        a.pCGRowSpan = element.PCC.length;
        a.tooltip = this.tooltipList[index++].text;
        element.PCC.forEach(pcc => {
          a.actuals = this.formatter.formatAndRoundOff(pcc.Actuals);
          a.attainment = pcc.Attainment.toString();
          a.currency = pcc.Currency;
          a.locked = pcc.LockStatus;
          a.pccNameGrid = pcc.Name;
          a.pCCId = pcc.PCCId;
          a.target = this.formatter.formatAndRoundOff(pcc.Target);
          a.startFiscalYr = pcc.StartFiscalYear;
          a.endFiscalYr = pcc.EndFiscalYear;
          a.dataSource = pcc.DataSource && pcc.DataSource.DataSource ? this.formatDataSource(pcc.DataSource.DataSource[0]) : new DataSource();
          a.actualAdjustment = this.formatter.formatAndRoundOff(pcc.ActualAdjustment);
          a.quotaAdjustment = this.formatter.formatAndRoundOff(pcc.QuotaAdjustment);
          a.pCC = null;
          arr.push(a);
          a = new CagrAttainmentDetails();
          a.pCGname = null;
          a.pCGId = null;
          a.pCGRowSpan = -1;
        });
      }
    });
    this.attainmentDetailsGrid = arr;
  }
  GetCagrAwardsUnlockedData(cagrParticipationId) {
    this.cagrService.getCagrAwardsUnlockedData(cagrParticipationId).subscribe(results => {
      this.BindAwardsUnlockedData(results);
    });
  }
  BindAwardsUnlockedData(results) {
    if (!this.totalCloudGrowthChart.locked) {
      this.awardsDataSet = [
        { itemNumber: 1, pay_percentage: results.FirstUnlockedAwardPayoutPercentage, pay_amount: '', currency: '', locked: false },
        { itemNumber: 2, pay_percentage: results.SecondUnlockedAwardPayoutPercentage, pay_amount: '', currency: '', locked: false },
        { itemNumber: 3, pay_percentage: results.ThirdUnlockedAwardPayoutPercentage, pay_amount: '', currency: '', locked: false }
      ];
      this.isAwardsUnlockedLoaded = true;
    } else {
      this.awardsDataSet = [
        { itemNumber: 1, pay_percentage: results.FirstUnlockedAwardPayoutPercentage, pay_amount: '', currency: '', locked: this.awardsUnlockedCount-- > 0 ? false : true },
        { itemNumber: 2, pay_percentage: results.SecondUnlockedAwardPayoutPercentage, pay_amount: '', currency: '', locked: this.awardsUnlockedCount-- > 0 ? false : true },
        { itemNumber: 3, pay_percentage: results.ThirdUnlockedAwardPayoutPercentage, pay_amount: '', currency: '', locked: this.awardsUnlockedCount > 0 ? false : true }
      ];
      this.isAwardsUnlockedLoaded = true;
    }
  }

  BindPccAttainmentDetailsforChart(cagrParticipationId, attainmentDetails) {
    const arr = new Array<CagrAttainmentDetails>();
    this.attainmentDetails = null;
    let index = 0;
    attainmentDetails.forEach(element => {
      let b = new CagrAttainmentDetails();
      const a = new CagrAttainmentDetails();
      a.pCC = new Array<CagrAttainmentDetails>();
      // need to change ((element.PCC[0].Name !== 'Total Cloud Growth' )) check once we have an identifier for 'Total Cloud Growth'
      // if (element.PCC.length === 1 && element.PCC[0].PCCId === 2803) { 11726 7316
      if (element.PCC.length === 1 && element.PCC[0].PCCId === this.totalCloudGrowthId) {
        a.isTotalCloudGrowth = true;
        a.actuals = this.formatter.formatAndRoundOff(element.PCC[0].Actuals);
        a.attainment = element.PCC[0].Attainment.toString();
        a.currency = element.PCC[0].Currency;
        a.locked = element.PCC[0].LockStatus;
        a.name = element.PCC[0].Name;
        a.target = this.formatter.formatAndRoundOff(element.PCC[0].Target);
        a.startFiscalYr = element.PCC[0].StartFiscalYear;
        a.endFiscalYr = element.PCC[0].EndFiscalYear;
        a.dataSource = element.PCC[0].DataSource && element.PCC[0].DataSource.DataSource ? this.formatDataSource(element.PCC[0].DataSource.DataSource[0]) : new DataSource();
        a.pCCId = element.PCC[0].PCCId;
        a.pCGname = null;
        a.pCGId = null;
        a.pCC = null;
        a.multiPcc = false;
        a.actualAdjustment = this.formatter.formatAndRoundOff(element.PCC[0].ActualAdjustment);
        a.quotaAdjustment = this.formatter.formatAndRoundOff(element.PCC[0].QuotaAdjustment);
        this.totalCloudGrowthChart = a;
      } else if (element.PCC.length === 1) {
        a.isTotalCloudGrowth = false;
        a.actuals = this.formatter.formatAndRoundOff(element.PCC[0].Actuals);
        a.attainment = element.PCC[0].Attainment.toString();
        a.currency = element.PCC[0].Currency;
        a.locked = element.PCC[0].LockStatus;
        if (!element.PCC[0].LockStatus) {
          this.awardsUnlockedCount++;
        }
        a.name = element.PCC[0].Name;
        a.pCCId = element.PCC[0].PCCId;
        a.target = this.formatter.formatAndRoundOff(element.PCC[0].Target);
        a.startFiscalYr = element.PCC[0].StartFiscalYear;
        a.endFiscalYr = element.PCC[0].EndFiscalYear;
        a.dataSource = element.PCC[0].DataSource && element.PCC[0].DataSource.DataSource ? this.formatDataSource(element.PCC[0].DataSource.DataSource[0]) : new DataSource();
        a.pCGname = null;
        a.pCGId = null;
        a.pCC = null;
        a.multiPcc = false;
        a.actualAdjustment = this.formatter.formatAndRoundOff(element.PCC[0].ActualAdjustment);
        a.quotaAdjustment = this.formatter.formatAndRoundOff(element.PCC[0].QuotaAdjustment);
        a.tooltip = this.tooltipList[index++].text;
        arr.push(a);
      } else if (element.PCC.length > 1) {
        a.isTotalCloudGrowth = false;
        a.pCGname = element.PCGName;
        a.pCGId = element.PCGId;
        a.multiPcc = true;
        a.tooltip = this.tooltipList[index++].text;
        a.locked = true;
        element.PCC.forEach(pcc => {
          if (!pcc.LockStatus && a.locked === true) {
            this.awardsUnlockedCount++;
          }
          if (!pcc.LockStatus) {
            a.locked = false;
          }
          b.actuals = this.formatter.formatAndRoundOff(pcc.Actuals);
          b.attainment = pcc.Attainment;
          b.currency = pcc.Currency;
          b.locked = pcc.LockStatus;
          b.name = pcc.Name;
          b.pCCId = pcc.PCCId;
          b.target = this.formatter.formatAndRoundOff(pcc.Target);
          b.startFiscalYr = pcc.StartFiscalYear;
          b.endFiscalYr = pcc.EndFiscalYear;
          b.dataSource = pcc.DataSource && pcc.DataSource.DataSource ? this.formatDataSource(pcc.DataSource.DataSource[0]) : new DataSource();
          b.actualAdjustment = this.formatter.formatAndRoundOff(pcc.ActualAdjustment);
          b.quotaAdjustment = this.formatter.formatAndRoundOff(pcc.QuotaAdjustment);
          a.pCC.push(b);
          b = new CagrAttainmentDetails();
        });
        arr.push(a);
      }
    });
    this.attainmentDetails = arr;
    this.GetCagrAwardsUnlockedData(cagrParticipationId);
  }
  onTabChange(event) {
    this.gridView = event.nextId === 'cagr_grid' ? true : false;
  }
  onTabChangeRevNtarget(event) {
    this.activeTabIdRevNtarget = event.nextId;
  }

  viewReport() {
    if (this.userProfileService.isRBIPersona()) {
      this.sharedService.ngViewReport(1, 1);
    } else if (this.userProfileService.isPPIPersona() || this.userProfileService.isUBIPersona()) {
      this.sharedService.ngViewReport(0, 0);
    } else {
      this.sharedService.ngViewReport(1, 1);
    }
  }
}
