<div class="myteam__tile">
  <div class="myteam__tile__box">
    <div>
      <svg height="40" viewBox="0 0 41 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M36.1919 36.2812L40.937 41.0474L39.0474 42.937L34.2812 38.1919L29.5151 42.958L27.6255 41.0474L32.3916 36.2812L27.6255 31.5151L29.5151 29.6255L34.2812 34.3916L39.0474 29.6255L40.937 31.5361L36.1919 36.2812ZM3.375 40.3125H24.875V43H0.6875V0H24.0981L35.625 11.5269V26.875H32.9375V13.4375H22.1875V2.6875H3.375V40.3125ZM24.875 10.75H31.0269L24.875 4.59814V10.75Z"
          fill="#00AE56"
        />
      </svg>
    </div>
    <div class="myteam__tile__box__innertext">
      <div class="myteam__tile__box__text">
        {{ countUnSignedPPA }}
      </div>
      <div class="myteam__tile__box__note">
        Participants who have not signed PPA
      </div>
    </div>
  </div>
  <div class="myteam__tile__box">
    <div>
      <svg height="40" viewBox="0 0 41 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M36.1919 36.2812L40.937 41.0474L39.0474 42.937L34.2812 38.1919L29.5151 42.958L27.6255 41.0474L32.3916 36.2812L27.6255 31.5151L29.5151 29.6255L34.2812 34.3916L39.0474 29.6255L40.937 31.5361L36.1919 36.2812ZM3.375 40.3125H24.875V43H0.6875V0H24.0981L35.625 11.5269V26.875H32.9375V13.4375H22.1875V2.6875H3.375V40.3125ZM24.875 10.75H31.0269L24.875 4.59814V10.75Z"
          fill="#00AE56"
        />
      </svg>
    </div>
    <div class="myteam__tile__box__innertext">
      <div class="myteam__tile__box__text">
        {{ countUnSignedQA }}
      </div>
      <div class="myteam__tile__box__note">
        Participants who have not signed QA/TA
      </div>
    </div>
  </div>
  <div class="myteam__tile__box">
    <div>
      <svg height="40" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M33.5714 10.0714H47V23.5H43.6429V15.8153L28.5357 30.8962L21.8214 24.1819L3.35714 42.6723V43.6429H47V47H0V0H3.35714V37.8991L21.8214 19.461L28.5357 26.1752L41.2563 13.4286H33.5714V10.0714Z"
          fill="#00AE56"
        />
      </svg>
    </div>
    <div class="myteam__tile__box__innertext">
      <div class="myteam__tile__box__text">
        {{ countUtilizationLt }}
      </div>
      <div class="myteam__tile__box__note">
        Participants with utilization &lt; 100%
      </div>
    </div>
  </div>
</div>
