import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { Inject } from '@angular/core';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { UserProfileService } from 'src/app/core/context/user-profile.service';
import { Observable, from } from 'rxjs';
import { Code } from 'src/app/app.constants';
import { MessagesService } from '@mint-libs/context';
import { FiltersService } from '../../filters/filters.service';
import { WidgetFactory } from '../../shared/widgets/widget.factory';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-incentive-summary-tiles-old',
  templateUrl: './incentive-summary-tiles.component.html',
  styleUrls: ['./incentive-summary-tiles.component.scss']
})
export class IncentiveSummaryTilesComponent_Old implements OnInit {
  isPayoutUnavailable: boolean;
  payoutUnavailableMessage = <any>{};
  @Input() tabId: number;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private sharedService: SharedService,
    private widgetFactory: WidgetFactory,
    public userProfileService: UserProfileService,
    private messageService: MessagesService,
    private filterService: FiltersService,
    public code: Code,
    public sharedContextState: Store<SharedContext>
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.isPayoutUnavailable = this.filterService.isPayoutUnavailable();
  }
  detailSummaryTabs = [
    { index: 0, name: 'Payouts' }
    // { index: 1, name: 'Quota Changes' }
    // { index: 2, name: 'Revenue Recognition' }
  ];

  selectedTab = 0; // This value will come from the DB as a user preference

  ngOnInit() {
    this.checkPayoutIsAvailable();
  }

  onTabChange(index) {
    this.selectedTab = index;
  }

  viewReport() {
    this.sharedService.ngViewReport(this.tabId, this.tabId);
  }

  checkPayoutIsAvailable() {
    if (this.isPayoutUnavailable) {
      this.messageService.getFullMessage(Code.PayoutUnavailibiltyMessage).subscribe(response => {
        this.payoutUnavailableMessage['Title'] = response[0]['title'];
        this.payoutUnavailableMessage['Text'] = response[0]['text'];
      });
    }
  }
}
