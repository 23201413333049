import { Formatter } from '@mint-libs/common';
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-attainment-renderer',
  styles: [
    `
      .threshold-info {
        font-size: 11px;
        color: #707070;
        line-height: 9px;
      }
    `
  ],
  template: `
    <span style="display: block;">
      <span style="font-size: 24px;"> {{ params.data.attainmentStr() }}</span> <br />
    </span>
  `
})
export class BonusAttainmentRendererComponent implements ICellRendererAngularComp {
  params: any;
  thresholdInfo: any;
  public shouldShowThresholdInfo = false;
  public data: number;
  constructor(private formatter: Formatter) {}
  agInit(params): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
