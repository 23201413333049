import { Component } from '@angular/core';

@Component({
    selector: 'app-demo-notification',
    templateUrl: './demoNotification.component.html'
})

export class DemoNotificationComponent {

}
