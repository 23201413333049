import { Injectable } from '@angular/core';
import { LoggerService } from '@mint-libs/logging';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { EndpointService } from '@mint-libs/network';

import { environment } from '../../../environments/environment';
import { DataService } from '../network/data.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  sharedContext: SharedContext = new SharedContext();

  constructor(private loggingService: LoggerService, private dataService: DataService, private endpoint: EndpointService, public sharedContextState: Store<SharedContext>) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  public authorize() {
    console.log('Authorizing');

    const promise = this.userProfileAuthorization('token').toPromise();
    promise.then(
      result => {
        this.logger(result, false);
        return result;
      },
      response => {
        this.logger(response, true);
        this.loggingService.trackError(response);
      }
    );
    return promise;
  }
  public logger(result, IsError) {
    var log: any = {
      uri: this.endpoint.AuthorizeParticipant.uri,
      appVersion: this.sharedContext.appVersion,
      ErrorInFunction: 'userProfileAuthorization',
      FileName: 'authorization.service.ts'
    };
    if (!IsError) {
      if (!result.IsAuthorized) {
        log.Status = 'Ok';
        log.SelectedTitleStartDate = this.sharedContext.appliedFilter.standardTitle ? this.sharedContext.appliedFilter.standardTitle.StartDate : null;
        log.SelectedTitleEndDate = this.sharedContext.appliedFilter.standardTitle ? this.sharedContext.appliedFilter.standardTitle.EndDate : null;
        log.ErrorCause = result.ErrorCause;
        log.IsAuthorized = result.IsAuthorized;
        log.MissingBusinesses = result.MissingBusinesses;
        log.MissingSubsidiaries = result.MissingSubsidiaries;
      }
    } else {
      log.data = result.data;
      log.status = result.status;
      log.statusText = result.statusText;
    }
    this.loggingService.trackError(log);
  }

  public authorizeAndGetParticipant() {
    var endpoint = this.endpoint.AuthorizeAndGetParticipant;
    endpoint.params.token = 'token';

    const promise = this.dataService.getWithGlobalFilters(environment.mintapiBaseUrl + endpoint.uri, endpoint.params).toPromise();

    promise.catch(function(error) {
      console.error(error);
      this.loggingService.trackError(error);
    });

    return promise;
  }

  public userProfileAuthorization(token) {
    var endpoint = this.endpoint.AuthorizeParticipant;
    endpoint.params.token = token;
    endpoint.params.appVersion = this.sharedContext.appVersion;
    endpoint.params.SelectedTitleStartDate = this.sharedContext.appliedFilter.standardTitle ? this.sharedContext.appliedFilter.standardTitle.StartDate : null;
    endpoint.params.SelectedTitleEndDate = this.sharedContext.appliedFilter.standardTitle ? this.sharedContext.appliedFilter.standardTitle.EndDate : null;

    return this.dataService.get(environment.mintapiBaseUrl + endpoint.uri, endpoint.params);
  }

  public authorizeImpersonation(userAlias, token, participationid, personnelNumber, fiscalYear) {
    console.log('Authorizing impersonation');

    var endpoint = this.endpoint.AuthorizeImpersonation;
    endpoint.params.alias = userAlias;
    endpoint.params.token = token;
    endpoint.params.ParticipationID = participationid;
    endpoint.params.PersonnelNumber = personnelNumber;
    endpoint.params.FiscalYear = fiscalYear;

    // (endpoint.params.ParticipationID && endpoint.params.PersonnelNumber) logic added to pick employee details instead of managers during impersanation
    if (this.sharedContext.loggedInUserInfo.isFCA || this.sharedContext.loggedInUserInfo.isSupportAdmin || this.sharedContext.loggedInUserInfo.isSuperAdmin) {
      return this.dataService.get(endpoint.uri, endpoint.params);
    } else if (endpoint.params.ParticipationID && endpoint.params.PersonnelNumber) {
      return this.dataService.getWithGlobalFilters(endpoint.uri, endpoint.params);
    } else {
      return this.dataService.get(endpoint.uri, endpoint.params);
    }
  }
}
