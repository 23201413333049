<div id="account-summary_1" class="tab__content__header">
  <div id="account-summary_2" *ngIf="!isADXResponsse && loaded" class="tab__content__subhead">
    Type of Account
  </div>
  <mint-detail-dropdown
    id="account-summary_3"
    class="dropdown__cmp"
    [itemCaption]="itemCaption"
    [subItemCaption]="subItemCaption"
    [items]="dropDownItems"
    [selectedItem]="selectedItem"
    [selectedSubItem]="selectedSubItem"
    (applyFilter)="onApplyFilter($event)"
  >
  </mint-detail-dropdown>
</div>

<div id="account-summary_12" *ngIf="isADXResponsse" class="tab__content__subhead">
  Disclaimer : This is a summary view providing either the top 100 performing accounts or top 10 performing territories that you are assigned to.
</div>
<div *ngIf="IsDynamicsDisclaimerVisible" class="tab__content__subhead">
  <ng-container>
    Disclaimer : Your custom Committed Growth Bucket Detail Reports are now available in Mint
    <a
      id="account-summary_12_dynamics_redirection"
      href="javascript:void(0)"
      (keyup.enter)="navigateToDynamicsAccountReport()"
      (click)="navigateToDynamicsAccountReport()"
      analytics-tracker
      [tracker]="'Dynamics_redirection_link'"
      [type]="'Click'"
      [element]="'Hyperlink'"
      [source]="'Account_summary_tab'"
    >
      here.</a
    >
  </ng-container>
</div>
<mint-metric-stack-chart
  id="account-summary_4"
  [dataSet]="graphData"
  class="stack__chart"
  *ngIf="loaded"
  [chartHelpText]="chartHelpText"
  [containerId]="'account_stack_chart'"
></mint-metric-stack-chart>
<div id="account-summary_12" *ngIf="!loaded" class="content__loader content__loader-chart">
  <mint-content-loader-bar-chart id="account-summary_13"></mint-content-loader-bar-chart>
</div>
<div id="account-summary_5" class="legend" *ngIf="showLegends">
  <div id="account-summary_6" class="legend__item">
    <div id="account-summary_7" class="legend__item__clr"></div>
    <div id="account-summary_8" class="legend__item__text">Revenue from Non-Customer Adds Accounts</div>
  </div>
  <div id="account-summary_9" class="legend__item">
    <div id="account-summary_10" class="legend__item__clr"></div>
    <div id="account-summary_11" class="legend__item__text">Revenue from Customer Adds Accounts</div>
  </div>
</div>
