<div *ngIf="isOptOutEligibleFlag && (isRBI || isUBI )&& optoutDemoMode()">
  <div *ngIf="isBanner" id="optout-notification_1" class="notification-optout warning" role="alert">
    <div class="row" id="optoutbannernotification">
      <div id="optout-notification_2">
        <p id="optout-notification_3" aria-live="polite">
          <span id="optout-notification_4" class="sr-only">Warning message</span> <span id="optout-notification_5"
            aria-hidden="true" class="icon icon-notification" title="Warning"></span>
          <strong id="optout-notification_6" *ngIf="topBannerMessage1 && topBannerMessage2">
            {{ topBannerMessage1 }} <a id="optout-notification_7" href="javascript:void(0);"
              (click)="navigateToOptout()" (keyup.enter)="navigateToOptout()" style="cursor: pointer">here</a>
            {{ topBannerMessage2 }}
          </strong>
          <button id="optout-notification_8" title="close warning message" class="btn-clear pull-right"
            (click)="closeOptOutBanner()" (keyup.enter)="closeOptOutBanner()" style="margin-right: -37px;">
            <em id="optout-notification_9" class="icon icon-close"></em>
          </button>
        </p>
      </div>
    </div>
  </div>


  <div *ngIf="!isBanner" class="optout-msg-comp ng-scope" id="optOut_message_div">
    <p aria-live="polite" id="optOut_message_p">
      {{ topBannerMessage1 }} <a id="ptOut-notification_a" href="javascript:void(0);" (click)="navigateToOptout()"
        (keyup.enter)="navigateToOptout()" style="cursor: pointer">here</a>
      {{ topBannerMessage2 }}
    </p>
  </div>
</div>