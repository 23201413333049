<div class="search-filter-container" (clickOutside)="closeSuggestionDropdown()">
  <input
    role="search"
    aria-label="Search territory"
    placeholder="Enter 2 or more characters to search"
    aria-controls="suggestion_dropdown"
    aria-haspopup="true"
    type="text"
    class="search-box"
    [ngModel]="searchText"
    (ngModelChange)="onInput($event)"
    (focus)="checkForDropdownVisibility()"
    (blur)="checkForDropdownVisibility()"
    analytics-tracker
    [tracker]="'DLTR_' + reportType + '_InputFilter'"
    [type]="'Search'"
    [element]="'Input'"
    [source]="'DetailReport' + reportType"
    [value]="searchText"
  />
  <mat-icon class="search-icon">search</mat-icon>
  <span class="sr-only" role="status"> {{ isSearching ? 'Searching' : '' }} </span>
  <span class="sr-only" role="status"> {{ !isSearching ? searchSuggestion.length + ' results found.' : '' }}</span>
  <div class="dropdown-container" *ngIf="isSuggestionDropdownVisible">
    <ul class="suggestion-container" role="listbox" id="suggestion_dropdown">
      <li
        class="dropdown-items"
        role="option"
        [ngClass]="{ selected: checkIfSelected(suggestion) }"
        *ngFor="let suggestion of searchSuggestion | slice: 0:maxDisplaySuggestion; let i = index"
        (click)="addFilter(suggestion)"
        (keyup.enter)="addFilter(suggestion)"
        tabindex="0"
        [attr.aria-selected]="checkIfSelected(suggestion)"
        analytics-tracker
        [tracker]="'DLTR_' + reportType + '_FilterSelection'"
        [type]="'Click'"
        [element]="'FilterItem'"
        [source]="'DetailReport' + reportType"
        [value]="suggestion"
      >
        {{ suggestion }}
      </li>
    </ul>
  </div>
</div>

<div class="filter-tiles-container" *ngIf="selectedfilters?.length && isFilterTabActive">
  <div class="filter-tiles" *ngFor="let filter of selectedfilters | slice: 0:maxDisplayFilter; let i = index">
    <div class="filter-tile">
      <div>{{ filter }}&nbsp;</div>
      <div
        class="close-filter-tile"
        [attr.aria-label]="filter + ' tile close'"
        tabindex="0"
        role="button"
        (click)="removeFilter(filter)"
        analytics-tracker
        [tracker]="'DLTR_' + reportType + '_FilterRemoval'"
        [type]="'Click'"
        [element]="'FilterItem'"
        [source]="'DetailReport' + reportType"
        [value]="filter"
      >
        X
      </div>
    </div>
  </div>
  <div *ngIf="selectedfilters?.length > maxDisplayFilter" class="filter-tiles" (clickOutside)="closeAdditionalFilter()">
    <div class="filter-tile">
      <span
        role="button"
        tabindex="0"
        aria-label="Show Additional filters"
        (click)="toggleAdditionalFilter()"
        analytics-tracker
        [tracker]="'DLTR_' + reportType + '_AdditonalFilter'"
        [type]="'Click'"
        [element]="'AdditionalFilterItem'"
        [source]="'DetailReport' + reportType"
        [value]="'AdditonalFilter'"
        >...</span
      >
    </div>
    <div *ngIf="isAdditionalFilterVisible" class="additional-filter-container">
      <div class="additional-filter-tiles">
        <div *ngFor="let filter of selectedfilters | slice: maxDisplayFilter; let i = index">
          <div class="additional-filter-tile">
            <div class="additional-filter-tile-title">{{ filter }}&nbsp;</div>
            <div
              class="close-additional-filter-tile"
              [attr.aria-label]="filter + ' tile close'"
              tabindex="0"
              role="button"
              (click)="removeFilter(filter)"
              analytics-tracker
              [tracker]="'DLTR_' + reportType + '_AdditonalFilterRemoval'"
              [type]="'Click'"
              [element]="'AdditionalFilterItem'"
              [source]="'DetailReport' + reportType"
              [value]="filter"
            >
              X
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="maxFilterSelectionExceeded" class="alert-text">
  You have exceeded the number of filters permitted. To add an additional filter, please delete an existing territory that is currently selected.
</div>
