import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpersonationComponent } from './impersonation/impersonation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ImpersonationAvatarComponent } from './impersonation-avatar/impersonation-avatar.component';
import { LocalStorageService } from '@mint-libs/network';
import { AvatarModule } from 'ngx-avatar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { allParticipantsFeatureKey, allParticipantsReducer, AllParticipantsEffects } from '@mint-libs/context';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { PlanActivationComponent } from './plan-activation/plan-activation.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PlanActivationService } from './plan-activation.service';
import { AdminRoutingModule } from './admin-routing.module';



@NgModule({
  declarations: [PlanActivationComponent, ImpersonationComponent, ImpersonationAvatarComponent],
  imports: [
    GridModule,
    InputsModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    SlickCarouselModule,
    NgxTypeaheadModule,
    StoreModule.forFeature(allParticipantsFeatureKey, allParticipantsReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([AllParticipantsEffects]),
    AdminRoutingModule
  ],
  providers: [LocalStorageService, PlanActivationService]
})
export class AdminModule { }
