import { Component, OnInit, Input } from '@angular/core';
import { PayoutReportService } from '../../../payout-report.service';

@Component({
  selector: 'mint-payout-report-bucket-attainment-tile',
  templateUrl: './bucket-attainment.component.html',
  styleUrls: ['./bucket-attainment.component.scss']
})
export class BucketAttainmentComponent implements OnInit {
  hasRecurringTarget: boolean;
  actualsLabel: string;
  actualsValue: number;
  targetValue: number;
  targetLabel: string;
  @Input() selectedCoreBucket: any;

  constructor(public payoutReportService: PayoutReportService) {}

  ngOnInit() {}

  ngOnChanges() {
    this.actualsLabel = 'Bucket Actuals';
    this.targetLabel = 'Bucket Target';
    this.actualsValue = this.payoutReportService.formatCurrency(this.selectedCoreBucket.Metrics.Actuals);
    this.targetValue = this.payoutReportService.formatCurrency(this.selectedCoreBucket.Metrics.Target);

    if (this.selectedCoreBucket.IsDynamicMetric) {
      this.actualsLabel = 'Total Value of Commitments Landed';
      this.targetLabel = 'Commitments Quota';
      this.actualsValue = this.payoutReportService.formatCurrency(this.selectedCoreBucket.Metrics.Actuals - this.selectedCoreBucket.Metrics.TargetRecurring);
      return;
    }
    if (this.selectedCoreBucket.Metrics.TargetRecurring > 0) {
      this.hasRecurringTarget = true;
      this.actualsLabel = 'Bucket Actuals - Bucket Recurring Target';
      this.targetLabel = 'Bucket Target - Bucket Recurring Target';
      this.actualsValue = this.payoutReportService.formatCurrency(this.selectedCoreBucket.Metrics.Actuals - this.selectedCoreBucket.Metrics.TargetRecurring);
      this.targetValue = this.payoutReportService.formatCurrency(this.selectedCoreBucket.Metrics.Target - this.selectedCoreBucket.Metrics.TargetRecurring);
    }
  }
}
