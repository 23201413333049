import { MetricGridYtdService } from './metric-grid-ytd.service';
import { DataSourceData } from './data-source';
import { Injectable } from '@angular/core';
import { ProductGroup } from './product-group';
import { forkJoin, BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';
import { DatePipe } from '@angular/common';
import { filter } from 'rxjs/operators';
import { MetricGridData } from './metric-grid-data';
import { MetricGridService } from './metric-grid.service';
import { PCGMetricAPIResponse } from '../models/pcg-metric-response';
import { Formatter } from '@mint-libs/common';
import { LegendService } from '../legends/legend.service';
import { Metric } from '../models/metric.model';
import { MetricPCG } from '../models/metric-pcg.model';
import { BucketParams } from '../models/bucket-params.model';
import { UserProfileService } from '../../core/context/user-profile.service';

@Injectable()
export class MetricGridDataHelperService {
  private noData: boolean;
  private errorOccured: boolean;
  public attainmentDataSet;
  public pcgMetricAPIResponse: PCGMetricAPIResponse = {};
  private pcgRowSpan: number;

  private apiResponseSource = new BehaviorSubject<PCGMetricAPIResponse>(this.pcgMetricAPIResponse);
  private apiResponseMessage = this.apiResponseSource.asObservable();
  pcgMetrics: Array<{ pcg: MetricPCG }> = [];

  constructor(
    private metricGridService: MetricGridService,
    private datePipe: DatePipe,
    private formatter: Formatter,
    private legendService: LegendService,
    private metricGridYtdService: MetricGridYtdService,
    public userProfileService: UserProfileService
  ) {}

  getMetricTileData(fromFreezeSnapshot: boolean = false, useNewPayoutAPI: boolean = false, isOptOut: boolean = false) {
    return forkJoin(this.metricGridService.getAllPaylines(), this.metricGridService.getPayoutSummary(fromFreezeSnapshot, useNewPayoutAPI, isOptOut), this.metricGridService.getTargetSummary());
  }

  getMetricGridData(bucketParams: BucketParams = null) {
    return forkJoin(
      this.metricGridService.getTargetSummary(bucketParams),
      this.metricGridService.getAllPaylines(bucketParams),
      this.metricGridService.getRefreshTimesOfDataSources(),
      this.metricGridService.getPayoutSummary()
    );
  }

  getPerformanceData(bucketParams: BucketParams = null) {
    return forkJoin(this.metricGridService.getTargetSummary(bucketParams), this.metricGridService.getAllPaylines(bucketParams), this.metricGridService.getRefreshTimesOfDataSources());
  }

  getPPIMetricGridData(bucketType: string = null) {
    const bucketParams = new BucketParams();
    bucketParams.bucketType = bucketType;
    return forkJoin(this.metricGridService.getTargetSummary(bucketParams), this.metricGridService.getRefreshTimesOfDataSources());
  }

  getMetricGridYtdData(selectedMonth) {
    return forkJoin(this.metricGridYtdService.getTargetSummary(selectedMonth), this.metricGridYtdService.getAllPaylines(), this.metricGridService.getRefreshTimesOfDataSources());
  }

  buildPcgData(paylinesSummaryResult, isNeutral: boolean, caDetails = null) {
    const payLines = paylinesSummaryResult.Paylines;

    const allPrimaryPaylines = this.checkAllPrimaryPaylines(payLines);

    return _.map(
      payLines,
      function(payline) {
        const attainment = caDetails ? ((caDetails.ActualHours / caDetails.TargetHours) * 100).toFixed(2) : payline.Attainment.toFixed(2);
        let Pcgname = '';
        if (allPrimaryPaylines === true) {
          Pcgname = payline.PcgName;
        } else if (payline.IsPrimary) {
          Pcgname = payline.PcgName + '(Primary)';
        } else {
          Pcgname = payline.PcgName;
        }

        return this.buildMetric(payline, attainment, isNeutral, caDetails);
      }.bind(this)
    );
  }

  buildMetric(payline, attainment, isNeutral: boolean, caDetails = null) {
    const metric = new Metric();
    metric.isNeutral = isNeutral;
    metric.name = payline.PcgName;
    metric.attainment = attainment;
    metric.threshold = payline.Threshold;
    metric.target = payline.Target;
    metric.excellence = payline.Excellence;
    // Adding weight for OCP PPM metric for grid view on dashboard screen
    metric.weight = payline.Weightage;
    metric.attainmentColor = caDetails ? '#000000' : this.legendService.getColor(metric);
    metric.incentiveType = payline.IncentiveType;
    return metric;
  }

  checkAllPrimaryPaylines(paylines) {
    const areAllPaylinesPrimary = _.every(paylines, function(payline) {
      return payline.IsPrimary === true;
    });
    return areAllPaylinesPrimary;
  }

  buildPccData(planComponentGroups, pcgData, refreshTimeSummary, caDetails = null) {
    const rowData = new Array<MetricGridData>();
    const pccData = [];
    const pcgGroupswithTALOA = planComponentGroups;
    const pcgGroups = planComponentGroups.filter(PCG => PCG.PeriodStatus === 'Active');
    _.each(pcgGroupswithTALOA, function(pcg) {
      const currentPcg = pcg.Name;
      if (pcg.PeriodStatus === 'TA' || pcg.PeriodStatus === 'LOA') {
        if (pcgGroups.filter(PCG => PCG.Name === currentPcg).length === 0) {
          pcgGroups.push(pcg);
        }
      }
    });
    let pcgDataItems: Array<MetricGridData>;

    _.each(
      pcgGroups,
      function(pcg) {
        if (pcg.PlanComponentCombinations) {
          const isCurrentPcgAlreadyAdded = false;
          pcgDataItems = new Array<MetricGridData>();
          this.pcgRowSpan = 0;

          pcg.PlanComponentCombinations.sort(function(a, b) {
            return b.Weightage - a.Weightage;
          });

          const currentPcgItem = this.getCurrentPcgItem(pcg, pcgData, pcgGroupswithTALOA);
          const pccDataRow = [];
          _.each(pcg.PlanComponentCombinations, function(pcc) {
            pccDataRow.push(pcc);
          });

          this.pcgRowSpan += pccDataRow.length;

          this.processPlanComponentCombinations(pcg, pccData, pcgDataItems, refreshTimeSummary, isCurrentPcgAlreadyAdded, currentPcgItem, caDetails);
          // pcgDataItems[0].PcgRowSpan = this.pcgRowSpan;
          // Set pcg level row span
          pcgDataItems.forEach((item: MetricGridData, index: number) => {
            if (index === 0) {
              item.PcgRowSpan = this.pcgRowSpan;
            } else {
              item.PcgRowSpan = -1;
            }
          });
        }

        _.each(pcgDataItems, function(pcgDataItem: MetricGridData) {
          rowData.push(pcgDataItem);
        });
      }.bind(this)
    );

    return rowData;
  }

  getCurrentPcgItem(pcg, pcgData, pcgGroupswithTALOA) {
    let currentPcgItem = new Metric();

    _.each(pcgData, function(pcgDataItem: Metric) {
      if (pcgDataItem.name === pcg.Name) {
        pcgDataItem.weight = pcg.Weightage * 100;
        currentPcgItem = pcgDataItem;
      }
    });

    const currentPCGs = pcgGroupswithTALOA.filter(pcg => pcg.Name === currentPcgItem.name);
    currentPcgItem.totalEarning = 0;
    currentPCGs.forEach(function(pcg: any) {
      currentPcgItem.totalEarning = currentPcgItem.totalEarning + pcg.Metrics.Earnings;
    });

    return currentPcgItem;
  }

  processPlanComponentCombinations(pcg, pccData, pcgDataItems, refreshTimeSummary, isCurrentPcgAlreadyAdded, currentPcgItem, caDetails = null) {
    _.each(
      pcg.PlanComponentCombinations,
      function(pcc) {
        const isCurrentPccAlreadyAdded = false;
        pccData.push(pcc);
        const productGroup = this.buildProductGroup(pcc);
        this.processRefreshTimeSummary(pcgDataItems, refreshTimeSummary, pcg, pcc, isCurrentPcgAlreadyAdded, currentPcgItem, isCurrentPccAlreadyAdded, productGroup, caDetails);
      }.bind(this)
    );
  }

  buildProductGroup(pcc) {
    const productGroup = new ProductGroup();
    productGroup.Name = pcc.Name;
    productGroup.Attainment = pcc.Metrics.Attainment;
    productGroup.Weight = (pcc.Weightage * 100).toString();
    return productGroup;
  }

  processRefreshTimeSummary(pcgDataItems, refreshTimeSummary, pcg, pcc, isCurrentPcgAlreadyAdded, currentPcgItem, isCurrentPccAlreadyAdded, productGroup, caDetails = null) {
    _.each(
      refreshTimeSummary,
      function(refreshTimeSummaryItem) {
        if (refreshTimeSummaryItem.PCCName === pcc.Name && refreshTimeSummaryItem.PCGName === pcc.Parent) {
          const dataSourceItems = refreshTimeSummaryItem.DataSource;

          this.pcgRowSpan += dataSourceItems.length - 1;

          _.each(
            dataSourceItems,
            function(dataSourceItem) {
              const getMetricGridData = new MetricGridData();

              if (!isCurrentPcgAlreadyAdded) {
                getMetricGridData.Metric = currentPcgItem;
                isCurrentPcgAlreadyAdded = true;
              }

              if (!isCurrentPccAlreadyAdded) {
                getMetricGridData.ProductGroup = productGroup;
                getMetricGridData.YTDQuota = this.formatter.formatWithCurrencyAndRoundOff(caDetails && caDetails.TargetHours ? caDetails.TargetHours : pcc.Metrics.Target, pcc.MeasuringUnits);
                getMetricGridData.YTDActuals = this.formatter.formatWithCurrencyAndRoundOff(caDetails && caDetails.ActualHours ? caDetails.ActualHours : pcc.Metrics.Actuals, pcc.MeasuringUnits);
                // Check for UBI
                if (caDetails) {
                  getMetricGridData.QuotaToGo = this.formatter.formatWithCurrencyAndRoundOff(
                    caDetails.ActualHours > caDetails.TargetHours ? 0 : caDetails.TargetHours - caDetails.ActualHours,
                    pcc.MeasuringUnits
                  );
                } else if (pcc?.IsDynamicMetric) {
                  getMetricGridData.QuotaToGo = 'NA';
                } else {
                  getMetricGridData.QuotaToGo = this.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.QuotaToGo, pcc.MeasuringUnits);
                }
                // Check for OCP PPM metric weight & earnings for grid view on dashboard screen
                const isZeroWeight = false;
                if (pcg.Weightage === 0 && this.userProfileService.isRBIPersona()) {
                  this.isZeroWeight = true;
                  this.showOCPLegend = true;
                }
                getMetricGridData.MetriclevelYTDEarnings = this.formatter.formatWithCurrencyAndRoundOff(currentPcgItem.totalEarning, pcg.Metrics.CurrencyCode, this.isZeroWeight);

                getMetricGridData.PccRowSpan = dataSourceItems.length;

                isCurrentPccAlreadyAdded = true;
              } else {
                getMetricGridData.PccRowSpan = -1;
              }

              getMetricGridData.DataSource = this.buildDataSource(dataSourceItem);

              pcgDataItems.push(getMetricGridData);
            }.bind(this)
          );
        }
      }.bind(this)
    );
  }

  buildDataSource(dataSourceItem) {
    const dataSource = new DataSourceData();
    dataSource.DataSourceName = dataSourceItem.SourceName;
    dataSource.LastRefresh =
      dataSourceItem.SourceLastRefreshTime === null ? 'Not available.' : this.formatter.transformToLocalDateTz(dataSourceItem.SourceLastRefreshTime, 'MMM D, YYYY, h:mm A ', true);
    const utcDate = this.formatter.getUtcDate();
    dataSource.NextRefresh =
      dataSourceItem.SourceNextRefreshTime === null
        ? dataSourceItem.SourceName === 'ODS'
          ? 'Coming soon.'
          : 'Not available.'
        : this.formatter.getDate(new Date(dataSourceItem.SourceNextRefreshTime)) < utcDate
        ? this.formatter.transformToLocalDateTz(dataSourceItem.SourceNextRefreshTime, 'MMM D, YYYY ', true) + ' (In Progress)'
        : this.formatter.transformToLocalDateTz(dataSourceItem.SourceNextRefreshTime, 'MMM D, YYYY ', true);
    return dataSource;
  }

  getPPIPCGGridViewMetrics(rowData, targetSummary, bucketType) {
    rowData.forEach(function(row: any) {
      if (row.Metric) {
        const pcgData = targetSummary.PlanComponentGroups.filter(ts => ts.Name === row.Metric.name);
        row.TotalTarget = this.formatter.formatWithCurrencyAndRoundOff(pcgData[0].Metrics.TotalTarget, pcgData[0].Metrics.CurrencyCode);
        row.TotalActuals = this.formatter.formatWithCurrencyAndRoundOff(pcgData[0].Metrics.TotalActuals, pcgData[0].Metrics.CurrencyCode);
        row.QuotaToGo_Bucket = this.formatter.formatWithCurrencyAndRoundOff(
          pcgData[0].Metrics.TotalActuals > pcgData[0].Metrics.TotalTarget ? 0 : pcgData[0].Metrics.TotalTarget - pcgData[0].Metrics.TotalActuals,
          pcgData[0].Metrics.CurrencyCode
        );
        row.PayoutImpact = (pcgData[0].Metrics.PayoutImpact * 100).toFixed(0);
        row.PayoutImpact = row.PayoutImpact > 0 ? '+' + row.PayoutImpact + '%' : row.PayoutImpact + '%';
        row.Threshold = (pcgData[0].Metrics.GateThreshold * 100).toFixed(0) + '%';
        row.Metric.bucketType = bucketType;
        row.Metric.payoutImpact = row.PayoutImpact;
      }
    }, this);

    // Commenting out removal of duplicate data source entries at PCG Level as this logic needs to be modified. Currently not needed.
    // if (bucketType === 'Payout Adjustment Bucket' || bucketType === 'Payout Gate Bucket') {
    //   let pcgDataSources: any;
    //   targetSummary.PlanComponentGroups.forEach(function(pcg: any) {
    //     let dataSources = rowData.filter(rd => rd.Metric.name === pcg.Name);
    //     if (dataSources.length > 0) {
    //       // Remove duplicate data source entries at PCG Level
    //       const seen = new Set();
    //       dataSources = dataSources.filter(el => {
    //         const duplicate = seen.has(el.DataSource.DataSourceName);
    //         seen.add(el.DataSource.DataSourceName);
    //         return !duplicate;
    //       });
    //       if (pcgDataSources) {
    //         Array.prototype.push.apply(pcgDataSources, dataSources);
    //       } else {
    //         pcgDataSources = dataSources;
    //       }
    //     }
    //   });
    //   rowData = pcgDataSources;
    // }
    return rowData;
  }

  getPPIPCGMetrics(targetSummary, bucketType) {
    this.pcgMetrics = new Array<{ pcg: any }>();
    const self = this;

    if (targetSummary) {
      targetSummary.PlanComponentGroups.forEach(function(pcg) {
        const metricPCG: any = {};

        metricPCG.PcgName = pcg.Name;
        metricPCG.AttainmentPercent = pcg.Metrics.Attainment.toFixed(2);
        metricPCG.CurrencyCode = pcg.Metrics.CurrencyCode;
        metricPCG.PcgWeight = pcg.PCGWeight.toFixed(0);
        metricPCG.IncentiveType = pcg.IncentiveType;
        metricPCG.TotalActual = self.formatter.formatWithCurrencyAndRoundOff(pcg.Metrics.TotalActuals, metricPCG.CurrencyCode);
        metricPCG.TotalTarget = self.formatter.formatWithCurrencyAndRoundOff(pcg.Metrics.TotalTarget, metricPCG.CurrencyCode);
        metricPCG.QuotaToGo = pcg.Metrics.TotalActuals > pcg.Metrics.TotalTarget ? '0' : (pcg.Metrics.TotalTarget - pcg.Metrics.TotalActuals).toString();
        metricPCG.Threshold = (pcg.Metrics.GateThreshold * 100).toFixed(0);
        metricPCG.ThresholdCap = (pcg.Metrics.GateThresholdCap * 100).toFixed(0);
        metricPCG.PayoutImpact = (pcg.Metrics.PayoutImpact * 100).toFixed(0);
        metricPCG.AttainmentStatus = self.getAttainmentStatus(metricPCG, bucketType, 'cssClass');

        self.pcgMetrics.push({ pcg: metricPCG });
      });
    }

    return self.pcgMetrics;
  }

  getAttainmentStatus(metricPCG, bucketType, valueType) {
    if (bucketType === 'Payout Adjustment Bucket') {
      if (Number(metricPCG.PayoutImpact) < 0) {
        return this.legendService.legendDetails.belowThreshold[valueType];
      } else {
        return this.legendService.legendDetails.belowExcellence[valueType];
      }
    } else if (bucketType === 'Payout Gate Bucket') {
      if (Number(metricPCG.AttainmentPercent) < Number(metricPCG.Threshold)) {
        return this.legendService.legendDetails.belowThreshold[valueType];
      } else {
        return this.legendService.legendDetails.belowExcellence[valueType];
      }
    } else {
      return metricPCG.Metric.attainmentColor;
    }
  }
}
