import { Expose, plainToClass, Type } from 'class-transformer';
import { BaseModel, IDeserializable } from '@mint-libs/common';
import { StandardTitle } from './standard-title.model';

export class UserPreference extends BaseModel implements IDeserializable<UserPreference>  {
  @Expose()
  fiscalYear: string;
  @Type(() => StandardTitle)
  @Expose()
  standardTitle: StandardTitle;
  @Expose()
  partition: string;
  @Expose()
  stepId: number;
  @Expose()
  fiscalQuarterId: number;

  deserialize(from: any): UserPreference {
    if (from.standardTitle.participationID === undefined && from.standardTitle.ParticipationID !== undefined) {
      const titleCap = from.standardTitle;
      const capitalizedTitlePreferences = {
        applicablePartitions: titleCap.ApplicablePartitions,
        code: titleCap.Code,
        countryName: titleCap.CountryName,
        duration: titleCap.Duration,
        endDate: titleCap.EndDate,
        fiscalYear: titleCap.FiscalYear,
        isMic2Profile: titleCap.IsMic2Profile,
        isParticipationCompliant: titleCap.IsParticipationCompliant,
        isPlanActive: titleCap.IsPlanActive,
        isPlanRollOver: titleCap.IsPlanRollOver,
        name: titleCap.Name,
        participationID: titleCap.ParticipationID,
        payeeID: titleCap.PayeeId,
        planEndDate: titleCap.PlanEndDate,
        planId: titleCap.PlanId,
        planName: titleCap.PlanName,
        planStartDate: titleCap.PlanStartDate,
        startDate: titleCap.StartDate
      };
      const pref = {
        ...from,
        standardTitle: capitalizedTitlePreferences,
      }
      return plainToClass(UserPreference, pref as object, this.classTransformOptions);
    }
    const userProfile = plainToClass(UserPreference, from as object, this.classTransformOptions);
    return userProfile;
  }
}
