<div id="payout-report-header-tile">
  <div id="payout-report-header-title">{{ paymentReportTitle }} {{ paymentDetails.CodePeriod }}</div>
  <div id="payout-report-header-body">
    <div class="payout-header-section-lhsamount">
      {{ payoutReportService.formatCurrency(paymentAmount) }}
      <span class="payout-header-section-subtitles">{{ paymentDetails.CurrencyCode }}</span>
    </div>
    <div>=</div>
    <div>
      <div class="payout-header-section-rhsamount">
        {{ payoutReportService.formatCurrency(paymentDetails.YTDEarnings) }} <span class="payout-header-section-subtitles">{{ paymentDetails.CurrencyCode }}</span>
      </div>
      <div class="payout-header-section-subtitles">Core Buckets YTD Award</div>
    </div>
    <div>+</div>
    <div>
      <div class="payout-header-section-rhsamount">
        {{ payoutReportService.formatCurrency(paymentDetails.TotalPayrollAdjustments) }} <span class="payout-header-section-subtitles">{{ paymentDetails.CurrencyCode }}</span>
      </div>
      <div class="payout-header-section-subtitles">Total Adjustments</div>
    </div>
    <div>+</div>
    <div>
      <div class="payout-header-section-rhsamount">
        {{ payoutReportService.formatCurrency(paymentDetails.PaymentRecoveryAmount) }} <span class="payout-header-section-subtitles">{{ paymentDetails.CurrencyCode }}</span>
      </div>
      <div class="payout-header-section-subtitles">Recovery Amount</div>
    </div>
    <div>-</div>
    <div>
      <div class="payout-header-section-rhsamount">
        {{ payoutReportService.formatCurrency(paymentDetails.PreviousPaymentAmount) }} <span class="payout-header-section-subtitles">{{ paymentDetails.CurrencyCode }}</span>
      </div>
      <div class="payout-header-section-subtitles">Previous Payment</div>
    </div>
  </div>
</div>
