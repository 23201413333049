import { Component, OnInit, Input } from '@angular/core';
import { PayoutReportService } from '../../payout-report.service';
import { TPayoutObject } from '../../types/payout-info-response-type';

@Component({
  selector: 'mint-payout-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {
  paymentReportTitle = 'Projected Earnings';
  paymentAmount: number;
  paymentCurrency?:String;
  filteredPaymentDetails: TPayoutObject[];
  @Input() paymentDetails: TPayoutObject[];

  constructor(public payoutReportService: PayoutReportService) { }

  ngOnInit() {

  }

  ngOnChanges() {
    if (this.paymentDetails) {
      this.filteredPaymentDetails = this.paymentDetails.filter(payment => payment.EarningDetails);
    }
    this.getPaymentTitle();
  }

  getPaymentTitle() {
    this.paymentReportTitle = 'Projected Earnings';
    this.paymentAmount = this.reduceProperty('ProjectedEarnings');
    this.paymentCurrency = this.filteredPaymentDetails[0].CurrencyCode;
    if (this.filteredPaymentDetails && this.filteredPaymentDetails.length > 0 && this.filteredPaymentDetails[0].PaymentStatusId == 4) {
        this.paymentReportTitle = 'RBI Plan Net Payment';  
        this.paymentAmount = this.reduceProperty('NetPaymentAmount');        
        if(this.filteredPaymentDetails[0].TransferredCurrencyAmount!=null){
          this.paymentAmount = this.reduceProperty('TransferredCurrencyAmount');
          this.paymentCurrency = (this.filteredPaymentDetails[0].TransferredCurrencyCode!=null 
                                &&  this.filteredPaymentDetails[0].TransferredCurrencyCode.trim().length!=0)
                                ? this.filteredPaymentDetails[0].TransferredCurrencyCode
                                :this.paymentCurrency; 
        }
      }
  }

  isCurrencyDifferent(paymentDetails : TPayoutObject){
    return paymentDetails.UseTransferCurrencyCode 
      && paymentDetails.TransferredCurrencyCode!=null 
      && paymentDetails.TransferredCurrencyCode.toLowerCase() !== paymentDetails.CurrencyCode?.toLowerCase()
  }

  reduceMaxOfProperty(key:string):number{
    if (this.filteredPaymentDetails) {
      return this.filteredPaymentDetails.reduce(
        (previous, current) => previous > current[key]?previous:current[key],
        0);
    }
    else {
      return 0;
    }
  }
  

  reduceProperty(key: string): number {
    if (this.filteredPaymentDetails) {
      return this.filteredPaymentDetails.reduce(
        (previous, current) => previous + current[key],
        0);
    }
    else {
      return 0;
    }
  }
}
