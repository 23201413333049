<content-loader [height]="300" [width]="1000">
    <svg:rect x="10" y="10" rx="4" ry="4" width="650" height="55" />
    <svg:rect x="680" y="10" rx="4" ry="4" width="300" height="55" />

    <svg:rect x="10" y="75" rx="4" ry="4" width="650" height="55" />
    <svg:rect x="680" y="75" rx="4" ry="4" width="300" height="55" />

    <svg:rect x="10" y="140" rx="4" ry="4" width="650" height="55" />
    <svg:rect x="680" y="140" rx="4" ry="4" width="300" height="55" />

    <svg:rect x="10" y="205" rx="4" ry="4" width="650" height="55" />
    <svg:rect x="680" y="205" rx="4" ry="4" width="300" height="55" />
</content-loader>