import { Pipe, PipeTransform } from '@angular/core';
import { Formatter } from '../utilities/formatter';

@Pipe({ name: 'ToDateFormat' })
export class ToDateFormatPipe implements PipeTransform {
  constructor(private formatter: Formatter) {}
  transform(value: any, format: string): string {
    const formatted = this.formatter.transformToDateFormat(value, format, 'en-US');
    return formatted;
  }
}
