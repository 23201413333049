import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { UserProfileService } from '../core/context/user-profile.service';
import { EndpointService } from '@mint-libs/network';
// import { DataService } from '@mint-libs/context';
import { HttpParams } from '@angular/common/http';
import { DataService } from '../core/network/data.service';
import { FiltersService } from '../filters/filters.service';
import { ImpersonationService } from '../core/impersonation/impersonation.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  sharedContext: SharedContext = new SharedContext();
  constructor(
    private impersonationService: ImpersonationService,
    private filterService: FiltersService,
    public userProfileService: UserProfileService,
    private endpoint: EndpointService,
    private dataService: DataService,
    public sharedContextState: Store<SharedContext>
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  getDashboardData() {
    return forkJoin(this.getRefreshTimesOfDataSources(), this.getAllPaylines(), this.getPayoutSummary(), this.getTargetSummary());
  }
  getPCGMetrics(): Observable<any> {
    return this.dataService.getWithGlobalFilters(this.endpoint.GetPCGMetrics.uri);
  }

  getRefreshTimesOfDataSources(): Observable<any> {
    return this.dataService.getWithGlobalFilters(this.endpoint.GetRefreshTimesOfDataSources.uri);
  }

  getTargetSummary(): Observable<any> {
    let params = new HttpParams();
    const isDMYEligible = this.sharedContext.IsDMYEligible;
    params = params.append('IsDMYElligible', isDMYEligible);
    return this.dataService.getWithGlobalFilters(this.endpoint.GetTargetSummary.uri, params);
  }

  getAllPaylines(): Observable<any> {
    return this.dataService.getWithGlobalFilters(this.endpoint.GetAllPaylines.uri, null);
  }

  getPayoutSummary(): Observable<any> {
    return this.dataService.getWithGlobalFilters(this.endpoint.GetPayoutSummary.uri, null);
  }

  isNewDashboardApplicable(): boolean {
    return (
      this.filterService.selectedFiscalYear >= 2019 &&
      (this.sharedContext.isImpersonating || // When impersonating we keep dashboard available
      this.userProfileService.isNPM() || // When NPM
      this.userProfileService.isParticipantManager() || // When PPI/UBI/RBI Participant Manager
        (this.userProfileService.isSeller() && this.userProfileService.isCompliant() && this.sharedContext.isRollOver === true))
    );
  }
}
