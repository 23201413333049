<app-progress-bar id="territory-report_0" class="sticky__progress__bar"></app-progress-bar>
<div id="territory-report_1" class="note-container">
  <div id="territory-report_2" *ngIf="isLargeUser" class="note">
    <strong id="territory-report_3">Note: </strong> We are unable to process the large number of territories you carry. Please select our recommended filters or select a few accounts or territories to
    begin
  </div>
  <div id="territory-report_4" *ngIf="reportDisclaimer" class="note"><strong id="territory-report_5">Note: </strong> {{ reportDisclaimer }}</div>
</div>
<div id="territory-report_6" class="report-container">
  <div class="pull-right">
    <app-tour id="revenueTerritoryDetail_49_tour" [tourName]="'Target and Actuals by Incentive Territory Report'"></app-tour>
  </div>
  <div id="territory-report_7" class="row" *ngIf="isLargeUser">
    <div id="territory-report_8" class="col-xs-12">
      <mint-advance-search-filter
        id="territory-report_9"
        [searchSuggestion]="searchSuggestion"
        [reportType]="revenueReportType"
        [isFilterTabActive]="activeTabId === filterTabId"
        (search)="search($event)"
        (filter)="filterTerritories($event)"
      ></mint-advance-search-filter>
    </div>
    <div id="territory-report_8_b" class="col-xs-12">
      <mint-report-tabs id="territory-report_9_b" [tabs]="tabs" [activeTabId]="activeTabId" [reportType]="revenueReportType" (tabsChange)="tabsChange($event)"></mint-report-tabs>
    </div>
  </div>
  <div id="territory-report_10" class="row mt-20">
    <div id="territory-report_11" class="col-xs-12 col-md-6 vr-line">
      <div id="territory-report_12" *ngIf="selectedPccChart" class="chart-title">Compensation Actuals by Metric</div>
      <div id="territory-report_13" class="chart-container">
        <mint-metric-chart
          id="territory-report_14"
          *ngIf="chartDataPccs"
          [dataset]="chartDataPccs"
          [doNotShowToolTip]="true"
          [chartHelpText]="'Revenue by Metric bar chart X axis is Metric Y axis is Revenue'"
          class="pcg__item__chart"
          [containerId]="'territory-report-pcc-chart'"
          (chartClick)="filterSelectedPcc($event)"
        ></mint-metric-chart>
        <mint-content-loader-bar-chart-vertical id="territory-report_15" *ngIf="!chartDataPccs"></mint-content-loader-bar-chart-vertical>
      </div>
    </div>
    <div id="territory-report_16" class="col-xs-12 col-md-6 ">
      <div id="territory-report_17" *ngIf="selectedPccChart" class="chart-title">{{ selectedPccChart.desc }}</div>
      <div id="territory-report_18" class="chart-container">
        <mint-metric-chart
          id="territory-report_19"
          *ngIf="chartDataRevenue"
          [dataset]="chartDataRevenue"
          [doNotShowToolTip]="true"
          [chartHelpText]="'Revenue by territories for ' + selectedPccChart.desc + ' bar chart X axis is Territory Y axis is Revenue'"
          class="pcg__item__chart"
          [containerId]="'territory-report-actuals-chart'"
        ></mint-metric-chart>
        <mint-content-loader-bar-chart-vertical id="territory-report_20" *ngIf="!chartDataRevenue"></mint-content-loader-bar-chart-vertical>
      </div>
    </div>
  </div>
  <hr id="territory-report_22" class="hr-line" />
  <div id="territory-report_23" class="row">
    <mint-report-grid
      *ngIf="revenueData?.length"
      [columns]="columns"
      [data]="revenueData"
      (exportToExcel)="onRevenueExcelExport($event)"
      [excelDownloadStatus]="revenueExcelConfigs.excelDownloadStatus"
      [excelDownloadMessage]="revenueExcelConfigs.excelDownloadMessage"
      [excelTitle]="revenueReportType"
    ></mint-report-grid>
    <div id="territory-report_25" *ngIf="!revenueData?.length" class="content__loader">
      <mint-content-loader-grid id="territory-report_26"></mint-content-loader-grid>
    </div>
  </div>
</div>
