import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EndpointService, LocalStorageService } from '@mint-libs/network';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthenticationInterceptor } from './authentication/authentication.interceptor';
import { UrlHandlingStrategy } from '@angular/router';
import { CustomUrlHandlingStrategy } from './routing/custom-url-handling-strategy';
import { ContextInterceptor } from './context/context.interceptor';
import { CachingInterceptor } from './caching/caching.interceptor';
import { DownTimeInterceptor } from './network/downtime.interceptor';
import { CachingService } from './caching/caching.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { UserProfileService } from './context/user-profile.service';
import { NotificationServiceFactory } from './context/notificationFactory.service';
import { OcvFeedbackService } from '../ocv-feedback/ocv-feedback.service';
import { OcvFloodgateService } from '../ocv-feedback/ocv-floodgate.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { userProfileFeatureKey, userProfileReducer, UserProfileEffects, filtersFeatureKey, filtersReducer, FilterEffects, RoleFeatureService, GraphService } from '@mint-libs/context';
import { FiltersModule } from '../filters/filters.module';
import { MeasureTypeMappingEffect, measureTypeMappingFeatureKey, measureTypeMappingReducer } from '@mint-libs/context';
import { EventQueueService } from './broadcast/event-queue.service';
import { InitializationService } from './initialization/initialization.service';
import { AuthenticationService } from '@mint-libs/authentication'; // ' @mint-libs/authentication/lib/authentication.service';
import { ImpersonationService } from './impersonation/impersonation.service';
import { NavigationService } from '@progress/kendo-angular-grid';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { WidgetFactory } from '../shared/widgets/widget.factory';
import { LoggerService } from '@mint-libs/logging';
import { PageTourService } from '../shared/training/pagetour.service';
import { DisclaimersService } from '../disclaimers/disclaimers.service';
import { UserSessionService } from './session/user-session-service';
import { Code } from '../app.constants';
import { FiltersService } from '../filters/filters.service';

export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: DownTimeInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ContextInterceptor, multi: true }
];

// private filterService: , private navigationFactory: , private authorizationService: AuthorizationService, private userProfile: , private code: Code, private configurationService: ConfigurationService, private cachingService: CachingService, private loggingService: LoggerService, private graphService: GraphService, private rolefeatureService: RoleFeatureService, private demoContext: DemoContext, private location: AngularLocation, private disclaimerService: DisclaimersService

@NgModule({
  imports: [
    HttpClientModule,
    StoreModule.forFeature(userProfileFeatureKey, userProfileReducer),
    StoreModule.forFeature(filtersFeatureKey, filtersReducer),
    StoreModule.forFeature(measureTypeMappingFeatureKey, measureTypeMappingReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([UserProfileEffects, MeasureTypeMappingEffect, FilterEffects]),
    FiltersModule
  ],
  providers: [
    { provide: UrlHandlingStrategy, useClass: CustomUrlHandlingStrategy },
    EndpointService,
    HttpInterceptorProviders,
    CachingService,
    SharedContext,
    InitializationService,
    Code,
    NotificationServiceFactory,
    AuthenticationService,
    UserSessionService,
    FiltersService,
    NavigationService,
    DisclaimersService,
    EventQueueService,
    OcvFeedbackService,
    OcvFloodgateService,
    RoleFeatureService,
    WidgetFactory,
    LocalStorageService,
    LoggerService,
    PageTourService,
    GraphService,
    DisclaimersService
    // Ng1Services.Ng1UserProfileServiceProvider,
    // Ng1Services.Ng1CommonFactoryProvider,
    // Ng1Services.Ng1ImpersonationServiceProvider,
    // Ng1Services.Ng1InitializationServiceProvider,
    // Ng1Services.Ng1AuthorizationServiceProvider,
    // Ng1Services.Ng1AdalServiceProvider,
    // Ng1Services.Ng1NavigationFactoryProvider,
    // Ng1Services.Ng1RoleFeatureServiceProvider,
    // Ng1Services.Ng1SharedContextProvider,
    // Ng1Services.Ng1AuthServiceProvider,
    // Ng1Services.Ng1WidgetFactoryProvider,
    // Ng1Services.Ng1LocalStorageServiceProvider,
    // Ng1Services.Ng1LoggingServiceProvider,
    // Ng1Services.Ng1PageTourServiceProvider,
    // Ng1Services.Ng1GraphServiceProvider,
    // Ng1Services.Ng1NotificationFactoryProvider
  ]
  // exports: [FilterDirective]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
