import { InitActions, InitializationActionTypes } from './initialization.actions';

export const loginFeatureKey = 'authInfo';

export interface InitializationState {
    clearCache: boolean;
}

export const initialState: InitializationState = { clearCache: true };

export function initializationReducer(state = initialState, action: InitActions): InitializationState {
  switch (action.type) {
    default:
      return state;
  }
}

export const initializationFeatureKey = "InitializationFeatureKey";