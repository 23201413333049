import { empty } from 'rxjs';
import { TGrainItemSectionHeader } from './types/bonus-config.type';
import { TBonusAttributeDetailSection, TBonusDetailView } from './types/bonus-data.type';

export const BINGO_BONUS_NAME = 'Excellence Bonus';
export const DEFAULT_PLAN_COMPONENT_NAME = 'DEFAULT';

export const emptyHeader: TGrainItemSectionHeader = {
  isHeaderEnabled: false,
  isHeaderRevmapName: false,
  isHeaderTooltipProgramDuration: false
};

export const emptyBonusSectionView: TBonusAttributeDetailSection = {
  header: emptyHeader,
  sectionItems: []
};

export const emptyDetailView: TBonusDetailView = {
  weightedSection: null,
  bonusSection: null,
  excellenceSection: null
};
