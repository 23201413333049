import { Component, OnInit, Inject } from '@angular/core';
import { SettingsTabs } from './settingsTabs.enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserProfileService } from '../core/context/user-profile.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  disclaimer: any;
  disclaimerText: SafeHtml;
  disclaimerAcceptDate: string;
  public sharedContext: SharedContext = new SharedContext();

  constructor(private sanitizer: DomSanitizer, public userProfileService: UserProfileService, public sharedContextState: Store<SharedContext>) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      this.disclaimer = this.sharedContext.Settings.Disclaimer;
      this.disclaimerText = this.sanitizer.bypassSecurityTrustHtml(this.disclaimer.Text);
      this.disclaimerAcceptDate = this.disclaimer.AcceptedDate;
    });
  }

  ngOnInit() {}

  settingsTabs = [
    {
      index: 0,
      id: 'settingsTab_about',
      key: SettingsTabs.About,
      title: 'About'
    },
    {
      index: 1,
      id: 'settingsTab_disclaimer',
      key: SettingsTabs.Disclaimer,
      title: 'Disclaimer'
    },
    {
      index: 2,
      id: 'settingsTab_thirdpartysoft',
      key: SettingsTabs.ThirdPartySoftwares,
      title: 'Third Party Softwares'
    }
  ];

  // ToDo: This value will come from the DB as a user preference
  selectedTab = 0;

  activeTabId = this.settingsTabs.filter(t => t.index === this.selectedTab)[0].id;

  onTabChange(event) {
    this.settingsTabs.forEach(x => {
      if (x.id === event.nextId) {
        this.selectedTab = x.index;
      }
    });
  }
}
