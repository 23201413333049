import { Component, OnInit, Input, TemplateRef, Inject, EventEmitter, Output } from '@angular/core';
import { PageTourService } from './pagetour.service';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import * as _ from 'underscore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageTour } from '@pagetour/sdk';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html'
})
export class TourComponent implements OnInit {
  hasTour: boolean;
  @Input() tourName: any;
  @Output() tourClick = new EventEmitter<any>();
  tourId: any;
  modalRef;
  tourFeedback: any;
  listOfTours: any;

  constructor(private pageTourService: PageTourService, private modalService: NgbModal) {}

  ngOnInit() {
    if (!environment.isPageTourEnabled) {
      return;
    }
    this.findTour();
  }

  // <------------------------------------ Old PageTour start --------------------------------------->

  // findTour() {
  //   this.pageTourService.getComponentTour(this.tourName).subscribe(result => {
  //     if (result && result !== '') {
  //       this.hasTour = true;
  //     }
  //   });
  //   // this.hasTour = true;
  // }

  // playTour(tourFeedback: TemplateRef<any>) {
  //   this.tourClick.emit();
  //   const pagetour = this.pageTour.getPlayTourInstance();
  //   const pagetourService = this.pageTour.getInstance().pagetourService;
  //   const context = {
  //     tourName: this.tourName,
  //     pageTour: pagetour,
  //     tourFeedback: tourFeedback,
  //     modal: this,
  //     callbackTourFeedback: this.callbackTourFeedback
  //   };
  //   const callback = this.callbackToPlayTour.bind(context);
  //   pagetourService.getTours('', callback);
  // }

  // callbackToPlayTour(tours) {
  //   const toursList = _.sortBy(tours, 'createdon').reverse();
  //   let tourId = null;
  //   let currentTour = null;
  //   _.find(
  //     toursList,
  //     function(tour) {
  //       if (tour.title === this.tourName) {
  //         this.modal.tourId = tour.id;
  //         tourId = tour.id;
  //         currentTour = tour;
  //         return true;
  //       }
  //     },
  //     this
  //   );
  //   if (tourId) {
  //     const callback = this.callbackTourFeedback.bind(this);
  //     let tourstr = JSON.stringify(currentTour);
  //     tourstr = tourstr.replace(/V2/g, '');
  //     currentTour = JSON.parse(tourstr);
  //     this.pageTour.runTour(currentTour, callback);
  //   }
  // }

  // <------------------------------------ Old PageTour end --------------------------------------->


  // <-------------------------------- New PageTour start ---------------------------------------->

  findTour() {
    const pagetour = PageTour.GetInstance();

    const pageContext = pagetour.getPageContext();
    pageContext.state = '';
    pageContext.url = '';
    const tags = [];
    pagetour.getTours(pageContext, tags).then(result => {
      console.log('result ', result);
      if (result) {
        this.listOfTours = result;
        this.hasTour = this.listOfTours.some(x => x.title === this.tourName);
      }
    });
  }

  playTour(tourFeedback: TemplateRef<any>) {
    const pagetour = PageTour.GetInstance();

    const tourObjList = this.listOfTours.filter(x => x.title === this.tourName);
    const tourObj = tourObjList.sort((a, b) => {
      if (a.createdon < b.createdon) {
        return 1;
      } else if (a.createdon > b.createdon) {
        return -1;
      } else {
        return 0;
      }
    });
    pagetour.autoPlayByTourObject(tourObj[0], 1).then(() => {
      this.callbackTourFeedback();
    });
  }

  // <-------------------------------- New PageTour end ---------------------------------------->

  callbackTourFeedback() {
    this.modalRef = this.modalService.open(this.tourFeedback, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'mint-modal-backdrop', windowClass: 'mint-modal-window' });
  }

  closeModal(event: any) {
    this.modalRef.close();
  }
}
