import { Component, OnInit, Input } from '@angular/core';
import { PayoutReportService } from '../../../../payout-report.service';

@Component({
  selector: 'mint-payout-report-metric-adjustment-details',
  templateUrl: './adjustment-details.component.html',
  styleUrls: ['./adjustment-details.component.scss']
})
export class MetricAdjustmentDetailsComponent implements OnInit {
  displayedColumns: string[] = ['Name', 'TotalTarget', 'Actuals', 'TotalRecurringTarget', 'MetricTarget', 'MetricActual'];
  @Input() adjustmentDetails: any;

  constructor(public payoutReportService: PayoutReportService) {}

  ngOnInit() {}
}
