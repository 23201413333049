import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmileyBoxComponent } from './smiley-box/smiley-box.component';
import { MultiSelectBoxComponent } from './multi-select-box/multi-select-box.component';
import { MultilineTextBoxComponent } from './multiline-text-box/multiline-text-box.component';
import { RadioBoxComponent } from './radio-box/radio-box.component';
import { RatingBoxComponent } from './rating-box/rating-box.component';
import { TextBoxComponent } from './text-box/text-box.component';
import { FeedbackComponent } from './feedback.component';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContextualVideoComponent } from './contextual-video/contextual-video.component';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule, NgbModule],
  declarations: [SmileyBoxComponent, MultilineTextBoxComponent, RadioBoxComponent, RatingBoxComponent, TextBoxComponent, MultiSelectBoxComponent, FeedbackComponent],
  providers: [],
  exports: [SmileyBoxComponent, MultilineTextBoxComponent, RadioBoxComponent, RatingBoxComponent, TextBoxComponent, MultiSelectBoxComponent, FeedbackComponent]
})
export class FeedbackModule { }
