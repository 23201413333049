import { state } from '@angular/animations';
import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserProfileService } from '../context/user-profile.service';
import * as Ng1Services from '../hybrid/ng1-upgraded-providers';

@Injectable()
export class CagrRouteGuard implements CanActivate {
  public sharedContext = new SharedContext();
  constructor(private _router: Router, public sharedContextState: Store<SharedContext>) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.sharedContext.IsCagrEligible) {
      return true;
    }

    this._router.navigate(['/login']);
    return false;
  }
}
