<div class="pcg__item" [ngStyle]="{ 'background-color': !lockStatus ? '#F2FBF7' : '#ffff' }">
    <div class="pcg__heading" [style.border-left]="!lockStatus ? '4px solid #26873A' : '4px solid #333333'"
        *ngIf="planComponentSectionIsEnabled">
        <div class="pcg__desc">
            <div class="pcg__name">
                {{ bonusName }}
            </div>
            <div class="txt-note" *ngIf="isSellerRoleEnabled">
                <!-- Seller role here -->
            </div>
            <div class="txt-note" *ngIf="isBucketWeightEnabled">
                <!-- Bucket weight data goes here -->
            </div>
            <div class="txt-note" *ngIf="planComponentSectionNotes">
                {{ planComponentSectionNotes }}
            </div>
        </div>
    </div>
    <div class="pcg__attainment__container" *ngIf="attainmentSectionIsEnabled">
        <div class="pcg__attainment" style="text-align: center;">
            <div class="pcg__attainment__val">{{ attainmentString }}</div>
            <div class="pcg__attainment__bar" (mouseover)="showTooltip($event, grainItem.attribute)"
                (mouseout)="hideTooltip(grainItem.attribute)" *ngIf="attainmentSectionIsProgressEnabled">
                <div class="pcg__attainment__bar-actual" [style.width]="attainmentPercentage + '%'"></div>
            </div>
        </div>
    </div>
    <div class="pcg__awards" *ngIf="attainmentSectionIsEnabled && lockStatus && attainmentSectionIsLockStatusEnabled">
        <div class="pcg__award">
            <mint-common-icon width="12" height="16" viewBox="0 0 12 16" fill="white" type="locked-lg">
            </mint-common-icon>
        </div>
    </div>
    <div class="pcg__awards pcg__awards-unlocked"
        *ngIf="attainmentSectionIsEnabled && !lockStatus && attainmentSectionIsLockStatusEnabled">
        <div class="pcg__award">
            <mint-common-icon width="12" height="16" viewBox="0 0 12 16" fill="#333333" type="unlocked-lg">
            </mint-common-icon>
        </div>
    </div>
    <div class="pcg__target__container" *ngIf="attainmentDescriptionSectionIsEnabled">
        <div class="pcg__target__item">
            <div class="target__icon" *ngIf="attainmentDescriptionSectionIsTargetMetEnabled">
                <mint-common-icon width="33" height="33" viewBox="0 0 33 33" [fill]="lockStatus ? '#AFAFAF' : '#00AE56'"
                    type="target"></mint-common-icon>
            </div>
            <div class="pcg__target__info" style="display: flex;">
                <div *ngIf="!lockStatus">
                    <div class="pcg__target__val">
                        {{ bonusAmount | number: '1.0-2' }}
                        {{ currencyCode }}
                    </div>
                    <div>
                        {{ attainmentDescriptionSectionUnlockedDescription }}
                    </div>
                </div>
                <div class="pcg__target__val" *ngIf="lockStatus" style="font-weight: normal; font-size: medium;">
                    {{ attainmentDescriptionSectionLockedDescription }}</div>

                <div class="stat__info"
                    *ngIf="attainmentDescriptionSectionLockedTooltip || attainmentDescriptionSectionUnlockedTooltip">
                    <div class="icon icon-info"></div>
                    <div class="stat__tooltip stat__tooltip-right">
                        <p class="stat__tooltip__text">
                            {{ lockStatus ? attainmentDescriptionSectionLockedTooltip: attainmentDescriptionSectionUnlockedTooltip}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pcg__target__container" *ngIf="capDetailsSectionIsEnabled">
        <div class="pcg__target__item">
            <div class="pcg__target__info" style="display: flex;">
                <div *ngIf="!lockStatus">
                    <div>
                        {{ capDetailsSectionUnlockedDescription }}
                    </div>
                </div>
                <div class="pcg__target__val" *ngIf="lockStatus" style="font-weight: normal; font-size: medium;">
                    {{ capDetailsSectionLockedDescription }}</div>
                <div class="stat__info" *ngIf="capDetailsSectionLockedTooltip || capDetailsSectionUnlockedTooltip">
                    <div class="icon icon-info"></div>
                    <div class="stat__tooltip stat__tooltip-right">
                        <p class="stat__tooltip__text">
                            {{ lockStatus ? capDetailsSectionLockedTooltip: capDetailsSectionUnlockedTooltip }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="linebar__tooltip" [attr.id]="'cloud_perf' + bonusPlanComponentId" *ngIf="!isBingo">
        <div class="tooltip__info__container">
            <div class="tooltip__info">
                <div class="tooltip__metric__item">
                    <div class="tooltip__metric__header">Cloud performance</div>
                    <div class="tooltip__metric">
                        <div class="tooltip__metric__desc">
                            <b>Actuals: {{ actuals | number: '1.0-2' }}
                                {{ pccUnitLongName }}</b>
                        </div>
                    </div>

                    <div class="tooltip__metric">
                        <div class="tooltip__metric__desc">
                            <b>Target: {{ quota | number: '1.0-2' }}
                                {{ pccUnitLongName }}</b>
                        </div>
                    </div>

                    <div class="tooltip__metric" *ngIf="quotaRecurring > 0">
                        <div class="tooltip__metric__desc">
                            <b>Recurring Target: {{ quotaRecurring | number: '1.0-2' }} {{ pccUnitLongName }}</b>
                        </div>
                    </div>
                </div>
                <div class="tooltip__metric__item" *ngIf="sourceNextRefresh || sourceLastRefresh">
                    <div class="tooltip__metric__header">Data Source Refresh</div>
                    <div class="tooltip__metric__bottom" class="pcg__items">
                        <div class="tooltip__metric__desc" *ngIf="sourceLastRefresh">Last Refresh:
                            {{ sourceLastRefresh }}</div>
                        <div *ngIf="sourceNextRefresh" class="tooltip__metric__desc">Next Refresh:
                            {{ sourceNextRefresh }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>