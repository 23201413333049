import { Component, OnInit, Inject } from '@angular/core';
import { BaseComponent } from '../shared/base.component';
import { Router } from '@angular/router';
import { CagrService } from './cagr.service';
import { CagrParticipationDetails } from '../shared/models/cagr-participation-details.model';
import { element } from 'protractor';
import { LocalStorageService } from '@mint-libs/network';
import { FiltersService } from '../filters/filters.service';
import { LoggerService } from '@mint-libs/logging';
import { UserProfileService } from '../core/context/user-profile.service';
import { CommonFactory, selectSharedContextState, SharedContext } from '@mint-libs/common';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from '../core/navigation/navigation.service';
import { ImpersonationService } from '../core/impersonation/impersonation.service';
import { InitializationService } from '../core/initialization/initialization.service';
import { UserSessionService } from '../core/session/user-session-service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-cagr',
  templateUrl: './cagr.component.html',
  styleUrls: ['./cagr.component.scss'],
  providers: [CagrService]
})
export class CagrComponent extends BaseComponent implements OnInit {
  public isDemoMode3 = false;
  public cagrParticipationDetails: Array<CagrParticipationDetails>;
  isDataLoaded = false;
  lastestFiscalYear: any;
  activeTabid: any;
  participationDataTemp: any;
  tabs: Array<any> = [];
  sharedContext = new SharedContext();

  constructor(
    public initializationService: InitializationService,
    public navigationService: NavigationService,
    public impersonationService: ImpersonationService,
    public sharedContextState: Store<SharedContext>,
    public userProfileService: UserProfileService,
    public rolefeatureService: RoleFeatureService,
    public filterService: FiltersService,
    public localStorage: LocalStorageService,
    public loggingService: LoggerService,
    public cagrService: CagrService,
    public userSessionService: UserSessionService,
    router: Router
  ) {
    super(initializationService, navigationService, impersonationService, sharedContextState, userProfileService, rolefeatureService, filterService, localStorage, router, userSessionService);
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => (this.sharedContext = sharedContext));
  }

  ngOnInit() {
    this.loggingService.trackPageView('CAGR', { UserAlias: this.sharedContext.alias });
    this.isDemoMode3 = this.sharedContext.isDemoMode3;
    this.GetCagrParticipationDetails();
  }

  GetCagrParticipationDetails() {
    this.cagrService.getCagrParticipationDetails().subscribe(results => {
      this.cagrParticipationDetails = new Array<CagrParticipationDetails>();
      this.participationDataTemp = results;
      this.tabs = [];
      var i = 0;
      this.participationDataTemp.forEach(element => {
        const a = new CagrParticipationDetails();
        a.cagrParticipationId = element.CagrParticipationID;
        a.revMapName = element.RevMapName;
        a.participationStartDate = element.ParticipationStartDate;
        a.participationEndDate = element.ParticipationEndDate;
        a.participationStartYear = element.ParticipationStartYear;
        a.participationEndYear = element.ParticipationEndYear;

        this.cagrParticipationDetails.push(a);
        this.tabs.push({ index: i, id: a.cagrParticipationId.toString(), key: '', title: '' + a.participationStartYear + '-' + a.participationEndYear });
        i = i + 1;
      });

      if (this.cagrParticipationDetails.length >= 1) {
        this.activeTabid = this.cagrParticipationDetails[0].cagrParticipationId.toString();
        this.isDataLoaded = true;
      }
    });
  }

  onTabChange(event) {
    this.isDataLoaded = false;
    this.cagrParticipationDetails.forEach(element => {
      if (event.nextId === element.cagrParticipationId.toString()) {
        this.activeTabid = element.cagrParticipationId.toString();
        this.isDataLoaded = true;
      }
    });
  }
}
