export enum PaylineRange {
  Current = 0,
  BeforeThreshold = 1,
  JustBeforeThreshold = 2,
  Threshold = 3,
  BetweenThresholdAndTarget = 4,
  Target = 5,
  BetweenTargetAndExcellence = 6,
  Excellence = 7,
  BelowHardCap = 8,
  AboveHardCap = 9,
  Simulated = 10
}
