import { Component, OnInit, Input, Inject } from '@angular/core';
import { LegendService } from '../legends/legend.service';
import { Metric } from '../models/metric.model';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { IncentiveType } from '../models/incentive-type.enum';
import { FiltersService } from '../../filters/filters.service';
@Component({
  selector: 'app-attainment-weight',
  template: `
    <span *ngIf="isWeightVisible(values)" class="att-wt" [ngStyle]="{ border: calculateAttaintmentColor() }">Weight: {{ values.weight }} %</span>
  `,
  styleUrls: ['./attainment-weight.component.scss']
})
export class AttainmentWeightComponent implements OnInit {
  @Input() values: Metric;
  constructor(private legendService: LegendService, private filterService: FiltersService) {}

  ngOnInit() {}
  calculateAttaintmentColor() {
    return '1px solid' + this.legendService.getColor(this.values);
  }

  isWeightVisible(value: any) {
    if ((!this.filterService.isFSA() && (value.incentiveType === IncentiveType.UBI || value.incentiveType === IncentiveType.RUBI)) || !value.weight) {
      return false;
    } else {
      return true;
    }
  }
}
