import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { EndpointService } from '@mint-libs/network';
import { CorrelatorService } from '@mint-libs/context';
import { environment } from '../../../environments/environment';

import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { ImpersonationService } from '../impersonation/impersonation.service';
import { Store } from '@ngrx/store';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ContextInterceptor implements HttpInterceptor {
  skipImpersonation: boolean;
  public sharedContext = new SharedContext();

  constructor(private correlator: CorrelatorService, private impersonationService: ImpersonationService, private endpointService: EndpointService, public sharedContextState: Store<SharedContext>) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const injectedHeaders = this.injectHeaders(req.headers);
    const injectedUrl = this.injectUrl(req.url, req.params);

    const clonedReq = req.clone({
      headers: injectedHeaders,
      url: injectedUrl
    });

    if (this.isReadOnlyMode(req)) {
      return EMPTY;
    } else {
      return next.handle(clonedReq);
    }
  }

  isReadOnlyMode(config) {
    if (config.method.toUpperCase() === 'POST' && config.body.IsViewAs) {
      return true;
    } else {
      return false;
    }
  }

  injectHeaders(headers: HttpHeaders): HttpHeaders {
    headers = headers.set('X-CorrelationId', this.correlator.getCorrelationId());
    headers = headers.set('X-SessionId', this.correlator.getSessionId());
    headers = headers.set('X-Content-Type-Options', 'nosniff');
    headers = headers.set('DemoMode', this.sharedContext.isDemoMode3 ? 'True' : 'False');
    return headers;
  }

  injectUrl(url: string, params: any): any {
    url = this.injectImpersonationValues(url);
    url = this.injectSpoofing(url, params);
    return url;
  }

  injectImpersonationValues(url: string): string {
    let skipImpersonationValues = false;

    const location = this.getLocation(url);
    const locationUrl = url ? location.protocol + '//' + location.host : url;
    if (locationUrl.indexOf('api.powerbi') > -1 ||  locationUrl.indexOf('graph.microsoft.com') > -1) {
      skipImpersonationValues = true;
    }

    if (!skipImpersonationValues && (this.sharedContext.isImpersonating || this.sharedContext.isViewAs)) {
      const queryStringIndex = url.indexOf('?');
      const impersonatingUserString = '/' + this.sharedContext.impersonatingAlias;
      if (queryStringIndex === -1) {
        url = url.concat(impersonatingUserString);
      } else {
        url = url
          .substr(0, queryStringIndex)
          .concat(impersonatingUserString)
          .concat(url.substr(queryStringIndex));
      }
    }

    this.skipImpersonation = skipImpersonationValues;

    return url;
  }

  injectSpoofing(url: string, params: any): any {
    if (this.skipImpersonation || (params && params.RequestedUser)) {
      return;
    }
    const spoofUser = environment.spoofUser;

    if (url && url.toLowerCase().indexOf('spoofuser') === -1 && spoofUser && spoofUser.trim() !== '') {
      if (url.indexOf('?') > -1) {
        url = url.concat('&');
      } else {
        url = url.concat('?');
      }
      url = url.concat('spoofUser=' + spoofUser);
    }

    return url;
  }

  getLocation(url) {
    const match = url.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)(\/[^?#]*)(\?[^#]*|)(#.*|)$/);
    return (
      match && {
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7]
      }
    );
  }
}
