import { Injectable, Inject } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../core/network/data.service';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Formatter, selectSharedContextState, SharedContext } from '@mint-libs/common';
import { plainToClass, ClassTransformOptions } from 'class-transformer';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { CustomHttpParams } from '../core/network/custom-http-params';
import { ContextState } from '@mint-libs/context';
import { selectConfigurationState } from '@mint-libs/configuration';
import { select, Store } from '@ngrx/store';
import { FiltersService } from '../filters/filters.service';
import { UserProfileService } from '../core/context/user-profile.service';

@Injectable({ providedIn: 'root' })
export class CagrService {
  latestFiscalYear: any;
  filterService: FiltersService;
  sharedContext = new SharedContext();

  constructor(
    private endpoint: EndpointService,
    private dataService: DataService,
    private formatter: Formatter,
    public userProfileService: UserProfileService,
    filterService: FiltersService,
    private contextState: Store<ContextState>,
    public sharedContextState: Store<SharedContext>
  ) {
    this.getConfigDetails();
    this.filterService = filterService;
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => (this.sharedContext = sharedContext));
  }

  // // getCagrAccounts() {
  // //   return this.dataService.getWithGlobalFilters<any>(this.endpoint.GetCagrAccounts.uri, null);
  // // }
  // // getCagrAccountsDetails(AccountId: any) {
  // //   return this.dataService.getWithGlotebalFilters<any>(this.endpoint.GetCagrAccountsDetails.uri, null);
  // // }

  getConfigDetails() {
    this.contextState
      .pipe(
        select(selectConfigurationState),
        filter(val => val !== undefined)
      )
      .subscribe(state => {
        this.latestFiscalYear = state.configs.latestFiscalYear;
      });
  }

  getCagrParticipationDetails() {
    let params = CustomHttpParams.getInstance();
    params = params.append('Alias', this.sharedContext.alias);
    params = params.append('FiscalYear', String(this.filterService.selectedFiscalYear));
    params = params.append('PersonnelNumber', `${this.sharedContext.personnelNumber}`);
    return this.dataService.get<any[]>(this.endpoint.GetCagrParticipations.uri, params);
  }
  getCagrPccAttainmentDetails(participationId, RevMapName) {
    let params = CustomHttpParams.getInstance();
    params = params.append('ParticipationId', participationId);
    params = params.append('FiscalYear', String(this.filterService.selectedFiscalYear));
    params = params.append('PlanName', RevMapName);
    return this.dataService.get<any[]>(this.endpoint.GetCagrPccAttainmentDetails.uri, params);
  }
  getCagrAccounts(participationId) {
    let params = CustomHttpParams.getInstance();
    params = params.append('ParticipationId', participationId);
    params = params.append('FiscalYear', String(this.filterService.selectedFiscalYear));
    return this.dataService.get<any[]>(this.endpoint.GetCagrAccounts.uri, params);
  }

  getCagrProductsServicesData(participationId, RevMapName) {
    let params = CustomHttpParams.getInstance();
    params = params.append('ParticipationId', participationId);
    params = params.append('FiscalYear', String(this.filterService.selectedFiscalYear));
    params = params.append('PlanName', RevMapName);
    return this.dataService.get<any[]>(this.endpoint.GetCagrProductsServicesData.uri, params);
  }
  getCagrMultipleAccountsData(participationId, RevMapName) {
    let params = CustomHttpParams.getInstance();
    params = params.append('ParticipationId', participationId);
    params = params.append('FiscalYear', String(this.filterService.selectedFiscalYear));
    params = params.append('PlanName', RevMapName);
    return this.dataService.get<any[]>(this.endpoint.GetCagrMultipleAccountsData.uri, params);
  }

  getCagrAwardsUnlockedData(participationId) {
    let params = CustomHttpParams.getInstance();
    params = params.append('ParticipationId', participationId);
    params = params.append('FiscalYear', String(this.filterService.selectedFiscalYear));
    return this.dataService.get<any[]>(this.endpoint.GetCagrAwardsData.uri, params);
  }

  getTotalCloudGrowthId(participationId) {
    let params = CustomHttpParams.getInstance();
    params = params.append('ParticipationId', participationId);
    params = params.append('FiscalYear', String(this.filterService.selectedFiscalYear));
    return this.dataService.get<any[]>(this.endpoint.GetCagrTotalCloudGrowthId.uri, params);
  }
}
