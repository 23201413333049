import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleFeatureService } from '@mint-libs/context';
import { UserProfileService } from '../context/user-profile.service';

@Injectable()
export class BonusRouteGuard implements CanActivate {
  constructor(public userProfileService: UserProfileService, private rolefeatureService: RoleFeatureService, private _router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.rolefeatureService.isPageApplicable(this.rolefeatureService.pages.ConsolidatedBonus)) {
      this._router.navigate(['/login']);
      return false;
    }

    return false;
  }
}
