import { Partition } from './partition.model';
import { Expose, Type } from 'class-transformer';
import { FiscalYear } from './fiscal-year.model';
import { Duration } from './duration.model';

export class StandardTitle {
  @Expose()
  payeeId: number;
  @Type(() => FiscalYear)
  @Expose()
  fiscalYear: FiscalYear;
  @Expose()
  code: string;
  @Expose()
  name: string;
  @Expose()
  startDate: Date | string;
  @Expose()
  endDate: Date | string;
  @Type(() => Duration)
  @Expose()
  duration: Duration;
  @Expose()
  planId: number;
  @Expose()
  planName: string;
  @Expose()
  planStartDate: Date | string;
  @Expose()
  planEndDate: Date | string;
  @Expose()
  participationID: number;
  @Type(() => Partition)
  @Expose()
  applicablePartitions: Partition[];
  @Expose()
  countryName: string;
  @Expose()
  isPlanActive: boolean;
  @Expose()
  isPlanRollOver: boolean;
  @Expose()
  isMic2Profile: boolean;
  @Expose()
  isParticipationCompliant: boolean;

  @Expose()
  minStep: number;
  @Expose()
  maxStep: number;

  @Expose()
  mic2Role: any;
}
