import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatureAdminRoutingModule } from './feature-admin-routing.module';
import { FeatureIntroductionComponent } from './feature-introduction/feature-introduction.component';
import { FeatureAdminComponent } from './feature-admin.component';
import { FeatureAdminService } from './feature-admin.service';
// import { CKEditorModule } from 'ckeditor4-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RowShortner } from './feature-table-pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, NgbModule, AngularEditorModule, NgxSpinnerModule, NgMultiSelectDropDownModule.forRoot(), FeatureAdminRoutingModule, HttpClientModule],
  declarations: [FeatureAdminComponent, FeatureIntroductionComponent, RowShortner],
  providers: [FeatureAdminService]
})
export class FeatureAdminModule {}
