import { Formatter } from '@mint-libs/common';
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'mint-grid-bonus-metric-renderer',
  template: `
    <div style=" font-size: 14px;display: flex;align-items: center;position: relative;" *ngIf="params.value">
      {{ params.value }}
      <div class="stat__info" style="position: relative;">
        <div class="icon icon-info" *ngIf="params.data.tooltip"></div>
        <div class="stat__tooltip stat__tooltip-tt-right stat__tooltip-left-tt-right">
          <p class="stat__tooltip__text">{{ params.data.tooltip }}</p>
        </div>
      </div>
    </div>
    <div style="font-size: 11px; margin-top: 10px;" *ngIf="params.data.PCGText">{{ params.data.PCGText }}</div>
  `
})
export class BonusMetricRendererComponent implements ICellRendererAngularComp {
  params: any;
  thresholdInfo: any;
  public shouldShowThresholdInfo = false;
  public data: number;
  constructor(private formatter: Formatter) {}
  agInit(params): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
