import { Inject, Injectable } from '@angular/core';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { LoggerService } from '@mint-libs/logging';
import { UserProfileService } from '../context/user-profile.service';
import * as Ng1Services from '../hybrid/ng1-upgraded-providers';

import { FiltersService } from '../../filters/filters.service';
import { EventQueueService } from '../broadcast/event-queue.service';
import { AppEvent } from '../broadcast/app-event';
import { AppEventType } from '../broadcast/app-event-type.enum';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public sharedContext = new SharedContext();
  constructor(
    private loggingService: LoggerService,
    private filterService: FiltersService,
    public sharedContextState: Store<SharedContext>,
    private eventQueue: EventQueueService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  //       var navManager = this;
  // navManager.filterService = filterService;
  // navManager.userProfile = userProfile;
  // navManager.sharedContext = sharedContext;

  public helpSubmenuList = [
    { name: 'FAQs', active: false, index: 0 },
    { name: 'Training Materials', active: false, index: 1 },
    { name: 'Glossary', active: false, index: 2 },
    { name: 'Accessibility Help', active: false, index: 3 }
    //{ name: 'Chat with bot', htmlfile: '/dist/app/features/help/bot.html', active: false }
  ];
  public compSubmenuList = [
    { name: 'My Compliance', active: false, index: 0 },
    { name: 'Quota Acknowledgement', active: false, index: 1 },
    { name: 'Plan Participation Agreement', active: false, index: 2 }
  ];

  // public reportSubmenuList = [
  //   { name: 'Attainment by Bucket', active: false, htmlfile: 'features/reports/attainmentByPCG.html', index: 0 },
  //   { name: 'Payout Details', active: false, htmlfile: 'features/reports/payoutDetailedMetrics.html', index: 1 },
  //   { name: 'Target and Actuals by Incentive Territory', active: false, htmlfile: 'features/reports/revenueTerritoryDetail.html', index: 2 },
  //   { name: 'Compensation Actuals by Product', active: false, htmlfile: 'features/reports/productRevenueDetailedMetrics.html', index: 3 },
  //   { name: 'Compensation Actuals by Account', active: false, htmlfile: 'features/reports/revenueAccountDetails.html', index: 4 },
  //   //hiding Actuals Analytics report
  //   // { name: 'Actuals Analytics (Beta)', active: false, htmlfile: 'features/reports/customReport.html', index: 5 },
  //   { name: 'Quota Details', active: false, htmlfile: 'features/compliance/quotaAcknowledgement/quotaAcknowledgement.html', index: 6 },
  //   { name: 'Dynamics Committed Growth by Account', active: false, htmlfile: 'features/reports/accountDynamicsCommittedGrowth.html', index: 7 },
  // ];

  public ngReportsSubMenuList = [
    { name: 'Attainment by Bucket', active: false, index: 0 },
    { name: 'Payout Details', active: false, index: 1 },
    { name: 'Compensation Actuals by Product', active: false, index: 2 },
    { name: 'Target and Actuals by Incentive Territory', active: false, index: 3 },
    { name: 'Compensation Actuals by Account', active: false, index: 4 },
    { name: 'Quota Details', active: false, index: 6 }
    // { name: 'Dynamics Committed Growth by Account', active: false, index: 7 }
  ];

  public ppiReportSubmenuList = [
    { name: 'Payout Report', active: false, index: 0 },
    { name: 'Target Detail Report', active: false, index: 1 }
  ];

  public ubiReportSubmenuList = [
    { name: 'Payout Details', active: false, index: 0 },
    { name: 'UBI Detailed Utilization Hours', active: false, index: 1 },
    { name: 'Target Detail Report', active: false, index: 2 },
    { name: 'Consumption Metric Report', active: false, index: 3 }
  ];

  public getNgReportsSubMenuList() {
    if (this.sharedContext.incentiveType === 'PPI') {
      if (this.filterService && this.filterService.selectedFiscalYear < 2021) {
        this.ngReportsSubMenuList = this.ppiReportSubmenuList.filter(item => item.name !== 'Target Detail Report');
      } else {
        this.ngReportsSubMenuList = this.ppiReportSubmenuList;
      }
    } else if (this.sharedContext.incentiveType === 'UBI') {
      if (this.filterService && this.filterService.selectedFiscalYear < 2021) {
        this.ngReportsSubMenuList = this.ubiReportSubmenuList.filter(item => item.name !== 'Target Detail Report' && item.name !== 'Consumption Metric Report');
      } else if (this.filterService && this.filterService.selectedFiscalYear >= 2021 && this.filterService.isSelectedFiscalYearLessThan2024()){
        this.ngReportsSubMenuList = this.ubiReportSubmenuList;
        let index = this.ngReportsSubMenuList.findIndex(item => item.name === 'Target Detail Report');
        if(index>=0){
          this.ngReportsSubMenuList[index].name = 'Quota and Target Detail Report';
        }
      } else {
        this.ngReportsSubMenuList = this.ubiReportSubmenuList;
        let index = this.ngReportsSubMenuList.findIndex(item => item.name === 'Quota and Target Detail Report');
        if(index>=0){
          this.ngReportsSubMenuList[index].name = 'Target Detail Report';
        }
      }
    }
    return this.ngReportsSubMenuList;
  }

  public checkIfReportSubmenuAvailable(tab) {
    if (tab.index === 7 && !this.sharedContext.IsDMYEligible) return false;
    return true;
  }

  public submenuList = [
    // { name: 'reports', active: false, height: '0px', maxHeight: this.getReportSubmenuList().length * 40 + 'px', menuList: this.getReportSubmenuList() },
    { name: 'reports', active: false, height: '0px', maxHeight: this.getNgReportsSubMenuList().length * 40 + 'px', menuList: this.getNgReportsSubMenuList() },
    { name: 'complianceDashboard', active: false, height: '0px', maxHeight: this.compSubmenuList.length * 40 + 'px', menuList: this.compSubmenuList },
    { name: 'help', active: false, height: '0px', maxHeight: this.helpSubmenuList.length * 40 + 'px', menuList: this.helpSubmenuList },
    { name: 'bonusv2', active: false, height: '0px', maxHeight: 0, menuList: [] }
  ];

  public submenuObj = {
    report: this.submenuList[0],
    compliance: this.submenuList[1],
    help: this.submenuList[2],
    ngReports: this.submenuList[3],
    bonusv2: this.submenuList[4]
  };

  public markMenuList(viewName) {
    this.updateReportsSubmenu(viewName);
    this.updateHomeReportSubmenu(viewName);

    this.submenuList.forEach(sm => {
      if (sm.name == viewName) {
        if (sm.active) {
          sm.active = false;
          sm.height = '0px';
        } else {
          sm.active = true;
          sm.height = sm.maxHeight as string;
        }
      }
    });
  }

  public updateReportsSubmenu(viewName) {
    var menuItem = this.submenuList.find(item => viewName == 'reports' && item.name == viewName);
    //   _.find(this.submenuList, function (item) {
    //   return viewName == 'reports' && item.name == viewName;
    // });
    if (menuItem) {
      menuItem.maxHeight = this.getNgReportsSubMenuList().length * 40 + 'px';
    }
  }

  public updateHomeReportSubmenu(viewName) {
    let menuItem = this.submenuList.find(submenu => submenu.name === 'reports') || ({} as any);
    menuItem.maxHeight = this.getNgReportsSubMenuList().length * 40 + 'px';
  }

  public markAllMenuList() {
    if (this.sharedContext.incentiveType === 'RBI') {
      this.compSubmenuList[1].name = 'Quota Acknowledgement';
    } else if (this.sharedContext.incentiveType === 'UBI' && this.filterService.isFSA() 
        && this.filterService.isSelectedFiscalYearLessThan2024()) {
      this.compSubmenuList[1].name = 'Quota and Target Acknowledgement';
    } else {
      this.compSubmenuList[1].name = 'Target Acknowledgement';
    }
    this.submenuList.forEach(sm => {
      if (sm.menuList.some(x => x.active)) {
        sm.active = true;
        sm.height = sm.maxHeight as string;
      } else {
        sm.active = false;
        sm.height = '0px';
      }
      sm.maxHeight = sm.menuList.length * 40 + 'px';
    });
  }

  public handleOptOut() {
    if (
      this.sharedContext.optoutDetails.isEligible &&
      (this.sharedContext.incentiveType === 'RBI' || this.sharedContext.incentiveType === 'UBI') &&
      !this.compSubmenuList.some(sm => sm.name === 'Opt Out')
    ) {
      this.compSubmenuList.push({ name: 'Opt Out', active: false, index: 3 });
    }
    this.submenuList[1].maxHeight = this.compSubmenuList.length * 40 + 'px';
  }

  public resetHeightsOfAllMenus() {
    this.submenuList.forEach(sm => {
      sm.active = false;
      sm.height = '0px';
    });
  }

  public resetSidebar() {
    this.resetHeightsOfAllMenus();
    this.resetActiveParamsOfAllMenus();
  }

  public getSpecificSM(menu, smName) {
    switch (menu) {
      case 'report':
        return this.ngReportsSubMenuList.filter(sm => sm.name === smName)[0];
      case 'complianceDashboard':
        return this.compSubmenuList.filter(sm => sm.name === smName)[0];
      case 'help':
        return this.helpSubmenuList.filter(sm => sm.name === smName)[0];
      case 'smlist':
        return this.submenuList.filter(sm => sm.name === smName)[0];
    }
  }

  // window.addEventListener('navigating', function(args) {
  //     var angularPromise = $state.go(args.detail.location, args.detail.state);
  //     args.detail.setPromise(PromiseFactory.toWinJSPromise(angularPromise));
  //   });

  public backButtonPressed(args) {
    this.spinner.hide();
    args.handled = true;
    if (this.canGoBack()) {
      return this.goBack();
    }
  }

  public resetActiveParamsOfAllMenus() {
    this.ngReportsSubMenuList.forEach(sm => (sm.active = false));
    this.helpSubmenuList.forEach(sm => (sm.active = false));
    this.compSubmenuList.forEach(sm => (sm.active = false));
    this.ubiReportSubmenuList.forEach(sm => (sm.active = false));
    this.ppiReportSubmenuList.forEach(sm => (sm.active = false));
  }

  public markActiveParamsOfSubMenu(view, submenu) {
    if (view == 'help') {
      this.helpSubmenuList.forEach(sm => (sm.active = sm.name === submenu.name ? true : false));
    }
    if (view == 'complianceDashboard') {
      this.compSubmenuList.forEach(sm => (sm.active = sm.name === submenu.name ? true : false));
    }
    if (view == 'reports') {
      this.getNgReportsSubMenuList().forEach(sm => (sm.active = sm.name === submenu.name ? true : false));
    }
  }

  private broadcastSubMenuTab(broadcastMethodName, index) {
    this.eventQueue.dispatch(new AppEvent(broadcastMethodName, index));
    // $rootScope.$broadcast(broadcastMethodName, index);
  }

  public navigateToOptout() {
    // window.history.location = 'Opt Out';
    // this.navigateToSubView('complianceDashboard', this.compSubmenuList[3]);
  }

  public navigateToSubView(view, submenu) {
    this.resetActiveParamsOfAllMenus();
    // window.history.current.location = submenu.name;
    this.navigateTo(view, { id: submenu.index }, false);
    this.markActiveParamsOfSubMenu(view, submenu);
    if (view == 'help') {
      this.broadcastSubMenuTab('LoadSpecificHelp', submenu.index);
    }
    if (view == 'complianceDashboard') {
      this.eventQueue.dispatch(new AppEvent(AppEventType.LoadSpecificComp, submenu.index));
      // $rootScope.$broadcast('LoadSpecificComp', submenu.index);
    }
  }

  public navigateTo(view, data?: any, hardRefresh?: boolean): Promise<boolean> {
    //$analytics.pageTrack(view);
    let viewToNavigateTo = view;
    if (Array.isArray(view)) {
      viewToNavigateTo = view.join('/');
      if (!viewToNavigateTo || viewToNavigateTo[0] !== '/') {
        viewToNavigateTo = `/${viewToNavigateTo}`;
      }
    }

    //Added check to avoid adding same view to the navigation history
    if (window.history.state && window.history.state.location === viewToNavigateTo && !hardRefresh) {
      return Promise.resolve(false);
    }
    if (window.location && window.location.pathname === viewToNavigateTo && !hardRefresh) {
      return Promise.resolve(false);
    }
    //loggingService.trackPageView(view);

    //this.eventQueue.dispatch(new AppEvent(AppEventType.NavDirection, 'forward'));
    var promise = this.router.navigate(typeof view === 'string' ? [view] : view, { queryParams: data });
    promise.then(function(response) {
      if (hardRefresh) {
        window.location.reload();
      }
    });
    return promise;
    // return Promise.resolve(true);
  }

  public navigateToPage(page: string) {
    // window.location.href = page;
  }

  public clearHistoryAndNavigateTo(view, data?: any) {
    this.clear();
    return this.navigateTo(view, data, true);
  }

  public state() {
    return window.history.state;
  }

  public goBack() {
    window.history.back();
  }

  public goForward() {
    this.eventQueue.dispatch(new AppEvent(AppEventType.NavDirection, 'forward'));
    // $rootScope.$broadcast('navdirection', 'forward');
    window.history.forward();
  }

  public current() {
    return window.history;
  }

  public canGoBack() {
    return this.canGoBack;
  }

  public canGoForward() {
    return window.history.length > 1;
  }

  public clear() {
    window.history.replaceState('', '');
  }

  public pop() {
    if (this.canGoBack()) {
      window.history.back();
    }
  }

  public history() {
    return window.history;
  }
}
