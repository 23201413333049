<div class="app__container">

  <mint-content-loader-icon-text *ngIf="!isDataLoaded"></mint-content-loader-icon-text>
  <mint-content-loader-bar-chart-horizontal *ngIf="!isDataLoaded"></mint-content-loader-bar-chart-horizontal>
  <div class="programDuration">
     <u> Program Duration: <i> {{bonusParticipationDetails.EarningsStartDateStr()}} - {{bonusParticipationDetails.EarningsEndDateStr()}}</i>
</u></div>
  <div>
    <div>
      <mint-3y3c-bonus-pct  class="pcg__items" [bonusComponents]="bonusParticipationDetails.BonusAttributes" [awardSummary]="awardSummary" ></mint-3y3c-bonus-pct>
    </div>
  </div>
  <div >         
    <mint-bonus-seller *ngIf="isDataLoaded"  [bonusParticipationDetails]="bonusParticipationDetails"></mint-bonus-seller>
    <!-- <mint-cagr-seller *ngIf="isDataLoaded && activeTabid == cagrParticipation.cagrParticipationId" [isDemoMode3]="isDemoMode3" [cagrParticipation]="cagrParticipation"></mint-cagr-seller> -->
  </div>
</div>
