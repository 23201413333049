import { Injectable } from '@angular/core';
import { TooltipTextBonusPayoutSummary } from './tooltip-text-bonus-payout-summary';
import { UserProfileService } from 'src/app/core/context/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class BonusPayoutSummaryHelperService {
  columnDefs: any;
  constructor(private tooltipTextBonusPayoutSummary: TooltipTextBonusPayoutSummary, public userProfileService: UserProfileService) {
  }

  getColumnDefs() {
    
    this.columnDefs = [
      {
        field: 'codePeriod',
        lockPosition: true,
        suppressNavigable: true,
        headerName: 'Time Frame',
        cellRenderer: 'payoutReportLinkRenderer',

        cellClassRules: {
          'payout-not-done': '!data.payoutDone',
          'payout-done': 'data.payoutDone'
        },
        minWidth: 110,
        tooltipField: this.userProfileService.isUBIPersona()
          ? this.tooltipTextBonusPayoutSummary.TimeFrame_Semester
          : this.userProfileService.isPPIPersona()
          ? this.tooltipTextBonusPayoutSummary.TimeFrame_Semester
          : this.tooltipTextBonusPayoutSummary.TimeFrame_Quarter
      }
    ];
   
    this.columnDefs.push(
      // comment after ag-grid enterprise is setup
      {
        field: 'yTDBonusEarnings',
        lockPosition: true,
        suppressNavigable: true,
        headerName: 'YTD Bonus Earnings (A)',
        minWidth: 135,
        tooltipField: this.tooltipTextBonusPayoutSummary.YTDBonusEarnings
      },
      {
        field: 'prevQuarterPayout',
        lockPosition: true,
        suppressNavigable: true,
        headerName: this.userProfileService.isRBIPersona() ? 'Paid in Previous Quarter (B)' : 'Paid Previously',
        minWidth: 190,
        tooltipField: this.userProfileService.isUBIPersona()
          ? this.tooltipTextBonusPayoutSummary.PreviousPayout_UBI_PPI
          :this.userProfileService.isPPIPersona()
          ? this.tooltipTextBonusPayoutSummary.PreviousPayout_UBI_PPI
          : this.tooltipTextBonusPayoutSummary.PreviousQuarterPayout
      },
      {
        field: 'ytdPaymentAdjustmentAndRecoveries',
        lockPosition: true,
        suppressNavigable: true,
        headerName: 'YTD Payment Adjustments and Recoveries (C)',
        minWidth: 290,
        tooltipField: this.userProfileService.isUBIPersona()
          ? this.tooltipTextBonusPayoutSummary.YtdPaymentAdjustmentAndRecoveries_UBI_PPI
          :this.userProfileService.isPPIPersona()
          ? this.tooltipTextBonusPayoutSummary.YtdPaymentAdjustmentAndRecoveries_UBI_PPI
          : this.tooltipTextBonusPayoutSummary.YtdPaymentAdjustmentAndRecoveries
      },
      {
        field: 'netPaymentAmount',
        lockPosition: true,
        suppressNavigable: true,
        headerName: 'Net Payment (A - B + C)',
        minWidth: 180,
        tooltipField: this.userProfileService.isRBIPersona() ? this.tooltipTextBonusPayoutSummary.NetPaymentAmount : this.tooltipTextBonusPayoutSummary.NetPaymentAmount_UBI_PPI
      },
      {
        field: 'calcDate',
        lockPosition: true,
        suppressNavigable: true,
        headerName: 'Calculation Date',
        minWidth: 145,
        tooltipField: this.userProfileService.isUBIPersona()
          ? this.tooltipTextBonusPayoutSummary.CalculationDate_UBI
          :this.userProfileService.isPPIPersona()
          ? this.tooltipTextBonusPayoutSummary.CalculationDate_PPI
          : this.tooltipTextBonusPayoutSummary.CalculationDate
      }
    );

    return this.columnDefs;
  }
}
