<content-loader>
  <svg:rect x="0" y="0" rx="3" ry="3" width="250" height="5" />
  <svg:rect x="20" y="10" rx="3" ry="3" width="220" height="5" />
  <svg:rect x="20" y="20" rx="3" ry="3" width="170" height="5" />
  <svg:rect x="0" y="30" rx="3" ry="3" width="250" height="5" />
  <svg:rect x="20" y="40" rx="3" ry="3" width="200" height="5" />
  <svg:rect x="20" y="50" rx="3" ry="3" width="80" height="5" />
  <svg:rect x="0" y="60" rx="3" ry="3" width="250" height="5" />
  <svg:rect x="20" y="70" rx="3" ry="3" width="220" height="5" />
  <svg:rect x="20" y="80" rx="3" ry="3" width="170" height="5" />
  <svg:rect x="0" y="90" rx="3" ry="3" width="250" height="5" />
  <svg:rect x="20" y="100" rx="3" ry="3" width="200" height="5" />
  <svg:rect x="20" y="110" rx="3" ry="3" width="80" height="5" />
</content-loader>
