import { Injector } from '@angular/core';
import { ClassTransformOptions, Exclude } from 'class-transformer';
import { Formatter } from '../../utilities/formatter';
const injector = Injector.create({
  providers: [
    {
      provide: Formatter,
      deps: []
    }
  ]
});

export const smallLetterKeys = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map(item => smallLetterKeys(item));
  }
  if (typeof obj !== 'object' || !obj) {
    return obj;
  }

  let smallObj = {};
  const keys = Object.keys(obj).concat();

  for (let i = 0; i < keys.length; i++) {
    const val = smallLetterKeys(obj[keys[i]]);
    smallObj[keys[i][0].toLowerCase() + keys[i].slice(1)] = val;
  }
  return smallObj;
};

export abstract class BaseModel {
  @Exclude() protected formatter: Formatter;
  @Exclude() protected classTransformOptions: ClassTransformOptions;
  constructor() {
    this.formatter = injector.get(Formatter);
    this.classTransformOptions = { strategy: 'excludeAll' };
  }
}
