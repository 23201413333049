import { UrlHandlingStrategy } from '@angular/router';

export class CustomUrlHandlingStrategy implements UrlHandlingStrategy {
  ngRoutes: Array<string> = [
    '/admin',
    '/error',
    '/dashboard',
    '/loginV2',
    '/reports',
    '/accelerateazure',
    '/compensationPlan',
    '/ytdDashboard',
    '/managerDashboard',
    '/complianceV2',
    '/complianceDashboard',
    '/demo',
    '/ppiCompensationPlan',
    '/ppiDashboard',
    '/cagr',
    '/notifications',
    '/mic2Reports',
    '/disclaimers',
    '/featureAdmin',
    '/mqch',
    '/manageNotification',
    '/planActivation',
    '/bonus',
    '/bonusv2',
    '/help',
    '/settings'
  ];
  shouldProcessUrl(url) {
    const shouldProcess: boolean = this.ngRoutes.some(function(route) {
      return url.toString().startsWith(route) && url.toString().indexOf('V1') === -1;
    });
    // console.log('CustomUrlHandlingStrategy: shouldProcess: ' + shouldProcess + ' URL : ' + url.toString());
    return shouldProcess;
  }
  extract(url) {
    return url;
  }
  merge(url, whole) {
    return url;
  }
}
