import { Component, Input, OnInit } from '@angular/core';
import { Direct } from '../models/direct';
import * as _ from 'underscore';

@Component({
  selector: 'mint-myteam-tiles',
  templateUrl: './myteam-tiles.component.html',
  styleUrls: ['./myteam-tiles.component.scss']
})
export class MyTeamTilesComponent implements OnInit {
  @Input() directs: Array<Direct>;

  countUnSignedPPA = 0;
  countUnSignedQA = 0;
  countUtilizationLt = 0;

  constructor() {}

  ngOnInit() {
    this.countUnSignedPPA = this.directs.filter(dir => dir.isPPAComplete === false && dir.currentParticipation !== 0).length;

    this.countUnSignedQA = this.directs.filter(dir => dir.isQAAccepted === false && dir.currentParticipation !== 0).length;

    this.countUtilizationLt = this.directs.filter(dir => (dir.currentParticipation !== 0 ? dir.ubiActiveAttainmentPercentage < 100 : 0)).length;
  }
}
