export class SharedContext {
  public appliedFilter: any = {
    fiscalYear: null,
    standardTitle: null,
    partition: null,
    stepId: null
  };

  public fiscalYearVersion = {};
  public paylineViewType = null;
  public currency = {
    symbol: '$',
    code: 'USD'
  };
  public payoutCurrency = '';
  public loggedInUserInfo = {
    isSupportAdmin: false,
    isSuperAdmin: false,
    isFCA: false,
    userAlias: null,
    userName: null
  };
  public usageMode = {
    impersonation: false,
    viewAs: false
  };
  public users = [];
  public totalCacheCount = 0;
  public totalCacheLimit = 20;
  public isDemoMode = false;
  public isDemoMode2 = false;
  public isDemoMode3 = false;
  public managerLevel = null;
  public toggleView = 0;
  public toggleQAView = 0;
  public pageTitle = '';
  public language = 'en';
  public reportDownloadLocation = '';
  public appVersion = '';
  public terminationReason = null;
  public terminationDate = null;
  public aiInEligibleTerminationDate = null;
  public isAdmin = false;
  public isSuperAdmin = false;
  public currentTabIndex = 0;
  public publicationId = 0;
  public businessSwitchEnabledFeatures = [];
  public isNavigationFromPayoutSummary = false;
  public unreadCount = 0;
  public totalCount = 0;
  public hasTour = false;
  public isOptOutBannerClosed = true;
  public skipAuthorization = false;
  public isQuotaAckEnabled = false;
  public userProfileInfo: any;
  public canCreateTour = false;
  public msSalesRefreshDateTime = '';
  public current: any;

  public trackName = 'impersonation';
  public fileName = 'impersonationListWeb';

  public isImpersonating = false;
  public isViewAs = false;
  public impersonatingAlias: null | string = null;
  public impersonatedParticipant: null | string = null;
  public impersonatedParticipantInitials: null | string = null;
  public impersonationBeingStopped = false;
  public loggedInUser: null | string = null;
  public isNameAvailable = false;
  public showFCAQuotaApprovalPendingError = false;
  public fcaQuotaApprovalPendingButAllowAccess = false;
  public alias: string = '';

  public emailId = '';
  public firstName = '';
  public lastName = '';
  public personnelNumber: number;
  public country = '';
  public segment = '';
  public role = '';
  public isASeller = false;
  public isSeller = false;
  public isManager = false;
  public agreementDetails = [];
  public isQuotaAcknowledged: any = false;
  public isPPAAvailable: any = false;
  public isPPASigned: any = false;
  public isRollOver: any = false;
  public quotaNotAckNotification = '';
  public isQuotaAckPostponed: any = false;
  public incentivePlanType = '';
  public incentiveType = '';
  public Settings = {
    IsDemoAdmin: false,
    IsSuperAdmin: false,
    IsSupportAdmin: false,
    IsEnrolledIntoGroup: false,
    CanSignOnBehalf: false,
    ReleaseGroupName: '',
    ReleaseGroupId: -1,
    WidgetsRole: [],
    UserRolePermissions: [],
    Disclaimer: {
      DisclaimerId: 0,
      AcceptedDate: 0
    }
  }; //Have widgets, default widgets, role based features;
  public canSignOnBehalf = false;
  public isLoggedInUserAdmin = false;
  public areAllTrainingsCompleted = false;
  public isPreviewUser = false;
  public releaseGroupName = '';
  public releaseGroupId = -1;
  public isEnrolledIntoGroup = false;
  public optoutDetails = {
    isOptOut: false,
    optOutDate: '',
    isEligible: false,
    isPreviousParticipation: false,
    optOutStartDate: '',
    optOutEndDate: '',
    nextOptOutStartDate: '',
    nextOptOutEndDate: '',
    partcipationEndDate: '',
    wasEligibleInPreviousCountry: false,
    didOptOutInPreviousCountry: false,
    isEligibleInNextCountry: false
  };
  public isACREligible = false;
  public areCustomerAddsMetricsApplicable = false;
  public IsSoftCapReviewWindowOpen = false;
  public IsSoftCapReviewAuthorized = true;
  public IsCagrEligible = false;
  public managerDashboardPartitionsList = [];
  public ManagerViewFiscalYears: number[] = [];
  public IsDMYEligible: any = false;
  public IsNonParticipantManager = false;
  public isOptOut: any = false;
  public FeatureRole = '';

  public currentBonusProgramId = '';
  public IsFCA = false;
  public aiFilters = '';
  public ArePayoutGatesMetricsApplicable = false;
  public IsNonParticipantManagerForced = false;
  public StandardTitles: any[] = [];
  public awardConsideration = '';
  public IsNonWwicAndMic2Eligible = false;
  public QuotaFCAApprovalPending = false;
  public msSalesRefreshDate = '';
  public DefaultFilter: any = {
    FiscalYear: -1,
    FiscalQuarterId: -1,
    FiscalSemesterId: -1,
    Partition: '',
    StandardTitle: ''
  };
  public FiscalYears: number[] = [];
  public Compliance = '';
  public Pages = [];
  public Features = [];
}
