import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BaseRevenueComponent } from '../base-revenue.component';
import { ReportType } from '../report-type.enum';
import { ReportsService } from '../reports.service';
import { MessagesService } from '@mint-libs/context';
import { Formatter } from '@mint-libs/common';
import { Code } from '../../app.constants';
import { RevenueAccount } from '../reports-shared/models/revenue-account.model';
import { ReportGrid } from '../reports-shared/models/report-grid.model';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { ExportToExcelService } from '../../shared/excel-export/export-to-excel.service';
import { ExcelConfigs } from '../../shared/excel-export/excel-config.model';
import { FiltersService } from '../../filters/filters.service';
import { LoggerService } from '@mint-libs/logging';

@Component({
  selector: 'mint-account-dynamics-committed-growth',
  templateUrl: './account-dynamics-committed-growth.component.html',
  styleUrls: ['./account-dynamics-committed-growth.component.scss'],
  providers: [Code]
})
export class AccountDynamicsCommittedGrowthComponent extends BaseRevenueComponent implements OnInit, OnDestroy {
  public defaultTabId = 2;
  public activeTabId = 2;
  public filterTabId = 4;
  public gridPageSize = 5;

  public filterTab: any;
  public tabs = [
    {
      id: 1,
      title: 'All Accounts',
      rowCount: null,
      disabled: true,
      dimList: null
    },
    {
      id: 2,
      title: 'Top ',
      rowCount: 100,
      disabled: false,
      dimList: null
    },
    {
      id: 3,
      title: 'Top ',
      rowCount: 200,
      disabled: false,
      dimList: null
    },
    {
      id: 4,
      title: 'Search Results ',
      rowCount: 0,
      disabled: true,
      dimList: []
    }
  ];
  revenueGridColumns: ReportGrid[] = [
    {
      field: 'name',
      columnName: 'Account Name',
      width: 180,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'pccName',
      columnName: 'Metric Name',
      width: 180,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.fiscalYear',
      columnName: 'Fiscal Year',
      width: 120,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.month',
      columnName: 'Fiscal Month',
      width: 120,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.totalActual',
      columnName: 'Billed + Scheduled <br> (as of last refresh)',
      width: 100,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.totalRecurringTarget',
      columnName: 'Scheduled Billing <br> (as of July 1)',
      width: 100,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.commitmentLanded',
      columnName: 'Value of Commitments Landed',
      width: 100,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.metric',
      columnName: 'Unit',
      width: 100,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    }
  ];
  targetGridColumns: ReportGrid[] = [
    {
      field: 'name',
      columnName: 'Account Name',
      width: 180,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'pccName',
      columnName: 'Metric Name',
      width: 180,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.fiscalYear',
      columnName: 'Fiscal Year',
      width: 120,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.month',
      columnName: 'Fiscal Month',
      format: '{0: MMMM, yyyy}',
      width: 120,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.totalTarget',
      columnName: 'Commitments Quota',
      width: 100,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.ytdQuota',
      columnName: 'YTD Commitments Quota',
      width: 100,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.metric',
      columnName: 'Unit',
      width: 100,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    }
  ];
  revenueData: RevenueAccount[] = [];
  targetData: RevenueAccount[] = [];

  targetExcelDownloadStatus: boolean;
  targetExcelDownloadMessage: string;
  selectedFiscalYearText: any;

  targetExcelConfigs: ExcelConfigs = new ExcelConfigs();
  constructor(
    public reportService: ReportsService,
    private formatter: Formatter,
    public messageService: MessagesService,
    public exportToExcelService: ExportToExcelService,
    public loggingService: LoggerService,
    public filterService: FiltersService
  ) {
    super(reportService, messageService, filterService);
    this.revenueReportType = ReportType.AccountDynamicsCommittedGrowthReport;
  }

  ngOnInit() {
    this.loggingService.startTrackPage('Report-Dynamics Committed Growth by Account');
    this.selectedFiscalYearText = this.filterService.getFormattedSelectedFiscalYear();
    this.filterTab = this.tabs.find(t => t.id === this.filterTabId);

    this.initTargetSummary(pcc => pcc.IsDynamicMetric);
    this.tabsChange(this.activeTabId);
  }

  ngOnDestroy() {
    this.loggingService.stopTrackPage('Report-Dynamics Committed Growth by Account');
  }

  private initDetailedAccounts(dimListName, rowCount) {
    this.reportService.getDetailedAccounts(dimListName, rowCount, this.revenueReportType).subscribe(response => {
      this.isLargeUser = response.isLargeUser;
      this.revenueData = new RevenueAccount().deserialize(response?.data);
      this.targetData = this.revenueData.filter(r => r.revenue.fiscalYear?.trim() === this.selectedFiscalYearText.trim());
      this.initPccChart(this.PCCs, this.selectedPccChart, ReportType.AccountDynamicsCommittedGrowthReport);
    });
  }

  public initRevenueChart(revenueData: RevenueAccount[]) {
    this.chartDataRevenue = [];
    const groupedByDimNameData = this.groupBy(revenueData, (r: RevenueAccount) => r.name);
    for (const dimName in groupedByDimNameData) {
      const revenueBarChartActual = {
        desc: dimName,
        columnType: 'actuals',
        val: 0,
        caddsval: 0,
        qval: 0
      };
      const revenueBarChartTarget = {
        desc: dimName,
        columnType: 'quota',
        val: 0,
        caddsval: 0,
        qval: 0
      };
      groupedByDimNameData[dimName].forEach(r => {
        revenueBarChartActual.val += r.revenue.commitmentLanded;
        revenueBarChartTarget.qval += r.revenue.totalTarget;
      });
      revenueBarChartActual.val = Math.trunc(revenueBarChartActual.val * 100) / 100;
      revenueBarChartTarget.qval = Math.trunc(revenueBarChartTarget.qval * 100) / 100;
      this.chartDataRevenue.push(revenueBarChartActual);
      this.chartDataRevenue.push(revenueBarChartTarget);
    }
    this.chartDataRevenue = this.chartDataRevenue.sort((a, b) => b.val - a.val);
  }

  public filterSelectedPcc(selectedPccChart) {
    this.selectedPccChart = selectedPccChart;
    let revenueDataFilteredByPcc = this.revenueData.filter(revenue => revenue.pccID === selectedPccChart?.id);
    if (selectedPccChart.isPointInTime) {
      revenueDataFilteredByPcc = revenueDataFilteredByPcc.filter(revenue => this.formatter.transformToDateFormat(revenue.revenue.month, 'MMMM, yyyy', 'en-US').split(',')[0] === this.getPITMonth());
    }
    this.initRevenueChart(revenueDataFilteredByPcc);
  }

  public search(queryText: string) {
    this.searchDimNames(queryText, ReportType.AccountReport);
  }

  public filterAccounts(selectedfilters) {
    this.filterTab.dimList = selectedfilters;
    this.filterTab.rowCount = selectedfilters?.length;

    if (selectedfilters?.length === 0 || !selectedfilters) {
      this.activeTabId = this.defaultTabId;
      this.tabsChange(this.activeTabId);
      this.filterTab.disabled = true;
      return;
    }
    this.activeTabId = this.filterTabId;
    this.filterTab.disabled = false;
    this.tabsChange(this.activeTabId);
  }

  public tabsChange(tabId) {
    this.activeTabId = tabId;
    const activeTab = this.tabs.find(t => t.id === this.activeTabId);
    this.initDetailedAccounts(activeTab.dimList, activeTab.rowCount);
  }

  onRevenueExcelExport(postBody) {
    this.revenueExcelConfigs.fileName = this.exportToExcelService.getFileName('DCG_ValueOfDealsLandedGrid', true);
    const $result = this.reportService.ExportDynamicsAccountRevenue(postBody);

    this.exportToExcelService.handleExcelDownload($result, this.revenueExcelConfigs);
  }

  onTargetExcelExport(postBody) {
    this.targetExcelConfigs.fileName = this.exportToExcelService.getFileName('DCG_CommittedQuotaGrid ', true);
    const $result = this.reportService.ExportDynamicsAccountTarget(postBody);

    this.exportToExcelService.handleExcelDownload($result, this.targetExcelConfigs);
  }
}
