<ng-template id="support_2" #splashTemplate>
  <div class="modal-header">
    <h3 class="modal-title" style="display: inline;">{{ displayFeatureGroup.featureHeader }}</h3>
    <button type="button" analytics-tracker [tracker]="'splash_close'" [type]="'Click'" [element]="'Button'" [source]="'splash component'" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul style="padding: 0; list-style-type:none;">
      <li *ngFor="let feature of displayFeatureGroup.featureIntroductions">
        <h4 *ngIf="feature.featureHead" [innerHTML]="feature.featureHead | safeHtml"></h4>
        <p *ngIf="feature.featureBody" [innerHTML]="feature.featureBody | safeHtml"></p>
        <div *ngIf="feature.videoLink" [innerHTML]="feature.videoLink | safeHtml"></div>
        <div *ngIf="feature.imageLink">
          <img src="{{ feature.imageLink }}" width="800" alt="image" />
        </div>

        <button
          *ngIf="feature.pageLink"
          analytics-tracker
          [tracker]="'splash_navigation_click'"
          [type]="'Click'"
          [element]="'Button'"
          [source]="'splash component'"
          style="  background-color: #4caf50; color: white; border: black; margin-top: 10px;"
          (click)="navigate(feature.pageLink)"
        >
          Get Started !
        </button>
      </li>
    </ul>
  </div>
</ng-template>
