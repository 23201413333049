import { Component, OnInit, Inject } from '@angular/core';
import { LocalStorageService } from '@mint-libs/network';
import { BonusState, TBonusConfig, TBonusData, selectBonusState, SelectBonus } from '@mint-libs/context';


import {LoggerService }  from '@mint-libs/logging';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../shared/base.component';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';

type NavbarDropdownItem = {
  id: string,
  tabText: string,
  key: string,
  active: boolean
};


@Component({
  selector: 'mint-bonus-v2',
  templateUrl: './bonus-v2.component.html',
  styleUrls: ['./bonus-v2.component.scss']
})
export class BonusV2Component implements OnInit {
  bonusState: BonusState = new BonusState();
  tabs: NavbarDropdownItem[] = [];
  activeTab: NavbarDropdownItem;
  isDataLoaded: boolean = false;
  activeDocumentId?: string;

  activeBonusDetails;
  bonusSummaryDetails;
  bonusAttributeDetails;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private bonusStore: Store<BonusState>,
    private loggingService: LoggerService,
  ) {
    this.setupBonusStore();
    this.getParamsFromRoute();
  }

  
  setupBonusStore() {
    this.bonusStore.select(selectBonusState).subscribe((bonusState: BonusState) => {
      this.checkForBonusConfigFetch(bonusState);
      this.bonusState = bonusState;
      this.isDataLoaded = this.checkIfBonusDataIsLoaded();
      this.populateTabs();

      this.activeBonusDetails = bonusState.getActiveBonusDetails();
      this.bonusSummaryDetails = bonusState.getSummaryDetails();
      this.bonusAttributeDetails = bonusState.getBonusAttributeDetails();
      
      if (this.activeBonusDetails) {
        this.loggingService.trackPageView('BonusV2', {
          BonusDisplayName: this.activeBonusDetails.bonusConfig?.bonusProgramDisplayName, 
          BonusName: this.activeBonusDetails.bonusConfig?.configuration?.bonusProgramName,
          BonusId: this.activeBonusDetails.bonusConfig?.id
        });
      }
    });
  }

  
  checkIfBonusDataIsLoaded(): boolean {
    return this.bonusState.getIsConfigFetched() && this.bonusState.getBonusData().findIndex((bData) => bData.id === this.bonusState.getSelectedBonusDocumentId()) >= 0;
  }

  populateTabs() {
    this.tabs = this.bonusState.getBonusConfig().map((config) => ({
      id: config.id,
      tabText: config.bonusProgramDisplayName,
      key: `key-${config.id}`,
      active: config.id === this.bonusState.getSelectedBonusDocumentId()
    }));
    this.activeTab = this.tabs.find((tab) => tab.active);
  }

  getParamsFromRoute() {
    this.route.queryParamMap.subscribe((params) => {
      this.activeDocumentId = params.get("id");
      if (this.bonusState.getIsConfigFetched()) {
        this.bonusStore.dispatch(new SelectBonus({ id: this.activeDocumentId }));
      }
    });
  }

  checkForBonusConfigFetch(bonusState: BonusState) {
    // Wait for bonus config to be fetched to set active document
    if (!this.bonusState.getIsConfigFetched() && bonusState.getIsConfigFetched()) {
      if (bonusState.getSelectedBonusDocumentId() !== this.activeDocumentId) {
        this.bonusStore.dispatch(new SelectBonus({ id: this.activeDocumentId }));
      }
    }
  }

  navigateToBonusRoute(id) {
    const url = this.router.createUrlTree([], {relativeTo: this.route, queryParams: {id: id}}).toString();
    this.location.go(url);
  }

  selectBonusDocument(id) {
    if (id !== this.bonusState.getSelectedBonusDocumentId()) {
      this.bonusStore.dispatch(new SelectBonus({ id }));
      this.navigateToBonusRoute(id);
    }
  }

  ngOnInit(): void {
  }

}


