import { OnInit, Inject, Component } from '@angular/core';
import * as Ng1Services from '../core/hybrid/ng1-upgraded-providers';
import { Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { LocalStorageService } from '@mint-libs/network';

import { environment } from '../../environments/environment';
import { FiltersService } from '../filters/filters.service';
import { UserProfileService } from '../core/context/user-profile.service';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from '../core/navigation/navigation.service';
import { ImpersonationService } from '../core/impersonation/impersonation.service';
import { InitializationService } from '../core/initialization/initialization.service';
import { UserSessionService } from '../core/session/user-session-service';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  template: ''
})
export class BaseComponent implements OnInit {
  loaded: false;
  canShowCurrentAttainment = false;
  isPageTourEnabled: boolean;
  public sharedContext = new SharedContext();
  constructor(
    public initializationService: InitializationService,
    public navigationService: NavigationService,
    public impersonationService: ImpersonationService,
    public sharedContextState: Store<SharedContext>,
    public userProfileService: UserProfileService,
    public rolefeatureService: RoleFeatureService,
    public filterService: FiltersService,
    public localStorage: LocalStorageService,
    public router: Router,
    public userSessionService: UserSessionService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      this.canShowCurrentAttainment = (this.sharedContext.isQuotaAcknowledged || this.sharedContext.isImpersonating) && this.sharedContext.isRollOver === true;
    });
    this.isPageTourEnabled = environment.isPageTourEnabled;
  }

  ngOnInit() {}

  applyFilter(route) {
    this.userSessionService.initialize(true, true, true, true);
    this.router.navigateByUrl(route, { skipLocationChange: true });
  }

  applyFilterAsync(route): Observable<any> {
    const observable = from(this.userSessionService.initialize(true, true, true, true));
    observable.subscribe(result => {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
      };
      this.router.navigateByUrl(route, { skipLocationChange: true });
    });

    return observable;
  }

  navigateTo(item, id, data) {
    this.navigationService.navigateTo(item, data);
  }

  navigateToMyTeamView() {
    this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, toggleView: 1 }));
    this.navigationService.navigateTo('dashboard');

    // this.navigation.navigateTo('managerDashboard');
  }
}
