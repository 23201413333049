import { EndpointService } from '@mint-libs/network';
import { DataService } from '../../core/network/data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MetricGridYtdService {
  constructor(private endpoint: EndpointService, private dataService: DataService) {}

  getTargetSummary(selectedMonth) {
    return this.dataService.getWithGlobalFiltersAndMonth<any[]>(this.endpoint.GetYtdTargetSummary.uri, this.endpoint.GetYtdTargetSummary.params);
  }

  getAllPaylines() {
    return this.dataService.getWithGlobalFiltersAndMonth<any[]>(this.endpoint.GetAllYtdPaylines.uri, this.endpoint.GetAllYtdPaylines.params);
  }
}
