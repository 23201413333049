import { Component, OnInit, Output, Inject } from '@angular/core';
import * as Ng1Services from '../core/hybrid/ng1-upgraded-providers';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { MetricGridService } from '../shared/metric-grid/metric-grid.service';
import { Formatter, PercentagePipe } from '@mint-libs/common';
import { MetricTile } from '../shared/models/metric-tile.model';
import { ToggleSwitchConfig } from '../shared/toggle-switch/toggle-switch-config';
import { UserProfileService } from '../core/context/user-profile.service';
import { from } from 'rxjs';
import { BaseDashboardComponent } from '../shared/base/base-dashboard.component';
import { Code } from '../app.constants';
import { ChartType } from '../shared/metric-chart/chartType.enum';
import { OcvFloodgateService } from '../ocv-feedback/ocv-floodgate.service';
import DashboardCampaign from '../ocv-feedback/DashboardCampaign';
import { MessagesService } from '@mint-libs/context';
import { LocalStorageService } from '@mint-libs/network';
import { FiltersService } from '../filters/filters.service';
import { LoggerService } from '@mint-libs/logging';

import { environment } from '../../environments/environment';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from '../core/navigation/navigation.service';
import { ImpersonationService } from '../core/impersonation/impersonation.service';
import { InitializationService } from '../core/initialization/initialization.service';
import { UserSessionService } from '../core/session/user-session-service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-dashboard',
  providers: [SharedService, MetricGridService, Code],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseDashboardComponent implements OnInit {
  newDashboardOn = false;
  featureId;
  pageId;
  pageUrl = 'dashboard';
  isMyTeamTeamApplicable: boolean;
  hasDirects: boolean;
  tabset: any;
  isGlobalFilterApplicable: boolean;
  dataset: any;
  gridView = false;

  ytdPayout: MetricTile = {};
  ytdNextPayout: MetricTile = {};
  weightedAttainment: MetricTile = {};
  emptyTile: MetricTile = {};

  filterService: any;

  showYTD: boolean;

  @Output() toggleSwitchConfigYtd: ToggleSwitchConfig;

  chartType: ChartType;
  activeTabId = this.gridView ? 'dashboard_grid' : 'dashboard_chart';

  constructor(
    private navigationService: NavigationService,
    public impersonationService: ImpersonationService,
    public sharedContextState: Store<SharedContext>,
    public rolefeatureService: RoleFeatureService,
    public initializationService: InitializationService,
    filterService: FiltersService,
    formatter: Formatter,
    sanitizer: DomSanitizer,
    public router: Router,
    percentagePipe: PercentagePipe,
    private sharedService: SharedService,
    private localStorage: LocalStorageService,
    private loggingService: LoggerService,
    public messageService: MessagesService,
    public userProfileService: UserProfileService,
    public userSessionService: UserSessionService,
    floodgateService: OcvFloodgateService
  ) {
    super(
      filterService,
      sanitizer,
      percentagePipe,
      formatter,
      initializationService,
      impersonationService,
      router,
      messageService,
      userProfileService,
      floodgateService,
      userSessionService,
      sharedContextState
    );
    this.impersonationService = impersonationService;
    this.initializationService = initializationService;
    this.filterService = filterService;
    this.featureId = rolefeatureService.features.DashboardV2;
    this.pageId = rolefeatureService.pages.Dashboard;
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.initializeToggleSwitchConfig();

    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      const isDMYEligible = this.sharedContext?.IsDMYEligible;
      this.showYTD = this.filterService.selectedFiscalYear >= 2019 && !isDMYEligible;
    });
    this.chartType = ChartType.Attainment;
  }

  initializeToggleSwitchConfig() {
    this.toggleSwitchConfigYtd = new ToggleSwitchConfig();
    this.toggleSwitchConfigYtd.target = 'ytdDashboard';
    this.toggleSwitchConfigYtd.title = 'Quarterly View';
    this.toggleSwitchConfigYtd.helpText = 'Quarterly view/Monthly view toggle';
    this.toggleSwitchConfigYtd.source = 'dashboardV2';
    this.toggleSwitchConfigYtd.isChecked = false;
    this.toggleSwitchConfigYtd.id = 'ytdDashboard';
    this.toggleSwitchConfigYtd.titleRight = 'Monthly View';
    this.toggleSwitchConfigYtd.toggleCssClass = 'ytd__lbl';
    this.toggleSwitchConfigYtd.tracker = 'Quarterly view/Monthly view toggle - Dashboard';
  }

  onTabChange(event) {
    this.gridView = event.nextId === 'dashboard_grid' ? true : false;
  }

  ngOnInit() {
    // tracks page view...
    this.loggingService.trackPageView('DashboardV2', { UserAlias: this.sharedContext.alias });

    this.hasDirects = this.sharedContext.isManager || this.sharedContext.IsNonParticipantManager;
    this.isMyTeamTeamApplicable =
      (this.hasDirects && !this.sharedContext.isImpersonating) ||
      (this.hasDirects && this.sharedContext.isAdmin && this.sharedContext.isImpersonating) ||
      (this.hasDirects && this.sharedContext.loggedInUserInfo.isFCA && this.sharedContext.isImpersonating);
    this.tabset = this.sharedContext.toggleView;

    if (
      this.sharedContext.IsNonParticipantManager ||
      this.sharedContext.IsNonParticipantManagerForced ||
      (this.sharedContext.isManager && (!this.sharedContext.isQuotaAcknowledged || !this.sharedContext.areAllTrainingsCompleted) && !this.sharedContext.isImpersonating)
    ) {
      this.tabset = 1;
    }

    this.isGlobalFilterApplicable = this.rolefeatureService.isFeatureApplicable(this.rolefeatureService.pages.Dashboard, this.rolefeatureService.features.GlobalFilter);

    if (!this.isMyInformationApplicable) {
      this.tabset = 1;
    }
    super.setFilterConfig(this.tabset);
    super.checkH2QuotaIsSupported();

    this.floodgateService.ActivateSurvey(DashboardCampaign);
  }

  toggleView(tabId) {
    this.tabset = tabId;
    this.initiaizeFilterConfig();
    super.setFilterConfig(this.tabset);
    super.checkH2QuotaIsSupported();

    this.loggingService.trackPageView('DashboardV2', { SubFeatureName: this.tabset === 1 ? 'My Team' : 'My Information' });
  }

  applyFilter(event: any) {
    if (this.filterService.selectedFiscalYear >= 2019) {
      super.applyFilters(this.router.url).subscribe(response => {});
    }
  }

  navigateTo(item, id, data) {
    this.localStorage.addToLocalStorage('dashboardV2Preference', false);
    this.navigationService.navigateTo(item, data);
  }
}
