import { MetricCardConfig } from './metric-card-config';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MetricPCG } from '../models/metric-pcg.model';
import { Router } from '@angular/router';
import { TooltipOrientation } from '../tooltip/tooltip-orientation.enum';

@Component({
  selector: 'app-metric-card',
  templateUrl: './metric-card.component.html',
  styleUrls: ['./metric-card.component.scss']
})
export class MetricCardComponent implements OnInit {
  @Input() componentId;
  @Input() cardData: MetricPCG;
  @Input() isYTD: boolean;

  @Input() metricCardConfig: MetricCardConfig;
  @Input() showProductList = false;
  /** Below FOUR properties are just used for communication between Parent component(Plan-Summary)
   * And Inner Child component product-list
   * In case this component will be use
   */
  /** This property will be passed into the INNER ProductList Component as is */
  @Input() commonProducts: Array<string>;
  /** This property will be passed into the INNER ProductList Component as is */
  @Input() otherProducts: Array<string>;
  /** This property will be passed into the INNER ProductList Component as is */
  @Input() highlightedProductName: string;
  @Input() conditionalClass: string;
  @Input() tooltipSize: any;
  attainmentTipOrientation = TooltipOrientation.Right;
  /** This event used BUBBLE up the signal to the parent component and other instances of ProductListComponent
   * about the change in Highlighted Product from the INNER ProductList Component
   */
  @Output() notifyProductSelection: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router) {
    this.metricCardConfig = new MetricCardConfig();
  }

  ngOnInit() { }

  /** This event will be called from Child Component ProductList and will be bubbled up to Parent Component
   */
  onProductSelectionInProductListComponent(productName: string) {
    this.notifyProductSelection.emit(productName);
  }

  checkIfEarningsIsVisible() {
    return !this.isYTD && this.metricCardConfig.shouldShowEarnings && this.cardData.earningsBeforeCap;
  }

  checkForConditionalTitleClass() {
    return this.router.url === '/compensationPlan';
  }
}
