<div *ngIf="tabs?.length">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" (activeIdChange)="tabChange($event)" class="nav-tabs" [keyboard]="true">
    <li role="none" [ngbNavItem]="i + 1" [disabled]="tab.disabled" *ngFor="let tab of tabs; let i = index">
      <a
        ngbNavLink
        analytics-tracker
        [tracker]="'DLTR_' + reportType + '_GetReportWithCustomCount'"
        [type]="'Click'"
        [element]="'AnchorTag'"
        [source]="'DetailReport' + reportType"
        [value]="tab.title + (tab.rowCount === null ? '' : tab.rowCount)"
        >{{ tab.title + (tab.rowCount === null ? '' : tab.rowCount) }}</a
      >
    </li>
  </ul>
</div>
