const MQCHCampaign: object = {
  Activity: 'OnQuotaChangeHistoryLoad',
  CampaignId: '997f9194-c4b7-4c62-b1dc-89a2eab6bedf', // Assigned by OCV
  StartTimeUtc: '2021-02-09T00:00:00Z', // If null, the campaign is inactive
  EndTimeUtc: '2021-04-01T00:00:00Z',
  GovernedChannelType: 0, // 1: Urgent Channel; 0 - Recommended for Production as it has channel cooldown
  NominationScheme: {
    Type: 0, // Default
    PercentageNumerator: 100, // Ratio of users to be nominated to be a candidate numerator
    PercentageDenominator: 100, // Ratio of users to be nominated to be a candidate denominator
    NominationPeriod: {
      Type: 0, // Default
      IntervalSeconds: 180 // NOT used when ratio is 100%
    },
    CooldownPeriod: {
      Type: 0, // Default
      IntervalSeconds: 1296000 // 15 days
    },
    FallbackSurveyDurationSeconds: 180
  },
  SurveyTemplate: {
    Type: 2, // NPS Template, can be NPS 5pts (0), NPS 11pts (1), FPS (2), NLQs (3), NPS (4), or GenericMessaging Survey (22)
    ActivationEvent: {
      Type: 0, // 0 For one event, 1 for multiple events (array of events)
      Activity: 'OnQuotaChangeHistoryLoad',
      Count: 1,
      IsAggregate: true
    },
    Content: {
      Prompt: {
        Title: 'How are we doing on Quota Traceability?',
        Question: 'Your feedback helps us create a seamless experience for you!',
        YesLabel: 'Yes',
        NoLabel: 'No'
      },
      Rating: {
        IsZeroBased: false,
        Question: 'How would you rate the current your quota understanding in Mint (My Incentive)?',
        RatingValuesAscending: ['1 = Very unclear', '2 = Somewhat unclear', '3 = Neither clear nor unclear', '4 = Somewhat clear', '5 = Very clear']
      },
      Question: { Question: 'Please tell us more about your choice of the answer above.' }
    }
  }
};
export default MQCHCampaign;
