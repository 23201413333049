<div class="directs__container" *ngIf="isLoaded">
  <div class="direct__level-1" *ngFor="let direct of directs; index as i">
    <mint-direct class="direct__item {{ getClassByLevel(direct) }}" [direct]="direct" [mode]="'azure-incentives'" (toggleShowDirects)="toggleShowDirects($event)"></mint-direct>
    <div class="direct__level-2__container" *ngIf="!direct.isFiltered && direct.isSubDirectsVisibleForAzureIncetives">
      <div *ngIf="direct.showSubDirectsLoader" class="direct__level direct__level-2">
        <div class="content__loader direct__item">
          <facebook-content-loader class="direct__detail__loader"></facebook-content-loader>
          <mint-content-loader-small class="pcg__items__loader"></mint-content-loader-small>
        </div>
      </div>
      <div *ngIf="!direct.showSubDirectsLoader && direct.directs?.length > 0">
        <div class="direct__level direct__level-2" *ngFor="let l2Direct of direct.directs; index as j">
          <mint-direct class="direct__item" [direct]="l2Direct" [mode]="'azure-incentives'" (toggleShowDirects)="toggleShowDirects($event)"></mint-direct>
          <div class="direct__level-3__container" *ngIf="!l2Direct.isFiltered && l2Direct.isSubDirectsVisibleForAzureIncetives">
            <div *ngIf="l2Direct.showSubDirectsLoader" class="direct__level direct__level-3">
              <div class="content__loader direct__item">
                <facebook-content-loader class="direct__detail__loader"></facebook-content-loader>
                <mint-content-loader-small class="pcg__items__loader"></mint-content-loader-small>
              </div>
            </div>
            <div *ngIf="!l2Direct.showSubDirectsLoader && l2Direct.directs?.length > 0">
              <div class="direct__level direct__level-3" *ngFor="let l3Direct of l2Direct.directs; index as k">
                <mint-direct class="direct__item" [direct]="l3Direct" [mode]="'azure-incentives'" [isShowDirects]="'false'"></mint-direct>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Loader content -->
<div *ngIf="!isLoaded">
  <div class="content__loader">
    <facebook-content-loader class="direct__detail__loader"></facebook-content-loader>
    <mint-content-loader-large class="pcg__items__loader"></mint-content-loader-large>
  </div>
  <div class="content__loader">
    <facebook-content-loader class="direct__detail__loader"></facebook-content-loader>
    <mint-content-loader-large class="pcg__items__loader"></mint-content-loader-large>
  </div>
  <div class="content__loader">
    <facebook-content-loader class="direct__detail__loader"></facebook-content-loader>
    <mint-content-loader-large class="pcg__items__loader"></mint-content-loader-large>
  </div>
</div>
