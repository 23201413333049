import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mint-content-loader-bar-chart-vertical',
  templateUrl: './content-loader-bar-chart-vertical.component.html'
})
export class ContentLoaderBarChartVerticalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
