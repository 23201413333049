import { Component, Inject, Input, OnInit } from '@angular/core';
import { ManagerDashboardHelper } from '../manager-dashboard.helper';
import { ManagerDashboardService } from '../manager-dashboard.service';
import { Direct } from '../models/direct';
import * as _ from 'underscore';
import { FiltersService } from '../../../filters/filters.service';

@Component({
  selector: 'mint-direct',
  templateUrl: './direct.component.html',
  styleUrls: ['./direct.component.scss']
})
export class DirectComponent implements OnInit {
  @Input() direct: Direct;
  @Input() mode: string;
  @Input() isShowDirects = true;
  @Input() showImage = true;

  errorMessage: string;
  isInUBIAttainmentMode: boolean;
  isInRBIAttainmentMode: boolean;
  isInPPIAttainmentMode: boolean;
  isInQuotaToGoMode: boolean;
  isInAzureIncentivesMode: boolean;
  ubiAttainmentMetric: any;

  // message keys.
  userPlanNotOnboardedMessageKey = 'UserPlanNotOnboardedManagerDashboard';
  fcaApprovalPendingMessageKey = 'QAStatusManager';

  constructor(
    private managerDashboardService: ManagerDashboardService,
    private managerDashboardHelper: ManagerDashboardHelper,
    private filterService: FiltersService
  ) {}

  ngOnInit() {
    this.buildErrorMessage();
    this.assignMode();
    this.direct.ubiAttainmentMetrics = _.sortBy(this.direct.ubiAttainmentMetrics, 'PeriodStatus');
  }

  getMessage(key: string, defaultMessage: string) {
    // Todo: Integrate messages from messageservice here.
    return defaultMessage;
  }

  onToggleViewDirects() {
    if (this.mode === 'attainment') {
      if (!this.direct.isSubDirectsVisibleForAttainment) {
        this.managerDashboardHelper.loadSubDirects(this.direct, this.mode, false);
      } else {
        this.direct.isSubDirectsVisibleForAttainment = false;
        this.direct.viewReporteesFlag = 'View';
        this.direct.directs = null;
        this.managerDashboardHelper.expandAllText = 'Expand to filter by all direct reports';
      }
    } else if (this.mode === 'quota-to-go') {
      if (!this.direct.isSubDirectsVisibleForQuotaToGo) {
        this.managerDashboardHelper.loadSubDirects(this.direct, this.mode);
      } else {
        this.direct.isSubDirectsVisibleForQuotaToGo = false;
        this.direct.viewReporteesFlag = 'View';
        this.direct.directs = null;
        this.managerDashboardHelper.expandAllText = 'Expand All';
      }
    } else if (this.mode === 'azure-incentives') {
      if (!this.direct.isSubDirectsVisibleForAzureIncetives) {
        this.managerDashboardHelper.loadSubDirects(this.direct, this.mode);
      } else {
        this.direct.isSubDirectsVisibleForAzureIncetives = false;
        this.direct.viewReporteesFlag = 'View';
        this.direct.directs = null;
        this.managerDashboardHelper.expandAllText = 'Expand All';
      }
    }
  }

  buildErrorMessage() {
    // Remove/Add condition here when PPI is on-boarded to PROD.
    if (this.direct.isUBIPersona && this.mode !== 'attainment') {
      this.errorMessage = this.getNotApplicableErrorMessage();
    } else if (this.direct.isPPIPersona && (this.filterService.selectedManagerViewStepId === 1 || this.filterService.selectedManagerViewStepId === 3)) {
      this.errorMessage = this.getNotApplicableQuarterErrorMessage();
    } else if (this.direct.isPPIPersona && (this.direct.pcgMetrics === null || this.direct.pcgMetrics === undefined)) {
      this.errorMessage = this.direct.name + ' is not an Incentive Plan Participant in Mint for the selected fiscal year.';
    } else if (this.direct.planId === -1) {
      this.errorMessage = this.direct.name + ' is not an Incentive Plan Participant in Mint for the selected fiscal year.';
    } else if (!this.direct.isPlanOnboarded) {
      this.errorMessage = this.direct.name + ' ' + this.getMessage(this.userPlanNotOnboardedMessageKey, 'belongs to a plan which is yet to be on-boarded.');
    } else if (!this.direct.isQaFcaApproved) {
      this.errorMessage = this.getMessage(this.fcaApprovalPendingMessageKey, 'Approval of quota information is pending.');
    } else if (!this.direct.isFCAHasAccess) {
      this.errorMessage = this.direct.name + ' is not under FCA Comp Group Hierarchy';
    } else if (!this.direct.isAuthorized && (this.direct.missingBusinesses || this.direct.missingSubsidiaries)) {
      this.errorMessage = this.getErrorMessageWithMissingSubsidiariesAndBusinesses(this.direct);
    } else if (this.mode === 'azure-incentives' && !this.direct.isACREligible) {
      this.errorMessage = this.getAzureIncentivesErrorMessage();
    } else if (this.direct.isUBIPersona && (this.filterService.selectedManagerViewStepId === 1 || this.filterService.selectedManagerViewStepId === 3)) {
      this.errorMessage = this.getNotApplicableQuarterErrorMessage();
    } else if (this.checkForEmptyData()) {
      this.errorMessage = 'Data is not available for ' + this.direct.name + ' yet.';
    }
  }

  checkForEmptyData() {
    return (
      (this.mode === 'attainment' &&
        ((this.direct.isRBIPersona && !this.direct.pcgMetrics) || (this.direct.isUBIPersona && !this.direct.ubiAttainmentMetrics) || (this.direct.isPPIPersona && !this.direct.pcgMetrics))) ||
      (this.mode === 'quota-to-go' && !this.direct.pccMetrics) ||
      (this.mode === 'azure-incentives' && !this.direct.azureIncentivesMetrics)
    );
  }

  getErrorMessageWithMissingSubsidiariesAndBusinesses(direct: Direct): string {
    let error = '';
    if (direct.missingBusinesses && direct.missingBusinesses) {
      error = `You need to have permission to ${direct.missingSubsidiaries} subsidiaries and ${direct.missingBusinesses} businesses to view this user's data.`;
    } else if (direct.missingBusinesses) {
      error = `You need to have permission to ${direct.missingBusinesses} businesses to view this user's data.`;
    } else if (direct.missingSubsidiaries) {
      error = `You need to have permission to ${direct.missingSubsidiaries} subsidiaries to view this user's data.`;
    }
    return error;
  }

  getNotApplicableErrorMessage(): string {
    const tabName = this.mode === 'quota-to-go' ? 'Quota To Go by Product Group (PCC)' : 'Accelerate Azure';
    return `The ${tabName} is not applicable for this ${this.direct.incentiveType} user.`;
  }

  getNotApplicableQuarterErrorMessage(): string {
    const tabName = this.mode === 'attainment' ? 'attainment' : 'quota';
    return `The ${tabName} data for ${this.direct.incentiveType} user is only applicable for Q2 & Q4 quarters only. Please select Q2 / Q4 to view ${tabName} data.`;
  }

  getAzureIncentivesErrorMessage(): string {
    return (
      `${this.direct.name} is not eligible for Azure Incentives. Review eligibility ` +
      '<a href="https://microsoft.sharepoint.com/teams/ICGuide/Pages/default.aspx#contestsandincentiveprograms-AccelerateAzureIncentive" target="_blank">here</a>'
    );
  }

  assignMode() {
    if (!this.errorMessage) {
      this.isInRBIAttainmentMode = this.mode === 'attainment' && this.direct.isRBIPersona;
      this.isInUBIAttainmentMode = this.mode === 'attainment' && this.direct.isUBIPersona;
      this.isInPPIAttainmentMode = this.mode === 'attainment' && this.direct.isPPIPersona;
      this.isInQuotaToGoMode = this.mode === 'quota-to-go';
      this.isInAzureIncentivesMode = this.mode === 'azure-incentives';
    }
  }
}
