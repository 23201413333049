import { Component, Inject } from '@angular/core';
import { OnInit } from '@angular/core';
import { ConfigurationState, selectConfigurationState } from '@mint-libs/configuration';
import { Store } from '@ngrx/store';
import { FiltersService } from '../filters/filters.service';
import { UserProfileService } from '../core/context/user-profile.service';
import { NavigationService } from '../core/navigation/navigation.service';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-complaince-notification',
  templateUrl: './compliance-notification.component.html'
})
export class ComplianceNotificationComponent implements OnInit {
  private filterService: any;
  private filterConfig: any;
  private tabset: any;
  showNotes = false;
  complianceBannerMessage: string;
  QASigned = false;
  PPASigned = false;
  index: any;
  showComplianceBanner = false;
  fiscalyear = null;
  hideNotification = false;
  config: any = {};
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private route: Router,
    public sharedContextState: Store<SharedContext>,
    public userProfileService: UserProfileService,
    filterService: FiltersService,
    private navigationService: NavigationService,
    private configurationState: Store<ConfigurationState>
  ) {
    this.filterService = filterService;

    this.filterConfig = {
      isFilter: false,
      isSearch: false,
      isFiscalYear: true,
      isTitle: false,
      isPartition: false,
      showSearch: false,
      showFilter: true
    };
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.getConfigDetails();
  }

  ngOnInit() {
    this.hideNotification = false;
    if (this.filterService.isMic2Profile() || this.route.url.includes('/admin') || this.route.url.includes('/complianceDashboard') || this.userProfileService.isNPM()) {
      this.hideNotification = true;
    }

    this.ProcessComplianceBannerMessage(this.sharedContext.toggleQAView);
  }

  tabselect(index) {
    this.tabset = index;
    if (index === 0) {
      this.filterConfig.isTitle = false;
      this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, toggleQAView: 0 }));
    } else if (index === 1) {
      this.filterConfig.isTitle = true;
      this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, toggleQAView: 1 }));
    } else if (index === 2) {
      this.filterConfig.isTitle = false;
      this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, toggleQAView: 2 }));
    } else {
      this.filterConfig.isTitle = true;
      this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, toggleQAView: 3 }));
    }
  }

  ComputeBannermessage(): string {
    this.QASigned = this.sharedContext.QuotaFCAApprovalPending || (!this.sharedContext.QuotaFCAApprovalPending && this.sharedContext.isQuotaAcknowledged) ? true : false;
    this.PPASigned = !this.sharedContext.isPPAAvailable || (this.sharedContext.isPPAAvailable && this.sharedContext.isPPASigned) ? true : false;

    if (!this.QASigned && this.PPASigned) {
      // Only QA Pending
      return this.config.onlyQAPendingText;
    } else if (this.QASigned && !this.PPASigned) {
      // Only PPA Pending
      return this.config.onlyPPAPendingText;
    } else if (!this.QASigned && !this.PPASigned) {
      // Both QA and PPA are pending
      return this.config.qAPPAPendingText;
    } else {
      // Both QA and PPA accepted
      return '';
    }
  }

  ProcessComplianceBannerMessage(selectedTab) {
    this.complianceBannerMessage = this.ComputeBannermessage();
    this.tabselect(selectedTab);

    if (this.complianceBannerMessage) {
      this.showComplianceBanner = !this.hideNotification && !this.sharedContext.isImpersonating;
    }
  }

  navigateTo(item, id, data) {
    this.navigationService.navigateTo(item, data);
    this.showNotes = false;
  }

  private getConfigDetails() {
    this.configurationState.select(selectConfigurationState).subscribe(state => {
      if (!state || !state.configs) {
        return;
      }
      this.config = state.configs;
      this.ProcessComplianceBannerMessage(this.sharedContext.toggleQAView);
    });
  }
}
