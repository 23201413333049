import { Inject, Injectable, OnInit } from '@angular/core';
import * as _ from 'underscore';
import { MessagesService, selectUserProfileState, ContextState, MeasureTypeMappingState, MeasureType } from '@mint-libs/context';
import { Observable } from 'rxjs/internal/Observable';
import { formatNumber } from '@angular/common';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Code } from '../../app.constants';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { UserProfileService } from '../../core/context/user-profile.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';

@Injectable()
export class ComplianceHelper implements OnInit {
  hasQuotaMultiplier = false; /* quota multiplier*/
  h2QuotaMessage: string;
  PCCs = [];
  locale = 'en-US';
  isHalfYearlyParticipation = false;
  titles = [];
  isRollOver: any;
  isQuotaUpdated: any;
  ppiPCCs = [];
  ubiTargetHoursDetails: any;
  isExportApplicable: any;
  notAvailable = false;
  showNotAvailableMessage: boolean;
  showImpersonatedFCANotApprovedHTML = false;
  hasPIT = false; /*point in time*/
  halfYearlyQuotaNotSupportedPartitions = ['Q3', 'Q4', 'H2'];
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private messageService: MessagesService,
    protected contextState: Store<ContextState>,
    private code: Code,
    public userProfileService: UserProfileService,
    public sharedContextState: Store<SharedContext>
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  ngOnInit() {}

  // private getUserProfileData() {
  //   this.contextState.select(selectUserProfileState).subscribe(userProfile => {
  //     if (!userProfile) {
  //       return;
  //     }
  //     this.userProfileService = userProfile;
  //   });
  // }

  getQuotaDetails(complianceInfo: any, isExportApplicable = null, measureTypeMapping: MeasureTypeMappingState = new MeasureTypeMappingState([])) {
    // this.getUserProfileData();
    const isLoaded = true;
    const isHalfYearlyQuotaSupported = complianceInfo.IsHalfYearlyQuotaSupported;
    this.isExportApplicable = isExportApplicable;
    if (complianceInfo && complianceInfo.PpiQuotaDetails != null) {
      // ONLY PPI Logic
      complianceInfo.Status = this.sharedContext.isQuotaAcknowledged;
      this.isRollOver = this.sharedContext.isRollOver;
      this.isQuotaUpdated = complianceInfo.IsQuotaUpdated;
      this.ppiPCCs = complianceInfo.PpiQuotaDetails;
      this.titles = this.getTitles(complianceInfo, this.ppiPCCs[0].PlanStartDate, this.ppiPCCs[0].PlanEndDate, isHalfYearlyQuotaSupported).titles;
    } else if (complianceInfo && complianceInfo.QuotaDetail != null) {
      complianceInfo.Status = this.sharedContext.isQuotaAcknowledged;
      this.isRollOver = this.sharedContext.isRollOver;
      const quotaDetail = complianceInfo.QuotaDetail;

      this.isQuotaUpdated = complianceInfo.IsQuotaUpdated;
      this.ubiTargetHoursDetails = complianceInfo.UBIQuotaHoursDetails;

      const period = this.getTitles(complianceInfo, quotaDetail.PlanStartDate, quotaDetail.PlanEndDate, isHalfYearlyQuotaSupported);
      this.isHalfYearlyParticipation = period.isHalfYearlyParticipation;
      this.titles = period.titles;

      let pccCountForPcg = 0;
      this.PCCs = [];
      _.each(
        quotaDetail.PlanComponentGroups,
        function(PCG) {
          pccCountForPcg = PCG.PlanComponentCombinations.length;
          let currPccIndex = 0;
          let currPccRenderRowCount = 0;
          if (!this.hasPIT) {
            this.hasPIT = this.checkForPIT(PCG.PlanComponentCombinations);
          }
          this.hasQuotaMultiplier = this.checkForQuotaMultiplierPCC(PCG.PlanComponentCombinations);

          let pccCollection = new Array();
          // var renderPCC = new Object();

          _.each(
            PCG.PlanComponentCombinations,
            function(PCC) {
              const mappingType = measureTypeMapping.getMeasureType(PCC.Id);
              let total = 0;
              let totalRecurring = 0;
              const totalNonRecurring = 0;
              let previousTarget = 0;
              let previousRecurringTarget = 0;
              let pccMetrics;
              const pccUnits = PCC.MeasuringUnits;
              let totalQuotaMultiplier = 0;
              let recurringQuotaMultiplier = 0;
              let nonRecurringQuotaMultiplier = 0;

              let totalUbiLegalWorkweekHours = 0;
              let totalUbiStandardTaskHours = 0;
              let totalUbiPublicHolidayHours = 0;
              let totalUbiVacationHours = 0;
              let totalUbiBaselineHours = 0;

              // To remove code periods from PCCs which are not supported in halfyearlyquotas quotas
              if (isHalfYearlyQuotaSupported && PCG.IncentiveType !== 'UBI') {
                PCC.AllMetrics = Object.keys(PCC.AllMetrics)
                  .filter(key => !this.halfYearlyQuotaNotSupportedPartitions.includes(key.split('-').pop()))
                  .reduce((object, key) => {
                    object[key] = PCC.AllMetrics[key];
                    return object;
                  }, {});
              }

              if (PCC.CalcType !== 'PIT') {
                pccMetrics = _.map(PCC.AllMetrics, function(metrics, codePeriodKey) {
                  let target = metrics.Target - previousTarget;
                  const targetRecurring = metrics.TargetRecurring - previousRecurringTarget;
                  previousTarget = metrics.Target;
                  previousRecurringTarget = metrics.TargetRecurring;
                  totalQuotaMultiplier += metrics.QuotaMultiplier;
                  recurringQuotaMultiplier += metrics.RecurringQuotaMultiplier;
                  nonRecurringQuotaMultiplier += metrics.NonRecurringQuotaMultiplier;
                  // Here, either add up quota for each quarter or directly take Q4 target as total quota
                  total += target;
                  totalRecurring += targetRecurring;
                  if (PCG.IncentiveType === 'UBI') {
                    totalUbiBaselineHours = metrics.UbiTargetBaselineHours;
                    totalUbiLegalWorkweekHours = metrics.UbiLegalWorkweekHours;
                    totalUbiStandardTaskHours = metrics.UbiStandardTaskHours;
                    totalUbiPublicHolidayHours = metrics.UbiPublicHolidayHours;
                    totalUbiVacationHours = metrics.UbiVacationHours;
                    target = metrics.Target;
                  }
                  return {
                    Target: target,
                    TargetRecurring: targetRecurring,
                    TargetNonRecurring: target - targetRecurring,
                    QuotaMultiplier: metrics.QuotaMultiplier,
                    RecurringQuotaMultiplier: metrics.RecurringQuotaMultiplier,
                    NonRecurringQuotaMultiplier: metrics.NonRecurringQuotaMultiplier,
                    totalUbiBaselineHours: metrics.UbiTargetBaselineHours,
                    totalUbiLegalWorkweekHours: metrics.UbiLegalWorkweekHours,
                    totalUbiStandardTaskHours: metrics.UbiStandardTaskHours,
                    totalUbiPublicHolidayHours: metrics.UbiPublicHolidayHours,
                    totalUbiVacationHours: metrics.UbiVacationHours,
                    timeWorkedPercentage: metrics.UbiTimeWorkPercentage,
                    quotaAdjustment: metrics.UbiTargetAdjustment,
                    CodePeriod: codePeriodKey
                  };
                });
              } else {
                pccMetrics = _.map(PCC.AllMetrics, function(metrics) {
                  total = metrics.Target;
                  totalRecurring = metrics.TargetRecurring;
                  totalQuotaMultiplier += metrics.QuotaMultiplier;
                  recurringQuotaMultiplier += metrics.RecurringQuotaMultiplier;
                  nonRecurringQuotaMultiplier += metrics.NonRecurringQuotaMultiplier;
                  return {
                    Target: total,
                    TargetRecurring: totalRecurring,
                    TargetNonRecurring: total - totalRecurring,
                    QuotaMultiplier: metrics.QuotaMultiplier,
                    RecurringQuotaMultiplier: metrics.RecurringQuotaMultiplier,
                    NonRecurringQuotaMultiplier: metrics.NonRecurringQuotaMultiplier
                  };
                });
              }
              if (totalRecurring !== 0) {
                // Recurring and Non-recurring parts
                let renderPCC: any;
                renderPCC = new Object();
                renderPCC.pccMetrics = pccMetrics;
                renderPCC.pccName = PCC.Name;
                renderPCC.metricType = mappingType === MeasureType.Baseline ? 'Baseline' : 'Recurring';
                renderPCC.metricTypeKey = 'TargetRecurring';
                renderPCC.total = totalRecurring;
                renderPCC.rowSpan = 2;
                renderPCC.pccUnits = pccUnits;
                renderPCC.calcType = PCC.CalcType;
                renderPCC.pcgName = PCC.Parent;
                renderPCC.pcgRowSpan = -1;
                renderPCC.territoryList = PCC.TerritoryList;

                pccCollection[currPccIndex++] = renderPCC;

                renderPCC = new Object();

                renderPCC.pccMetrics = pccMetrics;
                renderPCC.pccName = PCC.Name;
                renderPCC.metricType = mappingType === MeasureType.Baseline ? 'Total Quota - Baseline' : 'Non-Recurring';
                renderPCC.metricTypeKey = 'TargetNonRecurring';
                renderPCC.total = total - totalRecurring;
                renderPCC.rowSpan = -1;
                renderPCC.pccUnits = pccUnits;
                renderPCC.calcType = PCC.CalcType;
                renderPCC.pcgName = PCC.Parent;
                renderPCC.pcgRowSpan = -1;
                renderPCC.territoryList = PCC.TerritoryList;

                pccCollection[currPccIndex++] = renderPCC;

                currPccRenderRowCount += 2;
              } else {
                // Only total

                currPccRenderRowCount += 1;
                let renderPCC: any;
                renderPCC = new Object();

                renderPCC.pccMetrics = pccMetrics;
                renderPCC.pccName = PCC.Name;
                renderPCC.metricType = PCC.IsDynamicMetric ? 'Total Committed' : 'Total';
                renderPCC.metricTypeKey = 'Target';
                renderPCC.total = total;
                renderPCC.rowSpan = 1;
                renderPCC.pccUnits = pccUnits;
                renderPCC.calcType = PCC.CalcType;
                renderPCC.pcgName = PCC.Parent;
                renderPCC.pcgRowSpan = -1;
                renderPCC.territoryList = PCC.TerritoryList;
                if (PCG.IncentiveType === 'UBI') {
                  // renderPCC.UbiTargetHoursBreakUp = {
                  //   targetBaseLineHours: totalUbiBaselineHours,
                  //   totalLegalWorkweekHours: totalUbiLegalWorkweekHours,
                  //   totalStandardTaskHours: totalUbiStandardTaskHours,
                  //   totalPublicHolidayHours: totalUbiPublicHolidayHours,
                  //   totalVacationHours: totalUbiVacationHours
                  // };
                }
                pccCollection[currPccIndex++] = renderPCC;
              }
            },
            this
          );

          pccCollection[0].pcgRowSpan = currPccIndex;

          for (let i = 0; i < pccCollection.length; i++) {
            const pccItem = pccCollection[i];
            this.PCCs.push(
              this.getMetrics(
                pccItem.pccMetrics,
                pccItem.pccName,
                pccItem.metricType,
                pccItem.metricTypeKey,
                pccItem.total,
                pccItem.rowSpan,
                pccItem.pccUnits,
                pccItem.calcType,
                pccItem.pcgName,
                pccItem.pcgRowSpan,
                pccItem.UbiTargetHoursBreakUp,
                pccItem.territoryList,
                PCG.IncentiveType,
                PCG.PCGWeight
              )
            );
          }

          pccCollection = new Array();
        },
        this
      ); // END ForEach
      this.notAvailable = !quotaDetail || !quotaDetail.PlanComponentGroups || quotaDetail.PlanComponentGroups.length === 0 || this.PCCs.length === 0;
    } else if (!complianceInfo.isQuotaFCAApproved) {
      // && impersonationService.isImpersonating) {
      this.isExportApplicable = false;
      this.showImpersonatedFCANotApprovedHTML = true;
    } else {
      this.notAvailable = true;
      this.isExportApplicable = false;
      this.showNotAvailableMessage = true;
    }
    return {
      complianceInfo: complianceInfo,
      PCCs: this.PCCs,
      isLoaded: isLoaded,
      isRollOver: this.isRollOver,
      isQuotaUpdated: this.isQuotaUpdated,
      isHalfYearlyQuotaSupported: isHalfYearlyQuotaSupported,
      hasQuotaMultiplier: this.hasQuotaMultiplier,
      ppiPCCs: this.ppiPCCs,
      ubiTargetHoursDetails: this.ubiTargetHoursDetails,
      titles: this.titles,
      isHalfYearlyParticipation: this.isHalfYearlyParticipation,
      isExportApplicable: this.isExportApplicable,
      notAvailable: this.notAvailable,
      showNotAvailableMessage: this.showNotAvailableMessage,
      showImpersonatedFCANotApprovedHTML: this.showImpersonatedFCANotApprovedHTML,
      hasPIT: this.hasPIT
    };
  }

  private getTitles(complianceInfo: any, startDate: any, endDate: any, isHalfYearlyQuotaSupported: boolean) {
    const planStartMonth = new Date(startDate).getMonth();
    const planEndMonth = new Date(endDate).getMonth();
    return this.getPeriod(complianceInfo.StepTitle, planStartMonth, planEndMonth, isHalfYearlyQuotaSupported, this.halfYearlyQuotaNotSupportedPartitions);
  }

  private checkForPIT(PlanComponentCombinations: any) {
    const result = _.find(PlanComponentCombinations, function(PCC) {
      return PCC.CalcType === 'PIT';
    });
    return result !== undefined;
  }

  private checkForQuotaMultiplierPCC(PlanComponentCombinations) {
    const result = _.find(PlanComponentCombinations, function(pcc) {
      return _.find(pcc.AllMetrics, function(metrics) {
        if (metrics.QuotaMultiplier != null) {
          return true;
        } else {
          return false;
        }
      });
    });
    return (this.hasQuotaMultiplier = this.hasQuotaMultiplier || result !== undefined);
  }

  private getCurrentUTCDateTime(): Date {
    const d1 = new Date();
    const currentUTCDateTime = new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds());
    return currentUTCDateTime;
  }

  private checkQuotaMultiplierMetric(pccMetrics) {
    const isQuotaMultiplier = _.find(pccMetrics, function(metric) {
      if (metric.QuotaMultiplier != null) {
        return true;
      } else {
        return false;
      }
    });
    return isQuotaMultiplier !== undefined;
  }

  private getMetrics(pccMetrics, pccName, metricType, metricTypeKey, total, rowSpan, units, calcType, pcgName, pcgRowSpan, UbiTargetHoursBreakUp = null, territoryList, PCGIncentiveType, PCGWeight) {
    let isPIT = false;
    let totalQuotaMultiplier = 0;
    let recurringQuotaMultiplier = 0;
    let nonRecurringQuotaMultiplier = 0;
    const isQuotaMultiplier = this.checkQuotaMultiplierMetric(pccMetrics);
    if (isQuotaMultiplier) {
      _.each(pccMetrics, function(metric) {
        totalQuotaMultiplier += metric.QuotaMultiplier;
        recurringQuotaMultiplier += metric.RecurringQuotaMultiplier;
        nonRecurringQuotaMultiplier += metric.NonRecurringQuotaMultiplier;
      });
    }

    const metrics = _.map(
      pccMetrics,
      function(metric) {
        if (isNaN(metric[metricTypeKey])) {
          return {
            Value: metric[metricTypeKey],
            ValueNumeric: metric[metricTypeKey]
          };
        } else {
          return {
            Value: formatNumber(metric[metricTypeKey], this.locale, '1.0-2') + ' ' + units,
            // Value: $filter('number')(metric[metricTypeKey], 2) + ' ' + units,
            ValueNumeric: formatNumber(metric[metricTypeKey], this.locale, '1.0-2'),
            CodePeriod: metric.CodePeriod
            // $filter('number')(metric[metricTypeKey], 2)
          };
        }
      },
      this
    );

    if (PCGIncentiveType && PCGIncentiveType !== 'UBI') {
      metrics.push({
        Value: formatNumber(total, this.locale, '1.0-2') + ' ' + units,
        // $filter('number')(total, 2) + ' ' + units,
        ValueNumeric: formatNumber(total, this.locale, '1.0-2'),
        CodePeriod: metrics.CodePeriod
        // $filter('number')(total, 2)
      });
    }

    if (calcType.toUpperCase() === 'PIT') {
      isPIT = true;
    }

    return {
      Name: pccName,
      MetricType: metricType,
      RowSpan: rowSpan,
      Metrics: metrics,
      IsPIT: isPIT,
      Units: units,
      PCGName: pcgName,
      PCGRowSpan: pcgRowSpan,
      PCGWeight: PCGWeight,
      isQuotaMultiplier: isQuotaMultiplier,
      totalQuotaMultiplier: totalQuotaMultiplier,
      recurringQuotaMultiplier: recurringQuotaMultiplier,
      nonRecurringQuotaMultiplier: nonRecurringQuotaMultiplier,
      UbiTargetHoursBreakUp: UbiTargetHoursBreakUp,
      PCGIncentiveType: PCGIncentiveType,
      rawMatrics: pccMetrics,
      TerritoryList: territoryList
    };
  }

  private getPeriod(stepTitles, planStartMonth: number, planEndMonth: number, isHalfYearlyQuotaSupported: boolean, halfYearlyQuotaNotSupportedPartitions = null) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let fyStart = null,
      fyEnd = null,
      index = 0;

    const quarters = Object.keys(stepTitles).sort();

    let titles = quarters.map(period => {
      const startDate = new Date(stepTitles[period].StepStartDate);
      const endDate = new Date(stepTitles[period].StepEndDate);
      const pos = stepTitles[period].CodePeriod.indexOf('-');
      if (index === 0) {
        fyStart = monthNames[startDate.getMonth()];
        index++;
      }
      fyEnd = monthNames[endDate.getMonth()];
      return {
        Name: stepTitles[period].CodePeriod.slice(pos + 1, stepTitles[period].CodePeriod.length),
        Text: '(' + monthNames[startDate.getMonth()] + ' - ' + monthNames[endDate.getMonth()] + ')'
      };
    });

    if (planEndMonth - planStartMonth === 5) {
      const title = planEndMonth === 5 ? 'H2' : 'H1';
      titles.push({
        Name: title,
        Text: '(' + fyStart + ' - ' + fyEnd + ')'
      });
      this.isHalfYearlyParticipation = true;
    } else if (!isHalfYearlyQuotaSupported) {
      titles.push({
        Name: 'FY',
        Text: '(' + fyStart + ' - ' + fyEnd + ')'
      });
    }

    if (isHalfYearlyQuotaSupported && this.sharedContext.incentiveType === 'RBI') {
      titles = titles.filter(function(val) {
        return this.halfYearlyQuotaNotSupportedPartitions.indexOf(val.Name) === -1 ? true : false;
      }, this);
      if (titles.length > 0 && !titles.some(title => title.Name === 'H1')) {
        titles.push({ Name: 'H1', Text: '(Jul - Dec)' });
      }
    }

    return { titles: titles, isHalfYearlyParticipation: this.isHalfYearlyParticipation };
  }

  getPITConfigData(data: any) {
    if (data != null) {
      return data.TableInfoBottom;
    }
  }

  getH2Message(isReportView: boolean): Observable<string> {
    const page = isReportView ? 'Report' : 'Quota';
    // this.getUserProfileData();
    return this.messageService.get(Code.H2QuotaMessages).pipe(
      map(response => {
        return JSON.parse(<any>response)[page][this.sharedContext.incentiveType];
      })
    );
  }
}
