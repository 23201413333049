import { Direct } from '../models/direct';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mint-utilization-metric-attainment',
  templateUrl: './utilization-metric-attainment.component.html',
  styleUrls: ['./utilization-metric-attainment.component.scss']
})
export class UtilizationMetricAttainmentComponent implements OnInit {
  @Input() directs: Array<Direct>;
  @Input() isLoaded: boolean;
  @Input() showImages: boolean=false;

  constructor() {}

  ngOnInit() {}

  getClassByLevel(direct: Direct): string {
    const classByLevel = `direct__itemLevel${direct.level}`;
    return classByLevel;
  }
}
