<div class="manager__dashboard__feedback"></div>

<div class="manager__dashboard__container">
  <div class="myteam__container" [ngClass]="isUbiDirects ? '' : 'padding-top-20'">
    <div class="myteam__header" *ngIf="isDirectsLoaded && !isUbiDirects">
      <div class="myteam__expand__all" *ngIf="showExpandAll && isExpandAllApplicable">
        <div
          role="button"
          tabindex="0"
          (keyup.enter)="expandAll()"
          (click)="expandAll()"
          analytics-tracker
          [tracker]="'Expand_All'"
          [type]="'Click'"
          [element]="'HyperLink'"
          [source]="'New Manager Dashboard'"
        >
          {{ managerDashboardHelper.expandAllText }}
        </div>
        <div class="stat__info">
          <div tabindex="0" class="icon icon-info"></div>
          <div class="stat__tooltip stat__tooltip-left"><p class="stat__tooltip__text">Expands up to 4 levels in the organization (If applicable).</p></div>
        </div>
      </div>
      <mint-manager-dashboard-export [isRbiDirects]="isRbiDirects" [isUbiDirects]="isUbiDirects" class="myteam__excel__report"></mint-manager-dashboard-export>
      <!--Un comment while implementing search by alias-->
      <!-- <div class="myteam__search">
        <input type="text" placeholder="Search your team members" class="form-control" />
        <div class="icon icon-search"></div>
      </div> -->
    </div>
    <div class="myteam__options">
      <div class="myteam__filters">
        <div class="myteam__filter" *ngIf="!isUbiDirects">
          <mint-multi-select-dropdown
            [placeholder]="'Select Role Name'"
            [data]="directsFilter.roles"
            [(selectedItems)]="selectedRoles"
            (selectChange)="onSelectFilter($event, 'Role')"
            (selectAllChange)="onSelectAll($event, 'Role')"
          ></mint-multi-select-dropdown>
        </div>
        <div class="myteam__filter" *ngIf="!isUbiDirects && areBucketAndAttainmentFiltersApplicable">
          <mint-multi-select-dropdown
            [placeholder]="'Select Total Weighted Attainment Range'"
            [data]="directsFilter.weightedAttainmentRanges"
            [(selectedItems)]="selectedWeightedAttainmentRanges"
            (selectChange)="onSelectFilter($event,'WeightedAttainmentRange')"
            (selectAllChange)="onSelectAll($event,'WeightedAttainmentRange')"
          ></mint-multi-select-dropdown>
        </div>
        <div class="myteam__filter" *ngIf="!isUbiDirects && areBucketAndAttainmentFiltersApplicable">
          <mint-multi-select-dropdown
            [placeholder]="'Select Bucket Name'"
            [data]="directsFilter.bucketNames"
            [(selectedItems)]="selectedBuckets"
            (selectChange)="onSelectFilter($event,'Bucket')"
            (selectAllChange)="onSelectAll($event,'Bucket')"
          ></mint-multi-select-dropdown>
        </div>
      <div class="myteam__filter" *ngIf="!isUbiDirects && areBucketAndAttainmentFiltersApplicable">
        <mint-multi-select-dropdown
          [placeholder]="'Select Bucket Attainment Range'"
          [data]="directsFilter.bucketAttainmentRanges"
          [(selectedItems)]="selectedBucketAttainmentRanges"
          (selectChange)="onSelectFilter($event,'BucketAttainmentRange')"
          (selectAllChange)="onSelectAll($event,'BucketAttainmentRange')"
        ></mint-multi-select-dropdown>
      </div>
    </div>
      <div [ngClass]="isUbiDirects ? 'myteam__tabs10l' : 'myteam__tabs'" class="app__nav">
        <!--'app__nav'-->
        <div class="myteam__mintTabs">
          <mint-tabs [tabs]="myTeamTabs" [activeId]="activeTabId" (tabChange)="onTabChange($event)" [tracker]="'My_Team_View'" [source]="'MyTeam'"></mint-tabs>
        </div>
      </div>
      <!--div style="margin-left: auto;"><mint-contextual-video [featureId]="'64'" [videoData]="videoData"> </mint-contextual-video></div-->
    </div>

    <div class="tab__content">
      <mint-ubi-advance-filter
        *ngIf="activeTabId === 'attainment' && isUbiDirects"
        [directs]="directs"
        [roles]="roles"
        (advanceFilteredDirects)="onUbiAdvancedFilter($event)"
      ></mint-ubi-advance-filter>
      <mint-myteam-tiles *ngIf="activeTabId === 'attainment' && isUbiDirects" [directs]="directs"></mint-myteam-tiles>
      <mint-utilization-metric-attainment *ngIf="activeTabId === 'attainment' || !isDirectsLoaded" [directs]="filteredDirects" [isLoaded]="isAttainmentDataLoaded" [showImages]="showImages">
      </mint-utilization-metric-attainment>
      <mint-quota-to-go *ngIf="activeTabId === 'quota-to-go'" [directs]="filteredDirects" [isLoaded]="isQuotaDataLoaded" [showImages]="showImages"></mint-quota-to-go>
      <mint-azure-incetives *ngIf="activeTabId === 'azure-incentives' && isACRApplicable" [directs]="filteredDirects" [isLoaded]="isAttainmentDataLoaded"></mint-azure-incetives>
      <mint-soft-cap *ngIf="activeTabId === 'softcap-by-metric'" [directs]="filteredDirects" [isLoaded]="isSoftCapDataLoaded" [isSubmitted]="isSoftCapSubmitted"></mint-soft-cap>
      <mint-manager-dashboard-report
        *ngIf="activeTabId === 'manager-reports'"
        [isRbiDirects]="isRbiDirects"
        [isUbiDirects]="isUbiDirects"
        class="myteam__excel__report"
      ></mint-manager-dashboard-report>
    </div>
    <div class="myteam__legends" *ngIf="activeTabId === 'attainment'">
      <mint-legends *ngIf="!isUBIPersona" class="mint__legends-myteam"></mint-legends>
      <div class="legend__item legend__item-signed">
        <div class="legend__item__icon icon-ms icon__ms icon-compliance-signed"></div>
        <div class="legend__item__text">Signed</div>
      </div>
      <div class="legend__item">
        <div class="legend__item__icon icon-ms icon__ms icon-compliance-notsigned"></div>
        <div class="legend__item__text">Not Signed</div>
      </div>
      <div class="legend__item">
        <div class="legend__item__icon legend__item__icon-optout">
          <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.0214844 22.1878L1.06327 23L6.53171 16.9234V17.0383H11.9235V15.4225H7.88246L9.16806 13.9939H15.0701V12.3781H10.6222L12.122 10.7115H15.0701V9.09563H13.5761L20.9464 0.905713L19.9514 0L14.8448 5.6851H9.50821V7.30093H13.3935L11.7814 9.09563H6.53171V10.7115H10.33L8.83297 12.3781H6.53171V13.9939H7.38158L0.0214844 22.1878ZM3.23067 1.08096H16.7959L15.3713 2.6436H4.70596V14.343L3.23067 15.9614V1.08096ZM18.3308 6.38226L16.7687 8.0785V20.3472H5.47023L4.11211 21.8219H18.3308V6.38226Z"
              fill="#4F4F4F"
            />
          </svg>
        </div>
        <div class="legend__item__text">Opted out of advance payments</div>
      </div>
    </div>
  </div>
</div>
