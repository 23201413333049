<ngx-spinner></ngx-spinner>

<div id="mqch_1" class="header">
  <div id="mqch_2" class="header__left">
    <div class="header__left-text">My Change History</div>
    <app-tour [tourName]="'Tour the My Change History experience'"></app-tour>
    <mint-contextual-video [featureId]="'79'"> </mint-contextual-video>
  </div>
  <div>
    <!-- <form id="mqch_3" class="form-inline ai-filter" novalidate>

    <div id="mqch_4" class="form-group filter__control">
      <label id="mqch_5" for="mqch_fiscalyear">Time Period</label>
      <select [(ngModel)]="mqchService.selectedFiscalYear" name="FiscalYear" id="mqch_6"
        class="form-control select-uwp" title="Select Partition" style="width: 120px;"
        (change)="updateFiscalyear(mqchService.selectedFiscalYear)" analytics-tracker [tracker]="'MQCH_FiscalYear'"
        [type]="'Change'" [element]="'Dropdown'" [source]="'MQCH_Filter'">
        <option id="mqch_7" *ngFor="let fiscalyear of fiscalYearList"
          [ngValue]="fiscalyear.value">{{ fiscalyear.title }}</option>
      </select>
    </div>

    <div id="mqch_8" class="form-group filter__control">
      <label id="mqch_9" for="mqch_Role">Role</label>
      <select [(ngModel)]="mqchService.selectedStandardTitle" name="StandardTitleDropDown"
        id="mqch_10" class="form-control select-uwp" title="Select Title" style="width: 260px;"
        (change)="updateParticpation(mqchService.selectedStandardTitle)" analytics-tracker [tracker]="'MQCH_StandardTitle'"
        [type]="'Change'" [element]="'Dropdown'" [source]="'MQCH_Filter'">
        <option id="mqch_11" *ngFor="let standardTitle of standardTitleList"
          [ngValue]="standardTitle">{{standardTitle.text}}</option>
      </select>
    </div>
    
    <div id="mqch_12" class="form-group filter__control">
      <button id="mqch_13" type="button" name="Apply" class="btn btn-dark" [disabled]="!isFormDirty"
        (click)="applyFilters()" title="Apply">Apply</button>
    </div>
  </form> -->

    <!-- <global-filter id="myCompliance_6" [config]="filterConfig" (apply)="applyFilters()"></global-filter> -->
    <mint-filters id="myCompliance_6" [config]="filterConfig" (apply)="applyFilters()"></mint-filters>

  </div>
</div>


<div class="bodycontent">
  <div class="bodycontent-note m-lr-10 h2quota_banner">
    <span>Note: </span>
    <span>{{ mqchMessage }}</span>
  </div>
  <div class="dropdown__container">
    <div class="dropdown__name">Bucket</div>
    <div>
      <select aria-label="mqch_pcglist" name="pcglist" id="mqch_pcglist" [(ngModel)]="selectedPcg"
        (change)="pcgUpdate(selectedPcg)" class="form-control select-uwp">
        <option *ngFor="let pcg of pcglist" [ngValue]="pcg">{{ pcg.Name }}</option>
      </select>
    </div>
  </div>
  <div class="bodycontent__heading">Timeline View</div>
  <div class="timeline__cont">
    <app-timeline [timelineDataList]="timelineData" (monthClick)="updateMonthData($event)"></app-timeline>
  </div>
  <div class="grid__container">
    <div class="grid__header">
      <div class="grid__header-text">Detailed View</div>
      <div class="grid__header-excelexport"></div>
    </div>
    <div class="grid__data">
      <div class="grid__data-left" *ngIf="mqchService.selectedStandardTitle">
        {{ mqchService.selectedStandardTitle.value.StartDate | date : 'MMM y' }} - {{
        mqchService.selectedStandardTitle.value.EndDate | date : 'MMM y' }}
      </div>
      <div class="grid__data-right">
        <div class="grid__data-right-1">Total Quota BEFORE</div>
        <div class="grid__data-right-1">Total Quota AFTER</div>
      </div>
    </div>

      <ngb-accordion #acc="ngbAccordion" class="level-1"  [activeIds]="activeIds">
        <ngb-panel *ngFor="let month of timelineMonths" id="custom_panel_{{month.FiscalMonthId}}" title="{ month.FiscalMonthName }}">
          <ng-template ngbPanelHeader>
            <div class="collapse__header">
              <div ngbPanelToggle role="button" aria-label="toggle button" class="icon collapse__btn"
               (click)="updateMonthData(month)" 
                [ngClass]="{ 'icon-chevron-down': !month.isActive, 'icon-chevron-up': month.isActive }"></div>
              <div class="collapse__title" id="mqch_tile_1">
                <div class="collapse__title__desc">{{ month.FiscalMonthName }}</div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent *ngIf="detailViews.length == 0 && month.isActive">
            <div class="collapse__content">
              <div class="collapse__row collapse__row-nocontent">No Changes Available to view for the Selected Period
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent *ngIf="detailViews.length > 0 && month.isActive">
            <div *ngFor="let detailView of detailViews">
              <div class="collapse__content" id="{{ detailView.date_id}}">
                <div class="collapse__row">
                  <div class="collapse__col collapse__col-desc">{{ detailView.EffectiveDate | date: 'MMM d' }}</div>
                  <div class="collapse__col__cont" *ngIf="detailView.PCGQuota">
                    <div class="collapse__col collapse__col-val">
                      <div class="collapse__col-val-icon">
                        <svg width="10" *ngIf="detailView.PCGQuota.ChangePercentage > 0" height="10" viewBox="0 0 10 10"
                          fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 0L10 10H0L5 0Z" fill="#0078D4" />
                        </svg>
                        <svg width="10" height="10" *ngIf="detailView.PCGQuota.ChangePercentage < 0" viewBox="0 0 10 10"
                          fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 10L1.1365e-06 -1.1365e-06L10 -2.62268e-07L5 10Z" fill="#0078D4" />
                        </svg>
                      </div>
                      {{ detailView.PCGQuota.ChangePercentage | percentage }}
                    </div>
                    <div class="collapse__col collapse__col-val">{{ detailView.PCGQuota.BeforeQuota | customNumber }}
                    </div>
                    <div class="collapse__col collapse__col-val">{{ detailView.PCGQuota.AfterQuota | customNumber }}
                    </div>
                  </div>
                </div>
              </div>
              <ngb-accordion #acc="ngbAccordion" class="inner__level" *ngFor="let pcc of detailView.DeltaPcc"
              [activeIds]="activePccIds">
              <ngb-panel id="{{pcc.id}}">
                <ng-template ngbPanelHeader>
                    <div class="collapse__header collapse__header-line">
                      <div  role="button" ngbPanelToggle aria-label="toggle button" class="icon collapse__btn"
                        aria-labelledby="mqch_tile_6"
                        [ngClass]="{ 'icon-chevron-down': !acc.isExpanded('custom_panel_1_3'), 'icon-chevron-up': acc.isExpanded('custom_panel_1_3') }"></div>
                      <div class="collapse__title collapse__title-blue" id="mqch_tile_6">
                        <div class="collapse__title__desc">{{ pcc.PCCName }}</div>
                        <div class="collapse__title__val"></div>
                      </div>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="collapse__content collapse__content-white">
                      <div class="collapse__row">
                        <div class="collapse__col collapse__col-desc">Change Type</div>
                        <div class="collapse__col collapse__col-desc">Reason Code Provided</div>
                        <div class="collapse__col collapse__col-desc">Action</div>
                        <div class="collapse__col collapse__col-desc collapse__col__col2">Details</div>
                        <!-- <div class="collapse__col collapse__col-desc">Change Date</div> -->
                      </div>
                      <div class="collapse__row" *ngFor="let reason of pcc.ChangeParams">
                        <div class="collapse__col">{{ reason.ChangeType }}</div>
                        <div class="collapse__col">{{ reason.FieldFacingReason }}</div>
                        <div class="collapse__col">{{ reason.Action }}</div>
                        <div class="collapse__col collapse__col__col2">
                          <div>
                            {{ checkAttributeChanged(reason) }}
                            <a *ngIf="checkForPopup(reason)" (click)="openModal(mqchTemplate, reason)">click here</a>
                          </div>
                        </div>
                        <!-- <div class="collapse__col">{{ reason.EventDate | date: 'mediumDate' }}</div> -->
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>

    <div class="static__text">
      <div class="static__text-head">
        Questions about changes in your plan? Follow these recommended steps to get more information...
      </div>
      <div class="static__text-note">
        <span class="static__text-note-bold">Territory Changes: </span> Check with your manager if they have more
        context behind the change.
        If you need additional information, reach out to your local business partner who manages territory submissions
        for your role/geo.
      </div>
      <div class="static__text-note">
        <span class="static__text-note-bold">Quota Changes: </span> Ask your manager if they have more information
        behind this change.
        You may also reach out to your Sales Excellence or your local business partner who manages quota submissions for
        your role/geo.
      </div>
      <div class="static__text-note">
        <span class="static__text-note-bold">Adjustments: </span> Check your Payout Details report to see what
        adjustments were applied to your plan and whether they are part of active incentive programs for your role.
        If you don’t recognize the adjustment, submit a ticket by clicking 'Support' at the top-right of the screen.
      </div>
      <div class="static__text-note">
        <span class="static__text-note-bold">Role Changes: </span> Your manager should be able to confirm your role
        information is accurate via the Employee Central tool.
      </div>
      <div class="static__text-note">
        <span class="static__text-note-bold">Plan Changes: </span> WWIC sends communications before making changes to
        your plan.
        If you have questions about a plan change, we recommend you start by checking your inbox for a notice from WWIC
        Communications around the date of the change or asking your managers or peers.
      </div>
    </div>
  </div>

  <ng-template id="mqch_pop_1" #mqchTemplate>
    <div class="popup__close">
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="popup__header">Here's what we found ...</div>
      <div class="popup__disclaimer">Disclaimer : Below details are subject to what is retreived from the upstream data
        sources. Mint does not modify or append any details</div>
      <hr>
      <div class="popup__result">Results ({{popupAttributes.length}})</div>
      <div class="popup__content">
        <div class="popup__content-heading">
          <div *ngFor="let header of popupHeader" class="popup__content-heading-cell">{{ header }}</div>
        </div>
        <div>
          <div class="popup__content-body"
            *ngFor="let params of popupAttributes | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize; index as i">
            <div *ngFor="let key of popupHeader" class="popup__content-body-cell">{{ params[key] }}</div>
          </div>
        </div>
      </div>

      <div class="popup__pagniation" *ngIf="popupAttributes.length > 10">
        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="popupAttributes.length">
        </ngb-pagination>
      </div>
    </div>
  </ng-template>