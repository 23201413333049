import { Component, OnInit } from '@angular/core';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  // Progress bar reference
  progressBar: NgProgressRef;

  constructor(
    private ngProgress: NgProgress
  ) {
    this.initializeProgressBar();
  }

  initializeProgressBar() {
    this.progressBar = this.ngProgress.ref();
    // Get progress bar events (optional)
    this.progressBar.started.subscribe(() => this.onProgressStarted());
    this.progressBar.completed.subscribe(() => this.onProgressCompleted());
  }

  onProgressStarted() { }

  onProgressCompleted() { }

  ngOnInit() {
  }

}
