import { Component, OnInit, Input } from '@angular/core';
import { PayoutReportService } from '../../payout-report.service';

@Component({
  selector: 'mint-payout-report-adjustment-details',
  templateUrl: './adjustment-details.component.html',
  styleUrls: ['./adjustment-details.component.scss']
})
export class AdjustmentDetailsComponent implements OnInit {
  @Input() adjustmentInfo: any;
  displayedColumns: string[] = ['AdjustmentName', 'Reason', 'Comments', 'AdjustmentEffectiveDate', 'AdjustmentAmount'];
  constructor(public payoutReportService: PayoutReportService) {}

  ngOnInit() {}

}
