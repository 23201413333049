import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/user-sidebar/sidebar.component';
import { SharedModule } from '../shared/shared.module';
import { A11yModule } from '@angular/cdk/a11y';
import { HeaderComponent } from './header/header.component';
import { AdminSidebarComponent } from './sidebar/admin-sidebar/admin-sidebar.component';
import { AvatarModule } from 'ngx-avatar';
import { NotificationModule } from '../notifications/notification.module';


@NgModule({
  declarations: [SidebarComponent, HeaderComponent, AdminSidebarComponent],
  imports: [
    CommonModule, MatIconModule, RouterModule, SharedModule, AvatarModule, A11yModule, NotificationModule,
  ],
  exports: [SidebarComponent, HeaderComponent, AdminSidebarComponent],
  providers: []
})
export class LayoutModule { }
