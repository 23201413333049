import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoadUserProfileFailure, LoadUserProfileSuccess, UserProfileActionTypes, UserProfileActions } from './user-profile.actions';
import { EndpointService } from '@mint-libs/network';
import { HttpParams } from '@angular/common/http';
import { DataService } from '../../network/data.service';
import { GraphService } from '../../graph-service';
import { SetFilters } from '../../filters/state/filters.actions';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class UserProfileEffects {
  @Effect()
  loadUserProfiles$ = this.actions$.pipe(
    ofType(UserProfileActionTypes.LoadUserProfile),
    concatMap(action => {
      let params = new HttpParams();
      if (action && action.participationid) {
        params = params.append('participationid', action.participationid);
      }
      return this.dataService.get(this.endpoints.AuthorizeAndGetParticipant.uri, params).pipe(
        map(data => new LoadUserProfileSuccess({ data })),
        catchError(error => of(new LoadUserProfileFailure({ error })))
      );
    })
  );

  @Effect()
  GetGraphDetails$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserProfileActionTypes.GetGraphDetails),
        concatMap(action => {
          return this.graphService.getThumbnailPhoto(action.userAlias).pipe(
            tap(data => {
              const userInfoList: any[] = JSON.parse(window.localStorage.getItem('impersonationList') ?? '[]');
              if (userInfoList) {
                const user = userInfoList.find(userInfo => userInfo.userAlias === action.userAlias.toLowerCase());
                user.thumbnailPhoto = data;
                window.localStorage.setItem('impersonationList', JSON.stringify(userInfoList));
              }
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  constructor(private actions$: Actions<UserProfileActions>, private dataService: DataService, private endpoints: EndpointService, private graphService: GraphService) { }
}
