
<mint-content-loader-bar-chart-horizontal *ngIf="!isLoaded"></mint-content-loader-bar-chart-horizontal>
<div *ngIf="isLoaded">  
  <div class="pcg__header">
    <h4 class="pcg__header___heading">Priority Solution Target Attainment</h4>    
  </div>
  <mint-bonus-chart-horizontal [item]="item" class="pcg__items" *ngFor="let item of bonusComponents"></mint-bonus-chart-horizontal>
  
  

  <div class="pcg__header pcg__header-total" *ngIf="bingoComponents && bingoComponents.length > 0">
    <h4 class="pcg__header__txt">Priorities+ Excellence Bonus</h4>
    <div>
      You can earn an additional 2.5% of your ITA once you hit all your priority products.
    </div>
  </div>

  <mint-bonus-chart-horizontal [item]="item" *ngFor="let item of bingoComponents" ></mint-bonus-chart-horizontal>
</div>