import { Component, Inject, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service';
import { Formatter } from '@mint-libs/common';
import { ReportType } from '../report-type.enum';
import { BaseRevenueComponent } from '../base-revenue.component';
import { MessagesService } from '@mint-libs/context';
import { Code } from '../../app.constants';
import { RevenueTerritory } from '../reports-shared/models/revenue-territory.model';
import { ReportGrid } from '../reports-shared/models/report-grid.model';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { ExportToExcelService } from '../../shared/excel-export/export-to-excel.service';
import { FiltersService } from '../../filters/filters.service';
import { LoggerService } from '@mint-libs/logging';
@Component({
  selector: 'mint-territory-report',
  templateUrl: './territory-report.component.html',
  styleUrls: ['./territory-report.component.scss'],
  providers: [Code]
})
export class TerritoryReportComponent extends BaseRevenueComponent implements OnInit {
  public defaultTabId = 2;
  public activeTabId = 2;
  public filterTabId = 4;

  public filterTab: any;
  public tabs = [
    {
      id: 1,
      title: 'All Territories',
      rowCount: null,
      disabled: true,
      dimList: null
    },
    {
      id: 2,
      title: 'Top ',
      rowCount: 10,
      disabled: false,
      dimList: null
    },
    {
      id: 3,
      title: 'Top ',
      rowCount: 20,
      disabled: false,
      dimList: null
    },
    {
      id: 4,
      title: 'Search Results ',
      rowCount: 0,
      disabled: true,
      dimList: []
    }
  ];
  columns: ReportGrid[] = [
    {
      field: 'name',
      columnName: 'Incentive Territory Name',
      width: 200,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'type',
      columnName: 'Incentive Territory Type',
      width: 180,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'pcgName',
      columnName: 'Bucket Name',
      width: 200,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'pccName',
      columnName: 'Metric Name',
      width: 200,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.month',
      columnName: 'Fiscal Month',
      format: '{0: MMMM, yyyy}',
      width: 150,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.totalTarget',
      columnName: 'Total Target',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.totalRecurringTarget',
      columnName: 'Total Recurring Target or Baseline',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.totalActual',
      columnName: 'Total Actuals',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.quotaToGo',
      columnName: 'Quota To Go',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.metric',
      columnName: 'Unit',
      width: 120,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    }
  ];
  revenueData: RevenueTerritory[] = [];

  constructor(
    public reportService: ReportsService,
    private formatter: Formatter,
    public messageService: MessagesService,
    public exportToExcelService: ExportToExcelService,
    public loggingService: LoggerService,
    public filterService: FiltersService
  ) {
    super(reportService, messageService, filterService);
    this.revenueReportType = ReportType.TerritoryReport;
  }

  ngOnInit() {
    this.loggingService.trackPageView('Report-Revenue By Territories', null);
    this.filterTab = this.tabs.find(t => t.id === this.filterTabId);

    this.initPayoutDisclaimer();
    this.initTargetSummary(this.removeDynamicsPcc);
    this.tabsChange(this.activeTabId);
  }

  private initDetailedTerritories(dimListName, rowCount) {
    this.reportService.getDetailedTerritories(dimListName, rowCount).subscribe(response => {
      if (response) {
        this.revenueData = [];
        this.isLargeUser = response.isLargeUser;
        response.data.forEach(res => {
          const revenueTerritory = new RevenueTerritory().deserializeSingle(res);
          revenueTerritory.revenue.quotaToGo = revenueTerritory.isPODBasedQuota ? 0 : Math.max(revenueTerritory.revenue.totalTarget - revenueTerritory.revenue.totalActual, 0);
          revenueTerritory.revenue.actualAmount =
            revenueTerritory.revenue.actualAmount != null && revenueTerritory.revenue.activeUsageAmount != null
              ? revenueTerritory.revenue.actualAmount + revenueTerritory.revenue.activeUsageAmount
              : 0;
          this.revenueData.push(revenueTerritory);
        });
        this.initPccChart(this.PCCs, this.selectedPccChart, this.revenueReportType);
      }
    });
  }

  public filterSelectedPcc(selectedPccChart) {
    this.selectedPccChart = selectedPccChart;
    let revenueDataFilteredByPcc = this.revenueData.filter(revenue => revenue.pccID === selectedPccChart?.id);
    if (selectedPccChart.isPointInTime) {
      revenueDataFilteredByPcc = revenueDataFilteredByPcc.filter(revenue => this.formatter.transformToDateFormat(revenue.revenue.month, 'MMMM, yyyy', 'en-US').split(',')[0] === this.getPITMonth());
    }
    this.initRevenueChart(revenueDataFilteredByPcc);
  }

  public initRevenueChart(revenueData: RevenueTerritory[]) {
    this.chartDataRevenue = [];
    const groupedByDimNameData = this.groupBy(revenueData, (r: RevenueTerritory) => r.name);
    for (const dimName in groupedByDimNameData) {
      const revenueBarChart = {
        desc: dimName,
        val: 0
      };
      groupedByDimNameData[dimName].forEach(r => {
        if (r.Type !== 'Role-Based Quota') {
          revenueBarChart.val += r.revenue.totalActual;
        }
      });
      this.chartDataRevenue.push(revenueBarChart);
    }
    this.chartDataRevenue = this.chartDataRevenue.sort((a, b) => b.val - a.val);
  }

  public search(queryText: string) {
    this.searchDimNames(queryText, ReportType.TerritoryReport);
  }

  public filterTerritories(selectedfilters) {
    this.filterTab.dimList = selectedfilters;
    this.filterTab.rowCount = selectedfilters?.length;

    if (selectedfilters?.length === 0 || !selectedfilters) {
      this.activeTabId = this.defaultTabId;
      this.tabsChange(this.activeTabId);
      this.filterTab.disabled = true;
      return;
    }
    this.activeTabId = this.filterTabId;
    this.filterTab.disabled = false;
    this.tabsChange(this.activeTabId);
  }

  public tabsChange(tabId) {
    this.activeTabId = tabId;
    const activeTab = this.tabs.find(t => t.id === this.activeTabId);
    this.initDetailedTerritories(activeTab.dimList, activeTab.rowCount);
  }

  onRevenueExcelExport(postBody) {
    this.revenueExcelConfigs.fileName = this.exportToExcelService.getFileName('CompActualsByIT', true);
    const $result = this.reportService.exportRevenueTerritoryDetail(postBody);

    this.exportToExcelService.handleExcelDownload($result, this.revenueExcelConfigs);
  }
}
