import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, concatMap, mergeMap, tap, filter } from 'rxjs/operators';
import { FilterActions, ResetFilters, SetFilters } from './filters.actions';
import { Router } from '@angular/router';
import { LoadUserProfileSuccess, ResetUserProfile, UserProfileActionTypes } from '../../user-profile/state/user-profile.actions';
import { Observable } from 'rxjs';
import { UserProfile } from '../../user-profile/models/user-profile.model';
// import * as NavigationActions from '../../../routing/state/navigation.actions';

@Injectable()
export class FilterEffects {
  // navigate$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(FiltersActions.UpdateFilters),
  //     map(action => {
  //       return NavigationActions.refresh({ path: this.router.url });
  //     })
  //   );
  // });

  resetUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActionTypes.ResetUserProfile),
      concatMap(action => {
        return new Observable<ResetFilters>(s => s.next(new ResetFilters()));
      })
    )
  );

  setFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActionTypes.LoadUserProfileSuccess),
      mergeMap((action: LoadUserProfileSuccess) => {
        return new Observable<SetFilters>(subscriber => {
          const userProfile = action.payload.data;
          subscriber.next(new SetFilters({ userProfile: new UserProfile().deserialize(userProfile) }));
        });
      })
    )
  );

  constructor(private actions$: Actions<FilterActions>, private router: Router) {}
}
