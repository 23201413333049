import { Component, OnInit, Input } from '@angular/core';
import { BonusAttainmentDetails } from '../../shared/models/bonus-account-details.model';
import { BonusAwardSummary } from '../../shared/models/bonus-award-summary.model';

@Component({
  selector: 'mint-3y3c-bonus-pct',
  templateUrl: './3y3c-bonus-pct.component.html',
  styleUrls: ['./3y3c-bonus-pct.component.scss']
})
export class BonusPct3y3cComponent implements OnInit {
  
  constructor() { }
  @Input() bonusComponents: BonusAttainmentDetails[];
  @Input() awardSummary: BonusAwardSummary[];
  totalPercentage: number=0;
  //bonusComponents : BonusAttainmentDetails[];
  isLoaded = false;
  ngOnInit() {
 
    this.bonusComponents = this.bonusComponents.map(x=> x); // Array.from(this.bonusComponentsRow);
    this.bonusComponents.sort((a,b) => a.locked && !b.locked ? 1: -1 );
    this.bindTotalPercentage();
    this.isLoaded = true;

  }

  bindTotalPercentage(){
    this.bonusComponents.forEach(comp=>{
      if(comp.locked==false){
        const percentageValue = +comp.percentageValue;
        this.totalPercentage =  this.totalPercentage + percentageValue;
      }
    });
  }
  
}
