import { SafeHtml } from '@angular/platform-browser';
import { DataSource } from './data-source.model';
import { BaseModel } from './base.model';
import { Expose, deserializeArray, plainToClass } from 'class-transformer';

export class CagrAttainmentDetails extends BaseModel {
  @Expose({ name: 'PCGName' }) pCGname: string;
  @Expose({ name: 'PCGId' }) pCGId: string;
  @Expose({ name: 'PCCId' }) pCCId: string;
  @Expose({ name: 'Name' }) name: string;
  @Expose({ name: 'Actuals' }) actuals: string;
  @Expose({ name: 'Target' }) target: string;
  @Expose({ name: 'Attainment' }) attainment: string;
  @Expose({ name: 'LockStatus' }) locked: boolean;
  @Expose({ name: 'Currency' }) currency: string;
  @Expose({ name: 'StartFiscalYear' }) startFiscalYr: string;
  @Expose({ name: 'EndFiscalYear' }) endFiscalYr: string;
  @Expose({ name: 'DataSource' }) dataSource: DataSource;
  @Expose({ name: 'PCC' }) pCC: Array<CagrAttainmentDetails>;
  @Expose({ name: 'MultiPcc' }) multiPcc: boolean;
  @Expose({ name: 'ActualAdjustment' }) actualAdjustment: string;
  @Expose({ name: 'QuotaAdjustment' }) quotaAdjustment: string;
  pCGRowSpan: number;
  pccNameGrid: string;
  isTotalCloudGrowth: boolean;
  tooltip: string;

  deserialize(from: any): CagrAttainmentDetails[] {
    const cagrAccountDetailsList = deserializeArray<CagrAttainmentDetails>(CagrAttainmentDetails, JSON.stringify(from), this.classTransformOptions);
    return cagrAccountDetailsList;
  }
  deserializeSingle(from: object): CagrAttainmentDetails {
    const cagrAccountDetails = plainToClass<CagrAttainmentDetails, object>(CagrAttainmentDetails, from, this.classTransformOptions);
    return cagrAccountDetails;
  }
}
