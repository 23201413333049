import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TBonusSummary } from '@mint-libs/context';

@Component({
  selector: 'div[mint-bonus-awards-summary-header]',
  templateUrl: './bonus-awards-summary-header.component.html',
  styleUrls: ['./bonus-awards-summary-header.component.scss']
})
export class BonusAwardsSummaryHeaderComponent implements OnInit, OnChanges {
  @Input()
  bonusSummary: TBonusSummary;

  isBucketUnlockCountEnabled: boolean = false;
  isPotentialAwardEnabled: boolean = false;
  isPercentPotentialAwardEnabled: boolean = false;
  isCurrentITAEnabled: boolean = false;
  notes: string = '';
  
  unlockedProductsCount: number = 0.0;
  totalProductsCount: number = 0.0;
  totalUnlockedItems: number = 0;
  potentialAwardTotal: number = 0;
  currencyCode: string = '';
  percentagePotentialUnlocked: number = 0;
  derivedValue: number = 0.0;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.bonusSummary.configuration && this.bonusSummary.configuration.awardsSummary) {
      this.isBucketUnlockCountEnabled = this.bonusSummary.configuration.awardsSummary.isBucketUnlockCountEnabled;
      this.isPotentialAwardEnabled = this.bonusSummary.configuration.awardsSummary.isPotentialAwardEnabled;
      this.isPercentPotentialAwardEnabled = this.bonusSummary.configuration.awardsSummary.isPercentPotentialAwardEnabled;
      this.isCurrentITAEnabled = this.bonusSummary.configuration.awardsSummary.isCurrentITAEnabled;
      this.notes = this.bonusSummary.configuration.awardsSummary.notes;
    }

    if (this.bonusSummary.attribute && this.bonusSummary.attribute) {
      if (this.bonusSummary.attribute.productsSummary) {
        this.unlockedProductsCount = this.bonusSummary.attribute.productsSummary.unlockedProductsCount;
        this.totalProductsCount = this.bonusSummary.attribute.productsSummary.totalProductsCount;
      }
      this.totalUnlockedItems = this.bonusSummary.attribute.totalUnlockedItems.countValue;
      this.potentialAwardTotal = this.bonusSummary.attribute.potentialAwardTotal;
      this.currencyCode = this.bonusSummary.attribute.currencyCode;
      this.percentagePotentialUnlocked = this.bonusSummary.attribute.percentagePotentialUnlocked;
      this.derivedValue = this.bonusSummary.attribute.derivedValue;
    }
    
  }

}
