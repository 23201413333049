<div
  id="multi_select_control"
  tabindex="0"
  role="listbox"
  *ngIf="data != null"
  [attr.aria-label]="'multi select dropdown ' + (dropdownClosed ? '' : 'opened') + ', press Enter to ' + (dropdownClosed ? 'open' : 'close or Tab to navigate through the options')"
  class="multiselect-dropdown"
  (clickOutside)="closeDropdown()"
  (click)="toggleDropdown($event)"
  (keyup.enter)="toggleDropdown($event)"
>
  <span *ngIf="selectedItems" tabindex="-1" class="dropdown-btn">
    <span *ngIf="!selectedItems.length">{{ placeholder }}</span>
    <span class="selected-item" *ngFor="let item of selectedItems; trackBy: trackByFn; let k = index" [hidden]="k > maxDisplaySelection - 1">
      {{ item }}
      <a style="padding-top:2px;padding-left:2px;color:white" (click)="onItemClick($event, item)">x</a>
    </span>
    <span style="float:right !important;padding-right:4px">
      <span style="padding-right: 6px;" *ngIf="itemShowRemaining() > 0">+{{ itemShowRemaining() }}</span>
      <span [ngClass]="dropdownClosed ? 'dropdown-down' : 'dropdown-up'"></span>
    </span>
  </span>
  <div class="dropdown-list" aria-label="dropdown opened press tab to navigate through options" [hidden]="dropdownClosed" *ngIf="selectedItems">
    <ul role="option" class="item1">
      <li (keyup.enter)="toggleSelectAll()" (click)="toggleSelectAll()" class="multiselect-item-checkbox" style="border-bottom: 1px solid #ccc;padding:10px">
        <input type="checkbox" aria-label="select all" [checked]="isAllItemsSelected()" tabindex="0" />
        <div>Select All</div>
      </li>
    </ul>
    <ul role="option" class="item2" [style.maxHeight]="maxHeight + 'px'">
      <li *ngFor="let item of data; let i = index" (keyup.enter)="onItemClick($event, item)" (click)="onItemClick($event, item)" class="multiselect-item-checkbox">
        <input type="checkbox" [attr.aria-label]="item + ' ' + (i + 1) + ' of ' + data.length" [checked]="isSelected(item)" tabindex="0" />
        <div>{{ item }}</div>
      </li>
      <li class="no-data" *ngIf="data.length == 0">
        No Data Available
      </li>
    </ul>
  </div>
</div>
