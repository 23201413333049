<app-progress-bar id="account-report_0" class="sticky__progress__bar"></app-progress-bar>
<div id="product-report_1" class="note-container">
  <div id="product-report_2" *ngIf="reportDisclaimer" class="note"><strong id="product-report_3">Note: </strong> {{ reportDisclaimer }}</div>
</div>
<div id="product-report_4" class="report-container">
  <div class="pull-right">
    <app-tour id="revenueTerritoryDetail_49_tour" [tourName]="'Compensation Actuals by Product Report'"></app-tour>
  </div>
  <div id="product-report_5" class="row mt-20">
    <div id="product-report_6" class="col-xs-12 col-md-6 vr-line">
      <div id="product-report_7" *ngIf="selectedPccChart" class="chart-title">Compensation Actuals by Metric</div>
      <div id="product-report_8" class="chart-container">
        <mint-metric-chart
          id="product-report_9"
          *ngIf="chartDataPccs"
          [dataset]="chartDataPccs"
          [doNotShowToolTip]="true"
          [chartHelpText]="'Revenue by Metric bar chart X axis is Metric Y axis is Revenue'"
          class="pcg__item__chart"
          [containerId]="'product-report-pcc-chart'"
          (chartClick)="filterSelectedPcc($event)"
        ></mint-metric-chart>
        <mint-content-loader-bar-chart-vertical id="product-report_10" *ngIf="!chartDataPccs"></mint-content-loader-bar-chart-vertical>
      </div>
    </div>

    <div id="product-report_11" class="col-xs-12 col-md-6">
      <div id="product-report_12" *ngIf="selectedPccChart" class="chart-title">{{ selectedPccChart.desc }}</div>
      <div id="product-report_13" class="chart-container">
        <mint-metric-chart
          id="product-report_14"
          *ngIf="chartDataRevenue"
          [dataset]="chartDataRevenue"
          [doNotShowToolTip]="true"
          [chartHelpText]="'Revenue by products for ' + selectedPccChart.desc + ' bar chart X axis is products Y axis is Revenue'"
          class="pcg__item__chart"
          [containerId]="'product-report-actuals-chart'"
        ></mint-metric-chart>
        <mint-content-loader-bar-chart-vertical id="product-report_15" *ngIf="!chartDataRevenue"></mint-content-loader-bar-chart-vertical>
      </div>
    </div>
  </div>
  <hr id="product-report_17" class="hr-line" />
  <div id="product-report_18" class="row">
    <mint-report-grid
      *ngIf="revenueData?.length"
      [columns]="columns"
      [data]="revenueData"
      (exportToExcel)="onRevenueExcelExport($event)"
      [excelDownloadStatus]="revenueExcelConfigs.excelDownloadStatus"
      [excelDownloadMessage]="revenueExcelConfigs.excelDownloadMessage"
      [excelTitle]="revenueReportType"
    ></mint-report-grid>
    <div id="product-report_20" *ngIf="!revenueData?.length" class="content__loader">
      <mint-content-loader-grid id="product-report_21"></mint-content-loader-grid>
    </div>
  </div>
</div>
