import { Formatter } from '@mint-libs/common';
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-metric-renderer',
  template: `
    <div style=" font-size: 14px;display: flex;align-items: center;position: relative;" *ngIf="params.value">{{ params.value }}</div>
  `
})
export class CagrPccRendererComponent implements ICellRendererAngularComp {
  params: any;
  thresholdInfo: any;
  public shouldShowThresholdInfo = false;
  public data: number;
  constructor(private formatter: Formatter) {}
  agInit(params): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
