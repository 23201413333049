export class PageField{
    FieldName:string
    SortFilterValues:boolean
    PreSelectedValue:string

    constructor(FieldName:string, SortFilterValues:boolean=false, PreSelectedValue:string=""){
        this.FieldName = FieldName;
        this.SortFilterValues = SortFilterValues;
        this.PreSelectedValue = PreSelectedValue;
    }
}

export class PivotConfiguration{
    RowFields:string[]
    ColumnFields:string[]
    PageFields: PageField[]
    DataFields:string[]

    constructor(rowFields:string[], columnFields:string[],pageFields: PageField[], dataFields:string[] ){
        this.RowFields= rowFields
        this.ColumnFields = columnFields
        this.PageFields = pageFields
        this.DataFields = dataFields
    }
}
