import { Expose, Type } from 'class-transformer';
import { BaseModel } from '../../../shared/models/base.model';
import { Revenue } from './revenue.model';

export class RevenueAccount extends BaseModel {
  @Expose({ name: 'AccountName' })
  name: string;

  @Expose({ name: 'Type' })
  type: string;

  @Expose({ name: 'PCCName' })
  pccName: string;

  @Expose({ name: 'PCCID' })
  pccID: number;

  @Expose({ name: 'PCGName' })
  pcgName: string;

  @Type(() => Revenue)
  @Expose({ name: 'Revenue' })
  revenue: Revenue;

  deserialize(from: any): RevenueAccount[] {
    const revenueAccounts: RevenueAccount[] = [];
    from.forEach(a => {
      const account = new RevenueAccount();
      account.revenue = new Revenue().deserializeSingle(a.Revenue);
      account.name = a.AccountName;
      account.type = a.Type;
      account.pccName = a.PCCName;
      account.pccID = a.PCCId;
      account.pcgName = a.PCGName;
      revenueAccounts.push(account);
    });
    return revenueAccounts;
  }
  deserializeSingle(from: any): RevenueAccount {
    const account = new RevenueAccount();
    account.revenue = new Revenue().deserializeSingle(from.Revenue);
    account.name = from.AccountName;
    account.type = from.Type;
    account.pccName = from.PCCName;
    account.pccID = from.PCCId;
    account.pcgName = from.PCGName;
    return account;
  }
}
