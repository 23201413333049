import { Injectable } from '@angular/core';
import { ContextState } from '@mint-libs/context';
import { selectConfigurationState } from '@mint-libs/configuration';
import { SharedContext, ToDateFormatPipe } from '@mint-libs/common';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private _fiscalYearList: any[];
  private _managerViewFiscalYearsList: any[];
  private _standardTitleList: any[];
  private _managerDashboardPartitionsList: any[];
  private _selectedFiscalYear: number;
  private _selectedManagerViewFiscalYear: number;
  private _selectedStandardTitle: any;
  private _selectedStepId: number;
  private _selectedFiscalQuarterId: number;
  private _selectedFiscalSemesterId: number;
  private _selectedCodePeriod: string;
  private _selectedManagerViewCodePeriod: string;
  private _selectedManagerViewStepId: number;
  private _applicablePartitionsList: any[];

  private latestFiscalYear: number;
  private isHalfYearlyQuotaSupported: boolean;
  private isPayoutNotAvailable: boolean;

  personnelNumber: number;
  selectedFiscalMonthNumber: number;
  selectedManagerViewFiscalYear: number;
  isManagerViewPartitionLoaded: boolean;
  isManagerViewPartitionChanged: boolean;
  managerViewPartitions: any;
  previousValues: any;
  isFilterUpdated: boolean;
  payeeId: number;
  oldState: any;
  managerViewFiscalYears: number[];
  sharedContext = new SharedContext();

  constructor(private contextState: Store<ContextState>, private sharedContextState: Store<SharedContext>, private toDateFormat: ToDateFormatPipe) {
    this.sharedContextState.subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      if (this.sharedContext.appliedFilter) {
        this.setFilters(true);
      }
    });
    this.getConfigDetails();
  }

  public setFilters(forceLoad) {
    let sharedContext = {
      ...this.sharedContext,
      appliedFilter: JSON.parse(JSON.stringify(this.sharedContext.appliedFilter)),
      FiscalYears: this.sharedContext.FiscalYears ? [...this.sharedContext.FiscalYears].sort() : null,
      ManagerViewFiscalYears: this.sharedContext.ManagerViewFiscalYears ? [...this.sharedContext.ManagerViewFiscalYears].sort() : null
    };

    if (forceLoad === true) {
      //Do not set, if already
      this.fiscalYearList = sharedContext.FiscalYears == null ? null : sharedContext.FiscalYears;
      this.standardTitleList = sharedContext.StandardTitles;
      this.managerDashboardPartitionsList = sharedContext.managerDashboardPartitionsList;
      this.managerViewFiscalYearsList = sharedContext.ManagerViewFiscalYears == null ? null : sharedContext.ManagerViewFiscalYears;

      this.selectedFiscalYear = Number(sharedContext.DefaultFilter.FiscalYear);

      if (this.managerViewFiscalYearsList && this.managerViewFiscalYearsList.length > 0) {
        this.selectedManagerViewFiscalYear = Number(this.managerViewFiscalYearsList[0].Key);
      }

      this.selectedStandardTitle = sharedContext.DefaultFilter.StandardTitle;
      this.selectedStepId = sharedContext.DefaultFilter.StepId;
      this.selectedFiscalQuarterId = sharedContext.DefaultFilter.FiscalQuarterId;
      this.selectedFiscalSemesterId = sharedContext.DefaultFilter.FiscalSemesterId;
      this.payeeId = sharedContext.DefaultFilter.StandardTitle ? sharedContext.DefaultFilter.StandardTitle.PayeeId : null;
      this.personnelNumber = sharedContext.personnelNumber;
      this.selectedManagerViewStepId = sharedContext.DefaultFilter.StepId;
    }

    if (this.oldState && sharedContext.isImpersonating === false && sharedContext.isViewAs === false) {
      this.selectedFiscalYear = this.oldState.selectedFiscalYear;
      this.selectedStandardTitle = this.oldState.selectedStandardTitle;
      this.selectedStepId = this.oldState.selectedStepId;
      this.selectedFiscalQuarterId = this.oldState.selectedFiscalQuarterId;
      this.selectedFiscalSemesterId = this.oldState.selectedFiscalSemesterId;
      this.selectedManagerViewStepId = this.oldState.selectedManagerViewStepId;
      this.selectedManagerViewFiscalYear = this.oldState.selectedManagerViewFiscalYear;

      this.oldState = null;
    }
  }

  getConfigDetails() {
    this.contextState
      .pipe(
        select(selectConfigurationState),
        filter(val => val !== undefined && val !== null)
      )
      .subscribe(state => {
        if (!state || !state.configs) {
          return;
        }
        this.latestFiscalYear = Number(state.configs.latestFiscalYear);
        this.isHalfYearlyQuotaSupported = state.configs.isHalfYearlyQuotaSupported;
        this.isPayoutNotAvailable = state.configs.isPayoutUnavailable;
      });
  }

  // Properties - START
  get selectedFiscalYear() {
    return this._selectedFiscalYear;
  }
  set selectedFiscalYear(value) {
    this._selectedFiscalYear = value;
  }

  get fiscalYearList() {
    return this._fiscalYearList;
  }
  set fiscalYearList(value) {
    this._fiscalYearList = value;
  }

  get managerViewFiscalYearsList() {
    return this._managerViewFiscalYearsList;
  }
  set managerViewFiscalYearsList(value) {
    this._managerViewFiscalYearsList = value;
  }

  get standardTitleList() {
    return this._standardTitleList;
  }
  set standardTitleList(value) {
    this._standardTitleList = value;
    if (!this._standardTitleList) {
      return;
    }
    this._standardTitleList = this._standardTitleList.map(function(standardTitlesItem) {
      const standardCopy = JSON.parse(JSON.stringify(standardTitlesItem));
      const applicablePartitions = standardCopy.ApplicablePartitions;
      if (applicablePartitions) {
        const applicablePartitionsLength = applicablePartitions.length;
        standardCopy.minStep = applicablePartitions[0].StepId;
        standardCopy.maxStep = applicablePartitions[applicablePartitionsLength - 1].StepId;
        standardCopy.FiscalQuarterId = applicablePartitions[0].FiscalQuarterId;
        standardCopy.FiscalSemesterId = applicablePartitions[0].FiscalSemesterId;
      }
      return standardCopy;
    }, this);
  }

  get managerDashboardPartitionsList() {
    return this._managerDashboardPartitionsList;
  }
  set managerDashboardPartitionsList(value) {
    this._managerDashboardPartitionsList = value;
  }

  get selectedStandardTitle() {
    return this._selectedStandardTitle;
  }
  set selectedStandardTitle(value) {
    this._selectedStandardTitle = { ...value };
    if (this._selectedStandardTitle) {
      const applicablePartitions = this._selectedStandardTitle.ApplicablePartitions;
      if (applicablePartitions) {
        const applicablePartitionsLength = applicablePartitions.length;
        this._selectedStandardTitle.minStep = applicablePartitions[0].StepId;
        this._selectedStandardTitle.maxStep = applicablePartitions[applicablePartitionsLength - 1].StepId;
      }
    }
  }

  get selectedStepId() {
    return this._selectedStepId;
  }
  set selectedStepId(value) {
    this._selectedStepId = value;
    if (this._selectedStepId !== 0 && this._selectedStandardTitle && this._selectedStandardTitle.ApplicablePartitions) {
      this._selectedStandardTitle.ApplicablePartitions.forEach(function(partition) {
        if (this._selectedStepId === partition.StepId) {
          this._selectedCodePeriod = partition.Key;
          return;
        }
      }, this);
    }
  }

  get selectedManagerViewStepId() {
    return this._selectedManagerViewStepId;
  }
  set selectedManagerViewStepId(value) {
    this._selectedManagerViewStepId = value;
    if (this._selectedManagerViewStepId !== 0 && this._managerDashboardPartitionsList) {
      const applicablYearPartitions = this._managerDashboardPartitionsList.filter(partition => {
        return (
          this._selectedManagerViewFiscalYear && this._selectedManagerViewFiscalYear === partition.FiscalYear && this._selectedManagerViewStepId && this._selectedManagerViewStepId === partition.StepId
        );
      });
      if (applicablYearPartitions && applicablYearPartitions.length > 0) {
        {
          this._selectedManagerViewCodePeriod = applicablYearPartitions[0].Key;
        }
      }
    }
  }

  get selectedCodePeriod() {
    return this._selectedCodePeriod;
  }

  get selectedManagerViewCodePeriod() {
    return this._selectedManagerViewCodePeriod;
  }
  set selectedManagerViewCodePeriod(value) {
    this._selectedManagerViewCodePeriod = value;
  }

  get selectedFiscalQuarterId() {
    return this._selectedFiscalQuarterId;
  }
  set selectedFiscalQuarterId(value) {
    this._selectedFiscalQuarterId = value;
  }

  get selectedFiscalSemesterId() {
    return this._selectedFiscalSemesterId;
  }
  set selectedFiscalSemesterId(value) {
    this._selectedFiscalSemesterId = value;
  }

  get applicablePartitionsList() {
    return this._applicablePartitionsList;
  }
  set applicablePartitionsList(value) {
    this._applicablePartitionsList = value;
  }
  // Properties - END

  isMic2Profile() {
    return this._selectedStandardTitle != null && this._selectedStandardTitle.isMic2Profile;
  }

  isSelectedFiscalYearLessThan2024(){
    return this._selectedFiscalYear != null && this._selectedFiscalYear < 2024;
  }

  isFSA() {
    return this._selectedFiscalYear != null && this._selectedFiscalYear >= 2021;
  }

  isNewFiscalYear() {
    return this._selectedFiscalYear != null && this._selectedFiscalYear === 2022;
  }

  isH2QuotaSupported(fiscalYear = this._selectedFiscalYear) {
    return this.isHalfYearlyQuotaSupported && fiscalYear != null && fiscalYear === this.latestFiscalYear;
  }

  isPayoutUnavailable() {
    return this.isPayoutNotAvailable && this._selectedFiscalYear != null && this._selectedFiscalYear === this.latestFiscalYear;
  }

  isLatestFiscalYear() {
    return this._selectedFiscalYear != null && this._selectedFiscalYear === this.latestFiscalYear;
  }

  getFormattedSelectedFiscalYear() {
    return 'FY' + this._selectedFiscalYear.toString().substring(2);
  }

  setPartitionText(key, startDate, endDate) {
    return key.split('-')[1] + ' (' + this.toDateFormat.transform(startDate, 'MMM d, yyyy') + ' - ' + this.toDateFormat.transform(endDate, 'MMM d, yyyy') + ')';
  }

  isSelectedFiscalYear2021() {
    return this.selectedFiscalYear != null && this.selectedFiscalYear === 2021;
  }
}
