import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { TBonusAttributeDetailItem, BonusConstants } from '@mint-libs/context';
import { Formatter } from '@mint-libs/common';
import { CustomHeaderComponent } from '../../../shared/grid-components/custom-header/custom-header.component';
import { LegendService } from '../../../shared/legends/legend.service';
import { BaseGridComponent } from '../../../shared/metric-grid/base-grid.component';
import { BonusAmountRendererComponent } from './renderers/bonus-amount-renderer.component';
import { BonusAttainmentRendererComponent } from './renderers/bonus-attainment-renderer.component';
import { BonusMetricRendererComponent } from './renderers/bonus-metric-renderer.component';
@Component({
  selector: 'mint-bonus-grain-item-grid',
  templateUrl: './bonus-grain-item-grid.component.html',
  styleUrls: ['./bonus-grain-item-grid.component.scss']
})
export class BonusGrainItemGridComponent extends BaseGridComponent implements OnInit {
  @Input()
  grainItems: TBonusAttributeDetailItem[] = [];
  @Input()
  isBingo: boolean = false;


  columnDefs = [];
  rowData = [];
  defaultColDef = {
    sortable: true,
    filter: true,
  };
  
  bingoBonusColumns = ['planComponentName', 'attainment', 'lockStatus'];

  frameworkComponents = {
    agColumnHeader: CustomHeaderComponent,
    metricRenderer: BonusMetricRendererComponent,
    attainmentRenderer: BonusAttainmentRendererComponent,
    amountRenderer: BonusAmountRendererComponent
  };

  constructor(protected legendService: LegendService, protected element: ElementRef, private formatter: Formatter) {
    super(legendService, 105, element);
    const showWithCurrency = this.formatter.formatTwoDecimalWithCurrency;
    const showWithPercent = this.formatter.formatTwoDecimalWithPercent;
    this.columnDefs = [
      {
        field: 'planComponentName',
        lockPosition: true,
        headerName: 'Metric',
        cellClassRules: {
          'cell-span-unlocked': function(params) {
            return !params.data.attribute.LockStatus;
          },
          'cell-span-locked': function(params) {
            return params.data.attribute.LockStatus;
          },
        },
        suppressNavigable: true,
        minWidth: 150,
        cellRenderer: 'metricRenderer',
        valueGetter: function(params) {
          if (params.data.isBingoBonus) {
            return BonusConstants.BINGO_BONUS_NAME;
          }
          if (!params.data.attribute.PlanComponentName) {
            if (params.data.configuration.planComponentSection.notes) {
              return params.data.configuration.planComponentSection.notes;
            }
          }
          return `${params.data.attribute.PlanComponentName}`;
        },
      },
      {
        field: 'attainment',
        lockPosition: true,
        headerName: 'Attainment',
        suppressNavigable: true,
        minWidth: 116,
        valueGetter: function(params) {
          if (params.data.isBingoBonus) {
            if (params.data.configuration.grainItemValueType === 'Percentage') {
              return `${showWithPercent(params.data.summary.attribute.productsSummary.unlockedProductsPercent)}`;
            }
            return `${params.data.summary.attribute.productsSummary.unlockedProductsCount} / ${params.data.summary.attribute.productsSummary.totalProductsCount}`;
          }
          return `${showWithPercent(params.data.attribute.Attainment)}`;
        },
        cellRenderer: 'attainmentRenderer'
      },
      {
        field: 'lockStatus',
        lockPosition: true,
        headerName: 'Status',
        valueGetter: function(params) {
          if (params.data.attribute.LockStatus === undefined || params.data.attribute.LockStatus === null) {
            return 'Error. Something went wrong.';
          }
          return params.data.attribute.LockStatus ? 'Locked' : 'Unlocked';
        },
        suppressNavigable: true,
        minWidth: 150,
      },
      {
        field: 'actual',
        lockPosition: true,
        headerName: 'Actuals',
        suppressNavigable: true,
        minWidth: 90,
        valueGetter: function(params) {
          return `${showWithCurrency(params.data.attribute.Actual, params.data.attribute.PCCUnitLongName)}`;
        },
        cellRenderer: 'amountRenderer'
      },
      {
        field: 'quota',
        lockPosition: true,
        headerName: 'Target',
        suppressNavigable: true,
        minWidth: 90,
        valueGetter: function(params) {
          return `${showWithCurrency(params.data.attribute.Quota, params.data.attribute.PCCUnitLongName)}`;
        },
        cellRenderer: 'amountRenderer'
      },
      {
        field: 'quotaRecurring',
        lockPosition: true,
        headerName: 'Target Recurring',
        suppressNavigable: true,
        minWidth: 90,
        valueGetter: function(params) {
          return `${showWithCurrency(params.data.attribute.QuotaRecurring, params.data.attribute.PCCUnitLongName)}`;
        },
        cellRenderer: 'amountRenderer'
      }
    ];
  }

  ngOnInit(): void {
    if (this.isBingo) {
      this.columnDefs = this.columnDefs.filter((column) => this.bingoBonusColumns.findIndex((item) => item === column.field) >= 0);
    }
  }
}
