import { Component, OnInit, Inject } from '@angular/core';
import * as Ng1Services from '../core/hybrid/ng1-upgraded-providers';
import * as _ from 'underscore';
import { ReportsService } from './reports.service';
import { Observable, from } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportType } from './report-type.enum';
import { FilterState, MessagesService, selectFiltersState } from '@mint-libs/context';
import { Code } from '../app.constants';
import { UserProfileService } from '../core/context/user-profile.service';
import { BaseComponent } from '../shared/base.component';
import { LocalStorageService } from '@mint-libs/network';
import { FiltersService } from '../filters/filters.service';
import { LoggerService } from '@mint-libs/logging';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from '../core/navigation/navigation.service';
import { ImpersonationService } from '../core/impersonation/impersonation.service';
import { InitializationService } from '../core/initialization/initialization.service';
import { UserSessionService } from '../core/session/user-session-service';

@Component({
  selector: 'app-report',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [Code]
})
export class ReportsComponent extends BaseComponent implements OnInit {
  isDropdownOpen = false;
  tabs = [];
  filterConfig: any;
  message: Observable<any>;
  attainmentThresholdMessage: string;
  activeTab = <any>{};
  id: number;
  activeTabText: string;
  reportIndex: number;
  hideTerritory = true;
  hidePayoutDetails = true;
  widgetsWithReports = [
    {
      index: 0,
      key: ReportType.PCGReport,
      title: 'Attainment by Bucket',
      isAvailable: true
    },
    {
      index: 1,
      key: ReportType.PayoutReport,
      title: 'Payout Details',
      isAvailable: true
    },
    {
      index: 2,
      key: ReportType.ProductReport,
      title: 'Compensation Actuals by Product',
      isAvailable: true
    },
    {
      index: 3,
      key: ReportType.TerritoryReport,
      title: 'Targets and Actuals by Incentive Territory',
      isAvailable: true
    },
    {
      index: 4,
      key: ReportType.AccountReport,
      title: 'Compensation Actuals by Account',
      isAvailable: true
    },
    {
      index: 6,
      key: ReportType.QuotaReport,
      title: 'Quota Details',
      isAvailable: true
    },
    {
      index: 7,
      key: ReportType.AccountDynamicsCommittedGrowthReport,
      title: 'Dynamics Committed Growth by Account',
      isAvailable: false
    }
  ];

  constructor(
    private reportsService: ReportsService,
    public router: Router,
    private messageService: MessagesService,
    private code: Code,
    public sharedContextState: Store<SharedContext>,
    public filterStore: Store<FilterState>,
    public route: ActivatedRoute,
    public filterService: FiltersService,
    public initializationService: InitializationService,
    public navigationService: NavigationService,
    public impersonationService: ImpersonationService,
    public userProfileService: UserProfileService,
    public rolefeatureService: RoleFeatureService,
    public localStorage: LocalStorageService,
    private loggingService: LoggerService,
    public userSessionService: UserSessionService
  ) {
    super(initializationService, navigationService, impersonationService, sharedContextState, userProfileService, rolefeatureService, filterService, localStorage, router, userSessionService);
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.filterStore.select(selectFiltersState).subscribe(filterState => {
      this.widgetsWithReports = this.widgetsWithReports.filter(report => {
        return !report.title.includes('Actuals') || !filterState.is2025OrFuture;
      });
    });

    this.filterConfig = {
      isFilter: true,
      isSearch: false,
      isFiscalYear: true,
      isTitle: true,
      isPartition: true,
      showSearch: false,
      showFilter: true
    };
  }

  ngOnInit() {
    this.populateReportList();
    this.reportIndex = this.reportsService.selectedReportIndex; // Last visited report
    this.route.queryParams.subscribe(params => {
      // If opened through widget, find related report to load by default

      if (params.id) {
        this.tabs.find((tab, index) => {
          if (tab.id === Number(params.id) && this.navigationService.getNgReportsSubMenuList().find(report => report.index === tab.id)?.active) {
            this.reportIndex = tab.id;
            return;
          }
        });
        this.loadReport(this.reportIndex);
      } else {
        const activeReports = this.navigationService.getNgReportsSubMenuList().filter(tab => tab.active === true);
        if (activeReports && activeReports.length > 0) {
          this.reportIndex = 0;
          this.loadReport(this.reportIndex);
        }
      }
    });

    this.messageService.get(Code.AttainmentThresholdMessage).subscribe(response => {
      this.attainmentThresholdMessage = response;
    });

    // this.loadReport(this.reportIndex);
  }

  populateReportList() {
    const dynamicsAccountReport = this.widgetsWithReports.find(t => t.key === ReportType.AccountDynamicsCommittedGrowthReport);
    dynamicsAccountReport.isAvailable = this.sharedContext.IsDMYEligible;

    const reports = this.widgetsWithReports.filter(report => report.isAvailable);
    reports.forEach(item => {
      this.tabs.push({
        id: item.index,
        tabText: item.title,
        key: item.key,
        active: false
      });
    });
  }

  loadReport(index) {
    const reportName = 'Reports - ' + this.widgetsWithReports.find(t => t.index === index).key;
    this.loggingService.trackPageView(reportName, null);
    this.tabs.forEach(t => (t.active = false));
    const selectedTab = this.tabs.find(t => t.id === index);

    selectedTab.active = true;
    this.activeTab = selectedTab;
    this.reportIndex = index;
    this.reportsService.selectedReportIndex = index;
    this.router.navigateByUrl(`/reports?id=${index}`);
  }

  updateGlobalFilterConfig() {
    if (this.activeTab.key === ReportType.AccountDynamicsCommittedGrowthReport) {
      this.filterConfig.isPartition = false;
    }
    if (this.activeTab.key === ReportType.PayoutReport && this.filterService.selectedFiscalYear > 2021) {
      this.filterConfig.isTitle = false;
      this.filterConfig.isPartition = false;
    } else {
      this.filterConfig.isTitle = true;
      this.filterConfig.isPartition = true;
    }
  }

  expand() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  // applyFilter(event: any) {
  // this.navigationFactory.navigateToSubView('home.report', submenu);
  //   this.initializationService.initialize(true, true, true, true);
  //   this.router.navigateByUrl(this.router.url, { skipLocationChange: true });
  // }
  applyFilter(event: any) {
    //// this.navigationFactory.navigateToSubView('home.report', submenu);
    //this.initializationService.initialize(true, true, true, true);
    //this.router.navigateByUrl('reports', { skipLocationChange: false });
    super.applyFilterAsync(this.router.url).subscribe(response => { });
  }

  checkFocus(event: any, id) {
    if (id === this.tabs.length - 1 && event.keyCode === 9 && !event.shiftKey) {
      this.isDropdownOpen = false;
    }
  }

  onTabKeydown(event: any) {
    if (event.keyCode === 9) {
      document.getElementById('#report-nav-menu').classList.remove('open');
    }
  }
}
