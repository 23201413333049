import { Component, OnInit, Inject } from '@angular/core';
import { BaseComponent } from '../shared/base.component';
import { Router } from '@angular/router';
import { BonusService } from './bonus.service';
import { element } from 'protractor';
import { LocalStorageService } from '@mint-libs/network';
import { BonusParticipationDetails } from '../shared/models/bonus-participation-details.model';
import { BonusAwardSummary } from '../shared/models/bonus-award-summary.model';
import { UserProfileService } from '../core/context/user-profile.service';
import { FiltersService } from '../filters/filters.service';
import { LoggerService } from '@mint-libs/logging';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from '../core/navigation/navigation.service';
import { ImpersonationService } from '../core/impersonation/impersonation.service';
import { InitializationService } from '../core/initialization/initialization.service';
import { UserSessionService } from '../core/session/user-session-service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.scss'],
  providers: [BonusService]
})
export class BonusComponent extends BaseComponent implements OnInit {
  public isDemoMode3 = false;
  public bonusParticipationDetails: BonusParticipationDetails = new BonusParticipationDetails();
  isDataLoaded = false;
  lastestFiscalYear: any;
  activeTabid: any;
  participationDataTemp: any;
  public awardSummary: BonusAwardSummary;
  public sharedContext = new SharedContext();

  constructor(
    public initializationService: InitializationService,
    public navigationService: NavigationService,
    public impersonationService: ImpersonationService,
    public sharedContextState: Store<SharedContext>,
    public filterService: FiltersService,
    public rolefeatureService: RoleFeatureService,
    public localStorage: LocalStorageService,
    private loggingService: LoggerService,
    public bonusService: BonusService,
    public userProfileService: UserProfileService,
    public userSessionService: UserSessionService,
    public router: Router
  ) {
    super(initializationService, navigationService, impersonationService, sharedContextState, userProfileService, rolefeatureService, filterService, localStorage, router, userSessionService);
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => (this.sharedContext = sharedContext));
  }

  ngOnInit() {
    this.loggingService.trackPageView('Bonus', { UserAlias: this.sharedContext.alias });
    this.isDemoMode3 = this.sharedContext.isDemoMode3;
    this.GetCagrParticipationDetails();
  }

  GetCagrParticipationDetails() {
    const currentSelectedParticipationId = this.filterService.selectedStandardTitle.ParticipationID;

    this.bonusService.getBonusAttainmentDetails(currentSelectedParticipationId).subscribe(result => {
      let bonusProgram = {};

      if (result) {
        if (result['length'] > 0 && this.sharedContext.currentBonusProgramId) {
          const matchProgram = result.filter(x => x.id === this.sharedContext.currentBonusProgramId);
          if (matchProgram && matchProgram['length'] > 0) {
            bonusProgram = matchProgram[0];
          }
        } else {
          bonusProgram = result[0];
        }
      }

      const bonusParticipationDetails = new BonusParticipationDetails();
      try {
        const response = bonusParticipationDetails.deserializeSingle(bonusProgram);

        this.bonusParticipationDetails = response;
        this.BindAwardSummary();
        this.isDataLoaded = true;
      } catch {}
    });
  }

  BindAwardSummary() {
    this.awardSummary = new BonusAwardSummary();
    this.awardSummary.currency = this.bonusParticipationDetails.CurrencyCode;
    this.awardSummary.totalBonusAmount = this.bonusParticipationDetails.TotalBonusAmount;

    const allUnlockedComponents = this.bonusParticipationDetails.BonusAttributes.filter(comp => comp.isBingo() === false && comp.locked === false);
    const allComponents = this.bonusParticipationDetails.BonusAttributes.filter(comp => comp.isBingo() === false);

    let totalPercentage = 0;
    if (allUnlockedComponents && allUnlockedComponents.length >= 0) {
      this.awardSummary.totalProductsUnlocked = allUnlockedComponents.length;
    }
    this.awardSummary.totalProducts = allComponents.length;

    // Potential Award
    // 1. Get Total Percentage of Non Bingo components and multiply with ITA
    this.bonusParticipationDetails.BonusAttributes.forEach(comp => {
      const percentageValue = +comp.percentageValue;
      totalPercentage = totalPercentage + percentageValue;
    });

    // 2. Get ITA
    const iTA = allComponents[0].ITA;
    this.awardSummary.potentialAward = (totalPercentage / 100) * iTA;
    this.awardSummary.currentITA = iTA;
  }
}
