import { Injector } from '@angular/core';
import { Formatter } from '@mint-libs/common';
import { ClassTransformOptions } from 'class-transformer';
const injector = Injector.create({
  providers: [
    {
      provide: Formatter,
      deps: []
    }
  ]
});

export abstract class BaseModel {
  protected formatter: Formatter;
  protected classTransformOptions: ClassTransformOptions;
  constructor() {
    this.formatter = injector.get(Formatter);
    this.classTransformOptions = { strategy: 'excludeAll' };
  }
}
