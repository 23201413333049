import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Formatter } from '@mint-libs/common';
import { Observable } from 'rxjs';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../../core/network/data.service';
import { environment } from '../../../environments/environment';
import { CustomHttpParams } from '../../core/network/custom-http-params';
import { ExportToExcelService } from '../../shared/excel-export/export-to-excel.service';
import { FiltersService } from '../../filters/filters.service';
import { saveAs } from 'file-saver';
import { TPayoutObject, TPayoutResponse } from './types/payout-info-response-type';
import { payoutMock } from './mocks/payout-response-mock';

@Injectable({
  providedIn: 'root'
})
export class PayoutReportService {
  selectedBucketName: string;
  selectedTabBeforeReload: number;

  constructor(protected formatter: Formatter, private endpoint: EndpointService, private dataService: DataService,
    private exportToExcelServiceNew: ExportToExcelService, private filterService: FiltersService,) { }

  formatCurrency(value, showNAForZero = false) {
    return this.formatter.formatWithCurrencyAndRoundOff(value, null, showNAForZero);
  }

  roundOff(value) {
    return this.formatter.roundOff(value);
  }

  getSelectedCoreBucket() {
    const x = document.querySelector<HTMLElement>('.mat-expanded');
    if (x) {
      const y = x.innerText.split('\n')[0];
      return y;
    }
  }

  // Payout API for pre FY22 reports
  getOldPayoutInfo(fromFreezeSnapshot = false) {
    let params = CustomHttpParams.getInstance();
    params = params.append('FromFreezeSnapshot', String(fromFreezeSnapshot));
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.OldPayoutDetails.uri, params);
  }

  // Payout excel report API for pre FY22 reports
  getOldExcelReport(htmlTable, reportName, pcg_pcc_List, settings, showTerritoryEmployeeData) {
    const endPoint = this.endpoint.OldExportToExcelPayoutDetails.uri;
    const exceParams = {} as any;
    exceParams.HTMLTable = htmlTable;
    exceParams.FileName = reportName || 'Sample';
    exceParams.Pcg_Pcc_List = pcg_pcc_List;
    exceParams.Settings = settings;
    exceParams.PlanName = this.filterService.selectedStandardTitle.PlanName;
    exceParams.FiscalYear = this.filterService.selectedFiscalYear;
    exceParams.ManagerFiscalYear = this.filterService.selectedManagerViewFiscalYear;
    exceParams.ParticipationID = this.filterService.selectedStandardTitle.ParticipationID;
    exceParams.FiscalQuarterId = this.filterService.selectedFiscalQuarterId;
    exceParams.FromFreezeSnapshot = true;
    exceParams.FromVersionSnapShot = true;
    exceParams.StepId = this.filterService.selectedStepId;
    exceParams.ShowTerritoryEmployeeData = showTerritoryEmployeeData;

    return this.dataService.push(endPoint, exceParams, null, 'arraybuffer');
  }

  downloadOpenFile(wbout, reportName) {
    const data = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, reportName + '.xlsx');
  }

  //QRPCGLevelDeatils for OLD (FY20, FY21) payout reporting export
  getOldQRPCGLevelDetails() {
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.OldGetQRPCGLevelDetails.uri);
  }

  getPayoutInfo(isGroupedPayoutReport: boolean) {
    let params = CustomHttpParams.getInstance();
    params = params.append('IsGroupedPayoutReport', isGroupedPayoutReport.toString());
   // Uncomment to use mock data on mocks/ folder
    // return new Observable<TPayoutResponse>(subscriber => {
    //   subscriber.next(payoutMock as TPayoutResponse);
    // });
    params = params.append('IsHardTransferFeatureEnabled', String(environment.isHardTransferFeatureEnabled)); // remove it 
    return this.dataService.getWithGlobalFilters<TPayoutResponse | TPayoutObject[]>(this.endpoint.PayoutDetails.uri, params, null, environment.payoutApiUrl);
  }

  getExcelReport(paymentApprovalID) {
    let params = CustomHttpParams.getInstance();
    params = params.append('PersonnelNumber', this.filterService.personnelNumber.toString());
    params = params.append('PaymentApprovalID', paymentApprovalID);
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.ExportToExcel.uri, params, null, 'arraybuffer', environment.payoutApiUrl);
  }

  downloadFile(data) {
    this.exportToExcelServiceNew.downloadOpenFile(data, 'PayoutReport');
  }
}
