<!-- <mint-content-loader-grid *ngIf="!isLoaded"></mint-content-loader-grid> -->
<!-- <div *ngIf="isLoaded" id="metric-grid_1" class="grid-display"> -->
<div *ngIf="isLoaded" class="pcg__header">
  <div class="pcg__header__txt">Meet target in at least one cloud</div>
  <div class="stat__info">
    <div class="icon icon-info"></div>
    <div class="stat__tooltip stat__tooltip-left">
      <!-- <div class="stat__tooltip__header">Some header</div> -->
      <p class="stat__tooltip__text">
        Click on the information icon for each cloud to view the qualification criteria. Meet the target for one cloud and be eligible to receive 25% of annual bonus eligible salary. Meet the target
        for two clouds and be eligible to receive 50% of annual bonus eligible salary. Meet the target of all three clouds OR meet the total cloud target listed below and be eligible to receive 100%
        of annual bonus eligible salary.
      </p>
    </div>
  </div>
</div>
<br />
<div id="metric-grid_254" style=" overflow: hidden; flex-grow: 1; margin-bottom: 30px;" *ngIf="rowData != undefined">
  <ag-grid-angular
    id="cagrgrid"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    [frameworkComponents]="frameworkComponents"
    [defaultColDef]="defaultColDef"
    [domLayout]="domLayout"
  >
  </ag-grid-angular>
</div>

<br />
<br />
<div class="pcg__header">
  <div class="pcg__header__txt">Alternatively, meet total cloud target</div>
  <div class="stat__info">
    <div class="icon icon-info"></div>
    <div class="stat__tooltip stat__tooltip-left">
      <!-- <div class="stat__tooltip__header">Some header</div> -->
      <p class="stat__tooltip__text">
        A total cloud target has been identified to address scenarios where customers are more penetrated in one cloud versus another. Meet the total three-year cloud target (listed in your dashboard
        here) across all three clouds to be eligible for 100% of annual bonus eligible salary.
      </p>
    </div>
  </div>
</div>
<br />
<div id="metric-grid_234" style=" overflow: hidden; flex-grow: 1">
  <ag-grid-angular
    id="cagrgridtotal"
    class="ag-theme-balham"
    [rowData]="rowData1"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    [frameworkComponents]="frameworkComponents"
    [defaultColDef]="defaultColDef"
    [domLayout]="domLayout"
  >
  </ag-grid-angular>
</div>
<!-- </div> -->
