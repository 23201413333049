export class ToggleSwitchConfig {
  title: string;
  source: string;
  target: string;
  id: any;
  data: any;
  helpText: string;
  isChecked: boolean;
  titleRight: string;
  toggleCssClass: string;
  navigateOnToggle: boolean;
  tracker: string;
}
