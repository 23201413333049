import { Component, OnInit, Inject, Input } from '@angular/core';
import { PayoutReportService } from '../../../payout-report.service';
import { ToDateFormatPipe } from '@mint-libs/common';
import { BucketProratedTargetTypes } from './bucket-prorated-target-types.enum';

@Component({
  selector: 'mint-payout-report-bucket-incentive-award-tile',
  templateUrl: './bucket-incentive-award.component.html',
  styleUrls: ['./bucket-incentive-award.component.scss']
})
export class BucketIncentiveAwardComponent implements OnInit {
  totalDaysInTheYear: number;
  applicablePeriodStatusList: any;
  @Input() selectedPayoutEarningDetail: any;
  @Input() selectedCoreBucket: any;
  @Input() selectedFiscalYear: number;
  @Input() currency: any;

  constructor(private formatDate: ToDateFormatPipe, public payoutReportService: PayoutReportService) {}

  ngOnInit() {
    this.totalDaysInTheYear = this.getTotalDaysInYear(this.selectedFiscalYear);
  }

  ngOnChanges() {
    this.getApplicablePeriodStatus();
  }

  getApplicableUserITAs(periodStatus) {
    let applicableUserITAs = this.selectedPayoutEarningDetail.UserITAs.filter(function(item) {
      return item.PeriodStatus === periodStatus;
    });
    return applicableUserITAs;
  }

  getApplicablePeriodStatus() {
    this.applicablePeriodStatusList = [];
    const uniquePeriodStatus = this.selectedPayoutEarningDetail.UserITAs.map(item => item.PeriodStatus).filter((value, index, self) => self.indexOf(value) === index);
    uniquePeriodStatus.forEach(periodStatus => {
      this.applicablePeriodStatusList.push({
        PeriodStatus: periodStatus,
        Proratedtitle: BucketProratedTargetTypes[periodStatus]
      });
    });
  }

  getPayoutCap(periodStatus: string, pcg: any) {
    // for Paid LOA, payout cap is always 100%
    const status = (periodStatus || '').toUpperCase();
    if (status === 'PAID') {
      return 100;
    }
    // for unpaid LOA, payout cap is always 0%
    if (status === 'UNPAID') {
      return 0;
    }

    // for TA LOA, payout cap is always 100% if payout percentage is less than 100%
    if (status === 'TA') {
      return pcg.calc.PayoutBeforeCaps < 100 ? 100 : pcg.calc.PayoutAfterCaps;
    }
    return pcg.calc.PayoutAfterCaps;
  }

  getTotalDaysInYear(year) {
    return this.isLeapYear(year) ? 366 : 365;
  }

  isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }

  getFormattedDate(date) {
    return this.formatDate.transform(date, 'mediumDate');
  }
}
