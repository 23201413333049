import { Component, OnInit, Inject, Input, ElementRef } from '@angular/core';
import { CustomHeaderComponent } from '../../shared/grid-components/custom-header/custom-header.component';
import { MetricRendererComponent } from '../../shared/metric-grid/renderers/metric-renderer.component';
import { AttainmentRendererComponent } from '../../shared/metric-grid/renderers/attainment-renderer.component';
import { ProductGroupRendererComponent } from '../../shared/metric-grid/renderers/product-group-renderer.component';
import { DataSourceRendererComponent } from '../../shared/metric-grid/renderers/data-source-renderer.component';
import { CellClassParams } from 'ag-grid-community';
import { BaseGridComponent } from '../../shared/metric-grid/base-grid.component';
import { LegendService } from '../../shared/legends/legend.service';
import { ITooltipTextGrid } from '../../shared/metric-grid/tooltip-text-grid';

import { CagrMetricRendererComponent } from '../../shared/metric-grid/renderers/cagr-metric-renderer.component';
import { CagrPccRendererComponent } from '../../shared/metric-grid/renderers/cagr-pcc-renderer.component';
import { BonusTargetRendererComponent } from '../../shared/metric-grid/renderers/target-renderer-bonus.component';
import { BonusActualRendererComponent } from '../../shared/metric-grid/renderers/actuals-renderer-bonus.component';
import { BonusAttainmentRendererComponent } from '../../shared/metric-grid/renderers/attainment-rendered-bonus.component';
import { BonusTargetRecurringRendererComponent } from '../../shared/metric-grid/renderers/targetrecurring-renderer-bonus.component';

@Component({
  selector: 'mint-bonus-grid-view',
  templateUrl: './bonus-grid-view.component.html',
  styleUrls: ['./bonus-grid-view.component.scss']
})
export class BonusGridViewComponent extends BaseGridComponent implements OnInit {
  rowData1: any;
  isLoaded = false;
  rowData1Loaded = false;
  rowDataLoaded = false;

  columnDefsBingoGrid: any;

  constructor(protected legendService: LegendService, protected element: ElementRef) {
    super(legendService, 105, element);
    
  }
  @Input() bonusComponents;
  @Input() bingoComponents;

  ngOnInit(): void {
    if (this.bonusComponents != undefined) {
      this.isLoaded = true;
      this.columnDefs = [
        {
          field: 'PlanComponentName',
          lockPosition: true,
          headerName: 'Metric',
          cellRenderer: 'metricRenderer',
        //  rowSpan: this.pCGRowSpan,
          cellClassRules: {
            'cell-span-unlocked': function(params) {
              return params.data.locked == false;;
            },
            'cell-span-locked': function(params) {
              return params.data.locked == true;
            }
          },
          suppressNavigable: true,
          minWidth: 150
        },
        {
          field: 'attainment',
          lockPosition: true,
          headerName: 'Attainment',
          cellRenderer: 'attainmentRenderer',
          // cellClassRules: {
          //   'cell-span': this.cellSpan
          // },
          suppressNavigable: true,
          minWidth: 116
        },
        {
          field: 'locked',
          lockPosition: true,
          headerName: 'Status',
          valueGetter: function(params) {
            if (params.data.locked === undefined || params.data.locked === null) {
              return 'Error. Something went wrong.';
            }
            return params.data.locked ? 'Locked' : 'Unlocked';
          },
          // cellClassRules: {
          //   'cell-span': this.cellSpan
          // },
          suppressNavigable: true,
          minWidth: 150
        },
        {
          field: 'actuals',
          lockPosition: true,
          headerName: 'Actuals',
          cellRenderer: 'actualsRenderer',
          // cellClassRules: {
          //   'cell-span': this.cellSpan
          // },
          suppressNavigable: true,
          minWidth: 90
        },
        {
          field: 'target',
          lockPosition: true,
          headerName: 'Target',
          cellRenderer: 'targetRenderer',
          // cellClassRules: {
          //   'cell-span': this.cellSpan
          // },
          suppressNavigable: true,
          minWidth: 90
        },
        {
          field: 'targetRecurring',
          lockPosition: true,
          headerName: 'Target Recurring',
          cellRenderer: 'targetRecurringRenderer',
          // cellClassRules: {
          //   'cell-span': this.cellSpan
          // },
          suppressNavigable: true,
          minWidth: 90
        }
      ];

      this.columnDefsBingoGrid = [
        {
          field: 'PlanComponentName',
          lockPosition: true,
          headerName: 'Metric',
          cellRenderer: 'metricRenderer',
        //  rowSpan: this.pCGRowSpan,
          cellClassRules: {
            'cell-span-unlocked': function(params) {
              return params.data.locked == false;
            },
            'cell-span-locked': function(params) {
              return params.data.locked;
            }
          },
          suppressNavigable: true,
          minWidth: 150
        },
        {
          field: 'attainment',
          lockPosition: true,
          headerName: 'Attainment',
          cellRenderer: 'attainmentRenderer',
          suppressNavigable: true,
          minWidth: 116
        },
        {
          field: 'locked',
          lockPosition: true,
          headerName: 'Status',
          valueGetter: function(params) {
            if (params.data.locked === undefined || params.data.locked === null) {
              return 'Error. Something went wrong.';
            }
            return params.data.locked ? 'Locked' : 'Unlocked';
          },
          suppressNavigable: true,
          minWidth: 150
        }
      ];

      this.rowData = this.bonusComponents;
      this.rowData1 = this.bingoComponents;
    }

    this.frameworkComponents = {
      agColumnHeader: CustomHeaderComponent,
      metricRenderer: CagrMetricRendererComponent,
      pccRenderer: CagrPccRendererComponent,
      attainmentRenderer: BonusAttainmentRendererComponent,
      targetRenderer: BonusTargetRendererComponent,
      actualsRenderer: BonusActualRendererComponent,
      targetRecurringRenderer: BonusTargetRecurringRendererComponent
    };
  }
  pCGRowSpan(params) {
    if (params.data.pCGRowSpan !== -1) {
      return params.data.pCGRowSpan;
    }
    return 1;
  }
  cellSpan(params) {
    return params.data.pCGRowSpan !== -1 || params.data.pCGRowSpan !== 1;
  }
}
