import { Component, OnInit, Input, TemplateRef, Inject, QueryList, ViewChildren } from '@angular/core';
import * as _ from 'underscore';
import { MatAccordion } from '@angular/material/expansion';
import { PayoutReportService } from '../payout-report.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Ng1Services from '../../../core/hybrid/ng1-upgraded-providers';
import { TBonusEarningDetails, TEarningDetails, TPayoutObject, TPayrollAdjustment } from '../types/payout-info-response-type';
import { LoggerService } from '@mint-libs/logging';

@Component({
  selector: 'mint-payout-report-section',
  templateUrl: './payout-report-section.component.html',
  styleUrls: ['./payout-report-section.component.scss']
})
export class PayoutReportSectionComponent implements OnInit {
  fromPayout = true;
  graphService: any;
  thumbnailPhoto: any;
  showAdjustmentDetails: any;
  showCoreBucketDetails: boolean;
  paymentDetails: any;
  modalRef;
  selectedMetric: any;
  selectedPayoutInfo: TPayoutObject;
  selectedPCG: any;
  selectedAdjustment: TPayrollAdjustment;
  selectedBonus: TBonusEarningDetails;
  selectedPayoutEarningDetail: TEarningDetails | null;
  totalBonus: number;

  @Input() payoutInfoArray: TPayoutObject[];
  @ViewChildren(MatAccordion) accordions: QueryList<MatAccordion>;
  showBonusDetails: boolean;

  constructor(public payoutReportService: PayoutReportService, private modalService: NgbModal, private loggingService: LoggerService) {}
  ngOnInit() {}

  ngOnChanges() {
    this.payoutReportService.getSelectedCoreBucket();

    //Order payotus so that Bonus is last
    this.payoutInfoArray && this.payoutInfoArray.sort((a, b) => Number(!!a.BonusEarningDetails) - Number(!!b.BonusEarningDetails));
    //this.getHeightForBlade();
  }

  // getHeightForBlade() {
  //   const element = document.querySelector<HTMLElement>('.payout-report-blade1').offsetHeight;
  //   document.getElementById('payout-report-blade2').style.height = element.toString() + 'px';
  // }

  openAccordionElement(payoutInfo: TPayoutObject, dataType: string, data: any, selectedData = null) {
    this.showAdjustmentDetails = false;
    this.showCoreBucketDetails = false;
    this.showBonusDetails = false;
    //Save data related to the selected accordion.
    this.selectedPayoutInfo = payoutInfo;
    switch (dataType) {
      case 'PCG': {
        this.selectedPCG = data;
        this.showCoreBucketDetails = true;
        this.selectedPayoutEarningDetail = selectedData;
        break;
      }
      case 'Adjustment': {
        this.selectedAdjustment = data;
        this.showAdjustmentDetails = true;
        break;
      }
      case 'Bonus': {
        this.selectedBonus = data;
        this.showBonusDetails = true;
        break;
      }
      default: {
        break;
      }
    }
  }

  // openGroup(e) {
  //   const element = document.querySelector<HTMLElement>('.mat-expanded').offsetTop;
  //   if (element) {
  //     document.getElementById('selection-pointer').style.top = (element - 305).toString() + 'px';
  //   }
  //   document.getElementById('selection-pointer').style.visibility = 'visible';
  //   this.getHeightForBlade();
  // }

  openModal(template: TemplateRef<any>, pcg, pccId) {
    this.selectedMetric = pcg.PCCDetails.find(p => p.PCCID === pccId);
    this.loggingService.trackEvent('PayoutReport_ChosenMetric', { SelectedMenuItem: this.selectedMetric.PCCName }, true);
    this.modalRef = this.modalService.open(template, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'mint-modal-backdrop', windowClass: 'mint-modal-window-center' });
  }

  reduceTotalBonus(payoutInfo: TPayoutObject): number {
    return payoutInfo.BonusEarningDetails.reduce((previous, current) => previous + current.BonusEarningAmount, 0);
  }

  hasBonusData(payouts: TPayoutObject[]): boolean {
    let hasBonus = false;
    if (Array.isArray(payouts)) {
      payouts.map(payout => {
        if (payout.BonusEarningDetails) {
          hasBonus = true;
        }
        return payout;
      });
      return hasBonus;
    } else {
      return false;
    }
  }
}
