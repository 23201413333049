import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mint-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit {
  @Input() dropdownClosed = true;
  @Input() data: Array<any> = new Array<any>();
  @Input() maxDisplaySelection = 1; // to show how many selected items in the selected input box
  @Input() maxHeight = 180;
  @Input() placeholder = 'Select';
  @Output() selectChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectAllChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedItems: Array<any> = new Array<any>();
  @Output() selectedItemsChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  allItemSelected = false;
  constructor() {}

  ngOnInit() {}

  closeDropdown() {
    this.dropdownClosed = true;
  }

  toggleDropdown() {
    this.dropdownClosed = !this.dropdownClosed;
  }

  toggleSelectAll() {
    this.dropdownClosed = false;
    this.toggleDropdown();
    if (!this.allItemSelected) {
      this.selectedItems = [];
      this.data.forEach(item => this.selectedItems.push(item));
      this.allItemSelected = true;
    } else {
      this.selectedItems = [];
      this.allItemSelected = false;
    }
    this.selectAllChange.emit(this.selectedItems);
    this.selectedItemsChange.emit(this.selectedItems);
  }

  isAllItemsSelected() {
    return this.allItemSelected;
  }

  onItemClick($event, item) {
    this.dropdownClosed = false;
    this.toggleDropdown();
    if (this.selectedItems.includes(item)) {
      const index = this.selectedItems.indexOf(item, 0);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
      if (this.selectedItems.length !== this.data.length) {
        this.allItemSelected = false;
      }
    } else {
      this.selectedItems.push(item);
      if (this.selectedItems.length === this.data.length) {
        this.allItemSelected = true;
      }
    }
    this.selectChange.emit(item);
    this.selectedItemsChange.emit(this.selectedItems);
  }

  isSelected(item) {
    return this.selectedItems.includes(item);
  }

  itemShowRemaining() {
    return this.selectedItems.length - this.maxDisplaySelection;
  }
}
