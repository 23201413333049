import { ParticipantDetails } from './all-participant.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as AllParticipantsActions from './all-participants.actions';

export const allParticipantsFeatureKey = 'allParticipants';

export class ParticipantsDetailsState extends ParticipantDetails {}

export const allParticipantDetails: ParticipantsDetailsState = null;

const reducer = createReducer(
  allParticipantDetails,

  on(AllParticipantsActions.loadAllParticipants, state => state),
  on(AllParticipantsActions.loadAllParticipantssSuccess, (state, action) => {
    if (action.data) {
      const participantDetails = action.data;
      return { ...state, participantDetails: participantDetails };
    } else {
      return state;
    }
  })
);
export function allParticipantsReducer(state = allParticipantDetails, action: Action) {
  return reducer(state, action);
}
