import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../../../core/network/data.service';
import { CustomHttpParams } from '../../../core/network/custom-http-params';

@Injectable({
  providedIn: 'root'
})
export class PayoutSummaryService {
  constructor(private endpoint: EndpointService, private dataService: DataService) {}

  getPayoutSummaryData(fromFreezeSnapshot: boolean = false, forSummaryView: boolean = true) {
    let params = CustomHttpParams.getInstance();
    params = params.append('FromFreezeSnapshot', String(fromFreezeSnapshot));
    params = params.append('ForSummaryView', String(forSummaryView));

    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetPayoutSummary.uri, params);
  }
}
