import { UrlSegment, UrlSegmentGroup, Route } from '@angular/router';
import { UserProfileService } from '../context/user-profile.service';

import { Injector } from '@angular/core';

export let appInjector: Injector;

export function setAppInjector(injector: Injector) {
  appInjector = injector;
}

// Dashboard Matchers
export function rbiDashboardMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  const userProfileService: UserProfileService = getUserProfile();
  const isPersonaMatched = userProfileService.isNPM() || userProfileService.isRBIPersona();
  // console.log(userProfileService, segments);
  const result = matchPersonaAndRoute(isPersonaMatched, MintRoutes.Dashboard, segments[0]);
  return result;
}

export function ubiDashboardMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  const userProfileService: UserProfileService = getUserProfile();
  const isPersonaMatched = userProfileService.isNPM() || userProfileService.isUBIPersona();
  const result = matchPersonaAndRoute(isPersonaMatched, MintRoutes.Dashboard, segments[0]);
  return result;
}

export function ppiDashboardMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  const userProfileService: UserProfileService = getUserProfile();
  const isPersonaMatched = userProfileService.isNPM() || userProfileService.isPPIPersona();
  const result = matchPersonaAndRoute(isPersonaMatched, MintRoutes.Dashboard, segments[0]);
  return result;
}

// Comp. plan Matchers
export function commonCompPlanMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  const userProfileService: UserProfileService = getUserProfile();
  const isPersonaMatched = userProfileService.isRBIPersona() || userProfileService.isUBIPersona();
  const result = matchPersonaAndRoute(isPersonaMatched, MintRoutes.CompensationPlan, segments[0]);
  return result;
}

export function ppiCompPlanMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  const userProfileService: UserProfileService = getUserProfile();
  const isPersonaMatched = userProfileService.isPPIPersona();
  const result = matchPersonaAndRoute(isPersonaMatched, MintRoutes.CompensationPlan, segments[0]);
  return result;
}

// Reports Matchers
export function ubiReportsMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  const userProfileService: UserProfileService = getUserProfile();
  const isPersonaMatched = userProfileService.isUBIPersona();
  const result = matchPersonaAndRoute(isPersonaMatched, MintRoutes.Reports, segments[0]);
  return result;
}

export function ppiReportsMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  const userProfileService: UserProfileService = getUserProfile();
  const isPersonaMatched = userProfileService.isPPIPersona();
  const result = matchPersonaAndRoute(isPersonaMatched, MintRoutes.Reports, segments[0]);
  return result;
}

export function rbiReportsMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  const userProfileService: UserProfileService = getUserProfile();
  const isPersonaMatched = userProfileService.isRBIPersona();
  const result = matchPersonaAndRoute(isPersonaMatched, MintRoutes.Reports, segments[0]);
  return result;
}

function getUserProfile() {
  return appInjector.get<UserProfileService>(UserProfileService);
}

function matchPersonaAndRoute(isPersonaMatched: boolean, routeToMatch: string, urlSegment: UrlSegment) {
  const result = isPersonaMatched && isRouteMatched(routeToMatch, urlSegment.path) ? { consumed: [urlSegment] } : null;
  return result;
}

function isRouteMatched(routeToMatch: string, urlSegment: string): boolean {
  if (!urlSegment) {
    return false;
  }
  const matched = routeToMatch.toLowerCase().includes(urlSegment.toLowerCase());
  return matched;
}

enum MintRoutes {
  Dashboard = 'dashboard',
  CompensationPlan = 'compensationPlan',
  Reports = 'reports'
}
