import { Component, OnInit, Inject, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Code } from '../../../../app/app.constants';
import * as _ from 'underscore';
import { ComplianceHelper } from '../compliance.helper';
import { OcvFloodgateService } from '../../../ocv-feedback/ocv-floodgate.service';
import { ExportToExcelService } from '../../excel-export/export-to-excel.service';
import { FiltersService } from '../../../filters/filters.service';
import { UserProfileService } from '../../../core/context/user-profile.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { ImpersonationService } from 'src/app/core/impersonation/impersonation.service';

@Component({
  selector: 'mint-rbi-quota-details',
  templateUrl: './rbi-quota-details.component.html',
  styleUrls: ['./rbi-quota-details.component.scss'],
  providers: [Code, ComplianceHelper]
})
export class RbiQuotaDetailsComponent implements OnInit {
  @Input() titles = [];
  @Input() PCCs = [];
  @Input() isCompliancePage: boolean;
  @Input() hasQuotaMultiplier: boolean;
  @Input() isExportApplicable = true;
  @Input() notAvailable = false;
  @Input() isHalfYearlyQuotaSupported = false;
  @Input() isHalfYearlyParticipation = false;
  @Input() quotaStatus: boolean;
  @Input() hasPIT: boolean;
  @Input() pitInfo: string;

  public showQuotaChangedMessage: boolean;

  @ViewChild('quotatables_rbi_temp1', { read: ElementRef, static: true }) exportToExcelDiv: ElementRef;
  isMqchvisible: any;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    public userProfileService: UserProfileService,
    public router: Router,
    private filterService: FiltersService,
    private impersonationService: ImpersonationService,
    public sharedContextState: Store<SharedContext>,
    private floodgateService: OcvFloodgateService,
    private exportToExcelServiceNew: ExportToExcelService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  ngOnInit() {
    this.isMqchvisible =
      !window.location.host.includes('microsoftfederal') &&
      !this.filterService.isNewFiscalYear() &&
      this.filterService.isFSA() &&
      this.sharedContext.isImpersonating &&
      (this.sharedContext.loggedInUserInfo.isSuperAdmin || this.sharedContext.loggedInUserInfo.isSupportAdmin || this.sharedContext.loggedInUserInfo.isFCA);

    // SS (23/7/21): In FY22 we have Multi Determiner buckets (with 0% weight) and it is required for them to be shown in QA and QA Report Page
    // And since OCP bucket also has 0% weight and they are applicable to Fy21 (or may be earlier), so we filter out OCP metrics from QA page by calling
    // checkOCPMetrics() if FiscalYear is not latest (i.e Fy22)
    if (this.filterService.isLatestFiscalYear() === false) {
      this.checkOCPMetrics();
    }

    // Un-coment this line for OCV.
    // this.floodgateService.ActivateSurvey(MQCHCampaign);
  }

  exportToExcel(reportName, hasFixedHeader) {
    let tableHtml = '';
    if (hasFixedHeader) {
      this.exportToExcelDiv.nativeElement.firstChild.firstElementChild.remove();
    }

    tableHtml = this.exportToExcelDiv.nativeElement.firstChild.innerHTML;

    const htmlToExport =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><body><table>' +
      tableHtml +
      '</table></body></html>';

    return this.exportToExcelServiceNew.getExporttoexcelDetails(htmlToExport, reportName);
  }

  checkOCPMetrics() {
    const count = this.PCCs.filter(function(pcg) {
      return pcg.PCGWeight > 0;
    }).length;
    if (!this.quotaStatus || count > 0) {
      this.PCCs = this.PCCs.filter(function(pcg) {
        return pcg.PCGWeight > 0;
      });
    }
  }

  navigateToMqch() {
    this.router.navigateByUrl('/mqch');
  }
}
