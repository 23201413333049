import { Component, TemplateRef, Inject, OnInit, ViewChild, Input } from '@angular/core';
import * as Ng1Services from '../core/hybrid/ng1-upgraded-providers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { UserProfileService } from '../core/context/user-profile.service';
import { SplashService } from './splash.service';
import { LocalStorageService } from '@mint-libs/network';
import { NavigationService } from '../core/navigation/navigation.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
  @Input() isAdmin: boolean;
  public sharedContext: SharedContext = new SharedContext();
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private localStorage: LocalStorageService,
    public sharedContextState: Store<SharedContext>,
    private navigationService: NavigationService,
    public userProfileService: UserProfileService,
    private splashService: SplashService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    // this.reportSubmenuList = this.navigationService.reportSubmenuList;
    this.ngReportSubmenuList = this.navigationService.getNgReportsSubMenuList();
    this.compSubmenuList = this.navigationService.compSubmenuList;
    this.helpSubmenuList = this.navigationService.helpSubmenuList;
    this.submenuList = this.navigationService.submenuList;
    this.submenuObj = this.navigationService.submenuObj;
  }
  featureGroups = [];
  displayFeatureGroup: any;
  numOfFeatures: any;
  modalRef;
  modalRef_support;
  private commonFactory: any;
  reportSubmenuList: any[];
  compSubmenuList: any[];
  helpSubmenuList: any[];
  submenuList: any[];
  ngReportSubmenuList: any[];
  submenuObj: any;
  state: string;
  showNotes = false;
  placeholder: any;

  @ViewChild('splashTemplate') public templateref: TemplateRef<any>;

  navigate(arg: string) {
    if (arg === 'FAQs') {
      this.navigateToSubView('help', { name: 'FAQs', active: false, index: 0 });
    } else if (arg === 'TrainingMaterials') {
      this.navigateToSubView('help', { name: 'Training Materials', active: false, index: 1 });
    } else if (arg === 'Glossary') {
      this.navigateToSubView('help', { name: 'Glossary', active: false, index: 2 });
    } else if (arg === 'AccessibilityHelp') {
      this.navigateToSubView('help', { name: 'Accessibility Help', active: false, index: 3 });
    } else if (arg === 'AttainmentByBucket') {
      this.navigateToSubView('report', { name: 'Attainment by Bucket', active: false, index: 0 });
    } else if (arg === 'PayoutDetails') {
      this.navigateToSubView('report', { name: 'Payout Details', active: false, index: 1 });
    } else if (arg === 'TargetandActualsbyIncentiveTerritory') {
      this.navigateToSubView('report', { name: 'Target and Actuals by Incentive Territory', active: false, index: 2 });
    } else if (arg === 'CompensationActualsbyProduct') {
      this.navigateToSubView('report', { name: 'Compensation Actuals by Product', active: false, index: 3 });
    } else if (arg === 'CompensationActualsbyAccount') {
      this.navigateToSubView('report', { name: 'Compensation Actuals by Account', active: false, index: 4 });
    } else if (arg === 'ActualsAnalytics') {
      this.navigateToSubView('report', { name: 'Actuals Analytics (Beta)', active: false, index: 5 });
    } else if (arg === 'QuotaDetails') {
      this.navigateToSubView('report', { name: 'Quota Details', active: false, index: 6 });
    } else if (arg === 'PayoutReportPPI') {
      this.navigateToNgSubView('reports', { name: 'Payout Report', active: false, index: 0 });
    } else if (arg === 'ParticipantPlanPerformance') {
      this.navigateToNgSubView('reports', { name: 'Participant Plan Performance', active: false, htmlfile: '', index: 0 });
    } else if (arg === 'UBIUtilization') {
      this.navigateToNgSubView('reports', {
        name: 'UBI Detailed Utilization Hours',
        active: false,
        index: 1
      });
    } else if (arg === 'MyCompliance') {
      this.navigateToComplianceSubViews({ name: 'My Compliance', active: false, index: 0 });
    } else if (arg === 'Quota Acknowledgement') {
      this.navigateToComplianceSubViews({ name: 'Quota Acknowledgement', active: false, index: 1 });
    } else if (arg === 'Plan Participation Agreement') {
      this.navigateToComplianceSubViews({ name: 'Plan Participation Agreement', active: false, index: 2 });
    } else if (arg === 'Dashboard') {
      this.navigateTo('dashboard', null);
    } else if (arg === 'Estimator') {
      this.navigateTo('estimator', null);
    } else if (arg === 'MyCompensationPlan') {
      this.navigateTo('compensationPlan', null);
    } else if (arg === 'AccelerateAzure') {
      this.navigateTo('accelerateAzure', null);
    } else if (arg === 'ThreeYrThreeCloud') {
      this.navigateTo('cagr', null);
    } else if (arg === 'Settings') {
      this.navigateTo('settings', null);
    }
    this.modalRef.close();
    this.saveFeatureShown(this.displayFeatureGroup.FeatureGroupId + ' ' + this.displayFeatureGroup.modifiedOn);
  }
  ngOnInit() {
    if (!this.isAdmin) {
      this.getSplash();
    }
  }

  getSplash() {
    this.splashService.GetSplash().subscribe(
      (response: any) => {
        this.featureGroups = response;
        this.numOfFeatures = this.featureGroups.length;
        this.openModal(this.templateref);
      },
      error => {
        // handle error
        console.log('error fetching Feature Intro');
      }
    );
  }

  isFeatureShown(featureid) {
    return this.localStorage.getValueFromLocalStorage('Splash:' + this.sharedContext.emailId + featureid);
  }
  saveFeatureShown(featureid) {
    this.localStorage.addToLocalStorage('Splash:' + this.sharedContext.emailId + featureid, 'true');
  }

  openModal(splashTemplate: TemplateRef<any>) {
    if (this.numOfFeatures === 0) {
      return;
    }
    this.displayFeatureGroup = this.featureGroups[this.numOfFeatures - 1];

    if (this.sharedContext.isDemoMode2) {
      return;
    }
    if (!this.isFeatureShown(this.displayFeatureGroup.featureGroupId + ' ' + this.displayFeatureGroup.modifiedOn)) {
      this.modalRef = this.modalService.open(splashTemplate, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'mint-modal-backdrop', backdrop: 'static', windowClass: 'mint-modal-window-lg' });
    }
  }

  openModalAdmin(inputFeatureGroup) {
    this.displayFeatureGroup = inputFeatureGroup;
    this.modalRef = this.modalService.open(this.templateref, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'mint-modal-backdrop', backdrop: 'static', windowClass: 'mint-modal-window-lg' });
  }

  closeModal(event: any) {
    if (!this.isAdmin) {
      this.saveFeatureShown(this.displayFeatureGroup.featureGroupId + ' ' + this.displayFeatureGroup.modifiedOn);
      this.numOfFeatures -= 1;
      this.modalRef.close();
      this.saveFeatureShown(this.displayFeatureGroup.featureGroupId + ' ' + this.displayFeatureGroup.modifiedOn);
      this.openModal(this.templateref);
    } else {
      this.modalRef.close();
    }
  }

  navigateTo(item, data) {
    this.state = item;
    this.navigationService.navigateTo(item, data);
    // if (this.sidebarFactory.isOpened) {
    //   this.sidebarFactory.isOpened = false;
    // }
    this.showNotes = false;
  }

  navigateToSubView(view, submenu) {
    this.navigationService.navigateToSubView(view, submenu);
  }
  navigateToComplianceSubViews(submenuToBeActivated) {
    this.compSubmenuList.forEach(item => {
      if (item.index === submenuToBeActivated.index) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    // window.location.href = 'complianceDashboard';
    this.router.navigate(['complianceDashboard']);
  }

  navigateToNgSubView(view, submenu) {
    this.navigationService.resetActiveParamsOfAllMenus();
    // window.location.href = submenu.name;
    this.router.navigate(['/' + view], { queryParams: { id: submenu.index }, skipLocationChange: true }).then(result => {
      this.navigationService.markActiveParamsOfSubMenu(view, submenu);
    });
  }
}
