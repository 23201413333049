<content-loader [height]="300" [width]="1000">
  <svg:rect x="20" y="20" rx="4" ry="4" width="300" height="80" />
  <svg:rect x="340" y="20" rx="4" ry="4" width="300" height="80" />
  <svg:rect x="660" y="20" rx="4" ry="4" width="300" height="80" />
  <svg:rect x="30" y="134" rx="4" ry="4" width="45" height="200" />
  <svg:rect x="140" y="133" rx="4" ry="4" width="45" height="200" />
  <svg:rect x="245" y="129" rx="4" ry="4" width="45" height="200" />
  <svg:rect x="350" y="134" rx="4" ry="4" width="45" height="200" />
  <svg:rect x="460" y="129" rx="4" ry="4" width="45" height="200" />
  <svg:rect x="565" y="129" rx="4" ry="4" width="45" height="200" />
  <svg:rect x="670" y="129" rx="4" ry="4" width="45" height="200" />
  <svg:rect x="780" y="129" rx="4" ry="4" width="45" height="200" />
  <svg:rect x="885" y="129" rx="4" ry="4" width="45" height="200" />
</content-loader>
