<div id="bannerNotification_1_0_{{i}}" class="impersonation" *ngFor="let notification of bannerNotifications;index as i">
  <div id="bannerNotification_1_{{i}}" class="notification-alert warning" role="alert" *ngIf="!notification.isBannerDismissed">
    <div id="bannerNotification_2_{{i}}" class="row">
        <div id="bannerNotification_3_{{i}}" class="col-xs-12 commen newDashboardBanner_dim newDashboardBanner_dim1">
            <label id="bannerNotification_4_{{i}}" class="more banner-notif-truncate bannerNotification-label-normal">
              {{ notification.NotificationText }}
              <a *ngIf="notification.UrlLink && notification.UrlLink.length > 0" href="{{ notification.UrlLink }}" target="_blank">Click Here</a>
            </label>
        </div>
        <button id="bannerNotification_5_{{i}}" title="Close notification" (click)="removeBanner(notification)" class="btn-clear pull-right bannerNotification-mr-10">
            <em id="bannerNotification_6_{{i}}" class="icon icon-close"></em>
        </button>
        <!-- <a href="javascript:void(0)" id="banner-{{notification.NotificationId}}" class="pull-right triangle-down"></a> -->
    </div>
  </div>
</div>