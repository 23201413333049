import { Component, OnInit } from '@angular/core';
import { COMMON_ISSUES } from './common-issues';
import { environment } from '../../environments/environment';
import { UserProfileService } from '../core/context/user-profile.service';

@Component({
  selector: 'mint-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  commonIssues = [];
  msxSupportUrl = 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=custom&name=msp_msxhomepage_e01cc&data=%7B%22type%22:%22supportability%22,%22workspace%22:%22msp_mspearningsapp_4553f%22,%22showPanel%22:%22true%22%7D';
  paSupportUrl = 'https://aka.ms/mcapshelp';
  incentiveCompensationGuideUrl = 'https://microsoft.sharepoint.com/teams/IncentiveCompensationGuide/SitePages/NewSupportExperience.aspx?xsdata=MDV8MDJ8YW5pa2FsYXZpb3NAbWljcm9zb2Z0LmNvbXwzNWI3MzJkNjFhNWY0NGRmMDlhYjA4ZGM0MzZlODYyOHw3MmY5ODhiZjg2ZjE0MWFmOTFhYjJkN2NkMDExZGI0N3wxfDB8NjM4NDU5Mzg5MjIyNjAzNjgyfFVua25vd258VFdGcGJHWnNiM2Q4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazFoYVd3aUxDSlhWQ0k2TW4wPXwwfHx8&sdata=NGs4NHR2bWFqQTk2WDV1VDhESE1sN1VXQzRuWFRnUDUwS2RvdC8raW1LYz0%3d#mcapshelp';
  isServiceNowEnabled: boolean;
  isUBIPersona: boolean;
  constructor(
    private userProfileService: UserProfileService,
  ) {
    this.isServiceNowEnabled = environment.isServiceNowEnabled;
    this.isUBIPersona = this.userProfileService.isUBIPersona();
  }

  ngOnInit() {
    const defaultBaseUrl = 'mint.microsoft.com';
    if (environment.appBaseUrl == defaultBaseUrl) {
        this.commonIssues = COMMON_ISSUES;
    } else {
        this.commonIssues = COMMON_ISSUES.map(issue => {
          issue.content = issue.content.replace(RegExp(defaultBaseUrl, 'gi'), environment.appBaseUrl)
          return issue;
        });
    }
  }

  toggleAccordion(issue) {
    issue.isExpanded = !issue.isExpanded;
  }
}
