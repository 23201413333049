import { deserializeArray, Expose, plainToClass, Type } from 'class-transformer';
import { BaseModel } from '../../../shared/models/base.model';
import { Revenue } from './revenue.model';

export class RevenueProduct extends BaseModel {
  @Expose({ name: 'ProductName' })
  name: string;

  @Expose({ name: 'PCCName' })
  pccName: string;

  @Expose({ name: 'PCGName' })
  pcgName: string;

  @Expose({ name: 'PCCID' })
  pccID: number;

  @Type(() => Revenue)
  @Expose({ name: 'Revenue' })
  revenue: Revenue;

  deserialize(from: any): RevenueProduct[] {
    const revenueProducts: RevenueProduct[] = [];
    from.forEach(a => {
      const product = new RevenueProduct();
      product.revenue = new Revenue().deserializeSingle(a.Revenue);
      product.name = a.ProductName;
      product.pccID = a.PCCId;
      product.pccName = a.PCCName;
      product.pcgName = a.PCGName;
      revenueProducts.push(product);
    });
    return revenueProducts;
  }
  deserializeSingle(from: any): RevenueProduct {
    const product = new RevenueProduct();
    product.revenue = new Revenue().deserializeSingle(from.Revenue);
    product.name = from.ProductName;
    product.pccID = from.PCCId;
    product.pccName = from.PCCName;
    product.pcgName = from.PCGName;
    return product;
  }
}
