<div class="direct__utilization__quota">
  <div class="metric__type">
    <div class="metric__type__desc">{{ quarterText }}</div>
  </div>
  <div class="pcg__items__container">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem class="metric__detail slide">
        <div class="metric__name">Target</div>
        <div class="metric__weight">{{ direct.azureIncentivesMetrics.target | customCurrency: direct.azureIncentivesMetrics.unit }}</div>
      </div>
      <div ngxSlickItem class="metric__detail slide">
        <div class="metric__name">Revenue</div>
        <div class="metric__weight">{{ direct.azureIncentivesMetrics.revenue | customCurrency: direct.azureIncentivesMetrics.unit }}</div>
      </div>
      <div ngxSlickItem class="metric__detail slide">
        <div class="metric__name">Quota To Go</div>
        <div class="metric__weight">{{ direct.azureIncentivesMetrics.quotaToGo | customCurrency: direct.azureIncentivesMetrics.unit }}</div>
      </div>
      <div ngxSlickItem class="metric__detail slide">
        <div class="metric__name">Individual Attainment</div>
        <div class="metric__weight">{{ direct.azureIncentivesMetrics.individualAttainment | percentage }}</div>
      </div>
      <div ngxSlickItem class="metric__detail slide">
        <div class="metric__name">Overall Growth (target 75%)</div>
        <div class="metric__weight">{{ direct.azureIncentivesMetrics.overallGrowthRate }}</div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
