import { EndpointService } from '@mint-libs/network';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DataService } from '../network/data.service';

import * as AllParticipantsActions from './all-participants.actions';

@Injectable()
export class AllParticipantsEffects {
  loadAllParticipantss$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AllParticipantsActions.loadAllParticipants),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        this.dataService.get('api/Participant/RetreiveAllParticipants/v1').pipe(
          map(data => AllParticipantsActions.loadAllParticipantssSuccess({ data })),
          catchError(error => of(AllParticipantsActions.loadAllParticipantssFailure({ error })))
        )
      )
    );
  });

  constructor(private actions$: Actions, private dataService: DataService, private endpoints: EndpointService) {}
}
