<table>
  <tr>
    <th>Date</th>
    <th>Type</th>
    <th>Reason</th>
    <th>Time Period</th>
    <th>Amount</th>
  </tr>
  <tr *ngFor="let adjusmentInfo of adjustmentDetails">
    <td>{{ adjusmentInfo.AdjustmentDate | date: 'mediumDate' }}</td>
    <td>{{ adjusmentInfo.AdjustmentType }}</td>
    <td>{{ adjusmentInfo.AdjustmentReason }}</td>
    <td>{{ adjusmentInfo.AdjustmentCodePeriod }}</td>
    <td>{{ payoutReportService.formatCurrency(adjusmentInfo.AdjustmentAmount) }}</td>
  </tr>
</table>
