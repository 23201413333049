import * as _ from 'underscore';
import * as $ from 'jquery';
import { Workbook } from '@progress/kendo-angular-excel-export';
import { defer, Observable, Subscriber } from 'rxjs';

export class CommonFactory {
  notifications: any[] = [];
  showProgressList: any[] = [];
  spinners: any[] = [];
  showPartialSpinner: boolean;
  constructor() {}

  setHeight() {
    // $timeout(function() {
    //   var calh = 124; // 48 + 76 Base height (Green Bar)
    //   if (angular.element('.notification-container').length) {
    //     var calh = calh + angular.element('.notification-container').length * 52;
    //   }
    //   if (angular.element('.filters-tab').length) {
    //     calh = calh + angular.element('.filters-tab').length * 52;
    //   }
    //   if (angular.element('.optout').length) {
    //     calh = calh + angular.element('.optout').length * 56;
    //   }
    //   if (angular.element('.dashboard__new__title').length) {
    //     calh = calh + angular.element('.dashboard__new__title').length * 26;
    //   }
    //   if (angular.element('.notification-alert').length) {
    //     calh = calh + angular.element('.notification-alert').length * 56;
    //   }
    //   if (angular.element('.accelerate__azure__content').length) {
    //     calh = calh + 69;
    //   }
    //   var height = calh;
    //   angular
    //     .element('.content-wrapper')
    //     .css('height', '100vh')
    //     .css('height', '-=' + height + 'px');
    // }, 100);
  }

  getStartEndDatesByQuarter(context, userProfile, stepId) {
    var result = new Object();

    var standardTitles = userProfile.DefaultFilter.StandardTitle;
    if (Array.isArray(standardTitles)) {
      standardTitles.forEach(function(standardTitle) {
        standardTitle.ApplicablePartitions.forEach(function(partition) {
          if (partition.StepId === stepId) {
            result = partition.Duration;
          }
        });
      });
    } else {
      standardTitles.ApplicablePartitions.forEach(function(partition) {
        if (partition.StepId === stepId) {
          result = partition.Duration;
        }
      });
    }

    return result;
  }

  getDaysCountByQuarter(context, userProfile, stepId) {
    var result = this.getStartEndDatesByQuarter(context, userProfile, stepId);

    var duration = Math.floor((new Date(result['EndDate']).getTime() - new Date(result['StartDate']).getTime()) / 86400000);

    return duration + 1; // Adding one to make it inclusive of the last day. Not adding as part of the endDate creation to keep it more contextual with quarter.
  }

  getYTDDaysCount(context, userProfile) {
    var stepId = context.appliedFilter.stepId;
    var year = context.appliedFilter.fiscalYear;

    var ytdDaysCount = 0;

    while (stepId > 0) {
      ytdDaysCount += this.getDaysCountByQuarter(context, userProfile, stepId);
      stepId--;
    }

    return ytdDaysCount;
  }

//   showSpinner(promise, message) {
//     var id = `spinner_${this.showProgressList.length}`;

//     //sidebar and header should be active while loading any page on home.html
//     this.showProgressList.push({ id: id, message: message ? message : 'Loading...' });
//     //stop tabbing in the background.
//     // $(document).on('keydown', function(e) {
//     //   e.preventDefault();
//     // });
//     promise
//       .then(
//         function(success) {
//           //success
//           setTimeout(function() {
//             this.showProgressList.splice(
//               this.showProgressList.findIndex(function(item) {
//                 return item.id === id;
//               }),
//               1
//             );
//             // if (this.showProgressList.length === 0) {
//             //   $(document).off('keydown');
//             // }
//           }, 100);
//         },
//         function(error) {
//           //error
//           setTimeout(function() {
//             this.showProgressList.splice(
//               this.showProgressList.findIndex(function(item) {
//                 return item.id === id;
//               }),
//               1
//             );
//             // if (this.showProgressList.length === 0) {
//             //   $(document).off('keydown');
//             // }
//           }, 100);
//         }
//       )
//       .finally(function() {
//         if (this.showProgressList.length === 0) {
//           this.showPartialSpinner = false;
//         }
//       });
//   }
//   dismissAllSpinners() {
//     setTimeout(function() {
//       this.showProgressList = [];
//       this.showPartialSpinner = false;
//     }, 100);
//   }
//   dismissAllSpinnersWithoutTimeout() {
//     this.showProgressList = [];
//     this.showPartialSpinner = false;
//   }

  resolvePromise(deferred: Subscriber<any>, msg) {
    deferred.next(msg);
  }
  //   DownloadFile(data, location, fileName, isAnsi) {
  //     var deferred = $q.defer();

  //     location.createFileAsync(fileName, Windows.Storage.CreationCollisionOption.replaceExisting).done(
  //       function(file) {
  //         var memoryStream = new Windows.Storage.Streams.InMemoryRandomAccessStream();
  //         var dataWriter = new Windows.Storage.Streams.DataWriter(memoryStream);

  //         if (isAnsi === true) {
  //           dataWriter.writeString(data);
  //         } else {
  //           dataWriter.writeBuffer(data);
  //         }

  //         var buffer = dataWriter.detachBuffer();
  //         dataWriter.close();

  //         Windows.Storage.FileIO.writeBufferAsync(file, buffer).done(
  //           function(success) {
  //             deferred.resolve({ Status: true });
  //           },
  //           function(error) {
  //             deferred.reject(error);
  //           }
  //         );
  //       },
  //       function(err) {
  //         deferred.reject(err);
  //       }
  //     );
  //     return deferred.promise;
  //   }
  //   DownloadAndOpenFile(data, location, fileName, isAnsi) {
  //     var deferred = $q.defer();

  //     this.DownloadFile(data, location, fileName, isAnsi).then(function() {
  //       location.getFileAsync(fileName).done(function(file) {
  //         Windows.System.Launcher.launchFileAsync(file).done(
  //           function(success) {
  //             deferred.resolve({ Status: true });
  //           },
  //           function(error) {
  //             deferred.reject(error);
  //           }
  //         );
  //       });
  //     });

  //     return deferred.promise;
  //   }
  //   ExportGridToExcel(e, location, fileName) {
  //     var dataURL;
  //     var extension = '.xlsx';
  //     var fileWithDateTime = fileName + Date.now() + extension;

  //     if (e.preventDefault) {
  //       e.preventDefault();
  //       dataURL = new Workbook(e.workbook).toDataURL();
  //     } else {
  //       dataURL = e.toDataURL();
  //     }

  //     var encodedData = dataURL.split(';base64,')[1];

  //     var data = Windows.Security.Cryptography.CryptographicBuffer.decodeFromBase64String(encodedData);

  //     var promise = this.DownloadAndOpenFile(data, location, fileWithDateTime).then(
  //       function(result) {
  //         //Success
  //       },
  //       function(error) {
  //         //Failed
  //       }
  //     );
  //     this.showSpinner(promise);
  //   }
  //   generateArray(table) {
  //     var newHtmlObj = $(table).clone();

  //     //Remove unwanted objects from html
  //     $('div.add-widget-tile', newHtmlObj).remove();
  //     $('th[removeinExport="true"]', newHtmlObj).remove();
  //     $('td[removeinExport="true"]', newHtmlObj).remove();

  //     //prepare html that is excel compatible
  //     var htmlToExport =
  //       '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><body><table>' +
  //       newHtmlObj.html() +
  //       '</table></body></html>';

  //     var out = [];
  //     var html = $(htmlToExport);
  //     var htmlTable = $(htmlToExport);

  //     var headercell = [];
  //     var headerval = [];
  //     var heds = $(htmlToExport).find('th');
  //     $.each(heds, function(key, value) {
  //       headerval.push($(value).text());
  //     });

  //     var rows = $(htmlToExport).find('tbody tr');
  //     var ranges = [];
  //     for (var R = 0; R < rows.length; ++R) {
  //       var outRow = [];
  //       var row = rows[R];
  //       var columns = row.getElementsByTagName('td');
  //       for (var C = 0; C < columns.length; ++C) {
  //         var cell = columns[C];
  //         var colspan = cell.getAttribute('colspan');
  //         var rowspan = cell.getAttribute('rowspan');
  //         var cellValue = $.trim(cell.innerText);
  //         if (cellValue !== '' && cellValue !== null && cellValue == +cellValue) cellValue = +cellValue;

  //         //Skip ranges
  //         ranges.forEach(function(range) {
  //           if (R >= range.s.r && R <= range.e.r && outRow.length >= range.s.c && outRow.length <= range.e.c) {
  //             //for (var i = 0; i <= range.e.c - range.s.c; ++i) outRow.push(null);
  //           }
  //         });

  //         //Handle Row Span
  //         if (rowspan || colspan) {
  //           rowspan = rowspan || 1;
  //           colspan = colspan || 1;
  //           ranges.push({ s: { r: R, c: outRow.length }, e: { r: R + rowspan - 1, c: outRow.length + colspan - 1 } });
  //         }

  //         //Handle Value
  //         outRow.push(cellValue !== '' ? cellValue : null);

  //         //Handle Colspan
  //         // if (colspan) for (var k = 0; k < colspan - 1; ++k) outRow.push(null);
  //       }
  //       //outRow.push(headervalue !== "" ? headervalue : null);
  //       out.push(outRow);
  //     }
  //     // out.push(outRow);
  //     out[0] = headerval;

  //     out = _.without(out, null);
  //     return [out, ranges, headerval.length];
  //   }
  //   ExportTableToExcel(table, location, fileName) {
  //     var oo = this.generateArray(table);
  //     var ranges = oo[1];
  //     var headers = oo[2];
  //     var wscols = [];
  //     for (var C = 0; C < headers; C++) {
  //       wscols.push({ wch: 50 });
  //     }
  //     /* original data */
  //     var data = oo[0];
  //     var ws_name = fileName;
  //     //prepare filename
  //     var fileWithDateTime = fileName + Date.now() + '.xlxs';
  //     var errorcells = [];
  //     if (_.isUndefined(errorcells) || _.isNull(errorcells)) errorcells = [];

  //     function Workbook() {
  //       if (!(this instanceof Workbook)) return new Workbook();
  //       this.SheetNames = [];
  //       this.Sheets = {};
  //     }
  //     var wb = new Workbook(),
  //       ws = this.sheet_from_array_of_arrays(data, errorcells);

  //     /* add worksheet to workbook */
  //     wb.SheetNames.push(fileName);
  //     wb.Sheets[fileName] = ws;
  //     ws['!cols'] = wscols;

  //     //var wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: false, type: 'binary' });
  //     var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' });
  //     var promise = this.DownloadOpenFile(wbout, fileName).then(
  //       function(result) {
  //         //Success
  //       },
  //       function(error) {
  //         //Failed
  //       }
  //     );
  //     this.showSpinner(promise);
  //   }
  //   sheet_from_array_of_arrays(data, errorcells) {
  //     var ws = {};
  //     var colWidth = [];
  //     var range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
  //     for (var R = 0; R != data.length; ++R) {
  //       for (var C = 0; C != data[R].length; ++C) {
  //         if (range.s.r > R) range.s.r = R;
  //         if (range.s.c > C) range.s.c = C;
  //         if (range.e.r < R) range.e.r = R;
  //         if (range.e.c < C) range.e.c = C;
  //         var cell = {
  //           v: data[R][C]
  //         };
  //         if (cell.v == null) continue;
  //         var cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

  //         /* TEST: proper cell types and value handling */
  //         if (typeof cell.v === 'number') cell.t = 's';
  //         else if (typeof cell.v === 'boolean') cell.t = 'b';
  //         else if (cell.v instanceof Date) {
  //           cell.t = 's';
  //           cell.z = XLSX.SSF._table[14];
  //           cell.v = datenum(cell.v);
  //         } else cell.t = 's';
  //         if (R == 0) {
  //           colWidth.push({ wch: cell.v.length + 10 });
  //           cell.s = {
  //             font: {
  //               sz: '12',
  //               color: {
  //                 theme: '1'
  //               },
  //               name: 'Calbri',
  //               bold: true
  //             }
  //           };
  //         }

  //         var isErrorCell = _.find(errorcells, function(eCell) {
  //           return eCell.row - 1 == R && eCell.column - 1 == C;
  //         });
  //         if (isErrorCell != null) {
  //           cell.s = {
  //             fill: {
  //               patternType: 'solid',
  //               fgColor: {
  //                 rgb: 'FFFF0000'
  //               }
  //             }
  //           };
  //         }
  //         ws[cell_ref] = cell;
  //       }
  //     }
  //     if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
  //     ws['!cols'] = colWidth;
  //     return ws;
  //   }
  //   DownloadOpenFile(wbout, suggestedFilename) {
  //     var deferred = $q.defer();
  //     var promise = this.DownloadsFile(wbout, suggestedFilename);
  //     this.showSpinner(promise, 'Preparing Excel File');
  //     promise.then(
  //       function(file) {
  //         Windows.System.Launcher.launchFileAsync(file).done(
  //           function(success) {
  //             deferred.resolve({ Status: true });
  //           },
  //           function(error) {
  //             deferred.reject(error);
  //           }
  //         );
  //       },
  //       function(error) {
  //         deferred.reject(error);
  //       }
  //     );

  //     return deferred.promise;
  //   }
  //   DownloadsFile(wbout, suggestedFilename) {
  //     var deferred = $q.defer();
  //     var pickers = Windows.Storage.Pickers;
  //     var savePicker = new pickers.FileSavePicker();

  //     savePicker.fileTypeChoices.insert('PE Workbook', ['.xlsx']);
  //     savePicker.suggestedFileName = suggestedFilename || 'Quota' + Date.now();
  //     savePicker.pickSaveFileAsync().then(
  //       function(file) {
  //         if (file) {
  //           var memoryStream = new Windows.Storage.Streams.InMemoryRandomAccessStream();
  //           var dataWriter = new Windows.Storage.Streams.DataWriter(memoryStream);
  //           var buffer = Windows.Security.Cryptography.CryptographicBuffer.decodeFromBase64String(wbout);
  //           dataWriter.close();
  //           Windows.Storage.FileIO.writeBufferAsync(file, buffer).then(
  //             function() {
  //               deferred.resolve(file);
  //             },
  //             function(e) {
  //               deferred.reject(e);
  //             }
  //           );
  //         } else {
  //           deferred.reject('User Cancelled');
  //         }
  //       },
  //       function(error) {
  //         deferred.reject(error);
  //       }
  //     );

  //     return deferred.promise;
  //   }
  //   launchUriInBrowser(uri) {
  //     // Create a Uri object from a URI string
  //     var launchUri = new Windows.Foundation.Uri(uri);

  //     // Launch the URI with a warning prompt
  //     var options = new Windows.System.LauncherOptions();
  //     options.treatAsUntrusted = false;

  //     Windows.System.Launcher.launchUriAsync(launchUri, options).then(
  //       function(success) {
  //         if (success) {
  //           // URI launched and close the application
  //           window.close();
  //         } else {
  //           // URI launch failed
  //         }
  //       },
  //       function(error) {
  //         //error
  //       }
  //     );
  //   }
  formatSmartSearchInput(model) {
    if (model) {
      var aliasStartIndex = model.indexOf('(') + 1;
      var personnelNumberStartIndex = model.indexOf('-', aliasStartIndex);
      var personnelNumberEndIndex = model.indexOf(')');
      var alias = model.substring(aliasStartIndex, personnelNumberStartIndex).trim();
      var personnelNumber = model.substring(personnelNumberStartIndex + 1, personnelNumberEndIndex).trim();
      if (alias === 'null') {
        return personnelNumber;
      } else {
        return alias;
      }
    }
  }
  addNotification(notification: any) {
    notification.id = 'notification_' + this.notifications.length;

    //set order of notifications as Warnings, Error, Success, Information
    if (notification.name === 'warning') {
      this.notifications.splice(0, 0, notification);
    } else if (notification.name === 'error') {
      var lastIndex = this.notifications.findIndex(notif => notif.name === 'warning');
      this.notifications.splice(lastIndex + 1, 0, notification);
    } else if (notification.name === 'success') {
      var lastIndex = this.notifications.findIndex(notif => notif.name === 'error');
      if (lastIndex === -1) {
        lastIndex = this.notifications.findIndex(notif => notif.name === 'warning');
      }
      this.notifications.splice(lastIndex + 1, 0, notification);
    } else this.notifications.push(notification);

    this.setHeight();
    //remove notification after given timeout
    if (notification.dismiss) {
      setTimeout(function() {
        this.notifications.splice(
          this.notifications.findIndex(function(item) {
            return item.id === notification.id;
          }),
          1
        );
        this.setHeight();
      }, notification.dismiss);
    }
  }
  getUserName(userProfileInfo) {
    if (userProfileInfo == undefined || userProfileInfo == null) {
      return '';
    }
    return userProfileInfo.unique_name;
  }
  //   updateTrayIcon(value) {
  //     var Notifications = Windows.UI.Notifications;

  //     var badgeXml = Notifications.BadgeUpdateManager.getTemplateContent(Notifications.BadgeTemplateType.badgeNumber);
  //     var badgeAttributes = badgeXml.getElementsByTagName('badge');
  //     badgeAttributes[0].setAttribute('value', value);

  //     var badgeNotification = new Notifications.BadgeNotification(badgeXml);
  //     Notifications.BadgeUpdateManager.createBadgeUpdaterForApplication().update(badgeNotification);
  //   }
}
