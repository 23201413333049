import { SafeHtml } from '@angular/platform-browser';
import { DataSource } from './data-source.model';
import { BaseModel } from './base.model';
import { Expose, deserializeArray, plainToClass, Type } from 'class-transformer';
import { Formatter } from '@mint-libs/common';

export class BonusAttainmentDetails extends BaseModel {
  formatter: Formatter = new Formatter();

  @Expose({ name: 'PlanComponentName' }) PlanComponentName: string;
  @Expose({ name: 'PlanComponentId' }) PlanComponentId: string;
  @Expose({ name: 'PerspectiveName' }) PerspectiveName: string;
  @Expose({ name: 'CalculationType' }) CalculationType: string;
  @Expose({ name: 'Actual' }) actuals: string;
  @Expose({ name: 'Quota' }) target: string;
  @Expose({ name: 'QuotaRecurring' }) targetRecurring: string;


  @Expose({ name: 'Attainment' }) attainment: string;
  @Expose({ name: 'BingoAttainment' }) BingoAttainment: string;
  @Expose({ name: 'PercentageValue' }) percentageValue: string;
  @Expose({ name: 'LockStatus' }) locked: boolean;
  @Expose({ name: 'CurrencyCode' }) currency: string;
  @Expose({ name: 'PCCUnitLongName' }) unit: string;
  @Expose({ name: 'DerivedValue' }) ITA: number;
  @Expose({ name: 'GrainBonusAmount' }) bonusAmount: number;

  @Expose({ name: 'BonusTooltip' }) bonusTooltip: string;

  @Expose({ name: 'SourceDetails' })
  @Type(() => DataSource)
  dataSource: Array<DataSource>;
  // @Expose({ name: 'GrainBonusAmount' }) currency: string; 
  // @Expose({ name: 'BingoBonusAmount' }) currency: string; 

  pCGRowSpan: number;
  pccNameGrid: string;
  tooltip: string;

  isBingo(): boolean {
    return this.CalculationType == "All";
  }

  actualsStr() {
    return this.formatter.formatAndRoundOff(this.actuals);
  }

  actualsStrCurrency() {

    if (this.isBingo()) {
      return "";
    }
    else {
      return this.formatter.formatAndRoundOff(this.actuals) + " " + this.unit;
    }
  }

  targetStr() {
    return this.formatter.formatAndRoundOff(this.target);
  }

  targetStrCurrency() {

    if (this.isBingo()) {
      return "";
    }
    else {
      return this.formatter.formatAndRoundOff(this.target) + " " + this.unit;
    }
  }
  targetRecurringStrCurrency() {

    if (this.isBingo()) {
      return "";
    }
    else {
      return this.formatter.formatAndRoundOff(this.targetRecurring) + " " + this.unit;
    }
  }


  bonusAmountStr() {
    return this.formatter.formatAndRoundOff(this.bonusAmount);
  }

  attainmentStr() {

    if (this.isBingo()) {
      return this.BingoAttainment;
    }
    else {
      return this.formatter.formatAndRoundOff(this.attainment) + " %";

    }
  }


  deserialize(from: any): BonusAttainmentDetails[] {
    const cagrAccountDetailsList = deserializeArray<BonusAttainmentDetails>(BonusAttainmentDetails, JSON.stringify(from), this.classTransformOptions);
    return cagrAccountDetailsList;
  }
  deserializeSingle(from: object): BonusAttainmentDetails {
    const bonusAttributeDetails = plainToClass<BonusAttainmentDetails, object>(BonusAttainmentDetails, from, this.classTransformOptions);
    return bonusAttributeDetails;
  }



}
