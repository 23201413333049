import { Component, Inject, Input, EventEmitter, Output, AfterViewChecked, ElementRef } from '@angular/core';
import { OnInit } from '@angular/core';

import { MetricGridService } from './metric-grid.service';
import { MetricGridDataHelperService } from './metric-grid-data-helper.service';
import { MetricGridViewHelperService } from './metric-grid-view-helper.service';

import { MetricRendererComponent } from './renderers/metric-renderer.component';
import { AttainmentRendererComponent } from './renderers/attainment-renderer.component';
import { ProductGroupRendererComponent } from './renderers/product-group-renderer.component';
import { DataSourceRendererComponent } from './renderers/data-source-renderer.component';
import { CustomHeaderComponent } from '../grid-components/custom-header/custom-header.component';
import { PCGMetricAPIResponse } from '../models/pcg-metric-response';
import { BaseGridComponent } from './base-grid.component';
import { LegendService } from '../legends/legend.service';
import { Metric } from '../models/metric.model';
import { BucketParams } from '../models/bucket-params.model';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { IncentiveType } from '../models/incentive-type.enum';
import { UserProfileService } from '../../core/context/user-profile.service';
import { FiltersService } from '../../filters/filters.service';

@Component({
  selector: 'app-metric-grid',
  providers: [MetricGridService, MetricGridViewHelperService],
  templateUrl: './metric-grid.component.html',
  styleUrls: ['./metric-grid.component.scss']
})
export class MetricGridComponent extends BaseGridComponent implements OnInit {
  private noData: boolean;
  private errorOccured: boolean;
  rubiPCGData = [];

  public attainmentDataSet;


  @Output() totalWeightedAttainmentData = new EventEmitter();

  @Input() isYTD = false;
  @Input() neutralLegend: boolean;
  @Input() bucketType: string = null;
  @Input() bucketIncentiveType?: IncentiveType = null;

  pcgMetricAPIResponse: PCGMetricAPIResponse = {};

  @Input() selectedMonth: number;
  @Input() isUBI = false;
  @Input() isRUBI = false;
  @Input() isPPI = false;
  @Input() acceleration: string;
  @Input() caDetails = null;
  @Input() showOCPLegend = false;

  isLoaded = false;
  metricGridDataResult: any;
  isFSA: boolean;
  constructor(
    private metricGridDataHelperService: MetricGridDataHelperService,
    private metricGridViewHelperService: MetricGridViewHelperService,
    protected legendService: LegendService,
    private metricGridService: MetricGridService,
    private filterService:FiltersService,
    public userProfileService: UserProfileService,
    protected element: ElementRef
  ) {
    super(legendService, 105, element);
    this.isFSA = filterService.isFSA();
  }

  ngOnInit(): void {
    this.columnDefs = this.metricGridViewHelperService.getColumnDef(this.isYTD, this.getLegendClassRules, this.isUBI, this.isRUBI, this.isPPI, this.bucketType);

    if (this.isYTD) {
      this.getMetricData(this.selectedMonth);
    } else {
      this.getMetricData(-1);
    }

    this.frameworkComponents = {
      agColumnHeader: CustomHeaderComponent,
      metricRenderer: MetricRendererComponent,
      attainmentRenderer: AttainmentRendererComponent,
      pgRenderer: ProductGroupRendererComponent,
      dataSourceRenderer: DataSourceRendererComponent
    };
  }

  getMetricData(selectedMonth) {
    this.isLoaded = false;
    this.selectedMonth = selectedMonth;
    // getRubiPCGMetrics is only needed for past years
    if (this.isRUBI && !this.isFSA) {
      this.metricGridService.getRubiPCGMetrics().subscribe(result => {
        this.metricGridService.getRefreshTimesOfDataSources().subscribe(response => {
          const rubiPCG = {} as Metric;
          rubiPCG.attainment = result.PlanComponentGroups[0].Metrics.Attainment.toFixed(2);
          rubiPCG.name = result.PlanComponentGroups[0].Name;
          rubiPCG.attainmentColor = '#000000';
          rubiPCG.incentiveType = result.PlanComponentGroups[0].IncentiveType;
          const rubiPCGData = [];
          rubiPCGData.push(rubiPCG);
          this.isLoaded = true;
          this.rowData = this.metricGridDataHelperService.buildPccData(result.PlanComponentGroups, rubiPCGData, response);
          if (this.rowData && this.rowData.length > 0) {
            this.rowData[0].Acceleration = this.acceleration;
          }
        });
      });
    } else {
      if (this.isYTD) {
        this.metricGridDataHelperService.getMetricGridYtdData(selectedMonth).subscribe(pcgMetricAPIResponse => {
          this.processResponse(pcgMetricAPIResponse);
        });
      } else {
        const bucketParams = new BucketParams();
        bucketParams.bucketType = this.bucketType;
        bucketParams.incentiveType = this.bucketIncentiveType;
        this.metricGridDataResult = this.metricGridDataHelperService.getMetricGridData(bucketParams);
        this.metricGridDataResult.subscribe(pcgMetricAPIResponse => {
          if (pcgMetricAPIResponse[3]) {
            this.processResponse(pcgMetricAPIResponse);
          }
        });
      }
    }
  }

  processResponse(pcgMetricAPIResponse) {
    if (pcgMetricAPIResponse[1] && pcgMetricAPIResponse[2] && pcgMetricAPIResponse[0]) {
      const targetSummary = pcgMetricAPIResponse[0];
      const paylinesSummary = pcgMetricAPIResponse[1];
      const refreshTimeSummary = pcgMetricAPIResponse[2];

      this.totalWeightedAttainmentData.emit(targetSummary.TotalWeightedAttainment);

      if (targetSummary && targetSummary.PlanComponentGroups.length && targetSummary.PlanComponentGroups.length > 0) {
        this.noData = false;
        const pcgData = this.metricGridDataHelperService.buildPcgData(paylinesSummary, this.neutralLegend, this.caDetails);
        this.rowData = this.metricGridDataHelperService.buildPccData(targetSummary.PlanComponentGroups, pcgData, refreshTimeSummary, this.caDetails);
        if (this.isPPI) {
          this.rowData = this.metricGridDataHelperService.getPPIPCGGridViewMetrics(this.rowData, targetSummary, this.bucketType);
        }
        if (this.userProfileService.isRBIPersona()) {
          this.showOCPLegend = pcgData.find(pcg => pcg.weight === 0);
        }
      } else {
        this.noData = true;
      }

      this.isLoaded = true;
      return this.rowData;
    }
  }
}
