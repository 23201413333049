import { Component, OnInit, Input } from '@angular/core';
import { CagrService } from '../cagr.service';

@Component({
  selector: 'mint-products-services',
  templateUrl: './products-services.component.html',
  styleUrls: ['./products-services.component.scss']
})
export class ProductsServicesComponent implements OnInit {
  @Input() productsServicesData: any;
  private cagrService: CagrService;
  isProductsLoaded = false;
  constructor() {}
  data = [
    {
      pcgName: null,
      multiPCC: false,
      pCC: [
        {
          pccName: 'Azure Consumed Revenue',
          productsList: [
            'Azure Consumed Revenue',
            'Modern Workplace Cloud',
            'Business Applications Cloud',
            'Business Applications Platform (including Power BI, Power Apps)',
            'LinkedIn',
            'All on-premises (excluding Services, Surface)'
          ]
        }
      ]
    },
    {
      pcgName: null,
      multiPCC: false,
      pCC: [
        {
          pccName: 'Biz Apps',
          productsList: [
            'Dynamics 365',
            'Azure Consumed Revenue',
            'Customer Engagement',
            'Finance & Operations',
            'Business Applications Platform (including Power BI, Power Apps)',
            'LinkedIn',
            'All on-premises (excluding Services, Surface)'
          ]
        }
      ]
    },
    {
      pcgName: 'Mordern Workplace',
      multiPCC: true,
      pCC: [
        {
          pccName: 'Modern Workplace Billed',
          productsList: ['Azure Consumed Revenue']
        },
        {
          pccName: 'Teams Usage',
          productsList: [
            'Office 365 Core Active Users',
            'Office 365 ProPlus Monthly Active Devices',
            'Modern Communications Active Users',
            'Windows 10 Monthly Active Devices',
            'Enterprise Mobility Active Users'
          ]
        }
      ]
    }
  ];
  productsData = [
    {
      name: 'Azure Consumed revenue',
      listItems: [
        'Azure Consumed Revenue',
        'Modern Workplace Cloud',
        'Business Applications Cloud',
        'Business Applications Platform (including Power BI, Power Apps)',
        'LinkedIn',
        'All on-premises (excluding Services, Surface)'
      ]
    },
    {
      name: 'Biz Apps',
      listItems: [
        'Dynamics 365',
        'Azure Consumed Revenue',
        'Customer Engagement',
        'Finance & Operations',
        'Business Applications Platform (including Power BI, Power Apps)',
        'LinkedIn',
        'All on-premises (excluding Services, Surface)'
      ]
    },
    {
      name: 'Modern Workplace Billed',
      listItems: ['Azure Consumed Revenue']
    },
    {
      name: 'Teams Usage',
      listItems: [
        'Office 365 Core Active Users',
        'Office 365 ProPlus Monthly Active Devices',
        'Modern Communications Active Users',
        'Windows 10 Monthly Active Devices',
        'Enterprise Mobility Active Users'
      ]
    }
  ];
  pcgData = [{ pccCount: 1, pcgName: 'Azure Consumed revenue' }, { pccCount: 2, pcgName: 'Biz Apps' }, { pccCount: 2, pcgName: 'Mordern Workplace' }];

  productsServicesData1 = { productsData: this.productsData, pcgData: this.pcgData };
  ngOnInit() {
    // this.BindAccountsDetails(123);
  }
}
