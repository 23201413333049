import { Injectable } from '@angular/core';
// import { environment } from '@mint-libs/common/environments';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  // public BaseUri = environment.mintapiBaseUrl;
  // public MintApiBaseUri = environment.mintapiBaseUrl;
  // public MintApiDetailBaseUri = environment.mintapiDetailBaseUrl;
  // public RevenueBaseUri = environment.revenueserviceBaseUrl;
  // public DemoApiBaseUri = environment.demoapiBaseUrl;

  public spoofUser: string; // public spoofUser = 'jotap';

  public GetConfiguration: any = {
    uri: 'api/Configuration/GetConfiguration/v1',
    forceRedirect: true,
    params: {
      AppId: null,
      ConfigKey: null
    }
  };

  public GetVersionConfig: any = {
    uri: 'api/Configuration/GetVersionConfig/v1',
    forceRedirect: true,
    params: {
      AppId: null,
      fiscalYear: null
    }
  };

  public AuthorizeAndGetParticipant: any = {
    uri: 'api/Participant/GetParticipationAndAuthorize/v1',
    forceRedirect: true,
    params: {
      participationid: null,
      token: null
    }
  };

  public AuthorizeImpersonation: any = {
    uri: 'api/Participant/AuthorizeParticipantImpersonation/v1',
    forceRedirect: true,
    params: {
      alias: null,
      participationid: null,
      token: null
    }
  };

  public GetAllAlias: any = {
    uri: 'api/Participant/RetreiveAllParticipant/v1'
  };

  public GetTargetSummary: any = {
    uri: 'api/plan/GetEmployeePlanTarget/v1'
  };
  public GetPPAList: any = {
    uri: 'api/DocumentCompliance/GetPPAList/v1'
  };

  public GetPPAReceipientViewUrl: any = {
    uri: 'api/DocumentCompliance/GetPPAReceipientViewUrl/v1',
    forceRedirect: true,
    params: {
      FiscalYear: null,
      envelopeId: null,
      documentSignedStatusID: 0
    }
  };

  public GetYtdTargetSummary: any = {
    uri: 'api/plan/GetEmployeePlanYtdTarget/v1'
  };

  public GetRefreshTimesOfDataSources: any = {
    uri: 'api/Participant/GetRefreshTimesOfDataSources/v1'
  };

  public GetAllPaylines: any = {
    uri: 'api/plan/GetAllPaylineMetrics/v1'
  };

  public GetRubiFiscalYearPaylineMetrics: any = {
    uri: 'api/plan/GetRubiFiscalYearPaylineMetrics/v1'
  };

  public GetLastRefreshedTrainTime: any = {
    uri: 'api/Participant/GetLastRefreshedTrainTime/v1'
  };

  public GetRUBIMonthlyAttainment: any = {
    uri: 'api/plan/GetMonthlyAttainmentReportRUBI/v1'
  };

  public GetPaylineSegments: any = {
    uri: 'api/plan/GetPaylineSegments/v1'
  };

  public GetAllYtdPaylines: any = {
    uri: 'api/plan/GetAllYtdPaylineMetrics/v1'
  };

  public GetPCCInfoForDetailReports: any = {
    uri: 'api/Revenue/GetPCCInformation/v1'
  };

  public GetRUBIPCCMetrics: any = {
    uri: 'api/plan/GetRUBIPCCMetrics/v1'
  };

  public RevenueProductSummary: any = {
    uri: 'api/revenue/GetRevenueByProduct/v1'
  };

  public ExportProductsSummary: any = {
    uri: 'api/revenue/ExportProductRevenue/v1'
  };

  public ExportTerritorySummary: any = {
    uri: 'api/revenue/ExportTerritoryRevenueDetails/v1'
  };

  public RevenueTerritorySummary: any = {
    uri: 'api/revenue/GetRevenueByTerritory/v1'
  };

  public RevenueByAccountSummary: any = {
    uri: 'api/revenue/GetRevenueByAccount/v1'
  };

  public ExportRevenueProductDetail: any = {
    uri: 'api/revenue/ExportProductRevenue/v1'
  };

  public ExportRevenueTerritoryDetail: any = {
    uri: 'api/revenue/ExportTerritoryRevenueDetails/v1'
  };

  public ExportRevenueAccountDetail: any = {
    uri: 'api/revenue/ExportRevenueByAccount/v1'
  };

  public ExportDynamicsAccountRevenue: any = {
    uri: 'api/revenue/ExportDynamicsAccountRevenue/v1',
    params: {
      IncludePivot: false
    }
  };

  public ExportDynamicsAccountTarget: any = {
    uri: 'api/revenue/ExportDynamicsAccountTarget/v1',
    params: {
      IncludePivot: false
    }
  };

  public GetDimNames: any = {
    uri: 'api/revenue/getDimNames/v1'
  };

  public GetAttainmentByPCGDetail: any = {
    uri: 'api/plan/GetAttainmentByPCGDetail/v1'
  };

  public ExportAttainmentByPCGDetail: any = {
    uri: 'api/plan/ExportAttainmentByPCGDetail/v1'
  };

  public GetQuestionsByFeature: any = {
    uri: 'api/Feedback/GetQuestionsByFeature/v1',
    forceRedirect: true,
    params: {
      featureId: 0
    }
  };

  public GetBonusDetails: any = {
    uri: 'api/GetBonusDetails',
    forceRedirect: true,
    params: {
      ParticipationId: 159935,
      FiscalYearId: 2022,
      code: '',
      ActiveVersion: 2
    }
  };

  public GetBonusConfig: any = {
    uri: 'api/GetBonusProgramConfigurations',
    forceRedirect: true,
    params: {
      PersonnelNumber: 0,
      FiscalYearId: 2022,
      ActiveVersion: 1
    }
  };

  public GetBonusDetailsV2: any = {
    uri: 'api/v2/GetBonusDetails',
    forceRedirect: true,
    params: {
      PersonnelNumber: 0,
      FiscalYearId: 2022,
      ActiveVersion: 1,
      Id: '2022_0_1'
    }
  };

  public SaveAnswersForFeature: any = {
    uri: 'api/Feedback/SaveAnswersForFeature/v1',
    forceRedirect: true
  };

  public GetQuotaChangeHistory: any = {
    uri: 'api/DocumentCompliance/GetQuotaChangeHistory/v1',
    forceRedirect: true,
    params: {
      FiscalYear: 2019,
      PersonnelNumber: 726724,
      ParticipationId: 18,
      StepId: 1,
      Alias: 'JOTAP'
    }
  };

  public GetPlanSummaryReport: any = {
    uri: 'api/Plan/GetPlanDetailsReportWithOnlyProducts/v1',
    forceRedirect: true,
    params: {
      PlanID: null,
      StartDate: null,
      EndDate: null,
      FiscalYear: null,
      ParticipationID: null
    }
  };

  public GetPayoutSummaryInfo: any = {
    uri: 'api/GetPayoutSummary'
  }

  public GetPayoutSummary: any = {
    uri: 'api/Participant/GetPayoutSummary/v1'
  };

  public GetAccelerateAzureMetrics: any = {
    uri: 'api/Participant/GetAzureIncentiveDetails/v1'
  };

  public GetRoleInformation: any = {
    uri: 'api/Participant/GetRoleInformation/v1'
  };

  public GetPayIncentiveMixDetails: any = {
    uri: 'api/Participant/GetPayIncentiveMixDetails/v1'
  };

  public GetPCGMetrics: any = {
    uri: 'api/plan/GetPCGMetrics/v1'
  };

  public GetMultiplePayline = {
    uri: 'api/plan/GetMultiplePayline/v1'
  };

  public GetPlanDetails = {
    uri: 'api/Plan/GetPlanDetails/v1'
  };

  public GetRefreshTimesOfNonRevenueDataSources: any = {
    uri: 'api/Participant/GetRefreshTimesOfNonRevenueDataSources/v1'
  };

  public GetProductsAndModifiers: any = {
    uri: 'api/Plan/GetProductsAndModifiers/v1'
  };

  public GetYtdPerformanceByPCC: any = {
    uri: 'api/plan/GetYtdPerformanceByPCC/v1'
  };

  public GetPlanDetailsReportWithModifiers: any = {
    uri: 'api/Plan/GetPlanDetailsReportWithModifiers/v1'
  };
  public GetEarningAttributes: any = {
    uri: 'api/plan/GetEarningAttribute/v1',
    params: {
      PcgIds: null
    }
  };

  public GetMeasureTypeMapping: any = {
    uri: 'api/Plan/GetMeasureTypeMapping/v1',
  };

  public GetPayIncentiveMixDetailsReport: any = {
    uri: 'api/Participant/GetPayIncentiveMixDetailsReport/v1'
  };

  public SearchParticipant: any = {
    uri: 'api/Participant/SearchParticipant'
  };

  public GetDirectsInformation: any = {
    uri: 'api/Participant/GetDirectsInformation/v1'
  };

  public GetDirectsQuotaToGo: any = {
    uri: 'api/DocumentCompliance/GetDirectsQuotaToGo/v1'
  };

  public GetDirectsAzureIncentives: any = {
    uri: 'api/Participant/DirectsAzureIncentives/v1'
  };

  public GetDirectsSoftCap: any = {
    uri: 'api/Participant/GetDirectsSoftCap/v1'
  };

  public UpdateSoftCapDetails: any = {
    uri: 'api/Participant/UpdateSoftCapRemoval/v1'
  };

  public GetDirectsPayoutDetails: any = {
    uri: 'api/Participant/GetDirectsPayoutDetails/v1'
  };

  public GetDirectsQuotaDetails: any = {
    uri: 'api/Participant/GetManagerQuotaReport/v1'
  };

  public GetQuotaDetails: any = {
    uri: 'api/DocumentCompliance/GetQuotaAcknowledgementDetail/v1',
    params: {
      FiscalYear: null
    }
  };

  public AcknowledgeQuotaDocument: any = {
    uri: 'api/DocumentCompliance/AcknowledgeQuota/v1',
    params: {
      fiscalYear: null,
      quotaDetail: null
    }
  };

  public AcknowledgeQuotaDocumentCSA: any = {
    uri: 'api/DocumentCompliance/AcknowledgeQuotaDocument',
    params: {
      fiscalYear: null,
      quotaDetail: null
    }
  };

  public MarkQuotaAsViewed: any = {
    uri: 'api/DocumentCompliance/MarkQuotaAsViewed/v1',
    params: {
      fiscalYear: null
    }
  };

  public GetComplianceStatus: any = {
    uri: 'api/DocumentCompliance/GetComplianceStatus/v1',
    params: {
      FiscalYear: null
    }
  };

  public AddTrainingLinks: any = {
    uri: 'api/DocumentCompliance/AddTrainingLinks/v1',
    params: {
      FiscalYear: null,
      updatedTraining: null
    }
  };

  public GetTrainingLinksForUser: any = {
    uri: 'api/DocumentCompliance/GetTrainingLinksForUser/v1',
    params: {
      alias: null
    }
  };

  public PCCInfo: any = {
    uri: 'api/Revenue/GetPCCInformation/v1',
    params: {
      RequestedUser: null,
      FiscalYear: null,
      PlanId: null,
      SelectedTitleStartDate: null,
      SelectedTitleEndDate: null,
      IncludeSetupAt: false
    }
  };

  public GetRubiPCGMetrics: any = {
    uri: 'api/plan/GetRubiPCGMetrics/v1'
  };

  public GetDemoUsers: any = {
    uri: 'api/Demo/GetDemoUsers/v1',
    forceRedirect: true
  };

  public GetUBIWorkingHours: any = {
    uri: 'api/plan/GetUBIWorkingHours/v1'
  };

  public ExportPPIQuotaAcknowledgementDetail: any = {
    uri: 'api/DocumentCompliance/ExportPPIQuotaAcknowledgementDetail/v1',
    params: {
      FiscalYear: null
    }
  };

  public GetMessages: any = {
    uri: 'api/Message/GetErrorMessage/v1'
  };

  public GetMessageForParticipant: any = {
    uri: 'api/Message/GetMessageForParticipant/v1'
  };

  public GetAllMessagesForParticipant: any = {
    uri: 'api/Message/GetAllMessagesForParticipant/v1'
  };

  public SetOptOut: any = {
    uri: 'api/Participant/SetOptOut/v1'
  };

  public GetUtilizationDetails: any = {
    uri: 'api/plan/GetUtilizationDetails/v1'
  };

  public ExportUtilizationDetails: any = {
    uri: 'api/plan/ExportUtilizationDetails/v1'
  };

  public GetPayoutReportUBI: any = {
    uri: 'api/Participant/GetPayoutDetails/v1'
  };

  public GetAttainmentReportUBI: any = {
    uri: 'api/Participant/GetAttainmentReportUBI/v1'
  };

  public GetCagrPccAttainmentDetails: any = {
    uri: 'api/Cagr/GetCagrPccAttainmentDetails/v1'
  };

  public GetCagrAccounts: any = {
    uri: 'api/Cagr/GetCagrAccounts/v1'
  };

  public GetCagrParticipations: any = {
    uri: 'api/Cagr/GetCagrParticipations/v1'
  };

  public GetCagrProductsServicesData: any = {
    uri: 'api/Cagr/GetCagrProductsServicesData/v1'
  };

  public GetEarningDetail: any = {
    uri: 'api/plan/GetEarningDetail/v1'
  };

  public GetCagrMultipleAccountsData: any = {
    uri: 'api/Cagr/GetCagrMultipleAccountsData/v1'
  };

  public GetCagrAwardsData: any = {
    uri: 'api/Cagr/GetCagrAwardsData/v1'
  };

  public GetPPITenureCaps: any = {
    uri: 'api/plan/GetPPITenureCaps/v1'
  };

  public GetPayoutFlowSummary: any = {
    uri: 'api/Participant/GetPayoutFlowSummary/v1'
  };

  public GetPayoutEarningDetail: any = {
    uri: 'api/plan/GetPayoutEarningDetail/v1'
  };

  public GetITAInformation: any = {
    uri: 'api/Participant/GetITAInformation/v1'
  };

  public GetPPICustomerAddsPayoutAdjustment: any = {
    uri: 'api/Participant/GetPayoutAdjustment/v1'
  };

  public GetUserNotifications: any = {
    uri: 'api/UserNotification/GetNotifications/v1',
    forceRedirect: true
  };

  public GetUserSplash: any = {
    uri: 'api/Help/GetFeatureIntroductionItems/v1',
    forceRedirect: true
  };

  public GetAllFeatureGroups: any = {
    uri: 'api/Help/GetAllFeatureIntroductionItems/v1',
    forceRedirect: true
  };
  public GetAdminNotificationFilterData: any = {
    uri: 'api/Admin/GetAdminNotificationFilterData/v1',
    forceRedirect: true
  };

  public GetAdminNotifications: any = {
    uri: 'api/UserNotification/GetAllNotificationsForAdmin/v1',
    forceRedirect: true
  };

  public SetAdminNotifications: any = {
    uri: 'api/UserNotification/SetUserNotification/v1',
    forceRedirect: true
  };

  public AddorUpdateFeatureGroups: any = {
    uri: 'api/Help/AddFeatureGroupItems/v1',
    forceRedirect: true
  };

  public AddorUpdateFeatureIntroductions: any = {
    uri: 'api/Help/AddFeatureIntroductionItems/v1',
    forceRedirect: true
  };

  public DeleteFeatureIntroductions: any = {
    uri: 'api/Help/DeleteFeatureIntroductionItems/v1',
    forceRedirect: true
  };

  public DeleteFeatureGroup: any = {
    uri: 'api/Help/DeleteFeatureGroupItem/v1',
    forceRedirect: true
  };

  public GetExportPayoutDetails: any = {
    uri: 'api/Participant/ExportPayoutDetails/v1'
  };

  public GetAttainmentReportPPI: any = {
    uri: 'api/Participant/GetAttainmentReportPPI/v1'
  };

  public GetDirectsTerritoryDetails: any = {
    uri: 'api/Participant/GetDirectsTerritoryDetails/v1'
  };

  public ExportMonthlyAttainmentReportRUBI: any = {
    uri: 'api/Plan/ExportMonthlyAttainmentReportRUBI/v1'
  };

  public GetMic2Reports: any = {
    uri: 'api/Mic2/GetReports/v1',
    forceRedirect: true
  };

  public DownloadMic2Report: any = {
    uri: 'api/Mic2/DownloadReport/v1',
    forceRedirect: true,
    params: {
      ReportType: null
    }
  };

  public SetDisclaimerAcceptance: any = {
    forceRedirect: true,
    uri: 'api/Home/SetDisclaimerAcceptance/v1',
    params: {
      disclaimerId: null
    }
  };

  public GetEmbeddedVideoLinks: any = {
    forceRedirect: true,
    uri: 'api/Help/GetEmbeddedVideoLinks/v1'
  };
  public UpdateVideoViewCount: any = {
    forceRedirect: true,
    uri: 'api/Help/UpdateVideoViewCount/v1'
  };
  public GetBucketLevelEstimation: any = {
    forceRedirect: true,
    uri: 'api/Estimator/GetBucketLevelEstimation/v1'
  };
  public GetBucketLevelEstimationUBI: any = {
    forceRedirect: true,
    uri: 'api/Estimator/GetBucketLevelEstimationUBI/v1'
  };
  public GetTimelineView: any = {
    forceRedirect: true,
    uri: 'api/QuotaTraceability/GetQuotaTimelineView/v1'
  };
  public GetDetailedView: any = {
    forceRedirect: true,
    uri: 'api/QuotaTraceability/GetQuotaDetailView/v1'
  };
  public UploadFeatureImage: any = {
    forceRedirect: true,
    uri: 'api/Help/UploadFeatureImage/v1'
  };

  public GetPlans: any = {
    uri: 'api/Admin/GetPlans/v1',
    forceRedirect: true,
    params: {
      currentFiscalYear: null
    }
  };

  public UpdatePlans: any = {
    uri: 'api/Admin/UpdatePlans/v1',
    forceRedirect: true
  };

  public GetHelpDetails: any = {
    uri: 'api/Help/GetHelpDetails/v1',
    forceRedirect: true,
    params: {
      incentiveType: null
    }
  };

  public GetFeatureIntroductionItems: any = {
    forceRedirect: true,
    uri: 'api/Help/GetFeatureIntroductionItems/v1'
  };

  public AddHelpContent: any = {
    forceRedirect: true,
    uri: 'api/Help/AddHelpContent/v1',
    params: {
      contents: null
    }
  };
  public DeleteHelpContent: any = {
    forceRedirect: true,
    uri: 'api/Help/DeleteHelpContent/v1',
    params: {
      id: null
    }
  };

  /**
   * New PayoutDetails
   */
  public PayoutDetails: any = {
    forceRedirect: true,
    uri: 'api/GetPayoutDetails'
  };

  public ExportToExcel: any = {
    forceRedirect: true,
    uri: 'api/ExportToExcel',
    params: {
      PersonnelNumber: null,
      // FiscalYear: null,
      // FiscalQuarterId : null,
      PaymentApprovalID: null
    }
  };

  /**
   * Old PayoutDetails and Excel Export FY20 and FY21 
   */

  public OldPayoutDetails: any = {
    uri: 'api/Participant/GetPayoutDetails/v1'
  };

  public OldGetQRPCGLevelDetails: any = {
    uri: 'api/Participant/GetQRPCGLevelDetails/v1'
  };

  public OldExportToExcelPayoutDetails: any = {
    uri: 'api/Participant/GetExcelPayoutDetails/v1'
  };

  public GetExporttoexcelDetails: any = {
    uri: 'api/Home/ExportToExcel/v1',
    params: {
      HTMLTable: null,
      FileName: null,
      Pcg_Pcc_List: null
    }
  };

  public GetCagrTotalCloudGrowthId: any = {
    uri: 'api/Cagr/GetCagrTotalCloudGrowthId/v1'
  };

  public GetParticipantSettings: any = {
    uri: 'api/Participant/GetParticipantSettings/v1',
    forceRedirect: true,
    params: {
      featureRole: null
    }
  }

  public GetOptOutDetails: any = {
    uri: 'api/Participant/GetOptOutDetails/v1',
    forceRedirect: true
  };

  public GetParticipantDetails: any = {
    uri: 'api/Participant/GetParticipantDetails/v1',
    forceRedirect: true
  };

  public AuthorizeParticipant: any = {
    uri: 'api/Participant/AuthorizeParticipant/v1',
    forceRedirect: true,
    params: {
      token: null
    }
  };

  public RetreiveAllParticipants: any = {
    uri: 'api/Participant/RetreiveAllParticipants',
    forceRedirect: true
  };
}
