import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mint-content-loader-card-chart',
  templateUrl: './content-loader-card-chart.component.html'
})
export class ContentLoaderCardChartComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
