import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mint-common-icon',
  templateUrl: './common-icon.component.html',
  styleUrls: ['./common-icon.component.scss']
})
export class CommonIconComponent implements OnInit {
  @Input()
  width = '0';  
  @Input()
  height = '0'; 
  @Input()
  viewBox = '0 0 0 0'; 
  @Input()
  fill = 'none';
  @Input()
  type: 'unlocked-lg' | 'locked-lg' | 'info' | 'target';

  constructor() { }

  ngOnInit(): void {
  }

}
