import { CustomHeaderComponent } from './../../../shared/grid-components/custom-header/custom-header.component';
import { PayoutSummaryHelperService } from './payout-summary-helper.service';
import { PayoutSummaryService } from './payout-summary.service';
import { Component, OnInit, HostListener, Inject, ElementRef, AfterViewChecked } from '@angular/core';
import * as Ng1Services from '../../../core/hybrid/ng1-upgraded-providers';
import * as _ from 'underscore';
import { DatePipe } from '@angular/common';
import { Formatter, UpdateSharedContext } from '@mint-libs/common';
import { SharedService } from '../../../shared/shared.service';
import { PayoutReportLinkRenderer_Old } from './payout-report-link-renderer.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserProfileService } from '../../../core/context/user-profile.service';
import { MessagesService } from '@mint-libs/context';
import { Code } from '../../../app.constants';
import { FiltersService } from '../../../filters/filters.service';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-payout-summary-old',
  templateUrl: './payout-summary.component.html',
  styleUrls: ['./payout-summary.component.scss'],
  providers: [PayoutSummaryService, PayoutSummaryHelperService, Code]
})
export class PayoutSummaryComponent_Old implements OnInit, AfterViewChecked {
  filterService: any;
  planStartMonth: any;
  planEndMonth: any;
  planDuration: any;
  isOptoutEligibleAndInWindow = false;
  optOutMessage: Observable<any>;
  optOutMessageBanner1: string;
  optOutMessageBanner2: string;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private payoutsummaryService: PayoutSummaryService,
    private payoutsummaryhelperService: PayoutSummaryHelperService,
    private datePipe: DatePipe,
    private formatter: Formatter,
    private sharedService: SharedService,
    filterService: FiltersService,
    public sharedContextState: Store<SharedContext>,
    private messageService: MessagesService,
    private navigationService: NavigationService,
    private router: Router,
    public userProfileService: UserProfileService,
    private element: ElementRef
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.domLayout = 'autoHeight';
    this.filterService = filterService;
  }

  gridApi;
  gridColumnApi;
  columnDefs: any;
  context;
  gridOptions: any;
  rowData: any;
  groupDefaultExpanded;
  getDataPath;
  autoGroupColumnDef;
  frameworkComponents: any;
  domLayout: any;

  payoutSummaryDataset = null;
  final = null;
  helper = {
    prev: null,
    index: 0,
    codePeriod: '',
    adjustment: false,
    earningStartDate: null
  };

  halfYearlyQuotaNotSupportedPartitions = ['Q3', 'Q4', 'H2'];

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (!this.gridOptions.gridApi) {
      return;
    }

    setTimeout(() => {
      this.gridOptions.gridApi.sizeColumnsToFit();
    });
  }
  ngAfterViewChecked(): void {
    // add table caption to ag-grid accessibility fix
    const agGrid = this.element.nativeElement.querySelector('ag-grid-angular div[role="grid"]');
    if (agGrid) {
      agGrid.setAttribute('aria-label', 'My Payout Details');
    }
  }
  ngOnInit() {
    this.getDataPath = function(data) {
      return data.CodePeriod;
    };

    // Plan duration needs to be identified to identify if H1/H2 needs to be shown instead of Q1/Q3
    // Todo: Shakti GUP- we can get this from User Profile as Plan related information should come from UsrPrfl insteal of Filter

    this.planDuration = this.sharedContext.DefaultFilter.StandardTitle.PlanDuration;

    this.groupDefaultExpanded = -1;
    this.gridOptions = {
      suppressRowTransform: true,
      columnDefs: this.columnDefs,
      suppressCellSelection: true,
      rowData: this.rowData,
      onGridReady: this.onGridReady,
      rowHeight: 67,
      headerHeight: 52,
      ensureDomOrder: true,
      suppressColumnVirtualisation: true
    };

    this.columnDefs = this.payoutsummaryhelperService.getColumnDefs();
    this.context = { componentParent: this };
    this.frameworkComponents = {
      agColumnHeader: CustomHeaderComponent,
      payoutReportLinkRenderer_Old: PayoutReportLinkRenderer_Old
    };

    this.payoutsummaryService.getPayoutSummaryData(true).subscribe((result: any) => {
      let payoutSummaryResult = result.ApplicablePayouts;
      if (payoutSummaryResult) {
        // Remove H2 timeframes if H2 Quota is unavailable
        if (this.filterService.isH2QuotaSupported(this.filterService.selectedFiscalYear)) {
          payoutSummaryResult = payoutSummaryResult.filter(function(val) {
            return this.halfYearlyQuotaNotSupportedPartitions.indexOf(val.CodePeriod.split('-')[1]) === -1 ? true : false;
          }, this);
        }
        this.rowData = this.setPayoutData(payoutSummaryResult);
        if (this.gridOptions && this.gridOptions.gridApi) {
          this.gridOptions.gridApi.sizeColumnsToFit();
        }
      }
    });

    this.checkOptEligiblity();
  }

  checkOptEligiblity() {
    // Checks the OptOut eligiblity and whether the Optout window is open currently
    // Also ensures that optOut start and end date are present
    if (this.userProfileService.isOptoutEligibleAndInWindow() && this.sharedContext.optoutDetails.optOutStartDate && this.sharedContext.optoutDetails.optOutEndDate) {
      this.isOptoutEligibleAndInWindow = true;
    }

    if (this.isOptoutEligibleAndInWindow) {
      this.messageService.get(Code.PayoutTileOptOutMessages).subscribe(response => {
        this.optOutMessageBanner1 = JSON.parse(<any>response)['BannerMessage1'];
        this.optOutMessageBanner2 = JSON.parse(<any>response)['BannerMessage2'];
      });
    }
  }

  navigateToOptout() {
    this.navigationService.navigateToOptout();
    this.router.navigate(['complianceDashboard'], {
      queryParams: { id: 3 }
    });
  }

  navigateToDetailPayout(data) {
    let sharedContext = {
      ...this.sharedContext,
      appliedFilter: JSON.parse(JSON.stringify(this.sharedContext.appliedFilter)),
      FiscalYears: this.sharedContext.FiscalYears ? [...this.sharedContext.FiscalYears].sort() : null,
      ManagerViewFiscalYears: this.sharedContext.ManagerViewFiscalYears ? [...this.sharedContext.ManagerViewFiscalYears].sort() : null
    };

    if (this.userProfileService.isRBIPersona() || this.userProfileService.isUBIPersona() || this.userProfileService.isPPIPersona()) {
      sharedContext.appliedFilter.fiscalYear = this.filterService.selectedFiscalYear;

      sharedContext.appliedFilter.standardTitle = this.filterService.selectedStandardTitle;
      sharedContext.appliedFilter.stepId = data.stepId;
      sharedContext.isNavigationFromPayoutSummary = true;

      // This so that if user has multiple payouts in a same quarter, then correspoint payout report table will be autoselected
      sharedContext.currentTabIndex = data.detailViewTabIndex;

      //this.sharedContext.PublicationId = data.publicationId;

      this.filterService.previousValues = {};
      this.filterService.previousValues.selectedFiscalYear = this.filterService.selectedFiscalYear;
      this.filterService.previousValues.selectedStandardTitle = this.filterService.selectedStandardTitle;
      this.filterService.previousValues.selectedStepId = this.filterService.selectedStepId;

      this.filterService.selectedFiscalYear = this.filterService.selectedFiscalYear;

      this.filterService.selectedStepId = data.stepId;

      if (this.filterService.selectedStandardTitle.StartDate !== data.earningStartDate) {
        // If the user clicked Payout or Project earnings link that does not below to currenlly selected Participation,
        // than select the corresponding participation and then navigate user to Payout Detail report
        _.each(
          this.sharedContext.StandardTitles,
          function(StandardTitlesItem) {
            if (this.filterService.selectedFiscalYear === StandardTitlesItem.FiscalYear.Key && StandardTitlesItem.StartDate === data.earningStartDate) {
              this.filterService.selectedStandardTitle = StandardTitlesItem;
            }
          },
          this
        );
      }
      this.sharedContextState.dispatch(new UpdateSharedContext(sharedContext));

      if (this.userProfileService.isRBIPersona()) {
        this.sharedService.ngViewReport(1, 1);
      } else {
        this.sharedService.ngViewReport(0, 0);
      }
    } else {
      this.sharedService.ngViewReport(1, 1, data.paymentApprovalId);
    }
  }

  setPayoutData(payoutSummaryResult) {
    return (this.payoutSummaryDataset = _.map(
      payoutSummaryResult,
      function(payoutSummaryItem) {
        // detailViewTableIndex used to indicate the correct payout report incase of multiple payment in same quarter
        let detailViewTabIndex = 0;

        // Plan Duration ==5 indicates whats H1/H2 to be show
        if (this.planDuration === 5) {
          payoutSummaryItem.CodePeriod = payoutSummaryItem.CodePeriod.replace('Q2', 'H1');
          payoutSummaryItem.CodePeriod = payoutSummaryItem.CodePeriod.replace('Q4', 'H2');
        }

        if (this.helper.prev === payoutSummaryItem.CodePeriod && this.helper.earningStartDate === payoutSummaryItem.EarningStartDate) {
          this.helper.adjustment = true;
          this.helper.codePeriod = payoutSummaryItem.CodePeriod;
          detailViewTabIndex++;
          // payoutSummaryItem.CodePeriod + ' Adjustment Payment';
        } else {
          this.helper.codePeriod = payoutSummaryItem.CodePeriod;
          this.helper.prev = payoutSummaryItem.CodePeriod;
          this.helper.adjustment = false;
          this.helper.earningStartDate = payoutSummaryItem.EarningStartDate;
          detailViewTabIndex = 0;
        }

        let payrollPaymentAmount = payoutSummaryItem.PayrollPayment;
        let payrollPaymentCode = payoutSummaryItem.CurrencyCode;
        if (payoutSummaryItem.IsCountryChange) {
          payrollPaymentAmount = payoutSummaryItem.TransferredCurrencyAmount;
          payrollPaymentCode = payoutSummaryItem.TransferredCurrencyCode;
        }

        const yTDEarnings = this.formatter.formatWithCurrencyAndRoundOff(payoutSummaryItem.YTDEarnings, payoutSummaryItem.CurrencyCode);
        const prevQuarterPayout = this.formatter.formatWithCurrencyAndRoundOff(payoutSummaryItem.Advances, payoutSummaryItem.CurrencyCode);
        let covidAdjustmentAmount = 0,
          bonusAdjustmentAmount = 0;
        _.each(payoutSummaryItem.COVIDAdjustments, function(COVIDAdjustment) {
          covidAdjustmentAmount += COVIDAdjustment.PayrollAdjustment;
        });
        _.each(payoutSummaryItem.BonusAdjustments, function(BonusAdjustment) {
          bonusAdjustmentAmount += BonusAdjustment.PayrollAdjustment;
        });
        const ytdPaymentAdjustmentAndRecoveries = this.formatter.formatWithCurrencyAndRoundOff(
          payoutSummaryItem.Adjustments + payoutSummaryItem.RecoveryAmount + covidAdjustmentAmount + bonusAdjustmentAmount,
          payoutSummaryItem.CurrencyCode
        );
        let netPayment: any;
        let publicationDate: any;
        let payoutDone: any;
        let stepId: any;

        if (payoutSummaryItem.Partition && payoutSummaryItem.Partition.StepId) {
          stepId = payoutSummaryItem.Partition.StepId;
        } else {
          // Fallback machanism in case the stepid is NOT received from API,
          // This logic is borrowed from Old Dashboard (Angular JS)
          stepId = this.getStep(this.helper.codePeriod);
        }

        if (payoutSummaryItem.PaymentStatus === 'Published') {
          netPayment = this.formatter.formatWithCurrencyAndRoundOff(payrollPaymentAmount, payrollPaymentCode);
          if (payoutSummaryItem.PublicationDate) {
            publicationDate = this.datePipe.transform(payoutSummaryItem.PublicationDate, 'MMM d, yyyy').toLocaleString();
          }

          payoutDone = true;
        } else {
          netPayment = this.formatter.formatWithCurrencyAndRoundOff(payoutSummaryItem.NetPaymentAmount, payoutSummaryItem.CurrencyCode) + ' (Projected)';

          if (payoutSummaryItem.PaymentStatus === 'Paid In Next Quarter') {
            // This condition indicates that the Partipant for this Quarter is account in next Quarter
            // There could be instances where we do not pay a seller in Q1 but pay him/her in Q2.
            // The pending payment from Q1 is accounted for in Q2.
            publicationDate = 'Not Eligible';
          } else {
            // This condition indicates that payment is not done for this Quarter as well as any of the future Quarter
            publicationDate = 'Pending payment issuance';
          }

          payoutDone = false;
        }
        return {
          codePeriod: this.helper.codePeriod,
          adjustment: this.helper.adjustment,
          yTDEarnings: yTDEarnings,
          prevQuarterPayout: prevQuarterPayout,
          ytdPaymentAdjustmentAndRecoveries: ytdPaymentAdjustmentAndRecoveries,
          netPaymentAmount: netPayment,
          calcDate: publicationDate,
          payoutDone: payoutDone,
          publicationId: payoutSummaryItem.PublicationId,
          paymentApprovalId: payoutSummaryItem.PaymentApprovalID,
          stepId: stepId,
          detailViewTabIndex: detailViewTabIndex,
          earningStartDate: payoutSummaryItem.EarningStartDate
        };
      }.bind(this)
    ));
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      params.api.sizeColumnsToFit();
    }, 3000);
  }
}
