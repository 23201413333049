import { UserProfileActions, UserProfileActionTypes } from './user-profile.actions';
import { UserProfile } from '../models/user-profile.model';
import { plainToClass } from 'class-transformer';

export const userProfileFeatureKey = 'userProfile';

export class UserProfileState extends UserProfile {}

export const initialState: UserProfileState = null;

export function userProfileReducer(state = initialState, action: UserProfileActions): UserProfileState {
  switch (action.type) {
    case UserProfileActionTypes.LoadUserProfile:
      return state;

    case UserProfileActionTypes.LoadUserProfileSuccess:
      const userProfile = new UserProfile().deserialize(action.payload.data);
      return userProfile;

    case UserProfileActionTypes.LoadUserProfileFailure:
      console.log('Error: ' + action.payload.error);
      return state;

    case UserProfileActionTypes.ResetUserProfile:
      state = null;
      return state;

    default:
      return state;
  }
}
