import { Component, OnInit, Input, OnChanges, Inject } from '@angular/core';
import { DetailSummaryTilesService } from '../detail-summary-tiles.service';
import * as _ from 'underscore';
import { Formatter } from '@mint-libs/common';
import { DetailDropdownSelectedItem } from 'src/app/shared/detail-dropdown/models/detail-dropdown-selected-item';
import { ExportToExcelService } from '../../../shared/excel-export/export-to-excel.service';

@Component({
  selector: 'mint-territory-summary',
  templateUrl: './territory-summary.component.html',
  styleUrls: ['./territory-summary.component.scss']
})
export class TerritorySummaryComponent implements OnInit {
  @Input() loadSummaryReportHeading = false;
  @Input() loadDataForAcr: boolean = null;
  @Input() detailReportParams: any;
  @Input() containerId: string;
  constructor(private detailSummaryTilesService: DetailSummaryTilesService, private formatter: Formatter, private exportToExcelServiceNew: ExportToExcelService) {  }

  itemCaption = 'Metric';
  subItemCaption = 'Available Territory Type';
  dropDownItems: any = [];
  selectedItem;
  selectedSubItem;
  previousSelectedItem;
  previousSelectedSubItem;
  reportType = 'Territory';
  noData = false;
  errorOccurred = false;
  lastRefreshDate: Date = null;
  territoriesResult: any = null;
  territoriesDataset: any;
  loaded = false;
  chartHelpText = 'Lists all territories in your plan by revenue.';
  incentiveType = null;

  // POD data display variables
  showRBQLegend = false;
  podQuotaDisplayValue: string = null;

  isADXResponsse = false;
  showExportLoader;

  ngOnInit() {
    this.detailSummaryTilesService.getPCCInfoForDetailReports(this.reportType, this.loadDataForAcr).subscribe(resultPCC1 => {
      // Ignore Utilization for summary detail Report
      this.dropDownItems = resultPCC1
        .filter(pcc => {
          return !pcc?.IsDynamicMetric && !(typeof pcc.Name === 'string' && pcc.Name.startsWith('Utilization'));
        })
        .map(pccItem => {
          return {
            text: pccItem.Name,
            subItems: pccItem.ApplicableTypeDimensions
          };
        });
      // ToDo: These values need to be set as part of user preferences
      // below two properties will be returned as part of user preferences stored in PE DB
      if (this.dropDownItems && this.dropDownItems.length > 0) {
        this.previousSelectedItem = this.selectedItem = this.dropDownItems[0].text;
        if (this.dropDownItems[0].subItems && this.dropDownItems[0].subItems.length > 0) {
          this.previousSelectedSubItem = this.selectedSubItem = this.dropDownItems[0].subItems[0];
        }
      }
      this.getRevenuebyTerritorySummary({ selectedPCC: this.selectedItem, selectedDimension: this.selectedSubItem });
    });
  }

  onApplyFilter(selectedItem: DetailDropdownSelectedItem) {
    this.loaded = false;
    if (this.previousSelectedItem !== selectedItem.item) {
      this.getRevenuebyTerritorySummary({ selectedPCC: selectedItem.item, selectedDimension: selectedItem.subItem });
    } else if (this.previousSelectedSubItem !== selectedItem.subItem) {
      this.setTerritoriesData(selectedItem.item, selectedItem.subItem);
    }

    this.previousSelectedItem = selectedItem.item;
    this.previousSelectedSubItem = selectedItem.subItem;
  }

  getRevenuebyTerritorySummary(filterDetail: any) {
    this.showRBQLegend = false;
    const selectedPCC = filterDetail.selectedPCC;
    const selectedDimension = filterDetail.selectedDimension;

    this.detailSummaryTilesService.getRevenueByTerritorySummary(selectedPCC, this.detailReportParams, this.loadDataForAcr).subscribe(result => {
      this.territoriesResult = result;
      if (this.territoriesResult && this.territoriesResult.data.length && this.territoriesResult.data.length > 0) {
        this.noData = false;
        this.errorOccurred = false;
        this.isADXResponsse = this.territoriesResult.ISADX;
        this.incentiveType = this.territoriesResult.IncentiveType;
        this.lastRefreshDate = this.territoriesResult.DataGenerationTime ? this.formatter.transformToLocalDateTz(this.territoriesResult.DataGenerationTime, 'MMM D, YYYY, h:MM A ', true) : null;
        this.setTerritoriesData(selectedPCC, selectedDimension);
        // TODO: Implement the PIT type PCC case
        // Update: For summary tiles, PIT type PCC case is handled in the detail level SP itself, no changes from FY19 onwards in service/app
      } else {
        this.noData = true;
      }
      this.loaded = true;
    });
  }

  setTerritoriesData(selectedPCC, selectedDimension) {
    this.showRBQLegend = false;
    if (selectedPCC === this.territoriesResult.data[0].PCCName) {
      const filteredData = _.where(this.territoriesResult.data, { Type: selectedDimension });

      if (this.territoriesResult.PODQuotaPerPCC && this.territoriesResult.PODQuotaPerPCC.length > 0) {
        const podDetails = this.territoriesResult.PODQuotaPerPCC.find(pcc => pcc.PCCName === selectedPCC);
        if (podDetails) {
          this.showRBQLegend = true;
          this.podQuotaDisplayValue = this.formatter.formatWithCurrencyAndRoundOff(podDetails.TargetAmount, podDetails.Metric);
        }
      }

      let dataset: any = [];
      const groupByName = _.groupBy(filteredData, function(item) {
        return item.TerritoryName;
      });

      let tempTerritoriesDatasetActuals: any = [];
      let tempTerritoriesDatasetQuota: any = [];
      _.toArray(groupByName).forEach(groupedItem => {
        const label: string = groupedItem[0].TerritoryName;
        const territoryType: string = groupedItem[0].Type;
        let actualsValue = 0;
        let totalActualsValue = 0;
        let adjustmentValue = 0;
        let modifierValue = 0;
        let cAddsValue = 0;
        let quotaValue = 0;
        const isPOD: boolean = groupedItem[0].IsPODBasedQuota;
        const metric: string = groupedItem[0].Revenue.Metric !== null ? groupedItem[0].Revenue.Metric : null;

        // ToDo: set customer adds values also
        // Update: Until further changes or unless notified, showing Cust Adds values in Inc Terr tile is not possible
        _.each(groupedItem, function(item) {
          actualsValue += item.Revenue.ActualAmount;
          adjustmentValue += item.Revenue.ActualAdjustmentAmount;
          modifierValue += item.Revenue.ActualModifier;
          totalActualsValue += item.Revenue.TotalActual;
          cAddsValue = 0;
          quotaValue += item.Revenue.TotalTarget;
        });

        let toolTipData: any = {
          target: quotaValue,
          revenue: actualsValue,
          revenueAdjustment: adjustmentValue,
          revenueModifier: modifierValue,
          totalRevenue: totalActualsValue,
          cAddsRevenue: cAddsValue,
          showRevenueBreakup: true,
          showText: isPOD,
          textVal: 'Role-Based Quota',
          metric: metric
        };

        // Do not consider revenue by modifiers, multipliers and adjustments for Accelerate Azure program
        if (this.loadDataForAcr) {
          // Replacing Total Actuals with only Base Actuals
          totalActualsValue = actualsValue;
          toolTipData = {
            target: quotaValue,
            revenue: actualsValue,
            showRevenueBreakup: false,
            showText: isPOD,
            textVal: 'Role-Based Quota',
            metric: metric
          };
        }

        // ToDo: return customer ads revenue also as part of this territories dataset
        // Update: Until further changes or unless notified, showing Cust Adds values in Inc Terr tile is not possible
        tempTerritoriesDatasetActuals = tempTerritoriesDatasetActuals.concat({
          type: territoryType,
          desc: label,
          columnType: 'actuals',
          val: totalActualsValue,
          caddsval: cAddsValue,
          qval: 0,
          toolTip: toolTipData,
          showText: isPOD,
          textVal: 'RBQ',
          metric: metric
        });
        tempTerritoriesDatasetQuota = tempTerritoriesDatasetQuota.concat({
          type: territoryType,
          desc: label,
          columnType: 'quota',
          val: 0,
          caddsval: 0,
          qval: quotaValue,
          toolTip: toolTipData,
          showText: isPOD,
          textVal: 'RBQ',
          metric: metric
        });
      });
      dataset = dataset.concat(tempTerritoriesDatasetActuals);
      dataset = dataset.concat(tempTerritoriesDatasetQuota);

      this.territoriesDataset = dataset;
      this.territoriesDataset = _.sortBy(this.territoriesDataset, p => p.val).reverse();
    }
    this.loaded = true;
  }

  export(fileName: string) {
    this.showExportLoader = true;
    this.detailSummaryTilesService.exportRevenueByTerritory().subscribe(result => {
      this.exportToExcelServiceNew.getFileName(fileName);
      this.exportToExcelServiceNew.downloadOpenFile(result, fileName);
      this.showExportLoader = false;
    });
  }
}
