import { Expose, deserializeArray, plainToClass, Type } from 'class-transformer';
import { BaseModel } from '../../../shared/models/base.model';

export class Revenue extends BaseModel {

    @Expose({name: 'Month'})
    month: string;
    
    @Expose({name: 'TargetAmount'})
    targetAmount: number;

  @Expose({ name: 'TargetModifier' })
  targetModifier: number;

  @Expose({ name: 'TargetAdjustmentAmount' })
  targetAdjustmentAmount: number;

  @Expose({ name: 'TotalTarget' })
  totalTarget: number;

  @Expose({ name: 'TargetRecurringAmount' })
  targetRecurringAmount: number;

  @Expose({ name: 'TargetRecurringModifier' })
  targetRecurringModifier: number;

  @Expose({ name: 'ActualModifier' })
  actualModifier: number;

  @Expose({ name: 'TagActualModifier' })
  customerAddsModifier: number;

  @Expose({ name: 'TargetRecurringAdjustmentAmount' })
  targetRecurringAdjustmentAmount: number;

  @Expose({ name: 'TotalRecurringTarget' })
  totalRecurringTarget: number;

  @Expose({ name: 'TotalNonRecurringTarget' })
  totalNonRecurringTarget: number;

  @Expose({ name: 'ActualAmount' })
  actualAmount: number;

  @Expose({ name: 'ActiveUsageAmount' })
  activeUsageAmount: number;

  @Expose({ name: 'ActualAdjustmentAmount' })
  actualAdjustmentAmount: number;

  @Expose({ name: 'OtherModifier' })
  otherModifier: number;

  @Expose({ name: 'ActualLicenses' })
  actualLicenses: number;

  @Expose({ name: 'ActualLicensesAdjustment' })
  actualLicensesAdjustment: number;

  @Expose({ name: 'TotalActual' })
  totalActual: number;

  @Expose({ name: 'RNYB' })
  RNYB: number;

  @Expose({ name: 'QuotaToGo' })
  quotaToGo: number;

  @Expose({ name: 'CommitmentLanded' })
  commitmentLanded: number;

  @Expose({ name: 'YTDQuota' })
  ytdQuota: number;

  @Expose({ name: 'FiscalYear' })
  fiscalYear: string;

  @Expose({ name: 'Metric' })
  metric: string;

    deserializeSingle(from: any): Revenue {
    const revenue = new Revenue();//plainToClass<Revenue, object>(Revenue, from, this.classTransformOptions);
    revenue.month = from.Month;
    revenue.targetAmount = from.TargetAmount
    revenue.targetModifier = from.TargetModifier;
    revenue.targetAdjustmentAmount = from.TargetAdjustmentAmount;
    revenue.totalTarget = from.TotalTarget;
    revenue.targetRecurringAmount = from.TargetRecurringAmount;
    revenue.targetRecurringModifier = from.TargetRecurringModifier;
    revenue.actualModifier = from.ActualModifier;
    revenue.customerAddsModifier = from.TagActualModifier;
    revenue.targetRecurringAdjustmentAmount = from.TargetRecurringAdjustmentAmount;
    revenue.totalRecurringTarget = from.TotalRecurringTarget;
    revenue.totalNonRecurringTarget = from.TotalNonRecurringTarget;
    revenue.actualAmount = from.ActualAmount;
    revenue.activeUsageAmount = from.ActiveUsageAmount;
    revenue.actualAdjustmentAmount = from.ActualAdjustmentAmount;
    revenue.otherModifier = from.OtherModifier;
    revenue.actualLicenses = from.ActualLicenses;
    revenue.actualLicensesAdjustment = from.ActualLicensesAdjustment;
    revenue.totalActual = from.TotalActual;
    revenue.RNYB = from.RNYB;
    revenue.quotaToGo = from.QuotaToGo;
    revenue.commitmentLanded = from.CommitmentLanded;
    revenue.ytdQuota = from.YTDQuota;
    revenue.fiscalYear = from.FiscalYear;
    revenue.metric = from.Metric;

    return revenue;
  }
}
