<div id="toggleSwitch1" class="toggle__btn__container">
  <div id="toggleSwitch2" class="toggle__btn__title">{{ toggleSwitchConfig.title }}</div>
  <div role="button" id="toggleSwitch3_btn" class="toggle__btn" (keyup.enter)="navigateToTarget()" (keyup.space)="navigateToTarget()" [attr.aria-label]="toggleSwitchConfig.helpText" tabindex="0">
    <input
      style="display: none;"
      class="toggle__btn__inp"
      type="checkbox"
      name="toggleSwitch__{{ toggleSwitchConfig.id }}"
      id="toggleSwitch__{{ toggleSwitchConfig.id }}"
      analytics-tracker
      [tracker]="toggleSwitchConfig.tracker"
      [type]="'Click'"
      [element]="'toggle-switch-button'"
      [source]="toggleSwitchConfig.source"
      [checked]="isChecked"
      (click)="navigateToTarget()"
    />
    <label id="toggleSwitch5" class="toggle__btn__lbl" [ngClass]="toggleSwitchConfig.toggleCssClass" for="toggleSwitch__{{ toggleSwitchConfig.id }}"></label>
  </div>
  <div id="toggleSwitch3_title" class="toggle__btn toggle__btn__title" *ngIf="titleRight">{{ titleRight }}</div>
</div>
