import * as Ng1Services from 'src/app/core/hybrid/ng1-upgraded-providers';
import { ContextualVideoService } from './contextual-video.service';
import { Component, TemplateRef, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { ImpersonationService } from 'src/app/core/impersonation/impersonation.service';

@Component({
  selector: 'mint-contextual-video',
  templateUrl: './contextual-video.component.html',
  styleUrls: ['./contextual-video.component.scss']
})
export class ContextualVideoComponent implements OnInit {
  @Input() featureId;
  videoData: any;
  openFeedback: boolean;
  SelectedVideo: any;
  constructor(private modalService: NgbModal, private contextualVideoService: ContextualVideoService, private impersonationService: ImpersonationService) { }

  modalRef;
  @ViewChild('contextualVideoTemplate') public templateref: TemplateRef<any>;
  ngOnInit() {
    this.contextualVideoService.getEmbeddedVideoLinks().subscribe(results => {
      if (results.length) {
        this.SelectedVideo = results.filter(x => x.FeatureID == this.featureId);
        if (this.SelectedVideo.length) {
          this.videoData = this.SelectedVideo[0];
          this.videoData.videoHtml = environment.showVideoInPopup ?
            `<div style='max-width: 1280px'><div style='position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;'><iframe width='1280' height='720' src='${this.SelectedVideo[0].VideoUrl}' allowfullscreen style='border:none; position: absolute; top: 0; left: 0; right: 0; bottom: 0; height: 100%; max-width: 100%;'></iframe></div></div>` :
            `<div style='max-width: 1280px;min-height: 100px;padding: 15px!important;'><a href='${this.SelectedVideo[0].VideoUrl}' target='_blank'>${this.SelectedVideo[0].VideoUrl}</a><br/> ${environment.corpUrlMessage.toString()}</div>`
        }
      }
    });
  }
  onButtonClick() {
    this.openFeedback = false;
    this.openModal(this.templateref);
  }
  openModal(contextualVideoTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.open(contextualVideoTemplate, {
      ariaLabelledBy: 'modal-basic-title',
      backdropClass: 'mint-modal-backdrop',
      windowClass: 'mint-modal-window modal-centred',
      size: 'lg'
    });
  }

  closeModal(event: any) {
    this.modalRef.close();
    this.openFeedback = true; // Opens feedback modal
    if (!this.impersonationService.isImpersonatingOrViewAs() && !this.SelectedVideo[0].isViewed) {
      this.contextualVideoService.updateVideoViewCount(this.SelectedVideo[0]).subscribe();
    }
  }
}
