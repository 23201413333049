import { Component, OnInit, Input } from '@angular/core';
import { PayoutReportService } from '../../payout-report.service';

@Component({
  selector: 'mint-payout-report-bonus-details',
  templateUrl: './bonus-details.component.html',
  styleUrls: ['./bonus-details.component.scss']
})
export class BonusDetailsComponent implements OnInit {
  @Input() bonusInfo: any;
  @Input() publicationDate: string;
  displayedColumns: string[] = ['BonusName', 'WageCode', 'Amount', 'Date'];
  constructor(public payoutReportService: PayoutReportService) {}
  ngOnInit(): void {
    
  }
}
