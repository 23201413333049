<div class="card-section card-flex">
    <div mint-bonus-buckets-summary-header
        class="card-block percentage-block"
        [bonusSummary]="bonusSummary"
        *ngIf="bucketSummaryEnabled">
    </div>
    <div mint-bonus-awards-summary-header
        class="card-block currency-block"
        [bonusSummary]="bonusSummary"
        *ngIf="awardSummaryEnabled">
    </div>
</div>