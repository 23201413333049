import { Component, Inject, OnInit } from '@angular/core';
import * as Ng1Services from '../core/hybrid/ng1-upgraded-providers';
import { from } from 'rxjs';
import { MessagesService } from '@mint-libs/context';
import { FiltersService } from '../filters/filters.service';

@Component({
  selector: 'app-rollover-notification',
  templateUrl: './rollover-notification.component.html',
  styleUrls: ['./rollover-notification.component.scss']
})
export class RollOverNotificationComponent implements OnInit {
  public rolloverMessage: any;

  constructor(private messageService: MessagesService, private filterService: FiltersService) {}

  ngOnInit(): void {
    this.getRolloverMessage();
  }

  getRolloverMessage() {
    this.messageService.get('RolloverMessage').subscribe(response => {
      this.rolloverMessage = response;
      if (this.rolloverMessage) {
        const fiscalyear = this.filterService.selectedCodePeriod.substr(0, 4);
        this.rolloverMessage = this.rolloverMessage.replace('FY19', fiscalyear);
      }
    });
  }
}
