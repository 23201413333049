import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'mint-content-loader-bar-chart-horizontal',
    templateUrl: './content-loader-bar-chart-horizontal.component.html'
})
export class ContentLoaderBarChartHorizontalComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
