import { Expose, Type } from 'class-transformer';
import { Duration } from './duration.model';

export class FiscalYear {
  @Expose()
  key: number;
  @Type(() => Duration)
  @Expose()
  duration: Duration;
}
