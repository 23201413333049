import { Inject, Injectable } from '@angular/core';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { DataService } from '../../core/network/data.service';
import { EndpointService } from '@mint-libs/network';

@Injectable({ providedIn: 'root' })
export class ContextualVideoService {
  constructor(private endpoint: EndpointService, private dataService: DataService) {}
  getEmbeddedVideoLinks() {
    return this.dataService.getWithGlobalFilters<any>(this.endpoint.GetEmbeddedVideoLinks.uri, null);
  }
  updateVideoViewCount(featureID) {
    return this.dataService.post(this.endpoint.UpdateVideoViewCount.uri, featureID);
  }
}
