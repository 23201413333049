import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mint-awards-unlocked',
  templateUrl: './awards-unlocked.component.html',
  styleUrls: ['./awards-unlocked.component.scss']
})
export class AwardsUnlockedComponent implements OnInit {
  @Input() awardsDataSet;
   bonusPrecentage:any;
  constructor() {}
  //TODO: Add service and API calls for awards section
  // public awardsDataSet = [
  //   { itemNumber: 1, pay_percentage: 25, pay_amount: '', currency: '', locked: true },
  //   { itemNumber: 2, pay_percentage: 50, pay_amount: '', currency: '', locked: true },
  //   { itemNumber: 3, pay_percentage: 100, pay_amount: '', currency: '', locked: true }
  // ];
  ngOnInit() {
        if(this.awardsDataSet){
      this.bonusPrecentage = this.awardsDataSet[2].pay_percentage;
    }
  }
}
