<div id="compliance-notification_1" class="notification-alert warning" role="alert" *ngIf="showComplianceBanner">
  <div id="compliance-notification_2" class="row">
    <div id="compliance-notification_3">
      <p id="compliance-notification_4" aria-live="polite">
        <span id="compliance-notification_5" class="sr-only">Warning message</span> <span id="compliance-notification_6"
          aria-hidden="true" class="icon icon-notification" title="Warning"></span>
        <strong id="compliance-notification_7">{{ complianceBannerMessage }}</strong>
        <button id="compliance-notification_8" type="button" class="btn btn-clear btn-link m-l-20"
          (click)="tabselect(0); navigateTo('complianceDashboard')"
          (keyup.enter)="tabselect(0); navigateTo('complianceDashboard')" title="Compliance Dashobard">
          Go to Compliance Dashboard
        </button>
        <button id="compliance-notification_9" title="close warning message" class="btn-clear pull-right"
          (click)="showComplianceBanner = false" (keyup.enter)="showComplianceBanner = false">
          <em id="compliance-notification_10" class="icon icon-close"></em>
        </button>
      </p>
    </div>
  </div>
</div>