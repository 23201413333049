<div class="core-bucket-detail-tile">
  <div class="core-bucket-detail-header-title">
    Payout Percentage<mint-tooltip
      [orientation]="'right'"
      [size]="'small'"
      [tipText]="payoutReportService.roundOff(selectedCoreBucket.calc.PayoutAfterCaps) + '% capped Payout Percentage used for all calculations'"
    ></mint-tooltip>
  </div>
  <div>
    <div class="core-bucket-detail-body" id="payout-percentage-tile-body">
      <div>
        <div class="core-bucket-detail-body-section-lhsamount">{{ payoutReportService.roundOff(selectedCoreBucket.calc.PayoutBeforeCaps) }}%</div>
        <div class="core-bucket-detail-body-section-subtitles" id="payout-percentage-lhs-subtitle">Uncapped Payout Percentage</div>
      </div>
      <div>=</div>
      <div class="core-bucket-detail-body-rhssection">
        <div *ngFor="let payoutStage of selectedCoreBucket.calc.PayoutStages; let i = index">
          <div class="core-bucket-detail-body-rhs-row">
            <div>
              <div class="core-bucket-detail-body-section-rhsamount">{{ payoutReportService.roundOff(payoutStage.PayoutValue) }}%</div>
              <div class="core-bucket-detail-body-section-subtitles">{{ payoutStage.PayoutTitle }}</div>
            </div>
            <div>+</div>
            <div>
              <div class="core-bucket-detail-body-section-rhsamount">( {{ payoutReportService.roundOff(payoutStage.AttainmentValue) }}%</div>
              <div class="core-bucket-detail-body-section-subtitles">{{ payoutStage.AttainmentTitle }}</div>
            </div>
            <div>x</div>
            <div>
              <div class="core-bucket-detail-body-section-rhsamount">{{ payoutReportService.roundOff(payoutStage.MultiplierValue) }} )</div>
              <div class="core-bucket-detail-body-section-subtitles">{{ payoutStage.MultiplierTitle }}</div>
            </div>
          </div>
          <div *ngIf="i !== selectedCoreBucket.calc?.PayoutStages.length - 1" class="core-bucket-detail-body-multiple-rows-summation">+</div>
        </div>
      </div>
    </div>
  </div>
  <div class="payout-percentage-tile-footer">
    <div class="max-cap-percentage">
      Your Payout is <span>capped at {{ selectedCoreBucket.UsedCap }}%</span>
    </div>
    <div class="max-cap-percentage">Note: To learn more about your payout percentage, navigate to the Earning Potential tab in your My Compensation Plan page.</div>
  </div>
</div>
