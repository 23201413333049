import { Component, OnInit, Inject, EventEmitter, Input, Output } from '@angular/core';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { ExportToExcelService } from './export-to-excel.service';

@Component({
  selector: 'mint-export-excel',
  templateUrl: './excel-export.component.html',
  styleUrls: ['./excel-export.component.scss']
})
export class ExcelExportComponent implements OnInit {
  /** Title will be shown as tooptip */
  @Input() id: string;
  @Input() title: string;
  @Input() reportFileName: string;
  @Output() notifyDownloadClick: EventEmitter<string> = new EventEmitter<string>();
  @Input() downloadInactive: false;
  @Input() labelText: string;
  @Input() icon = 'excel';
  isExportDisabled;
  public sharedContext: SharedContext = new SharedContext();
  constructor(public sharedContextState: Store<SharedContext>, private exportToExcelServiceNew: ExportToExcelService) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      this.isExportDisabled = this.sharedContext.isDemoMode2 === true;
    });
  }

  ngOnInit() {}

  /** Downloads the Plan details (Spreadsheat) in borwser  */
  onExportToExcel() {
    if (!this.isExportDisabled) {
      const fileName = this.exportToExcelServiceNew.getFileName(this.reportFileName);
      this.notifyDownloadClick.emit(fileName);
    }
  }
}
