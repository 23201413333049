<table mat-table [dataSource]="bonusInfo" class="mat-elevation-z8">
  <!-- Bonus Name Column -->
  <ng-container matColumnDef="BonusName">
    <th mat-header-cell *matHeaderCellDef>Bonus Name</th>
    <td mat-cell *matCellDef="let element">{{ element.RevmapName }}</td>
  </ng-container>
  <!-- Bonus Wage Code Column -->
  <ng-container matColumnDef="WageCode">
    <th mat-header-cell *matHeaderCellDef>Wage Code</th>
    <td mat-cell *matCellDef="let element">{{ element.WageCode }}</td>
  </ng-container>
  <!-- Bonus Amount Column -->
  <ng-container matColumnDef="Amount">
    <th mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let element">
        {{ payoutReportService.formatCurrency(element.BonusEarningAmount) }} 
        <span class="payout-report-body-subtitles">{{ element.CurrencyCode }}</span>
    </td>
  </ng-container>
  <!-- Bonus Date Column -->
  <ng-container matColumnDef="Date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">{{ publicationDate | date: 'mediumDate' }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
