export class DirectQACompliance {
  status: number;
  startDate: Date;
  endDate: Date;
  dateRangeString: string;

  constructor(qaStatus: number, qaStartDate: Date, qaEndDate: Date, dateRangeString: string) {
    this.status = qaStatus;
    this.startDate = qaStartDate;
    this.endDate = qaEndDate;
    this.dateRangeString = dateRangeString;
  }
}
