import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TelemetryContext {
  public isInitialized = false;

  public currentPage = '';
  public userAlias = '';
  public personnelNumber = '';
  public segment = '';
  public StandardTitle = '';
  public PlanName = '';
  public incentivePlanType = '';
  public country = '';
  public isPPASigned = false;
  public isQuotaAcknowledged = false;
  public isSeller = false;
  public isManager = false;
  public isNonParticipantManager = false;
  public isSupportAdmin = false;
  public isFCA = false;
  public role = '';
  public featureRole = '';
  public incentiveType = '';
  public isNonWwicAndMic2Eligible = false;

  public getRole = function() {
    var role = '';
    if (this.isSeller) {
      role = 'Seller';
    } else if (this.isManager) {
      role = 'Manager';
    } else if (this.isNonParticipantManager) {
      role = 'NonParticipatingManager';
    } else if (this.isSupportAdmin) {
      role = 'SupportAdmin';
    } else if (this.isFCA) {
      role = 'FCA';
    }
    return role;
  };
}
