<content-loader [height]="200" [width]="900">
  <svg:rect x="26" y="17" rx="0" ry="0" width="39" height="182" />
  <svg:rect x="73" y="16" rx="0" ry="0" width="39" height="182" />
  <svg:rect x="155" y="17" rx="0" ry="0" width="39" height="184" />
  <svg:rect x="200" y="16" rx="0" ry="0" width="41" height="183" />
  <svg:rect x="285" y="17" rx="0" ry="0" width="41" height="182" />
  <svg:rect x="332" y="15" rx="0" ry="0" width="39" height="183" />
  <svg:rect x="469" y="16" rx="0" ry="0" width="40" height="183" />
  <svg:rect x="419" y="15" rx="0" ry="0" width="43" height="186" />
  <svg:rect x="4" y="197" rx="0" ry="0" width="596" height="4" />
  <svg:rect x="3" y="18" rx="0" ry="0" width="2" height="181" />
</content-loader>
