import { Expose, Type } from 'class-transformer';
import { Disclaimer } from './disclaimer.model';

export class Widget {
  // userPreference: UserPreference;
  // role: EmployeeType;
  @Expose() col: number;
  @Expose() detailView: string;
  @Expose() displayName: string;
  @Expose() helpText: string;
  @Expose() htmlTemplate: string;
  @Expose() isConfigurable: boolean;
  @Expose() isPinned: boolean;
  @Expose() isVisible: boolean;
  @Expose() roleId: number;
  @Expose() selectedValue: any;
  @Expose() selectedPCValue: any;
  @Expose() settings: string;
  @Expose() specs: any;
  @Expose() timestamp: string;
  @Expose() type: string;
  @Expose() widgetConfigId: number;
  @Expose() widgetId: number;
}
