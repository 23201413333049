<div id="payout-report-header-tile" *ngIf="bonusDetails">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div id="payout-report-header-title">{{ paymentReportTitle }} {{ paymentPeriod}}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div id="payout-report-header-body">
      <div class="payout-header-section-lhsamount">
        {{ payoutReportService.formatCurrency(reduceProperty(bonusDetails, 'BonusEarningAmount')) }}
        <span class="payout-header-section-subtitles">{{ bonusDetails[0].CurrencyCode }}</span>
      </div>
      <div>=</div>
      <div>
        <div class="payout-header-section-rhsamount">
          {{ payoutReportService.formatCurrency(reduceProperty(bonusDetails, 'BonusEarningAmount')) }} <span
            class="payout-header-section-subtitles">{{ bonusDetails[0].CurrencyCode }}</span>
        </div>
        <div class="payout-header-section-subtitles">Total Bonus</div>
      </div>
      <div>+</div>
      <div>
        <div class="payout-header-section-rhsamount">
          {{ payoutReportService.formatCurrency(reduceProperty(filteredPaymentDetails, 'TotalPayrollAdjustments')) }}
          <span class="payout-header-section-subtitles">{{
            paymentDetails[0].CurrencyCode }}</span>
        </div>
        <div class="payout-header-section-subtitles">Total Bonus Adjustments</div>
      </div>
      <div>+</div>
      <div>
        <div class="payout-header-section-rhsamount">
          {{ payoutReportService.formatCurrency(reduceProperty(filteredPaymentDetails, 'PaymentRecoveryAmount')) }}
          <span class="payout-header-section-subtitles">{{
            bonusDetails[0].CurrencyCode }}</span>
        </div>
        <div class="payout-header-section-subtitles">Recovery Amount</div>
      </div>
      <div>-</div>
      <div>
        <div class="payout-header-section-rhsamount">
          {{ payoutReportService.formatCurrency(reduceProperty(filteredPaymentDetails, 'PreviousPaymentAmount')) }}
          <span class="payout-header-section-subtitles">{{
            bonusDetails[0].CurrencyCode }}</span>
        </div>
        <div class="payout-header-section-subtitles">Previous Payment</div>
      </div>
    </div>
  </mat-expansion-panel>
</div>