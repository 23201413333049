import { Injectable } from '@angular/core';
import { CustomHttpParams } from '../../core/network/custom-http-params';
import { DataService } from '../../core/network/data.service';
import { EndpointService } from '@mint-libs/network';

@Injectable({ providedIn: 'root' })
export class EstimationService {
  constructor(private endpoint: EndpointService, private dataService: DataService) {}

  getBucketEstimation(EstimatorPCGDetails) {
    let params = CustomHttpParams.getInstance();
    params = params.append('EstimatorPCGDetails', EstimatorPCGDetails);
    let res = this.dataService.postWithGlobalFilters<any[]>(this.endpoint.GetBucketLevelEstimation.uri, EstimatorPCGDetails);
    return res;
  }

  getBucketEstimationUBI(EstimatorInputUBI) {
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.GetBucketLevelEstimationUBI.uri, EstimatorInputUBI);
  }
}
