<div class="metric-details-modal">
  <div class="metric-details-header-section">
    <div class="metric-details-header-title">{{ selectedMetric.PCCName }}</div>
    <button
      id="metric-details-header-close"
      class="mat-icon-container"
      aria-label="Close Metric Detail Modal"
      tabindex="0"
      ngbAutofocus
      (click)="closeModal()"
      (keyup.enter)="closeModal()"
      analytics-tracker
      [tracker]="'MetricDetailModal_Close'"
      [value]="'Button_Click'"
    >
      <mat-icon aria-hidden="false">close</mat-icon>
    </button>
  </div>
  <div>
    <table class="metric-details-grid">
      <tbody>
        <tr>
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.TotalTarget) }}</div>
            <div class="grid-subtitles">Total Target</div>
            <div class="total-calc">(P+Q+R)</div>
          </td>
          <td class="grid-calc-symbols">=</td>
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.BaselineTarget) }}</div>
            <div class="grid-subtitles">Baseline Target (P)</div>
          </td>
          <td class="grid-calc-symbols">+</td>
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.TargetAdjustment) }}</div>
            <div class="grid-subtitles">Target Adjustment (Q)</div>
            <div *ngIf="targetAdjustmentDetails.length > 0" class="grid-subtitles adjusment-details-link" (click)="toggleAdjustmentDetails(targetAdjustmentDetails, 'targetAdjustmentDetails')">
              View Adjustment Details
              <button>
                <mat-icon *ngIf="!viewTargetAdjustmentDetails">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="viewTargetAdjustmentDetails">keyboard_arrow_up</mat-icon>
              </button>
            </div>
          </td>
          <td class="grid-calc-symbols">+</td>
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.TargetModifier) }}</div>
            <div class="grid-subtitles">Target Modifier (R)</div>
          </td>
        </tr>
        <tr *ngIf="viewTargetAdjustmentDetails">
          <td colspan="7">
            <mint-payout-report-metric-adjustment-details id="adjustmentDetails" [adjustmentDetails]="adjustmentDetailsInfo"></mint-payout-report-metric-adjustment-details>
          </td>
        </tr>
        <tr>
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.Actuals) }}</div>
            <div class="grid-subtitles">Total Actuals</div>
            <div class="total-calc">(A+B+C)</div>
          </td>
          <td class="grid-calc-symbols">=</td>
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.BaselineActuals) }}</div>
            <div class="grid-subtitles">Baseline Actuals (A)</div>
          </td>
          <td class="grid-calc-symbols">+</td>
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.ActualsAdjustment) }}</div>
            <div class="grid-subtitles">Baseline Actuals Adjustments (B)</div>
            <div *ngIf="actualsAdjustmentDetails.length > 0" class="grid-subtitles adjusment-details-link" (click)="toggleAdjustmentDetails(actualsAdjustmentDetails, 'actualsAdjustmentDetails')">
              View Adjustment Details
              <button>
                <mat-icon *ngIf="!viewActualsAdjustmentDetails">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="viewActualsAdjustmentDetails">keyboard_arrow_up</mat-icon>
              </button>
            </div>
          </td>
          <td class="grid-calc-symbols">+</td>
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.ActualsModifier) }}</div>
            <div class="grid-subtitles">Baseline Actuals Modifier (C)</div>
          </td>
        </tr>
        <tr *ngIf="viewActualsAdjustmentDetails">
          <td colspan="7">
            <mint-payout-report-metric-adjustment-details id="adjustmentDetails" [adjustmentDetails]="adjustmentDetailsInfo"></mint-payout-report-metric-adjustment-details>
          </td>
        </tr>
        <tr *ngIf="!selectedMetric.IsDynamicMetric">
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.TotalRecurringTarget) }}</div>
            <div class="grid-subtitles">{{ totalRecurringStr }}</div>
            <div class="total-calc">(G+H+I)</div>
          </td>
          <td class="grid-calc-symbols">=</td>
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.TargetRecurring) }}</div>
            <div class="grid-subtitles">{{ recurringTargetStr }} (G)</div>
          </td>
          <td class="grid-calc-symbols">+</td>
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.TargetRecurringAdjustment) }}</div>
            <div class="grid-subtitles">Recurring Adjustment (H)</div>
            <div
              *ngIf="recurringTargetAdjustmentDetails.length > 0"
              class="grid-subtitles adjusment-details-link"
              (click)="toggleAdjustmentDetails(recurringTargetAdjustmentDetails, 'recurringTargetAdjustmentDetails')"
            >
              View Adjustment Details
              <button>
                <mat-icon *ngIf="!viewRecurringTargetAdjustmentDetails">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="viewRecurringTargetAdjustmentDetails">keyboard_arrow_up</mat-icon>
              </button>
            </div>
          </td>
          <td class="grid-calc-symbols">+</td>
          <td>
            <div class="grid-content">{{ payoutReportService.formatCurrency(selectedMetric.Metrics.TargetRecurringModifier) }}</div>
            <div class="grid-subtitles">Recurring Modifier (I)</div>
          </td>
        </tr>
        <tr *ngIf="viewRecurringTargetAdjustmentDetails">
          <td colspan="7">
            <mint-payout-report-metric-adjustment-details id="adjustmentDetails" [adjustmentDetails]="adjustmentDetailsInfo"></mint-payout-report-metric-adjustment-details>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
