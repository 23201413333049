import { Direct } from './models/direct';
import { Injectable, Inject } from '@angular/core';
import { Metric } from 'src/app/shared/models/metric.model';
import { QuotaTogoMetric } from './models/quota-togo-metric';
import { AzureIncentiveMetric } from './models/azure-incentive-metric';
import { Formatter } from '@mint-libs/common';
import { DirectPPACompliance } from './models/direct-ppa-compliance';
import { DirectQACompliance } from './models/direct-qa-compliance';
import { ManagerDashboardService } from './manager-dashboard.service';
import { forkJoin } from 'rxjs';
import * as _ from 'underscore';
import { LegendService } from 'src/app/shared/legends/legend.service';
import { UserProfileService } from 'src/app/core/context/user-profile.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { DirectsFilter } from './models/directs-filter';

@Injectable()
export class ManagerDashboardHelper {
  expandAllText = 'Expand All';
  maxDirectsLevel = 4;
  minDirectsLevel = 1;
  public sharedContext: SharedContext = new SharedContext();

  // Todo: Remove this flag and it's usage in html once we have export options built for UBI directs.

  constructor(
    private formatter: Formatter,
    private managerDashboardService: ManagerDashboardService,
    private legendService: LegendService,
    public userProfileService: UserProfileService,
    public sharedContextState: Store<SharedContext>
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  processDirectsResult(directsFromResult: any): Array<Direct> {
    const directs = new Array<Direct>();

    if (directsFromResult && directsFromResult.length > 0) {
      directsFromResult.forEach(directFromResult => {
        const direct = new Direct();
        // Basic data
        this.processBasicData(direct, directFromResult);

        // Access
        this.processDirectsAccessInfo(direct, directFromResult.Access);

        // QA & PPA
        this.processDirectsComplianceInfo(direct, directFromResult);

        // RBI Attainment
        this.processDirectsAttainment(direct, directFromResult.PcgList);

        // UBI Attainment
        if (directFromResult.UBIAttainmentMetrics != null && directFromResult.UBIAttainmentMetrics.length > 0) {
          this.processDirectsUBIAttainment(direct, directFromResult.UBIAttainmentMetrics);
        }

        directs.push(direct);
      });
    }

    return directs;
  }

  processBasicData(direct: Direct, directFromResult) {
    direct.name = directFromResult.EmployeeName;
    direct.firstName = directFromResult.FirstName;
    direct.lastName = directFromResult.LastName;
    direct.role = directFromResult.StandardTitle;
    direct.alias = directFromResult.Alias;
    direct.managerAlias = directFromResult.ManagerAlias;
    direct.personnelNumber = directFromResult.PersonnelNumber;
    direct.planId = directFromResult.PlanId;
    direct.optedOut = directFromResult.IsOptOut;
    // Remove/Add condition here when PPI is on-boarded to PROD.
    direct.incentiveType = directFromResult.IncentivePlanType;
    direct.isRBIPersona = directFromResult.IncentivePlanType === 'RBI';
    direct.isUBIPersona = directFromResult.IncentivePlanType === 'UBI';
    direct.isPPIPersona = directFromResult.IncentivePlanType === 'PPI';
    direct.quotaDocumentName = direct.isRBIPersona ? 'QA' : 'TA';
    direct.numberOfReportees = directFromResult.DirectsCount;
    direct.totalWeightedAttainment = directFromResult.TotalWeightedAttainment;
    direct.currentParticipation = directFromResult.CurrentParticipation;
    direct.isACREligible = directFromResult.IsACREligible;
    direct.isACREligibleInHierarchy = directFromResult.IsACREligibleInHierarchy;
    direct.isManager = directFromResult.IsManager;
    direct.isFCAHasAccess = directFromResult.ISFCAHasAccess;
    direct.isPlanOnboarded = directFromResult.IsPlanOnboarded;
    direct.isQaFcaApproved = directFromResult.IsQaFcaApproved;
    direct.ubiRunRate = directFromResult.UbiRunRate;
  }

  processDirectsAccessInfo(direct: Direct, accessInfo) {
    if (accessInfo) {
      direct.isAuthorized = accessInfo.IsAuthorized;
      direct.missingBusinesses = accessInfo.MissingBusinesses;
      direct.missingSubsidiaries = accessInfo.MissingSubsidiaries;
    }
  }

  processDirectsComplianceInfo(direct: Direct, directFromResult) {
    direct.isQAAccepted = directFromResult.IsQAAccepted;
    direct.isPPAComplete = directFromResult.IsPPAComplete;

    if (directFromResult.EmployeeDetails) {
      // PPA
      if (directFromResult.EmployeeDetails.PPACompliances && directFromResult.EmployeeDetails.PPACompliances.length > 0) {
        direct.ppaComplianceCountries = new Array<DirectPPACompliance>();
        directFromResult.EmployeeDetails.PPACompliances.filter(t => t.PPAStatus === 1 || t.PPAStatus === 2).forEach(ppaCompliance => {
          const countryNameString = ppaCompliance.CountryName.trim().toUpperCase() === 'France'.toUpperCase() ? ` (${ppaCompliance.CountryName})` : '';
          direct.ppaComplianceCountries.push(new DirectPPACompliance(ppaCompliance.PPAStatus, ppaCompliance.CountryName, countryNameString));
        });
      }

      // QA
      if (directFromResult.EmployeeDetails.QACompliances && directFromResult.EmployeeDetails.QACompliances.length > 0) {
        direct.qaComplianceDates = new Array<DirectQACompliance>();
        directFromResult.EmployeeDetails.QACompliances.filter(t => t.QaStatus === 1 || t.QaStatus === 2 || t.QaStatus === 3).forEach(qaCompliance => {
          const startDate = this.formatter.transformToDateFormat(qaCompliance.ParticipationStartDate, 'dd-MMM', 'en-US');
          const endDate = this.formatter.transformToDateFormat(qaCompliance.ParticipationEndDate, 'dd-MMM', 'en-US');
          const dateRangeString = ` (${startDate} to ${endDate})`;
          direct.qaComplianceDates.push(new DirectQACompliance(qaCompliance.QaStatus, qaCompliance.ParticipationStartDate, qaCompliance.ParticipationEndDate, dateRangeString));
        });
      }
    }
  }

  processDirectsAttainment(direct: Direct, pcgList) {
    if (pcgList && pcgList.length > 0) {
      direct.pcgMetrics = new Array<Metric>();
      pcgList.forEach(pcg => {
        const pcgMetric = new Metric();

        pcgMetric.name = pcg.Name;
        pcgMetric.attainment = pcg.Metrics.Attainment;
        pcgMetric.weight = pcg.Weightage / 100;

        // processing width & color for attainment progress bar
        pcgMetric.attainmentWidth = (pcgMetric.attainment <= 100 ? (pcgMetric.attainment > 0 ? pcgMetric.attainment : 5) : 100) + '%';
        pcgMetric.attainmentClass =
          ' ' +
          this.legendService.getValue(
            pcg.Metrics.Attainment,
            pcg.PayoutStages.ThresholdAttainment,
            pcg.PayoutStages.TargetAttainment,
            pcg.PayoutStages.ExcellenceAttainment,
            // Check for OCP PPM metric weight in legend service
            pcg.Weightage,
            'cssClass'
          );

        direct.pcgMetrics.push(pcgMetric);
      });
    }
  }

  processDirectsUBIAttainment(direct: Direct, ubiAttainmentMetrics: any) {
    direct.ubiAttainmentMetrics = ubiAttainmentMetrics;
    direct.ubiAttainmentMetrics.forEach(ubiAttainmentMetric => {
      if (ubiAttainmentMetric) {
        ubiAttainmentMetric.attainmentWidth =
          (ubiAttainmentMetric.AttainmentPercentage <= 100 ? (ubiAttainmentMetric.AttainmentPercentage > 0 ? ubiAttainmentMetric.AttainmentPercentage : 5) : 100) + '%';
        ubiAttainmentMetric.attainmentClass =
          ' ' +
          this.legendService.getValue(
            ubiAttainmentMetric.AttainmentPercentage,
            ubiAttainmentMetric.ThresholdAttainment,
            ubiAttainmentMetric.TargetAttainment,
            ubiAttainmentMetric.ExcellenceAttainment,
            // Check for OCP PPM metric weight in legend service
            0,
            'cssClass'
          );
        ubiAttainmentMetric.consumptionMetricAttainmentWidth =
          (ubiAttainmentMetric.ConsumptionMetricAttainment <= 100 ? (ubiAttainmentMetric.ConsumptionMetricAttainment > 0 ? ubiAttainmentMetric.ConsumptionMetricAttainment : 5) : 100) + '%';
        ubiAttainmentMetric.consumptionMetricAttainmentClass = ' ' + this.legendService.getValue(ubiAttainmentMetric.ConsumptionMetricAttainment, 0, 0, 0, 0, 'cssClass');
        if (this.userProfileService.isUBIPersona()) {
          ubiAttainmentMetric.attainmentClass = 'gray';
          ubiAttainmentMetric.consumptionMetricAttainmentClass = 'gray';
        }
        if (ubiAttainmentMetric.PeriodStatus.toLowerCase() === 'active') {
          direct.ubiActiveAttainmentPercentage = ubiAttainmentMetric.AttainmentPercentage;
        }
      }
    });
  }

  processQuotaToGoResult(directs: Direct[], quotaToGoResult: any) {
    if (quotaToGoResult && quotaToGoResult.length > 0) {
      quotaToGoResult.forEach(quotaToGoItem => {
        const direct = directs.find(x => x.personnelNumber === quotaToGoItem.PersonnelNumber);

        if (quotaToGoItem.Pccs && quotaToGoItem.Pccs.length > 0) {
          direct.pccMetrics = new Array<QuotaTogoMetric>();
          quotaToGoItem.Pccs.forEach(pccMetric => {
            const quotaToGoMetric = new QuotaTogoMetric();

            quotaToGoMetric.name = pccMetric.Name;
            quotaToGoMetric.unit = pccMetric.MeasuringUnits;
            quotaToGoMetric.quotaToGo = pccMetric.Metrics.QuotaToGo;
            quotaToGoMetric.nonRecurringQuota = pccMetric.Metrics.Target - pccMetric.Metrics.TargetRecurring;
            direct.pccMetrics.push(quotaToGoMetric);
          });
        }
      });
    }
  }

  processAzureIncentivesResult(directs: Direct[], azureIncentivesResult: any) {
    if (azureIncentivesResult && azureIncentivesResult.length > 0) {
      azureIncentivesResult.forEach(azureIncentiveItem => {
        if (azureIncentiveItem.AzureIncentives) {
          const direct = directs.find(x => x.personnelNumber === azureIncentiveItem.PersonnelNumber);
          if (direct) {
            direct.azureIncentivesMetrics = new AzureIncentiveMetric();
            direct.azureIncentivesMetrics.individualAttainment = azureIncentiveItem.DiscreteAcrAttainment;
            direct.azureIncentivesMetrics.quotaToGo = azureIncentiveItem.DiscreteAcrQuotaToGo;
            direct.azureIncentivesMetrics.overallGrowthRate = azureIncentiveItem.MicrosoftAttaintment;
            direct.azureIncentivesMetrics.revenue = azureIncentiveItem.DiscreteAcrRevenue;
            direct.azureIncentivesMetrics.target = azureIncentiveItem.DiscreteAcrTarget;
            direct.azureIncentivesMetrics.unit = azureIncentiveItem.Currency;
          }
        }
      });
    }
  }

  processSoftCapResult(directs: Direct[], softCapResult: any): boolean {
    let isSoftCapSubmitted = false;

    if (directs && directs.length > 0) {
      directs.forEach(direct => {
        direct.softCapInfo = [];
        if (softCapResult && softCapResult.length > 0) {
          const directSoftCapDetails = softCapResult.find(softCapDetail => softCapDetail.PersonnelNumber === direct.personnelNumber);
          if (directSoftCapDetails && directSoftCapDetails.SoftCapPCGDetails !== null) {
            direct.softCapInfo.softCapPCGDetails = directSoftCapDetails.SoftCapPCGDetails;
            direct.softCapInfo.isSoftCapEligible = direct.softCapInfo.softCapPCGDetails ? true : false;
            direct.softCapInfo.businessJustification = directSoftCapDetails.SoftCapPCGDetails.find(b => b.BusinessJustification !== null)
              ? directSoftCapDetails.SoftCapPCGDetails.find(b => b.BusinessJustification !== null).BusinessJustification
              : null;

            if (!isSoftCapSubmitted) {
              isSoftCapSubmitted = directSoftCapDetails.SoftCapPCGDetails.find(b => b.ManagerSubmittedDate !== null || b.ICBMManagerSubmittedDate !== null) ? true : false;
            }
          }
        } else {
          direct.softCapInfo.isSoftCapEligible = false;
          isSoftCapSubmitted = true;
        }
        direct.softCapInfo.directsPCGSoftCapChangesCount = 0;
        direct.softCapInfo.isBusinessJustificationValidationMandate = false;
      });
      if (!this.sharedContext.IsSoftCapReviewWindowOpen) {
        isSoftCapSubmitted = true;
      }
      return isSoftCapSubmitted;
    }
  }

  getAttainmentSlickConfig() {
    return {
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
      accessibility: false,
      responsive: [
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  }

  getQuotaToGoSlickConfig() {
    return {
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: false,
      accessibility: false,
      dots: false,
      responsive: [
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  }

  getAzureIncentivesSlickConfig() {
    return {
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: false,
      accessibility: false,
      dots: false,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  }

  getDirectsFilter(directs: Direct[], directsFilter: DirectsFilter,includeSubDirects: boolean=true, shouldGetBucketNames:boolean=false){
    directsFilter.roles =  this.getRoles(directs, includeSubDirects);
    if(shouldGetBucketNames){
      directsFilter.bucketNames = this.getBucketNames(directs, includeSubDirects);
    }
    return directsFilter;
  }

  getRoles(directs: Direct[], includeSubDirects:boolean=false) {
    let roles = directs.map(dir => dir.role).filter((value, index, self) => self.indexOf(value) === index && value);
    if(includeSubDirects){
    // Picking up roles of the sub directs.
      directs.forEach(dir => {
        if (dir.directs && dir.directs.length > 0) {
          const subRoles = this.getRoles(dir.directs);
          if (subRoles && subRoles.length > 0) {
            roles.concat(subRoles);
          }
        }
      });
        // filtering duplicate entries
      roles = roles.filter((value, index, self) => self.indexOf(value) === index);
    }
    return roles;
  }

  getBucketNames(directs: Direct[], includeSubDirects:boolean=false): Set<String> {
    let bucketNames = new Set<String>();
    directs.forEach(dir => {
      if(dir.pcgMetrics && dir.pcgMetrics.length>0){
       dir.pcgMetrics.forEach(pcgMetric => bucketNames.add(pcgMetric.name.trim()));
    }
  })
  if(includeSubDirects){
    // Picking up roles of the sub directs.
    directs.forEach(dir => {
      if (dir.directs && dir.directs.length > 0) {
        const subBucketNames = this.getBucketNames(dir.directs);
        if(subBucketNames && subBucketNames.size>0){
         subBucketNames.forEach(subBucketName => bucketNames.add(subBucketName));
       }
      }
    });
  }
    return bucketNames;
  }

  appendRolesFromSubDirects(subDirects: Direct[],directsFilter: DirectsFilter){
    if(directsFilter!=null){
      const subDirectRoles = this.getRoles(subDirects);
      // concatenating / union
      directsFilter.roles.push(...subDirectRoles);
      // filtering duplicate entries
      directsFilter.roles= directsFilter.roles.filter((value, index, self) => self.indexOf(value) === index);
    }
  }

  filterFlatennedDirects(flattenedDirects:Direct[], directsFilter:DirectsFilter, selectedRoles: string[],selectedBucketNames: string[]=null, selectedWeightedAttainmentRanges: string[]=null, selectedBucketAttainmentRanges: string[]=null){
    if(!flattenedDirects || !flattenedDirects.length){
      return flattenedDirects;
    }

    let filteredFlattenedDirects = flattenedDirects.filter(dir => {
      const isWeightedAttainmentInRange = selectedWeightedAttainmentRanges==null || selectedWeightedAttainmentRanges.length==0 ? true : dir.totalWeightedAttainment!=null && selectedWeightedAttainmentRanges.some( range => directsFilter.attainmentRangeMap.get(range)[0]<=dir.totalWeightedAttainment && directsFilter.attainmentRangeMap.get(range)[1]>=dir.totalWeightedAttainment);
      const isBucketNameIncluded = selectedBucketNames == null || selectedBucketNames.length==0 ? true : dir.pcgMetrics!=null && dir.pcgMetrics.some(pcgMetric =>selectedBucketNames.includes(pcgMetric.name.trim()));
      const isRoleIncluded = selectedRoles==null || selectedRoles.length==0 ? true:  selectedRoles.includes(dir.role) 
      const isBucketAttainmentInRange = selectedBucketAttainmentRanges == null || selectedBucketAttainmentRanges.length == 0 ? true : 
                      dir.pcgMetrics!=null && selectedBucketNames.every(selectedBucketName => 
                        dir.pcgMetrics.some(pcgMetric => 
                          selectedBucketName == pcgMetric.name.trim() && selectedBucketAttainmentRanges.some(range => 
                           directsFilter.attainmentRangeMap.get(range)[0] <= pcgMetric.attainment &&  directsFilter.attainmentRangeMap.get(range)[1]>=pcgMetric.attainment)));
      dir.isFiltered = isRoleIncluded && isBucketNameIncluded && isWeightedAttainmentInRange && isBucketAttainmentInRange;
      return dir.isFiltered;
    });
    return filteredFlattenedDirects;

  }
  
  filterDirects(directs: Direct[], selectedRoles: string[], includeSubDirects: boolean=true): Direct[] {
    if (!directs || !directs.length) {
      return directs;
    }

    let filteredFlattenedDirects = directs.filter(dir => {
      const isRoleIncluded = selectedRoles==null || selectedRoles.length==0 ? true:  selectedRoles.includes(dir.role);
       dir.isFiltered = isRoleIncluded 
      return dir.isFiltered;
    });

    if (includeSubDirects) {
      // Considering if sub directs are included
      const filteredFlattenedSubDirects = new Array<Direct>();
      directs.forEach(dir => {
        if (dir.directs && dir.directs.length > 0) {
          const filteredSubDirects = this.filterDirects(dir.directs, selectedRoles,true);
          if (filteredSubDirects && filteredSubDirects.length > 0) {
            filteredSubDirects.forEach(subDirect => {
              filteredFlattenedSubDirects.push(subDirect);
            });
          }
        }
      });

      if (filteredFlattenedSubDirects && filteredFlattenedSubDirects.length > 0) {
        filteredFlattenedDirects = [...filteredFlattenedDirects, ...filteredFlattenedSubDirects];
      }
    }

    return filteredFlattenedDirects;
  }

  resetFilterFlags(directs: Direct[]) {
    if (directs && directs.length > 0) {
      directs.forEach(direct => {
        direct.isFiltered = false;
        this.resetFilterFlags(direct.directs);
      });
    }
  }

  // Loading sub directs
  loadSubDirects(direct: Direct, mode: string, loadNextLevel = false, directsFilter:DirectsFilter=null, storeFlattenedSubDirects = false, flattenedDirects: Direct[]= null) {
    if (direct.numberOfReportees > 0) {
      direct.viewReporteesFlag = 'Hide';
      if (mode === 'attainment') {
        this.loadSubDirectsWithAttainment(direct,directsFilter,flattenedDirects, storeFlattenedSubDirects, loadNextLevel);
      } else if (mode === 'quota-to-go') {
        this.loadSubDirectsWithQuotaMetrics(direct, loadNextLevel, directsFilter);
      } else {
        // 'azure-incentives'
        this.loadSubDirectsWithAzureIncetiveMetrics(direct, loadNextLevel, directsFilter);
      }
    }
  }


  private loadSubDirectsWithAttainment(direct: Direct,directsFilter: DirectsFilter, flattenedDirects: Direct[], storeFlattenedSubDirects = false, loadNextLevel= true){   
   // if(direct.isSubDirectsVisibleForAttainment){

    var directsLevel = this.maxDirectsLevel-1;
    this.managerDashboardService.getDirectsSummary(direct.alias,directsLevel.toString()).subscribe(subDirects => {
      subDirects = this.processDirectsResult(subDirects);
      this.appendRolesFromSubDirects(subDirects, directsFilter);
      if(storeFlattenedSubDirects && flattenedDirects!=null){
        flattenedDirects.push(...subDirects);
      }
      if (subDirects && subDirects.length > 0) {
        let directsMap = new Map<String, Direct>();
        subDirects.forEach(subDirect => directsMap.set(subDirect.alias, subDirect));
        for(let subDirect of subDirects){
          let managerDirect = direct;
          if(subDirect.managerAlias !== direct.alias){
            managerDirect =  directsMap.get(subDirect.managerAlias);
          }
          if(managerDirect.directs == undefined){
            managerDirect.directs = []
          }
          managerDirect.directs = managerDirect.directs.concat(subDirect);
          managerDirect.directs = _.sortBy(managerDirect.directs, 'name');
          managerDirect.isSubDirectsVisibleForAttainment = true;
          // Load bucketname and role name into filters
          if(subDirect.pcgMetrics && subDirect.pcgMetrics.length>0 && directsFilter!=null) {
            subDirect.pcgMetrics.forEach(pcgMetric => {directsFilter.bucketNames.add(pcgMetric.name.trim())})
          };      
      }
      direct.directs = _.sortBy(direct.directs, 'name');
    //this.appendRolesAndBucketNamesFromSubDirects(subDirects, directsFilter);
    }; 
    direct.showSubDirectsLoader = false;
    });
  }

  private loadSubDirectsWithQuotaMetrics(direct: Direct, loadNextLevel = false, directsFilter: DirectsFilter) {
    if (direct.isSubDirectsVisibleForQuotaToGo) {
      if (loadNextLevel && direct.directs && direct.directs.length > 0) {
        direct.directs.forEach(subDirect => {
          this.loadSubDirects(subDirect, 'quota-to-go',loadNextLevel);
        });
      }
    } else {
      direct.isSubDirectsVisibleForQuotaToGo = true;
      direct.showSubDirectsLoader = true;

      forkJoin(this.managerDashboardService.getDirectsSummary(direct.alias), this.managerDashboardService.getDirectsQuotaToGo(direct.alias)).subscribe(([directsResult, quotaResult]) => {
        this.assignSubDirects(direct, this.processDirectsResult(directsResult), directsFilter);
        this.processQuotaToGoResult(direct.directs, quotaResult);
        direct.showSubDirectsLoader = false;

        if (loadNextLevel && direct.directs && direct.directs.length > 0) {
          direct.directs.forEach(subDirect => {
            this.loadSubDirects(subDirect, 'quota-to-go', true, directsFilter);
          });
        }
      });
    }
  }

  private loadSubDirectsWithAzureIncetiveMetrics(direct: Direct, loadNextLevel = false, directsFilter: DirectsFilter) {
    if (direct.isSubDirectsVisibleForAzureIncetives) {
      if (loadNextLevel && direct.directs && direct.directs.length > 0) {
        direct.directs.forEach(subDirect => {
          this.loadSubDirects(subDirect, 'azure-incentives',true);
        });
      }
    } else {
      direct.isSubDirectsVisibleForAzureIncetives = true;
      direct.showSubDirectsLoader = true;

      forkJoin(this.managerDashboardService.getDirectsSummary(direct.alias), this.managerDashboardService.getDirectsAccelerateAzure(direct.alias)).subscribe(
        ([directsResult, azureIncentiveResult]) => {
          this.assignSubDirects(direct, this.processDirectsResult(directsResult), directsFilter);
          this.processAzureIncentivesResult(direct.directs, azureIncentiveResult);
          direct.showSubDirectsLoader = false;

          if (loadNextLevel && direct.directs && direct.directs.length > 0) {
            direct.directs.forEach(subDirect => {
              this.loadSubDirects(subDirect, 'azure-incentives',true, directsFilter);
            });
          }
        }
      );
    }
  }

  private assignSubDirects(parentDirect: Direct, subDirects: Direct[], directsFilter:DirectsFilter=null) {
    if (subDirects && subDirects.length > 0) {
      subDirects.forEach(subDirect => {
        subDirect.level = parentDirect.level + 1;
      });
    }
    parentDirect.directs = _.sortBy(subDirects, 'name');
    this.appendRolesFromSubDirects(subDirects,directsFilter);
  }

  expandAll(directs: Direct[], mode: string, flattenedDirects:Direct[], directsFilter) {
    // if(mode=="Attainment"){
    //   // For attainment mode, loadSubDirects needs to be called only for Level 1 directs
    //   directs.forEach(direct => )
    // }
    // else{
    if (directs && directs.length > 0 && directs[0].level < 3) {
        directs.forEach(direct => {
          if(direct.numberOfReportees>0){ 
              direct.showSubDirectsLoader=true;
              direct.isSubDirectsVisibleForAttainment = true;
              this.loadSubDirects(direct,mode, true,directsFilter, true, flattenedDirects);
            }
        });
      }
  //  }
  }

  collapseAll(directs: Direct[], directsFilter:DirectsFilter, mode: string) {
    directs.forEach(direct => {
      direct.isSubDirectsVisibleForAttainment = false;
      direct.isSubDirectsVisibleForAzureIncetives = false;
      direct.isSubDirectsVisibleForQuotaToGo = false;
      direct.viewReporteesFlag = 'View';
      direct.directs = null;
    });
    this.getDirectsFilter(directs,directsFilter, false, mode=="attainment");
  }
}
