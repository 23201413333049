import { Directive, ViewContainerRef, TemplateRef, Inject } from '@angular/core';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { FiltersService } from '../../filters/filters.service';

@Directive({
  selector: '[mintPayoutUnavailable]'
})
export class PayoutUnavailableDirective {
  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>, private filterService: FiltersService) {
    // Let view be embedded if payout data is available
    if (!this.filterService.isPayoutUnavailable()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // Else clear it
      this.viewContainer.clear();
    }
  }
}
