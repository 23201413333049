import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AvatarModule } from 'ngx-avatar';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { PayoutReportSectionComponent } from './payout-report-section/payout-report-section.component';
import { PayoutReportBackupSectionComponent } from './payout-report-backup-section/payout-report-section.component';
import { PayoutReportComponent } from './payout-report.component';
import { PaymentDetailsComponent } from './payout-report-section/payment-details/payment-details.component';
import { PaymentDetailsBackupComponent } from './payout-report-backup-section/payment-details/payment-details.component';
import { BonusPaymentDetailsComponent } from './payout-report-section/bonus-payment-details/bonus-payment-details.component';
import { AdjustmentDetailsComponent } from './payout-report-section/adjustment-details/adjustment-details.component';
import { BonusDetailsComponent } from './payout-report-section/bonus-details/bonus-details.component';
import { CoreBucketDetailsComponent } from './payout-report-section/core-bucket-details/core-bucket-details.component';
import { PayoutReportService } from './payout-report.service';
import { BucketIncentiveAwardComponent } from './payout-report-section/core-bucket-details/bucket-incentive-award/bucket-incentive-award.component';
import { PayoutPercentageComponent } from './payout-report-section/core-bucket-details/payout-percentage/payout-percentage.component';
import { BucketAttainmentComponent } from './payout-report-section/core-bucket-details/bucket-attainment/bucket-attainment.component';
import { BucketTargetActualsComponent } from './payout-report-section/core-bucket-details/bucket-target-actuals/bucket-target-actuals.component';
import { MatIconModule } from '@angular/material/icon';
import { MetricDetailsComponent } from './payout-report-section/core-bucket-details/metric-details/metric-details.component';
import { MetricAdjustmentDetailsComponent } from './payout-report-section/core-bucket-details/metric-details/adjustment-details/adjustment-details.component';

@NgModule({
  imports: [CommonModule, SharedModule, NgbModule, AvatarModule, MatDividerModule, MatExpansionModule, MatTableModule, MatIconModule],
  declarations: [
    PayoutReportComponent,
    PayoutReportSectionComponent,
    PaymentDetailsComponent,
    PaymentDetailsBackupComponent,
    PayoutReportBackupSectionComponent,
    BonusPaymentDetailsComponent,
    AdjustmentDetailsComponent,
    CoreBucketDetailsComponent,
    BonusDetailsComponent,
    BucketIncentiveAwardComponent,
    PayoutPercentageComponent,
    BucketAttainmentComponent,
    BucketTargetActualsComponent,
    MetricDetailsComponent,
    MetricAdjustmentDetailsComponent
  ],
  exports: [PayoutReportComponent],
  providers: [PayoutReportService]
})
export class PayoutReportModule {}
