import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  logError(message: string, stack: string) {
    console.log('LoggerService: ' + message, stack);
  }
}
