import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { UserProfileService } from '../core/context/user-profile.service';
import { RoleFeatureService } from '@mint-libs/context';
import { FiltersService } from '../filters/filters.service';
import { ImpersonationService } from '../core/impersonation/impersonation.service';

@Injectable()
export class MqchRouteGuard implements CanActivate {
  mchUserAliases = [];
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    public userProfileService: UserProfileService,
    private filterService: FiltersService,
    private impersonationService: ImpersonationService,
    public sharedContextState: Store<SharedContext>,
    private rolefeatureService: RoleFeatureService,
    private _router: Router
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('share context ', this.sharedContext);
    if (this.filterService.isFSA()) {
      return true;
    }
    this._router.navigate(['/login']);
    return false;
  }

  flightCheck() {
    return (
      (this.sharedContext.isImpersonating && (this.sharedContext.loggedInUserInfo.isSuperAdmin || this.sharedContext.loggedInUserInfo.isSupportAdmin || this.sharedContext.loggedInUserInfo.isFCA)) ||
      this.checkMCHisEnabled()
    );
  }

  checkMCHisEnabled() {
    return this.rolefeatureService.isPageApplicable(this.rolefeatureService.pages.MyChangeHistory);
  }
}
