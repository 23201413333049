import { SafeHtml } from '@angular/platform-browser';
import { DataSource } from './data-source.model';
import { BaseModel } from './base.model';
import { Expose, deserializeArray, plainToClass, Type } from 'class-transformer';

export class BucketEstimatorDetails extends BaseModel {
  @Expose({ name: 'Name' }) BucketName: string;
  @Expose({ name: 'PcgId' }) pcgId: string;
  @Expose({ name: 'Weightage' }) Weight: string;
  @Expose({ name: 'Earnings' }) CurrentEarnings: number;
  @Expose({ name: 'Attainment' }) Attainment: string;
  @Expose({ name: 'EarningsCurrency' }) EarningsCurrency: string;
  @Expose({ name: 'AttainmentCSS' }) AttainmentCSS: string;
  @Expose({ name: 'AttainmentCSSClass' }) AttainmentCSSClass: string;
  @Expose({ name: 'PcgCurrency' }) PcgCurrency: string;
  @Expose({ name: 'MeasuringUnits' }) MeasuringUnits: string;
  @Expose({ name: 'ProjectedEarnings' }) ProjectedEarnings: string;
  @Expose({ name: 'ProjectedAttainment' }) ProjectedAttainment: string;
  @Expose({ name: 'MaxCappedEarnigns' }) MaxCappedEarnigns: number;
  @Expose({ name: 'EarningsPercentage' }) EarningsPercentage: number;
  @Expose({ name: 'SoftCap' }) SoftCap: number;
  @Expose({ name: 'PayoutPercentage' }) PayoutPercentage: number;
  @Expose({ name: 'UsedCap' }) UsedCap: number;
  @Expose({ name: 'ITA' }) ITA: number;
  @Expose({ name: 'TargetHours' }) TargetHours: number;
  @Expose({ name: 'ActualHours' }) ActualHours: number;
  @Expose({ name: 'ProjectedActualHours' }) ProjectedActualHours: number;
  @Expose({ name: 'ProjectedWeeklyRate' }) ProjectedWeeklyRate: number;
  @Expose({ name: 'PeriodStatus' }) PeriodStatus: string;
  @Expose({ name: 'AttainmentTA' }) AttainmentTA: number;
  @Expose({ name: 'AttainmentActive' }) AttainmentActive: number;
  @Expose({ name: 'IsDynamicBucket' }) IsDynamicBucket: boolean;

  Estimated: boolean;
  @Type(() => AttainmentMetric) @Expose({ name: 'Metrics' }) Metrics = new AttainmentMetric();

  FormattedTargetRecurring: string;
  FormattedActuals: string;
  FormattedCurrentEarnings: string;
  FormattedTarget: string;
  FormattedTotalActuals: string;

  deserialize(from: any): BucketEstimatorDetails[] {
    const bucketEstimatorDetails = deserializeArray<BucketEstimatorDetails>(BucketEstimatorDetails, JSON.stringify(from), this.classTransformOptions);
    return bucketEstimatorDetails;
  }
  deserializeSingle(from: object): BucketEstimatorDetails {
    const bucketEstimatorDetail = plainToClass<BucketEstimatorDetails, object>(BucketEstimatorDetails, from, this.classTransformOptions);
    return bucketEstimatorDetail;
  }
}

export class AttainmentMetric extends BaseModel {
  @Expose({ name: 'Target' }) Target: string;
  @Expose({ name: 'TotalActuals' }) Actuals: string;
  @Expose({ name: 'Actuals' }) TotalActuals: string;
  @Expose({ name: 'TargetRecurring' }) TargetRecurring: string;
  @Expose({ name: 'ActualsModifier' }) ActualsModifier: string;
  @Expose({ name: 'TargetModifier' }) TargetModifier: string;
  @Expose({ name: 'TargetAdjustment' }) TargetAdjustment: string;
  @Expose({ name: 'TargetRecurringModifier' }) TargetRecurringModifier: string;
  @Expose({ name: 'TargetRecurringAdjustment' }) TargetRecurringAdjustment: string;
  @Expose({ name: 'ActualsAdjustment' }) ActualsAdjustment: string;
  @Expose({ name: 'TotalTarget' }) TotalTarget: string;
  @Expose({ name: 'TotalTargetRecurring' }) TotalTargetRecurring: string;

  ProjectedActual: number;

  deserializeSingle(from: object): AttainmentMetric {
    const metric = plainToClass<AttainmentMetric, object>(AttainmentMetric, from, this.classTransformOptions);
    return metric;
  }
}
