<mint-content-loader-bar-chart *ngIf="!isLoaded"></mint-content-loader-bar-chart>
<div class="card-section card-flex" *ngIf="isLoaded">
  <!-- Percentage : Block -->
  <div class="card-block percentage-block">
    <div class="card-header">
      <h5>Bonuses Percentage Unlocked</h5>
    </div>
    <div class="card-body">
      <div class="percentage-result">
        <div class="result-highlight">
          <h2>{{totalPercentage}}%</h2>
        </div>
        <div class="result-details">
          <div class="unlocked-bonus">
            <p>of your Bonus Award unlocked</p>
            <ul>
              <li [item]="item" *ngFor="let item of bonusComponents">

                <span [ngClass]="item.locked ? 'locked' : 'unlocked'" >
                  <svg *ngIf="item.locked==false" width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.53182 4.67162V9.87442H6.93066V4.67162H2.13153V2.93735C2.13153 2.69051 2.17311 2.46159 2.2563 2.25058C2.33682 2.04016 2.44933 1.85806 2.59383 1.70429C2.73568 1.54763 2.90365 1.42565 3.09775 1.33836C3.29238 1.24818 3.50355 1.20309 3.73124 1.20309C3.95893 1.20309 4.17009 1.24818 4.36418 1.33836C4.55882 1.42565 4.72839 1.54763 4.87236 1.70429C5.0142 1.85806 5.12671 2.04016 5.2099 2.25058C5.29042 2.46159 5.33095 2.69051 5.33095 2.93735H5.86419C5.86419 2.609 5.80979 2.30492 5.70154 2.02513C5.59329 1.74187 5.44346 1.49676 5.25149 1.28865C5.06006 1.08111 4.83504 0.918669 4.57696 0.801317C4.31567 0.683965 4.03412 0.625 3.73124 0.625C3.42836 0.625 3.14788 0.683965 2.88979 0.801317C2.62851 0.918669 2.40241 1.08111 2.21044 1.28865C2.01901 1.49676 1.86918 1.74187 1.76093 2.02513C1.65215 2.30492 1.59829 2.609 1.59829 2.93735V4.67162H0.53182ZM1.06506 5.24971H6.39743V9.29633H1.06506V5.24971Z"
                      fill="black"
                    />
                  </svg>
                  <svg *ngIf="item.locked" width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.10983 4.04662V9.24942H0V4.04662H1.18497V2.3575C1.18497 2.03233 1.2454 1.72675 1.36567 1.44071C1.48595 1.15468 1.65244 0.904765 1.86574 0.690988C2.07844 0.477211 2.32847 0.30864 2.61523 0.18516C2.90555 0.0617383 3.21838 0 3.55491 0C3.89145 0 4.20309 0.0617383 4.48986 0.18516C4.78018 0.30864 5.03138 0.477211 5.24468 0.690988C5.45738 0.904765 5.62387 1.15468 5.74414 1.44071C5.86501 1.72675 5.92486 2.03233 5.92486 2.3575V4.04662H7.10983ZM1.77746 4.04662H5.33237V2.3575C5.33237 2.11361 5.28793 1.8848 5.19847 1.67102C5.109 1.45424 4.98398 1.26457 4.82342 1.10195C4.66582 0.939392 4.47742 0.811407 4.25879 0.718103C4.04253 0.624742 3.8079 0.578089 3.55491 0.578089C3.30192 0.578089 3.06611 0.624742 2.84689 0.718103C2.63063 0.811407 2.44281 0.939392 2.28225 1.10195C2.12465 1.26457 2.00141 1.45424 1.91194 1.67102C1.82248 1.8848 1.77746 2.11361 1.77746 2.3575V4.04662ZM6.51734 4.62471H0.592486V8.67133H6.51734V4.62471Z" fill="black"/>
                    </svg>
                    
                  {{item.percentageValue}}%
                </span>
              </li>              
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  
  <!-- Award summary : Block -->
  <div class="card-block currency-block">
    <div class="card-header">
      <h5>Awards Summary</h5>
    </div>
    <div class="card-body">
      <div class="currency-result">
        <div class="result-highlight">
          <h2>{{awardSummary.totalBonusAmountStr()}}</h2>
          <div class="sub-text" >
            <strong>{{awardSummary.currency}}</strong>
            <span>Unlocked Award</span>
          </div>
        </div>
        <div class="awardSummary">
          <div class="sub-result">
            <ul>
              <li class="text-center">
                <label>Products Unlocked </label>
                <h4 class="value"> {{awardSummary.totalProductsUnlocked}}/{{awardSummary.totalProducts}}</h4>
              </li>
              <li>
                <label>Potential Award </label>
                <h4 class="value"> {{awardSummary.potentialAwardStr()}} <small>{{awardSummary.currency}}</small></h4> 
              </li>
              <li>
                <label>Current ITA </label>
                <h4 class="value"> {{awardSummary.currentITAStr()}} <small>{{awardSummary.currency}}</small></h4> 
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p class="txt-note">The awards and attainment displayed here are annual figures and your payout for this program will occur at the end of the fiscal year.</p>
    </div>
  </div>
</div>
