import { Injectable, Inject } from '@angular/core';
import * as Ng1Services from 'src/app/core/hybrid/ng1-upgraded-providers';
import { IncentiveType } from 'src/app/shared/models/incentive-type.enum';
import { UserProfileService } from 'src/app/core/context/user-profile.service';
import { FiltersService } from '../../filters/filters.service';

@Injectable({
  providedIn: 'root'
})
export class PlanHelper {
  constructor(private filterService: FiltersService,
  public userProfileService: UserProfileService) {}

  getBucketTitle(incentiveType: string) {
    return incentiveType != null && incentiveType === IncentiveType.UBI ? 'Utilization Based Incentive' : incentiveType != null && incentiveType === IncentiveType.RUBI ? this.getRUBITitle() : '';
  }

  getRUBITitle() {
    return this.filterService.isFSA() ? 'Consumption Metric' : 'Revenue Based Determiner Metric';
  }

  getQuotaOrTargetHeader() {
    return this.userProfileService.isRBIPersona() ? 'Quota' : this.getTargetHeader();
  }

  getTargetHeader() {
    return this.userProfileService.isUBIPersona() && this.filterService.isFSA() && this.filterService.isSelectedFiscalYearLessThan2024() ? 'Quota and Target' : 'Target';
  }

  getQuotaOrTarget() {
    return this.userProfileService.isRBIPersona() ? 'Quota' : 'Target';
  }
}
