import { ManagerDashboardService } from './manager-dashboard.service';
import { Direct } from './models/direct';
import { Component, OnInit, Inject } from '@angular/core';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { ManagerDashboardHelper } from './manager-dashboard.helper';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/core/context/user-profile.service';
import * as _ from 'underscore';
import { OcvFloodgateService } from '../../ocv-feedback/ocv-floodgate.service';
import UBIManagerDashboardCampaign from '../../ocv-feedback/UBIManagerDashboardCampaign';
import { FiltersService } from '../../filters/filters.service';
import { LoggerService } from '@mint-libs/logging';
import { CommonFactory, UpdateSharedContext } from '@mint-libs/common';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from '../../core/navigation/navigation.service';
import { InitializationService } from 'src/app/core/initialization/initialization.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { DirectsFilter } from './models/directs-filter';

@Component({
  selector: 'mint-manager-dashboard',
  templateUrl: './manager-dashboard.component.html',
  styleUrls: ['./manager-dashboard.component.scss']
})
export class ManagerDashboardComponent implements OnInit {
  public activeTabId: string;

  myTeamTabs: any[] = [];
  areBucketAndAttainmentFiltersApplicable : boolean = true;
  videoData = {
    key: 'MyTeamTutorial',
    videoHtml:
      "<div style='max-width: 1280px'><div style='position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;'><iframe width='1280' height='720' src='https://msit.microsoftstream.com/embed/video/3249fb44-c0df-453f-9eaa-45e76d1dc7ad?autoplay=false&amp;showinfo=true' allowfullscreen style='border:none; position: absolute; top: 0; left: 0; right: 0; bottom: 0; height: 100%; max-width: 100%;'></iframe></div></div>",
    linkName: 'My Team Tutorial'
  };
  filterConfig = {
    isFilter: true,
    isSearch: false,
    isFiscalYear: true,
    isTitle: true,
    isPartition: true,
    showSearch: false,
    showFilter: true
  };

  // Expand all
  roles = [];
  showExpandAll = true;
  isExpandAllApplicable = false;
  isExpandedForAttainment = false;
  isExpandedForQuotaToGo = false;
  isExpandedForAzureIncentives = false;
  selectedManagerViewStepId = null;

  // State for attainment, quota, & softcap
  directs: Array<Direct>;
  flattenedDirects: Direct[];
  filteredDirects: Array<Direct>;
  apiFailureMessage = 'Error occurred during this operation. Please retry.';
  isAttainmentDataLoaded = false;
  isQuotaDataLoaded = false;
  isAzureIncentivesLoaded = false;
  isSoftCapDataLoaded = false;
  isSoftCapSubmitted = false;
  isACRApplicable = false;
  isDirectsLoaded = false;
  directsFilter: DirectsFilter;
  
  // dropdown settings
  selectedRoles = [];
  selectedBuckets = [];
  selectedWeightedAttainmentRanges = [];
  selectedBucketAttainmentRanges = [];

  expandText = "Expand to filter by all direct reports";
  collapseText = "Collapse to filter by top level direct reports";

  isRbiDirects = false;
  isUbiDirects = false;
  isUBIPersona;

  attainmenTab = {
    id: 'attainment',
    title: 'Performance by Bucket'
  };
  quotaToGoTab = {
    id: 'quota-to-go',
    title: 'Quota To Go by Metric'
  };
  softCapTab = {
    id: 'softcap-by-metric',
    title: 'Soft Cap Review by Metric'
  };
  azureIncentiveTab = {
    id: 'azure-incentives',
    title: 'Accelerate Azure'
  };
  managerReportsTab = {
    id: 'manager-reports',
    title: 'Manager Reports'
  };
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private managerDashboardService: ManagerDashboardService,
    public managerDashboardHelper: ManagerDashboardHelper,
    public userProfileService: UserProfileService,
    private navigationService: NavigationService,
    public sharedContextState: Store<SharedContext>,
    private initializationService: InitializationService,
    private commonFactory: CommonFactory,
    public rolefeatureService: RoleFeatureService,
    private filterService: FiltersService,
    private loggingService: LoggerService,
    private router: Router,
    private floodgateService: OcvFloodgateService
  ) {
    this.directsFilter = new DirectsFilter();
    this.filterService = filterService;
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.selectedManagerViewStepId = filterService.selectedManagerViewStepId;
  }

  ngOnInit() {
    this.loggingService.trackPageView(this.sharedContext.incentiveType + 'ManagerDashboard', { UserAlias: this.sharedContext.alias });
    this.activate();
  }

  activate() {
    if(this.filterService.selectedFiscalYear && this.filterService.selectedManagerViewStepId){ // This is done to avoid unneccessary calls happening in non-prod environments
      this.managerDashboardService.getDirectsSummary(this.sharedContext.alias).subscribe(
        directsResult => {
         // if(this.directs == undefined || this.directs == null || this.directs.length==0){
            this.directs = this.managerDashboardHelper.processDirectsResult(directsResult);
            // Passing this as input parameter to hide export TeamPayoutReport
            if (this.directs && this.directs.length > 0) {
              this.isUBIPersona = this.directs.some(x => x.isUBIPersona);
              const rbiDirects = this.directs.filter(t => t.isRBIPersona || t.isPPIPersona);
              const ubiDirects = this.directs.filter(t => t.isUBIPersona);
              if (rbiDirects.length > 0) {
                this.isRbiDirects = true;
              }
              if (ubiDirects.length > 0) {
                this.isUbiDirects = true;
                this.floodgateService.ActivateSurvey(UBIManagerDashboardCampaign);
              }
            }
            this.flattenedDirects = this.directs.map(a => ({...a})); // Deep copy

            this.showAll();
            this.managerDashboardHelper.getDirectsFilter(this.directs,this.directsFilter, true, true);
            this.roles = this.directsFilter.roles;

            this.getApplicableTabs();
            if (this.myTeamTabs.length > 0) {
              this.activeTabId = this.myTeamTabs[0].id;
              this.activateTab();
              this.isDirectsLoaded = true;
            }
         // }
        },
          error => {
            this.getApplicableTabs();
            if (this.myTeamTabs.length > 0) {
              this.activeTabId = this.myTeamTabs[0].id;
              this.activateTab();
              this.isDirectsLoaded = true;
            }

            this.showApiFailureError();
          }
      );
    }
} 

  getApplicableTabs() {
    if (!this.directs || this.directs.length === 0) {
      return;
    }
    const rbiDirects = this.directs.filter(t => t.isRBIPersona);
    const ppiDirects = this.directs.filter(t => t.isPPIPersona);

    this.myTeamTabs = [];

    // Add Attainment Tab
    // // if (this.filterService.selectedManagerViewFiscalYear < 2021 ) {
    // //   this.myTeamTabs.push(this.attainmenTab);
    // // } else if (this.filterService.selectedManagerViewFiscalYear < 2021 && this.newUserProfileService.isRollover()) {
    // //   this.myTeamTabs.push(this.attainmenTab);
    // // }
    this.myTeamTabs.push(this.attainmenTab);

    // Add Quota to go Tab
    if (!this.isUbiDirects) {
      this.myTeamTabs.push(this.quotaToGoTab);
    }

    if (this.isUbiDirects) {
      this.myTeamTabs.push(this.managerReportsTab);
    }

    // Add Softcap tab
    if (this.sharedContext.IsSoftCapReviewAuthorized) {
      this.myTeamTabs.push(this.softCapTab);
    }
    // Add Azure Incentive Tab
    if (rbiDirects && rbiDirects.length > 0 && rbiDirects[0].isACREligibleInHierarchy && !this.myTeamTabs.find(t => t.id === 'azure-incentives')) {
      this.isACRApplicable = rbiDirects[0].isACREligibleInHierarchy;
      this.myTeamTabs.push(this.azureIncentiveTab);
    }
  }

  navigateToDashboardV2() {
    this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, toggleView: 0 }));
    this.navigationService.navigateTo('dashboard');
  }

  onTabChange(event) {
    this.activeTabId = event.nextId;
    this.resetSelectedFilters();
    this.activateTab();
  }

  activateTab() {
    this.showExpandAll = true;
    if (this.activeTabId === 'attainment') {
      this.areBucketAndAttainmentFiltersApplicable = true;
      this.managerDashboardHelper.expandAllText = this.isExpandedForAttainment ? this.collapseText : this.expandText;
      this.loadAttainmentData();
    } else if (this.activeTabId === 'quota-to-go') {
      this.areBucketAndAttainmentFiltersApplicable = false;
      this.managerDashboardHelper.expandAllText = this.isExpandedForQuotaToGo ? this.collapseText : this.expandText;
      this.loadQuotaToGoData();
    } else if (this.activeTabId === 'azure-incentives') {
      this.areBucketAndAttainmentFiltersApplicable = false;
      this.managerDashboardHelper.expandAllText = this.isExpandedForAzureIncentives ? this.collapseText : this.expandText;
      this.loadAzureIncentivesData();
    } else if (this.activeTabId === 'softcap-by-metric') {
      this.areBucketAndAttainmentFiltersApplicable = false;
      this.showExpandAll = false;
      this.loadSoftCapData();
    }
  }

  loadAttainmentData() {
    if (!this.directs) {
      this.isAttainmentDataLoaded = false;

      this.managerDashboardService.getDirectsSummary(this.sharedContext.alias).subscribe(
        directsResult => {
          this.directs = this.managerDashboardHelper.processDirectsResult(directsResult);

          this.showAll();
          this.managerDashboardHelper.getDirectsFilter(this.directs, this.directsFilter, true, true);
          this.roles = this.directsFilter.roles;
          this.flattenedDirects = this.directs.map(a => ({...a})); // Deep copy
          this.isAttainmentDataLoaded = true;
        },
        error => {
          this.isAttainmentDataLoaded = true;
          this.showApiFailureError();
        }
      );
    } else {
      this.isAttainmentDataLoaded = true;
      this.flattenedDirects = this.directs.map(a => ({...a})); // Deep copy
      this.filteredDirects = this.directs;
          // This is a workaround to fix intermittent issues in cases like DEBCUPP, where selectedManagerViewStepId is changing to 0 after loading manager dashboard
    // Can remove this once we migrate to MSX
    if(this.filterService.selectedManagerViewStepId==null || this.filterService.selectedManagerViewStepId==0){
      this.filterService.selectedManagerViewStepId = this.selectedManagerViewStepId;
    }
    }
  }

  loadQuotaToGoData() {
    this.isQuotaDataLoaded = false;
    if (!this.directs) {
      forkJoin(this.managerDashboardService.getDirectsSummary(this.sharedContext.alias), this.managerDashboardService.getDirectsQuotaToGo(this.sharedContext.alias)).subscribe(
        ([directsResult, quotaResult]) => {
          this.directs = this.managerDashboardHelper.processDirectsResult(directsResult);
          this.managerDashboardHelper.processQuotaToGoResult(this.directs, quotaResult);

          this.showAll();
          this.managerDashboardHelper.getDirectsFilter(this.directs, this.directsFilter);

          this.isQuotaDataLoaded = true;
        },
        error => {
          this.isQuotaDataLoaded = true;
          this.showApiFailureError();
        }
      );
    } else {
      this.managerDashboardService.getDirectsQuotaToGo(this.sharedContext.alias).subscribe(
        quotaToGoResult => {
          this.managerDashboardHelper.processQuotaToGoResult(this.directs, quotaToGoResult);

          this.showAll();
          this.managerDashboardHelper.getDirectsFilter(this.directs, this.directsFilter);

          this.isQuotaDataLoaded = true;
        },
        error => {
          this.isQuotaDataLoaded = true;
          this.showApiFailureError();
        }
      );
    }
  }

  loadSoftCapData() {
    this.isSoftCapDataLoaded = false;

    if (!this.directs) {
      forkJoin(this.managerDashboardService.getDirectsSummary(this.sharedContext.alias), this.managerDashboardService.getDirectsSoftCapDetails(this.sharedContext.alias)).subscribe(
        ([directsResult, softCapResult]) => {
          this.directs = this.managerDashboardHelper.processDirectsResult(directsResult);
          this.isSoftCapSubmitted = this.managerDashboardHelper.processSoftCapResult(this.directs, softCapResult);

          this.showAll();
          this.managerDashboardHelper.getDirectsFilter(this.directs, this.directsFilter);

          this.isSoftCapDataLoaded = true;
        },
        error => {
          this.isSoftCapDataLoaded = true;
          this.showApiFailureError();
        }
      );
    } else {
      this.managerDashboardService.getDirectsSoftCapDetails(this.sharedContext.alias).subscribe(
        softCapResult => {
          this.isSoftCapSubmitted = this.managerDashboardHelper.processSoftCapResult(this.directs, softCapResult);

          this.showAll();
          this.managerDashboardHelper.getDirectsFilter(this.directs, this.directsFilter);

          this.isSoftCapDataLoaded = true;
        },
        error => {
          this.isSoftCapDataLoaded = true;
          this.showApiFailureError();
        }
      );
    }
  }

  loadAzureIncentivesData() {
    this.isAttainmentDataLoaded = false;
    if (!this.directs) {
      forkJoin(this.managerDashboardService.getDirectsSummary(this.sharedContext.alias), this.managerDashboardService.getDirectsAccelerateAzure(this.sharedContext.alias)).subscribe(
        ([directsResult, azureIncentivesResult]) => {
          this.directs = this.managerDashboardHelper.processDirectsResult(directsResult);
          this.managerDashboardHelper.processAzureIncentivesResult(this.directs, azureIncentivesResult);

          this.showAll();
          this.managerDashboardHelper.getDirectsFilter(this.directs, this.directsFilter);

          this.isAttainmentDataLoaded = true;
        },
        error => {
          this.isAttainmentDataLoaded = true;
          this.showApiFailureError();
        }
      );
    } else {
      this.managerDashboardService.getDirectsAccelerateAzure(this.sharedContext.alias).subscribe(
        quotaToGoResult => {
          this.managerDashboardHelper.processAzureIncentivesResult(this.directs, quotaToGoResult);

          this.showAll();
          this.managerDashboardHelper.getDirectsFilter(this.directs, this.directsFilter);

          this.isAttainmentDataLoaded = true;
        },
        error => {
          this.isAttainmentDataLoaded = true;
          this.showApiFailureError();
        }
      );
    }
  }

  showApiFailureError() {
    this.commonFactory.addNotification({
      name: 'error',
      message: this.apiFailureMessage,
      dismiss: 4000
    });
  }

  onSelectFilter($event, filterName) {
   // this.showExpandAll = false;
    if ((!this.selectedRoles || !this.selectedRoles.length) && (!this.selectedBuckets || !this.selectedBuckets.length) 
          && (!this.selectedWeightedAttainmentRanges || !this.selectedWeightedAttainmentRanges.length) && (!this.selectedBucketAttainmentRanges || !this.selectedBucketAttainmentRanges.length)) {
      this.showAll();
    } else {
      if(this.areBucketAndAttainmentFiltersApplicable){
        this.assignFilteredDirects(this.managerDashboardHelper.filterFlatennedDirects(this.flattenedDirects,this.directsFilter, this.selectedRoles, this.selectedBuckets, this.selectedWeightedAttainmentRanges, this.selectedBucketAttainmentRanges ));
      }
      else{
        this.assignFilteredDirects(this.managerDashboardHelper.filterDirects(this.directs, this.selectedRoles, this.activeTabId !== 'softcap-by-metric'));
      }
   }
  }

  onSelectAll($event, filterName){

   // this.showExpandAll = false;
    if(filterName=='Role'){
      this.selectedRoles = $event;
    }
    else if(filterName=='Bucket'){
      this.selectedBuckets = $event;
    }
    else if(filterName=='WeightedAttainmentRange'){
      this.selectedWeightedAttainmentRanges = $event;
    }
    else if(filterName=='BucketAttainmentRange'){
      this.selectedBucketAttainmentRanges = $event;
    }

    if ((!this.selectedRoles || !this.selectedRoles.length) && (!this.selectedBuckets || !this.selectedBuckets.length) && (!this.selectedWeightedAttainmentRanges || !this.selectedWeightedAttainmentRanges) && (!this.selectedBucketAttainmentRanges || !this.selectedBucketAttainmentRanges.length)) {
      this.showAll();
    } 
    else{

    if(this.areBucketAndAttainmentFiltersApplicable){
      this.assignFilteredDirects(this.managerDashboardHelper.filterFlatennedDirects(this.flattenedDirects,this.directsFilter, this.selectedRoles, this.selectedBuckets, this.selectedWeightedAttainmentRanges, this.selectedBucketAttainmentRanges));
    }
    else{
      this.assignFilteredDirects(this.managerDashboardHelper.filterDirects(this.directs, this.selectedRoles, this.activeTabId !== 'softcap-by-metric'));
    }
    }

  }


  showAll() {
    this.assignFilteredDirects(this.directs);
    this.showExpandAll = true;
    this.managerDashboardHelper.resetFilterFlags(this.filteredDirects);

    if (this.filteredDirects && this.filteredDirects.length > 0) {
      // To display expand all
      const directsWithReportees = this.filteredDirects.filter(t => t.numberOfReportees > 0);
      this.isExpandAllApplicable = directsWithReportees && directsWithReportees.length > 0;

      this.getApplicableTabs();
    }
  }

  

  assignFilteredDirects(directs: Array<Direct>) {
    this.filteredDirects = directs;
  }

  resetSelectedFilters(){
    this.selectedRoles = [];
    this.selectedBuckets = [];
    this.selectedWeightedAttainmentRanges = [];
    this.selectedBucketAttainmentRanges = [];
  }

  expandAll() {
    if (this.activeTabId === 'attainment') {
      this.isExpandedForAttainment = true;
    } else if (this.activeTabId === 'quota-to-go') {
      this.isExpandedForQuotaToGo = true;
    } else if (this.activeTabId === 'azure-incentives') {
      this.isExpandedForAzureIncentives = true;
    }

    // This is a workaround to fix intermittent issues in cases like DEBCUPP, where selectedManagerViewStepId is changing to 0 after loading manager dashboard
    // Can remove this once we migrate to MSX
    if(this.filterService.selectedManagerViewStepId==null || this.filterService.selectedManagerViewStepId==0){
      this.filterService.selectedManagerViewStepId = this.selectedManagerViewStepId;
    }
    // Reset filters and filtered Directs
    this.managerDashboardHelper.resetFilterFlags(this.filteredDirects);
    this.resetSelectedFilters();
    this.filteredDirects = this.directs;

    if (this.managerDashboardHelper.expandAllText === this.expandText) {
      this.managerDashboardHelper.expandAllText = this.collapseText;
      this.managerDashboardHelper.expandAll(this.filteredDirects, this.activeTabId, this.flattenedDirects,this.directsFilter);
    } else {
      this.managerDashboardHelper.expandAllText = this.expandText;
      this.managerDashboardHelper.collapseAll(this.filteredDirects, this.directsFilter, this.activeTabId);
      this.flattenedDirects = this.filteredDirects.map(a => ({...a})); // Deep Copy
    }
  }

  onUbiAdvancedFilter(ubiFilteredDirects: Array<Direct>) {
    this.filteredDirects = ubiFilteredDirects;
  }
}
