import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageNotificationComponent } from './manage-notification.component';
import { ManageNotificationRoutingModule } from './manage-notification-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ManageNotificationService } from './manage-notification.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { RowShortner } from './manage-notification-pipe';

@NgModule({
  declarations: [ManageNotificationComponent, RowShortner],
  imports: [
    CommonModule,
    ManageNotificationRoutingModule,
    NgbModule,
    NgMultiSelectDropDownModule,
    FormsModule
  ],
  providers: [ManageNotificationService]
})
export class ManageNotificationModule { }
