import { IEnvironment } from './iEnvironment';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const clientId = '434d9e5e-56f5-4882-bbf3-7286e96509f2';
const aadResource = 'http://ParticipantExperienceServiceTest';
const aadInstance = 'https://login.microsoftonline.com/';
const aadTenant = 'microsoft.onmicrosoft.com';

const endpoints = {
  serviceBaseUrl: 'https://mint-core.uat.mint.azure-test.net/',
  mintApiBaseUrl: 'https://mint-core.uat.mint.azure-test.net/',
  mintApiDetailBaseUrl: 'https://mint-core.uat.mint.azure-test.net/',
  revenueserviceBaseUrl: 'https://mintservicedtl-test.azurewebsites.net/',
  demoApiBaseUrl: 'https://mintdemo2.icmintprod.p.azurewebsites.net/',
  bonusApiUrl: 'https://bonusapiuat.azurewebsites.net/',
  payoutApiUrl: 'https://payoutapiuat.azurewebsites.net/'
};

export const environmentDefault: IEnvironment = {
  spoofUser: '',

  production: false,
  uat: false,
  environment: 'UAT',
  serviceBaseUrl: endpoints.serviceBaseUrl,
  mintapiBaseUrl: endpoints.mintApiBaseUrl,
  mintapiDetailBaseUrl: endpoints.mintApiDetailBaseUrl,
  revenueserviceBaseUrl: endpoints.revenueserviceBaseUrl,
  demoapiBaseUrl: endpoints.demoApiBaseUrl,
  demoAppUrl: 'https://mint-demo.microsoft.com',
  bonusApiUrl: endpoints.bonusApiUrl,
  payoutApiUrl: endpoints.payoutApiUrl,
  appInsightsKey: '57772889-e936-4ff6-a17f-d4c2d852ce96',
  appInsightsEndpoint: 'https://dc.services.visualstudio.com/v2/track',
  utpResourceId: 'b2406a33-9114-4fa4-b366-ff1935cc625f',
  aadResource: aadResource,
  aadClientId: clientId,
  aadInstance: aadInstance,
  aadTenant: aadTenant,
  isDemoMode2: false,
  enableISA: true,
  ocvEnvironment: 1,
  isOcvEnabled: true,
  isUserVoiceEnabled: true,
  isPageTourEnabled: true,
  isBotEnabled: true,
  isServiceNowEnabled: true,
  enablePWA: false,
  ocvAppId: 2461,
  adobeOriginUri: 'https://secure.na3.adobesign.com',
  // config for page tour
  pageTourConfig: {
    resourceId: 'cbd1fcd7-3769-4123-8ac9-cb51188ec3ba',
    apiUrl: 'https://pagetourserviceuatwest.azurewebsites.net/api/v1/',
    tenantId: '7c42b380-d7d7-4c67-95f0-a43b8ede2f3b'
  },
  // Configuration for authentication
  authConfig: {
    tenant: aadTenant,
    clientId: clientId,
    instance: aadInstance,
    extraQueryParameter: 'nux=1',
    popUp: false,
    navigateToLoginRequestUrl: true,
    cacheLocation: 'sessionStorage',
    endpoints: {
      [endpoints.serviceBaseUrl]: aadResource,
      [endpoints.mintApiBaseUrl]: aadResource,
      [endpoints.mintApiDetailBaseUrl]: aadResource,
      [endpoints.revenueserviceBaseUrl]: aadResource,
      [endpoints.demoApiBaseUrl]: aadResource
    }
  },
  corpUrlMessage: '',
  showMintInsider: true,
  graphResource: 'https://graph.microsoft.com',
  graphDomain: 'microsoft.com',
  supportHref: 'https://aka.ms/wwichelp',
  redirectHomeUrl: 'https://aka.ms/mint',
  showAccount360: true,
  useUserVoice: true,
  useTelemetryCxtForUserAlias: true,
  appBaseUrl: 'mint.microsoft.com',
  showVideoInPopup: true,
  complianceRedirectCorpUrl: false,
  corpBaseUrl: 'https://mint.microsoft.com',
  showBonusPayoutInfo: true,
  isHardTransferFeatureEnabled: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
