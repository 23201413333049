<div id="dashboard_11" class="dashboard__container">
  <div class="notification-alert warning bannerNotification" id="azure_banner" *ngIf="sharedContext.isACREligible">
    <div id="dashboard_47" class="more bannerNotification-label-normal">
      <b>
        You're eligible for the Accelerate Azure incentive!
        <a role="button" tabindex="0" (click)="navigation.navigateTo('accelerateAzure', null, null)"
          (keyup.enter)="navigation.navigateTo('accelerateAzure', null, null)" analytics-tracker
          [tracker]="'Accelerate_Azure_View'" [type]="'Click'" [element]="'HyperLink'" [source]="'DashboardV2'">
          Track your progress here.
        </a></b>
    </div>
  </div>
  <div *ngIf="!toggleEstimator" id="dashboard_12" class="dashboard__header" style="padding: 0 17px;">
    <div id="dashboard_19" class="dashboard__feedback ">
      <app-tour id="dashboard_tour" [tourName]="'Tour the dashboard'"></app-tour>
    </div>
    <div style="margin-left: auto;">
      <mint-contextual-video [featureId]="'65'"> </mint-contextual-video>
    </div>
  </div>
  <div id="dashboard_22" class="content-wrapper">
    <div id="dashboard_23" class="stats stats-dashboard" *ngIf="!showContentLoaderTile">
      <app-metric-tile id="dashboard_24" [componentId]="'dashboard_24'" [tileData]="ytdPaymentKPI" class="stat"
        *mintPayoutUnavailable> </app-metric-tile>
      <app-metric-tile id="dashboard_25" [componentId]="'dashboard_25'" [tileData]="projectedPaymentKPI" class="stat"
        *mintPayoutUnavailable> </app-metric-tile>
      <app-metric-tile id="dashboard_26" [componentId]="'dashboard_26'" [tileData]="weightedAttainmentKPI" class="stat">
      </app-metric-tile>
    </div>
    <div class="mint_tile_loader" *ngIf="showContentLoaderTile">
      <mint-content-loader-icon-text></mint-content-loader-icon-text>
    </div>

    <div id="dashboard_27" class="app__tile">
      <div id="dashboard_28" class="app__tile__header">
        <div id="dashboard_29" class="app__tile__heading">Year-to-date Performance by Bucket</div>
        <div *ngIf="!toggleEstimator">
          <app-tour [tourName]="'Attainment by metric tour'" (tourClick)="playTour($event)"></app-tour>
          <mint-contextual-video [featureId]="'66'"> </mint-contextual-video>
        </div>

        <a id="dashboard_30" class="app__tile__report" role="button" tabindex="0" (keyup.enter)="viewReport(0)"
          (click)="viewReport(0)" analytics-tracker [tracker]="'Widget_Action_ViewReportV2_Attainment'" [type]="'Click'"
          [element]="'Hyperlink'" [source]="'Widget_Header_More_DropdownV2'" *ngIf="!toggleEstimator">
          View detailed report
        </a>
        <!-- <a
          id="dashboard_30"
          class="app__tile__report"
          role="button"
          tabindex="0"
          (keyup.enter)="viewEstimator()"
          (click)="viewEstimator()"
          analytics-tracker
          [tracker]="'Widget_Action_ViewEstimator_From_Bucket_Estimator'"
          [type]="'Click'"
          [element]="'Hyperlink'"
          [source]="'Bucket_Estimation'"
          *ngIf="toggleEstimator"
        >
          Go To My Estimator
        </a> -->
      </div>
      <div id="Bucket_estimation_toggleSwitch1" class="toggle__btn__container" style="padding-top: 15px;"
        *ngIf="isBucketEstimatorEnabled">
        <div id="Bucket_estimation_toggleSwitch2" class="toggle__btn__title" *ngIf="estimatorEligibleForQuarter">Enable
          Estimator for {{ CurrentQuarter }}</div>
        <div id="Bucket_estimation_toggleSwitch2" class="toggle__btn__title" style="color: #cccccc;"
          *ngIf="!estimatorEligibleForQuarter">Enable Estimator for {{ CurrentQuarter }}</div>
        <!-- <mint-tooltip [id]="'AvailableHours'" [tipText]="bucketEstimationTooltip" [id]="'bucket_Estimation_Tooltip'" [orientation]="'right'"></mint-tooltip> -->
        <div id="Bucket_estimation_toggleSwitch_btn" class="toggle__btn">
          <input style="display: none;" class="toggle__btn__inp" [disabled]="!estimatorEligibleForQuarter"
            type="checkbox" name="toggleSwitch__Enable_bucket_Estimation" id="toggleSwitch__Enable_bucket_Estimation"
            analytics-tracker [tracker]="'Enable_bucket_Estimation'" [type]="'Click'" [element]="'input'"
            [source]="'Bucket_Estimation'" [checked]="isChecked" (click)="toggleBucketEstimation()"
            (keyup.enter)="toggleBucketEstimation()" (keyup.space)="toggleBucketEstimation()" />
          <label id="toggleSwitch50" class="toggle__btn__lbl" for="toggleSwitch__Enable_bucket_Estimation"></label>
          <div class="estimator_tours">
            <app-tour [tourName]="'Tour the new Estimator'" (tourClick)="playTour($event)"></app-tour>
            <mint-contextual-video *ngIf="toggleEstimator" [featureId]="'76'"> </mint-contextual-video>
          </div>
        </div>
      </div>
      <div class="app__nav app__nav-views" id="dashboard_31" *ngIf="!toggleEstimator">
        <mint-tabs [tabs]="chartGridViewTab" (tabChange)="onTabChange($event)" [activeId]="activeTabId"
          [tracker]="'DashboardV2_ChartGridView'" [source]="'DashboardV2'"></mint-tabs>
      </div>
      <div *ngIf="!toggleEstimator">
        <app-metric-pcg-block id="dashboard_43" *ngIf="!gridView" [metricCardConfig]="metricCardConfig"
          [chartType]="chartType" [containerId]="'ytd_bucket'"></app-metric-pcg-block>
        <app-metric-grid id="dashboard_44" *ngIf="gridView" [gridLabel]="'Year to date Performance by Bucket'">
        </app-metric-grid>
      </div>
      <mint-bucket-estimator *ngIf="estimatorEligibleForQuarter && toggleEstimator && weightedAttainmentKPI"
        [weightedAttainmentKPI]="weightedAttainmentKPI" [projectedPaymentKPI]="projectedPaymentKPI">
      </mint-bucket-estimator>
    </div>

    <app-detail-summary-tiles id="dashboard_46" *ngIf="!isDLTRDisabled"></app-detail-summary-tiles>
    <app-incentive-summary-tiles *ngIf="useNewPayoutAPI" class="incentive__history" id="dashboard_47" [tabId]="1"
      [payoutSummary]="payoutSummaryResult"></app-incentive-summary-tiles>
    <app-incentive-summary-tiles-old *ngIf="!useNewPayoutAPI" class="incentive__history" id="dashboard_48" [tabId]="1">
    </app-incentive-summary-tiles-old>
  </div>
</div>