import { BonusActions, BonusActionTypes } from './bonus.actions';
import { plainToClass } from 'class-transformer';
import { BonusConfig, BonusDocumentConfig } from '../models/bonus-config.model';
import { BonusState } from '../models/bonus-state.model';

export const bonusFeatureKey = 'bonus';

const initialState: BonusState = new BonusState();

export function bonusReducer(state = initialState, action: BonusActions): BonusState {
  switch (action.type) {
    case BonusActionTypes.LoadBonusConfig:
      return new BonusState({ bonusConfig: action.payload.bonusConfig, isConfigFetched: true });

    case BonusActionTypes.SelectBonus:
      return state.setSelectedBonus(action.payload.id);

    case BonusActionTypes.ClearSelectedBonus:
      return state.clearSelectedBonus();
      
    case BonusActionTypes.SignalEmptyData:
      return new BonusState();

    case BonusActionTypes.AddBonusData:
      return state.addBonusData(action.payload);

    default:
      return state;
  }
}
