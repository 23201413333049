import { Inject, Injectable } from '@angular/core';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { LoggerService } from '@mint-libs/logging';
import { AuthorizationService } from '../authorization/authorization.service';
import { UserProfileService } from '../context/user-profile.service';
import { GraphService } from '@mint-libs/context';
import { DemoContext } from '../context/demo.context';
import { environment } from '../../../environments/environment';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '@mint-libs/context';
import { NavigationService } from '../navigation/navigation.service';
import { from, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
// import { UserSessionState } from "../initialization/state/initialization.reducer";
// import { LoadFilterState, SaveFilterStateAndReset } from "../session/state/user-session-actions";
import { UserSessionService } from '../session/user-session-service';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {
  sharedContext: SharedContext = new SharedContext();
  userInfoList: any = [];
  constructor(
    private authorizationService: AuthorizationService,
    public sharedContextState: Store<SharedContext>,
    private userProfileService: UserProfileService,
    private loggingService: LoggerService,
    private graphService: GraphService,
    private demoContext: DemoContext,
    private endpointService: EndpointService,
    private dataService: DataService,
    private navigationService: NavigationService,
    private userSessionService: UserSessionService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  // public trackName = 'impersonation';
  // public fileName = 'impersonationListWeb';

  // public isImpersonating = false;
  // public isViewAs = false;
  // public impersonatingAlias: null | string = null;
  // public impersonatedParticipant: null | string = null;
  // public impersonatedParticipantInitials: null | string = null;
  // public impersonationBeingStopped = false;
  // public loggedInUser: null | string = null;
  // public isNameAvailable = false;
  // public users = [];
  // public showFCAQuotaApprovalPendingError = false;
  // public fcaQuotaApprovalPendingButAllowAccess = false;
  // public alias: string = '';

  public startImpersonation(alias, name, skipAuth, participationid, personnelNumber, fiscalYear, viewAsEnabled = false): Promise<any> {
    if (this.sharedContext.skipAuthorization || skipAuth) {
      console.warn('Skipped Authorization for impersonation');
      return this.doImpersonation(alias, name, skipAuth, viewAsEnabled);
    } else {
      return this.authorizationService
        .authorizeImpersonation(alias, 'token', participationid, personnelNumber, fiscalYear)
        .toPromise()
        .then((result: any) => {
          if (result.IsAuthorized) {
            return this.doImpersonation(alias, name, true, viewAsEnabled)
              .then(
                response => {
                  return Promise.resolve(response);
                },
                error => {
                  return Promise.reject(error);
                }
              )
              .finally(() => {
                this.setFCAQuotaApprovalPendingFlag(result.ErrorCause);
                return Promise.resolve();
                // this.loggingService.stopTrackPage(this.sharedContext.trackName);
              });
          } else if (this.sharedContext.IsFCA && result.IsAuthorized) {
            return Promise.resolve();
            // deferred.reject();
          } else {
            console.error('Authorization failed for impersonation. Navigating to error page');

            this.loggingService.logInformation({ status: 'unauthorized', impersonatingUser: alias });
            this.loggingService.stopTrackPage(this.sharedContext.trackName);

            this.navigationService.navigateTo(['error', 'unauthorized']);
            // NavigationFactory.navigateTo('errorState.unauthorized', result);
            this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, toggleView: 1 }));
            return Promise.resolve();
          }
        });
    }
  }

  public doImpersonation(alias, name, skipAuthorization, viewAsEnabled): Promise<any> {
    this.userSessionService.saveFilterState();
    this.userSessionService.resetFilters();

    // TODO: NavigationFactory.resetSidebar();

    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        isImpersonating: !viewAsEnabled,
        isViewAs: viewAsEnabled,
        usageMode: {
          ...this.sharedContext.usageMode,
          impersonation: !viewAsEnabled,
          viewAs: viewAsEnabled
        },
        loggedInUser: this.sharedContext.firstName + ' ' + this.sharedContext.lastName,
        isNameAvailable: !!this.sharedContext.firstName || !!this.sharedContext.lastName,
        impersonatingAlias: alias,
        impersonatedParticipant: name
      })
    );
    this.loggingService.logInformation({ impersonatingAlias: alias, impersonatedParticipant: name });
    return this.userSessionService
      .initialize(
        skipAuthorization && !viewAsEnabled,
        (this.sharedContext.loggedInUserInfo.isFCA === true || this.sharedContext.loggedInUserInfo.isSupportAdmin === true) && !this.sharedContext.isDemoMode ? false : true,
        false,
        false,
        false,
        false
      )
      .then(response => {
        this.sharedContextState.dispatch(
          new UpdateSharedContext({
            ...this.sharedContext,
            toggleView: this.sharedContext.IsNonParticipantManager ? 1 : 0
          })
        );
        this.userSessionService.refresh(true);
        // this.userProfileService.init();
      });
  }

  public loadParticipantDetails() {
    var uri = this.endpointService.RetreiveAllParticipants.uri;
    return this.dataService.get(environment.mintapiBaseUrl + uri);
  }

  public stopImpersonation(skipAuthorization) {
    if (this.sharedContext.isDemoMode) {
      this.demoContext.selectedDemoRole.value = this.demoContext.selectedDemoRole.value + 'Manager';
    }
    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        isImpersonating: false,
        isViewAs: false,
        usageMode: {
          ...this.sharedContext.usageMode,
          impersonation: false,
          viewAs: false
        },
        impersonatingAlias: null,
        impersonationBeingStopped: true,
        impersonatedParticipant: null
      })
    );
    // this.sharedContext.isImpersonating = false;
    // this.sharedContext.isViewAs = false;
    // this.sharedContext.usageMode.impersonation = this.sharedContext.isImpersonating;
    // this.sharedContext.usageMode.viewAs = this.sharedContext.isViewAs;
    // this.sharedContext.impersonatingAlias = null;
    // this.sharedContext.impersonationBeingStopped = true;
    // this.sharedContext.impersonatedParticipant = null;
    this.userSessionService.loadOldFilterState();

    this.navigationService.navigateTo('/', {}, true);
    // this.userSessionService
    //   .initialize(
    //     skipAuthorization,
    //     (this.sharedContext.loggedInUserInfo.isFCA === true || this.sharedContext.loggedInUserInfo.isSupportAdmin === true) && !this.sharedContext.isDemoMode ? false : true,
    //     false,
    //     true
    //   )
    //   .then(response => {
    //     // $rootScope.$broadcast('isRefreshBannerNotif');

    //     if (this.sharedContext.isAdmin || this.sharedContext.loggedInUserInfo.isFCA) {
    //       //  $state.reload();
    //     } else {
    //       this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, toggleView: 1 }));
    //       //  $state.reload();
    //     }
    //     this.navigationService.navigateTo('/', {}, true);
    //     // NavigationFactory.navigateTo('login');
    //   });
  }

  public deleteAliasHistory() {
    localStorage.remove(this.sharedContext.fileName);
  }

  public readFileOrCreateIfNotExists() {
    var userList = [];
    var fileContent = localStorage.get(this.sharedContext.fileName);
    if ((fileContent || fileContent == null || fileContent == undefined) && fileContent.length > 0) {
      userList = JSON.parse(fileContent);
    }
    return userList;
  }

  // public addUserToList(alias, fullName, nameInitials, incentiveType) {
  //   if (alias != null && alias.startsWith('Test')) {
  //     return;
  //   }

  //   this.readFileAndDontCreateIfNotExists().then((response) => {
  //     var listToShow = response;
  //     var aliasList: string[] = [];
  //     var user = {};
  //     for (const a of listToShow) {
  //       aliasList.push(a.alias);
  //     }
  //     var doesExist = aliasList.includes(this.sharedContext.alias);
  //     if (!doesExist) {
  //       if (listToShow.length === 10) {
  //         listToShow.pop();
  //       }
  //       this.graphService.getThumbnailPhoto(alias).toPromise().then(
  //         (data) => {
  //           user = {
  //             alias: alias,
  //             thumbnailPhoto: data,
  //             fullName: fullName,
  //             nameInitials: nameInitials,
  //             incentiveType: incentiveType
  //           };
  //           listToShow.unshift(user);
  //           this.writeUserObjectToLocalFile(listToShow);
  //           ;
  //         },
  //         (error) => {
  //           user = {
  //             alias: alias,
  //             thumbnailPhoto: null,
  //             fullName: fullName,
  //             nameInitials: nameInitials,
  //             incentiveType: incentiveType
  //           };
  //           listToShow.unshift(user);
  //           this.writeUserObjectToLocalFile(listToShow);
  //           return new Promise(() => listToShow);
  //         }
  //       );
  //     } else {
  //       var index = aliasList.indexOf(alias);
  //       user = listToShow[index];
  //       listToShow.splice(index, 1);
  //       listToShow.unshift(user);
  //       this.writeUserObjectToLocalFile(listToShow);
  //       new Promise(() => listToShow);
  //     }
  //   });
  // }

  public setFCAQuotaApprovalPendingFlag(errorCause) {
    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        showFCAQuotaApprovalPendingError: errorCause === 'FCAQuotaApprovalPendingButAllowAccess' && !this.sharedContext.IsNonParticipantManager
      })
    );
  }

  public isImpersonatingOrViewAs() {
    return this.sharedContext.isImpersonating || this.sharedContext.isViewAs;
  }

  public isImpersonating() {
    return this.sharedContext.isImpersonating;
  }

  public isViewAs() {
    return this.sharedContext.isViewAs;
  }
}
