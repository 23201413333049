import { ITooltipTextGrid } from './tooltip-text-grid';

export class TooltipTextGridPPICustomerAdds implements ITooltipTextGrid {
  Attainment = `Actuals/Target. This reflects YTD actuals against H1 or full-year target, depending on the time period selection in the upper right-hand corner of the dashboard.`;
  YTDQuota = `Target for H1 or full-year, as per the time period selection in the upper right-hand corner of the dashboard.`;
  YTDActuals = `Actuals for H1 or full-year, as per the time period selection in the upper right-hand corner of the dashboard.`;
  QuotaToGo = `Target Remaining = YTD Target less YTD Actuals. Meet target to not be negatively impacted in your final payout.
  Exceed target to receive an increase in your final payout amount by up to 15% across all solution areas.`;
  PayoutImpact = `Your final payout percent will be adjusted up or down by the percent indicated, depending on the attainment of customer adds for the given solution area.
  The payout adjustment is applied after the payout percent based on performance in Core Buckets is determined. Refer to the Payout % tile to see the potential range of impact on your final payout.`;
}
