import { Component, Inject, OnInit } from '@angular/core';
import { BaseRevenueComponent } from '../base-revenue.component';
import { ReportType } from '../report-type.enum';
import { ReportsService } from '../reports.service';
import { MessagesService } from '@mint-libs/context';
import { Formatter } from '@mint-libs/common';
import { Code } from '../../app.constants';
import { RevenueProduct } from '../reports-shared/models/revenue-product.model';
import { ReportGrid } from '../reports-shared/models/report-grid.model';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { ExportToExcelService } from '../../shared/excel-export/export-to-excel.service';
import { FiltersService } from '../../filters/filters.service';
import { LoggerService } from '@mint-libs/logging';

@Component({
  selector: 'mint-product-report',
  templateUrl: './product-report.component.html',
  styleUrls: ['./product-report.component.scss'],
  providers: [Code]
})
export class ProductReportComponent extends BaseRevenueComponent implements OnInit {
  columns: ReportGrid[] = [
    {
      field: 'name',
      columnName: 'Product Name',
      width: 180,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'pcgName',
      columnName: 'Bucket Name',
      width: 200,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'pccName',
      columnName: 'Metric Name',
      width: 180,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.month',
      format: '{0: MMMM, yyyy}',
      columnName: 'Fiscal Month',
      width: 150,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.actualAmount',
      columnName: 'Actual',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.actualAdjustmentAmount',
      columnName: 'Bulk Actuals Adjustment',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.actualModifier',
      columnName: 'Actual Modifier',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.RNYB',
      columnName: 'Reserved Actual',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.totalActual',
      columnName: 'Total Actual',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.metric',
      columnName: 'Unit',
      width: 120,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    }
  ];
  revenueData: RevenueProduct[] = [];
  constructor(
    public reportService: ReportsService,
    private formatter: Formatter,
    public messageService: MessagesService,
    public exportToExcelService: ExportToExcelService,
    public loggingService: LoggerService,
    public filterService: FiltersService
  ) {
    super(reportService, messageService, filterService);
    this.revenueReportType = ReportType.ProductReport;
  }

  ngOnInit() {
    this.loggingService.trackPageView('Report-Revenue By Products');
    this.initPayoutDisclaimer();
    this.initTargetSummary(this.removeDynamicsPcc);
    this.initDetailedProducts();
  }

  private initDetailedProducts() {
    this.reportService.getDetailedProducts().subscribe(response => {
      this.revenueData = new RevenueProduct().deserialize(response?.data);
      this.initPccChart(this.PCCs, this.selectedPccChart, this.revenueReportType);
    });
  }

  public initRevenueChart(revenueData: RevenueProduct[]) {
    this.chartDataRevenue = [];
    const groupedByDimNameData = this.groupBy(revenueData, (r: RevenueProduct) => r.name);
    for (const dimName in groupedByDimNameData) {
      const revenueBarChart = {
        desc: dimName,
        val: 0
      };
      groupedByDimNameData[dimName].forEach(r => (revenueBarChart.val += r.revenue.totalActual));
      this.chartDataRevenue.push(revenueBarChart);
    }
    this.chartDataRevenue = this.chartDataRevenue.sort((a, b) => b.val - a.val);
  }

  public filterSelectedPcc(selectedPccChart) {
    this.selectedPccChart = selectedPccChart;
    let revenueDataFilteredByPcc = this.revenueData.filter(revenue => revenue.pccID === selectedPccChart?.id);
    if (selectedPccChart.isPointInTime) {
      revenueDataFilteredByPcc = revenueDataFilteredByPcc.filter(revenue => this.formatter.transformToDateFormat(revenue.revenue.month, 'MMMM, yyyy', 'en-US').split(',')[0] === this.getPITMonth());
    }
    this.initRevenueChart(revenueDataFilteredByPcc);
  }

  onRevenueExcelExport(postBody) {
    this.revenueExcelConfigs.fileName = this.exportToExcelService.getFileName('CompActualsByProduct', true);
    const $result = this.reportService.exportRevenueProductDetail(postBody);

    this.exportToExcelService.handleExcelDownload($result, this.revenueExcelConfigs);
  }
}
