<div id="detail-summary-tiles_1" class="app__tile">
  <div id="detail-summary-tiles_2" class="app__tile__header">
    <div id="detail-summary-tiles_3" class="app__tile__heading">YTD Performance by Product, Incentive Territories, and
      Account</div>
    <app-tour id="detail-summary-tiles_tour" [tourName]="'Performance by Product and Territory'"></app-tour>
    <mint-contextual-video [featureId]="'67'"> </mint-contextual-video>
    <a id="detail-summary-tiles_4" class="app__tile__report" role="button" tabindex="0"
      (keyup.enter)="viewReport(selectedTab + 2)" (click)="viewReport(selectedTab + 2)" analytics-tracker
      [tracker]="'Widget_Action_ViewReport_Product_Territory'" [type]="'Click'" [element]="'Hyperlink'"
      [source]="'Widget_Header_More_DropdownV2'">
      <!-- <div class="detailSummarySection__report" (click)="viewReport1(2)" analytics-tracker [tracker]="'Widget_Action_ViewReport'"
    [type]="'Click'" [element]="'Hyperlink'" [source]="'Widget_Header_More_DropdownV2'"> -->
      View detailed report
    </a>
  </div>
  <div class="tab__content__subhead pt-4" *ngIf="this.filterService.selectedStandardTitle.FiscalYear.Key < 2024">
    Please note: Target and actuals received by MINT don’t contain account-level data for the UCR metric. To add the
    target and actuals, MINT has functionality to place these values against some accounts, which are randomly selected.
    Please rest assured that despite this, the total target and actuals are accurate, and your attainment and payout are
    correctly calculated.
  </div>
  <div id="detail-summary-tiles_5" class="app__nav">
    <mint-tabs [tabs]="detailSummaryTabs" (tabChange)="onTabChange($event)"
      [activeId]="detailSummaryTabs[selectedTab].id" [tracker]="'DetailReportsTab'" [source]="'DashboardV2'">
    </mint-tabs>
    <div id="detail-summary-tiles_8">
      <mint-products-summary id="detail-summary-tiles_9" *ngIf="selectedTab === 0"></mint-products-summary>
      <mint-territory-summary id="detail-summary-tiles_10" *ngIf="selectedTab === 1" [loadDataForAcr]="false"
        [detailReportParams]="detailReportParamsFromDashboard" [containerId]="'ds_territory_chart'">
      </mint-territory-summary>
      <mint-account-summary id="detail-summary-tiles_11" *ngIf="selectedTab === 2"></mint-account-summary>
    </div>
  </div>
</div>