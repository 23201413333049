const RbiEstimatorCampaign: object = {
  Activity: 'OnNewEstimatorToggle',
  CampaignId: '97dfeaf7-7144-44c8-8ced-f8a689a6bdf2', // Assigned by OCV
  StartTimeUtc: '2021-01-20T00:00:00Z', // If null, the campaign is inactive
  EndTimeUtc: '2021-04-01T00:00:00Z',
  GovernedChannelType: 0, // 1: Urgent Channel; 0 - Recommended for Production as it has channel cooldown
  NominationScheme: {
    Type: 0, // Default
    PercentageNumerator: 100, // Ratio of users to be nominated to be a candidate numerator
    PercentageDenominator: 100, // Ratio of users to be nominated to be a candidate denominator
    NominationPeriod: {
      Type: 0, // Default
      IntervalSeconds: 180 // NOT used when ratio is 100%
    },
    CooldownPeriod: {
      Type: 0, // Default
      IntervalSeconds: 1296000 // 15 days
    },
    FallbackSurveyDurationSeconds: 180
  },
  SurveyTemplate: {
    Type: 2, // NPS Template, can be NPS 5pts (0), NPS 11pts (1), FPS (2), NLQs (3), NPS (4), or GenericMessaging Survey (22)
    ActivationEvent: {
      Type: 0, // 0 For one event, 1 for multiple events (array of events)
      Activity: 'OnNewEstimatorToggle',
      Count: 1,
      IsAggregate: true
    },
    Content: {
      Prompt: {
        Title: "We'd love your feedback on the new Estimator in Mint!",
        Question: 'We have two questions for you.',
        YesLabel: 'Yes',
        NoLabel: 'No'
      },
      Rating: {
        IsZeroBased: false,
        Question: 'How satisfied are you with the new experience of Estimator in the Dashboard view?',
        RatingValuesAscending: ['1 = Very dissatisfied', '2 = Dissatisfied', '3 = Neutral', '4 = Satisfied', '5 = Very satisfied']
      },
      Question: { Question: 'Please tell us why you chose that rating.' }
    }
  }
};
export default RbiEstimatorCampaign;
