<div id="employee-info_1" class="app__tile">
  <div id="employeeInformation_1" [hidden]="isRoleInformationError">
    <div id="employee-info_2" class="app__tile__header">
      <div class="app__tile__heading" id="employeeInformation_3">Employee Information</div>
      <div style="display:flex; margin-right: auto;">
        <app-tour [tourName]="tourName" id="employee-info_10" style="margin-right:auto" *ngIf="enableTour && !hideTour">
        </app-tour>
        <mint-contextual-video [featureId]="'70'" *ngIf="this.sharedContext.incentiveType == 'RBI'">
        </mint-contextual-video>
      </div>
      <div id="employee-info_3" class="hr__date" *ngIf="hrLastRefreshDate && !isOptoutView">
        Employee Data Last Refresh: {{ hrLastRefreshDate }}
        <div id="employee-info_4" class="hr__date__info">
          <button id="employee-info_5" class="icon icon-info tooltip__trigger" aria-label="info"
            [attr.aria-describedby]="'info button'"></button>
          <div id="employee-info_6" class="stat__tooltip">
            <p id="employee-info_7" class="stat__tooltip__text">
              This date shows the last date the information was refreshed from upstream HR systems such as Manager
              Self-Service (MSS) or Employee Central (EC). Changes made by your manager in these
              tools can take up to 72 hours to reflect, which is then consumed by Mint on a daily basis. There are
              periods of time where data refreshes are paused, which is communicated to you via a
              notification on your dashboard.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="emp__info" id="employeeInformation_4" *ngIf="loaded">
      <div class="emp__fig" id="employeeInformation_5">
        <ngx-avatar id="employeeInformation_6 + {{ employeeInfo?.getUserName() }}"
          name="{{ employeeInfo?.getUserName() }}" initialsSize="2" size="120" [round]="true" alt="Employee Pic"
          src="{{ thumbnailPhoto }}" textSizeRatio="2"></ngx-avatar>
      </div>
      <div class="emp__desc" id="employeeInformation_7" *ngIf="!isOptoutView">
        <div class="emp__name" id="employeeInformation_8">
          <span id="employeeInformation_42">{{ userNameWithRole }}</span>
        </div>
        <div class="emp__details" id="employeeInformation_9">
          <div class="emp__detail" id="employeeInformation_10">
            <div class="emp__detail__desc" id="employeeInformation_11">Personnel Number</div>
            <div class="emp__detail__val" id="employeeInformation_12">{{ employeeInfo?.personnelNumber }}</div>
          </div>
          <div class="emp__detail" id="employeeInformation_13">
            <div class="emp__detail__desc" id="employeeInformation_14">Reports To</div>
            <div class="emp__detail__val" id="employeeInformation_15">{{ employeeInfo?.getManagerName() }}</div>
          </div>
          <div class="emp__detail" id="employeeInformation_16">
            <div class="emp__detail__desc" id="employeeInformation_17">Role Duration</div>
            <div class="emp__detail__val" id="employeeInformation_18">{{ employeeInfo?.getRoleDuration() }}</div>
          </div>
          <div class="emp__detail" id="employeeInformation_19">
            <div class="emp__detail__desc" id="employeeInformation_20">Plan</div>
            <div class="emp__detail__val" id="employeeInformation_21">{{ employeeInfo?.planName }}</div>
          </div>
        </div>
        <div class="emp__details" id="employeeInformation_25">
          <div class="emp__detail" id="employeeInformation_29">
            <div class="emp__detail__desc" id="employeeInformation_30">Org</div>
            <div class="emp__detail__val" id="employeeInformation_31">{{ employeeInfo?.organizationName }}</div>
          </div>
          <div class="emp__detail" id="employeeInformation_32">
            <div class="emp__detail__desc" id="employeeInformation_33">Qualifier 1</div>
            <div class="emp__detail__val" id="employeeInformation_34">{{ employeeInfo?.qualifier1Name }}</div>
          </div>
          <div class="emp__detail" id="employeeInformation_35">
            <div class="emp__detail__desc" id="employeeInformation_36">Qualifier 2</div>
            <div class="emp__detail__val" id="employeeInformation_37">{{ employeeInfo?.qualifier2Name }}</div>
          </div>
          <div class="emp__detail" id="employeeInformation_22">
            <div class="emp__detail__desc" id="employeeInformation_23">Incentive Plan</div>
            <div class="emp__detail__val" id="employeeInformation_24">{{ employeeInfo?.incentiveType }}</div>
          </div>
        </div>
      </div>
      <div class="emp__desc" id="employeeInformation_7" *ngIf="isOptoutView">
        <div class="emp__name" id="employeeInformation_8">
          <span id="employeeInformation_42">{{ userNameWithRole }}</span>
        </div>
        <div class="emp__details" id="employeeInformation_9">
          <div class="emp__detail" id="employeeInformation_10">
            <div class="emp__detail__desc" id="employeeInformation_11">Personnel Number</div>
            <div class="emp__detail__val" id="employeeInformation_12">{{ employeeInfo?.personnelNumber }}</div>
          </div>
          <div class="emp__detail" id="employeeInformation_22">
            <div class="emp__detail__desc" id="employeeInformation_23">Incentive Plan</div>
            <div class="emp__detail__val" id="employeeInformation_24">{{ employeeInfo?.incentiveType }}</div>
          </div>
          <div class="emp__detail" id="employeeInformation_13">
            <div class="emp__detail__desc" id="employeeInformation_14">Country</div>
            <div class="emp__detail__val" id="employeeInformation_15">{{ employeeCountry }}</div>
          </div>
          <div class="emp__detail" id="employeeInformation_19">
            <div class="emp__detail__desc" id="employeeInformation_20">Plan</div>
            <div class="emp__detail__val" id="employeeInformation_21">{{ employeeInfo?.planName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="employee-info_8" *ngIf="!loaded" class="content__loader">
      <facebook-content-loader id="employee-info_9" [height]="150" [width]="1600"></facebook-content-loader>
    </div>
  </div>
  <div id="employeeInformation_41" class="widget-error-container" [hidden]="!isRoleInformationError">{{ errorMessage }}
  </div>
</div>