import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TBonusSummary } from '@mint-libs/context';

@Component({
  selector: 'mint-bonus-summary-header',
  templateUrl: './bonus-summary-header.component.html',
  styleUrls: ['./bonus-summary-header.component.scss']
})
export class BonusSummaryHeaderComponent implements OnInit, OnChanges {
  @Input()
  bonusSummary: TBonusSummary;
  
  bucketSummaryEnabled: boolean = false;
  awardSummaryEnabled: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.bonusSummary.configuration && this.bonusSummary.configuration.bucketsSummary) {
      this.bucketSummaryEnabled = this.bonusSummary.configuration.bucketsSummary.isEnabled;
    }

    if (this.bonusSummary.configuration && this.bonusSummary.configuration.awardsSummary) {
      this.awardSummaryEnabled = this.bonusSummary.configuration.awardsSummary.isEnabled;
    }
  }

}
