import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'mint-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnChanges {
  /** The unique and alphabetically sorted common product name collection which are present in more than one PCG  */
  @Input() public commonProducts: Array<string>;

  /** The unique and alphabetically sorted product name collection */
  @Input() public otherProducts: Array<string>;

  @Input() public highlightedProductName: string;

  @Input() public pcgName: string;

  /** This event used to signal the parent component and other instances of ProductListComponent
   * about the change in Highlighted Product
   */
  @Output() notifyProductSelection: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  /** This is invoked when user clicks on 'Show Common or Hide Common' link
   * @param productName - The product name which is to be Highlighted, or emtry string when a Product needs to be Un-highlighted
   */
  toggleProductHighlight(productName: string) {
    productName !== this.highlightedProductName ? this.notifyProductSelection.emit(productName):this.notifyProductSelection.emit('');
  }

  /** This component will be initialized parallely in multiple Metric Card Components, thus to detect change in highlighted Product
   * in other instance of ProductList Component, this function is called.
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'productToBeHighlighted') {
        const change = changes[propName];
        this.highlightedProductName = change.currentValue;
      }
    }
  }
}
