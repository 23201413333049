import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mint-content-loader-double-bar-chart-single',
  templateUrl: './content-loader-double-bar-chart-single.component.html'
})
export class ContentLoaderDoubleBarChartSingleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
