

export class DirectsFilter{
roles : string[]
bucketNames: Set<String>;
weightedAttainmentRanges: string[]
bucketAttainmentRanges: string[]
attainmentRangeMap : Map<string, number[]>;

constructor(){
    this.roles = [];
    this.bucketNames= new Set<String>();
    this.attainmentRangeMap = new Map<string, number[]>();
    this.attainmentRangeMap.set('0 - 49%',[0, 49]); 
    this.attainmentRangeMap.set('50% - 99%', [50,99]);
    this.attainmentRangeMap.set('100% - 299%', [100, 299]);
    this.attainmentRangeMap.set('300%+', [300, Number.MAX_SAFE_INTEGER]);
    this.weightedAttainmentRanges = Array.from(this.attainmentRangeMap.keys());
    this.bucketAttainmentRanges = Array.from(this.attainmentRangeMap.keys());
}
}