import { Action, createReducer, on } from '@ngrx/store';
import { smallLetterKeys } from '@mint-libs/common';
import { ConfigurationActions, ConfigurationActionTypes } from './configuration.actions';
import { GetConfigData, LoadConfigurationFailure, LoadConfigurations, LoadConfigurationSuccess } from './configuration.actions';

export const configurationFeatureKey = 'configuration';

export interface ConfigurationState {
  configuration: any;
  configs: any;
  environment?: any;
  versionConfig?: any;
  pages?: any;
  features?: any;
}

export const initialState: ConfigurationState = { configuration: null, configs: null };

// const reducer = createReducer(
//   initialState,

//   on(LoadConfigurations, state => state),
//   on(LoadConfigurationSuccess, (state, action) => action.data),
//   on(GetConfigData, state => state.configs),
//   on(LoadConfigurationFailure, (state, action) => state)
// // );

// export function configurationReducer(state: ConfigurationState | undefined, action: Action) {
//   return reducer(state, action);
// }

export function configurationReducer(state = initialState, action: ConfigurationActions): ConfigurationState {
  switch (action.type) {
    case ConfigurationActionTypes.LoadConfigurations:
      return state;

    case ConfigurationActionTypes.LoadConfigurationSuccess:
      return smallLetterKeys(action.data);

    case ConfigurationActionTypes.GetConfigData:
      return state.configs;

    default:
      return state;
  }
}
