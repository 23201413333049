<div class="core-bucket-detail-tile">
  <div class="core-bucket-detail-header-title">Bucket Attainment</div>
  <div>
    <div class="core-bucket-detail-body" id="bucket-attainment-body">
      <div class="core-bucket-detail-body-section-lhsamount">{{ payoutReportService.roundOff(selectedCoreBucket.Attainment) }}%</div>

      <div>=</div>
      <div class="bucket-attainment-rhssection">
        <div class="bucket-attainment-rhs-amount">
          <div>
            <div>{{ actualsValue }}</div>
          </div>
          <mat-divider></mat-divider>
          <div>{{ targetValue }}</div>
        </div>
        <div class="bucket-attainment-subtitles">
          <div>{{ actualsLabel }}</div>
          <div>{{ targetLabel }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
