import { MetricCardConfig } from '../metric-card/metric-card-config';
import { MetricTile } from '../models/metric-tile.model';
import { Component, Inject, OnInit } from '@angular/core';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { DomSanitizer } from '@angular/platform-browser';
import { svgIcons } from '../icons.svg';
import { Formatter, PercentagePipe } from '@mint-libs/common';
import { Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { Code } from 'src/app/app.constants';
import { UserProfileService } from 'src/app/core/context/user-profile.service';
import { MessagesService } from '@mint-libs/context';
import { OcvFloodgateService } from '../../ocv-feedback/ocv-floodgate.service';
import DashboardCampaign from '../../ocv-feedback/DashboardCampaign';
import { FiltersService } from '../../filters/filters.service';
import { ImpersonationService } from 'src/app/core/impersonation/impersonation.service';
import { InitializationService } from 'src/app/core/initialization/initialization.service';
import { UserSessionService } from 'src/app/core/session/user-session-service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  template: '',
  providers: [Code]
})
export class BaseDashboardComponent implements OnInit {
  protected selectedFiscalYear: string;
  protected selectedQuarter: string;
  protected metricCardConfig: MetricCardConfig;
  public weightedAttainmentKPI: MetricTile = {};
  public projectedPaymentKPI: MetricTile = {};
  public ytdPaymentKPI: MetricTile = {};
  public showContentLoaderTile: boolean;
  filterConfig: any;
  public isMyInformationApplicable: boolean;
  sumOfYTDPayments = 0;
  svg = svgIcons;
  h2DashboardMessage: string;
  isHalfYearlyQuotaSupported = false;
  sharedContext = new SharedContext();

  constructor(
    public filterService: FiltersService,
    protected sanitizer: DomSanitizer,
    protected percentagePipe: PercentagePipe,
    protected formatter: Formatter,
    public initializationService: InitializationService,
    public impersonationService: ImpersonationService,
    public router: Router,
    public messageService: MessagesService,
    public userProfileService: UserProfileService,
    protected floodgateService: OcvFloodgateService,
    public userSessionService: UserSessionService,
    public sharedContextState: Store<SharedContext>
  ) {
    this.setFilterProperties(filterService);
    this.initializeMetricCardConfig();
    this.initiaizeFilterConfig();
    this.impersonationService = impersonationService;

    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      this.setTabApplicability();
    });
    this.messageService = messageService;

    this.floodgateService.InitializeSurveys();
    this.floodgateService.ActivateSurvey(DashboardCampaign);
  }

  ngOnInit() {}

  private setTabApplicability() {
    this.isMyInformationApplicable =
      (this.userProfileService.isSeller() ||
        (this.sharedContext.isManager && (this.sharedContext.isImpersonating || this.sharedContext.isRollOver === true) && !this.sharedContext.IsNonParticipantManagerForced)) &&
      (this.sharedContext.isImpersonating || this.sharedContext.isQuotaAcknowledged);
  }

  setFilterProperties(filterService: any) {
    this.selectedFiscalYear = 'FY' + filterService.selectedFiscalYear.toString().substring(2);
    this.selectedQuarter = 'Q' + filterService.selectedStepId;
  }

  initializeMetricCardConfig() {
    this.metricCardConfig = new MetricCardConfig();

    this.metricCardConfig.shouldShowTitle = true;
    this.metricCardConfig.shouldShowWeight = true;
    this.metricCardConfig.shouldShowAttainment = true;
    this.metricCardConfig.shouldShowEarnings = true;
  }

  setWeightedAttainmentKPI(allPaylines: any) {
    this.weightedAttainmentKPI = new MetricTile();
    this.weightedAttainmentKPI.svg = this.sanitizer.bypassSecurityTrustHtml(this.svg.iconTrendArrow);
    this.weightedAttainmentKPI.amount = this.percentagePipe.transform(allPaylines.TotalWeightedAttainment);
    this.weightedAttainmentKPI.amountTitle = this.filterService.selectedCodePeriod + ' Weighted Attainment';
    this.weightedAttainmentKPI.toolTipHeading = '';
    this.weightedAttainmentKPI.toolTipText =
      'Total Weighted Attainment is the sum of individual bucket attainment x weight.  ' +
      'Total weighted attainment is not used in calculating payout or rewards and it doesn’t include payout caps, threshold, ' +
      'or determiners used to calculate year end rewards. This number is provided solely as an information data point. ' +
      'Your actual weighted attainment used for other purposes may be higher or lower.';
  }

  /***
   * Uses new Payout Summary API's data for YTD Payout and Next Payout. Currently this is called only RBI users(Only when showBonusPayoutInfo is true and fiscalyear>=2022)(Can see this in seller-dashboard.component.ts file).
   * PPI and UBI users call setYtdPayoutAndNextPayoutKPI instead.
   */
  setYtdPayoutAndNextPayoutKPIFromNewPayoutSummaryAPI(payoutSummary: any) {
    let payoutMetricsSummary = payoutSummary.PayouMetricSummary;
    const isOptOut = this.sharedContext.optoutDetails.isOptOut;

    this.ytdPaymentKPI.svg = this.sanitizer.bypassSecurityTrustHtml(this.svg.iconDoubleTick);
    this.ytdPaymentKPI.amount = this.formatter.formatAndRoundOff(payoutMetricsSummary.YtdPayments);
    this.ytdPaymentKPI.currencyCode = payoutMetricsSummary.CurrencyCode;
    // amount Title is uses last published fiscal quarter, if nothing is published, then it displays selectedCodePeriod
    this.ytdPaymentKPI.amountTitle =
      'YTD Payments in ' + this.ytdPaymentKPI.currencyCode + ' (' + (payoutMetricsSummary.LastPublishedYtdPaymentCodePeriod ?? this.filterService.selectedCodePeriod) + ')';
    if (isOptOut) {
      this.ytdPaymentKPI.amountTitle = '(Opted out of advance payments) ' + this.ytdPaymentKPI.amountTitle;
    }

    this.projectedPaymentKPI.svg = this.sanitizer.bypassSecurityTrustHtml(this.svg.iconHourGlass);
    this.projectedPaymentKPI.amount = this.formatter.formatAndRoundOff(payoutMetricsSummary.NextProjectedPayment);
    this.projectedPaymentKPI.currencyCode = payoutMetricsSummary.CurrencyCode;
    // NextProjectedPaymentCodePeriod will be null if there is no pending payment and we are in the last quarter
    this.projectedPaymentKPI.fiscalQuarter = payoutMetricsSummary.NextProjectedPaymentCodePeriod;
    this.projectedPaymentKPI.amountTitle =
      this.projectedPaymentKPI.fiscalQuarter != null
        ? 'Next Projected Payment in ' + this.projectedPaymentKPI.currencyCode + ' (' + this.projectedPaymentKPI.fiscalQuarter + ')'
        : `No more on-cycle payments in the ${this.selectedFiscalYear}`;
  }

  setYtdPayoutAndNextPayoutKPI(payoutSummary: any) {
    this.projectedPaymentKPI = new MetricTile();
    const isOptOut = this.sharedContext.optoutDetails.isOptOut;

    this.ytdPaymentKPI.svg = this.sanitizer.bypassSecurityTrustHtml(this.svg.iconDoubleTick);
    this.projectedPaymentKPI.svg = this.sanitizer.bypassSecurityTrustHtml(this.svg.iconHourGlass);

    const publishedPayouts = payoutSummary.ApplicablePayouts.filter(payout => payout.PaymentStatus === 'Published');

    const lastPublished = publishedPayouts.length > 0 ? publishedPayouts[publishedPayouts.length - 1] : null;

    const nonPublishedPayouts = payoutSummary.ApplicablePayouts.filter(
      payout => payout.PaymentStatus === 'Not Published' && (lastPublished == null || payout.Partition.StepId > lastPublished.Partition.StepId)
    );

    const firstNonPublished = nonPublishedPayouts.length > 0 ? nonPublishedPayouts[0] : null;
    const lastNonPublished = nonPublishedPayouts.length > 0 ? nonPublishedPayouts[nonPublishedPayouts.length - 1] : null;

    const lastPayoutRecord = payoutSummary.ApplicablePayouts[payoutSummary.ApplicablePayouts.length - 1];

    if (publishedPayouts.some(x => x.IsCountryChange)) {
      const findIndex = publishedPayouts.findIndex(x => x.IsCountryChange);
      const conversionFactor = (publishedPayouts[findIndex].PayrollPayment / publishedPayouts[findIndex].NetPaymentAmount).toFixed(4);
      publishedPayouts.forEach((payout, index) => {
        if (index < findIndex) {
          this.sumOfYTDPayments += payout.PayrollPayment * Number(conversionFactor);
        } else {
          this.sumOfYTDPayments += payout.PayrollPayment;
        }
      });
    } else {
      publishedPayouts.forEach(payout => {
        this.sumOfYTDPayments += payout.PayrollPayment;
      });
    }

    this.ytdPaymentKPI.amount = '0.00';
    this.ytdPaymentKPI.amountTitle = 'No Payments are made till now in this Fiscal Year.';

    this.projectedPaymentKPI.amount = 'N/A';
    this.projectedPaymentKPI.amountUnformatted = 0;
    this.projectedPaymentKPI.amountTitle = `No more on-cycle payments in the ${this.selectedFiscalYear}`;

    if (lastPublished !== null) {
      this.ytdPaymentKPI.fiscalQuarter = '(' + lastPublished.CodePeriod + ')';
      this.ytdPaymentKPI.currencyCode = lastPublished.IsCountryChange ? lastPublished.TransferredCurrencyCode : lastPublished.CurrencyCode;
      this.ytdPaymentKPI.amountTitle = 'YTD Payments in ' + this.ytdPaymentKPI.currencyCode + ' ' + this.ytdPaymentKPI.fiscalQuarter;
      this.ytdPaymentKPI.amount = this.formatter.formatAndRoundOff(this.sumOfYTDPayments);
      if (lastPublished.IsCountryChange) {
        this.ytdPaymentKPI.amount = this.formatter.formatAndRoundOff(lastPublished.TransferredCurrencyAmount);
      }
    }

    const nextNonPublished = isOptOut ? lastNonPublished : firstNonPublished;

    if (nextNonPublished !== null) {
      this.projectedPaymentKPI.fiscalQuarter = '(' + nextNonPublished.CodePeriod + ')';
      this.projectedPaymentKPI.currencyCode = nextNonPublished.IsCountryChange ? nextNonPublished.TransferredCurrencyCode : nextNonPublished.CurrencyCode;
      this.projectedPaymentKPI.amount = this.formatter.formatAndRoundOff(nextNonPublished.NetPaymentAmount) !== '0' ? this.formatter.formatAndRoundOff(nextNonPublished.NetPaymentAmount) : '0.00';
      this.projectedPaymentKPI.amountUnformatted = nextNonPublished.NetPaymentAmount !== '0' ? nextNonPublished.NetPaymentAmount : '0.00';
      this.projectedPaymentKPI.amountTitle = 'Next Projected Payment in ' + this.projectedPaymentKPI.currencyCode + ' ' + this.projectedPaymentKPI.fiscalQuarter;
    } else {
      // TODO: to be removed once WWIC confirms that payment has been recieved by sellers
      if (lastPublished !== null) {
        this.projectedPaymentKPI.fiscalQuarter = '(' + lastPublished.CodePeriod + ')';
        this.projectedPaymentKPI.currencyCode = lastPublished.IsCountryChange ? lastPublished.TransferredCurrencyCode : lastPublished.CurrencyCode;
        this.projectedPaymentKPI.amount = this.formatter.formatAndRoundOff(lastPublished.NetPaymentAmount) !== '0' ? this.formatter.formatAndRoundOff(lastPublished.NetPaymentAmount) : '0.00';
        this.projectedPaymentKPI.amountUnformatted = lastPublished.NetPaymentAmount !== '0' ? lastPublished.NetPaymentAmount : '0.00';
      }
    }

    if (lastPublished === null) {
      this.ytdPaymentKPI.amount = '0.00';
      this.ytdPaymentKPI.amountTitle = 'YTD Payments in ';
      if (this.projectedPaymentKPI.currencyCode) {
        this.ytdPaymentKPI.amountTitle += this.projectedPaymentKPI.currencyCode;
      }
      this.ytdPaymentKPI.amountTitle += ' (' + this.filterService.selectedCodePeriod + ')';
      this.ytdPaymentKPI.currencyCode = this.projectedPaymentKPI.currencyCode;
    }

    if (isOptOut) {
      this.ytdPaymentKPI.amountTitle = '(Opted out of advance payments) ' + this.ytdPaymentKPI.amountTitle;
    }
  }

  initiaizeFilterConfig() {
    this.filterConfig = {
      isFilter: true,
      isSearch: false,
      isFiscalYear: true,
      isTitle: true,
      isPartition: true,
      showSearch: false,
      showFilter: true,
      isManagerView: false
    };
  }

  setFilterConfig(tabId: number) {
    if (tabId === 0) {
      this.filterConfig.isSearch = false;
      this.filterConfig.isManagerView = false;
      this.filterConfig.isPartition = true;
      this.filterConfig.isTitle = true;
      this.filterConfig.isFiscalYear = true;
    } else {
      // this.filterConfig.isSearch = true;
      // To be enabled later when feature is ready
      this.filterConfig.isFiscalYear = false;
      this.filterConfig.isManagerView = true;
      this.filterConfig.isPartition = false;
      this.filterConfig.isTitle = false;
    }
  }

  applyFilters(route): Observable<any> {
    const observable = from(this.userSessionService.initialize(true, true, true, true));
    observable.subscribe(result => {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
      };
      this.router.navigateByUrl(route, { skipLocationChange: true });
    });
    this.checkH2QuotaIsSupported();

    return observable;
  }

  checkH2QuotaIsSupported() {
    let fiscalYear = this.filterService.selectedFiscalYear;
    let partition = this.filterService.selectedStepId;

    if (this.filterConfig.isManagerView) {
      fiscalYear = this.filterService.selectedManagerViewFiscalYear;
      partition = this.filterService.selectedManagerViewStepId;
    }

    if (this.filterService.isH2QuotaSupported(fiscalYear)) {
      this.isHalfYearlyQuotaSupported = true;
      this.getH2Message();
    }
  }

  getH2Message() {
    this.messageService.get(Code.H2QuotaMessages).subscribe(response => {
      this.h2DashboardMessage = JSON.parse(<any>response).Dashboard[this.userProfileService.getIncentiveType()];
    });
  }
}
