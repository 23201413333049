<mint-content-loader-card-chart *ngIf="!isLoaded"></mint-content-loader-card-chart>
<div *ngIf="isLoaded" id="metric-pcg-block_1" class="chart-view__container">
  <div class="pcg__item" *ngFor="let item of ytdPCGs; let i = index" [attr.id]="'metric-pcg-block_2_' + i + item.pcg.pcgId" [ngClass]="{ 'pcg-item-center': isMultiplePccInPcg }">
    <app-metric-card
      [isYTD]="isYTD"
      [componentId]="'metric-pcg-block_3_' + i + item.pcg.pcgId"
      [id]="'metric-pcg-block_3' + i + item.pcg.pcgId"
      [cardData]="item.pcg"
      [conditionalClass]="item.pcg.attainmentStatus"
      [metricCardConfig]="metricCardConfig"
      [tooltipSize]="tooltipSize"
    >
    </app-metric-card>
    <div *ngIf="timeSpanLabel" class="time-span">
      <span>{{ '(' + timeSpanLabel + ')' }}</span>
    </div>
    <div>
      <div class="determiner__text_block" *ngIf="item.pcg.determines && item.pcg.determines.length > 0"> 
        <span class="determiner__span_block" *ngIf="item.pcg.determines && item.pcg.determines.length > 0"> Determines {{ capitlizeString(item.pcg.determines[0]) }} </span>  
      </div>

      <div class="determiner__text_block" *ngIf="item.pcg.determinings && item.pcg.determinings.length > 0"> 
        <span class="determiner__span_block"> Has Determiners
          <mint-tooltip [id]="'det_tooltip'" [tipText]="'List of Determiners'" [id]="determiner__tooltip"
        [orientation]="'right'" [size]="'small'">
        <!--Below will be transcluded in the tooltip-->
          <ng-container tip-body>
            <b><span style="font-size: 14px;"></span></b>
            <ul>
              <li *ngFor="let det of item.pcg.determinings"> {{ capitlizeString(det) }}</li>
            </ul>
          </ng-container>
          <ng-container tip-body1>
            <ul>
              <li *ngFor="let det of item.pcg.determinings"> {{ capitlizeString(det) }}</li>
            </ul>
          </ng-container>
        </mint-tooltip>
        </span>  
      </div>
      <div class="determiner__dummy_block" *ngIf="item.pcg.determinings && item.pcg.determinings.length == 0 && item.pcg.determines && item.pcg.determines.length == 0"> 
        <span class="determiner__span_block"> </span>  
      </div>
    </div>
    <mint-metric-chart
      [id]="'metric-pcg-block_4_' + i + item.pcg.pcgId"
      [dataset]="item.pcg.chartData.dataSet"
      [yAxisMaxLimit]="yScaleMax"
      [showYScale]="i === 0 ? true : false"
      class="pcg__item__chart"
      [ngClass]="item.pcg.attainmentStatus"
      [doNotShowToolTip]="isSinglePccInPcg"
      [chartHelpText]="chartHelpText"
      [periodStatus]="periodStatus"
      [descriptionText]="item.pcg.chartData.descText"
      [chartType]="chartType"
      [containerId]="containerId + '_' + i + item.pcg.pcgId"
    >
    </mint-metric-chart>
  </div>
  <div id="metric-pcg-block_5" class="pcg__item pcg__item-single" *ngIf="isSinglePccInPcg">
    <div id="metric-pcg-block_6" class="pcg__item-single__desc" [innerHTML]="ytdPCGs[0].pcg.chartData.dataSet[0].toolTipHtml"></div>
  </div>
</div>
<mint-legends *ngIf="userProfileService.isRBIPersona() && !isYTD" id="dashboard_45" [showOCPLegend]="showOCPLegend"></mint-legends>
