import { Component, OnInit, HostListener, Inject, OnDestroy } from '@angular/core';
import { environment } from '../environments/environment';
import { DeviceDetectorService, BROWSERS } from 'ngx-device-detector';
import * as _ from 'underscore';
import { Router, ActivatedRoute, ResolveEnd } from '@angular/router';
import { ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageTourService } from './shared/training/pagetour.service';
import { SidebarService } from './layout/sidebar/user-sidebar/sidebar.service';
import { Tutorial } from '@pagetour/sdk';
import { UserProfileService } from './core/context/user-profile.service';
import { NavigationService } from './core/navigation/navigation.service';
import { ConfigurationService } from '@mint-libs/configuration';
import { UserSessionService } from './core/session/user-session-service';
import { Location } from '@angular/common';
import { MsalBroadcastService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { BrowserUtils, InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '@mint-libs/authentication';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { TelemetryService } from '@mint-libs/logging';

//import { ConfigurationService } from '@mint-libs/configuration';
// import { AuthenticationService } from "@mint-libs/authentication";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  url: string;
  listOfId = [
    'header_4',
    'sidebar_pane',
    'hamburger-menu',
    'reports_sub_container',
    'reports_sub_icon',
    'comp_sub_container',
    'comp_sub_icon',
    'help_sub_container',
    'help_sub_icon',
    'bonus_sub_container',
    'bonus_sub_icon'
  ];
  @ViewChild('tourFeedback') private content;
  modalRef: any;
  tourName: any;
  tourId: any;
  private readonly _destroying$ = new Subject<void>();
  sharedContext = new SharedContext();

  constructor(
    private configuration: ConfigurationService,
    private router: Router,
    private route: ActivatedRoute,
    private deviceDetectorService: DeviceDetectorService,
    private sidebarService: SidebarService,
    private navigationService: NavigationService,
    public userProfileService: UserProfileService,
    private modalService: NgbModal,
    private pagetourService: PageTourService,
    private userSessionService: UserSessionService,
    private location: Location,
    private msalBroadcastService: MsalBroadcastService,
    private authenticationService: AuthenticationService,
    private sharedContextState: Store<SharedContext>,
    private telemetryService: TelemetryService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    environment.authConfig.cacheLocation = this.getCacheLocation();
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  loginDisplay = false;
  isIframe = false;

  login() {
    this.authenticationService.msalService.loginRedirect();
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.authenticationService.msalService.instance.getAllAccounts().length > 0) {
          if (environment.isPageTourEnabled) {
            this.pagetourService.pageTourInit(this.callbackOnTourEnd);
          }
          this.userSessionService.initialize(false, false, true).then(x => {
            // if (this.router.url !== '') {
            //   if (this.sharedContext.isLoggedInUserAdmin) {
            //     this.router.navigate(['admin', 'impersonate']);
            //   } else {
            //     this.router.navigate(['dashboard']);
            //   }
            // }
          });
        } else {
          this.login();
        }
      });
    // this.authService.handleWindowCallback(false);
  }

  getCacheLocation() {
    if (this.deviceDetectorService.browser === BROWSERS.IE || this.deviceDetectorService.browser === BROWSERS.MS_EDGE) {
      return 'localStorage';
    }
    return 'sessionStorage';
  }

  // To close any open tooltips on screen when clicked outside the graph
  @HostListener('document:click', ['$event'])
  handleClickOut(event) {
    this.closeTooltip(event);
    this.closeSidebar(event);
  }

  @HostListener('document:keydown.escape', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.collapseSidebar(event);
  }

  closeTooltip(event) {
    const element = document.getElementsByClassName('bar__tooltip bar__tooltip-click');
    if (element) {
      const tooltip = element as HTMLCollectionOf<HTMLElement>;
      _.each(tooltip, function(item) {
        item.style.display = 'none';
      });
    }
  }

  collapseSidebar(event) {
    const sideElm = document.getElementById('sidebar_pane');
    if (sideElm && sideElm.classList.contains('active')) {
      sideElm.classList.remove('active');
      this.sidebarService.isSidebarActive = false;
    }
    this.closeSubmenu(event);
  }

  closeSidebar(event) {
    if (this.listOfId.indexOf(event.target.id) < 0) {
      this.collapseSidebar(event);
    }
  }

  closeSubmenu(event) {
    this.navigationService.resetHeightsOfAllMenus();
  }

  closeModal(event: any) {
    this.modalRef.close();
  }

  callbackOnTourEnd = (tour: Tutorial) => {
    console.log(`tour ended ${tour}`);
    this.tourName = tour.title;
    this.tourId = tour.id;
    this.modalRef = this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'mint-modal-backdrop', windowClass: 'mint-modal-window' });
  };
}
