export class Metric {
  public name: string;
  public weight: number;
  public attainment: number;
  public threshold: number;
  public target: number;
  public excellence: number;
  public payoutImpact: number;
  public attainmentColor: any;
  public isNeutral = false;
  public unit: string;
  public incentiveType?: string;
  // progress-bar styling
  attainmentWidth: string;
  attainmentClass: string;
  totalEarning: number = 0;
}
