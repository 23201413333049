import { Component, Input, OnInit } from '@angular/core';
import { BonusService } from '../bonus.service'; 
import { DatePipe } from '@angular/common';
import { BonusAttainmentDetails } from 'src/app/shared/models/bonus-account-details.model';
import { Formatter } from '@mint-libs/common';
import { DataSource } from '../../shared/models/data-source.model';
import { environment } from '../../../environments/environment';
import { BonusParticipationDetails } from '../../shared/models/bonus-participation-details.model';
import { BonusAwardSummary } from '../../shared/models/bonus-award-summary.model';

@Component({
  selector: 'mint-bonus-seller',
  templateUrl: './bonus-seller.component.html',
  styleUrls: ['./bonus-seller.component.scss']
})
export class BonusSellerComponent implements OnInit {
  @Input() isDemoMode3;
  @Input() bonusParticipationDetails : BonusParticipationDetails;
  cagrParticipationId: any;
  revMapName: any;
  participationStartYear: any;
  participationEndYear: any;
  public accounts: any;
  public bonusComponents: BonusAttainmentDetails[];
  public bingoComponents : BonusAttainmentDetails[];
  public attainmentDetailsGrid: any;
  public pccList: any;
  public totalCloudGrowthGrid: any;
  public totalCloudGrowthChart: any;
  public awardsDataSet: any;
  gridView = false;
  isattainmentDetailsLoaded = false;
  isAwardsUnlockedLoaded = false;
  activeTabId = this.gridView ? 'cagr_grid' : 'cagr_chart';
  showNewFeaturesBanner = true;
  isLoaded = false;
  awardsUnlockedCount = 0;
  activeTabIdRevNtarget: any;
  awardSummary : BonusAwardSummary;
  
  tooltipList = [
    {
      name: 'Dynamics 365',
      text: 'Meet the three year Dynamics 365, and PowerApps and Flow Billed Revenue target'
    },
    {
      name: 'Modern Workplace',
      text: 'Meet the three year MW Cloud Billed Revenue target or the Teams Active Usage target'
    },
    {
      name: 'Total ACR',
      text: 'Meet the three year ACR target'
    }
  ];

  chartGridViewTab = [
    {
      title: ` <span>
      <svg class="view__icon"  width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 9V5.53845H2.2V9H0ZM2.93333 9V2.76923H5.13333V9H2.93333ZM8.8 2.76923H11V9H8.8V2.76923ZM5.86667
      9V0H8.06667V9H5.86667Z"
          fill="#828282"
        />
      </svg>
    </span>
    Chart view`,
    id: 'cagr_chart'
    },
    {
      title: `<span>
      <svg class="view__icon"  width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H11V3.46153H0V0ZM0 9V5.53845H11V9H0Z" fill="#828282" />
      </svg>
    </span>
    Grid view`,
      id: 'cagr_grid'
    }
  ];

  
   
  constructor(private bonusService: BonusService, private datePipe: DatePipe, private formatter: Formatter) {}

  ngOnInit(): void {
   
    this.participationStartYear = this.bonusParticipationDetails.EarningsStartDate;
    this.participationEndYear = this.bonusParticipationDetails.EarningsEndDate;
    
    if(this.bonusParticipationDetails.BonusAttributes.length > 0){
      this.BindPccAttainmentDetailsforChart(this.bonusParticipationDetails.ParticipationId,null);      
      this.isattainmentDetailsLoaded = true;
    }
    else{
      console.error("BonusAttributes empty");
    }
    
  }  

  formatDataSource(elem) {
    elem.SourceLastRefreshTime = this.datePipe.transform(elem.SourceLastRefreshTime, 'MMM d, yyyy h:mm a z');
    elem.SourceNextRefreshTime = this.datePipe.transform(elem.SourceNextRefreshTime, 'MMM d, yyyy h:mm a z');
    return elem;
  }  

  BindPccAttainmentDetailsforChart(cagrParticipationId, attainmentDetails) {
  
    this.bonusComponents = this.bonusParticipationDetails.BonusAttributes.filter(component => component.isBingo() == false && component.CalculationType );
    let bingoObjects = this.bonusParticipationDetails.BonusAttributes.filter(component => component.isBingo() == true );

    bingoObjects.forEach(bingo=>{
     // let objBingo= new BonusAttainmentDetails();
      bingo.PlanComponentName="Priorities+ Excellence Bonus";
      // objBingo.PercentageValue= objBingo.BingoBonusPercentage;
     // objBingo.GrainBonusAmount = objBingo.BingoBonusAmount;
    });

    this.bingoComponents = bingoObjects;

  }

 

  onTabChange(event) {
    this.gridView = event.nextId === 'cagr_grid' ? true : false;
  }
  onTabChangeRevNtarget(event) {
    this.activeTabIdRevNtarget = event.nextId;
  }
}
