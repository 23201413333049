<div>
  <div class="impersonation_container">
    <div class="admin-user">
      <ngx-avatar name="{{ userInfo.fullName }}" initialsSize="2" size="94" [round]="true" src="{{ thumbnailPhoto }}" textSizeRatio="2.5"></ngx-avatar>
    </div>
    <div class="impersonated_user_container">
      <div class="impersonated_user_name">{{ userInfo.fullName }}</div>
      <div class="impersonated_user_footer_container">
      <div class="impersonated_user_alias">({{ userInfo.alias }})</div>
      <div *ngIf="userInfo.incentiveType" class="impersonated_user_incentivetype">{{ userInfo.incentiveType }}</div></div>
    </div>
  </div>
</div>
