import { CagrRouteGuard } from './core/authentication/cagr-route-guard';
import { NgModule, ErrorHandler, APP_INITIALIZER, ApplicationRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './login.component';
import { HomeModule } from './home/home.module';
import { DashboardModule } from './dashboard/dashboard.module';
import './core/hybrid/ng-downgraded-components';
import { FeedbackModule } from './feedback/feedback.module';
import { PreviewRouteGuard } from './core/authentication/preview-route-guard';
import { GlobalErrorHandler } from './core/exceptionHandling/exception.interceptor';
import { DashboardV2RouteGuard } from './core/authentication/dashboard-v2-route-gaurd';
import { PageTourService } from './shared/training/pagetour.service';
import { AIRouteGuard } from './core/authentication/azureincentives-route-guard';
import { FY19RouteGuard } from './core/authentication/fy19-route-gaurd';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DemoComponent } from './demo/demo.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CagrModule } from './cagr/cagr.module';
import { BonusModule } from './bonus/bonus.module';
import { Mic2RouteGuard } from './core/authentication/mic2-route-gaurd';
import { FeatureAdminModule } from './feature-admin/feature-admin.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MintCommonModule, sharedContextFeatureKey, sharedContextReducer } from '@mint-libs/common';
import { MintAuthenticationModule } from '@mint-libs/authentication';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from '@mint-libs/context';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SupportHelperService } from './support/support-helper.service';
import { ConfigurationEffects, configurationFeatureKey, configurationReducer } from '@mint-libs/configuration';
import {
  MeasureTypeMappingEffect,
  measureTypeMappingFeatureKey,
  measureTypeMappingReducer,
  filtersFeatureKey,
  filtersReducer,
  FilterEffects,
  userProfileFeatureKey,
  userProfileReducer,
  UserProfileEffects
} from '@mint-libs/context';
import { EffectsModule } from '@ngrx/effects';
import { LayoutModule } from './layout/layout.module';
import { AdminModule } from './admin/admin.module';
import { MqchModule } from './mqch/mqch.module';
import { BonusV2Module } from './bonus-v2/bonus-v2.module';
import { AppRoutingModule } from './app-routing.module';
import { ManageNotificationModule } from './manage-notification/manage-notification.module';
import { SettingsComponent } from './settings/settings.component';
import { ThirdPartySoftwareComponent } from './settings/third-party-software/third-party-software.component';
import { HelpComponent } from './help/help.component';
import { FaqComponent } from './help/faq/faq.component';
import { GlossaryComponent } from './help/glossary/glossary.component';
import { AccessibilityHelpComponent } from './help/accessibility-help/accessibility-help.component';
import { TrainingMaterialComponent } from './help/training-material/training-material.component';
import { HelpService } from './help/help.service';
import { BonusRouteGuard } from './core/authentication/bonus-route-guard';
import { ConsolidatedBonusGuard } from './core/authentication/consolidated-bonus-guard';
import { WidgetFactory } from './shared/widgets/widget.factory';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { PublicClientApplication, BrowserCacheLocation, InteractionType, LogLevel } from '@azure/msal-browser';
import { initializationFeatureKey, initializationReducer } from './core/initialization/state/initialization.reducer';
import { InitializationEffects } from './core/initialization/state/initialization.effects';
import { NgxSpinnerModule } from 'ngx-spinner';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set('https://graph.microsoft.com/v1.0/*', ['user.read', 'user.readbasic.all']);
  protectedResourceMap.set(environment.mintapiBaseUrl, [environment.aadResource + '/access_as_user']);
  protectedResourceMap.set(environment.mintapiDetailBaseUrl, [environment.aadResource + '/access_as_user']);
  protectedResourceMap.set(environment.demoapiBaseUrl, [environment.aadResource + '/access_as_user']);
  protectedResourceMap.set(environment.bonusApiUrl, [environment.aadResource + '/access_as_user']);
  protectedResourceMap.set(environment.payoutApiUrl, [environment.aadResource + '/access_as_user']);
  protectedResourceMap.set(environment.pageTourConfig.apiUrl, [environment.pageTourConfig.resourceId + '/access_as_user']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    UpgradeModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    CoreModule,
    SharedModule,
    HomeModule,
    DashboardModule,
    CagrModule,
    BonusModule,
    BonusV2Module,
    FeedbackModule,
    LayoutModule,
    FeatureAdminModule,
    MqchModule,
    ManageNotificationModule,
    NgbModule,
    AdminModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        // MSAL Configuration
        auth: {
          clientId: environment.aadClientId,
          authority: 'https://login.microsoftonline.com/' + environment.authConfig.tenant,
          redirectUri: window.location.origin,
          postLogoutRedirectUri: window.location.origin
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage
        },
        system: {
          loggerOptions: {
            loggerCallback: () => { },
            piiLoggingEnabled: false
          }
        }
      }),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory()
    ),
    MintAuthenticationModule.forRoot(environment),
    MintCommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 100, // Retains last 100 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    StoreModule.forFeature(initializationFeatureKey, initializationReducer),
    StoreModule.forFeature(configurationFeatureKey, configurationReducer),
    StoreModule.forFeature(userProfileFeatureKey, userProfileReducer),
    StoreModule.forFeature(measureTypeMappingFeatureKey, measureTypeMappingReducer),
    StoreModule.forFeature(filtersFeatureKey, filtersReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([UserProfileEffects, ConfigurationEffects, MeasureTypeMappingEffect, FilterEffects]),
    StoreModule.forFeature(sharedContextFeatureKey, sharedContextReducer),
    EffectsModule.forRoot([InitializationEffects]),
    EffectsModule.forFeature([ConfigurationEffects, InitializationEffects]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [
    PreviewRouteGuard,
    AIRouteGuard,
    CagrRouteGuard,
    BonusRouteGuard,
    ConsolidatedBonusGuard,
    FY19RouteGuard,
    Mic2RouteGuard,
    DashboardV2RouteGuard,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    PageTourService,
    SupportHelperService,
    HelpService,
    WidgetFactory,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    DemoComponent,
    SettingsComponent,
    ThirdPartySoftwareComponent,
    HelpComponent,
    FaqComponent,
    GlossaryComponent,
    AccessibilityHelpComponent,
    TrainingMaterialComponent
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class MintAppModule {
  constructor(private upgrade: UpgradeModule) {
    // console.log("Here");
    // this.upgrade.bootstrap(document.body, ['participantExpApp']);
  }
  ngDoBootstrap(appRef: ApplicationRef) {
    appRef.bootstrap(AppComponent);
  }
}
