<div id="help_1" class="page-container">
  <!-- Commented out as we are now showing a static content for users which redirects to IC guide. Retaining code, so that if required we can consume it back.-->
  <!-- <div id="help_2" class="filters-tab">
    <div id="help_3" class="pull-left">
      <mint-tabs [tabs]="helpTabs" (tabChange)="onTabChange($event)" [activeId]="helpTabs[selectedTab].id"
        [tracker]="'HelpTab'" [source]="'Help'"></mint-tabs>
    </div>
  </div> -->
  <!-- <app-progress-bar id="dashboard_8" class="sticky__progress__bar"></app-progress-bar> -->
  <!-- <div id="help-tiles"> -->
  <!-- <div class="m-t-20" *ngIf="selectedTab === 0 && helpData !== null">
      <mint-faq [helpResponse]="helpData"></mint-faq>
    </div>
    <div class="m-t-20" *ngIf="selectedTab === 1 && helpData !== null">
      <mint-glossary [helpResponse]="helpData"></mint-glossary>
    </div> -->
  <!-- For now below content is commented if training and accessibility is required then can be turned back in future -->
  <!-- <div class="m-t-20" *ngIf="selectedTab === 2 && helpData !== null">
      <mint-training-material [helpResponse]="helpData"></mint-training-material>
    </div>
    <div class="m-t-20" *ngIf="selectedTab === 3">
      <mint-accessibility-help></mint-accessibility-help>
    </div> -->
  <!-- </div> -->
  <strong class="help-tiles">Please visit your <a target="_blank" title="IC Guide" [href]="icGuideUrl">IC Guide</a> for
    more
    information on your
    incentive compensation.
  </strong>
</div>