<div class="estimation_buttons">
  <button
    class="estimate_button"
    (click)="estimate()"
    (keyup.enter)="estimate()"
    (keyup.space)="estimate()"
    [disabled]="estimatingEarningsFlag"
    [ngStyle]="{ background: estimatingEarningsFlag ? '#2F80ED' : '#0078D4', opacity: estimatingEarningsFlag ? '30%' : '100%' }"
    analytics-tracker
    [tracker]="'Bucket_Estimation_Estimate'"
    [source]="'Bucket_Estimation'"
    [type]="'Click'"
    [element]="'input'"
  >
    <svg class="button_style" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1V15H4V16H0V0H8.71094L13 4.28906V5H8V1H1ZM9 1.71094V4H11.2891L9 1.71094ZM12 10L10.5 12L9 10H10V6H11V10H12ZM16 10.5L14 12V11H12.5L13.25 10H14V9L16 10.5ZM5.05469 11C5.10156 10.5781 5.20312 10.1745 5.35938 9.78906C5.52083 9.39844 5.73438 9.03646 6 8.70312L6.71094 9.41406C6.35156 9.88281 6.13281 10.4115 6.05469 11H5.05469ZM6.05469 12C6.13281 12.5885 6.35156 13.1172 6.71094 13.5859L6 14.2969C5.73438 13.9635 5.52083 13.6042 5.35938 13.2188C5.20312 12.8281 5.10156 12.4219 5.05469 12H6.05469ZM9 8.05469C8.41146 8.13281 7.88281 8.35156 7.41406 8.71094L6.70312 8C7.03646 7.73438 7.39583 7.52344 7.78125 7.36719C8.17188 7.20573 8.57812 7.10156 9 7.05469V8.05469ZM12.2891 13.5859C12.6484 13.1172 12.8672 12.5885 12.9453 12H13.9453C13.8984 12.4219 13.7943 12.8281 13.6328 13.2188C13.4766 13.6042 13.2656 13.9635 13 14.2969L12.2891 13.5859ZM10 14.9453C10.5885 14.8672 11.1172 14.6484 11.5859 14.2891L12.2969 15C11.9635 15.2656 11.6016 15.4792 11.2109 15.6406C10.8255 15.7969 10.4219 15.8984 10 15.9453V14.9453ZM6.70312 15L7.41406 14.2891C7.88281 14.6484 8.41146 14.8672 9 14.9453V15.9453C8.57812 15.8984 8.17188 15.7969 7.78125 15.6406C7.39583 15.4792 7.03646 15.2656 6.70312 15Z"
        fill="white"
      />
    </svg>
    Estimate
  </button>
  <button
    class="start_over_button"
    (click)="startOver()"
    (keyup.enter)="startOver()"
    (keyup.space)="startOver()"
    analytics-tracker
    [tracker]="'Bucket_Estimation_Start_Over'"
    [source]="'Bucket_Estimation'"
    [type]="'Click'"
    [element]="'input'"
  >
    <svg class="button_style" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 4C9.80729 4 10.5729 4.15104 11.2969 4.45312C12.026 4.75521 12.6745 5.1901 13.2422 5.75781C13.8099 6.32552 14.2448 6.97396 14.5469 7.70312C14.849 8.42708 15 9.19271 15 10C15 10.5521 14.9271 11.0833 14.7812 11.5938C14.6406 12.1042 14.4401 12.5833 14.1797 13.0312C13.9193 13.474 13.6068 13.8776 13.2422 14.2422C12.8776 14.6068 12.4714 14.9193 12.0234 15.1797C11.5807 15.4401 11.1042 15.6432 10.5938 15.7891C10.0833 15.9297 9.55208 16 9 16H2V15H9C9.45833 15 9.90104 14.9401 10.3281 14.8203C10.7552 14.7005 11.1536 14.5339 11.5234 14.3203C11.8932 14.1016 12.2292 13.8411 12.5312 13.5391C12.8385 13.2318 13.099 12.8932 13.3125 12.5234C13.5312 12.1536 13.7005 11.7552 13.8203 11.3281C13.9401 10.901 14 10.4583 14 10C14 9.54167 13.9401 9.09896 13.8203 8.67188C13.7005 8.24479 13.5312 7.84635 13.3125 7.47656C13.099 7.10677 12.8385 6.77083 12.5312 6.46875C12.2292 6.16146 11.8932 5.90104 11.5234 5.6875C11.1536 5.46875 10.7552 5.29948 10.3281 5.17969C9.90104 5.0599 9.45833 5 9 5H2.58594L5.91406 8.33594L5.33594 8.91406L0.914062 4.5L5.33594 0.0859375L5.91406 0.664062L2.58594 4H9Z"
        fill="#0064BF"
      />
    </svg>
    Start over
  </button>
</div>
<mint-content-loader-card-chart *ngIf="!isLoaded"></mint-content-loader-card-chart>
<div id="estimator_buckets_detail_123">
  <div *ngIf="isLoaded">
    <div class="estimation_cards">
      <div class="bucket_estimator_earnings">
        <div class="bucket_estimator_left_bar"></div>
        <div class="bucket_estimator_outer_box">
          <div class="current_val_head">
            <div>Current Projected Earnings</div>
            <div class="current_values">
              <div class="estimation_numbers">{{ formatter.formatAndRoundOff(currentProjectedTotalEarnings) }}</div>
              <div class="earnings_currency">{{ EarningsCurrency }}</div>
            </div>
          </div>
          <div style="flex:1;">
            <div class="estimated_returns">Estimated Projected Earnings</div>
            <div class="estimated_values" *ngIf="!estimatingEarningsFlag">
              <div class="estimated_results" [ngStyle]="{ opacity: isEstimatorRun ? '100%' : '30%' }"  [attr.aria-disabled]="!isEstimatorRun">
                <div class="estimation_numbers">{{ formatter.formatAndRoundOff(newEstimatedTotalEarnings) }}</div>
                <div class="earnings_currency">{{ EarningsCurrency }}</div>
              </div>
              <div class="delta_change" *ngIf="isEstimatorRun">
                <svg *ngIf="newEstimatedTotalEarnings - currentProjectedTotalEarnings > 0" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.45479 0L10.4548 10H0.45479L5.45479 0Z" fill="#0078D4" />
                </svg>
                <svg *ngIf="newEstimatedTotalEarnings - currentProjectedTotalEarnings < 0" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.76063 10L0.760627 -1.14854e-06L10.7606 -2.74316e-07L5.76063 10Z" fill="#0078D4" />
                </svg>
                <div class="delta_estimations">{{ formatter.formatAndRoundOff(newEstimatedTotalEarnings - currentProjectedTotalEarnings) }} {{ EarningsCurrency }}</div>
              </div>
            </div>
            <svg
              *ngIf="estimatingEarningsFlag"
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.0"
              width="20px"
              height="20px"
              viewBox="0 0 128 128"
              xml:space="preserve"
            >
              <rect x="0" y="0" width="100%" height="100%" fill="#ffff" />
              <g>
                <path d="M64 9.75A54.25 54.25 0 0 0 9.75 64H0a64 64 0 0 1 128 0h-9.75A54.25 54.25 0 0 0 64 9.75z" fill="#00ae56" fill-opacity="1" />
                <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="800ms" repeatCount="indefinite"></animateTransform>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="bucket_estimator_earnings" style="margin-right: 0px;">
        <div class="bucket_estimator_left_bar"></div>
        <div class="bucket_estimator_outer_box">
          <div class="current_val_head">
            <div>Current Weighted Attainment</div>
            <div class="current_values">
              <div class="estimation_numbers">{{ currentWeightedAttainment | number: '1.2-2' }}%</div>
            </div>
          </div>
          <div style="flex:1;">
            <div class="estimated_returns">Estimated Weighted Attainment</div>
            <div class="estimated_values" *ngIf="!estimatingEarningsFlag">
              <div class="estimated_results" [ngStyle]="{ opacity: isEstimatorRun ? '100%' : '30%' }"  [attr.aria-disabled]="!isEstimatorRun">
                <div class="estimation_numbers">{{ newEstimatedWeightedAttainment | number: '1.2-2' }}%</div>
              </div>
              <div class="delta_change" *ngIf="isEstimatorRun">
                <svg *ngIf="newEstimatedWeightedAttainment - currentWeightedAttainment > 0" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.45479 0L10.4548 10H0.45479L5.45479 0Z" fill="#0078D4" />
                </svg>
                <svg *ngIf="newEstimatedWeightedAttainment - currentWeightedAttainment < 0" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.76063 10L0.760627 -1.14854e-06L10.7606 -2.74316e-07L5.76063 10Z" fill="#0078D4" />
                </svg>
                <div class="delta_estimations">{{ newEstimatedWeightedAttainment - currentWeightedAttainment | number: '1.2-2' }}%</div>
              </div>
            </div>
            <svg
              *ngIf="estimatingEarningsFlag"
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.0"
              width="20px"
              height="20px"
              viewBox="0 0 128 128"
              xml:space="preserve"
            >
              <rect x="0" y="0" width="100%" height="100%" fill="#ffff" />
              <g>
                <path d="M64 9.75A54.25 54.25 0 0 0 9.75 64H0a64 64 0 0 1 128 0h-9.75A54.25 54.25 0 0 0 64 9.75z" fill="#26873A" fill-opacity="1" />
                <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="800ms" repeatCount="indefinite"></animateTransform>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="estimation-cards">
      <div *ngFor="let item of bucketLevelData" class="bucket__item">
        <mint-bucket-estimator-bucket-card
          [bucketDetails]="item"
          [estimatedEarnings]="estimatedEarnings"
          [estimatingEarningsFlag]="estimatingEarningsFlag"
          [startOverFlag]="startOverFlag"
          [CurrentQuarter]="CurrentQuarter"
          (bucketEstimatorEmitter)="updateEstimatorData($event)"
        ></mint-bucket-estimator-bucket-card>
      </div>
    </div>
    <div class="note">
      Note: The estimator allows you to estimate up to soft cap but your quarterly advances are capped at bucket level advance cap. Additionally, buckets with transition allowance are not available for estimation at this time.
    </div>
  </div>
</div>
<div class="legend">
  <div id="legends_17" class="legend__item">
    <div id="legends_18" class="legend__item__clr"></div>
    <div id="legends_19" class="legend__item__text">Current YTD Earnings</div>
  </div>
  <div id="legends_17" class="legend__item">
    <div id="legends_18" class="legend__item__clr"></div>
    <div id="legends_19" class="legend__item__text">Estimated Earnings</div>
  </div>
</div>
