<div class="direct__utilization__quota">
  <div class="metric__type">
    <div class="metric__type__desc" style="text-align: left">Total Weighted Attainment</div>
    <div class="metric__type__val">{{ direct.totalWeightedAttainment | percentage }}</div>
  </div>
  <div class="pcg__items__container">
    <div class="carousel">
      <div  class="metric__detail slide" *ngFor="let metric of direct.pcgMetrics">
        <div class="metric__name">{{ metric.name }}</div>
        <div class="metric__weight">Weight: {{ metric.weight | percent }}</div>
        <div class="metric__attainment">{{ metric.attainment | percentage }}</div>
        <div class="metric__progress">
          <div class="metric__progress__placeholder"><div [ngClass]="['metric__progress__actual', metric.attainmentClass]" [style.width]="metric.attainmentWidth"></div></div>
        </div>
      </div>
    </div>
  </div>
</div>
