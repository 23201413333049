import { Component, Input, OnInit, Inject } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { UserProfileService } from 'src/app/core/context/user-profile.service';
import { Observable, from, forkJoin } from 'rxjs';
import { Code } from 'src/app/app.constants';
import { MessagesService } from '@mint-libs/context';
import { IncentiveSummaryService } from './incentive-summary.service';
import { FiltersService } from '../../filters/filters.service';
import { WidgetFactory } from '../../shared/widgets/widget.factory';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-incentive-summary-tiles',
  templateUrl: './incentive-summary-tiles.component.html',
  styleUrls: ['./incentive-summary-tiles.component.scss']
})
export class IncentiveSummaryTilesComponent implements OnInit {
  isPayoutUnavailable: boolean;
  corePayoutSummary: any;
  bonusPayoutSummary: any;
  isBonusDetailsAvailable: boolean;
  payoutUnavailableMessage = <any>{};
  selectedTab = 0;
  isRBIUser: boolean;
  isOptOut: boolean;
  @Input() tabId: number;
  @Input() payoutSummary: any;
  public sharedContext = new SharedContext();

  constructor(
    private incentiveSummaryService: IncentiveSummaryService,
    private sharedService: SharedService,
    private userProfileService: UserProfileService,
    private messageService: MessagesService,
    private filterService: FiltersService,
    public sharedContextState: Store<SharedContext>,
    public code: Code
  ) {
    this.isPayoutUnavailable = this.filterService.isPayoutUnavailable();
    this.isRBIUser = this.userProfileService.isRBIPersona();

    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      this.isOptOut = this.sharedContext.optoutDetails.isOptOut;
    });
  }

  incentiveSummaryTabs = [
    { index: 0, name: 'CorePayouts', id: 'incentiveSummaryTab_CorePayouts', title: 'Core Payouts' }
    //{ index: 1, name:'BonusPayouts', id:'incentiveSummaryTab_BonusPayouts', title:'Bonus Payouts'}
  ];

  loadTemplate = false;
  hasBonusPrograms = !window.location.hostname.includes('microsoftfederal');

  ngOnInit() {
    this.checkPayoutIsAvailable();
    // If payout Summary data is not passed from parent, call the api
    if (this.payoutSummary == undefined) {
      forkJoin([this.incentiveSummaryService.getPayoutSummaryData(true, true, this.isOptOut), this.incentiveSummaryService.getPayoutSummaryDataFromParticipantAPI(this.isRBIUser)]).subscribe(
        ([payoutSummary_new, payoutSummary_old]) => {
          payoutSummary_old = payoutSummary_old == null ? null : payoutSummary_old.ApplicablePayouts;
          this.setPayoutSummaryData(payoutSummary_new, payoutSummary_old);
        }
      );
    } else {
      // If it is passed, it happens only for RBI user
      this.setPayoutSummaryData(this.payoutSummary, null);
    }
  }

  setPayoutSummaryData(payoutSummary_new, payoutSummary_old) {
    this.bonusPayoutSummary = payoutSummary_new.PayoutReportSummary.BonusEarningSummary;
    if (this.isRBIUser) {
      this.corePayoutSummary = payoutSummary_new.PayoutReportSummary.EarningSummary;
    } else {
      this.corePayoutSummary = payoutSummary_old;
    }
    this.isBonusDetailsAvailable = this.bonusPayoutSummary.length == 0;
    if (this.bonusPayoutSummary.length != 0) {
      this.incentiveSummaryTabs.push({ index: 1, name: 'BonusPayouts', id: 'incentiveSummaryTab_BonusPayouts', title: 'Bonus Payouts' });
    }
    this.selectedTab = 0;
    this.loadTemplate = true;
  }

  onTabChange(event) {
    this.incentiveSummaryTabs.forEach(x => {
      if (x.id === event.nextId) {
        this.selectedTab = x.index;
      }
    });
  }

  viewReport() {
    this.sharedService.ngViewReport(this.tabId, this.tabId);
  }

  checkPayoutIsAvailable() {
    if (this.isPayoutUnavailable) {
      this.messageService.getFullMessage(Code.PayoutUnavailibiltyMessage).subscribe(response => {
        this.payoutUnavailableMessage['Title'] = response[0]['title'];
        this.payoutUnavailableMessage['Text'] = response[0]['text'];
      });
    }
  }
}
