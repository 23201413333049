import { NgModule } from '@angular/core';
import { ToDateFormatPipe } from './pipes/custom-date.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { CustomNumberPipe } from './pipes/custom-number.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { ToLocalDatePipe } from './pipes/custom-local-date.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';

@NgModule({
  imports: [],
  declarations: [ToDateFormatPipe, CustomCurrencyPipe, CustomNumberPipe, PercentagePipe, SafeHtmlPipe, ToLocalDatePipe, ShortNumberPipe],
  providers: [ToDateFormatPipe, CustomCurrencyPipe, CustomNumberPipe, PercentagePipe, SafeHtmlPipe, ToLocalDatePipe, ShortNumberPipe],
  exports: [ToDateFormatPipe, CustomCurrencyPipe, CustomNumberPipe, PercentagePipe, SafeHtmlPipe, ToLocalDatePipe, ShortNumberPipe]
})
export class MintCommonModule {}
