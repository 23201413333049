import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Feature } from './models/feature.model';
import { PageFeature } from './models/page-feature.model';
import { Page } from './models/page.model';
import { SharedContext, selectSharedContextState } from '@mint-libs/common';
@Injectable({
  providedIn: 'root'
})
export class RoleFeatureService {
  constructor(private sharedContextState: Store<SharedContext>) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.populateFeatures(sharedContext.Features);
      this.populatePages(sharedContext.Pages);
      this.pageFeatures = sharedContext.Settings.UserRolePermissions;
    });
  }
  public pageFeatures: PageFeature[] = [];
  public pages: { [key: string]: number } = {};
  public features: { [key: string]: number } = {};

  public populateFeatures(featureList: Feature[]) {
    for (let feature of featureList) {
      this.features[feature.FeatureName] = feature.FeatureId;
    }
  }

  public populatePages(pageList: Page[]) {
    for (let page of pageList) {
      this.pages[page.PageName] = page.PageId;
    }
  }

  public isFeatureApplicable(pageID: number, featureID: number) {
    if (!this.pageFeatures) return false;
    const page = this.pageFeatures?.find(x => x.PageId === pageID && (x.FeatureIds || []).includes(featureID));
    if (!!page) {
      return true;
    }
    return false;
  }

  public isBetaFeature(pageID: number, featureID: number) {
    if (!this.pageFeatures) return false;
    const page = this.pageFeatures?.find(x => x.PageId === pageID && (x.BetaFeatureIds || []).includes(featureID));
    if (!!page) {
      return true;
    }
    return false;
  }

  public isPageApplicable(pageID: number) {
    if (!this.pageFeatures) return false;
    const page = this.pageFeatures?.find(x => x.PageId === pageID);
    if (!!page) {
      return true;
    }
    return false;
  }
}
