import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeatureAdminFilterService {
  initialize(addFeature: any) {
    addFeature.filters = ['plans', 'standardTitles', 'roles', 'countries', 'businessSegments', 'qualifier1s', 'qualifier2s'];
    addFeature.plans = {};
    addFeature.standardTitles = {};
    addFeature.roles = {};
    addFeature.countries = {};
    addFeature.businessSegments = {};
    addFeature.qualifier1s = {};
    addFeature.qualifier2s = {};

    addFeature.plans.list = [];
    addFeature.standardTitles.list = [];
    addFeature.roles.list = [];
    addFeature.countries.list = [];
    addFeature.businessSegments.list = [];
    addFeature.qualifier1s.list = [];
    addFeature.qualifier2s.list = [];

    addFeature.plans.selected = [];
    addFeature.standardTitles.selected = [];
    addFeature.roles.selected = [];
    addFeature.countries.selected = [];
    addFeature.businessSegments.selected = [];
    addFeature.qualifier1s.selected = [];
    addFeature.qualifier2s.selected = [];

    addFeature.plans.count = 0;
    addFeature.standardTitles.count = 0;
    addFeature.roles.count = 0;
    addFeature.countries.count = 0;
    addFeature.businessSegments.count = 0;
    addFeature.qualifier1s.count = 0;
    addFeature.qualifier2s.count = 0;

    // filter dropdown's default text
    addFeature.plans.title = 'Select Plans';
    addFeature.standardTitles.title = 'Select Standard Titles';
    addFeature.roles.title = 'Select Roles';
    addFeature.countries.title = 'Select Countries';
    addFeature.businessSegments.title = 'Select Business Segments';
    addFeature.qualifier1s.title = 'Select Qualifier 1s';
    addFeature.qualifier2s.title = 'Select Qualifier 2s';

    addFeature.plans.label = 'Plans';
    addFeature.standardTitles.label = 'Standard Titles';
    addFeature.roles.label = 'Roles';
    addFeature.countries.label = 'Countries';
    addFeature.businessSegments.label = 'Business Segments';
    addFeature.qualifier1s.label = 'Qualifier 1s';
    addFeature.qualifier2s.label = 'Qualifier 2s';

    addFeature.aliasList = '';
  }

  bindDropdowns(response, addFeature) {
    for (let i = 0; i < response.plans.length; i++) {
      const plan = {
        id: i,
        code: '',
        name: response.plans[i]
      };
      addFeature.plans.selected = [];
      addFeature.plans.list.push(plan);
    }
    response.standardTitles = response.standardTitles.filter(x => x.name != null);
    let setStandardTitles = new Set();
    for (let i = 0; i < response.standardTitles.length; i++) {
      if (!setStandardTitles.has(response.standardTitles[i].name)) {
        const standardTitle = {
          id: i,
          code: response.standardTitles[i].id,
          name: response.standardTitles[i].name
        };
        addFeature.standardTitles.list.push(standardTitle);
        setStandardTitles.add(response.standardTitles[i].name);
      }
    }
    addFeature.standardTitles.selected = [];
    for (let i = 0; i < response.roles.length; i++) {
      const role = {
        id: i,
        code: response.roles[i].id,
        name: response.roles[i].name
      };
      addFeature.roles.list.push(role);
      addFeature.roles.selected = [];
    }
    for (let i = 0; i < response.countries.length; i++) {
      const country = {
        id: i,
        code: response.countries[i].id,
        name: response.countries[i].name
      };
      addFeature.countries.selected = [];
      addFeature.countries.list.push(country);
    }
    for (let i = 0; i < response.qualifier1s.length; i++) {
      const qualifier1 = {
        id: i,
        code: response.qualifier1s[i].id,
        name: response.qualifier1s[i].name
      };
      addFeature.qualifier1s.selected = [];
      addFeature.qualifier1s.list.push(qualifier1);
    }
    for (let i = 0; i < response.qualifier2s.length; i++) {
      const qualifier2 = {
        id: i,
        code: response.qualifier2s[i].id,
        name: response.qualifier2s[i].name
      };
      addFeature.qualifier2s.selected = [];
      addFeature.qualifier2s.list.push(qualifier2);
    }
    for (let i = 0; i < response.businessSegments.length; i++) {
      const businesSegment = {
        id: i,
        code: '',
        name: response.businessSegments[i]
      };
      addFeature.businessSegments.selected = [];
      addFeature.businessSegments.list.push(businesSegment);
    }
  }

  consumeFilters = function(filterList, filters) {
    filters.selected = [];
    if (!filterList) {
      return null;
    }
    const flist = filterList.split(',');
    if (flist[1] === 'ALL') {
      for (let i = 0; i < filters.list.length; i++) {
        filters.selected.push(filters.list[i]);
      }
    } else {
      const flistSet = new Set();
      for (let j = 1; j < flist.length; j++) {
        flistSet.add(flist[j]);
      }
      for (let i = 0; i < filters.list.length; i++) {
        if (flistSet.has(filters.list[i].code) || flistSet.has(filters.list[i].name)) {
          filters.selected.push(filters.list[i]);
        }
      }
    }
  };

  produceFilters(filters) {
    if (filters.selected.length === filters.list.length) {
      filters.selected = [];
      return ',ALL,';
    } else {
      let ret = ',';
      for (let i = 0; i < filters.selected.length; i++) {
        if (filters.selected[i].code) {
          ret += filters.selected[i].code + ',';
        } else {
          ret += filters.selected[i].name + ',';
        }
      }
      filters.selected = [];
      if (ret === ',') {
        return ',ALL,';
      } else {
        return ret;
      }
    }
  }
}
