const DashboardCampaign: object = {
  Activity: 'OnDashboardLoad',
  CampaignId: 'e17783d5-8d6b-4991-a794-6bee833421ee', // Assigned by OCV
  StartTimeUtc: '2020-12-15T00:00:00Z', // If null, the campaign is inactive
  EndTimeUtc: '2021-04-15T00:00:00Z',
  GovernedChannelType: 0, // 1: Urgent Channel; 0 - Recommended for Production as it has channel cooldown
  NominationScheme: {
    Type: 0, // Default
    PercentageNumerator: 100, // Ratio of users to be nominated to be a candidate numerator
    PercentageDenominator: 100, // Ratio of users to be nominated to be a candidate denominator
    NominationPeriod: {
      Type: 0, // Default
      IntervalSeconds: 30 // NOT used when ratio is 100%
    },
    CooldownPeriod: {
      Type: 0, // Default
      IntervalSeconds: 2592000 // 30 days
    },
    FallbackSurveyDurationSeconds: 30
  },
  SurveyTemplate: {
    Type: 2, // NPS Template, can be NPS 5pts (0), NPS 11pts (1), FPS (2), NLQs (3), NPS (4), or GenericMessaging Survey (22)
    ActivationEvent: {
      Type: 0, // 0 For one event, 1 for multiple events (array of events)
      Activity: 'OnDashboardLoad',
      Count: 1,
      IsAggregate: true
    },
    Content: {
      Prompt: {
        Title: "We'd love your feedback!",
        Question: 'We just have two questions for you.',
        YesLabel: 'Yes',
        NoLabel: 'No'
      },
      Rating: {
        IsZeroBased: false,
        Question: 'How would you rate your experience with Mint (My Incentive)?',
        RatingValuesAscending: ['1 = Extremely dissatisfied', '2 = Somewhat dissatisfied', '3 = Neither satisfied nor dissatisfied', '4 = Somewhat satisfied', '5 = Extremely satisfied']
      },
      Question: { Question: 'Tell us more about your Mint experience.' }
    }
  }
};
export default DashboardCampaign;
