import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PayoutReportService } from '../../payout-report.service';
import { TPayoutObject } from '../../types/payout-info-response-type';
import { PeriodStatus } from 'src/app/shared/models/period-status.enum';

@Component({
  selector: 'mint-payout-core-bucket-details',
  templateUrl: './core-bucket-details.component.html',
  styleUrls: ['./core-bucket-details.component.scss']
})
export class CoreBucketDetailsComponent implements OnInit, OnChanges {
  selectedCoreBucket: any;
  selectedFiscalYearInfo: any;
  @Input() selectedPayoutEarningDetail: any;
  @Input() currency: string;
  @Input() selectedPCG: any;
  @Input() selectedFiscalYear: number;
  constructor(public payoutReportService: PayoutReportService) {}

  ngOnInit() {
    this.getCoreBucketDetails();
  }

  ngOnChanges() {
    this.getCoreBucketDetails();
  }

  getCoreBucketDetails() {
    this.selectedCoreBucket = this.selectedPCG;
    this.calculateCapsInfo(this.selectedCoreBucket);
  }

  calculateCapsInfo(pcg: any) {
    pcg.calc = {};
    pcg.calc.PayoutStages = [];

    let flag = 0;
    pcg.calc.PayoutBeforeCaps = pcg.PayoutStages.PayoutAtThreshold + pcg.PayoutStages.PayoutThresholdToTarget + pcg.PayoutStages.PayoutTargetToExcellence + pcg.PayoutStages.PayoutBeyondExcellence;
    if (pcg.PayoutStages.PayoutThresholdToTarget != 0) {
      pcg.calc.PayoutStages.push({
        PayoutTitle: 'Threshold Payout',
        PayoutValue: pcg.PayoutStages.ThresholdPayout,
        AttainmentTitle: 'Attainment Threshold To Target',
        AttainmentValue: pcg.PayoutStages.AttainmentThresholdToTarget,
        MultiplierTitle: 'Threshold to Target Rate',
        MultiplierValue: pcg.PayoutStages.MultiplierThresholdToTarget
      });
      flag++;
    }
    if (pcg.PayoutStages.PayoutTargetToExcellence != 0) {
      pcg.calc.PayoutStages.push({
        PayoutTitle: 'Target To Excellence Payout',
        PayoutValue: pcg.PayoutStages.TargetPayout,
        AttainmentTitle: 'Attainment Target To Excellence',
        AttainmentValue: pcg.PayoutStages.AttainmentTargetToExcellence,
        MultiplierTitle: 'Target to Excellence Rate',
        MultiplierValue: pcg.PayoutStages.MultiplierTargetToExcellence
      });
      flag++;
    }
    if (pcg.PayoutStages.PayoutBeyondExcellence != 0) {
      pcg.calc.PayoutStages.push({
        PayoutTitle: 'Excellence Payout',
        PayoutValue: pcg.PayoutStages.ExcellencePayout,
        AttainmentTitle: 'Attainment Beyond Excellence',
        AttainmentValue: pcg.PayoutStages.AttainmentBeyondExcellence,
        MultiplierTitle: 'Beyond Excellence Rate',
        MultiplierValue: pcg.PayoutStages.MultiplierBeyondExcellence
      });
      flag++;
    }
    if (pcg.PayoutStages.PayoutAtThreshold != 0 || flag === 0) {
      pcg.calc.PayoutStages.push({
        PayoutTitle: 'Till Threshold Payout',
        PayoutValue: pcg.PayoutStages.TillThresholdPayout,
        AttainmentTitle: 'Attainment At Threshold',
        AttainmentValue: pcg.PayoutStages.AttainmentAtThreshold,
        MultiplierTitle: 'Until Threshold Rate',
        MultiplierValue: pcg.PayoutStages.MultiplierTillThreshold
      });
    }
    const PayoutAfterCaps = pcg.calc.PayoutBeforeCaps > pcg.UsedCap ? pcg.UsedCap : pcg.calc.PayoutBeforeCaps;
    pcg.calc.PayoutAfterCaps = pcg.calc.PayoutBeforeCaps < 100 && pcg.calc.PeriodStatus === PeriodStatus.TA ? 100 : PayoutAfterCaps;
  }
}
