import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { CustomHttpParams } from './custom-http-params';
import { Response } from 'selenium-webdriver/http';
import { FiltersService } from '../../filters/filters.service';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { CachingService } from '../caching/caching.service';
import { PerformInitRefresh } from '../initialization/state/initialization.actions';

@Injectable({
  providedIn: 'root'
})
export class BonusApiDataService {
  sharedContext: SharedContext = new SharedContext();
  constructor(private http: HttpClient, private filterService: FiltersService, public sharedContextState: Store<SharedContext>, private cacheService: CachingService) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }
  IsMaintainanceMode = false;

  public MaintainanceMode = {
    message: '',
    title: '',
    setMessage: function(startTime, endTime, message) {
      if (startTime !== '' && endTime !== '') {
        startTime = this.filterService('ToLocalTime')(startTime);
        endTime = this.filterService('ToLocalTime')(endTime);
        this.message = message
          .replace('{0}', startTime)
          .split('{1}')
          .join(endTime);
      }
    },
    setTitle: function(title) {
      this.title = title;
    },
    On: function(startTime, endTime, message, title) {
      this.IsMaintainanceMode = true;
      this.setMessage(startTime, endTime, message);
      this.setTitle(title);
    },
    Off: function() {
      this.IsMaintainanceMode = false;
    }
  };

  public get<T>(uri: string, params: HttpParams): Observable<Response> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http
      .get<any>(this.getApiUri(uri), {
        headers: headers,
        params: params
      })
      .pipe(
        map(response => {
          this.refreshCache(response);
          return response;
        })
      );
  }

  public delete<T>(uri: string, params: HttpParams): Observable<Response> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http
      .delete<any>(this.getApiUri(uri), {
        headers: headers,
        params: params
      })
      .pipe(
        map(response => {
          this.refreshCache(response);
          return response;
        })
      );
  }

  public getCamelCase<T>(uri: string, params: HttpParams): Observable<Response> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http
      .get<any>(this.getApiUri(uri), {
        headers: headers,
        params: params
      })
      .pipe(
        map(response => {
          this.refreshCache(response);
          return response;
        })
      );
  }

  public post<T>(uri: string, body: JSON): Observable<T> {
    const urlBody = body;
    return this.http.post<T>(this.getApiUri(uri), urlBody);
  }

  public getWithGlobalFilters<T>(uri: string, params: HttpParams, headers: HttpHeaders = null): Observable<any> {
    if (params == null) {
      params = CustomHttpParams.getInstance();
    }
    params = this.setGlobalFilterParameters(params);

    if (headers == null) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .get<any>(this.getApiUri(uri), {
        headers: headers,
        params: params
      })
      .pipe(
        map(response => {
          this.refreshCache(response);
          return response;
        })
      );
  }

  public refreshCache(response) {
    if (response && response.Version != null && response.Version.length > 0) {
      if (this.sharedContext.fiscalYearVersion[response.FiscalYear] == null) {
        let sharedContext = { ...this.sharedContext, fiscalYearVersion: { ...this.sharedContext.fiscalYearVersion } };
        sharedContext.fiscalYearVersion[response.FiscalYear] = response.Version;
        this.sharedContextState.dispatch(new UpdateSharedContext(sharedContext));
      } else if (this.sharedContext.fiscalYearVersion[response.FiscalYear] !== response.Version) {
        let sharedContext = { ...this.sharedContext, fiscalYearVersion: { ...this.sharedContext.fiscalYearVersion } };
        sharedContext.fiscalYearVersion[response.FiscalYear] = response.Version;
        this.sharedContextState.dispatch(new UpdateSharedContext(sharedContext));
        if (this.sharedContext.totalCacheCount < this.sharedContext.totalCacheLimit) {
          // referencing `userSessionService` causes a circular dependency error
          // hence clearing cache directly and refreshing page
          /* 
            this.userSessionService.refresh(true);
            this.sharedContext.totalCacheCount++;
          */
          this.cacheService.clear();
          window.location.href = '/'; // perhaps this can reference `navigationService`?
        }
      }
    }
  }

  public postWithGlobalFilters<T>(uri: string, body?: any, headers?: HttpHeaders, responseType?): Observable<T> {
    let jsonBody = JSON.parse(JSON.stringify(body != null ? body : {}));
    jsonBody = this.setGlobalFilterParametersForJson(jsonBody);

    if (headers == null) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');
    if (responseType != null && responseType.toLowerCase() === 'arraybuffer') {
      headers = headers.set('Accept', 'application/octet-stream');
    }

    return this.http.post<T>(this.getApiUri(uri), jsonBody, {
      headers: headers,
      responseType: responseType ? responseType : 'json'
    });
  }

  // Todo: To be removed once new demo mode implementation is ready. Base method to be used instead which also include Month.
  public getWithGlobalFiltersAndMonth<T>(uri: string, params: HttpParams, headers: HttpHeaders = null): Observable<any> {
    if (this.filterService.selectedFiscalMonthNumber) {
      if (params == null) {
        params = CustomHttpParams.getInstance();
      }
    }

    return this.getWithGlobalFilters(uri, params, headers);
  }

  getApiUri(url: string): string {
    // defaulted to csa
    // TODO: This will be removed this week, once we have implement Auth via APIM this week (EOW 10th Dec)
    // let apiUrl = "https://bonusapiuat.azurewebsites.net/" +  url;
    // let apiUrl = "http://localhost:7071" +  url;

    const apiUrl = environment.bonusApiUrl + url.replace('/v1', '');

    return apiUrl;
  }

  setGlobalFilterParameters(params: HttpParams): HttpParams {
    params = params.append('FiscalYearId', this.filterService.selectedFiscalYear ? this.filterService.selectedFiscalYear.toString() : null);
    // TODO: This will be removed this week, once we have implement Auth via APIM this week (EOW 10th Dec)
    params = params.append('code', 'gcQpvuBfDkSREgpaAayYJZliYos9n1eOBBRwaARoraUkNEar5QkGxw==');
    params = params.append('ActiveVersion', '2');

    if (!params.has('ParticipationID')) {
      params = params.append('ParticipationID', this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.ParticipationID : null);
    }
    return params;
  }

  setGlobalFilterParametersForJson(jsonObject: any): any {
    // Todo : To be implemeted
    jsonObject['FiscalYear'] = this.filterService.selectedFiscalYear ? this.filterService.selectedFiscalYear : null;

    return jsonObject;
  }
}
