import { Formatter } from '@mint-libs/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { LegendService } from '../../legends/legend.service';

@Component({
  selector: 'app-grid-attainment-renderer',
  styles: [
    `
      .threshold-info {
        font-size: 11px;
        color: #707070;
        line-height: 9px;
      }
    `
  ],
  template: `
    <span style="display: block;">
      <span style="font-size: 24px;"> {{ data }}</span> <br />
      <span class="threshold-info" id="thresholdInfo" *ngIf="shouldShowThresholdInfo">({{ thresholdInfo }})</span>
    </span>
  `
})
export class AttainmentRendererComponent implements ICellRendererAngularComp {
  params: any;
  thresholdInfo: any;
  public shouldShowThresholdInfo = false;
  public data: number;
  constructor(private legendService: LegendService, private formatter: Formatter) {}
  agInit(params): void {
    this.params = params;
    if (params && params.data && params.data.Metric && (params.data.Metric.incentiveType === 'RUBI' || params.data.Metric.incentiveType === 'UBI' || params.data.Metric.incentiveType === 'PPI')) {
      this.data = this.formatter.formatWithCurrencyAndRoundOff(params.data.Metric.attainment, '%');
      this.shouldShowThresholdInfo = false;
    } else {
      if (params.value && !params.data.month) {
        this.thresholdInfo = this.legendService.getText(params.data.Metric);
        this.data = this.formatter.formatWithCurrencyAndRoundOff(params.data.Metric.attainment, '%');
        // Check for OCP PPM metric weight & earnings for grid view on dashboard screen
        this.shouldShowThresholdInfo = params.data.Metric.weight === 0 ? false : true;
      } else {
        this.data = this.formatter.formatWithCurrencyAndRoundOff(params.value, '%');
        this.shouldShowThresholdInfo = false;
      }
    }
  }

  refresh(): boolean {
    return false;
  }
}
