import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Formatter } from '@mint-libs/common';
import { MeasureTypeMappingState, selectMeasureTypeMappingState, MeasureType } from '@mint-libs/context';
import { Store } from '@ngrx/store';
import { UserProfileService } from '../../core/context/user-profile.service';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';

@Component({
  selector: 'mint-bucket-estimator-bucket-card',
  templateUrl: './bucket-estimator-bucket-card.component.html',
  styleUrls: ['./bucket-estimator-bucket-card.component.scss']
})
export class BucketEstimatorBucketCardComponent implements OnInit, OnChanges {
  @Input() bucketDetails;
  @Input() estimatedEarnings;
  @Input() startOverFlag;
  @Input() CurrentQuarter;
  @Input() estimatingEarningsFlag = false;
  @Output() bucketEstimatorEmitter: EventEmitter<any> = new EventEmitter<any>();
  EstimatorinputBucketDetails: any;
  isDisabledAttainment = false;
  isDisabledActuals = true;
  isCheckedAttainment = true;
  isCheckedActuals = false;
  isChanged = false;
  inputAttainment = 0;
  inputActual = 0;
  totalProjectedActuals = 0;
  isloaded = false;
  earningsPercentage = 0;
  newEarningsPercentage = 0;
  increasedEarningsPercentage = 0;
  newPayoutPercentage: any;
  EarningsTooltip: any;
  isDMYEligible: false;
  private measureTypeMapping: MeasureTypeMappingState = new MeasureTypeMappingState([]);
  measureTypeText: string = 'Target Recurring';

  constructor(public formatter: Formatter, 
    private userProfileService1: UserProfileService,
    private measureState: Store<MeasureTypeMappingState>,) { 
    this.getPCGMeasureType();
  }

  getPCGMeasureType() {
    this.measureState.select(selectMeasureTypeMappingState)
      .subscribe((measureTypeMapping) => {
        this.measureTypeMapping = measureTypeMapping;
        this.setMeasureTypeText();
      });
  }

  setMeasureTypeText() {
    if (this.measureTypeMapping.isFetched && this.bucketDetails) { 
      this.measureTypeText = this.measureTypeMapping.doesBucketContainMeasureType(this.bucketDetails.pcgId, MeasureType.Baseline) ? 'Target Recurring or Baseline' : 'Target Recurring';
    }
  }

  ngOnInit() {
    this.isDMYEligible = this.userProfileService1?.getIsDMYEligible();
    if (this.bucketDetails) {
      this.EarningsTooltip = 'Soft Cap Earnings is ' + this.bucketDetails.SoftCap + '% of Incentive Target Amount';
      this.EstimatorinputBucketDetails = this.bucketDetails;
      this.earningsPercentage = (this.bucketDetails.CurrentEarnings / this.bucketDetails.MaxCappedEarnigns) * 100;
      this.isloaded = true;
      this.setMeasureTypeText();
    }
  }
  enableDisableInputBoxAttainment() {
    this.isDisabledAttainment = !this.isDisabledAttainment;
    this.isDisabledActuals = true;
    this.isCheckedAttainment = !this.isCheckedAttainment;
    this.isCheckedActuals = false;
    this.inputAttainment = 0;
    this.inputActual = 0;
    this.totalProjectedActuals = 0;
    this.EstimatorinputBucketDetails.ProjectedEarnings = 0;
    this.EstimatorinputBucketDetails.ProjectedPayoutPercentage =0;
    this.isChanged = false;
    return;
  }
  enableDisableInputBoxActuals() {
    this.isDisabledActuals = !this.isDisabledActuals;
    this.isDisabledAttainment = true;
    this.isCheckedActuals = !this.isCheckedActuals;
    this.isCheckedAttainment = false;
    this.inputAttainment = 0;
    this.inputActual = 0;
    this.totalProjectedActuals = 0;
    this.EstimatorinputBucketDetails.ProjectedEarnings = 0;
    this.EstimatorinputBucketDetails.ProjectedPayoutPercentage =0;
    this.isChanged = false;
    return;
  }
  onFocusFunction() {
    this.isChanged = true;
    if (this.inputAttainment == 0 && this.inputActual == 0) {
      this.inputAttainment = null;
      this.inputActual = null;
      this.EstimatorinputBucketDetails.Metrics.ProjectedActual = 0;
      this.bucketEstimatorEmitter.emit(this.EstimatorinputBucketDetails);
    }
  }

  onFocusOutFunction() {
    if (!this.inputAttainment || !this.inputActual) {
      this.isChanged = false;
      this.inputAttainment = 0;
      this.inputActual = 0;
      this.totalProjectedActuals = 0;
      this.EstimatorinputBucketDetails.ProjectedEarnings = 0;
      this.EstimatorinputBucketDetails.ProjectedPayoutPercentage =0;
    }
  }

  onKeyAttainment($event) {
    if (this.inputAttainment && this.inputAttainment != 0) {
      // tslint:disable-next-line: max-line-length
      this.totalProjectedActuals = (this.inputAttainment * (this.bucketDetails.Metrics.TotalTarget - this.bucketDetails.Metrics.TotalTargetRecurring)) / 100 + this.bucketDetails.Metrics.TotalTargetRecurring;
      this.inputActual = this.totalProjectedActuals - this.bucketDetails.Metrics.TotalActuals;
      this.EstimatorinputBucketDetails.Metrics.ProjectedActual = this.inputActual;
      this.EstimatorinputBucketDetails.ProjectedAttainment = this.inputAttainment;
      this.EstimatorinputBucketDetails.ProjectedEarnings = 0;
      this.EstimatorinputBucketDetails.ProjectedPayoutPercentage =0;
      this.bucketEstimatorEmitter.emit(this.EstimatorinputBucketDetails);
    }
  }
  onKeyActual($event) {
    if (this.inputActual && this.inputActual != 0) {
      this.inputAttainment =
        // tslint:disable-next-line: max-line-length
        ((this.bucketDetails.Metrics.TotalActuals + this.inputActual - this.bucketDetails.Metrics.TotalTargetRecurring) / (this.bucketDetails.Metrics.TotalTarget - this.bucketDetails.Metrics.TotalTargetRecurring)) *
        100;
      this.EstimatorinputBucketDetails.Metrics.ProjectedActual = this.inputActual;
      this.EstimatorinputBucketDetails.ProjectedAttainment = this.inputAttainment;
      this.EstimatorinputBucketDetails.ProjectedEarnings = 0;
      this.EstimatorinputBucketDetails.ProjectedPayoutPercentage =0;
      this.totalProjectedActuals = this.inputActual + this.bucketDetails.Metrics.TotalActuals;
      this.bucketEstimatorEmitter.emit(this.EstimatorinputBucketDetails);
    }
  }

  undoFunction() {
    this.inputAttainment = 0;
    this.inputActual = 0;
    this.totalProjectedActuals = 0;
    this.isChanged = false;
    this.bucketDetails.Estimated = false;
    this.bucketDetails.ProjectedEarnings = 0;
    this.bucketDetails.ProjectedPayoutPercentage =0;
    this.isCheckedAttainment = true;
    this.isDisabledAttainment = false;
    this.isDisabledActuals = true;
    this.isCheckedActuals = false;
    this.newPayoutPercentage = 0;
    this.newEarningsPercentage = 0;
    if (this.EstimatorinputBucketDetails && this.EstimatorinputBucketDetails.Metrics) {
      this.EstimatorinputBucketDetails.Metrics.ProjectedActual = 0;
      this.bucketEstimatorEmitter.emit(this.EstimatorinputBucketDetails);
    }
  }

  transform(number: any): any {
    let exp,
      suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];

    if (Number.isNaN(number)) {
      return null;
    }
    if (number < 1000 && number > -1000) {
      if (number == 0) {
        return number;
      }
      return number.toFixed(2);
    }
    const isNegative = number < 0;
    if (isNegative) {
      number = number * -1;
    }
    exp = Math.floor(Math.log(number) / Math.log(1000));
    if (isNegative) {
      return this.transform((number * -1) / Math.pow(1000, exp)) + suffixes[exp - 1];
    } else {
      return this.transform(number / Math.pow(1000, exp)) + suffixes[exp - 1];
    }
  }

  updateEarnings(earningsData: any) {
    this.newEarningsPercentage = this.earningsPercentage;
    earningsData.forEach(pcg => {
      if (this.bucketDetails.pcgId === pcg.PCGID) {
        this.bucketDetails.ProjectedEarnings = pcg.PCGEarnings;
        this.bucketDetails.ProjectedPayoutPercentage =pcg.payoutPercentage;
        this.newPayoutPercentage = pcg.PCGEarnings ? (this.bucketDetails.ProjectedEarnings / this.bucketDetails.ITA) * 100 : 0;
        if (this.EstimatorinputBucketDetails.Metrics.ProjectedActual && this.EstimatorinputBucketDetails.Metrics.ProjectedActual > 0) {
          this.bucketDetails.Estimated = true;
          if (this.bucketDetails.MaxCappedEarnigns > 0) {
            this.newEarningsPercentage = (this.bucketDetails.ProjectedEarnings / this.bucketDetails.MaxCappedEarnigns) * 100;
            this.newPayoutPercentage = (this.bucketDetails.ProjectedEarnings / this.bucketDetails.ITA) * 100;
            if (this.bucketDetails.CurrentEarnings > 0) {
              this.increasedEarningsPercentage = (this.bucketDetails.ProjectedEarnings / this.bucketDetails.CurrentEarnings) * 100;
            } else if (this.bucketDetails.CurrentEarnings == 0) {
              this.increasedEarningsPercentage = 100;
            }
          }
        }
      }
    });
  }
  showTooltip(e, id) {
    // Get the tooltip element
    const tooltipElem: HTMLElement = document.querySelector('#earning_tooltip' + id);
    tooltipElem.style.display = 'block';
    // Position tooltip
    tooltipElem.style.left = `${e.clientX - 48 - 90}px`;
    tooltipElem.style.top = `${e.clientY - 48 + 10}px`;
    // console.log(`coordinates: (${e.clientX},${e.clientY})`);
  }

  hideTooltip(id) {
    const tooltipElem: HTMLElement = document.querySelector('#earning_tooltip' + id);
    tooltipElem.style.display = 'none';
  }
  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName === 'estimatedEarnings') {
        this.estimatedEarnings = changes[propName].currentValue;
        if (this.estimatedEarnings) {
          this.updateEarnings(this.estimatedEarnings.Result);
        }
      }
      if (propName === 'estimatingEarningsFlag') {
        this.estimatingEarningsFlag = changes[propName].currentValue;
      }
      if (propName === 'startOverFlag') {
        this.undoFunction();
      }
    }
  }
}
