import { Component, OnInit, Input } from '@angular/core';
import { Direct } from '../models/direct';

@Component({
  selector: 'mint-quota-to-go',
  templateUrl: './quota-to-go.component.html',
  styleUrls: ['./quota-to-go.component.scss']
})
export class QuotaToGoComponent implements OnInit {
  @Input() directs: Array<Direct>;
  @Input() isLoaded: boolean;
  @Input() showImages:boolean=true;

  constructor() {}

  ngOnInit() {}

  getClassByLevel(direct: Direct): string {
    const classByLevel = `direct__itemLevel${direct.level}`;
    return classByLevel;
  }
}
