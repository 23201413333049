import { Expose, Type, plainToClass } from 'class-transformer';
import { BaseModel } from 'src/app/shared/models/base.model';
import { PayComponent } from './pay-component.model';
import { IDeserializable } from 'src/app/shared/models/iDeserializable.interface';

export class PayIncentiveMix extends BaseModel implements IDeserializable<PayIncentiveMix> {
  @Type(() => PayComponent)
  @Expose({ name: 'Components' })
  components: PayComponent[];

  deserialize(from: object): PayIncentiveMix {
    const payIncentiveMix = plainToClass<PayIncentiveMix, object>(PayIncentiveMix, from, this.classTransformOptions);
    return payIncentiveMix;
  }
}
