<div class="excel__export__container">
  <span class="excel__export__lbl" *ngIf="labelText">{{ labelText }}</span>
  <div
    role="button"
    tabindex="0"
    [className]="isExportDisabled ? 'excel__export__disabled' : 'excel__export'"
    id="exportToExcel_1_{{ id }}"
    [title]="downloadInactive ? 'downloading' : title"
    analytics-tracker="ExporttoExcel"
    analytics-type="Click"
    analytics-element="Button"
    analytics-source="Export to Excel"
    download-button
    (click)="onExportToExcel()"
    (keyup.enter)="onExportToExcel()"
    [ngClass]="{ 'excel__export-inactive': downloadInactive }"
  >
    <mint-export-icon *ngIf="icon === 'excel'" [id]="id + '_label'"></mint-export-icon>
    <img *ngIf="icon === 'pdf'" alt="pdf download icon" class="pdf__icon" src="../../../../assets/images/pdf.png" />
  </div>
  <div *ngIf="downloadInactive" class="pre__loader"></div>
</div>
