import { Component, OnInit, Inject } from '@angular/core';
import { DetailSummaryTilesService } from '../detail-summary-tiles.service';
import * as _ from 'underscore';
import { Formatter } from '@mint-libs/common';
import * as Ng1Services from '../../../core/hybrid/ng1-upgraded-providers';
import { Input } from '@angular/core';
import { ChartType } from '../../../shared/metric-chart/chartType.enum';
import { ExportToExcelService } from '../../../shared/excel-export/export-to-excel.service';
import { FiltersService } from '../../../filters/filters.service';

@Component({
  selector: 'mint-products-summary',
  templateUrl: './products-summary.component.html',
  styleUrls: ['./products-summary.component.scss']
})
export class ProductsSummaryComponent implements OnInit {
  @Input() loadSummaryReportHeading = false;
  chartType: ChartType;
  noData = false;
  errorOccurred = false;
  lastRefreshDate = null;
  products: any;
  productsDataset: any;
  filterService: any;
  chartHelpText = 'Lists all products in your plan by revenue.';
  loadOnDemand = false;
  loaded = false;
  incentiveType = null;
  exportToExcelService: any;
  showExportLoader;

  // TODO: to be refactored
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  constructor(
    private detailSummaryTilesService: DetailSummaryTilesService,
    private formatter: Formatter,
    filterService: FiltersService,
    private exportToExcelServicenNew: ExportToExcelService
  ) {
    this.chartType = ChartType.RevenueProduct;
    this.filterService = filterService;
  }

  ngOnInit() {
    this.loadOnDemand = this.detailSummaryTilesService.onDemandLoadProducts;

    if (this.loadOnDemand) {
      this.getProductsData();
    }
  }

  getProductsData() {
    this.loadOnDemand = true;
    this.detailSummaryTilesService.getRevenueByProduct().subscribe(result => {
      const productsResult = result;
      this.products = productsResult;
      if(result!=null){
        this.detailSummaryTilesService.getPCCInfoForDetailReports('Product').subscribe(pccList => {
          this.products.data = this.filterForPIT(pccList, productsResult.data, this);
          if (productsResult && productsResult.data.length && productsResult.data.length > 0) {
            this.noData = false;
            this.errorOccurred = false;
            this.lastRefreshDate = productsResult.DataGenerationTime ? this.formatter.transformToLocalDateTz(productsResult.DataGenerationTime, 'MMM D, YYYY, h:MM A ', true) : null;
            this.incentiveType = productsResult.IncentiveType;
            this.setProductsData(productsResult);
            // TODO: Implement the PIT type PCC case
            // TODO: Set properties for the bar graph
          } else {
            this.noData = true;
          }
          this.loaded = true;
        });
      }
      else{
        this.noData = true;
        this.loaded = true;
      }
    });
  }

  setProductsData(products) {
    const groupedData = _.groupBy(products.data, function (item) {
      return item.ProductName;
    });

    this.productsDataset = _.map(
      _.toArray(groupedData),
      function (groupedItem) {
        const label = groupedItem[0].ProductName;
        let value = 0;
        const metric = groupedItem[0].Revenue.Metric !== null ? groupedItem[0].Revenue.Metric : null;

        _.each(groupedItem, function (item) {
          value += item.Revenue.ActualAmount;
        });
        // Get context info
        const allProducts = this.products.data;
        const filteredProducts = _.filter(allProducts, function (p) {
          return p.ProductName === label;
        });
        const pccs = _.map(filteredProducts, product => {
          return {
            name: product.PCCName,
            revenue: this.formatter.roundOff(product.Revenue.ActualAmount),
            revenueAdjustment: this.formatter.roundOff(product.Revenue.ActualAdjustmentAmount),
            modifier: this.formatter.roundOff(product.Revenue.ActualModifier),
            totalRevenue:
              this.formatter.roundOff(product.Revenue.ActualAmount) + this.formatter.roundOff(product.Revenue.ActualAdjustmentAmount) + this.formatter.roundOff(product.Revenue.ActualModifier),
            metric: metric
          };
        });

        const sum = function (total, next) {
          return total + next;
        };
        const gPCCs = _.mapObject(
          _.groupBy(pccs, 'name'),
          function (values, name) {
            return {
              name: name,
              revenue: this.formatter.roundOff(_.reduce(_.pluck(values, 'revenue'), sum, 0)),
              revenueAdjustment: this.formatter.roundOff(_.reduce(_.pluck(values, 'revenueAdjustment'), sum, 0)),
              modifier: this.formatter.roundOff(_.reduce(_.pluck(values, 'modifier'), sum, 0)),
              totalRevenue: this.formatter.roundOff(_.reduce(_.pluck(values, 'totalRevenue'), sum, 0)),
              metric: metric
            };
          },
          this
        );

        return {
          desc: label,
          val: value,
          pccs: gPCCs,
          metric: metric,
          a11yText: label + ' with ' + value + ' ' + metric + ' revenue'
        };
      },
      this
    );
    this.productsDataset = _.sortBy(this.productsDataset, p => p.val);
    this.productsDataset = this.productsDataset.reverse();
  }

  private filterForPIT(pccList, products, context) {
    const filteredProducts = _.filter(
      products,
      function (product) {
        const pcc = pccList.find(p => p.Name === product.PCCName);
        if (!pcc.IsPointInTime) {
          return true;
        }
        const partition = _.find(
          this.filterService.selectedStandardTitle.ApplicablePartitions,
          function (p) {
            return p.StepId === this.filterService.selectedStepId;
          },
          this
        );
        const endDate =
          new Date(partition.Duration.EndDate) < new Date(this.filterService.selectedStandardTitle.EndDate)
            ? new Date(partition.Duration.EndDate)
            : new Date(this.filterService.selectedStandardTitle.EndDate);
        return product.Revenue.Month.split(',')[0] === this.months[endDate.getMonth()];
      },
      context
    );
    return filteredProducts;
  }

  export(fileName: string) {
    this.showExportLoader = true;
    this.detailSummaryTilesService.exportRevenueByProduct().subscribe(result => {
      this.exportToExcelServicenNew.getFileName(fileName);
      this.exportToExcelServicenNew.downloadOpenFile(result, fileName);
      this.showExportLoader = false;
    });
  }
}
