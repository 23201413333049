import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from '../user-sidebar/sidebar.service';
import { NavigationService } from 'src/app/core/navigation/navigation.service';
import { AuthenticationService } from '@mint-libs/authentication';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {
  private navigationFactory: any;
  userProfileInfo: any;
  isFalse = false;
  isFeatureAdminActive: boolean;
  isNotificationActive: boolean;
  isSettingsActive: boolean;
  sharedContext = new SharedContext();

  constructor(private router: Router, private sidebarService: SidebarService, navigationFactory: NavigationService, private sharedContextState: Store<SharedContext>) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.navigationFactory = navigationFactory;
  }

  ngOnInit() {
    this.userProfileInfo = this.sharedContext.userProfileInfo;
    this.setFeatureActivation();
  }

  getSidebarStatus() {
    return this.sidebarService.isSidebarActive;
  }

  isFeatureActive(fragment) {
    return this.router.url === fragment;
  }

  setFeatureActivation() {
    this.isFeatureAdminActive = this.isFeatureActive('/featureAdmin');
    this.isNotificationActive = this.isFeatureActive('/manageNotification');
  }

  navigateTo(item, id) {
    this.navigationFactory.navigateTo(item, id).then(status => this.setFeatureActivation());
  }

  toggleSidebar() {
    this.sidebarService.isSidebarActive = !this.sidebarService.isSidebarActive;

    if (this.sidebarService.isSidebarActive) {
      this.navigationFactory.markAllMenuList();
    }
  }

  logout() {}
}
