import { AfterViewChecked, Component, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { LegendService } from '../legends/legend.service';
import { CellClassParams } from 'ag-grid-community';

@Component({
  template: ''
})

export class BaseGridComponent implements AfterViewChecked {
  columnDefs: any;
  gridOptions: any;
  rowData: any;
  gridApi: any;
  gridColumnApi: any;
  frameworkComponents: any;
  defaultColDef: any;
  domLayout: any;
  @Input() gridLabel: string; // provide grid label for accessibility purposes

  legendClassRules: any;
  constructor(protected legendService: LegendService, @Inject(Number) rowHeight: number, protected element?: ElementRef) {
    this.domLayout = 'autoHeight';
    this.setGridOptions(rowHeight);
    this.setLegendClassRules();
  }

  ngAfterViewChecked() {
     // add table caption to ag-grid accessibility fix
     let agGrid = this.element.nativeElement.querySelector('ag-grid-angular div[role="grid"]');
     if (agGrid) {
       agGrid.setAttribute('aria-label', this.gridLabel);
     }
  }

  setGridOptions(rowHeight) {
    this.gridOptions = {
      context: this,
      suppressCellSelection: true,
      suppressRowTransform: true,
      onGridReady: this.onGridReady,
      columnDefs: this.columnDefs,
      rowHeight: rowHeight,
      ensureDomOrder: true,
      suppressColumnVirtualisation: true,
      headerHeight: 42
    };
  }

  setLegendClassRules(): any {
    this.legendClassRules = {
      'cell-span-below-threshold': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, params.data, params.context.legendService.legends.belowThreshold);
      },
      'cell-span-below-target': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, params.data, params.context.legendService.legends.belowTarget);
      },
      'cell-span-below-excellence': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, params.data, params.context.legendService.legends.belowExcellence);
      },
      'cell-span-above-excellence': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, params.data, params.context.legendService.legends.aboveExcellence);
      },
      'cell-span-neutral': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, params.data, params.context.legendService.legends.neutral);
      },
      'cell-span-rubi-neutral': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, params.data, params.context.legendService.legends.rubiNeutral);
      },
      'cell-span-ocp-metric': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, params.data, params.context.legendService.legends.ocpMetric);
      }
    };
  }

  getLegendClassRules(parentObjectKey: string): any {
    return {
      'cell-span-below-threshold': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, parentObjectKey ? params.data[parentObjectKey] : params.data, params.context.legendService.legends.belowThreshold);
      },
      'cell-span-below-target': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, parentObjectKey ? params.data[parentObjectKey] : params.data, params.context.legendService.legends.belowTarget);
      },
      'cell-span-below-excellence': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, parentObjectKey ? params.data[parentObjectKey] : params.data, params.context.legendService.legends.belowExcellence);
      },
      'cell-span-above-excellence': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, parentObjectKey ? params.data[parentObjectKey] : params.data, params.context.legendService.legends.aboveExcellence);
      },
      'cell-span-neutral': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, parentObjectKey ? params.data[parentObjectKey] : params.data, params.context.legendService.legends.neutral);
      },
      'cell-span-rubi-neutral': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, parentObjectKey ? params.data[parentObjectKey] : params.data, params.context.legendService.legends.rubiNeutral);
      },
      'cell-span-ocp-metric': function (params: CellClassParams) {
        return params.context.isCellClassApplicable(params.context, parentObjectKey ? params.data[parentObjectKey] : params.data, params.context.legendService.legends.ocpMetric);
      }
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const _this = this;
    if (!this.gridOptions.api) {
      return;
    }
    setTimeout(() => {
      _this.gridOptions.api.sizeColumnsToFit();
    });
  }

  isCellClassApplicable(context, row, legend) {
    const isApplicable = row ? context.legendService.getColor(row) === legend : false;
    return isApplicable;
  }
}
