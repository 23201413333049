import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FeatureAdminService } from './feature-admin.service';
import { FeatureAdminFilterService } from './feature-admin-filters.service';
import { SplashComponent } from '../splash/splash.component';
import { FeatureGroup } from '../shared/models/feature-group.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CachingService } from '../core/caching/caching.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@mint-libs/authentication';

@Component({
  selector: 'mint-feature-admin',
  templateUrl: './feature-admin.component.html',
  styleUrls: ['./feature-admin.component.scss']
})
export class FeatureAdminComponent implements OnInit {
  @ViewChild(SplashComponent)
  private splashComponent: SplashComponent;
  fgData: FeatureGroup[] = [];
  toggleAdd = false;
  showAlias = true;
  submenu = false;
  filters: any = {};
  currFG: any = {};
  deletedIdList: any = [];
  validationError1 = '';
  openPosition = -1;
  page = 1;
  pageSize = 10;
  sortCols = {
    startTimeAsc: true,
    endTimeAsc: true,
    statusAsc: true
  };
  statusCodes = { 0: 'Draft', 2: 'Published', 10: 'Expired' };
  dropdownSettings = {};
  adminAlias: string;
  modalRef: any;
  saveStatus = 'Saving ...';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    maxHeight: 'auto',
    minHeight: '300px',
    showToolbar: true,
    toolbarPosition: 'top',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Segoe UI',
    fonts: [
      { class: 'Segoe UI', name: 'Segoe UI' },
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote'
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1'
      }
    ]
  };

  constructor(
    private featureAdminService: FeatureAdminService,
    private featureAdminFilterService: FeatureAdminFilterService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private cacheService: CachingService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.cacheService.clear();
    this.getFeatureGroupData();
    this.getFilters();
    this.adminAlias = this.authService.getUserInfo().userName;
    this.adminAlias = this.adminAlias.substr(0, this.adminAlias.lastIndexOf('@'));

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  addNewFeatureIntro() {
    this.toggleAdd = !this.toggleAdd;
    this.submenu = false;
    this.deletedIdList = [];
    if (this.toggleAdd) {
      this.currFG = this.getNewFeatureGroup();

      this.featureAdminFilterService.consumeFilters(this.currFG.countryNames, this.filters.countries);
      this.featureAdminFilterService.consumeFilters(this.currFG.qualifier1Names, this.filters.qualifier1s);
      this.featureAdminFilterService.consumeFilters(this.currFG.qualifier2Names, this.filters.qualifier2s);
      this.featureAdminFilterService.consumeFilters(this.currFG.standardTitles, this.filters.standardTitles);
      this.featureAdminFilterService.consumeFilters(this.currFG.roles, this.filters.roles);
      this.featureAdminFilterService.consumeFilters(this.currFG.planNames, this.filters.plans);
      this.featureAdminFilterService.consumeFilters(this.currFG.businessSegment, this.filters.businessSegments);
      this.showAlias = true;
      this.filters.aliasList = 'ALL';
    }
  }

  editFeatureGroup(fg) {
    this.toggleAdd = !this.toggleAdd;
    this.submenu = false;
    this.deletedIdList = [];
    if (this.toggleAdd) {
      this.currFG = fg;

      if (this.currFG.aliasList === '' || !this.currFG.aliasList) {
        this.showAlias = false;
        this.featureAdminFilterService.consumeFilters(fg.countryNames, this.filters.countries);
        this.featureAdminFilterService.consumeFilters(fg.qualifier1Names, this.filters.qualifier1s);
        this.featureAdminFilterService.consumeFilters(fg.qualifier2Names, this.filters.qualifier2s);
        this.featureAdminFilterService.consumeFilters(fg.standardTitles, this.filters.standardTitles);
        this.featureAdminFilterService.consumeFilters(fg.roles, this.filters.roles);
        this.featureAdminFilterService.consumeFilters(fg.planNames, this.filters.plans);
        this.featureAdminFilterService.consumeFilters(fg.businessSegment, this.filters.businessSegments);
      } else {
        this.filters.aliasList = fg.aliasList !== ',ALL,' ? fg.aliasList.substring(1) : 'ALL';
      }
    }
  }

  toggleSubmenu() {
    this.submenu = !this.submenu;
  }

  // getFeatureGroupData() {
  //   this.featureAdminService.getFeatureGroups().subscribe(ret => {
  //     console.log(`Feature Groups ${ret}`);
  //     this.fgData = ret;
  //   });
  // }

  getNewFeatureGroup() {
    const featureGrp = {};

    featureGrp['featureGroupId'] = -1;
    featureGrp['featureHeader'] = '';
    featureGrp['startTime'] = new Date().toISOString().slice(0, 16);
    featureGrp['endTime'] = new Date(new Date().getTime() + 60 * 60 * 24 * 1000).toISOString().slice(0, 16);
    featureGrp['createdBy'] = this.adminAlias;
    featureGrp['modifiedBy'] = this.adminAlias;
    featureGrp['modifiedOn'] = new Date().toISOString().slice(0, 16);
    featureGrp['aliasList'] = '';
    featureGrp['countryNames'] = ',ALL,';
    featureGrp['planNames'] = ',ALL,';
    featureGrp['businessSegment'] = ',ALL,';
    featureGrp['roles'] = ',ALL,';
    featureGrp['standardTitles'] = ',ALL,';
    featureGrp['qualifier1Names'] = ',ALL,';
    featureGrp['qualifier2Names'] = ',ALL,';
    featureGrp['status'] = 0;
    featureGrp['featureIntroductions'] = [];

    return featureGrp;
  }

  validateInputs(currFG) {
    const timenow = new Date().toISOString().slice(0, 16);
    if (!currFG['featureHeader'] || currFG['featureHeader'].length > 50) {
      this.validationError1 = 'Please set valid header, capacity 50 char';
    } else if (!currFG['endTime'] || currFG['endTime'] <= timenow) {
      this.validationError1 = 'Please set valid end date-time, end date-time cannot be in past';
    } else if (new Date(currFG['startTime']) >= new Date(currFG['endTime'])) {
      this.validationError1 = 'Set Start date-time after End date-time';
    } else {
      this.validationError1 = '';
    }
  }
  getFeatureGroupData() {
    this.featureAdminService.GetAllFeatureGroups().subscribe(
      (response: FeatureGroup[]) => {
        this.fgData = response;
        this.fgData = this.fgData.reverse();
      },
      error => {
        console.log('error fetching Feature Intro');
      }
    );
  }

  sortByCol(key) {
    this.sortCols[key + 'Asc'] = !this.sortCols[key + 'Asc'];
    if (key === 'status') {
      this.fgData = this.fgData.sort((a, b) => a[key] - b[key]);
      return;
    }
    this.fgData = this.fgData.sort((a, b) => {
      if (this.sortCols[key + 'Asc']) {
        return new Date(a[key]) > new Date(b[key]) ? 1 : -1;
      } else {
        return new Date(a[key]) > new Date(b[key]) ? -1 : 1;
      }
    });
  }
  getFilters() {
    this.featureAdminService.GetAdminNotificationsBindings().subscribe(
      (response: any) => {
        this.featureAdminFilterService.initialize(this.filters);
        this.featureAdminFilterService.bindDropdowns(response, this.filters);
      },
      error => {
        console.log('failed to get filters');
      }
    );
  }

  deleteFeatureGroup(fg, index) {
    this.featureAdminService.DeleteFeatureGroup(fg.featureGroupId).subscribe(
      (response: any) => {
        this.fgData.splice(this.fgData.indexOf(fg), 1);
      },
      error => {
        // handle error
        console.log('error in deleting feature group');
      }
    );
  }

  previewFeatureGroup(fg) {
    this.splashComponent.openModalAdmin(fg);
  }

  addFeatureIntroduction(currFG) {
    if (currFG['featureIntroductions'].length < 5) {
      currFG['featureIntroductions'].push({
        featureGroupHead: '',
        featureGroupId: currFG.FeatureGroupId,
        pageLink: '',
        videoLink: '',
        imageLink: '',
        createdBy: this.adminAlias,
        updateBy: this.adminAlias,
        id: -1
      });
    }
  }

  removeFeatureIntroduction(currFG, index) {
    if (currFG.featureGroupId >= 0 && currFG['featureIntroductions'][index].id >= 0) {
      this.deletedIdList.push(currFG['featureIntroductions'][index].id);
    }
    currFG['featureIntroductions'].splice(index, 1);
  }

  openFeature(i) {
    this.openPosition = i === this.openPosition ? -1 : i;
  }

  getStatus(fg) {
    return new Date(fg['endTime']) > new Date(new Date().toISOString().substring(0, 23)) ? this.statusCodes[fg.status] : this.statusCodes[10];
  }

  saveFG(currFG, status) {
    if (status === 2) {
      this.modalRef.close();
      this.saveStatus = 'Publishing...';
    }
    this.validateInputs(currFG);
    if (!(this.validationError1 === '')) {
      this.submenu = false;
      return;
    }

    this.spinner.show();

    currFG['modifiedBy'] = this.adminAlias;
    currFG['status'] = status;
    currFG['countryNames'] = this.showAlias ? null : this.featureAdminFilterService.produceFilters(this.filters.countries);
    currFG['qualifier1Names'] = this.showAlias ? null : this.featureAdminFilterService.produceFilters(this.filters.qualifier1s);
    currFG['qualifier2Names'] = this.showAlias ? null : this.featureAdminFilterService.produceFilters(this.filters.qualifier2s);
    currFG['standardTitles'] = this.showAlias ? null : this.featureAdminFilterService.produceFilters(this.filters.standardTitles);
    currFG['roles'] = this.showAlias ? null : this.featureAdminFilterService.produceFilters(this.filters.roles);
    currFG['planNames'] = this.showAlias ? null : this.featureAdminFilterService.produceFilters(this.filters.plans);
    currFG['businessSegment'] = this.showAlias ? null : this.featureAdminFilterService.produceFilters(this.filters.businessSegments);
    currFG['aliasList'] = this.showAlias ? (this.filters.aliasList === 'ALL' || !this.filters.aliasList ? ',ALL,' : ';' + this.filters.aliasList) : null;

    this.filters.plans.selected = [];
    this.filters.standardTitles.selected = [];
    this.filters.roles.selected = [];
    this.filters.countries.selected = [];
    this.filters.businessSegments.selected = [];
    this.filters.qualifier1s.selected = [];
    this.filters.qualifier2s.selected = [];

    this.featureAdminService.AddOrUpdateFeatureGroup(currFG).subscribe(
      (response: any) => {
        currFG['featureGroupId'] = response.Result.FeatureGroupId;

        if (this.deletedIdList.length > 0) {
          this.featureAdminService.DeleteFeatureIntroduction(this.deletedIdList).subscribe(
            (responseDelete: any) => { },
            error => {
              // handle error
              console.log('failed to delete featureIntro line items.');
            }
          );
        }

        if (currFG['featureIntroductions'].length > 0) {
          for (let i = 0; i < currFG['featureIntroductions'].length; i++) {
            currFG['featureIntroductions'][i]['featureGroupId'] = currFG['featureGroupId'];
            currFG['featureIntroductions'][i]['updatedBy'] = this.adminAlias;
          }

          this.featureAdminService.AddOrUpdateFeatureIntroduction(currFG['featureIntroductions']).subscribe(
            response => {
              this.spinner.hide();
              this.cacheService.clear();
              this.getFeatureGroupData();
            },
            function (error) {
              console.log('failed to save featureIntro line items.');
            }
          );
        } else {
          this.spinner.hide();
          this.cacheService.clear();
          this.getFeatureGroupData();
        }

        this.toggleAdd = false;
        this.submenu = false;
      },
      error => {
        // handle error
        console.log('failed to save');
        this.toggleAdd = false;
        this.submenu = false;
      }
    );
  }

  open(content) {
    this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'mint-modal-backdrop', windowClass: 'mint-modal-window' });
  }

  onFileSelected(event, fintro) {
    const file: File = event.target.files[0];
    console.log('file ', file);
    if (file) {
      const fileName = file.name;
      const formData = new FormData();
      formData.append('thumbnail', file);
      const upload = this.featureAdminService.UploadFeatureImage(formData).subscribe(data => {
        if (data && data.imageLink) {
          console.log('uploaded successfully');
          fintro.imageLink = data.imageLink;
        }
      });
    }
  }
}
