import { Expose, plainToClass, deserializeArray, Transform } from 'class-transformer';
import { BaseModel } from './base.model';
import { IDeserializable } from './iDeserializable.interface';

export class Payline extends BaseModel implements IDeserializable<Payline[]> {
  @Expose({ name: 'PCGId' })
  pcgId: number;
  @Expose({ name: 'StepId' })
  StepId: number;
  @Expose({ name: 'PcgName' })
  pcgName: string;
  @Expose({ name: 'IncentiveType' })
  incentiveType: string;
  @Expose({ name: 'Weightage' })
  @Transform(({value}) => Number(value) * 100, { toClassOnly: true })
  weightage: number;
  @Expose({ name: 'Attainment' })
  attainment: number;
  @Expose({ name: 'AttainmentStatus' })
  attainmentStatus?: string;
  @Expose({ name: 'SimulatedAttainment' })
  simulatedAttainment?: number;
  @Expose({ name: 'PayoutPercentage' })
  payoutPercentage?: number;
  @Expose({ name: 'PCGCurrency' })
  pcgCurrency: string;
  // Earnings
  @Expose({ name: 'PCGEarnings' })
  pcgEarnings?: number;
  @Expose({ name: 'PCGActiveEarnings' })
  pcgActiveEarnings?: number;
  @Expose({ name: 'PCGTAEarnings' })
  pcgTAEarnings?: number;
  @Expose({ name: 'PCGLOAEarnings' })
  pcgLOAEarnings?: number;
  // Payline stages
  @Expose({ name: 'Threshold' })
  threshold: number;
  @Expose({ name: 'ThresholdPayout' })
  thresholdPayout: number;
  @Expose({ name: 'Target' })
  target: number;
  @Expose({ name: 'TargetPayout' })
  targetPayout: number;
  @Expose({ name: 'Excellence' })
  excellence: number;
  @Expose({ name: 'ExcellencePayout' })
  excellencePayout: number;
  @Expose({ name: 'PayoutAtThreshold' })
  payoutAtThreshold?: number;
  @Expose({ name: 'AttainmentAtThreshold' })
  attainmentAtThreshold?: number;
  @Expose({ name: 'MultiplierTillThreshold' })
  multiplierTillThreshold?: number;
  @Expose({ name: 'PayoutThresholdToTarget' })
  payoutThresholdToTarget?: number;
  @Expose({ name: 'AttainmentThresholdToTarget' })
  attainmentThresholdToTarget?: number;
  @Expose({ name: 'MultiplierThresholdToTarget' })
  multiplierThresholdToTarget?: number;
  @Expose({ name: 'PayoutTargetToExcellence' })
  payoutTargetToExcellence?: number;
  @Expose({ name: 'AttainmentTargetToExcellence' })
  attainmentTargetToExcellence?: number;
  @Expose({ name: 'MultiplierTargetToExcellence' })
  multiplierTargetToExcellence?: number;
  @Expose({ name: 'PayoutBeyondExcellence' })
  payoutBeyondExcellence?: number;
  @Expose({ name: 'AttainmentBeyondExcellence' })
  attainmentBeyondExcellence?: number;
  @Expose({ name: 'MultiplierBeyondExcellence' })
  multiplierBeyondExcellence?: number;
  // Caps
  @Expose({ name: 'SoftCap' })
  softCap?: number;
  @Expose({ name: 'HardCap' })
  hardCap?: number;
  @Expose({ name: 'UsedCap' })
  usedCap?: number;
  @Expose({ name: 'HurdleCap' })
  hurdleCap?: string;
  @Expose({ name: 'IsHurdleCapApplicable' })
  isHurdleCapApplicable: boolean;
  setupLevel: string;

  deserialize(from: any): Payline[] {
    const paylines = deserializeArray<Payline>(Payline, JSON.stringify(from), this.classTransformOptions);
    return paylines;
  }

  deserializeSingle(from: object): Payline {
    const paylines = plainToClass<Payline, object>(Payline, from, this.classTransformOptions);
    return paylines;
  }
}
