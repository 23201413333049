import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  addToLocalStorage(key, value) {
    if (key && value) {
      window.localStorage.setItem(key, value);
    }
  }

  deleteLocalStorageKey(key) {
    window.localStorage.removeItem(key);
  }

  getValueFromLocalStorage(key) {
    return window.localStorage.getItem(key);
  }
}
