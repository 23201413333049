import { Component, OnInit, Input, OnChanges, SimpleChanges, ContentChild, TemplateRef } from '@angular/core';
import { TooltipSize } from '../tooltip/tooltip-size.enum';
import { TooltipOrientation } from '../tooltip/tooltip-orientation.enum';

@Component({
  selector: 'mint-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnChanges {
  @Input() tipText;
  @Input() id;
  @Input() orientation = TooltipOrientation.Left;
  @Input() size = TooltipSize.Normal;
  @ContentChild('tipBody', { read: TemplateRef }) tipContent;
  tooltipOrientaionMap: Map<string, string>;
  tooltipSizeMap: Map<string, string>;
  tooltipOrientedClass: any;
  tooltipSizeClass: any;

  constructor() {
    this.setTooltipOrientaionMap();
    this.setTooltipSizeMap();
    this.tooltipOrientedClass = this.tooltipOrientaionMap.get(this.orientation);
    this.tooltipSizeClass = this.tooltipSizeMap.get(this.size);
  }

  ngOnChanges() {
    if (this.orientation) {
      this.tooltipOrientedClass = this.tooltipOrientaionMap.get(this.orientation);
    }
    if (this.size) {
      this.tooltipSizeClass = this.tooltipSizeMap.get(this.size);
    }
  }

  setTooltipOrientaionMap() {
    this.tooltipOrientaionMap = new Map();
    this.tooltipOrientaionMap.set(TooltipOrientation.Left, 'tooltip__content__left');
    this.tooltipOrientaionMap.set(TooltipOrientation.ExtraLeft, 'tooltip__content__extra_left');
    this.tooltipOrientaionMap.set(TooltipOrientation.Right, 'tooltip__content__right');
    this.tooltipOrientaionMap.set(TooltipOrientation.Center, 'tooltip__content__center');
  }

  setTooltipSizeMap() {
    this.tooltipSizeMap = new Map();
    this.tooltipSizeMap.set(TooltipSize.Normal, 'tooltip__content__normal');
    this.tooltipSizeMap.set(TooltipSize.Small, 'tooltip__content__small');
    this.tooltipSizeMap.set(TooltipSize.Large, 'tooltip__content__large');
    this.tooltipSizeMap.set(TooltipSize.ExtraLarge, 'tooltip__content__extralarge');
    this.tooltipSizeMap.set(TooltipSize.XXLarge, 'tooltip__content__xxlarge');
  }
}
