<content-loader [height]="300" [width]="1000">
  <svg:rect x="30" y="80" rx="4" ry="4" width="45" height="220" />
  <svg:rect x="140" y="80" rx="4" ry="4" width="45" height="220" />
  <svg:rect x="245" y="80" rx="4" ry="4" width="45" height="220" />
  <svg:rect x="350" y="80" rx="4" ry="4" width="45" height="220" />
  <svg:rect x="460" y="80" rx="4" ry="4" width="45" height="220" />
  <svg:rect x="565" y="80" rx="4" ry="4" width="45" height="220" />
  <svg:rect x="670" y="80" rx="4" ry="4" width="45" height="220" />
  <svg:rect x="780" y="80" rx="4" ry="4" width="45" height="220" />
  <svg:rect x="885" y="80" rx="4" ry="4" width="45" height="220" />
</content-loader>
