import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleEstimatorComponent } from "./toggle-estimator/toggle-estimator.component";
import { UbiEstimatorCardComponent } from "./ubi-estimator-card/ubi-estimator-card.component";
import { UbiEstimatorComponent } from "./ubi-estimator/ubi-estimator.component";
import { BucketEstimatorComponent } from '../bucket-estimator/bucket-estimator.component';
import { BucketEstimatorBucketCardComponent } from '../bucket-estimator-bucket-card/bucket-estimator-bucket-card.component';
import { BucketEstimatorEarningsComponent } from '../bucket-estimator/bucket-estimator-earnings/bucket-estimator-earnings.component';
import { SharedModule } from '../shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule],
  declarations: [UbiEstimatorComponent, UbiEstimatorCardComponent, ToggleEstimatorComponent,
    BucketEstimatorComponent, BucketEstimatorBucketCardComponent, BucketEstimatorEarningsComponent],
  providers: [],
  exports: [ToggleEstimatorComponent, BucketEstimatorComponent, UbiEstimatorComponent]
})
export class EstimatorModule { }
