import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from './network/data.service';
import { environmentDefault } from '@mint-libs/common';

@Injectable({
  providedIn: 'root'
})
export class GraphService {
  graphResource : string = environmentDefault.graphResource;
  tenantConfig = {
    tenant: environmentDefault.graphDomain,
    thumbnailEndpoint: this.graphResource + '/v1.0/users/{0}/photo/$value'
  };
  constructor(private http: HttpClient, private dataService: DataService) {}
  getThumbnailPhoto(alias) {
    const thumbnailUrl = this.tenantConfig.thumbnailEndpoint.replace('{0}', alias + '@' + this.tenantConfig.tenant);
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'image/jpeg');
    headers = headers.append('Content-Type', 'text/plain');
    const options = { headers: headers, responseType: 'arraybuffer' as 'arraybuffer' };
    return this.http.get(thumbnailUrl, options).pipe(
      map(response => {
        const bytes = new Uint8Array(response);
        const data = this.bufferToBase64(bytes);
        return data;
      })
    );
  }
  bufferToBase64(buf) {
    const binstr = Array.prototype.map
      .call(buf, function(ch) {
        return String.fromCharCode(ch);
      })
      .join('');
    return btoa(binstr);
  }
}
