<!-- Side panel notifications -->
<!-- <mic-notifications
  id="header_1"
  toggle-panel="showNotes"
  unread-count="unReadCount"
  show-notes="showNotes"
  [hidden]="!(showNotes === true)"
  class="no-print"
  (paneClosed)="onPaneClosed($event)"
></mic-notifications> -->
<!-- <div role="contentinfo ">
  <mint-bot *ngIf="enableBot()"></mint-bot>
</div> -->

<span id="header_2" class="skip-main" role="link" (keyup.enter)="skipMainContent()" (click)="skipMainContent()" tabindex="0" aria-label="Skip to main content">Skip</span>

<div id="header" class="header-impersonation" role="banner">
  <div id="header_3" class="col">
    <div class="icon-container" (click)="toggleSidebar()" (keyup.enter)="toggleSidebar()" name="Navigation Menu" aria-label="Navigation Menu" id="hamburger-menu" role="button" tabindex="0">
      <em id="header_4" class="icon icon-hamburger"></em>
    </div>
    <h1 id="header_5" class="title">{{ title }}</h1>
  </div>
  <app-impersonation-notification
    id="home_7"
    tabindex="0"
    ariaLabelledBy="title"
    analytics-tracker
    [tracker]="'User_Impersonation'"
    *ngIf="isImpersonatingOrViewAs()"
    class="impersonation"
  >
  </app-impersonation-notification>
  <div id="header_6" class="col">
    <div id="header_7" class="pull-right" style="display: flex;">
      <!-- <div id="header_8" *ngIf="sharedContext.hasTour" class="header-info header-link" [style.padding-top.px]="12">
        <span id="header_9">
          <a
            id="header_10"
            role="button"
            title="Play PageTour"
            class="pageTourHeader"
            tabindex="0"
            arialabelledby="title"
            (keyup.enter)="playPageTour()"
            (click)="playPageTour()"
            analytics-tracker
            [tracker]="'PageTour_Play'"
            [type]="'Click'"
            [element]="'Hyperlink'"
            [source]="'HeaderV2'"
            >Play Tour</a
          >
        </span>
      </div> -->
      <div id="header_11" *ngIf="canCreateTour" class="header-info header-link" [style.padding-top.px]="12">
        <span id="header_12">
          <a
            id="header_13"
            role="button"
            title="Manage PageTours"
            class="pageTourHeader"
            tabindex="0"
            arialabelledby="title"
            (keyup.enter)="managePageTour()"
            (click)="managePageTour()"
            analytics-tracker
            [tracker]="'PageTour_Manage'"
            [type]="'Click'"
            analytics-element="'Hyperlink'"
            [source]="'HeaderV2'"
            >Manage Tours
          </a>
        </span>
      </div>
      <div id="header_14" *ngIf="canCreateTour" class="header-info header-link" [style.padding-top.px]="12">
        <span id="header_15">
          <a
            id="header_16"
            role="button"
            title="Author PageTour"
            class="pageTourHeader"
            tabindex="0"
            arialabelledby="title"
            (keyup.enter)="authorPageTour()"
            (click)="authorPageTour()"
            analytics-tracker
            [tracker]="'PageTour_Author'"
            [type]="'Click'"
            [element]="'Hyperlink'"
            [source]="'HeaderV2'"
            >Author Tour
          </a>
        </span>
      </div>
      <div id="header_17" class="header-info header-link" [style.padding-top.px]="12" *ngIf="isPreviewUser">
        <span id="header_18">
          <a
            id="header_19"
            role="button"
            title="Go to Mint Insiders"
            tabindex="0"
            arialabelledby="title"
            (keyup.enter)="navigateToMintInsiders()"
            class="pageTourHeader"
            (click)="navigateToMintInsiders()"
            analytics-tracker
            [tracker]="'Mint_Insiders'"
            [type]="'Click'"
            [element]="'Hyperlink'"
            [source]="'HeaderV2'"
            >What is Mint Insiders?</a
          >
        </span>
      </div>
      <div id="header_20" class="header-info header-link" [style.padding-top.px]="12" *ngIf="isDemoEnabled">
        <span id="header_21">
          <a
            id="header_22"
            role="button"
            title="Go to Demo app"
            tabindex="0"
            arialabelledby="title"
            (keyup.enter)="openDemoApp()"
            class="pageTourHeader"
            (click)="openDemoApp()"
            analytics-tracker
            [tracker]="'Goto_DemoApp'"
            [type]="'Click'"
            [element]="'Hyperlink'"
            [source]="'HeaderV2'"
            >Demo</a
          >
        </span>
      </div>
      <div id="header_23" class="separator" *ngIf="isDemoEnabled"></div>
      <div>
        <div
          class="header-info hover"
          id="NotifButton"
          [attr.aria-label]="'Show Notification with ' + sharedContext.unreadCount + ' Notifications'"
          [hidden]="!isNotificationFeatureShown()"
          ng-init="isRead = false;"
          [ngClass]="{ active: showNotes }"
          role="button"
          tabindex="0"
          (click)="showNotes = !showNotes; isRead = true"
          (keyup.enter)="showNotes = !showNotes; isRead = true"
        >
          <div id="header_24" class="btn-icon">
            <span id="header_25" class="icon icon-bell-outline"></span>
            <!-- <notification-count id="header_26"></notification-count> -->
            <span
              *ngIf="sharedContext.unreadCount > 0"
              id="header_27"
              analytics-tracker
              [tracker]="'Notifications_View_NotificationMessage'"
              [type]="'Click'"
              [element]="'Span'"
              [source]="'notifications'"
              class="note-num"
              >{{ sharedContext.unreadCount }}</span
            >
          </div>
          <span class="sr-only" role="alert" aria-live="polite" *ngIf="sharedContext.unreadCount != 0 && title === 'Dashboard'">you have {{ sharedContext.unreadCount }} new Notifications</span>
        </div>
        <mint-notifications-pane id="header_1" [hidden]="!(showNotes === true)" class="no-print" (paneClosed)="onPaneClosed()"> </mint-notifications-pane>
      </div>
      <div id="header_43" class="separator"></div>
      <div class="header-info hover" role="button" id="SettingsButton" aria-label="Settings" tabindex="0" (click)="navigateToSettings()" (keyup.enter)="navigateToSettings()" title="Settings">
        <div id="header_44" class="btn-icon"><span id="header_44" class="icon icon-setting"></span></div>
      </div>
     
      <div id="header_28" class="separator"></div>
      <div class="header-info hover" role="button" id="RefreshButton" aria-label="Refresh" tabindex="0" (click)="refresh()" (keyup.enter)="refresh()" title="Refresh the page">
        <div id="header_29" class="btn-icon"><span id="header_30" class="icon icon-refresh"></span></div>
      </div>
      <div id="header_31" class="separator" *ngIf="!isDemoMode2"></div>
      <div id="header_32" class="header-info header-link" [style.padding-top.px]="12" *ngIf="!isDemoMode2">
        <button
          type="button"
          id="header_33"
          (click)="openSupportModal()"
          title="Support button has popup"
          class="btn__support"
          analytics-tracker
          [tracker]="'Support'"
          [type]="'Click'"
          [element]="'Hyperlink'"
          [source]="'Header'"
        >
          Support
        </button>
      </div>
      <div id="header_33_1" class="separator" *ngIf="!isDemoMode2"></div>
      <div
        *ngIf="isOcvVisible"
        class="header-info hover feedback-header"
        role="button"
        id="smileyButton"
        aria-label="Feedback"
        tabindex="0"
        (click)="showInAppFeedbackPopup()"
        (keyup.enter)="showInAppFeedbackPopup()"
        title="Provide feedback"
        analytics-tracker
        [tracker]="'Provide_InAppFeedback'"
        [type]="'Click'"
        [element]="'Hyperlink'"
        [source]="'HeaderV2'"
      >
        <div id="header_34" class="btn-icon feedback-icon"></div>
      </div>
    </div>
  </div>
</div>
