import { MeasureType, TMeasureTypeMapping } from '../types/measure-type-mapping.type';

export class MeasureTypeMappingState {
  mappings: TMeasureTypeMapping[];
  isFetched: boolean = false;

  constructor(data: TMeasureTypeMapping[], isFetched: boolean = false) {
    this.mappings = data;
    this.isFetched = isFetched;
  }

  getMeasureType(pccId: string | number): MeasureType {
    const pccIdNum = typeof pccId === 'string' ? Number.parseInt(pccId) : pccId;
    const matchingMappings = this.mappings.find(mapping => {
      return mapping.pccid === pccIdNum;
    });
    if (!matchingMappings) {
      return MeasureType.DefaultRecurring;
    }
    return matchingMappings.quotaMeasureType;
  }

  doesBucketContainMeasureType(pcgId: string | number, measureType: MeasureType): boolean {
    const pcgIdNum = typeof pcgId === 'string' ? Number.parseInt(pcgId) : pcgId;
    return this.mappings.reduce((prev, mapping) => {
      return (mapping.pcgid === pcgIdNum && mapping.quotaMeasureType === measureType) || prev;
    }, false);
  }
}
