import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BonusV2RoutingModule } from './bonus-v2-routing.module';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BonusV2Component } from './bonus-v2.component';
import { A11yModule } from '@angular/cdk/a11y';
import { BonusSummaryHeaderComponent } from './bonus-summary-header/bonus-summary-header.component';
import { BonusBucketsSummaryHeaderComponent } from './bonus-summary-header/bonus-buckets-summary-header/bonus-buckets-summary-header.component';
import { BonusAwardsSummaryHeaderComponent } from './bonus-summary-header/bonus-awards-summary-header/bonus-awards-summary-header.component';
import { BonusDetailViewComponent } from './bonus-detail-view/bonus-detail-view.component';
import { BonusGrainItemChartComponent } from './bonus-detail-view/bonus-grain-item-chart/bonus-grain-item-chart.component';
import { BonusGrainItemGridComponent } from './bonus-detail-view/bonus-grain-item-grid/bonus-grain-item-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { CustomHeaderComponent } from '../shared/grid-components/custom-header/custom-header.component';
import { BonusAmountRendererComponent } from './bonus-detail-view/bonus-grain-item-grid/renderers/bonus-amount-renderer.component';
import { BonusMetricRendererComponent } from './bonus-detail-view/bonus-grain-item-grid/renderers/bonus-metric-renderer.component';
import { BonusAttainmentRendererComponent } from './bonus-detail-view/bonus-grain-item-grid/renderers/bonus-attainment-renderer.component';

@NgModule({
  declarations: [
    BonusV2Component,
    BonusSummaryHeaderComponent,
    BonusBucketsSummaryHeaderComponent,
    BonusAwardsSummaryHeaderComponent,
    BonusDetailViewComponent,
    BonusGrainItemChartComponent,
    BonusGrainItemGridComponent,
    BonusAmountRendererComponent,
    BonusMetricRendererComponent,
    BonusAttainmentRendererComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    AgGridModule.withComponents([
      CustomHeaderComponent, 
      BonusAmountRendererComponent,
      BonusMetricRendererComponent,
      BonusAttainmentRendererComponent, ]),
    BonusV2RoutingModule,
    A11yModule,
  ],
})
export class BonusV2Module { }
