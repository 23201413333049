import { Component, OnInit, Inject, Input, Output, EventEmitter, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from '../notifications.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NotificationServiceFactory } from '../../core/context/notificationFactory.service';
import { from } from 'rxjs';
import { notificationSvgIcons } from '../../shared/notification.icon.svg';
import { Router } from '@angular/router';
import { ContextState } from '@mint-libs/context';
import { selectConfigurationState } from '@mint-libs/configuration';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from 'src/app/core/navigation/navigation.service';

@Component({
  selector: 'mint-notifications-pane',
  templateUrl: './notifications-pane.component.html',
  styleUrls: ['./notifications-pane.component.scss']
})
export class NotificationsPaneComponent implements OnInit, OnChanges {
  limit = 0;
  NotificationConfig: any;
  NotificationTimer: any;
  setOrder = true;
  notes = [];
  visibleNotes = [];
  unreadCount = 0;
  showNotes = false;
  loading = false;
  config: any;
  iconError?: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconError + notificationSvgIcons.iconColorGreen);
  iconInfo?: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconInfo + notificationSvgIcons.iconColorGreen);
  iconAlert?: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconAlert + notificationSvgIcons.iconColorGreen);
  iconWarning?: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconWarning + notificationSvgIcons.iconColorGreen);
  iconBanner?: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconBanner + notificationSvgIcons.iconColorGreen);
  iconMap: Map<number, SafeHtml>;

  @Input() togglePanel: boolean;
  @Output() paneClosed: EventEmitter<any> = new EventEmitter<any>();
  public sharedContext: SharedContext = new SharedContext();

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closePanel();
    }
  }

  constructor(
    private notificationServiceFactory: NotificationServiceFactory,
    private notificationService: NotificationService,
    public sharedContextState: Store<SharedContext>,
    private rolefeatureService: RoleFeatureService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private contextState: Store<ContextState>
  ) {
    this.getConfigDetails();
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    //this.NotificationConfig = this.config.notificationConfig;
    if (!this.NotificationConfig) {
      // Incase the Notification Config is not Set
      this.NotificationConfig = {};
      this.NotificationConfig.NotificationMessageLimit = 5;
      this.NotificationConfig.NotificationTimer = 3000;
    }

    this.limit = parseInt(this.NotificationConfig.NotificationMessageLimit, 10);
    this.NotificationTimer = parseInt(this.NotificationConfig.NotificationTimer, 10);

    this.iconMap = new Map<number, SafeHtml>();
    this.initIcons();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkForNewNotification(false);
  }

  ngOnInit() {
    this.checkForNewNotification(false);
  }

  private getConfigDetails() {
    this.contextState
      .pipe(
        select(selectConfigurationState),
        filter(val => val !== undefined)
      )
      .subscribe(state => {
        this.config = state.configs;
      });
  }

  initIcons() {
    this.iconMap.set(0, this.iconInfo);
    this.iconMap.set(1, this.iconWarning);
    this.iconMap.set(2, this.iconError);
    this.iconMap.set(3, this.iconAlert);
    this.iconMap.set(4, this.iconBanner);
  }

  checkForNewNotification(markAsRead) {
    this.loading = true;
    // get Only Active notifications
    this.notificationService.GetNotifications(true).subscribe(
      response => {
        // get notification messages from service

        let userNotification = this.notificationServiceFactory.ReadLocalMessage(response, markAsRead);
        this.loading = false;
        this.notes = [];
        if (!userNotification || !userNotification.Notifications) {
          return;
        }
        this.notes = userNotification.Notifications.sort((a: any, b: any) => <any>new Date(b.NotificationStartDate) - <any>new Date(a.NotificationStartDate));

        this.setIcon();
        // We should not  bypass the Angulars check for malicious htl & scripts as this noitification text is provided by end user (admin)
        // response.Notifications.forEach(note => {
        //   //   note.NotificationText = this.sanitizer.bypassSecurityTrustHtml(note.NotificationText);
        // });
        const unreadNotes = this.notes.filter(note => !note.IsNotificationRead);

        this.unreadCount = unreadNotes.length;
        this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, unreadCount: this.unreadCount }));
        // this.limit = this.notes.length >= this.limit ? this.limit : this.notes.length; // show 0/0 on ui if there are no messages

        if (markAsRead === true) {
          this.ResetCounter();
        }
        this.setPanelVisibility(unreadNotes);
      },
      error => {
        // handle error
        this.limit = 0;
        this.loading = false;
      }
    );
  }
  // this method resets the message count to 0 after opening the panel.
  ResetCounter() {
    setTimeout(() => {
      this.unreadCount = 0;
      this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, unreadCount: 0 }));
    }, 6000);
  }

  setPanelVisibility(unreadNotes: any[]) {
    const unreadBannerNotes = unreadNotes.filter(note => note.NotificationType === 4);

    const isNotificationsIconVisible = this.rolefeatureService.isFeatureApplicable(this.rolefeatureService.pages.Dashboard, this.rolefeatureService.features.Notifications);

    if (isNotificationsIconVisible && unreadBannerNotes.length > 1) {
      this.showNotes = true;
    } else {
      this.showNotes = false;
    }
  }

  closePanel() {
    this.togglePanel = false;
    this.unreadCount = 0;
    this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, unreadCount: 0 }));

    if (this.paneClosed) {
      this.paneClosed.emit();
    }
  }

  setIcon() {
    this.notes.forEach(note => {
      note.icon = this.iconMap.get(note.NotificationType);
    });
  }

  navigateToAllNotification() {
    this.router.navigateByUrl('notifications');
  }
}
