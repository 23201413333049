<div class="direct__softcap" *ngIf="!isUBI">
  <div class="pcg__list" *ngFor="let pcg of directSoftCapData?.softCapInfo?.softCapPCGDetails">
    <div class="pcg__item">
      <div class="metric__pcg__name">{{ pcg?.PCGName }}</div>
      <div class="metric__list">
        <div class="metric__item">
          <!-- for RBI -->
          <div class="metric__item__lbl" *ngIf="!isUtilizationPCG(pcg?.PCGName)">
            Attainment %
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['Attainment'] }}</p>
              </div>
            </div>
          </div>
          <!-- for UBI -->
          <div class="metric__item__lbl" *ngIf="isUtilizationPCG(pcg?.PCGName)">
            Utilization %
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['Utilization'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val">{{ getFormattedValue(pcg?.Attainment) }}%</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Soft Cap Payout %
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['SoftCapPayoutPercentage'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val">{{ getFormattedValue(pcg?.EarningPercentageBeforeCap) }}%</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Soft Cap Payout (LC)
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['SoftCapPayout'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val">{{ getFormattedValue(pcg?.EarningsBeforeCap) }} {{ pcg?.Currency }}</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl" *ngIf="pcg?.softCapEligible">
            Uncapped Payout %
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['UnCappedPayoutPercentage'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val" *ngIf="pcg?.softCapEligible">
            {{ getFormattedValue(pcg?.EarningPercentageAfterCap) }}%</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl" *ngIf="pcg?.softCapEligible">
            Uncapped Payout (LC)
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['UnCappedPayout'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val" *ngIf="pcg?.softCapEligible">{{ getFormattedValue(pcg?.EarningsAfterCap) }}
            {{ pcg?.Currency }}</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl" *ngIf="pcg?.softCapEligible">
            Payout Above Cap (LC)
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['PayoutAboveCap'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val" *ngIf="pcg?.softCapEligible">
            {{ getFormattedValue(pcg?.EarningsAfterCap - pcg?.EarningsBeforeCap) }} {{ pcg?.Currency }}</div>
        </div>
      </div>
      <div class="softcap__approval">
        <div class="softcap__approval__txt"
          *ngIf="isSoftCapRemovalEligibleWindow && !isSubmittedFlag && pcg?.softCapEligible">
          Request to release soft cap? <i>(Recommended: {{ getRecommendType(pcg?.SystemApproval) }})</i>
        </div>
        <div class="softcap__approval__txt" *ngIf="!pcg?.softCapEligible">Bucket not applicable for soft cap review
        </div>
        <div class="softcap__approval__btn"
          *ngIf="isSoftCapRemovalEligibleWindow && !isSubmittedFlag && pcg?.softCapEligible">
          <mint-toggle-switch id="pcg?.PCGName"
            [toggleSwitchConfig]="initializeToggleSwitchConfig(pcg?.SystemApproval, directSoftCapData.currentParticipation, pcg?.PCGName)"
            (updateTitle)="updateSoftCap($event, directSoftCapData.currentParticipation, pcg.PCGName)">
          </mint-toggle-switch>
        </div>
      </div>
      <!-- <div *ngIf="isSubmittedFlag && pcg?.softCapEligible" class="softcap__approval">
        <div class="softcap__approval__txt">
          <b
            [ngClass]="getApprovalStatus(pcg) === 0 ? 'softcap__approval-approved' : 'softcap__approval-declined'">{{ getApprovalType(getApprovalStatus(pcg)) }}</b>
          Soft Cap release request on
          <i>{{ getSubmittedDate(pcg.ManagerSubmittedDate) }}</i>
        </div>
        <div *ngIf="pcg?.ManagerApproval !== null" class="softcap__approval__txt">
          <b
            [ngClass]="pcg?.ManagerApproval === 0 ? 'softcap__approval-approved' : 'softcap__approval-declined'">{{ getApprovalType(pcg?.ManagerApproval) }}</b>
          Soft Cap release request on
          <i>{{ getSubmittedDate(pcg?.ManagerSubmittedDate) }}</i>
        </div>
        <div *ngIf="pcg?.ManagerApproval === null" class="softcap__approval__txt">
          <b
            [ngClass]="pcg?.SystemApproval === 0 ? 'softcap__approval-approved' : 'softcap__approval-declined'">{{ getApprovalType(pcg?.SystemApproval) }}</b>
          Soft Cap release request on
          <i>{{ getSubmittedDate(softCapEndDate) }}</i>
        </div>
      </div> -->
      <div *ngIf="isSubmittedFlag && pcg?.softCapEligible" class="softcap__approval">
        <div class="softcap__approval__txt" *ngIf="pcg?.ManagerApproval !== null">
          <b
            [ngClass]="dpcg?.ManagerApproval ? 'softcap__approval-approved' : 'softcap__approval-declined'">{{ getApprovalType(pcg?.ManagerApproval) }}</b>
          Soft Cap release request on
          <i>{{ getSubmittedDate(pcg?.ManagerSubmittedDate) }}</i>
        </div>
        <div class="softcap__approval__txt" *ngIf="pcg?.ManagerApproval === null">
          <b>No Action Taken By Manager</b>
        </div>
        <div class="softcap__approval__txt"
          *ngIf="pcg?.FinalApproval !== null && pcg?.FinalApproval != pcg?.ManagerApproval">
          <b [ngClass]="pcg?.FinalApproval ? 'softcap__approval-approved' : 'softcap__approval-declined'">{{ getApprovalType(pcg?.FinalApproval) }}
            By WWIC</b>
          Soft Cap release request on
          <i>{{ getSubmittedDate(pcg?.ICBMManagerSubmittedDate) }}</i>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="direct__softcap" *ngIf="isUBI && this.utilizationBucket.length>=1">
  <div class="pcg__list">
    <div class="pcg__item">
      <div class="metric__pcg__name">Utilization</div>
      <div class="metric__list">
        <div class="metric__item">
          <div class="metric__item__lbl">
            Period
            <!-- <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['PeriodStatus'] }}</p>
              </div>
            </div> -->
          </div>
          <div *ngFor="let pcg of utilizationBucket" class="metric__item__val"
            style="color: #707070;">{{ pcg?.PeriodStatus }}</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Utilization %
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['Utilization'] }}</p>
              </div>
            </div>
          </div>
          <div *ngFor="let pcg of utilizationBucket" class="metric__item__val">
            {{ getFormattedValue(pcg?.Attainment) }}%</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Soft Cap Payout %
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['SoftCapPayoutPercentage'] }}</p>
              </div>
            </div>
          </div>
          <div *ngFor="let pcg of utilizationBucket" class="metric__item__val">
            {{ getFormattedValue(pcg?.EarningPercentageBeforeCap) }}%</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Soft Cap Payout (LC)
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['SoftCapPayout'] }}</p>
              </div>
            </div>
          </div>
          <div *ngFor="let pcg of utilizationBucket" class="metric__item__val">
            {{ getFormattedValue(pcg?.EarningsBeforeCap) }} {{ pcg?.Currency }}</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Uncapped Payout %
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['UnCappedPayoutPercentage'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val" *ngFor="let pcg of utilizationBucket">
            {{ getFormattedValue(pcg?.EarningPercentageAfterCap) }}%</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Uncapped Payout (LC)
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['UnCappedPayout'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val" *ngFor="let pcg of utilizationBucket">
            {{ getFormattedValue(pcg?.EarningsAfterCap) }}
            {{ pcg?.Currency }}</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Payout Above Cap (LC)
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['PayoutAboveCap'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val" *ngFor="let pcg of utilizationBucket">
            {{ getFormattedValue(pcg?.EarningsAfterCap - pcg?.EarningsBeforeCap) }} {{ pcg?.Currency }}</div>
        </div>
      </div>
      <div class="softcap__approval">
        <div class="softcap__approval__txt"
          *ngIf="isSoftCapRemovalEligibleWindow && !isSubmittedFlag && utilizationBucket[0].softCapEligible && utilizationBucket[0].FinalApproval==NULL">
          Request to release soft cap? <i>(Recommended:
            {{ getRecommendType(utilizationBucket[0].SystemApproval) }})</i>
        </div>
        <div class="softcap__approval__txt" *ngIf="!utilizationBucket[0].softCapEligible">
          Bucket not applicable for soft
          cap review
        </div>
        <div class="softcap__approval__btn"
          *ngIf="isSoftCapRemovalEligibleWindow && !isSubmittedFlag && utilizationBucket[0].softCapEligible && utilizationBucket[0].FinalApproval==NULL">
          <mint-toggle-switch id="utilizationBucket[0].PCGName"
            [toggleSwitchConfig]="initializeToggleSwitchConfig(utilizationBucket[0].SystemApproval, directSoftCapData.currentParticipation, utilizationBucket[0].PCGName)"
            (updateTitle)="updateSoftCapUBI($event, directSoftCapData.currentParticipation, utilizationBucket[0].PCGName)">
          </mint-toggle-switch>
        </div>
      </div>
      <div *ngIf="isSubmittedFlag && utilizationBucket[0].softCapEligible"
        class="softcap__approval">
        <div class="softcap__approval__txt"
          *ngIf="utilizationBucket[0].ManagerApproval !== null">
          <b
            [ngClass]="utilizationBucket[0].ManagerApproval ? 'softcap__approval-approved' : 'softcap__approval-declined'">{{ getApprovalType(utilizationBucket[0].ManagerApproval) }}</b>
          Soft Cap release request on
          <i>{{ getSubmittedDate(utilizationBucket[0].ManagerSubmittedDate) }}</i>
        </div>
        <div class="softcap__approval__txt"
          *ngIf="utilizationBucket[0].ManagerApproval === null">
          <b>No Action Taken By Manager</b>
        </div>
        <div class="softcap__approval__txt"
          *ngIf="utilizationBucket[0].FinalApproval !== null && utilizationBucket[0].FinalApproval != utilizationBucket[0].ManagerApproval">
          <b
            [ngClass]="utilizationBucket[0].FinalApproval ? 'softcap__approval-approved' : 'softcap__approval-declined'">{{ getApprovalType(utilizationBucket[0].FinalApproval) }}
            By WWIC</b>
          Soft Cap release request on
          <i>{{ getSubmittedDate(utilizationBucket[0].ICBMManagerSubmittedDate) }}</i>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="direct__softcap" *ngIf="isUBI && this.consumptionBucket.length>=1">
  <div class="pcg__list">
    <div class="pcg__item">
      <div class="metric__pcg__name">{{consumptionBucket[0].PCGName}}</div>
      <div class="metric__list">
        <div class="metric__item">
          <div class="metric__item__lbl">
            Period
            <!-- <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['PeriodStatus'] }}</p>
              </div>
            </div> -->
          </div>
          <div *ngFor="let pcg of consumptionBucket" class="metric__item__val"
            style="color: #707070;">{{ pcg?.PeriodStatus }}</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Attainment %
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['Attainment'] }}</p>
              </div>
            </div>
          </div>
          <div *ngFor="let pcg of consumptionBucket" class="metric__item__val">
            {{ getFormattedValue(pcg?.Attainment) }}%</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Soft Cap Payout %
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['SoftCapPayoutPercentage'] }}</p>
              </div>
            </div>
          </div>
          <div *ngFor="let pcg of consumptionBucket" class="metric__item__val">
            {{ getFormattedValue(pcg?.EarningPercentageBeforeCap) }}%</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Soft Cap Payout (LC)
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['SoftCapPayout'] }}</p>
              </div>
            </div>
          </div>
          <div *ngFor="let pcg of consumptionBucket" class="metric__item__val">
            {{ getFormattedValue(pcg?.EarningsBeforeCap) }} {{ pcg?.Currency }}</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Uncapped Payout %
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['UnCappedPayoutPercentage'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val" *ngFor="let pcg of consumptionBucket">
            {{ getFormattedValue(pcg?.EarningPercentageAfterCap) }}%</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Uncapped Payout (LC)
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['UnCappedPayout'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val" *ngFor="let pcg of consumptionBucket">
            {{ getFormattedValue(pcg?.EarningsAfterCap) }}
            {{ pcg?.Currency }}</div>
        </div>
        <div class="metric__item">
          <div class="metric__item__lbl">
            Payout Above Cap (LC)
            <div class="stat__info__softCap">
              <div class="icon icon-info"></div>
              <div class="stat__tooltip">
                <p class="stat__tooltip__text">{{ softCapTooltipMessages['PayoutAboveCap'] }}</p>
              </div>
            </div>
          </div>
          <div class="metric__item__val" *ngFor="let pcg of consumptionBucket">
            {{ getFormattedValue(pcg?.EarningsAfterCap - pcg?.EarningsBeforeCap) }} {{ pcg?.Currency }}</div>
        </div>
      </div>
      <div class="softcap__approval">
        <div class="softcap__approval__txt"
          *ngIf="isSoftCapRemovalEligibleWindow && !isSubmittedFlag && consumptionBucket[0].softCapEligible && consumptionBucket[0].FinalApproval==NULL">
          Request to release soft cap? <i>(Recommended:
            {{ getRecommendType(consumptionBucket[0].SystemApproval) }})</i>
        </div>
        <div class="softcap__approval__txt" *ngIf="!consumptionBucket[0].softCapEligible">
          Bucket not applicable for soft
          cap review
        </div>
        <div class="softcap__approval__btn"
          *ngIf="isSoftCapRemovalEligibleWindow && !isSubmittedFlag && consumptionBucket[0].softCapEligible && consumptionBucket[0].FinalApproval==NULL">
          <mint-toggle-switch id="consumptionBucket[0].PCGName"
            [toggleSwitchConfig]="initializeToggleSwitchConfig(consumptionBucket[0].SystemApproval, directSoftCapData.currentParticipation, consumptionBucket[0].PCGName)"
            (updateTitle)="updateSoftCapUBI($event, directSoftCapData.currentParticipation, consumptionBucket[0].PCGName)">
          </mint-toggle-switch>
        </div>
      </div>
      <div *ngIf="isSubmittedFlag && consumptionBucket[0].softCapEligible"
        class="softcap__approval">
        <div class="softcap__approval__txt"
          *ngIf="consumptionBucket[0].ManagerApproval !== null">
          <b
            [ngClass]="consumptionBucket[0].ManagerApproval ? 'softcap__approval-approved' : 'softcap__approval-declined'">{{ getApprovalType(consumptionBucket[0].ManagerApproval) }}</b>
          Soft Cap release request on
          <i>{{ getSubmittedDate(consumptionBucket[0].ManagerSubmittedDate) }}</i>
        </div>
        <div class="softcap__approval__txt"
          *ngIf="consumptionBucket[0].ManagerApproval === null">
          <b>No Action Taken By Manager</b>
        </div>
        <div class="softcap__approval__txt"
          *ngIf="consumptionBucket[0].FinalApproval !== null && consumptionBucket[0].FinalApproval != consumptionBucket[0].ManagerApproval">
          <b
            [ngClass]="consumptionBucket[0].FinalApproval ? 'softcap__approval-approved' : 'softcap__approval-declined'">{{ getApprovalType(consumptionBucket[0].FinalApproval) }}
            By WWIC</b>
          Soft Cap release request on
          <i>{{ getSubmittedDate(consumptionBucket[0].ICBMManagerSubmittedDate) }}</i>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="justification">
  <div class="justification__lbl" *ngIf="directSoftCapData.softCapInfo.isSoftCapEligible">Business Justification<span
      style="color:#a4262c">*</span>
    <div class="stat__info__softCap">
      <div class="icon icon-info"></div>
      <div class="stat__tooltip__softcap">
        <p class="stat__tooltip__text">For RBI, when providing business justification, please consider the
          following:<br>
          • Level of influence of the seller in the performance of the territory assigned<br>
          • Time in role (sellers in role for a partial year may be impacted by seasonality)<br>
          • Quota errors that would account for high attainment (e.g. low quotas, seasonality errors, budget
          errors)<br>
          • Overachievement due to a bluebird deal (opportunity that was not budgeted)<br>
          • Overall performance of the products/services in the subsidiary (alignment with <br>subsidiary performance,
          as
          misalignment could indicate a quota issue)
          <br>
          For UBI, when providing business justification, please consider the following:<br>
          • Time in role (those who are in role for partial year)<br>
          • Quality of delivery – leverage IP to enable acceleration of new technologies, create customer value, and
          reduce risk<br>
          • Always delivering on contract, ensuring no rework is needed<br>
          • Focuses primarily on customer requirements and building relationships</p>
      </div>
    </div>
  </div>
  <div class="justification__input" *ngIf="directSoftCapData.softCapInfo.isSoftCapEligible">
    <textarea rows="4" onfocus="this.placeholder=''" onblur="this.placeholder=``"
      [attr.disabled]="!(isSoftCapRemovalEligibleWindow && !isSubmittedFlag) ? 'disabled' : null" [style.border-color]="
        directSoftCapData.softCapInfo.directsPCGSoftCapChangesCount > 0 &&
        (directSoftCapData.softCapInfo.businessJustification?.trim() === '' ||
          directSoftCapData.softCapInfo.businessJustification === null ||
          directSoftCapData.softCapInfo.businessJustification === undefined)
          ? '#a4262c'
          : null
      " [ngClass]="{
        mandatory:
          directSoftCapData.softCapInfo.directsPCGSoftCapChangesCount > 0 &&
          (directSoftCapData.softCapInfo.businessJustification?.trim() === '' ||
            directSoftCapData.softCapInfo.businessJustification === null ||
            directSoftCapData.softCapInfo.businessJustification === undefined)
      }" [(ngModel)]="directSoftCapData.softCapInfo.businessJustification" required></textarea>
    <span *ngIf="
        directSoftCapData.softCapInfo.isBusinessJustificationValidationMandate &&
        (directSoftCapData.softCapInfo.businessJustification?.trim() === '' ||
          directSoftCapData.softCapInfo.businessJustification === null ||
          directSoftCapData.softCapInfo.businessJustification === undefined)
      " style="color: #a4262c">Business Justification is Mandatory</span>
  </div>
  <!-- <div *ngIf="!directSoftCapData.softCapInfo.isSoftCapEligible" style="margin-top: 2.5%">{{ softCapEligibleMessage }}
  </div> -->
</div>