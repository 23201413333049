<div class="video-link__container" *ngIf="videoData">
  <div id="streamIcon_{{ featureId }}" class="video-link__icon">
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.1562 11.0001C20.1562 10.6472 19.9912 10.2942 19.6566 10.0788L7.66203 2.49342C6.9287 2.01217 5.95703 2.53925 5.95703 3.41467V11.0001L12.832 11.9167L20.1562 11.0001Z"
        fill="#C2185B"
      />
      <path d="M20.1562 11.0001H5.95703V18.5855C5.95703 19.4609 6.9287 19.988 7.66203 19.5067L19.6566 11.9213C19.9866 11.7059 20.1562 11.353 20.1562 11.0001Z" fill="#F06292" />
      <path
        d="M2.75 15.3868V6.20726C2.75 5.72096 3.30046 5.43955 3.69462 5.72417L10.3038 10.4977C10.6407 10.7411 10.6315 11.2462 10.2855 11.4767L3.67629 15.8831C3.28029 16.1467 2.75 15.8625 2.75 15.3868Z"
        fill="#880E4F"
      />
    </svg>
  </div>
  <div>
    <button
      (click)="onButtonClick()"
      title="click to play the video"
      class="video-link__anchor"
      analytics-tracker
      [tracker]="'VideoLink_Click'"
      [value]="videoData.VideoTitle"
      [type]="'Click'"
      [element]="'Hyperlink'"
      [source]="'Video_link'"
    >
      [<span class="video-link__anchor__text">View a tutorial</span>]
    </button>
  </div>
  <ng-template id="support_2" style="vertical-align: centre;" #contextualVideoTemplate>
    <div class="modal-header">
      <h4 class="modal-title" style="display: inline;">{{ videoData.VideoTitle }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div [innerHtml]="videoData.videoHtml | safeHtml"></div>
  </ng-template>
</div>
