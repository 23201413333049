import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { Inject } from '@angular/core';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { DetailSummaryTabs } from './detailSummaryTabs.enum';
import { FiltersService } from '../../filters/filters.service';

@Component({
  selector: 'app-detail-summary-tiles',
  templateUrl: './detail-summary-tiles.component.html',
  styleUrls: ['./detail-summary-tiles.component.scss']
})
export class DetailSummaryTilesComponent implements OnInit {
  selectedReport: any;
  sharedService: any;
  filterService: any;
  detailReportParamsFromDashboard = [];
  constructor(sharedService: SharedService, filterService: FiltersService) {
    this.sharedService = sharedService;
    this.filterService = filterService;
  }

  detailSummaryTabs = [
    {
      index: 0,
      id: 'dedetailSummaryTab_products',
      key: DetailSummaryTabs.RevenueProductSummary,
      title: 'Products',
      reportId: 5
    },
    {
      index: 1,
      id: 'dedetailSummaryTab_incentiveTerritories',
      key: DetailSummaryTabs.RevenueTerritorySummary,
      title: 'Incentive Territories',
      reportId: 4
    },
    {
      index: 2,
      id: 'dedetailSummaryTab_accounts',
      key: DetailSummaryTabs.RevenueAccountSummary,
      title: 'Accounts',
      reportId: 6
    }
  ];

  // ToDo: This value will come from the DB as a user preference
  selectedTab = 0;

  activeTabId = this.detailSummaryTabs.filter(t => t.index === this.selectedTab)[0].id;

  ngOnInit() {
    // To do selected tab is hardcoded to be fetched from DB and then change this logic
    this.onTabChange({ nextId: 'dedetailSummaryTab_products' });
    this.detailReportParamsFromDashboard = [];
    this.detailReportParamsFromDashboard.push({
      FiscalYear: this.filterService.selectedStandardTitle.FiscalYear.Key,
      Period: this.filterService.selectedCodePeriod,
      ParticipationId: this.filterService.selectedStandardTitle.ParticipationID,
      PersonnelNumber: this.filterService.personnelNumber,
      FiscalQuarterId: this.filterService.selectedFiscalQuarterId,
      ParticipationEndDate: this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.EndDate : null
    });
  }

  viewReport(id) {
    this.sharedService.ngViewReport(id, id);
  }

  viewReport1(id) {
    this.sharedService.ngViewReport(id, id);
  }

  onTabChange(event) {
    this.detailSummaryTabs.forEach(x => {
      if (x.id === event.nextId) {
        this.selectedTab = x.index;
        this.selectedReport = x.reportId;
      }
    });
  }
}
