import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Payline } from '../models/payline.model';
import { map } from 'rxjs/operators';
import { PaylineSegment } from '../models/payline-segment.model';
import { CustomHttpParams } from '../../core/network/custom-http-params';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../../core/network/data.service';
import { BucketParams } from '../models/bucket-params.model';

@Injectable({
  providedIn: 'root'
})
export class EarningPotentialService {
  constructor(private endpoint: EndpointService, private dataService: DataService) {}

  getAllPaylines(bucketParams: BucketParams = null): Observable<Payline[]> {
    const httpParams = this.getParams(bucketParams);
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetAllPaylines.uri, httpParams).pipe(
      map(result => {
        return new Payline().deserialize(result.Paylines);
      })
    );
  }

  getPaylineSegments(pcgName): Observable<PaylineSegment[]> {
    let httpParams = CustomHttpParams.getInstance();
    httpParams = httpParams.append('PcgName', pcgName);
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetPaylineSegments.uri, httpParams).pipe(
      map(result => {
        return new PaylineSegment().deserialize(result);
      })
    );
  }

  getPCGMetrics(bucketParams: BucketParams = null): Observable<any[]> {
    const httpParams = this.getParams(bucketParams);
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetPCGMetrics.uri, httpParams).pipe(
      map(result =>
        result?.map(pcgDetail => {
          return {
            name: pcgDetail.Name,
            // Added to perform checks for OCP PPM Metric
            weight: pcgDetail.Weightage,
            incentiveType: pcgDetail.IncentiveType,
            determines: pcgDetail.determines,
            determinings: pcgDetail.determinings
          };
        })
      )
    );
  }

  getMultiplePayline(bucketParams: BucketParams = null, fromFreezeSnapshot: boolean = false): Observable<any> {
    const httpParams = this.getParams(bucketParams, fromFreezeSnapshot);

    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetMultiplePayline.uri, httpParams);
  }

  private getParams(bucketParams: BucketParams, fromFreezeSnapshot: boolean = null) {
    let httpParams = CustomHttpParams.getInstance();
    if (!bucketParams) {
      return httpParams;
    }
    if (bucketParams.bucketName) {
      httpParams = httpParams.append('PcgName', bucketParams.bucketName);
    }
    if (bucketParams.periodStatus) {
      httpParams = httpParams.append('PeriodStatus', bucketParams.periodStatus);
    }
    if (bucketParams.paylineSegmentName) {
      httpParams = httpParams.append('SegmentName', bucketParams.paylineSegmentName);
    }
    if (bucketParams.incentiveType) {
      httpParams = httpParams.append('IncentiveType', bucketParams.incentiveType);
    }
    if (fromFreezeSnapshot) {
      httpParams = httpParams.append('FromFreezeSnapshot', String(fromFreezeSnapshot));
    }
    return httpParams;
  }

  getEarningAttributes(pcgIds: number[]) {
    let params = CustomHttpParams.getInstance();
    for (const pcgId of pcgIds) {
      params = params.append('PcgIds', pcgId.toString());
    }
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetEarningAttributes.uri, params);
  }
}
