import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DataService } from '../core/network/data.service';
import { EndpointService } from '@mint-libs/network';
import { UserProfileService, WidgetRole } from '../core/context/user-profile.service';
import * as Ng1Services from '../core/hybrid/ng1-upgraded-providers';
import { ReportType } from './report-type.enum';
import { FiltersService } from '../filters/filters.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Injectable()
export class ReportsService {
  selectedReportIndex = 0;
  reportTabs = null;
  filterConfig: any;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private endpoint: EndpointService,
    private dataService: DataService,
    public userProfileService: UserProfileService,
    public filterService: FiltersService,
    public sharedContextState: Store<SharedContext>
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.filterConfig = {
      isFilter: true,
      isSearch: false,
      isFiscalYear: true,
      isTitle: true,
      isPartition: true,
      showSearch: false,
      showFilter: true
    };
  }

  public getDimNames(queryText, reportType) {
    let params = new HttpParams();
    params = this.appendCustomParams(params, 'Query', queryText);
    params = this.appendCustomParams(params, 'ReportType', reportType);

    return this.dataService.getWithGlobalFilters(this.endpoint.GetDimNames.uri, params);
  }

  public getTargetSummary(reportType?) {
    let params = new HttpParams();
    const IsDMYEligible = this.sharedContext.IsDMYEligible;
    params = this.appendCustomParams(params, 'IsDMYElligible', `${IsDMYEligible}`);

    if (reportType === ReportType.AccountDynamicsCommittedGrowthReport) {
      const applicablePartitions = this.filterService.applicablePartitionsList.map(p => p.StepId);
      const maxApplicableStepId = Math.max(...applicablePartitions);
      params = this.appendCustomParams(params, 'StepId', maxApplicableStepId);
    }
    return this.dataService.getWithGlobalFilters(this.endpoint.GetTargetSummary.uri, params);
  }

  public getDetailedTerritories(dimNameList, rowCount) {
    return this.getWithCustomParams(this.endpoint.RevenueTerritorySummary.uri, dimNameList, rowCount);
  }

  public getDetailedAccounts(dimNameList, rowCount, reportType?) {
    return this.getWithCustomParams(this.endpoint.RevenueByAccountSummary.uri, dimNameList, rowCount, reportType);
  }

  public getDetailedProducts() {
    return this.getWithCustomParams(this.endpoint.RevenueProductSummary.uri);
  }

  public getAttainmentByPCGDetail() {
    let params = new HttpParams();
    const partitionDuration = this.getPartitionDuration();
    const IsDMYEligible = this.sharedContext?.IsDMYEligible;
    params = this.appendCustomParams(params, 'PartitionStartDate', partitionDuration?.partitionStartDate);
    params = this.appendCustomParams(params, 'PartitionEndDate', partitionDuration?.partitionEndDate);
    params = this.appendCustomParams(params, 'IsDMYElligible', IsDMYEligible);
    return this.dataService.getWithGlobalFilters(this.endpoint.GetAttainmentByPCGDetail.uri, params);
  }

  public exportAttainmentByPCGDetail() {
    const partitionDuration = this.getPartitionDuration();
    const IsDMYElligible = this.sharedContext?.IsDMYEligible;
    const postBody = {
      PartitionStartDate: partitionDuration?.partitionStartDate,
      PartitionEndDate: partitionDuration?.partitionEndDate,
      IsDMYElligible
    };
    return this.dataService.postWithGlobalFilters(this.endpoint.ExportAttainmentByPCGDetail.uri, postBody, null, 'arraybuffer');
  }

  public getAllPaylines() {
    return this.dataService.getWithGlobalFilters(this.endpoint.GetAllPaylines.uri, null);
  }

  public exportRevenueProductDetail(postBody) {
    return this.exportWithCustomParams(this.endpoint.ExportRevenueProductDetail.uri, postBody, ReportType.ProductReport);
  }

  public exportRevenueTerritoryDetail(postBody) {
    return this.exportWithCustomParams(this.endpoint.ExportRevenueTerritoryDetail.uri, postBody, ReportType.TerritoryReport);
  }

  public exportRevenueAccountDetail(postBody) {
    return this.exportWithCustomParams(this.endpoint.ExportRevenueAccountDetail.uri, postBody, ReportType.AccountReport);
  }

  public ExportDynamicsAccountRevenue(postBody) {
    postBody.IsDmyDLTRReport = true;
    postBody.IncludePivot = false;
    return this.exportWithCustomParams(this.endpoint.ExportDynamicsAccountRevenue.uri, postBody, ReportType.AccountDynamicsCommittedGrowthReport);
  }

  public ExportDynamicsAccountTarget(postBody) {
    postBody.IsDmyDLTRReport = true;
    postBody.IncludePivot = false;
    return this.exportWithCustomParams(this.endpoint.ExportDynamicsAccountTarget.uri, postBody, ReportType.AccountDynamicsCommittedGrowthReport);
  }

  private getWithCustomParams(endpointUri, dimNameList?, rowCount?, reportType?, selectedPcc?, isSummary?, dimensions?, modifierDetails?) {
    let params = new HttpParams();
    params = this.appendCustomParams(params, 'PccName', selectedPcc?.Name);
    params = this.appendCustomParams(params, 'IsSummaryReport', isSummary);
    params = this.appendCustomParams(params, 'Dimensions', dimensions);
    params = this.appendCustomParams(params, 'WithModifierDetail', modifierDetails);
    params = this.appendCustomParams(params, 'dltrRowCount', rowCount);
    params = this.appendCustomParams(params, 'IsDmyDLTRReport', reportType === ReportType.AccountDynamicsCommittedGrowthReport);
    params = this.appendCustomArrayParams(params, 'dimNameList[]', dimNameList);

    return this.dataService.getWithGlobalFilters(endpointUri, params);
  }

  private exportWithCustomParams(endpointUri, postBody: any, reportType: ReportType) {
    const pivotSettings = (this.sharedContext.Settings.WidgetsRole as WidgetRole[]).find(r => r.Type === reportType);
    postBody.settings = pivotSettings?.Settings;

    return this.dataService.postWithGlobalFilters(endpointUri, postBody, null, 'arraybuffer');
  }

  private appendCustomParams(params: HttpParams, paramKey: string, paramValue: any) {
    if (paramValue === null || paramValue === undefined) {
      return params;
    }
    return params.append(paramKey, paramValue);
  }

  private appendCustomArrayParams(params: HttpParams, paramKey: string, paramValue: any[]) {
    if (paramValue === null || paramValue === undefined) {
      return params;
    }
    paramValue.forEach(p => (params = params.append(paramKey, p)));
    return params;
  }

  private getPartitionDuration() {
    const PartitionDuration = null;
    const selectedStandardTitle = this.filterService.selectedStandardTitle;
    if (selectedStandardTitle != null) {
      const partition = selectedStandardTitle.ApplicablePartitions.find(partition => {
        return partition.StepId === this.filterService.selectedStepId;
      });
      if (partition) {
        return {
          partitionStartDate: partition.Duration.StartDate,
          partitionEndDate: partition.Duration.EndDate
        };
      }
    }
    return PartitionDuration;
  }
}
