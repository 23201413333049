import { Component, OnInit, Inject, ViewChild, ElementRef, OnChanges, AfterViewChecked } from '@angular/core';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { CompensationPlanService } from '../../compensation-plan/compensation-plan.service';
import { EmployeeInfo } from '../../shared/employee-info/employee-info.model';
import { from } from 'rxjs';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { PayoutReportService } from './payout-report.service';
import { SharedService } from '../../shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { FiltersService } from '../../filters/filters.service';
import { formatNumber } from '@angular/common';
import { saveAs } from 'file-saver';
import { TPayoutObject, TPayoutResponse } from './types/payout-info-response-type';
import { environment } from '../../../environments/environment';
import { LoggerService } from '@mint-libs/logging';
import { UserProfileService } from '../../core/context/user-profile.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { CommonFactory } from '@mint-libs/common';
import { GraphService } from '@mint-libs/context';
import { PeriodStatus } from 'src/app/shared/models/period-status.enum';

@Component({
  selector: 'mint-payout-report',
  templateUrl: './payout-report.component.html',
  styleUrls: ['./payout-report.component.scss'],
  providers: [CompensationPlanService, NgbNavConfig]
})
export class PayoutReportComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('payout_tables_temp')
  payout_tables_temp: ElementRef;
  @ViewChild('payout_tables_temp_QR')
  payout_tables_temp_QR: ElementRef;
  @ViewChild('employee_avatar')
  employee_avatar: ElementRef;
  employeeInfo: EmployeeInfo;
  fromPayout = true;
  thumbnailPhoto: any;
  activeId: any;
  payoutFilterTabs = [];
  selectedTab: number;
  selectedPayoutTab: any;
  payoutInfo: TPayoutResponse | TPayoutObject[];
  maxPublicationDate: Date;
  showPayoutReportLoader = false;
  showBonusPayoutInfo = false;
  payoutReportLabelText = '';
  isLoaded = false;
  preFY22Report = false;
  locale = 'en-US';
  loadingReport = false;
  old_data: any = {};
  old_TotalIncentiveTargetFullYear: any;
  old_showLOARow: boolean;
  old_showTARow: boolean;
  old_earningsCurrencyCode: any;
  old_earningsMessage: any;
  old_showProjectedEarningsSinceLastPayout: any;
  old_showTable: boolean;
  old_showPCC: boolean;
  old_totalParticipationDays: number;
  old_pccLength: number;
  old_isOptOut: any;
  old_PayoutReport: boolean;
  old_EarningsReport: boolean;
  old_showReport: boolean;
  old_showDataNotAvailable: boolean;
  old_isMSAITargetMet: boolean;
  old_isIndividualAITargetMet: boolean;
  old_payoutCurrencyCode: any;
  old_publicationDate: any;
  old_totalDaysInYear: number;
  private _old_pcglist: any[];
  private _old_pcg_manypcc_list: any[];
  old_masterTargetAjustmentList: any;
  old_masterActualAjustmentList: any;
  old_masterRecurringTargetAjustmentList: any;
  old_showTgtAdj: boolean;
  old_showActAdj: boolean;
  old_showRecAdj: boolean;
  old_payoutShowTerritoryReport: boolean;
  old_payoutTitle: string;
  old_payoutIncentiveTitle: string;
  old_showMorePayIncentive: any;
  old_attainmentThresholdValue: any;
  old_showAttainmentMessage: boolean;
  old_showRecurring: boolean;
  old_isMinimumQuotaThresholdEligibile: boolean;
  old_totalColumns: any;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private compensationPlanService: CompensationPlanService,
    private graphService: GraphService,
    protected navConfig: NgbNavConfig,
    public payoutReportService: PayoutReportService,
    private filterService: FiltersService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private loggingService: LoggerService,
    private commonFactory: CommonFactory,
    public sharedContextState: Store<SharedContext>,
    public userProfileService: UserProfileService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    navConfig.keyboard = true;
    this.preFY22Report = this.filterService.selectedFiscalYear > 2021 ? false : true;
    this.old_payoutShowTerritoryReport =
      this.filterService.selectedStepId && this.filterService.selectedFiscalYear
        ? (this.filterService.selectedStepId >= 2 && this.filterService.selectedFiscalYear == 2020) || this.filterService.selectedFiscalYear > 2020
        : false;
  }

  ngOnInit() {
    this.showBonusPayoutInfo = environment.showBonusPayoutInfo;
    if (!this.preFY22Report) {
      this.getEmployeeInformation();
      this.payoutReportService.getPayoutInfo(this.showBonusPayoutInfo).subscribe((response1): any => {
        this.payoutInfo = response1;
        this.getPayoutFilterTabs();
        this.isLoaded = true;
      });
    } else {
      this.payoutReportService.getOldPayoutInfo(true).subscribe((old_response): any => {
        this.old_PrepareDataForExportTable(old_response);
        this.isLoaded = true;
      });
    }
  }

  ngAfterViewChecked(): void {
    // adding alt text to ngx-avatar
    if (this.employee_avatar) {
      const avtarImg = this.employee_avatar.nativeElement.querySelector('ngx-avatar img');
      if (avtarImg) {
        avtarImg.setAttribute('alt', this.employeeInfo.getUserName());
      }
    }
  }

  ngOnChanges() {
    this.setSelectedTabData();
  }

  getMaxPublicationDate(payouts: TPayoutObject[]): Date {
    return payouts.reduce((prevPayout, payout) => (prevPayout > new Date(payout.PublicationDate) ? prevPayout : new Date(payout.PublicationDate)), new Date(payouts[0].PublicationDate));
  }

  getPayoutFilterTabs() {
    this.payoutFilterTabs = [];

    if (this.showBonusPayoutInfo) {
      var keys = Object.keys(this.payoutInfo);

      // Get transferred CodePeriod

      var transferredKey = keys.find(key => key.split('-')[1] != 'None' && key.includes('Transferred'))?.toString();
      var transferredCodePeriod = transferredKey != undefined ? this.payoutInfo[transferredKey][0].CodePeriod : undefined;
      var ispostTransfer = false;
      var isNextTransferFound = false;
      var isBonus = false;
      keys.sort((key1: string, key2: string) => {
        if (this.payoutInfo[key1][0].CodePeriod.includes('None')) {
          return 1;
        } else if (this.payoutInfo[key2][0].CodePeriod.includes('None')) {
          return -1;
        } else {
          if (this.payoutInfo[key1][0].CodePeriod == this.payoutInfo[key2][0].CodePeriod) {
            return this.payoutInfo[key1][0].PayeeStatus > this.payoutInfo[key2][0].PayeeStatus ? -1 : 1;
          } else {
            return this.payoutInfo[key1][0].CodePeriod.localeCompare(this.payoutInfo[key2][0].CodePeriod);
          }
        }
      });

      //Get one tab object for each KEY in the dictonary
      keys.forEach((key, index) => {
        var currentCodePeriod = this.payoutInfo[key][0].CodePeriod;
        isBonus = this.payoutInfo[key][0].CodePeriod.split('-')[1].toString() === 'None';
        var isTeriminated = this.payoutInfo[key][0].PayeeStatus === 'Terminated';
        if (!isBonus && !isNextTransferFound) {
          ispostTransfer = transferredCodePeriod != undefined && currentCodePeriod >= transferredCodePeriod && this.payoutInfo[key][0].PayeeStatus == 'Active';
          isNextTransferFound = ispostTransfer;
        }
        this.payoutFilterTabs.push({
          index: index,
          id: this.payoutInfo[key][0].PaymentApprovalID,
          key: key,
          title: this.payoutInfo[key][0].CodePeriod.replace('-None', '') + (isTeriminated && !isBonus ? '-Terminated' : '') + (isBonus ? '-Bonus' : '') + (ispostTransfer ? '-Post Transfer' : '')
        });
        isBonus = false;
        ispostTransfer = false;
      });

      this.selectedTab = this.route.snapshot.queryParams['payoutReportId']
        ? Number(this.route.snapshot.queryParams['payoutReportId'])
        : this.payoutReportService.selectedTabBeforeReload
        ? this.payoutReportService.selectedTabBeforeReload
        : this.payoutFilterTabs[0].id; // default tab

      //Set selectedPayoutTab
      this.setSelectedTabData();
    } else {
      if (Array.isArray(this.payoutInfo)) {
        this.payoutInfo.forEach((payoutData, index) => {
          this.payoutFilterTabs.push({
            index: index,
            id: payoutData.PaymentApprovalID,
            key: payoutData.PaymentApprovalID,
            title: payoutData.CodePeriod
          });
        });
        this.selectedTab = this.route.snapshot.queryParams['payoutReportId']
          ? Number(this.route.snapshot.queryParams['payoutReportId'])
          : this.payoutReportService.selectedTabBeforeReload
          ? this.payoutReportService.selectedTabBeforeReload
          : this.payoutFilterTabs[0].id; // default tab
        this.selectedPayoutTab = this.payoutInfo.find(object => object.PaymentApprovalID === this.selectedTab);
        this.loggingService.trackEvent('PayoutReports_TabChange', { SelectedPayoutTab: this.selectedPayoutTab.CodePeriod }, true);
      }
    }
  }

  getEmployeeInformation() {
    this.compensationPlanService.getEmployeeInformation(this.fromPayout).subscribe(
      result => {
        this.employeeInfo = result;

        // Load the Employee Thumbnail photo after Employee Information is retrived
        const graph = from(this.graphService.getThumbnailPhoto(this.employeeInfo.alias));
        graph.subscribe(thumbnailResult => {
          if (thumbnailResult) {
            this.thumbnailPhoto = `data:image/jpeg;base64,${thumbnailResult}`;
          }
        });
      },
      error => {}
    );
  }

  setSelectedTabData() {
    //Set selectedPayoutTab
    let selectedTab = this.payoutFilterTabs.find(tab => tab.id === this.selectedTab);
    if (selectedTab && selectedTab.key) {
      const tabKey = selectedTab.key;
      this.selectedPayoutTab = this.payoutInfo[tabKey];
      this.maxPublicationDate = this.getMaxPublicationDate(this.selectedPayoutTab);
      this.loggingService.trackEvent('PayoutReports_TabChange', { SelectedPayoutTab: this.selectedPayoutTab[0].CodePeriod }, true);
    }
  }

  onTabChange(event) {
    if (this.route.snapshot.queryParams['payoutReportId']) {
      this.sharedService.ngViewReport(1, 1);
    }
    this.payoutReportService.selectedTabBeforeReload = event.activeId;

    if (this.showBonusPayoutInfo) {
      this.selectedTab = event.nextId;
      this.setSelectedTabData();
    } else {
      if (Array.isArray(this.payoutInfo)) {
        this.selectedPayoutTab = this.payoutInfo.find(object => object.PaymentApprovalID === event.nextId);
        this.loggingService.trackEvent('PayoutReports_TabChange', { SelectedPayoutTab: this.selectedPayoutTab.CodePeriod }, true);
      }
    }
  }

  old_PrepareDataForExportTable = old_response => {
    this.old_showLOARow = false;
    this.old_showTARow = false;
    this.old_data.payouts = old_response.ApplicablePayouts;
    this.old_totalDaysInYear = this.getTotalDaysInYear(this.filterService.selectedFiscalYear);

    if (this.old_data.payouts == null) {
      this.old_data.TotalIncentiveTarget = old_response.earningsDetail.TotalIncentiveTarget;
      this.old_TotalIncentiveTargetFullYear = old_response.earningsDetail.TotalIncentiveTargetFullYear;
      this.old_data.UserITAs = old_response.earningsDetail.UserITAs;
      this.old_data.ActivePeriodItaCount = old_response.earningsDetail.ActivePeriodItaCount;
      this.old_data.PcgList = this.Sort(old_response.earningsDetail.PcgList);
      this.old_checkMQTandAIEligibility();

      var prorationInfo = this.old_getProrationDetail(this.old_data.UserITAs);

      this.old_data.PcgList.forEach(pcg => {
        let ytdDaysCount = this.commonFactory.getYTDDaysCount(this.sharedContext, this.userProfileService);
        let ytdDaysCountPostProration = ytdDaysCount - (prorationInfo.TA_Duration + prorationInfo.LOA_Duration);

        let ActiveEarningsCalc = '';

        let isITACalcShown = false;

        this.old_data.UserITAs.forEach(userITA => {
          if (userITA.PeriodStatus.toUpperCase() === 'ACTIVE') {
            var ITADaysInCurrentQuarter = userITA.ITADuration < ytdDaysCountPostProration ? userITA.ITADuration : ytdDaysCountPostProration;

            if (ITADaysInCurrentQuarter > 0) {
              if (isITACalcShown) {
                ActiveEarningsCalc += ' + ';
              }
              ActiveEarningsCalc +=
                '[ ' +
                formatNumber(userITA.ITA, this.locale, '1.0-2') +
                ' x ' +
                (pcg.Weightage * 100).toFixed(2) +
                '% x ( ' +
                ITADaysInCurrentQuarter +
                '/' +
                this.old_totalDaysInYear +
                ')' +
                ' x ' +
                pcg.EarningsUsedCap.toFixed(2) +
                '% ]';

              ytdDaysCountPostProration -= userITA.ITADuration;
              isITACalcShown = true;
            }
          }
          if (userITA.PeriodStatus.toUpperCase() === 'TA') {
            prorationInfo.TA_ITA = userITA.ITA;
          }
          if (userITA.PeriodStatus.toUpperCase() === 'PAID') {
            prorationInfo.LOA_ITA = userITA.ITA;
          }
        });

        if (isITACalcShown) {
          ActiveEarningsCalc += ' = ' + formatNumber(pcg.Metrics.ActiveEarnings, this.locale, '1.0-2');
        } else {
          ActiveEarningsCalc += formatNumber(pcg.Metrics.ActiveEarnings, this.locale, '1.0-2');
        }

        pcg.ActiveEarningsCalc = ActiveEarningsCalc;

        if (pcg.Metrics.TAEarnings != null && pcg.Metrics.TAEarnings != 0) {
          this.old_payoutTitle = 'Bucket Active Prorated Target';
          this.old_payoutIncentiveTitle = 'Bucket Incentive Award (Incentive Target * Payout %) + Allowances';
          this.old_showTARow = true;
          pcg.TAEarningsCalc =
            '[ ' +
            formatNumber(prorationInfo.TA_ITA, this.locale, '1.0-2') +
            ' x ' +
            (pcg.Weightage * 100).toFixed(2) +
            '% x ( ' +
            pcg.ProrationData.TADays +
            '/' +
            this.old_totalDaysInYear +
            ')' +
            ' x ' +
            pcg.TAUsedCap +
            '% ] = ' +
            formatNumber(pcg.Metrics.TAEarnings, this.locale, '1.0-2');
        }
        if (pcg.Metrics.LOAEarnings != null && pcg.Metrics.LOAEarnings != 0) {
          this.old_showLOARow = true;
          this.old_payoutTitle = 'Bucket Active Prorated Target';
          this.old_payoutIncentiveTitle = 'Bucket Incentive Award (Incentive Target * Payout %) + Allowances';
          pcg.LOAEarningsCalc =
            '[ ' +
            prorationInfo.LOA_ITA.toFixed(2) +
            ' x ' +
            (pcg.Weightage * 100).toFixed(2) +
            '% x ( ' +
            pcg.ProrationData.PaidDays +
            '/' +
            this.old_totalDaysInYear +
            ')' +
            ' x 100% ] = ' +
            pcg.Metrics.LOAEarnings.toFixed(2);
        }
      });
      this.old_data.TotalAllowanceEarning = old_response.earningsDetail.TAEarnings + old_response.earningsDetail.LOAEarnings;

      this.old_earningsCurrencyCode = this.sharedContext.payoutCurrency;
      this.old_earningsMessage = old_response.earningsDetail.Message;
      this.old_data.TotalRBIAward = old_response.earningsDetail.ProjectedEarnings;
      this.old_data.ProjectedEarningsSinceLastPayout = old_response.earningsDetail.ProjectedEarningsSinceLastPayout;
      this.old_showProjectedEarningsSinceLastPayout = old_response.earningsDetail.ShowProjectedEarningsSinceLastPayout;

      old_response.earningsDetail.PcgList.forEach(pcg => {
        this.old_showMorePayIncentive = this.old_showMorePayIncentive || pcg.PayIncentiveComponent.MoreDetails;
        if (pcg.Metrics.WeightedAttainment == this.old_attainmentThresholdValue) {
          this.old_showAttainmentMessage = true;
        }
      });
    }

    if (this.old_data.payouts && this.old_data.payouts.length > 0) {
      old_response.ShowPayoutDetails = true;
      this.old_bindPaymentDate(0);
    }

    this.old_bindViewModelCalcData();

    this.old_data.PcgList.forEach(pcg => {
      pcg.PlanComponentCombinations.forEach(pcc => {
        if (pcc.Metrics.TotalRecurringTarget != 0) {
          this.old_showRecurring = true;
        }
      });
    });

    //FOR Adjustments
    this.old_getAdjustments(this.old_data.PcgList);

    if (this.old_data.PcgList.length === 0) {
      this.old_showTable = false;
    } else {
      var hasMultiplePCC = false;
      this.old_data.PcgList.forEach(p => {
        if (p.PlanComponentCombinations.length > 1) {
          hasMultiplePCC = true;
        }
      });
      this.old_showPCC = hasMultiplePCC;
      this.old_showTable = true;
    }
    if (this.old_data.PcgList && this.old_data.PcgList.length === 0) {
      this.old_totalParticipationDays = this.old_totalDaysInYear;
      this.old_pccLength = 0;
    } else {
      this.old_totalParticipationDays = this.old_data.PcgList[0].TotalParticipationDays;
      this.old_pccLength = this.old_data.PcgList[0].PlanComponentCombinations.length;
    }
    if (this.sharedContext.isOptOut) {
      this.old_isOptOut = this.sharedContext.isOptOut;
      if (this.old_data.PcgList && this.old_data.PcgList.length === 0) {
        this.old_PayoutReport = false;
        this.old_EarningsReport = false;
        this.old_showReport = false;
        this.old_showDataNotAvailable = true;
      } else {
        this.old_PayoutReport = old_response.ShowPayoutDetails;
        this.old_EarningsReport = !old_response.ShowPayoutDetails;
        this.old_showReport = true;
      }
    } else {
      this.old_PayoutReport = old_response.ShowPayoutDetails;
      this.old_EarningsReport = !old_response.ShowPayoutDetails;
      this.old_showReport = true;
    }

    if (this.old_data.IsQRApplied) {
      var getQuotaReleifPromise = this.payoutReportService.getOldQRPCGLevelDetails();
      getQuotaReleifPromise.subscribe((r): any => {
        if (r !== null) {
          this.old_data.qrPCGLevelDetails = r;
        }
      });
    }
  };

  old_checkMQTandAIEligibility = () => {
    this.old_data.PcgList.forEach(pcg => {
      pcg.PlanComponentCombinations.forEach(pcc => {
        if (pcc.Metrics.MinimumTargetAdjustment != 0) {
          this.old_isMinimumQuotaThresholdEligibile = true;
        }
        if (pcc.Metrics.AIACRActualCredit != 0) {
          this.old_isIndividualAITargetMet = true;
        }
        if (pcc.Metrics.AIACRActualMultiplier != 0) {
          this.old_isMSAITargetMet = true;
        }
      });
    });
    if ((this.old_isMSAITargetMet = true && this.old_isIndividualAITargetMet == true)) {
      this.old_isMSAITargetMet = true;
    } else {
      this.old_isMSAITargetMet = false;
    }
  };

  old_getProrationDetail = userITAs => {
    var prorationInfo: any = {};
    prorationInfo.TA_ITA = 0;
    prorationInfo.TA_Duration = 0;
    prorationInfo.LOA_ITA = 0;
    prorationInfo.LOA_Duration = 0;
    userITAs.forEach((userITA, index) => {
      if (userITA.PeriodStatus.toUpperCase() === 'TA') {
        prorationInfo.TA_ITA = userITA.ITA;
        prorationInfo.TA_Duration = userITA.ITADuration;
      }
      if (userITA.PeriodStatus.toUpperCase() === 'PAID') {
        prorationInfo.LOA_ITA = userITA.ITA;
        prorationInfo.LOA_Duration = userITA.ITADuration;
      }
    });

    return prorationInfo;
  };

  old_bindPaymentDate = index => {
    this.old_data.PreviousPayment = this.old_data.payouts[index].Advances;
    this.old_data.PayrollAdjustment = this.old_data.payouts[index].Adjustments;
    this.old_data.ShowPaymentAdjustmentReason = this.old_data.payouts[index].Adjustments == 0 ? false : true;
    this.old_data.AdjustmentDate = this.old_data.payouts[index].AdjustmentsEffectiveDate;
    this.old_data.AdjustmentReason = this.old_data.payouts[index].PaymentAdjustmentReason;
    this.old_data.AdjustmentComments = this.old_data.payouts[index].PaymentAdjustmentComments;
    this.old_data.RecoveryAmount = this.old_data.payouts[index].RecoveryAmount;
    this.old_data.NetPaymentAmount = this.old_data.payouts[index].NetPaymentAmount;
    this.old_data.ShowPayoutCurencyold_data = this.old_data.payouts[index].IsCountryChange;
    this.old_data.EarningsType = this.old_data.payouts[index].EarningsType;
    this.old_data.COVIDAdjustments = this.old_data.payouts[index].COVIDAdjustments;
    this.old_data.BonusAdjustments = this.old_data.payouts[index].BonusAdjustments;
    //QR fields
    this.old_data.IsQRApplied = this.old_data.payouts[index].IsQRApplied;
    this.old_data.QRAmount = this.old_data.payouts[index].YTDEarnings - this.old_data.payouts[index].YTDEarningsNonQR;

    this.old_data.UserITAs = this.old_data.payouts[index].PayoutEarningDetail.UserITAs;
    this.old_data.ActivePeriodItaCount = this.old_data.payouts[index].PayoutEarningDetail.ActivePeriodItaCount;

    var prorationInfo = this.old_getProrationDetail(this.old_data.UserITAs);

    if (this.old_data.ShowPayoutCurencyold_data) {
      this.old_data.PreviousCurrencyCode = this.old_data.payouts[index].PreviousCurrencyCode;
      this.old_data.PreviousCurrencyAmount = this.old_data.payouts[index].PreviousCurrencyAmount;
      this.old_data.TransferredCurrencyCode = this.old_data.payouts[index].TransferredCurrencyCode;
      this.old_data.TransferredCurrencyAmount = this.old_data.payouts[index].TransferredCurrencyAmount;
      this.old_data.ExchangeDate = this.old_data.payouts[index].ExchangeDate;
      this.old_data.ExchangeRate = this.old_data.payouts[index].PreviousToCUDRate * this.old_data.payouts[index].CUDToTransferRate;
    }
    this.old_payoutCurrencyCode = this.old_data.payouts[index].CurrencyCode;

    this.old_publicationDate = this.old_data.payouts[index].PublicationDate;
    this.old_data.TotalIncentiveTarget = this.old_data.payouts[index].PayoutEarningDetail.TotalIncentiveTarget;
    this.old_TotalIncentiveTargetFullYear = this.old_data.payouts[index].PayoutEarningDetail.TotalIncentiveTargetFullYear;
    this.old_data.PcgList = this.Sort(this.old_data.payouts[index].PayoutEarningDetail.PcgList);

    this.old_checkMQTandAIEligibility();

    this.old_data.PcgList.forEach(pcg => {
      var ytdDaysCount = this.commonFactory.getYTDDaysCount(this.sharedContext, this.userProfileService);
      var ytdDaysCountPostProration = ytdDaysCount - (prorationInfo.TA_Duration + prorationInfo.LOA_Duration);

      var ActiveEarningsCalc = '';

      var ActiveEarningsCalc = '';
      var isITACalcShown = false;
      this.old_data.UserITAs.forEach((userITA, index) => {
        if (userITA.PeriodStatus.toUpperCase() === 'ACTIVE') {
          var ITADaysInCurrentQuarter = userITA.ITADuration < ytdDaysCountPostProration ? userITA.ITADuration : ytdDaysCountPostProration;
          if (ITADaysInCurrentQuarter > 0) {
            if (isITACalcShown) {
              ActiveEarningsCalc += ' + ';
            }
            ActiveEarningsCalc +=
              '[ ' +
              formatNumber(userITA.ITA, this.locale, '1.0-2') +
              ' x ' +
              (pcg.Weightage * 100).toFixed(2) +
              '% x ( ' +
              ITADaysInCurrentQuarter +
              '/' +
              this.old_totalDaysInYear +
              ')' +
              ' x ' +
              pcg.EarningsUsedCap.toFixed(2) +
              '% ]';

            ytdDaysCountPostProration -= userITA.ITADuration;
            isITACalcShown = true;
          }
        }
      });

      if (isITACalcShown) {
        ActiveEarningsCalc += ' = ' + formatNumber(pcg.Metrics.ActiveEarnings, this.locale, '1.0-2');
      } else {
        ActiveEarningsCalc += formatNumber(pcg.Metrics.ActiveEarnings, this.locale, '1.0-2');
      }

      pcg.ActiveEarningsCalc = ActiveEarningsCalc;

      //pcg.ActiveEarningsCalc = '[ ' + $filter('number')(this.TotalIncentiveTargetFullYear, 2) + ' x ' + (pcg.Weightage * 100).toFixed(2) + '% x ( ' + pcg.ProrationData.ActiveDays + '/' + totalDaysInYear + ')' + ' x ' + pcg.EarningsUsedCap.toFixed(2) + '% ] = ' + (pcg.Metrics.ActiveEarnings).toFixed(2);

      if (pcg.Metrics.TAEarnings != null && pcg.Metrics.TAEarnings != 0) {
        this.old_payoutTitle = 'Bucket Active Prorated Target';
        this.old_payoutIncentiveTitle = 'Bucket Incentive Award (Incentive Target * Payout %) + Allowances';
        this.old_showTARow = true;
        pcg.TAEarningsCalc =
          '[ ' +
          formatNumber(prorationInfo.TA_ITA, this.locale, '1.0-2') +
          ' x ' +
          (pcg.Weightage * 100).toFixed(2) +
          '% x ( ' +
          pcg.ProrationData.TADays +
          '/' +
          this.old_totalDaysInYear +
          ')' +
          ' x ' +
          pcg.TAUsedCap.toFixed(2) +
          '% ] = ' +
          formatNumber(pcg.Metrics.TAEarnings, this.locale, '1.0-2');
      }
      if (pcg.Metrics.LOAEarnings != null && pcg.Metrics.LOAEarnings != 0) {
        this.old_payoutTitle = 'Bucket Active Prorated Target';
        this.old_payoutIncentiveTitle = 'Bucket Incentive Award (Incentive Target * Payout %) + Allowances';
        this.old_showLOARow = true;
        pcg.LOAEarningsCalc =
          '[ ' +
          prorationInfo.LOA_ITA.toFixed(2) +
          ' x ' +
          (pcg.Weightage * 100).toFixed(2) +
          '% x ( ' +
          pcg.ProrationData.PaidDays +
          '/' +
          this.old_totalDaysInYear +
          ')' +
          ' x 100% ] = ' +
          pcg.Metrics.LOAEarnings.toFixed(2);
      }
    });

    this.old_data.TotalAllowanceEarning = this.old_data.payouts[index].PayoutEarningDetail.TotalTAEarnings + this.old_data.payouts[index].PayoutEarningDetail.TotalLOAEarnings;
    this.old_earningsCurrencyCode = this.sharedContext.payoutCurrency;
    this.old_earningsMessage = this.old_data.payouts[index].PayoutEarningDetail.Message;
    this.old_data.TotalRBIAward = this.old_data.payouts[index].PayoutEarningDetail.ProjectedEarnings;
    this.old_data.payouts[index].PayoutEarningDetail.PcgList.forEach(pcg => {
      this.old_showMorePayIncentive = this.old_showMorePayIncentive || pcg.PayIncentiveComponent.MoreDetails;
    });

    this.old_bindViewModelCalcData();
  };

  old_bindViewModelCalcData = () => {
    this._old_pcglist = [];
    this._old_pcg_manypcc_list = [];
    this.old_data.PcgList.forEach(pcg => {
      if (!pcg.calc) {
        pcg.calc = {};
      }

      var flag = 0;
      pcg.calc.PayoutBeforeCaps = pcg.PayoutStages.PayoutAtThreshold + pcg.PayoutStages.PayoutThresholdToTarget + pcg.PayoutStages.PayoutTargetToExcellence + pcg.PayoutStages.PayoutBeyondExcellence;
      if (pcg.PayoutStages.PayoutThresholdToTarget != 0) {
        pcg.calc.PayoutThresholdToTarget =
          '[ ' +
          this.getPrecision(pcg.PayoutStages.ThresholdPayout, 0) +
          '% + (' +
          this.getPrecision(pcg.PayoutStages.AttainmentThresholdToTarget, 2) +
          '% x ' +
          this.getPrecision(pcg.PayoutStages.MultiplierThresholdToTarget, 2) +
          ') ] = ' +
          this.getPrecision(pcg.PayoutStages.PayoutThresholdToTarget, 2) +
          '%';
        flag++;
      } else {
        pcg.calc.PayoutThresholdToTarget = '-';
      }
      if (pcg.PayoutStages.PayoutTargetToExcellence != 0) {
        pcg.calc.PayoutTargetToExcellence =
          '[ ' +
          this.getPrecision(pcg.PayoutStages.TargetPayout, 0) +
          '% + (' +
          this.getPrecision(pcg.PayoutStages.AttainmentTargetToExcellence, 2) +
          '% x ' +
          this.getPrecision(pcg.PayoutStages.MultiplierTargetToExcellence, 2) +
          ') ] = ' +
          this.getPrecision(pcg.PayoutStages.PayoutTargetToExcellence, 2) +
          '%';
        flag++;
      } else {
        pcg.calc.PayoutTargetToExcellence = '-';
      }
      if (pcg.PayoutStages.PayoutBeyondExcellence != 0) {
        pcg.calc.PayoutBeyondExcellence =
          '[ ' +
          this.getPrecision(pcg.PayoutStages.ExcellencePayout, 0) +
          '% + (' +
          this.getPrecision(pcg.PayoutStages.AttainmentBeyondExcellence, 2) +
          '% x ' +
          this.getPrecision(pcg.PayoutStages.MultiplierBeyondExcellence, 2) +
          ') ] = ' +
          this.getPrecision(pcg.PayoutStages.PayoutBeyondExcellence, 2) +
          '%';
        flag++;
      } else {
        pcg.calc.PayoutBeyondExcellence = '-';
      }
      if (pcg.PayoutStages.PayoutAtThreshold != 0 || flag === 0) {
        pcg.calc.PayoutAtThreshold =
          '[ ' +
          this.getPrecision(pcg.PayoutStages.TillThresholdPayout, 0) +
          '% + (' +
          this.getPrecision(pcg.PayoutStages.AttainmentAtThreshold, 2) +
          '% x ' +
          this.getPrecision(pcg.PayoutStages.MultiplierTillThreshold, 2) +
          ') ] = ' +
          this.getPrecision(pcg.PayoutStages.PayoutAtThreshold, 2) +
          '%';
      } else {
        pcg.calc.PayoutAtThreshold = '-';
      }
      var PayoutAfterCaps = pcg.calc.PayoutBeforeCaps > pcg.UsedCap ? pcg.UsedCap : pcg.calc.PayoutBeforeCaps;
      pcg.calc.PayoutAfterCaps = pcg.calc.PayoutBeforeCaps < 100 && pcg.calc.PeriodStatus === PeriodStatus.TA ? 100 : PayoutAfterCaps;
      this.old_totalColumns += pcg.PlanComponentCombinations.length;

      if (pcg.PlanComponentCombinations.length > 0) {
        var pcgName = '';
        pcg.PlanComponentCombinations.forEach(pcc => {
          pcgName = pcc.Parent;
          if (this._old_pcglist.indexOf(pcgName) != -1) {
            var resultset = pcgName + '_' + pcc.Name;
            this._old_pcglist.push(resultset);
            this._old_pcg_manypcc_list.push(resultset);
          } else {
            this._old_pcglist.push(pcgName);
          }
        });
      }
    });
  };

  old_getAdjustments = PCGList => {
    const targetAdjList = [],
      actualAdjList = [],
      recurringTargetAdjList = [];
    PCGList.forEach(pcg => {
      pcg.PlanComponentCombinations.forEach(pcc => {
        if (pcc.PCCAdjustment) {
          pcc.PCCAdjustment.forEach(adj => {
            const obj = {
              AdjusmentType: adj.AdjusmentType,
              AdjusmentCreatedDate: adj.AdjusmentCreatedDate,
              AdjustmentReason: adj.AdjustmentReason,
              AdjustmentName: adj.AdjustmentName,
              StepId: adj.StepId,
              StepName: ''
            };
            if (adj.TotalTargetAdjustmentAmount !== 0 && !targetAdjList.some(x => x === obj)) {
              targetAdjList.push(JSON.parse(JSON.stringify(obj)));
              this.old_showTgtAdj = true;
            }
            if (adj.TotalAcutalAdjustmentAmount !== 0 && !actualAdjList.some(x => x === obj)) {
              actualAdjList.push(JSON.parse(JSON.stringify(obj)));
              this.old_showActAdj = true;
            }
            if (adj.TotalRecurringTargetAdjustmentAmount !== 0 && !recurringTargetAdjList.some(x => x === obj)) {
              recurringTargetAdjList.push(JSON.parse(JSON.stringify(obj)));
              this.old_showRecAdj = true;
            }
          });
        }
      });
    });
    this.old_masterTargetAjustmentList = [];
    this.old_masterActualAjustmentList = [];
    this.old_masterRecurringTargetAjustmentList = [];
    this.old_createMasterAdjustmentList(targetAdjList, 'TotalTargetAdjustmentAmount', PCGList);
    this.old_createMasterAdjustmentList(actualAdjList, 'TotalAcutalAdjustmentAmount', PCGList);
    this.old_createMasterAdjustmentList(recurringTargetAdjList, 'TotalRecurringTargetAdjustmentAmount', PCGList);
  };

  old_createMasterAdjustmentList = (adjustmentsList, type, PCGList) => {
    if (adjustmentsList.length > 0) {
      const timePeriods = ['H1 (Jul - Dec)', 'H2 (Jan - Jun)'];
      adjustmentsList.forEach(adjustment => {
        const adjustments = [];
        PCGList.forEach(pcg => {
          pcg.PlanComponentCombinations.forEach(pcc => {
            if (pcc.PCCAdjustment) {
              let hasAdjustment = false;
              pcc.PCCAdjustment.forEach(adj => {
                if (
                  adjustment.AdjusmentType === adj.AdjusmentType &&
                  adjustment.AdjusmentCreatedDate === adj.AdjusmentCreatedDate &&
                  adjustment.AdjustmentReason === adj.AdjustmentReason &&
                  adjustment.AdjustmentName === adj.AdjustmentName &&
                  adjustment.StepId === adj.StepId
                ) {
                  adjustments.push(adj[type]);
                  hasAdjustment = true;
                }
              });
              if (!hasAdjustment) {
                adjustments.push('-');
              }
            } else {
              adjustments.push('-');
            }
          });
        });
        // change the step id display in the adjustment
        adjustment.StepName = timePeriods[adjustment.StepId <= 2 ? 0 : 1];
        switch (type) {
          case 'TotalTargetAdjustmentAmount':
            this.old_masterTargetAjustmentList.push({ Adjustment: adjustment, Metrics: adjustments.map(metric => ({ Value: metric })) });
            break;
          case 'TotalAcutalAdjustmentAmount':
            this.old_masterActualAjustmentList.push({ Adjustment: adjustment, Metrics: adjustments.map(metric => ({ Value: metric })) });
            break;
          case 'TotalRecurringTargetAdjustmentAmount':
            this.old_masterRecurringTargetAjustmentList.push({ Adjustment: adjustment, Metrics: adjustments.map(metric => ({ Value: metric })) });
            break;
        }
      });
    }
  };

  getTotalDaysInYear = year => {
    return this.isLeapYear(year) ? 366 : 365;
  };

  isLeapYear = year => {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  };

  getPrecision = (value, precision) => {
    return formatNumber(value, 'en-US', `1.0-${precision}`);
  };

  Sort = PcgList => {
    if (PcgList.length > 0) {
      // Check to remove OCP PPM metric from payout detail report
      PcgList = PcgList.filter(pcg => {
        return pcg.Weightage > 0;
      });
      return PcgList.sort(this.cmp);
    }
  };
  cmp_IsPrimary = (a, b) => {
    return a == b ? 0 : a ? -1 : +1;
  };
  cmp_Weightage = (a, b) => {
    return a == b ? 0 : a < b ? +1 : -1;
  };
  cmp = (a, b) => {
    return this.cmp_IsPrimary(a.IsPrimary, b.IsPrimary) || this.cmp_Weightage(a.Weightage, b.Weightage);
  };
  exportPayoutReport(fileName: string) {
    this.showPayoutReportLoader = true;
    this.payoutReportLabelText = 'Downloading...';
    this.payoutReportService.getExcelReport(this.selectedTab).subscribe(r => {
      this.payoutReportService.downloadFile(r);
      this.showPayoutReportLoader = false;
      this.payoutReportLabelText = '';
    });
  }

  oldExportToExcel = () => {
    var newHtmlObj = this.payout_tables_temp;
    var htmlToExport = '';
    let title = 'PayoutReport';
    this.loadingReport = true;
    if (this.old_data.IsQRApplied) {
      var newHtmlObjQR = this.payout_tables_temp_QR;

      htmlToExport =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><body><table id=Report>' +
        newHtmlObj.nativeElement.innerHTML +
        '</table><table id=Quota Relief>' +
        newHtmlObjQR.nativeElement.innerHTML +
        '</table></body></html>';
    } else {
      htmlToExport =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><body><table id=Report>' +
        newHtmlObj.nativeElement.innerHTML +
        '</table></body></html>';
    }
    return this.payoutReportService.getOldExcelReport(htmlToExport, title, this._old_pcg_manypcc_list, null, this.old_payoutShowTerritoryReport).subscribe(data => {
      this.loadingReport = false;
      this.downloadOpenFile(data, title);
    });
  };

  downloadOpenFile(wbout, reportName) {
    const data = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, reportName + '.xlsx');
  }
}
