import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeatureAdminComponent } from '../feature-admin/feature-admin.component';
import { ManageNotificationComponent } from '../manage-notification/manage-notification.component';
import { ImpersonationComponent } from './impersonation/impersonation.component';
import { PlanActivationComponent } from './plan-activation/plan-activation.component';

const routes: Routes = [
  {
    path: 'impersonate',
    component: ImpersonationComponent,
    data: {
      title: 'Impersonate'
    }
  },
  {
    path: 'planActivation',
    component: PlanActivationComponent,
    data: {
      title: 'Activate Plans'
    }
  },
  {
    path: 'manageNotification',
    component: ManageNotificationComponent,
    data: {
      title: 'Manage Notifications'
    }
  },
  {
    path: 'featureAdmin',
    component: FeatureAdminComponent,
    data: {
      title: 'Feature Admin'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
