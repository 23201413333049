import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from '../../../core/context/user-profile.service';

@Injectable()
export class SidebarService {
  isDashboardActive: boolean;
  isReportActive: boolean;
  isNgReportsActive: boolean;
  isEstimatorActive: boolean;
  isMyCompensationPlanActive: boolean;
  isComplianceActive: boolean;
  isHelpActive: boolean;
  isSettingsActive: boolean;
  isAIActive: boolean;
  isCagrActive: boolean;
  isMic2ReportsActive: boolean;
  isMQCHActive: boolean;
  isBonusActive: boolean;
  isSidebarActive = false;
  // reportSubmenuList: any[];
  // compSubmenuList: any[];
  // helpSubmenuList: any[];

  constructor(private router: Router, public userProfileService: UserProfileService) {
    // this.reportSubmenuList = [
    //   { name: 'Attainment by Performance Metric (PCG)', isActive: false, id: 'features/reports/attainmentByPCG.html', route: 'home.report' },
    //   { name: 'Payout Details', isActive: false, id: 'features/reports/payoutDetailedMetrics.html', route: 'home.report' },
    //   { name: 'Target and Actuals by Incentive Territory', isActive: false, id: 'features/reports/revenueTerritoryDetail.html', route: 'home.report' },
    //   { name: 'Compensation Actuals by Product', isActive: false, id: 'features/reports/productRevenueDetailedMetrics.html', route: 'home.report' },
    //   { name: 'Compensation Actuals by Account', isActive: false, id: 'features/reports/revenueAccountDetails.html', route: 'home.report' },
    //   { name: 'Actuals Analytics (Beta)', isActive: false, id: 'features/reports/revenueAccountDetails.html', route: 'home.report' },
    //   { name: 'Quota Details', isActive: false, id: 'features/compliance/quotaAcknowledgement/quotaAcknowledgement.html', route: 'home.report' }
    // ];
    // this.compSubmenuList = [
    //   { name: 'My Compliance', isActive: false, id: 'features/compliance/myCompliance/myCompliance.html', index: 0, route: 'home.complianceDashboard' },
    //   { name: 'Quota Acknowledgement', isActive: false, id: 'features/compliance/myCompliance/quotaAcknowledgement.html', index: 1, route: 'home.complianceDashboard' },
    //   { name: 'Plan Participation Agreement', isActive: false, id: 'features/compliance/myCompliance/ppaAcceptance.html', index: 2, route: 'home.complianceDashboard' }
    // ];
    // this.helpSubmenuList = [
    //   { name: 'FAQs', isActive: false, id: 'features/help/faq.html', index: 0, route: 'home.help' },
    //   { name: 'Training Materials', isActive: false, id: 'features/help/trainingMaterial.html', index: 1, route: 'home.help' },
    //   { name: 'Glossary', isActive: false, id: 'features/help/glossary.html', index: 2, route: 'home.report' },
    //   { name: 'Accessibility Help', isActive: false, id: 'features/help/accessibilityHelp.html', index: 3, route: 'home.help' }
    // ];
    // this.setOptoutSubMenu();
    this.setFeatureActivation();
  }

  // setOptoutSubMenu() {
  //   if (this.userProfileService.isOptOutEligible) {
  //     this.compSubmenuList.push({ name: 'Opt Out', isActive: false, id: 'features/compliance/myCompliance/optout.html', index: 3, route: 'home.complianceDashboard' });
  //   }
  // }

  // markSubmenuActive(view, submenu) {
  //   this.reportSubmenuList.forEach(sm => (sm.isActive = sm.name === submenu.name && view === sm.route ? true : false));
  //   this.compSubmenuList.forEach(sm => (sm.isActive = sm.name === submenu.name && view === sm.route ? true : false));
  //   this.helpSubmenuList.forEach(sm => (sm.isActive = sm.name === submenu.name && view === sm.route ? true : false));
  // }

  setFeatureActivation() {
    this.isDashboardActive = this.isFeatureActive('/dashboard') || this.isFeatureActive('/ytdDashboard');
    this.isReportActive = this.isFeatureActive('/report');
    this.isNgReportsActive = this.isFeatureActive('/reports');
    this.isEstimatorActive = this.isFeatureActive('/estimator');
    this.isMyCompensationPlanActive = this.isFeatureActive('/compensationPlan');
    this.isComplianceActive = this.isFeatureActive('/complianceV2') || this.isFeatureActive('/complianceDashboard');
    this.isHelpActive = this.isFeatureActive('/help');
    this.isSettingsActive = this.isFeatureActive('/settings');
    this.isAIActive = this.isFeatureActive('/accelerateazure');
    this.isCagrActive = this.isFeatureActive('/cagr');
    this.isMic2ReportsActive = this.isFeatureActive('/mic2Reports');
    this.isMQCHActive = this.isFeatureActive('/mqch');
    this.isBonusActive = this.isFeatureActive('/bonusv2');
  }

  isFeatureActive(fragment) {
    return this.router.url.split('?')[0] === fragment;
  }
}
