import { Injectable } from "@angular/core";
import { EndpointService } from '@mint-libs/network';
import { CustomHttpParams } from "../core/network/custom-http-params";
import { DataService } from '@mint-libs/context';
import { Observable } from 'rxjs';

@Injectable()
export class PlanActivationService {
    constructor(private endpoint: EndpointService, private dataService: DataService) { }

    getPlans(fiscalyear): Observable<any> {
        let params = CustomHttpParams.getInstance();
        params = params.append('currentFiscalYear', fiscalyear);
        return this.dataService.get(this.endpoint.GetPlans.uri, params);
    }

    updatePlans(plans: any[]) {
        const jsonBody = JSON.parse(JSON.stringify(plans ? plans : []));;
        return this.dataService.post<any>(this.endpoint.UpdatePlans.uri, jsonBody);
    }
}