export class Code {
  public static UnAuthorized = 'UnAuthorized';
  public static General = 'Common';
  public static English = 'en';
  public static WrongAppVersion = 'WrongAppVersion';
  public static InSufficientMSSalesPermission = 'InSufficientMSSalesPermission';
  public static NotAnIntendedAudience = 'NotAnIntendedAudience';
  public static NonComplaint = 'NonComplaint';
  public static DisclaimerNotAccepted = 'DisclaimerNotAccepted';
  public static OptedOutStatus = 'OptedOutStatus';
  public static ReportOptedOutStatus = 'ReportOptedOutStatus';
  public static PayoutDisclaimer = 'PayoutDisclaimer';
  public static ReportDisclaimer = 'ReportDisclaimer';
  //public; //TODO= Add below errors in db
  public static NoParticipationFound = 'NoParticipationFound';
  public static ConfigNotFound = 'Failed to retrieve required configuration. Please try again.';
  public static AttainmentThresholdMessage = 'AttainmentThresholdMessage';
  public static OptOutHelptext = 'OptOutHelptext';
  public static TotalWeightedAttainmentText = 'TotalWeightedAttainmentText';
  public static TPIDLevelCustomerAdd = 'CustomerAddTPIDLevel';
  public static NonTPIDLevelCustomerAdd = 'NonTPIDLevelCustomerAdd';
  public static ModifiersMessage = 'ModifiersMessage';
  public static FCAQuotaApprovalPending = 'FCAQuotaApprovalPending';
  public static QAReportMessage = 'QAReportMessage';
  public static OptoutControllerMessages = 'OptoutControllerMessages';
  public static AppControllerOptOutMessages = 'AppControllerOptOutMessages';
  public static PayoutTileOptOutMessages = 'PayoutTileOptOutMessages';
  public static ComplainceOptOutMessages = 'ComplainceOptOutMessages';
  public static ManagerExpandMessage = 'ManagerExpandMessage';
  public static MinimumQuotaThresholdMessage = 'MinimumQuotaThresholdMessage';
  public static H2QuotaMessages = 'H2QuotaMessages';
  public static PayoutUnavailibiltyMessage = 'PayoutUnavailibiltyMessage';
  public static SoftCapNotEligible: 'SoftCapNotEligible';
  public static SoftCapFailure: 'SoftCapFailure';
  public static SoftCapSuccess: 'SoftCapSuccess';
  public static SoftCapTooltipMessages: 'SoftCapTooltipMessages';
  public static QuotaFCAUnapprovedMessage = 'FCAQuotaApprovalPending';
}
