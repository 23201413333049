<app-progress-bar></app-progress-bar>
<div class="admin_container">
  <div class="admin_avatar">
    <ngx-avatar name="{{ userProfileInfo.name }}" initialsSize="2" size="98" [round]="true" src="data:image/jpeg;base64,{{ userProfileInfo.thumbnailPhoto }}" textSizeRatio="2.5"></ngx-avatar>
  </div>
  <div class="admin_details_container">
    <div class="admin_details_name">Welcome {{ userProfileInfo.name }}</div>
    <div class="admin_details_role">
      You are an Admin in the Mint system
    </div>
  </div>
</div>
<div class="impersonation_container">
  <div>
    <div id="impersonation_container">
      <div id="title">
        Impersonation
      </div>
      <div id="impersonate_toggle_btn">
        <div id="toggleSwitch1" class="toggle__btn__container">
          <div id="toggleSwitch2" class="toggle__btn__title">Impersonate Mode</div>
          <div role="button" id="toggleSwitch3_btn" class="toggle__btn" (keyup.enter)="toggleAdminModes()" (keyup.space)="toggleAdminModes()" tabindex="0">
            <input style="display: none;" class="toggle__btn__inp" type="checkbox" [checked]="isChecked" (click)="toggleAdminModes()" />
            <label id="toggleSwitch5" id="toggle_btn_admin" class="toggle__btn__lbl" (click)="toggleAdminModes()"></label>
          </div>
          <div id="toggleSwitch3_title" class="toggle__btn toggle__btn__title">View As Mode</div>
        </div>
      </div>
    </div>
    <form [formGroup]="impersonationForm" id="impersonation_form" (ngSubmit)="onImpersonate()">
      <input
        id="impersonation_input"
        class="form-control"
        placeholder="Alias or Name"
        type="text"
        (input)="onTextChange($event)"
        formControlName="impersonatedAlias"
        aria-label="Impersonate as enter Alias or Name"
        autofocus
        autocomplete
        [value]="search"
        ngxTypeahead
        [taList]="dataList"
        [taItemTpl]="searchAhead"
        (taSelected)="selectedTempStatic($event)"
      />
      <button type="submit" class="btn impersonate-btn m-l-15">
        {{ buttonText }}
      </button>
    </form>
  </div>
  <div>
    <ng-template #searchAhead let-item>
      <div class="item-name">{{ item.result.firstName }} {{ item.result.lastName }}</div>
      <div class="item-desc">({{ item.result.userAlias | lowercase }} - {{ item.result.personnelNumber }})</div>
    </ng-template>
  </div>
  <div id="impersonated_users_container">
    <div *ngIf="userInfoList.length > 0" id="impersonated_users_count">Last {{ userInfoList.length }} impersonated users</div>
    <div id="impersonated_users_carousel_section">
      <ngx-slick-carousel
        class="carousel"
        id="impersonated_users_carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)"
        (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)"
        (mousedown)="dragStart()"
        (mouseup)="dragEnd()"
      >
        <div ngxSlickItem *ngFor="let user of userInfoList; let i = index" class="slide" role="button" tabindex="-1" (click)="startImpersonationByClicking(user.alias)">
          <mint-impersonation-avatar (click)="startImpersonationByClicking(user.alias)" [userInfo]="user"></mint-impersonation-avatar>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>
