<mint-content-loader-grid *ngIf="!isLoaded"></mint-content-loader-grid>
<div *ngIf="isLoaded" id="metric-grid_1" class="grid-display">
  <div id="metric-grid_2" style=" overflow: hidden; flex-grow: 1">
    <ag-grid-angular
      id="ytdGrid"
      class="ag-theme-balham"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      [frameworkComponents]="frameworkComponents"
      [defaultColDef]="defaultColDef"
      [domLayout]="domLayout"
    >
    </ag-grid-angular>
  </div>
</div>
<mint-legends *ngIf="userProfileService.isRBIPersona() && !isYTD" id="dashboard_45" [showOCPLegend]="showOCPLegend"></mint-legends>
