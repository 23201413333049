import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { MintAppModule } from './app/app.module';
import { setAppInjector } from './app/core/routing/url-matchers';
import { environment } from './environments/environment';

// import { UpgradeModule } from '@angular/upgrade/static';
// import { LicenseManager } from "ag-grid-enterprise";
// Evaluation license: To fetch from keyvault TODO
// LicenseManager.setLicenseKey("Evaluation_License_Not_For_Production_Valid_Until1_March_2019__MTU1MTM5ODQwMDAwMA==571888b3c7cbc45a13d91e9c2e885c44");

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function() {};
  }
}

platformBrowserDynamic()
  .bootstrapModule(MintAppModule)
  .then(platformRef => {
    // const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
    // upgrade.bootstrap(document.body, ['participantExpApp']);

    // Disabled PWA
    // if ('serviceWorker' in navigator && environment.production) {
    //   navigator.serviceWorker.register('/ngsw-worker.js');
    // }
    setAppInjector(platformRef.injector);
    // const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
    // upgrade.bootstrap(document.body, ['myApp'], { strictDi: true });
  });
