import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { NetworkService, CustomHttpParams } from '@mint-libs/network';
import { ConfigurationState, selectConfigurationState } from '@mint-libs/configuration';
// import { Filters, FilterState, selectFiltersState } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { Filters } from '../filters/filters.model';
import { FilterState } from '../filters/state/filters.reducer';
import { selectFiltersState } from '../filters/state/filters.selectors';
import { UserProfileState } from '../user-profile/state/user-profile.reducer';
import { UserProfile } from '../user-profile/models/user-profile.model';
import { selectUserProfileState } from '../user-profile/state/user-profile.selectors';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  filters: FilterState;
  userProfile: UserProfile;

  activeVersion: number = 0;

  constructor(private networkService: NetworkService, private filterState: Store<FilterState>, private configState: Store<ConfigurationState>, private userProfileState: Store<UserProfileState>) {
    this.getFilters();
    this.getConfigDetails();
  }

  private getFilters() {
    this.filterState.select(selectFiltersState).subscribe(filters => (this.filters = filters));
    this.userProfileState.select(selectUserProfileState).subscribe(userProfile => {
      if (!userProfile) {
        return;
      }
      this.userProfile = userProfile;
    });
  }

  private getConfigDetails() {
    this.configState.select(selectConfigurationState).subscribe(state => {
      if (state) {
        this.activeVersion = state.versionConfig?.activeVersion;
      }
    });
  }

  public get<T>(uri: string, params: HttpParams = null): Observable<T> {
    return this.networkService.get<T>(uri, params).pipe(
      map(response => {
        // this.refreshCache(response);
        if ((<any>response).result) return (<any>response).result;
        return response as T;
      })
    );
  }

  public post<T>(uri: string, body: JSON): Observable<T> {
    return this.networkService.post<T>(uri, body);
  }

  public getWithGlobalFilters<T>(uri: string, params: HttpParams = null, headers: HttpHeaders = null): Observable<T> {
    if (!(this.filters && this.filters.selectedFilters && this.filters.selectedFilters.stepId > 0 && this.filters.selectedFilters.fiscalYear) || !this.userProfile) {
      return new Observable<T>(subscriber => {
        subscriber.next(undefined);
      });
    }

    if (params == null) {
      params = CustomHttpParams.getInstance();
    }
    params = this.setGlobalFilterParameters(params);
    return this.get<T>(uri, params);
  }

  public postWithGlobalFilters<T>(uri: string, body?: any, headers?: HttpHeaders, responseType?): Observable<T> {
    if (!(this.filters && this.filters.selectedFilters && this.filters.selectedFilters.stepId > 0 && this.filters.selectedFilters.fiscalYear) || !this.userProfile) {
      return new Observable<T>(subscriber => {
        subscriber.next(undefined);
      });
    }

    let jsonBody = JSON.parse(JSON.stringify(body != null ? body : {}));
    jsonBody = this.setGlobalFilterParametersForJson(jsonBody);

    if (headers == null) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');
    if (responseType != null && responseType.toLowerCase() === 'arraybuffer') {
      headers = headers.set('Accept', 'application/octet-stream');
    }

    return this.post<T>(uri, jsonBody);
  }

  setGlobalFilterParameters(params: HttpParams): HttpParams {
    if (!(this.filters && this.filters.selectedFilters && this.filters.selectedFilters.stepId > 0 && this.filters.selectedFilters.fiscalYear)) {
      return params;
    }
    const currentFilters = this.filters.selectedFilters;
    const userProfile = this.userProfile;
    params = params.append('FiscalYearId', this.getValue(currentFilters.fiscalYear));
    params = params.append('FiscalYear', this.getValue(currentFilters.fiscalYear));
    params = params.append('StepId', this.getValue(currentFilters.stepId));
    params = params.append('ActiveVersion', this.getValue(this.activeVersion ?? '1'));
    // params = params.append('Period', currentFilters.selectedCodePeriod ? currentFilters.selectedCodePeriod : null);
    // params = params.append('ManagerViewPeriod', currentFilters.selectedManagerViewCodePeriod ? currentFilters.selectedManagerViewCodePeriod : null);
    params = params.append('PersonnelNumber', this.getValue(userProfile.personnelNumber));
    params = params.append('FiscalQuarterId', this.getValue(currentFilters.fiscalQuarterId));

    // Adjusting for capitalization for user profile fetch vs filter from component (filters.service.ts)
    if (currentFilters.standardTitle) {
      params = params.append('PlanId', this.getValue(currentFilters.standardTitle?.planId ?? currentFilters.standardTitle['PlanId']));
      params = params.append('PlanName', this.getValue(currentFilters.standardTitle.planName ?? currentFilters.standardTitle['PlanName']));
      params = params.append('ParticipationID', this.getValue(currentFilters.standardTitle.participationID ?? currentFilters.standardTitle['ParticipationID']));
      params = params.append('PayeeId', this.getValue(currentFilters.standardTitle.payeeId ?? currentFilters.standardTitle['PayeeId']));
      params = params.append('ParticipationStartDate', this.getValue(currentFilters.standardTitle.planStartDate ?? currentFilters.standardTitle['PlanStartDate']));
      params = params.append('ParticipationEndDate', this.getValue(currentFilters.standardTitle.planEndDate ?? currentFilters.standardTitle['PlanEndDate']));
    }
    params = params.append('IsDMYElligible', this.getValue(userProfile.isDMYEligible));
    // params = params.append('ManagerViewStepId', currentFilters.selectedManagerViewStepId ? currentFilters.selectedManagerViewStepId : null);
    return params;
  }

  setGlobalFilterParametersForJson(jsonObject: any): any {
    if (!(this.filters && this.filters.selectedFilters && this.filters.selectedFilters.stepId > 0 && this.filters.selectedFilters.fiscalYear)) {
      return;
    }
    const currentFilters = this.filters.selectedFilters;
    const userProfile = this.userProfile;
    jsonObject['FiscalYear'] = this.getValue(currentFilters.fiscalYear);
    jsonObject['FiscalYearId'] = this.getValue(currentFilters.fiscalYear);
    jsonObject['ActiveVersion'] = this.getValue(this.activeVersion ?? '1');
    // jsonObject['ManagerFiscalYear'] = this.filterService.selectedManagerViewFiscalYear ? this.filterService.selectedManagerViewFiscalYear : null;
    jsonObject['StepId'] = this.getValue(currentFilters.stepId);
    // jsonObject['Period'] = currentFilters.selectedCodePeriod ? currentFilters.selectedCodePeriod : null;
    // jsonObject['ManagerViewPeriod'] = this.filterService.selectedManagerViewCodePeriod ? this.filterService.selectedManagerViewCodePeriod : null;
    jsonObject['PersonnelNumber'] = this.getValue(userProfile.personnelNumber);
    jsonObject['FiscalQuarterId'] = this.getValue(currentFilters.fiscalQuarterId);
    // jsonObject['FiscalSemesterId'] = this.getValue(currentFilters.fiscalSemesterId);
    if (currentFilters.standardTitle) {
      jsonObject['PlanId'] = this.getValue(currentFilters.standardTitle.planId ?? currentFilters.standardTitle['PlanId']);
      jsonObject['PlanName'] = this.getValue(currentFilters.standardTitle.planName ?? currentFilters.standardTitle['PlanName']);
      jsonObject['ParticipationID'] = this.getValue(currentFilters.standardTitle.participationID ?? currentFilters.standardTitle['ParticipationID']);
      jsonObject['PayeeId'] = this.getValue(currentFilters.standardTitle.payeeId ?? currentFilters.standardTitle['PayeeId']);
      jsonObject['ParticipationStartDate'] = this.getValue(currentFilters.standardTitle.planStartDate ?? currentFilters.standardTitle['PlanStartDate']);
      jsonObject['ParticipationEndDate'] = this.getValue(currentFilters.standardTitle.planEndDate ?? currentFilters.standardTitle['PlanEndDate']);
    }
    // jsonObject['ManagerViewStepId'] = this.filterService.selectedManagerViewStepId ? this.filterService.selectedManagerViewStepId : null;

    return jsonObject;
  }

  getValue(value: any): string {
    return value !== null && value !== undefined ? value.toString() : null;
  }
}
