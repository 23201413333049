import { CustomHttpParams } from 'src/app/core/network/custom-http-params';
import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../../core/network/data.service';
import { Observable } from 'rxjs';

@Injectable()
export class ManagerDashboardService {
  constructor(private endpoint: EndpointService, private dataService: DataService) {}

  getDirectsSummary(alias: string = null,directsLevel:string = null ) {
    let params = CustomHttpParams.getInstance();
    if (alias) {
      params = params.append('Alias', alias);
      params = params.append('DirectsLevel', directsLevel);
    }
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetDirectsInformation.uri, params);
  }

  getDirectsQuotaToGo(alias: string) {
    let params = CustomHttpParams.getInstance();
    params = params.append('Alias', alias);
    return this.dataService.getWithGlobalFilters<any>(this.endpoint.GetDirectsQuotaToGo.uri, params);
  }

  getDirectsAccelerateAzure(alias: string) {
    let params = CustomHttpParams.getInstance();
    params = params.append('Alias', alias);
    return this.dataService.getWithGlobalFilters<any>(this.endpoint.GetDirectsAzureIncentives.uri, params);
  }

  getDirectsSoftCapDetails(alias: string): Observable<any> {
    let params = CustomHttpParams.getInstance();
    params = params.append('Alias', alias);
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetDirectsSoftCap.uri, params);
  }

  updateDirectSoftCapDetails(directsSoftCapData: any[], isViewAs: boolean): Observable<any> {
    let directsSoftCapData1 = directsSoftCapData;
    return this.dataService.postWithGlobalFilters(this.endpoint.UpdateSoftCapDetails.uri, directsSoftCapData1);
  }

  getDirectsPayoutDetails(reportType: string, fromFreezeSnapshot: boolean = false, includePivot:boolean=false,settings: string=null,) {
    const jsonParam = { IncludePivot: includePivot, Settings:settings,  ReportType: reportType, FromFreezeSnapshot: fromFreezeSnapshot };
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.GetDirectsPayoutDetails.uri, jsonParam, null, 'arraybuffer');
  }

  getDirectsQuotaDetails() {
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.GetDirectsQuotaDetails.uri, null, null, 'arraybuffer');
  }

  getDirectsTerritoryDetails(alias: string, fromVersionSnapShot: boolean = false) {
    const jsonParam = { Alias: alias, FromVersionSnapShot: fromVersionSnapShot };
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.GetDirectsTerritoryDetails.uri, jsonParam, null, 'arraybuffer');
  }
}
