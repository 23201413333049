export const COMMON_ISSUES = [
  {
    id: 1,
    title: 'Not able to access Mint?',
    icon: 'error_outline',
    content:
      '<p> We have verified you as an active user and seems that your setup to be alright. <br /> It would be great if you can try the below steps for Edge. <br />1. Open the Dev Tools (F12).<br />  2. Go to Debugger/Storage View.<br /> 3. Open the local Storage tab (select localStorage).&nbsp &nbsp; <br /> 4. Select all and delete.<br /> And relaunch the site.</p><p>Also, below are the supported browsers for Web version of Mint.<br /> <b>Edge, Chrome, Firefox and Opera.</b><br /> You can try any of them. Please let us know if the issue still persists. <br/> We would be happy to assist on call also.<br /></p> <p>Alternatively you can also follow the below steps.<br /><b>Edge:</b><br />  open Edge Browser<br />   Ctrl + Shft + p (this open a new In Private window).<br /> <b>Please login to mint.microsoft.com</b></p> <p><b>Chrome:</b><br />  open Chrome Browser<br /> Ctrl + Shft + n (this open a new In Private window).<br /> <b>Please login to mint.microsoft.com</b></p>',
    isExpanded: false
  },
  {
    id: 2,
    title: 'My data in Mint has not refreshed yet.',
    icon: 'sync_problem',
    content:
      'Please check the Data Source Refresh tile on the dashboard for the latest Mint refresh date. Please note that this date is when Mint refreshed from the source. To check when the source refreshed last please validate with the individual sources.',
    isExpanded: false
  },
  {
    id: 3,
    title: 'My data does not match at the Summary and Detailed level',
    icon: 'data_usage',
    content: 'Data at the detailed level does not account for Participant Level Adjustments & Modifiers. Also, data in the detailed reports is always the latest.',
    isExpanded: false
  }
];