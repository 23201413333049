import { Injectable } from '@angular/core';
import { DataService } from '../../core/network/data.service';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import * as _ from 'underscore';
import { selectUserProfileState, UserProfileState } from '@mint-libs/context';
import { Store } from '@ngrx/store';

@Injectable()
export class WidgetFactory {
  widgets = [];
  widgetListforRole = [];
  widgetInContext = {};
  //TODO: This is required but not rightly placed - should be moved to db
  widgetsMeta = [
    {
      type: 'product',
      meta: {
        endpoint: 'api/revenue/GetRevenueProductSummary',
        sortColumns: [
          { name: 'Name', value: 'label' },
          { name: 'Revenue', value: 'value' }
        ]
      }
    },
    {
      type: 'Territory',
      meta: {
        endpoint: 'api/revenue/GetRevenueSalesTerritory',
        sortColumns: [
          { name: 'Name', value: 'label' },
          { name: 'Revenue', value: 'value' }
        ]
      }
    },
    {
      type: 'account',
      meta: {
        endpoint: 'api/revenue/GetRevenueAccountSummary',
        sortColumns: [
          { name: 'Name', value: 'label' },
          { name: 'Revenue', value: 'value' }
        ]
      }
    }
  ];

  constructor(private dataService: DataService, private userProfileState: Store<UserProfileState>) {
    this.userProfileState.select(selectUserProfileState).subscribe(state => {
      if (!state) {
        return;
      }
      this.setWidgets(state.settings?.widgets ?? (state.settings.widgetsRole as object[]));
      this.setwidgetListforRole(state.settings.widgetsRole as object[]);
    });
  }

  setWidgets(widgets) {
    this.widgets = widgets;
  }

  setwidgetListforRole(widgetsListForRole) {
    this.widgetListforRole = widgetsListForRole;
  }

  getWidgetSequence(list: any[], sortColumn, rowVal) {
    const allWithTemplates = list.filter(l => {
      return l.HTMLTemplate != null;
    });
    const sorted = allWithTemplates.sort((a, b) => a[sortColumn] - b[sortColumn]);

    let count = 1,
      r = 0,
      c = 0;
    const mapped = sorted.map(function(row, index) {
      if (count <= rowVal) {
        row.row = r;
        row.col = c;

        if (count === rowVal) {
          count = 1;
          r = r + 1;
          c = 0;
        } else {
          count++;
          c = c + 3;
        }
      }
      return row;
    });

    return mapped;
  }

  setWidgetSequence(list, sortColumn) {
    const sorted = list.sort((a, b) => a[sortColumn] - b[sortColumn]);

    var mapped = sorted.map((row, index) => {
      row.Col = index;
      return row;
    });

    return mapped;
  }

  changeWidgetContext(w) {
    // this.widgetInContext = w;
    // $rootScope.$broadcast('handleChangeInWidgetContext');
  }

  extendWidget(widget) {
    widget = Object.assign(widget, {
      showLoading: function() {
        widget.loaded = false;
      },
      hideLoading: function() {
        widget.loaded = true;
      }
    });
  }

  setWidgetSequenceForUser(widgets) {
    var date = new Date();
    var endPoint = {
      uri: 'api/Participant/SetWidgetSequenceForUser',
      forceRedirect: true
    };
    var data: Object[] = [];
    var listItems = widgets;

    for (var i = 0; i < listItems.length; i++) {
      data.push({
        WidgetConfigId: listItems[i].WidgetConfigId,
        WidgetId: listItems[i].WidgetId,
        Col: listItems[i].Col,
        RoleId: listItems[i].RoleId,
        IsVisible: listItems[i].IsVisible,
        timestamp: date
      });
    }

    return this.dataService.push(environment.mintapiBaseUrl + endPoint.uri, data, '');
  }

  setWidgetConfigurationForUser(widget) {
    var data = {
      WidgetConfigId: widget.WidgetConfigId,
      DisplayName: widget.DisplayName,
      WidgetId: widget.WidgetId,
      IsVisible: widget.IsVisible,
      Type: widget.Type,
      RoleId: widget.RoleId,
      SelectedValue: widget.SelectedValue,
      Col: widget.Col,
      SelectedPCValue: widget.SelectedPCValue,
      Specs: {
        SortBy: widget.Specs.SortBy,
        IsAsc: widget.Specs.IsAsc,
        Top: widget.Specs.Top,
        FilterBy: widget.Specs.FilterBy,
        Operator: widget.Specs.Operator,
        FilterValues: widget.Specs.FilterValues
      }
    };

    return this.dataService.push(environment.mintapiBaseUrl + 'api/Participant/SetWidgetConfigurationForUser', data, '');
  }

  resetWidgetConfiguration(roleId) {
    var endPoint = 'api/Participant/ResetWidgetConfiguration';
    const param = new HttpParams().append('RoleId', roleId);
    return this.dataService.getWithGlobalFilters(environment.mintapiBaseUrl + endPoint, param);
  }

  getRoleWidgetList() {
    return this.dataService.getWithGlobalFilters(environment.mintapiBaseUrl + 'api/Participant/GetRoleWidgetList');
  }
}
