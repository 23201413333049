import { SafeHtml } from '@angular/platform-browser';
import { DataSource } from './data-source.model';
import { BaseModel } from './base.model';
import { Expose, deserializeArray, plainToClass } from 'class-transformer';

export class CagrParticipationDetails extends BaseModel {
  @Expose({ name: 'CagrParticipationID' }) cagrParticipationId: number;
  @Expose({ name: 'RevMapName' }) revMapName: string;
  @Expose({ name: 'ParticipationStartDate' }) participationStartDate: Date;
  @Expose({ name: 'ParticipationEndDate' }) participationEndDate: Date;
  @Expose({ name: 'ParticipationStartYear' }) participationStartYear: number;
  @Expose({ name: 'ParticipationEndYear' }) participationEndYear: number;

  deserialize(from: any): CagrParticipationDetails[] {
    const cagrAccountDetailsList = deserializeArray<CagrParticipationDetails>(CagrParticipationDetails, JSON.stringify(from), this.classTransformOptions);
    return cagrAccountDetailsList;
  }
  deserializeSingle(from: object): CagrParticipationDetails {
    const cagrAccountDetails = plainToClass<CagrParticipationDetails, object>(CagrParticipationDetails, from, this.classTransformOptions);
    return cagrAccountDetails;
  }
}
