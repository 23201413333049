import { BaseModel } from './base.model';
import { IDeserializable } from './iDeserializable.interface';
import { deserializeArray, Expose } from 'class-transformer';

export class PaylineSegment extends BaseModel implements IDeserializable<PaylineSegment[]> {
  @Expose({ name: 'SegmentName' })
  name: string;
  @Expose({ name: 'IsCurrent' })
  isCurrent: boolean;
  @Expose({ name: 'IsOld' })
  isOld: boolean;
  isActive: boolean;
  @Expose({ name: 'AttainmentFrom' })
  attainmentFrom: number;
  @Expose({ name: 'PayoutPercentageFrom' })
  payoutPercentageFrom: number;
  deserialize(from: any): PaylineSegment[] {
    const paylineSegments = deserializeArray<PaylineSegment>(PaylineSegment, JSON.stringify(from), this.classTransformOptions);
    return paylineSegments;
  }
}
