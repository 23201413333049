<mint-content-loader-icon-text *ngIf="!isattainmentDetailsLoaded"></mint-content-loader-icon-text>
<!-- <ul>
<li>
  isAccountsLoaded = {{isAccountsLoaded}} <br>
  isAwardsUnlockedLoaded = {{isAwardsUnlockedLoaded}} <br>
  isattainmentDetailsLoaded = {{isattainmentDetailsLoaded}} <br>
</li>
</ul> -->
<div>
  <br>
  <div>
    This program allows sellers in designated roles to be rewarded an additional 2.5% of their RBI Target Incentive for meeting their targets for each of their priority products. Eligible sellers who have all strategic priorities in their plan are eligible for the Priorities+ Excellence Bonus, an additional 2.5% for achieving quota on all priorities.
  </div>
  <div>
    For more information on this bonus, please visit your <a href="https://microsoft.sharepoint.com/teams/IncentiveCompensationGuide/SitePages/Incentive%20Programs/FY22/PrioritiesBonus.aspx" target="_blank" 
    title="Incentive Compensation Guide"> Incentive Compensation Guide.</a> 
  </div>
</div>
<div class="cagr__views">
  <div class="app__nav app__nav-views">
    <mint-tabs [tabs]="chartGridViewTab" (tabChange)="onTabChange($event)" [activeId]="activeTabId" [tracker]="'Cagr_ChartGridView'" [source]="'Cagr'"></mint-tabs>
  </div>
  <div class="tab__container">
    <mint-content-loader-bar-chart-horizontal *ngIf="!isattainmentDetailsLoaded"> </mint-content-loader-bar-chart-horizontal>
    <mint-bonus-chart-view
      *ngIf="!gridView && isattainmentDetailsLoaded"
      [bonusComponents]="bonusComponents"
      [bingoComponents]="bingoComponents"  
    ></mint-bonus-chart-view>
    <mint-bonus-grid-view
      *ngIf="gridView && isattainmentDetailsLoaded"
      
      [bonusComponents]="bonusComponents"
      [bingoComponents]="bingoComponents"
      [gridLabel]="'Meet target in at least one cloud'"
    ></mint-bonus-grid-view>
  </div>
</div>
<div style="height:100px;" id="bonus_tootip_content"></div>

