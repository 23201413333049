<button id="detail-dropdown_1" type="button" class="btn btn__select" (click)="openModal(template)">
  <div id="detail-dropdown_2" class="btn__select__txt">{{ displayText }}</div>
  <div id="detail-dropdown_3" class="icon icon-chevron-down"></div>
</button>

<ng-template id="detail-dropdown_4" #template let-modal>
  <div id="detail-dropdown_5" class="modal-body__centre">
    <div id="detail-dropdown_6" class="dropdown__container">
      <button id="detail-dropdown_7" class="dropdown__close close" aria-label="Close" (click)="modalRef.close()">
        <svg id="detail-dropdown_8" class="high_contrast_mode_close" width="10" height="10" viewBox="0 0 10 10" fill="#333333" xmlns="http://www.w3.org/2000/svg">
          <path
            id="detail-dropdown_9"
            d="M9.70398 0.851639L5.561 5.00004L9.70398 9.14844L9 9.85164L4.85199 5.70904L0.703979 9.85164L0 9.14844L4.14297 5.00004L0 0.851639L0.703979 0.148438L4.85199 4.29104L9 0.148438L9.70398 0.851639Z"
          />
        </svg>
      </button>
      <div id="detail-dropdown_10" class="dropdown__content">
        <fieldset id="detail-dropdown_11" class="content__left">
          <legend id="detail-dropdown_12" class="content__header">{{ itemCaption }}</legend>
          <div id="detail-dropdown_13">
            <div
              id="detail-dropdown_15"
              class="high_contrast_mode_radio__btn_grp"
              [ngClass]="{
                radio__btn__grp: true,
                active: newSelectedItem === item.text
              }"
              *ngFor="let item of items; let idx = index"
            >
              <input
                class="radio__btn__inp"
                type="radio"
                name="{{ itemCaption }}"
                id="input__{{ item.text.split(' ').join('-') }}"
                (change)="selectItem(item.text)"
                [checked]="item.text === newSelectedItem"
              />
              <label id="detail-dropdown_16" class="radio__btn__lbl" for="input__{{ item.text.split(' ').join('-') }}">
                <div id="detail-dropdown_17" class="radio__btn__new"></div>
                {{ item.text }}
              </label>
              <div id="detail-dropdown_18" class="radio__btn__active icon icon-chevron-right"></div>
            </div>
          </div>
        </fieldset>
        <fieldset id="detail-dropdown_19" class="content__right">
          <legend id="detail-dropdown_20" class="content__header">{{ subItemCaption }}</legend>
          <div id="detail-dropdown_21">
            <div
              id="detail-dropdown_23"
              class="high_contrast_mode_radio__btn_grp"
              [ngClass]="{
                radio__btn__grp: true,
                active: newSelectedSubItem === subItem
              }"
              *ngFor="let subItem of subItems; let idx = index"
            >
              <input
                class="radio__btn__inp"
                type="radio"
                name="{{ subItemCaption }}"
                id="input__subItem__{{ subItem.split(' ').join('-') }}"
                (change)="newSelectedSubItem = subItem"
                [checked]="newSelectedSubItem === subItem"
              />
              <label id="detail-dropdown_24" class="radio__btn__lbl" for="input__subItem__{{ subItem.split(' ').join('-') }}">
                <div id="detail-dropdown_25" class="radio__btn__new "></div>
                {{ subItem }}
              </label>
            </div>
          </div>
        </fieldset>
      </div>
      <div id="detail-dropdown_26" class="dropdown__footer">
        <button id="detail-dropdown_27" class="footer__btn footer__btn-primary" (click)="applyDetailDropdownFilter()" tabindex="0">Apply</button>
        <button id="detail-dropdown_28" class="footer__btn footer__btn-cancel" (click)="cancelDetailDropdownFilter()" tabindex="0">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
