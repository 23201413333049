import { deserializeArray, Expose, plainToClass, Type } from 'class-transformer';
import { BaseModel } from '../../../shared/models/base.model';
import { Revenue } from './revenue.model';

export class RevenueTerritory extends BaseModel {
  @Expose({ name: 'TerritoryName' })
  name: string;

  @Expose({ name: 'Type' })
  type: string;

  @Expose({ name: 'PCCName' })
  pccName: string;

  @Expose({ name: 'PCCID' })
  pccID: number;

  @Expose({ name: 'PCGName' })
  pcgName: string;

  @Type(() => Revenue)
  @Expose({ name: 'Revenue' })
  revenue: Revenue;

  @Expose({ name: 'IsPODBasedQuota' })
  isPODBasedQuota: boolean;

  deserialize(from: any): RevenueTerritory[] {
    const revenueTerritories: RevenueTerritory[] = [];
    from.forEach(a => {
      const territory = new RevenueTerritory();
      territory.revenue = new Revenue().deserializeSingle(a.Revenue);
      territory.name = a.TerritoryName;
      territory.type = a.Type;
      territory.pccName = a.PCCName;
      territory.pccID = a.PCCId;
      territory.pcgName = a.PCGName;
      revenueTerritories.push(territory);
    });
    return revenueTerritories;
  }
  deserializeSingle(from: any): RevenueTerritory {
    const territory = new RevenueTerritory();
    territory.revenue = new Revenue().deserializeSingle(from.Revenue);
    territory.name = from.TerritoryName;
    territory.type = from.Type;
    territory.pccName = from.PCCName;
    territory.pccID = from.PCCId;
    territory.pcgName = from.PCGName;
    return territory;
  }
}
