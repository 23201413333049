import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, map, tap } from 'rxjs/operators';
import { ConfigurationActionTypes } from '@mint-libs/configuration';
import { FilterActionTypes } from '../../filters/state/filters.actions';
import { MeasureTypeMappingService } from '../../network/measure-type-mapping.service';
import { UserProfileActionTypes } from '../../user-profile/state/user-profile.actions';
import { LoadMeasureTypeMapping, MeasureTypeMappingAction } from './measure-type-mapping.actions';

@Injectable()
export class MeasureTypeMappingEffect {
  loadMeasureTypeMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActionTypes.SetFilters, FilterActionTypes.UpdateFilters, ConfigurationActionTypes.LoadConfigurationSuccess),
      mergeMap(action => {
        return this.measureService.getMeasureTypeMapping().pipe(
          map(mapping => {
            return new LoadMeasureTypeMapping({ mapping: mapping ?? [] });
          })
        );
      })
    )
  );

  constructor(private actions$: Actions<MeasureTypeMappingAction>, private measureService: MeasureTypeMappingService) {}
}
