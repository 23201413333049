import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UserProfileService } from '../core/context/user-profile.service';
import { ToDateFormatPipe, UpdateSharedContext } from '@mint-libs/common';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { FiltersService } from './filters.service';
import { FilterState, UpdateFilters, UserPreference } from '@mint-libs/context';
import { AuthorizationService } from '../core/authorization/authorization.service';
import { ImpersonationService } from '../core/impersonation/impersonation.service';
import { InitializationService } from '../core/initialization/initialization.service';
import { UserSessionService } from '../core/session/user-session-service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'mint-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  @Input()
  config: any = {};
  @Output() apply = new EventEmitter();

  // filterService: any;
  // initializationService: any;
  fiscalYearList = [];
  managerFiscalYearList = [];

  selectedFiscalYear = null;
  selectedManagerViewFiscalYear = null;
  standardTitleList = [];
  selectedStandardTitle: any = null;
  selectedPartition = null;
  selectedFiscalQuarterId = null;
  selectedFiscalSemesterId = null;
  selectedManagerViewStepId = null;
  isFormDirty = false;
  reInit = false;
  applicablePartitionsList = [];
  managerDashboardPartitions = [];
  selectedManagerPartition = null;
  selectedManagerFiscalQuarterId = null;
  selectedManagerFiscalSemesterId = null;
  selectedManagerPartitionName = null;

  allStandardTitles = [];
  allManagerTimePeriods = [];
  isDemoMode = environment.isDemoMode2;
  public sharedContext: SharedContext = new SharedContext();
  constructor(
    private filterService: FiltersService,
    private initializationService: InitializationService,
    public userProfileService: UserProfileService,
    private impersonationService: ImpersonationService,
    public sharedContextState: Store<SharedContext>,
    private authorizationService: AuthorizationService,
    private toDateFormat: ToDateFormatPipe,
    private router: Router,
    private filterState: Store<FilterState>,
    private userSessionService: UserSessionService,
    private spinner: NgxSpinnerService
  ) {
    this.filterService = filterService;
    this.config.isSearch = false;
    this.config.isManagerView = false;
    this.config.isPartition = true;
    this.config.isTitle = true;
    this.config.isFiscalYear = true;
    this.config.showFilter = true;
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    let listOfYears = this.filterService.fiscalYearList;
    if (this.router.url === '/mqch') {
      listOfYears = listOfYears.filter(x => x.Key >= 2021);
    }
    this.fiscalYearList = this.populateFiscalYearList(JSON.parse(JSON.stringify(listOfYears)).sort());
    this.managerFiscalYearList = this.populateFiscalYearList(this.filterService.managerViewFiscalYearsList);
    this.allStandardTitles = this.filterService.standardTitleList;
    // Sort titles
    if (this.allStandardTitles) {
      this.allStandardTitles.sort((a, b) => this.sortByDate(a.startDate, b.startDate));
    }

    this.allManagerTimePeriods = this.filterService.managerDashboardPartitionsList;
    this.setSelections();
    this.setManagerPartitions();
  }

  populateFiscalYearList(fiscalYears) {
    if (!fiscalYears) {
      return [];
    }
    const allFiscalYears = fiscalYears.map(function(fiscalYear) {
      return JSON.parse(fiscalYear.Key);
    });
    const latestFiscalYear = Math.max.apply(Math, allFiscalYears);

    const applicableFiscalyears = fiscalYears.filter(function(fiscalYear) {
      return !this.isDemoMode || (this.isDemoMode && fiscalYear.Key === latestFiscalYear);
    }, this);

    const fiscalYearList = applicableFiscalyears.map(function(fiscalYear) {
      return {
        value: fiscalYear.Key,
        text: 'FY' + fiscalYear.Key.toString().substring(2)
      };
    });
    // filterVm.FiscalYearList.unshift({ 'value': 'Select Fiscal Year', 'text': 'Select Fiscal Year' });
    return fiscalYearList;
  }

  populateStandardTitleList(fiscalYear, manualSelection) {
    // Find applicable standard titles
    const applicableStandardTitles = this.allStandardTitles.filter(function(title) {
      return title.FiscalYear.Key === fiscalYear;
    }, this);
    applicableStandardTitles.sort((a, b) => this.sortByDate(a.startDate, b.startDate));
    // Prepare as a list to be bound
    this.standardTitleList = applicableStandardTitles.map(function(title) {
      let text = title.Name;
      if (title.ParticipationID > 0) {
        const startDate = this.toDateFormat.transform(title.StartDate, 'MMM d, yyyy');
        const endDate = this.toDateFormat.transform(title.EndDate, 'MMM d, yyyy');
        text += ' (' + startDate + ' - ' + endDate + ')';
      }
      return {
        value: title,
        text: text
      };
    }, this);

    if (applicableStandardTitles.length > 0) {
      // when the fiscal year filter is changed,below methods refreshes standard title & Quarter dropdown filter
      if (manualSelection) {
        this.getTitlesByDate(applicableStandardTitles);
        this.setCurrentPartition(applicableStandardTitles);
      }
    }
  }

  setSelections() {
    // Set FiscalYear selection
    this.selectedFiscalYear = this.filterService.selectedFiscalYear;
    this.selectedManagerViewFiscalYear = this.filterService.selectedManagerViewFiscalYear;
    this.onFiscalYearChange(true);
    // Set StandardTitle selection
    const title = this.standardTitleList.find(
      t => t.value.StartDate === this.filterService.selectedStandardTitle.StartDate && t.value.EndDate === this.filterService.selectedStandardTitle.EndDate,
      this
    );

    if (title) {
      this.selectedStandardTitle = title.value;
      this.onStandardTitleChange();
      this.selectedPartition = this.filterService.selectedStepId;
      this.selectedFiscalQuarterId = this.filterService.selectedFiscalQuarterId;
      this.selectedFiscalSemesterId = this.filterService.selectedFiscalSemesterId;
    }

    this.selectedManagerPartition = this.filterService.selectedManagerViewStepId;

    this.isFormDirty = false;
  }

  setManagerPartitions() {
    if (!this.filterService.managerDashboardPartitionsList || this.filterService.managerDashboardPartitionsList.length === 0) {
      return;
    }
    this.setApplicableManagerPartitions(this.filterService.managerDashboardPartitionsList);
    this.filterService.managerViewPartitions = this.managerDashboardPartitions;
    if (!this.filterService.isManagerViewPartitionLoaded) {
      const partition = this.getApplicablePartition(this.filterService.managerViewPartitions);
      this.selectedManagerFiscalQuarterId = partition.FiscalQuarterId;
      this.selectedManagerFiscalSemesterId = partition.FiscalSemesterId;
      this.filterService.selectedManagerViewStepId = partition.StepId;
      this.selectedManagerViewStepId = this.filterService.selectedManagerViewStepId;
    }
    this.selectedManagerPartition = this.filterService.selectedManagerViewStepId;

    this.filterService.isManagerViewPartitionLoaded = true;
  }

  setApplicableManagerPartitions(partitions) {
    let startDate;
    const applicablYearPartitions = partitions.filter(function(p) {
      return p.FiscalYear === this.selectedManagerViewFiscalYear;
    }, this);
    // Sort partitions
    applicablYearPartitions.sort((a, b) => this.sort(a.StepId, b.StepId));

    this.managerDashboardPartitions = applicablYearPartitions.map(function(title) {
      if (!startDate) {
        startDate = new Date(title.Duration.StartDate);
      }
      const currentParition = this.setPartition(title, startDate, true);
      currentParition.Key = title.Key;

      return currentParition;
    }, this);
  }

  onFiscalYearChange(manualSelection, isManagerView = false) {
    this.isFormDirty = true;
    this.reInit = true;
    if (isManagerView) {
      this.selectedFiscalYear = this.selectedManagerViewFiscalYear;
    } else {
      this.selectedManagerViewFiscalYear = this.selectedFiscalYear;
    }

    this.populateStandardTitleList(this.selectedFiscalYear, manualSelection);
  }

  onManagerViewFiscalYearChange() {
    this.isFormDirty = true;
    this.reInit = true;
    this.onFiscalYearChange(true, true);
    this.filterService.isManagerViewPartitionChanged = false;
    this.filterService.isManagerViewPartitionLoaded = false;
    this.setManagerPartitions();
  }

  onStandardTitleChange() {
    this.isFormDirty = true;
    this.reInit = true;
    this.setApplicablePartitions(this.selectedStandardTitle.ApplicablePartitions);
    if (this.selectedStandardTitle.StartDate === this.filterService.selectedStandardTitle.StartDate && this.selectedStandardTitle.EndDate === this.filterService.selectedStandardTitle.EndDate) {
      this.selectedPartition = this.filterService.selectedStepId;
      this.selectedFiscalQuarterId = this.filterService.selectedFiscalQuarterId;
      this.selectedFiscalQuarterId = this.filterService.selectedFiscalSemesterId;
    } else {
      this.selectedPartition = this.applicablePartitionsList[this.applicablePartitionsList.length - 1].StepId;
      this.selectedFiscalQuarterId = this.applicablePartitionsList[this.applicablePartitionsList.length - 1].FiscalQuarterId;
      this.selectedFiscalSemesterId = this.applicablePartitionsList[this.applicablePartitionsList.length - 1].FiscalSemesterId;
    }
    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        appliedFilter: {
          ...this.sharedContext.appliedFilter,
          ApplicablePartitions: this.applicablePartitionsList
        }
      })
    );
    this.getTitlesByDate(
      this.standardTitleList.map(title => title.value),
      this.selectedStandardTitle
    );
  }

  onPartitionChange() {
    this.isFormDirty = true;
  }

  setApplicablePartitions(defaultStandardTitle) {
    let startDate;
    if (this.selectedStandardTitle == null) {
      this.selectedStandardTitle = this.standardTitleList[0].value;
    }
    this.applicablePartitionsList = this.selectedStandardTitle.ApplicablePartitions.map(function(title) {
      if (!startDate) {
        startDate = new Date(title.Duration.StartDate);
      }
      this.filterService.applicablePartitionsList = this.applicablePartitionsList;

      let overridenStartDate = startDate;

      if (new Date(this.selectedStandardTitle.StartDate) > new Date(title.Duration.StartDate) && !this.userProfileService.isNPM() && !this.userProfileService.isNPMForced()) {
        overridenStartDate = new Date(this.selectedStandardTitle.StartDate);
      }
      return this.setPartition(title, overridenStartDate, false);
    }, this);
  }

  setCurrentPartition(applicableStandardTitles) {
    const defaultStandardTitle = this.getApplicablePartition(applicableStandardTitles);
    this.setApplicablePartitions(defaultStandardTitle);

    const partition = this.getApplicablePartition(defaultStandardTitle.ApplicablePartitions);

    this.selectedPartition = partition.StepId;
    this.selectedFiscalQuarterId = partition.FiscalQuarterId;
    this.selectedFiscalSemesterId = partition.FiscalSemesterId;
  }

  setPartition(title, overridenStartDate = null) {
    const endDate = new Date(title.Duration.EndDate);
    let startDate = new Date(title.Duration.StartDate);
    if (overridenStartDate) {
      startDate = overridenStartDate;
    }
    return {
      StepId: title.StepId,
      FiscalQuarterId: title.FiscalQuarterId,
      FiscalSemesterId: title.FiscalSemesterId,
      StartDate: title.Duration.StartDate,
      EndDate: title.Duration.EndDate,
      text: this.setPartitionText(title.Key, startDate, endDate)
    };
  }

  setPartitionText(key, startDate, endDate) {
    const text = this.filterService.setPartitionText(key, startDate, endDate);
    return text;
  }

  getTitlesByDate(applicableStandardTitles, selectedStandardTitle = null) {
    let currentDate = null;
    let standardTitles = null;
    if (selectedStandardTitle == null) {
      this.selectedStandardTitle = this.getApplicablePartition(applicableStandardTitles); // applicableStandardTitles[0];
    }
    if (this.selectedStandardTitle == null) {
      this.selectedStandardTitle = applicableStandardTitles[0];
    } else {
      currentDate = new Date(this.selectedStandardTitle.StartDate);
      standardTitles = applicableStandardTitles.filter(function(obj) {
        return currentDate >= new Date(obj.StartDate) && currentDate <= new Date(obj.EndDate);
      }, this);
      this.selectedStandardTitle = standardTitles.length > 0 ? standardTitles[0] : applicableStandardTitles[0];
    }
  }

  getApplicablePartition(partition) {
    const currentDate = new Date();
    let result = partition.find(function(f) {
      const start = new Date(f.StartDate || f.Duration.StartDate);
      const end = new Date(f.EndDate || f.Duration.EndDate);

      return currentDate.getTime() >= start.getTime() && currentDate.getTime() <= end.getTime();
    }, this);

    if (!result || result.length === 0) {
      result = partition[partition.length - 1];
    }

    return result;
  }

  toggleFilter() {
    this.config.showFilter = !this.config.showFilter;
  }

  update() {
    this.filterService.previousValues = {};
    this.filterService.previousValues.selectedFiscalYear = this.filterService.selectedFiscalYear;
    this.filterService.previousValues.selectedManagerViewFiscalYear = this.filterService.selectedManagerViewFiscalYear;
    this.filterService.previousValues.selectedStandardTitle = this.filterService.selectedStandardTitle;
    this.filterService.previousValues.selectedStepId = this.filterService.selectedStepId;
    this.filterService.previousValues.selectedFiscalQuarterId = this.filterService.selectedFiscalQuarterId;
    this.filterService.previousValues.selectedFiscalSemesterId = this.filterService.selectedFiscalSemesterId;
    this.filterService.previousValues.selectedManagerViewStepId = this.filterService.selectedManagerViewStepId;

    this.filterService.selectedFiscalYear = this.selectedFiscalYear;
    this.filterService.selectedManagerViewFiscalYear = this.selectedManagerViewFiscalYear;
    this.filterService.selectedStandardTitle = this.selectedStandardTitle;
    this.filterService.selectedStepId = this.selectedPartition;
    this.filterService.selectedFiscalQuarterId = this.selectedFiscalQuarterId;
    this.filterService.selectedFiscalSemesterId = this.selectedFiscalSemesterId;
    this.filterService.selectedManagerViewStepId = this.selectedManagerPartition;
    this.filterService.applicablePartitionsList = this.applicablePartitionsList;

    this.setFiscalQuarterId(this.filterService);

    if (this.filterService.isMic2Profile() && this.router.url !== '/mic2Reports') {
      this.router.navigateByUrl('/mic2Reports');
      return;
    }

    this.reInit = false;
    this.filterService.isManagerViewPartitionChanged = this.filterService.isManagerViewPartitionChanged = false;
    this.isFormDirty = false;
    this.apply.emit();
    const updateFilters: any = {
      fiscalYear: this.selectedFiscalYear.toString(),
      standardTitle: this.selectedStandardTitle,
      stepId: this.selectedPartition,
      fiscalQuarterId: this.selectedFiscalQuarterId,
      partition: null
    };
    this.filterState.dispatch(new UpdateFilters({ selectedFilters: updateFilters, route: '' }));
    // this.userSessionService.refresh(true); // Calling unneccessary update and setting invalid data intermittently
    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        alias: this.sharedContext.impersonatingAlias
      })
    );
    let currentUrl = this.router.url;
    this.spinner.show();

    if (currentUrl.includes('?force-refresh=true')) {
      currentUrl = currentUrl.replace('?force-refresh=true', '');
    }

    this.router
      .navigateByUrl(currentUrl + '?force-refresh=true', { skipLocationChange: true })
      .then(x => {
        this.router.navigate([currentUrl]);
      })
      .then(() => {
        this.spinner.hide();
      });
  }

  setFiscalQuarterId(fService: any) {
    if (!this.applicablePartitionsList) {
      return;
    }
    const partition = this.applicablePartitionsList.find(p => p.StepId === fService.selectedStepId);
    if (partition) {
      fService.selectedFiscalQuarterId = partition.FiscalQuarterId;
      fService.selectedFiscalSemesterId = partition.FiscalSemesterId;
    }
  }

  callInitialize() {
    if (this.sharedContext.isImpersonating) {
      this.userSessionService.initialize(true, true, true);
      let alias = '';
      let participationId = '';
      let personnelNumber = '';
      let fiscalYear = '';
      if (this.userProfileService.getUserAlias()) {
        alias = this.userProfileService.getUserAlias();
      }
      if (this.selectedStandardTitle) {
        participationId = this.selectedStandardTitle.ParticipationID;
      }
      if (this.selectedFiscalYear) {
        fiscalYear = this.selectedFiscalYear;
      }
      if (this.sharedContext.personnelNumber) {
        personnelNumber = `${this.sharedContext.personnelNumber}`;
      }

      const authorize$ = from(this.authorizationService.authorizeImpersonation(alias, alias, participationId, personnelNumber, fiscalYear));
      authorize$.subscribe((result: any) => {
        if (result.IsAuthorized) {
          this.impersonationService.setFCAQuotaApprovalPendingFlag(result.ErrorCause);
          this.userSessionService.initialize(true, true, true);
        } else {
          // NavigationFactory.clear();
          this.router.navigateByUrl('/error/error', { queryParams: { showFilter: true, qaFcaApprovalPending: this.userSessionService.error.qaFcaApprovalPending } });
          console.error('Authorization failed for impersonated profile on changing filter.');
        }
      });
      // CommonFactory.showSpinner(authorizePromise, 'Loading ...');
    } else {
      this.userSessionService.initialize(false, true, undefined, undefined, undefined, true);
    }
  }

  sort(value1, value2) {
    if (value1 > value2) {
      return 1;
    } else if (value2 > value1) {
      return -1;
    }
    return 0;
  }
  sortByDate(date1, date2) {
    const startDate1 = new Date(date1);
    const startDate2 = new Date(date2);
    return this.sort(startDate1, startDate2); // sort by date ascending
  }
}
