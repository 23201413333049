import { BaseModel } from 'src/app/shared/models/base.model';
import { Expose, Type, Exclude } from 'class-transformer';
import { TargetProration } from './target-proration.model';

export class PayComponent extends BaseModel {
  @Expose({ name: 'Name' })
  name: string;
  @Expose({ name: 'Percentage' })
  percentage: number;
  @Expose({ name: 'Target' })
  target: number;
  @Expose({ name: 'CurrencyCode' })
  currencyCode: string;
  @Expose({ name: 'SalesIncentiveAmountTarget' })
  salesIncentiveAmountTarget: number;
  @Type(() => TargetProration)
  @Expose({
    name: 'TargetProration'
  })
  targetProration: TargetProration;
  @Type(() => PayComponent)
  @Expose({
    name: 'SubComponents'
  })
  subComponents: PayComponent[];
  @Exclude() attainmentColor: string;

  @Exclude() getLabel(withName: boolean = true) {
    // TODO: Work In Progress
    const formatted = this.formatter.roundOffToWhole(this.percentage);
    let label = `${formatted}%`;
    if (withName) {
      label = `${this.name} ${label}`;
    }
    return label;
  }
}
