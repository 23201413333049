import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-metric-renderer',
  template: `
    <div style=" font-size: 14px;" *ngIf="params.value">{{ params.value.name }}</div>
    <app-attainment-weight [values]="params.value" *ngIf="params.value"></app-attainment-weight>
  `
})
export class MetricRendererComponent implements ICellRendererAngularComp {
  params: any;
  constructor() {}
  agInit(params): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
