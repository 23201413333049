<div id="payout-report-header-tile" *ngIf="filteredPaymentDetails && filteredPaymentDetails.length > 0">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div id="payout-report-header-title">{{ paymentReportTitle }} {{ filteredPaymentDetails[0].CodePeriod }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div id="payout-report-header-body">
      <div class="payout-header-section-lhsamount">
        {{ payoutReportService.formatCurrency(paymentAmount) }}
        <span class="payout-header-section-subtitles">{{ paymentCurrency }}</span>
        <mint-tooltip *ngIf="isCurrencyDifferent(filteredPaymentDetails[0])" [orientation]="'right'" [size]="'small'" [tipText]="'Total converted into your local currency'"></mint-tooltip>

      </div>
      <div>=</div>
      <div>
        <div class="payout-header-section-rhsamount">
          {{ payoutReportService.formatCurrency(reduceProperty('YTDEarnings')) }} <span
            class="payout-header-section-subtitles">{{ filteredPaymentDetails[0].CurrencyCode }}</span>
        </div>
        <div class="payout-header-section-subtitles">Core Buckets YTD Award</div>
      </div>
      <div>+</div>
      <div>
        <div class="payout-header-section-rhsamount">
          {{ payoutReportService.formatCurrency(reduceMaxOfProperty('TotalPayrollAdjustments')) }} <span
            class="payout-header-section-subtitles">{{ filteredPaymentDetails[0].CurrencyCode }}</span>
        </div>
        <div class="payout-header-section-subtitles">Total Adjustments</div>
      </div>
      <div>+</div>
      <div>
        <div class="payout-header-section-rhsamount">
          {{ payoutReportService.formatCurrency(reduceProperty('PaymentRecoveryAmount')) }} <span
            class="payout-header-section-subtitles">{{ filteredPaymentDetails[0].CurrencyCode }}</span>
        </div>
        <div class="payout-header-section-subtitles">Recovery Amount</div>
      </div>
      <div>-</div>
      <div>
        <div class="payout-header-section-rhsamount">
          {{ payoutReportService.formatCurrency(reduceProperty('PreviousPaymentAmount')) }} <span
            class="payout-header-section-subtitles">{{ filteredPaymentDetails[0].CurrencyCode }}</span>
        </div>
        <div class="payout-header-section-subtitles">Previous Payment</div>
      </div>
    </div>
  </mat-expansion-panel>
</div>