import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TBonusDetailView, TBonusSummary, BonusData } from '@mint-libs/context';

@Component({
  selector: 'mint-bonus-detail-view',
  templateUrl: './bonus-detail-view.component.html',
  styleUrls: ['./bonus-detail-view.component.scss']
})
export class BonusDetailViewComponent implements OnInit, OnChanges {
  @Input()
  bonusDetails: TBonusDetailView;
  @Input()
  bonusSummary: TBonusSummary;
  @Input()
  bonusData: BonusData;


  sections: {
    items: any[],
    header: string,
    headerNotes: string,
    isBingo: boolean
  }[] = [];

  chartGridViewTab = [
    {
      title: ` <span>
      <svg class="view__icon"  width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 9V5.53845H2.2V9H0ZM2.93333 9V2.76923H5.13333V9H2.93333ZM8.8 2.76923H11V9H8.8V2.76923ZM5.86667
      9V0H8.06667V9H5.86667Z"
          fill="#828282"
        />
      </svg>
    </span>
    Chart view`,
    id: 'chart'
    },
    {
      title: `<span>
      <svg class="view__icon"  width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H11V3.46153H0V0ZM0 9V5.53845H11V9H0Z" fill="#828282" />
      </svg>
    </span>
    Grid view`,
      id: 'grid'
    }
  ];
  activeTabId: string = this.chartGridViewTab[0].id;

  constructor() { }

  ngOnInit(): void {
  }

  onTabChange(event) {
    this.activeTabId = event.nextId;
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.sections = [];
    if (this.bonusDetails.weightedSection) {
      this.sections.push({
        items: this.bonusDetails.weightedSection.sectionItems,
        header: this.bonusDetails.weightedSection.header.isHeaderEnabled ? `Eligibility for  ${this.bonusDetails.weightedSection.header.isHeaderRevmapName ? this.bonusData.revmapName : this.bonusData.bonusProgramName }` : '',
        headerNotes: this.bonusDetails.weightedSection.header.headerNotes,
        isBingo: false,
      })
    }
    if (this.bonusDetails.bonusSection) {
      this.sections.push({
        items: this.bonusDetails.bonusSection.sectionItems,
        header: this.bonusDetails.bonusSection.header.isHeaderEnabled ? `${ this.bonusData.bonusProgramName } ${ this.bonusDetails.bonusSection.header.isHeaderRevmapName ? this.bonusData.revmapName: '' } Performance` : '',
        headerNotes: this.bonusDetails.bonusSection.header.headerNotes,
        isBingo: false,
      })
    }
    if (this.bonusDetails.excellenceSection) {
      this.sections.push({
        items: this.bonusDetails.excellenceSection.sectionItems,
        header: this.bonusDetails.excellenceSection.header.isHeaderEnabled ? `Excellence Bonus for ${ this.bonusData.bonusProgramName }` : '',
        headerNotes: this.bonusDetails.excellenceSection.header.headerNotes,
        isBingo: true,
      })
    }
  }
}
