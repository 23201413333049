import { TBonusAttribute, TBonusData } from '../types/bonus-data.type';

export class BonusData {
  bonusAttributes: TBonusAttribute[];
  bonusProgramName: string;
  createdDate: string;
  currencyCode: string;
  earningsEndDate: string;
  earningsStartDate: string;
  fiscalYearId: number;
  incentiveType: "RBI" | "UBI" | "PPI";
  participationId: number;
  periodId: string;
  programEndDate: string;
  programId: number;
  programStartDate: string;
  revmapName: string;
  runID?: number;
  totalBonusAmount: number;
  id: string;

  constructor(bonusData: TBonusData) {
    this.bonusAttributes = bonusData.BonusAttributes;
    this.bonusProgramName = bonusData.BonusProgramName;
    this.createdDate = bonusData.CreatedDate;
    this.currencyCode = bonusData.CurrencyCode;
    this.earningsEndDate = bonusData.EarningsEndDate;
    this.earningsStartDate = bonusData.EarningsStartDate;
    this.fiscalYearId = bonusData.FiscalYearId;
    this.incentiveType = bonusData.IncentiveType;
    this.participationId = bonusData.ParticipationId;
    this.periodId = bonusData.PeriodId;
    this.programEndDate = bonusData.ProgramEndDate;
    this.programId = bonusData.ProgramId;
    this.programStartDate = bonusData.ProgramStartDate;
    this.revmapName = bonusData.RevmapName;
    this.runID = bonusData.RunID;
    this.totalBonusAmount = bonusData.TotalBonusAmount;
    this.id = bonusData.id;
  }

  toBonusDataType(): TBonusData {
    return {
      BonusAttributes: this.bonusAttributes,
      BonusProgramName: this.bonusProgramName,
      CreatedDate: this.createdDate,
      CurrencyCode: this.currencyCode,
      EarningsEndDate: this.earningsEndDate,
      EarningsStartDate: this.earningsStartDate,
      FiscalYearId: this.fiscalYearId,
      IncentiveType: this.incentiveType,
      ParticipationId: this.participationId,
      PeriodId: this.periodId,
      ProgramStartDate: this.programStartDate,
      ProgramEndDate: this.programEndDate,
      ProgramId: this.programId,
      RevmapName: this.revmapName,
      RunID: this.runID,
      TotalBonusAmount: this.totalBonusAmount,
      id: this.id,
    };
  }
}
