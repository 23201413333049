import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import {  distinct, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';

@Component({
  selector: 'multicheck-filter',
  templateUrl: './multicheck-filter.component.html',
  styleUrls: ['./multicheck-filter.component.scss']
})
export class MulticheckFilterComponent implements  AfterViewInit{

  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: any;
  @Input() public data;
  @Input() public textField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Output() public valueChange = new EventEmitter<number[]>();

  @ViewChild('searchBox') searchBox: ElementRef;

  public currentData: any;
  public value: any[] = [];


  public ngAfterViewInit() {
    this.currentData = this.data;
    this.value = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);
    this.searchBox.nativeElement.focus();
  }

  public isItemSelected(item) {
    return this.value.some(x => x === item);
  }

  public onSelectionChange(item) {
    if (this.value.some(x => x === item)) {
      this.value = this.value.filter(x => x !== item);
    } else {
      this.value.push(item);
    }

    this.filterService.filter({
        filters: this.value.map(value => ({
            field: this.field,
            operator: 'eq',
            value
        })),
        logic: 'or'
    });
  }

  public onInput(e: any) {
    this.currentData = distinct([
      ...this.currentData.filter(dataItem => this.value.some(val => val === dataItem)),
      ...filterBy(this.data, {
        operator: 'contains',
        field: this.textField,
        value: e.target.value
      })],
                                this.textField
      );
  }
}
