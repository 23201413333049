import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MetricGridDataHelperService } from '../metric-grid/metric-grid-data-helper.service';
import { BucketEstimatorDetails } from '../models/bucket-estimator-detials.model';
import { Formatter } from '@mint-libs/common';
import { Payline } from '../models/payline.model';
import { LegendService } from '../legends/legend.service';
import { EstimationService } from './bucket-estimation.service';
import { OcvFloodgateService } from '../../ocv-feedback/ocv-floodgate.service';
import RbiEstimatorCampaign from '../../ocv-feedback/RbiEstimatorCampaign';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { UserProfileService } from '../../core/context/user-profile.service';

@Component({
  selector: 'mint-bucket-estimator',
  templateUrl: './bucket-estimator.component.html',
  styleUrls: ['./bucket-estimator.component.scss']
})
export class BucketEstimatorComponent implements OnInit {
  @Input() weightedAttainmentKPI;
  @Input() projectedPaymentKPI;
  bucketLevelData: any;
  isLoaded = false;
  EstimatorDataInput: any;
  estimatedEarnings: any;
  estimatingEarningsFlag = false;
  currentProjectedTotalEarnings = 0;
  newEstimatedTotalEarnings: number;
  currentWeightedAttainment: any;
  newEstimatedWeightedAttainment: any;
  startOverFlag = false;
  CurrentQuarter = '';
  EarningsCurrency: any;
  isEstimatorRun = false;
  isDMYEligible = false;
  determinerBucketLevelData: any;
  constructor(
    private metricGridDataHelperService: MetricGridDataHelperService,
    public formatter: Formatter,
    private legendService: LegendService,
    private estimatorService: EstimationService,
    private floodgateService: OcvFloodgateService,
    private userProfileService1: UserProfileService
  ) {}

  ngOnInit() {
    this.isDMYEligible = this.userProfileService1?.getIsDMYEligible();
    this.metricGridDataHelperService.getMetricGridData().subscribe(([targetSummaryResult, payLinesResult]) => {
      if (targetSummaryResult && payLinesResult) {
        this.bindBucketEstimationData(targetSummaryResult, payLinesResult);
      }
    });
  }
  bindBucketEstimationData(targetSummaryResult, payLinesResult) {
    this.currentWeightedAttainment = payLinesResult.TotalWeightedAttainment;
    this.newEstimatedWeightedAttainment = this.currentWeightedAttainment;
    this.bucketLevelData = new Array<BucketEstimatorDetails>();
    this.determinerBucketLevelData = new Array<BucketEstimatorDetails>();
    payLinesResult.Paylines.forEach(payline => {
      const a = new BucketEstimatorDetails();
      a.BucketName = payline.PcgName;
      a.pcgId = payline.PCGId;
      a.Weight = payline.Weightage;
      a.Attainment = payline.Attainment;
      a.PcgCurrency = payline.PCGCurrency;
      this.EarningsCurrency = payline.PCGCurrency;
      const paylineModel = new Payline().deserializeSingle(payline);
      a.AttainmentCSSClass = this.legendService.getCssClass(paylineModel);
      a.AttainmentCSS = this.legendService.getColor(paylineModel);
      a.ProjectedEarnings = '0';
      const filteredPCG = targetSummaryResult.PlanComponentGroups.filter(pcg => pcg.Name === payline.PcgName);
      a.CurrentEarnings = 0;
      let tempITA = 0;
      filteredPCG.forEach(pcg => {
        a.CurrentEarnings += pcg.Metrics.Earnings;
        tempITA += pcg.Metrics.IncentiveTargetAmount;
        a.IsDynamicBucket = pcg.IsDynamicBucket;
      });
      a.ITA = tempITA * payline.Weightage;
      // a.IsDynamicBucket = targetSummaryResult.PlanComponentGroups.filter(pcg => pcg.Name === payline.PcgName);
      let selectedBucket;
      const activeBucket = filteredPCG.filter(pcg => pcg.PeriodStatus === 'Active');
      if (activeBucket.length > 0) {
        selectedBucket = activeBucket[0];
      } else {
        const taBucket = filteredPCG.filter(pcg => pcg.PeriodStatus === 'Unpaid');
        selectedBucket = taBucket[0];
      }
      if (selectedBucket) {
        a.EarningsPercentage = (payline.PayoutPercentage / payline.SoftCap) * 100;
        a.SoftCap = payline.SoftCap;
        a.PayoutPercentage = payline.PayoutPercentage;
        a.UsedCap = payline.UsedCap;
        const activeITA = selectedBucket.Metrics.IncentiveTargetAmount * payline.Weightage;
        a.MaxCappedEarnigns = (activeITA * payline.SoftCap) / 100 + a.ITA - activeITA;
        a.Metrics.TargetRecurring = selectedBucket.Metrics.TargetRecurring;
        a.Metrics.Target = selectedBucket.Metrics.Target;
        a.Metrics.Actuals = selectedBucket.Metrics.Actuals;
        a.EarningsCurrency = selectedBucket.Metrics.CurrencyCode;
        a.Metrics.ActualsModifier = selectedBucket.Metrics.ActualsModifier;
        a.Metrics.TotalActuals = !a.IsDynamicBucket ? selectedBucket.Metrics.TotalActuals : (selectedBucket.Metrics.TotalActuals - (selectedBucket.Metrics.TargetRecurring + selectedBucket.Metrics.TargetRecurringModifier + selectedBucket.Metrics.TargetRecurringAdjustment));
        a.Metrics.TargetModifier = selectedBucket.Metrics.TargetModifier;
        a.Metrics.TargetAdjustment = selectedBucket.Metrics.TargetAdjustment;
        a.Metrics.TargetRecurringAdjustment = selectedBucket.Metrics.TargetRecurringAdjustment;
        a.Metrics.TargetRecurringModifier = selectedBucket.Metrics.TargetRecurringModifier;
        a.Metrics.ActualsAdjustment = selectedBucket.Metrics.ActualsAdjustment;
        a.Metrics.TotalTarget = selectedBucket.Metrics.TotalTarget;
        a.Metrics.TotalTargetRecurring = a.IsDynamicBucket ? 0 : (selectedBucket.Metrics.TargetRecurring + selectedBucket.Metrics.TargetRecurringModifier + selectedBucket.Metrics.TargetRecurringAdjustment);
        a.MeasuringUnits = selectedBucket.PlanComponentCombinations[0].MeasuringUnits;
      }
      // Only Push Non-OCP Metrics
      if (Number(a.Weight) !== 0) {
        this.bucketLevelData.push(a);
        this.currentProjectedTotalEarnings += a.CurrentEarnings;
      }

      if (Number(a.Weight) === 0 && selectedBucket.determines && selectedBucket.determines.length > 0) {
        this.determinerBucketLevelData.push(a);
      }
    });
    this.EstimatorDataInput = this.bucketLevelData;
    if (this.projectedPaymentKPI) {
      // this.currentProjectedTotalEarnings = this.projectedPaymentKPI.amountUnformatted;
    }
    this.isLoaded = true;
    this.newEstimatedTotalEarnings = this.currentProjectedTotalEarnings;
  }

  estimate() {
    const formatedEstimatorInputList = this.formatEstimatorData();
    this.estimatingEarningsFlag = true;
    this.estimatorService.getBucketEstimation(formatedEstimatorInputList).subscribe(results => {
      this.isEstimatorRun = true;
      this.estimatedEarnings = results;
      this.CalculateNewEarningsAndAttainment(results);
      this.estimatingEarningsFlag = false;
    });

    this.floodgateService.ActivateSurvey(RbiEstimatorCampaign);
  }

  CalculateNewEarningsAndAttainment(result) {
    if (result) {
      let tempEarnings = 0;
      let tempAttainment = 0;

      result.Result.forEach(element => {
        tempEarnings += element.PCGEarnings;
      });
      this.newEstimatedTotalEarnings = tempEarnings;
      this.bucketLevelData.forEach(pcg => {
        if (pcg.ProjectedAttainment) {
          tempAttainment += pcg.ProjectedAttainment * pcg.Weight;
        } else {
          tempAttainment += pcg.Attainment * pcg.Weight;
        }
      });
      this.newEstimatedWeightedAttainment = tempAttainment;
    }
  }
  formatEstimatorData() {
    let formatedEstimatorInput: any;
    const formatedEstimatorInputList = new Array<Object>();
    let estimatorData = [...this.EstimatorDataInput];
    estimatorData = estimatorData.concat(this.determinerBucketLevelData);
    estimatorData.forEach(element => {
      const Metric = {
        Target: element.Metrics.Target,
        Actuals: !element.IsDynamicBucket ? element.Metrics.Actuals : element.Metrics.Actuals - (element.Metrics.TargetRecurring + element.Metrics.TargetRecurringModifier + element.Metrics.TargetRecurringAdjustment),
        ProjectedActuals: element.Metrics.ProjectedActual,
        TargetModifier: element.Metrics.TargetModifier,
        TargetAdjustment: element.Metrics.TargetAdjustment,
        TargetRecurringAdjustment: !element.IsDynamicBucket ? element.Metrics.TargetRecurringAdjustment : 0,
        TargetRecurringModifier: !element.IsDynamicBucket ? element.Metrics.TargetRecurringModifier : 0,
        ActualsModifier: element.Metrics.ActualsModifier,
        ActualsAdjustment: element.Metrics.ActualsAdjustment,
        TargetRecurring: !element.IsDynamicBucket ? element.Metrics.TargetRecurring : 0
      };
      formatedEstimatorInput = {
        Id: element.pcgId,
        Metrics: Metric
      };
      formatedEstimatorInputList.push(formatedEstimatorInput);
    });
    const finalList = { EstimatorPCGDetails: formatedEstimatorInputList };
    return finalList;
  }
  startOver() {
    this.startOverFlag = !this.startOverFlag;
    this.EstimatorDataInput.forEach(element => {
      element.Metrics.ProjectedActual = 0;
    });
    this.newEstimatedTotalEarnings = this.currentProjectedTotalEarnings;
    this.newEstimatedWeightedAttainment = this.currentWeightedAttainment;
    this.isEstimatorRun = false;
  }

  updateEstimatorData(bucketData: any) {
    this.EstimatorDataInput.forEach(element => {
      if (bucketData.pcgId === element.pcgId) {
        element.Metrics.ProjectedActual = bucketData.Metrics.ProjectedActual;
      }
    });
  }
}
