<div id="filter_1" class="mic-filter">
  <div id="filter_2" [ngClass]="{'mic-mb-div': config.showFilter}">
      <!-- Toggle -->
      <button attr.aria-live="'polite'" attr.aria-label="{{ config.showFilter ? 'press spacebar to close filters' : 'press spacebar to open filters' }}" title="Filter" (click)="toggleFilter()" id="toggle-filter" name="toggle-filter" attr.aria-expanded="{{ config.showFilter }}">
          <span id="filter_3" class="icon icon-filter"></span>
      </button>
      <form id="filter_4" class="form-inline m-l-10" *ngIf="config.showFilter" novalidate="">
          <!-- Fiscal Year -->
          <div id="filter_5" class="form-group filter-control" *ngIf="config.isFiscalYear">
              <label id="filter_6" for="fiscal-year">Fiscal Year</label>
              <select name="fiscal-year" id="fiscal-year" class="form-control select-uwp" title="Select Fiscal Year" [(ngModel)]="selectedFiscalYear" (change)="onFiscalYearChange(true)">
                <option *ngFor="let fiscalYear of fiscalYearList" [ngValue]="fiscalYear.value" [selected]="fiscalYear.value == selectedFiscalYear">{{fiscalYear.text}}</option>
              </select>
          </div>
          <!-- Manager Fiscal Year -->
          <div id="filter_51" class="form-group filter-control" *ngIf="config.isManagerView">
              <label id="filter_516" for="fiscal-year">Fiscal Year</label>
              <select name="fiscal-year" id="fiscal-year" class="form-control select-uwp" title="Select Fiscal Year" [(ngModel)]="selectedManagerViewFiscalYear" (change)="onManagerViewFiscalYearChange()">
                <option *ngFor="let fiscalYear of managerFiscalYearList" [ngValue]="fiscalYear.value" [selected]="fiscalYear.value == selectedManagerViewFiscalYear">{{fiscalYear.text}}</option>
              </select>
          </div>
          <!-- Standard Title -->
          <div id="filter_7" class="form-group filter-control" *ngIf="config.isTitle">
              <label id="filter_8" for="title">{{ selectedFiscalYear  > 2021 ? "Role Summary" : "Role"}}</label>
              <select name="title" id="title" class="form-control select-uwp" title="Select Title" [(ngModel)]="selectedStandardTitle" (change)="onStandardTitleChange()" style="width: 260px;">
                <option *ngFor="let standardTitle of standardTitleList" [ngValue]="standardTitle.value" [selected]="standardTitle.value == selectedStandardTitle">{{standardTitle.text}}</option>
              </select>
          </div>
          <!-- Manager Standard Title -->
          <div id="filter_71" class="form-group filter-control" *ngIf="config.isManagerView">
              <label id="filter_8" for="title">{{ selectedFiscalYear  > 2021 ? "Role Summary" : "Role"}}</label>
              <select name="titleManager" id="titleManager" class="form-control select-uwp" title="Select Title" style="width: 260px;">
                  <option value="Participant Manager" selected="">Participant Manager</option>
              </select>
          </div>
          <!-- Partition -->
          <div id="filter_9" class="form-group filter-control" *ngIf="config.isPartition">
              <label id="filter_10" for="quarterSelect">Time Period</label>
              <select name="quarter" id="quarterSelect" class="form-control select-uwp" title="Select Partition" [(ngModel)]="selectedPartition" (change)="onPartitionChange()" style="width: 120px;">
                <option *ngFor="let partition of applicablePartitionsList" [ngValue]="partition.StepId" [selected]="partition.StepId == selectedPartition">{{partition.text}}</option>
              </select>
          </div>
          <!-- Manager Partition -->
          <div id="filter_20" class="form-group filter-control" *ngIf="config.isManagerView">
              <label id="filter_21" for="quarterSelectForManagerView">Time Period</label>
              <select name="quarter" id="quarterSelectForManagerView" class="form-control select-uwp" title="Select Partition" [(ngModel)]="selectedManagerPartition"  (change)="onPartitionChange()"  style="width: 120px;">
                <option *ngFor="let partition of managerDashboardPartitions" [ngValue]="partition.StepId" [selected]="partition.StepId == selectedManagerPartition">{{partition.text}}</option>
              </select>
          </div>
          <!-- Apply -->
          <div id="filter_11" class="form-group">
              <button id="filter_12" type="button" name="Apply" class="btn btn-dark" [disabled]="!isFormDirty" (click)="update()">
                  Apply
              </button>
          </div>
      </form>
  </div>
  <div id="filter_19" class="clearfix"></div>
</div>
