<div class="app__container">
  <!-- <app-progress-bar id="cagr_app_bar" class="sticky__progress__bar"></app-progress-bar> -->
  <div class="app__nav app__nav-cagr">
      <mint-tabs [tabs]="tabs" (tabChange)="onTabChange($event)" [activeId]="activeTabid" [tracker]="'Cagr_TabView'" [source]="'Cagr'">
      </mint-tabs>
  </div>
  <mint-content-loader-icon-text *ngIf="!isDataLoaded"></mint-content-loader-icon-text>
  <mint-content-loader-bar-chart-horizontal *ngIf="!isDataLoaded"></mint-content-loader-bar-chart-horizontal>
  <div *ngFor="let cagrParticipation of cagrParticipationDetails">
    <mint-cagr-seller *ngIf="isDataLoaded && activeTabid == cagrParticipation.cagrParticipationId" [isDemoMode3]="isDemoMode3" [cagrParticipation]="cagrParticipation"></mint-cagr-seller>
  </div>
</div>
