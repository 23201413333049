import { Injectable, Inject } from '@angular/core';
import DashboardCampaign from './DashboardCampaign';
import { UserProfileService } from '../core/context/user-profile.service';
import { Router } from '@angular/router';
import 'node_modules/@ms-ofb/officebrowserfeedback/scripts/officebrowserfeedback_floodgate.min.js';
import { OcvFeedbackService } from './ocv-feedback.service';
import RbiEstimatorCampaign from './RbiEstimatorCampaign';
import UBIEstimatorCampaign from './UBIEstimatorCampaign';
import UBIManagerDashboardCampaign from './UBIManagerDashboardCampaign';
import MQCHCampaign from './MQCHCampaign';
import { TelemetryContext } from '@mint-libs/context';
import { NavigationService } from '../core/navigation/navigation.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Injectable()
export class OcvFloodgateService extends OcvFeedbackService {
  constructor(
    router: Router,
    public userProfileService: UserProfileService,
    public telemetryContext: TelemetryContext,
    public navigationService: NavigationService,
    public sharedContextState: Store<SharedContext>
  ) {
    super(router, userProfileService, telemetryContext, sharedContextState, navigationService);

    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.initOcv();
  }

  // Init OCV.
  public async initOcv(): Promise<void> {
    // Init options setup in base service.
    super.initOcv();

    // Setting up floodgate options.
    this.officeBrowserFeedbackObject.floodgate = this.officeBrowserFeedbackObject.floodgate || {};
    this.officeBrowserFeedbackObject.floodgate.initOptions = {
      autoDismiss: 26, // 7*x seconds i.e 180 seconds
      campaignDefinitions: []
    };

    await this.officeBrowserFeedbackObject.floodgate.initialize();
    await this.officeBrowserFeedbackObject.floodgate.start();

    (<any>window).onfocus = () => {
      if (this.officeBrowserFeedbackObject.floodgate) {
        this.officeBrowserFeedbackObject.floodgate.start();
      }
    };

    (<any>window).onblur = () => {
      if (this.officeBrowserFeedbackObject.floodgate) {
        this.officeBrowserFeedbackObject.floodgate.stop();
      }
    };

    (<any>window).onunload = () => {
      if (this.officeBrowserFeedbackObject.floodgate) {
        this.officeBrowserFeedbackObject.floodgate.stop();
      }
    };
  }

  public async InitializeSurveys() {
    this.ClearState();

    this.CheckAndAddCampaign(DashboardCampaign);
    this.CheckAndAddCampaign(RbiEstimatorCampaign);
    this.CheckAndAddCampaign(UBIEstimatorCampaign);
    this.CheckAndAddCampaign(UBIManagerDashboardCampaign);
    this.CheckAndAddCampaign(MQCHCampaign);

    await this.officeBrowserFeedbackObject.floodgate.initialize();
    await this.officeBrowserFeedbackObject.floodgate.start();
  }

  CheckAndAddCampaign(campaign) {
    const campaignDefinitions = this.officeBrowserFeedbackObject.floodgate.initOptions.campaignDefinitions;
    if (!campaignDefinitions.find(t => t.CampaignId === campaign.CampaignId)) {
      campaignDefinitions.push(campaign);
    }
    this.officeBrowserFeedbackObject.floodgate.initOptions.campaignDefinitions = campaignDefinitions;
  }

  public async ActivateSurvey(campaign) {
    setTimeout(() => {
      if (this.officeBrowserFeedbackObject.floodgate.initOptions.campaignDefinitions.find(t => t.CampaignId === campaign.CampaignId)) {
        this.officeBrowserFeedbackObject.floodgate
          .getEngine()
          .getActivityListener()
          .logActivity(campaign.Activity);
      }
    }, 5000);

    this.autoCloseSurvey(180000); // 180 seconds.
  }

  public autoCloseSurvey(timeoutInMilliSeconds: number) {
    setTimeout(() => {
      this.ClearState();
    }, timeoutInMilliSeconds);
  }

  public async ClearState() {
    // Closes any existing survey toast.
    if (document.getElementById('obf-ToastCancel')) {
      document.getElementById('obf-ToastCancel').click();
    }

    // Clearing floodgate states.
    if (window.localStorage) {
      window.localStorage.removeItem('obf-GovernedChannelStates');
      // window.localStorage.removeItem('obf-CampaignStates');
      // window.localStorage.removeItem('obf-SurveyActivationStats');
      // window.localStorage.removeItem('obf-SurveyEventActivityStats');
    }
  }
}
