import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../core/network/data.service';
import { CustomHttpParams } from '../core/network/custom-http-params';

@Injectable()
export class SplashService {
  constructor(private endpoint: EndpointService, private dataService: DataService) {}

  GetSplash() {
    let params = CustomHttpParams.getInstance();
    params = params.append('camelcase', 'true');
    const a = this.dataService.getCamelCase<any[]>(this.endpoint.GetUserSplash.uri, params);
    return a;
  }
}
