<nav id="sidebar_pane" [ngClass]="{ active: getSidebarStatus() }" class="sidebar__menu" role="navigation">
  <ul id="sidebar_2" role="menu" class="menu__top">
    <li class="list__item" id="sidebar_3" role="none" *ngIf="isDashboardVisible"
      [class.active]="sidebarService.isDashboardActive">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" role="menuitem" id="sidebar-dashboard" title="Dashboard"
          (click)="navigateTo('dashboard', null)"
          (keyup.enter)="sharedContext.toggleView = 0; navigateTo('dashboard', null)" analytics-tracker
          [tracker]="'Sidebar_Dashboard_Icon'" [type]="'Click'" [element]="'Icon'" [source]="'SidebarV2'">
          <div id="sidebar_4" class="list__item__icon"><em id="sidebar_5" class="icon-ms icon-dashboard"></em></div>
          <div id="sidebar_6" class="list__item__text" *ngIf="getSidebarStatus()">Dashboard</div>
        </div>
      </div>
    </li>
    <li class="list__item" id="sidebar_7" role="none" *ngIf="isReportsVisible"
      [class.active]="sidebarService.isNgReportsActive">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" role="menuitem" id="sidebar-reports" title="Reports"
          aria-label="Reports" (click)="navigateToNgSubView('reports', ngReportSubmenuList[0])"
          (keyup.enter)="navigateToNgSubView('reports', ngReportSubmenuList[0])" analytics-tracker
          [tracker]="'Sidebar_Reports_Icon'" [type]="'Click'" [element]="'Icon'" [source]="'SidebarV2'">
          <div id="sidebar_8" class="list__item__icon"><em id="sidebar_9" class="icon icon-file-multiple"></em></div>
          <div id="sidebar_10" class="list__item__text" *ngIf="getSidebarStatus()">Reports</div>
        </div>
        <div id="reports_sub_container" class="list__item__icon list__item__icon-expand"
          (click)="expandReportsSubMenu()" (keyup.enter)="expandReportsSubMenu()" analytics-tracker
          [tracker]="'Sidebar_Reports_Submenu_Click'" [type]="'Click'" [element]="'Icon'" [source]="'SidebarV2'"
          [value]="submenuObj['report'].active" *ngIf="ngReportSubmenuList.length > 0 && getSidebarStatus()"
          aria-label="report submenu" tabindex="0">
          <div class="icon icon-chevron-right" id="reports_sub_icon"
            [ngClass]="{ active: isReportsSubMenuActive || submenuObj['ngReports'].active }">
          </div>
        </div>
      </div>
      <ul class="sub__menu" role="menu" [ngStyle]="{ height: (submenuObj['report']['menuList']).length * 40 + 'px' }"
        *ngIf="getSidebarStatus() && (isReportsSubMenuActive || submenuObj['report'].active)">
        <li *ngFor="let reportSubmenu of ngReportSubmenuList" (click)="navigateToNgSubView('reports', reportSubmenu)"
          (keyup.enter)="navigateToNgSubView('reports', reportSubmenu)" analytics-tracker
          [tracker]="'Sidebar_Reports_SubMenu_' + reportSubmenu.name" [type]="'Click'" [element]="'Icon'"
          [source]="'SidebarV2'" [ngClass]="{ active: reportSubmenu.active }" role="menuitem" tabindex="0">
          <div class="list__item__text">{{ reportSubmenu.name }}</div>
        </li>
      </ul>
    </li>
    <!-- <li class="list__item" id="sidebar_14" role="none" *ngIf="isEstimatorVisible" [class.active]="sidebarService.isEstimatorActive">
      <div class="list__item__container">
        <div
          class="list__item__btn"
          tabindex="0"
          role="menuitem"
          id="sidebar-estimator"
          aria-label="Estimator"
          title="Estimator"
          analytics-tracker
          [tracker]="'Sidebar_Estimator_Icon'"
          [type]="'Click'"
          [element]="'Icon'"
          [source]="'SidebarV2'"
          (click)="navigateTo('home.estimator', 'sidebar-estimator', null, false)"
          (keyup.enter)="navigateTo('home.estimator', 'sidebar-estimator', null, false)"
        >
          <div id="sidebar_15" class="list__item__icon"><em id="sidebar_16" class="icon icon-calculator"></em></div>
          <div id="sidebar_17" class="list__item__text" *ngIf="getSidebarStatus()">Estimator</div>
        </div>
      </div>
    </li> -->
    <li class="list__item" id="sidebar_14" role="none" *ngIf="isMQCHVisible"
      [class.active]="sidebarService.isMQCHActive">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" role="menuitem" id="sidebar-mqch" aria-label="mqch"
          title="My Change History" analytics-tracker [tracker]="'Sidebar_MQCH_Icon'" [type]="'Click'"
          [element]="'Icon'" [source]="'SidebarV2'" (click)="navigateTo('mqch', null)"
          (keyup.enter)="navigateTo('mqch', null)">
          <div id="sidebar_15" class="list__item__icon"><em id="sidebar_16" class="icon icon-history"></em></div>
          <div id="sidebar_17" class="list__item__text" *ngIf="getSidebarStatus()">My Change History</div>
        </div>
      </div>
    </li>
    <li class="list__item" id="sidebar_18" role="none" *ngIf="isMyCompensationPlanVisible"
      [class.active]="sidebarService.isMyCompensationPlanActive">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" role="menuitem" id="sidebar-compensation" title="My Compensation Plan"
          (click)="navigateTo('compensationPlan', null)" (keyup.enter)="navigateTo('compensationPlan', null)"
          analytics-tracker [tracker]="'Sidebar_CompensationPlan_Icon'" [type]="'Click'" [element]="'Icon'"
          [source]="'SidebarV2'">
          <div id="sidebar_19" class="list__item__icon"><em id="sidebar_20" class="icon icon-account-box"></em></div>
          <div id="sidebar_21" class="list__item__text" *ngIf="getSidebarStatus()">My Compensation Plan</div>
        </div>
      </div>
    </li>
    <li class="list__item" id="sidebar_22" role="none" *ngIf="isQAVisible"
      [class.active]="sidebarService.isComplianceActive">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" role="menuitem" id="sidebar-compliance" title="Compliance"
          (click)="navigateToComplianceSubViews(compSubmenuList[0])"
          (keyup.enter)="navigateToComplianceSubViews(compSubmenuList[0])" analytics-tracker
          [tracker]="'Sidebar_QuotaAcknowledge_Icon'" [type]="'Click'" [element]="'Icon'" [source]="'SidebarV2'">
          <div id="sidebar_23" class="list__item__icon"><em id="sidebar_24" class="icon-ms icon-quota"></em></div>
          <div id="sidebar_25" class="list__item__text" *ngIf="getSidebarStatus()">Compliance</div>
        </div>
        <div id="comp_sub_container" class="list__item__icon list__item__icon-expand"
          (click)="expandComplianceSubMenu()" (keyup.enter)="expandComplianceSubMenu()" analytics-tracker
          [tracker]="'Sidebar_QuotaAcknowledge_Submenu_Click'" [type]="'Click'" [element]="'Icon'"
          [source]="'SidebarV2'" [value]="submenuObj['compliance'].active"
          *ngIf="compSubmenuList.length > 0 && getSidebarStatus()" tabindex="0" aria-label="compliance submenu">
          <div class="icon icon-chevron-right" id="comp_sub_icon"
            [ngClass]="{ active: isComplianceSubMenuActive || submenuObj['compliance'].active }">
          </div>
        </div>
      </div>
      <ul class="sub__menu" role="menu" [ngStyle]="{ height: submenuObj['compliance']['menuList'].length * 40 + 'px' }"
        *ngIf="getSidebarStatus() && (isComplianceSubMenuActive || submenuObj['compliance'].active)">
        <li *ngFor="let compSubmenu of compSubmenuList" (click)="navigateToComplianceSubViews(compSubmenu)"
          (keyup.enter)="navigateToComplianceSubViews(compSubmenu)" analytics-tracker
          [tracker]="'Sidebar_Complaince_SubMenu_' + compSubmenu.name" [type]="'Click'" [element]="'Icon'"
          [source]="'SidebarV2'" [ngClass]="{ active: compSubmenu.active }" role="menuitem" tabindex="0">
          <div class="list__item__text">{{ compSubmenu.name }}</div>
        </li>
      </ul>
    </li>
    <li class="list__item" id="sidebar_AI_30" role="none" *ngIf="isAIvisible"
      [class.active]="sidebarService.isAIActive">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" role="menuitem" id="sidebar_AccelerateAzure"
          title="Accelerate Azure Program" analytics-tracker [tracker]="'Sidebar_AccelerateAzure_Icon'" [type]="'Click'"
          [element]="'Icon'" [source]="'SidebarV2'" (click)="navigateTo('accelerateAzure', null)"
          (keyup.enter)="navigateTo('accelerateAzure', null)">
          <div id="sidebar_AI_31" class="list__item__icon"><em id="sidebar_AI_32" class="icon icon-unlock"></em></div>
          <div id="sidebar_AI_33" class="list__item__text" *ngIf="getSidebarStatus()">Accelerate Azure Program</div>
        </div>
      </div>
    </li>
    <li class="list__item" id="sidebar_AI_30" role="none" *ngIf="isCagrVisible"
      [class.active]="sidebarService.isCagrActive">
      <div class="list__item__container">
        <div class="list__item__btn" role="button" tabindex="0" role="menuitem" id="sidebar_AccelerateAzure"
          title="Three Year Microsoft Cloud Growth Bonus" analytics-tracker [tracker]="'Sidebar_AccelerateAzure_Icon'"
          [type]="'Click'" [element]="'Icon'" [source]="'SidebarV2'" (click)="navigateTo('cagr', null)"
          (keyup.enter)="navigateTo('cagr')">
          <div id="sidebar_AI_31" class="list__item__icon">
            <em id="sidebar_AI_32">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H48V48H0V0Z" fill="none" />
                <path
                  d="M26.5179 31.0128C26.5927 31.1731 26.7517 31.2672 26.9175 31.2672C26.9805 31.2672 27.0438 31.2538 27.104 31.2259L27.3584 31.0128V30.0807V25.9314L27.9924 26.5652L28.616 25.9414L26.9173 24.2422L25.2175 25.9414L25.8413 26.5652L26.476 25.9304V30.825H26.4774C26.4774 30.8884 26.4894 30.9523 26.5179 31.0128Z"
                  fill="white" />
                <path
                  d="M23.5509 31.4167C23.7944 31.4167 24.2608 31.4167 24.2608 31.4012V17.6882L24.8946 18.3225L25.5184 17.6992L23.8195 16L22.1203 17.6992L22.7435 18.3225L23.3788 17.6882V31.0738C22.7176 31.0738 21.5248 31.0738 21.5248 31.0738V23.7132L22.1591 24.3478L22.7828 23.7243L21.0835 22.0249L19.3843 23.7243L20.008 24.3478L20.6423 23.7132V29.9943C20.6423 31.8333 20.6343 31.4167 23.5509 31.4167Z"
                  fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M20.6626 19.5613C20.902 19.5183 21.0694 19.3017 21.0402 19.0657C21.011 18.8296 20.7959 18.6603 20.5532 18.6769L20.6626 19.5613Z"
                  fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M20.5551 18.6777C18.512 19.248 17.0167 21.1162 17.0167 23.3053C17.0167 23.5711 17.0168 23.8147 17.0614 24.0362C15.2738 24.4348 14 26.0296 14 27.8678C14 30.0606 15.7877 31.8324 18 31.8324H30.3128H30.4023C31.3184 31.8324 32.2122 31.4781 32.8827 30.8579C33.5977 30.1713 34 29.2411 34 28.2444C34 26.3397 32.5475 24.7892 30.6704 24.6121V24.5899C30.6704 22.5301 28.9721 20.8468 26.8938 20.8468C26.797 20.8468 26.7002 20.8497 26.6023 20.8565V21.7667C26.6912 21.7586 26.7804 21.7549 26.8715 21.7549C28.4581 21.7549 29.7542 23.0395 29.7542 24.6121C29.7542 24.7227 29.7318 24.8557 29.7095 24.9886C29.6872 25.1215 29.7318 25.2543 29.8212 25.3429C29.9106 25.4316 30.0223 25.498 30.1564 25.498H30.3128C31.8324 25.498 33.0837 26.7162 33.0837 28.2444C33.0837 28.9974 32.7932 29.684 32.257 30.2156C31.743 30.725 31.0503 30.9908 30.3128 30.9686H17.9777C16.257 30.9686 14.8715 29.5733 14.8715 27.89C14.8715 26.3175 16.0558 25.0107 17.6424 24.8335C17.7765 24.8114 17.9106 24.7449 17.9777 24.6342C18.0447 24.5235 18.067 24.3906 18.0223 24.2577C17.9329 23.9919 17.8883 23.6818 17.8883 23.3053C17.8883 21.544 19.0654 20.0597 20.6676 19.5627V18.9439H20.654L20.5551 18.6777Z"
                  fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M26.6051 20.8574C26.3572 20.871 26.1604 21.0694 26.1604 21.3123C26.1604 21.5552 26.3572 21.7536 26.6051 21.7672V20.8574Z"
                  fill="white" />
              </svg>
            </em>
          </div>
          <div id="sidebar_AI_33" class="list__item__text" *ngIf="getSidebarStatus()">Three Year Microsoft Cloud Growth
            Bonus</div>
        </div>
      </div>
    </li>
    <!-- Bonus Start -->
    <ng-container *ngIf="!isConsolidatedBonusViewActive">
      <li class="list__item" id="sidebar_AI_51" role="none" *ngFor="let program of bonusPrograms; let index = index"
        [class.active]="sidebarService.isCagrActive">
        <div class="list__item__container">
          <div class="list__item__btn" role="button" tabindex="0" role="menuitem"
            id="{{ 'sidebar_bonus_' + program.id }}" title="Priorities Bonus" analytics-tracker
            [tracker]="'Sidebar_AccelerateAzure_Icon'" [type]="'Click'" [element]="'Icon'" [source]="'SidebarV2'"
            (click)="navigateTo('bonus', program)" (keyup.enter)="navigateTo('bonus', program)">
            <div id="sidebar_AI_551" class="list__item__icon">
              <em id="sidebar_AI_52">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H48V48H0V0Z" fill="none" />
                  <path
                    d="M26.5179 31.0128C26.5927 31.1731 26.7517 31.2672 26.9175 31.2672C26.9805 31.2672 27.0438 31.2538 27.104 31.2259L27.3584 31.0128V30.0807V25.9314L27.9924 26.5652L28.616 25.9414L26.9173 24.2422L25.2175 25.9414L25.8413 26.5652L26.476 25.9304V30.825H26.4774C26.4774 30.8884 26.4894 30.9523 26.5179 31.0128Z"
                    fill="white" />
                  <path
                    d="M23.5509 31.4167C23.7944 31.4167 24.2608 31.4167 24.2608 31.4012V17.6882L24.8946 18.3225L25.5184 17.6992L23.8195 16L22.1203 17.6992L22.7435 18.3225L23.3788 17.6882V31.0738C22.7176 31.0738 21.5248 31.0738 21.5248 31.0738V23.7132L22.1591 24.3478L22.7828 23.7243L21.0835 22.0249L19.3843 23.7243L20.008 24.3478L20.6423 23.7132V29.9943C20.6423 31.8333 20.6343 31.4167 23.5509 31.4167Z"
                    fill="white" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M20.6626 19.5613C20.902 19.5183 21.0694 19.3017 21.0402 19.0657C21.011 18.8296 20.7959 18.6603 20.5532 18.6769L20.6626 19.5613Z"
                    fill="white" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M20.5551 18.6777C18.512 19.248 17.0167 21.1162 17.0167 23.3053C17.0167 23.5711 17.0168 23.8147 17.0614 24.0362C15.2738 24.4348 14 26.0296 14 27.8678C14 30.0606 15.7877 31.8324 18 31.8324H30.3128H30.4023C31.3184 31.8324 32.2122 31.4781 32.8827 30.8579C33.5977 30.1713 34 29.2411 34 28.2444C34 26.3397 32.5475 24.7892 30.6704 24.6121V24.5899C30.6704 22.5301 28.9721 20.8468 26.8938 20.8468C26.797 20.8468 26.7002 20.8497 26.6023 20.8565V21.7667C26.6912 21.7586 26.7804 21.7549 26.8715 21.7549C28.4581 21.7549 29.7542 23.0395 29.7542 24.6121C29.7542 24.7227 29.7318 24.8557 29.7095 24.9886C29.6872 25.1215 29.7318 25.2543 29.8212 25.3429C29.9106 25.4316 30.0223 25.498 30.1564 25.498H30.3128C31.8324 25.498 33.0837 26.7162 33.0837 28.2444C33.0837 28.9974 32.7932 29.684 32.257 30.2156C31.743 30.725 31.0503 30.9908 30.3128 30.9686H17.9777C16.257 30.9686 14.8715 29.5733 14.8715 27.89C14.8715 26.3175 16.0558 25.0107 17.6424 24.8335C17.7765 24.8114 17.9106 24.7449 17.9777 24.6342C18.0447 24.5235 18.067 24.3906 18.0223 24.2577C17.9329 23.9919 17.8883 23.6818 17.8883 23.3053C17.8883 21.544 19.0654 20.0597 20.6676 19.5627V18.9439H20.654L20.5551 18.6777Z"
                    fill="white" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M26.6051 20.8574C26.3572 20.871 26.1604 21.0694 26.1604 21.3123C26.1604 21.5552 26.3572 21.7536 26.6051 21.7672V20.8574Z"
                    fill="white" />
                </svg>
              </em>
            </div>
            <div id="sidebar_AI_53" class="list__item__text" *ngIf="getSidebarStatus()">Priorities Bonus ({{
              program.EarningsStartDateStr() }} - {{ program.EarningsEndDateStr() }})</div>
          </div>
        </div>
      </li>
    </ng-container>
    <!-- Bonus END -->
    <!-- Bonus V2 START -->
    <li class="list__item" id="sidebar_AI_532" role="none" [class.active]="sidebarService.isBonusActive"
      *ngIf="bonusConfig.length > 0 && isConsolidatedBonusViewActive">
      <div class="list__item__container">
        <div (click)="navigateToNgSubView('bonusv2')" (keyup.enter)="navigateToNgSubView('bonusv2')"
          class="list__item__btn" role="button" tabindex="0" role="menuitem" id="{{ 'sidebar_bonus_any' }}"
          title="Bonus" analytics-tracker [tracker]="'Sidebar_Bonus_Icon'" [type]="'Click'" [element]="'Icon'"
          [source]="'SidebarV2'">
          <div id="sidebar_AI_5512" class="list__item__icon">
            <em id="sidebar_343" class="icon-ms icon-badge"></em>
          </div>
          <div id="sidebar_AI_5322" class="list__item__text" *ngIf="getSidebarStatus()">My Bonus</div>
        </div>
        <div id="bonus_sub_container" class="list__item__icon list__item__icon-expand" (click)="expandBonusSubMenu()"
          (keyup.enter)="expandBonusSubMenu()" analytics-tracker [tracker]="'Sidebar_Bonus_Submenu_Click'"
          [type]="'Click'" [element]="'Icon'" [source]="'SidebarV2'" [value]="isBonusSubMenuActive"
          *ngIf="bonusConfig.length > 0 && getSidebarStatus()" tabindex="0" aria-label="bonus submenu">
          <div class="icon icon-chevron-right" id="bonus_sub_icon" [ngClass]="{ active: isBonusSubMenuActive }"></div>
        </div>
      </div>
      <ul class="sub__menu" role="menu" [ngStyle]="{ height: bonusConfig.length * 40 + 'px' }"
        *ngIf="getSidebarStatus() && isBonusSubMenuActive">
        <li *ngFor="let program of bonusConfig; let i = index" analytics-tracker
          [tracker]="'Sidebar_Bonus_SubMenu_' + program.bonusProgramDisplayName" [type]="'Click'" [element]="'Icon'"
          [source]="'SidebarV2'" [ngClass]="{ active: isBonusSelected(program) }" role="menuitem" tabindex="{{ i }}"
          (click)="navigateTo('bonusv2', program)" (keyup.enter)="navigateTo('bonusv2', program)">
          <div class="list__item__text">{{ program.bonusProgramDisplayName }}</div>
        </li>
        <!-- <li><div class="list__item__text">Attainment by Performance Metric (PCG)</div></li>
                  <li><div class="list__item__text">Payout Details</div></li>
                  <li><div class="list__item__text">Target and Actuals by Incentive Territory</div></li> -->
      </ul>
    </li>
    <!-- Bonus V2 END -->
    <li class="list__item" id="sidebar_18" role="none" *ngIf="isMic2ReportsVisible"
      [class.active]="sidebarService.isMic2ReportsActive">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" role="menuitem" id="sidebar-mic2reports" title="Reports"
          (click)="navigateTo('mic2Reports', null)" (keyup.enter)="navigateTo('mic2Reports', null)" analytics-tracker
          [tracker]="'Sidebar_Mic2Reports_Icon'" [type]="'Click'" [element]="'Icon'" [source]="'SidebarV2'">
          <div id="sidebar_19" class="list__item__icon"><em id="sidebar_20" class="icon icon-file-multiple"></em></div>
          <div id="sidebar_21" class="list__item__text" *ngIf="getSidebarStatus()">Reports</div>
        </div>
      </div>
    </li>

    <!-- <li class="list__item" id="sidebar_30" role="none" *ngIf="isUserVoiceVisible" >
      <div class="list__item__container">
        <div
          class="list__item__btn"
          tabindex="0"
          role="menuitem"
          id="sidebar_uservoice"
          ng-voice-enter=""
          aria-label="User Voice has pop up "
          title="User Voice"
          analytics-tracker
          [tracker]="'Sidebar_UserVoice_Icon'"
          [type]="'Click'"
          [element]="'Icon'"
          [source]="'SidebarV2'"
        >
          <div id="sidebar_31" class="list__item__icon"><em id="sidebar_32" class="icon-ms icon-vision"></em></div>
          <div id="sidebar_33" class="list__item__text" *ngIf="getSidebarStatus()">User Voice</div>
        </div>
      </div>
    </li> -->
  </ul>

  <ul class="menu__bottom" role="menu">
    <li class="list__item" id="sidebar_34" role="none">
      <div class="list__item__container">
        <div class="list__item__btn" id="sidebar_35" tabindex="0" role="menuitem"
          title="{{ userProfileInfo.name + ' ' + userProfileInfo.upn }}" style="cursor:pointer;">
          <div id="sidebar_36" class="list__item__icon">
            <ngx-avatar id="sidebar_37" name="{{ userProfileInfo.name }}" initialsSize="2" size="35" [round]="true"
              src="data:image/jpeg;base64,{{ userProfileInfo.thumbnailPhoto }}" textSizeRatio="2.5"></ngx-avatar>
          </div>
          <div id="sidebar_38" class="ellipsis list__item__text user__profile__name" *ngIf="getSidebarStatus()">
            <span id="sidebar_39">{{ userProfileInfo.name }}</span>
          </div>
        </div>
      </div>
    </li>
    <li class="list__item" id="sidebar_26" role="none" [class.active]="sidebarService.isHelpActive">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" role="menuitem" id="sidebar-help" title="Help" analytics-tracker
          [tracker]="'Sidebar_Help_Icon'" [type]="'Click'" [element]="'Icon'" [source]="'SidebarV2'"
          (click)="navigateToSubView('help', helpSubmenuList[0])"
          (keyup.enter)="navigateToSubView('help', helpSubmenuList[0])">
          <div id="sidebar_27" class="list__item__icon"><em id="sidebar_28" class="icon-ms icon-qahelp"></em></div>
          <div id="sidebar_29" class="list__item__text" *ngIf="getSidebarStatus()">Help</div>
        </div>
        <div id="help_sub_container" class="list__item__icon list__item__icon-expand" (click)="expandHelpMenu()"
          (keyup.enter)="expandSubMenu()" analytics-tracker [tracker]="'Sidebar_Help_Submenu_Click'" [type]="'Click'"
          [element]="'Icon'" [source]="'SidebarV2'" [value]="submenuObj['help'].active"
          *ngIf="helpSubmenuList.length > 0 && getSidebarStatus()" tabindex="0" aria-label="help submenu">
          <div class="icon icon-chevron-right" id="help_sub_icon" [ngClass]="{ active: isHelpSubMenuActive }">
          </div>
        </div>
      </div>
      <ul class="sub__menu" role="menu" [ngStyle]="{ height: submenuObj['help']['menuList'].length * 40 + 'px' }"
        *ngIf="getSidebarStatus() && (isHelpSubMenuActive || submenuObj['help'].active)">
        <li *ngFor="let helpSubmenu of helpSubmenuList" (click)="navigateToSubView('help', helpSubmenu)"
          (keyup.enter)="navigateToSubView('help', helpSubmenu)" analytics-tracker
          [tracker]="'Sidebar_Help_SubMenu_' + helpSubmenu.name" [type]="'Click'" [element]="'Icon'"
          [source]="'SidebarV2'" [ngClass]="{ active: helpSubmenu.active || submenuObj['help'].active }" role="menuitem"
          tabindex="0">
          <div class="list__item__text">{{ helpSubmenu.name }}</div>
        </li>
      </ul>
    </li>
    <!-- <li class="list__item" id="sidebar_40" role="none" [class.active]="sidebarService.isSettingsActive">
      <div class="list__item__container">
        <div
          class="list__item__btn"
          tabindex="0"
          role="menuitem"
          id="sidebar-settings"
          title="Settings"
          (click)="navigateTo('settings', 'sidebar-settings', 'General', false)"
          (keyup.enter)="navigateTo('settings', 'sidebar-settings', 'General', false)"
          analytics-tracker
          [tracker]="'Sidebar_Settings_Icon'"
          [type]="'Click'"
          [element]="'Icon'"
          [source]="'SidebarV2'"
        >
          <div id="sidebar_41" class="list__item__icon"><em id="sidebar_42" class="icon icon-setting"></em></div>
          <div id="sidebar_43" class="list__item__text" *ngIf="getSidebarStatus()">Settings</div>
        </div>
      </div>
    </li> -->
    <li class="list__item" id="sidebar_44" role="none">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" role="menuitem" id="sidebar-logout" title="Logout" (click)="logout()"
          (keyup.enter)="logout()" analytics-tracker [tracker]="'Sidebar_Logout_Icon'" [type]="'Click'"
          [element]="'Icon'" [source]="'SidebarV2'">
          <div id="sidebar_45" class="list__item__icon"><em id="sidebar_46" class="icon icon-logout"></em></div>
          <div id="sidebar_47" class="list__item__text" *ngIf="getSidebarStatus()">Logout</div>
        </div>
      </div>
    </li>
  </ul>
</nav>