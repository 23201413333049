import { Component } from '@angular/core';

@Component({
  selector: 'mint-half-yearly-quota-section',
  templateUrl: './halfyearly-quota-section.component.html',
  styleUrls: ['./halfyearly-quota-section.component.scss']
})
export class HalfYearlyQuotaSectionComponent {
  constructor() {}
}
