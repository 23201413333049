import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DetailDataService } from '../../core/network/detail-data.service';
import { Observable, of } from 'rxjs';
import { CustomHttpParams } from '../../core/network/custom-http-params';
import { UserProfileService } from '../../core/context/user-profile.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Injectable()
export class DetailSummaryTilesService {
  onDemandLoadProducts = true;
  sharedContext = new SharedContext();
  constructor(private endpoint: EndpointService, private dataService: DetailDataService, public userProfileService: UserProfileService, public sharedContextState: Store<SharedContext>) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  getPCCInfoForDetailReports(reportType: string, getOnlyAcrData = false): Observable<any> {
    let pccParams = CustomHttpParams.getInstance();
    pccParams = pccParams.append('IncludeTypeAttributes', 'true');
    pccParams = pccParams.append('IncludeSetupAt', 'true');
    pccParams = pccParams.append('ReportType', reportType);
    if (getOnlyAcrData) {
      pccParams = pccParams.append('GetOnlyAcrData', 'true');
    }

    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetPCCInfoForDetailReports.uri, pccParams, null);
  }

  getRevenueByProduct(): Observable<any> {
    this.sharedContext.isRollOver;
    this.onDemandLoadProducts = true;
    return this.sharedContext.isRollOver ? this.dataService.getWithGlobalFilters<any[]>(this.endpoint.RevenueProductSummary.uri, this.endpoint.RevenueProductSummary.params, null) : of(null);
  }

  getRevenueByTerritorySummary(pccName: string, detailReportParams: any, getOnlyAcrData = false): Observable<any> {
    const paramsTerritory = this.PopulateTerritoryParams(detailReportParams, pccName, getOnlyAcrData);
    return this.dataService.get<any[]>(this.endpoint.RevenueTerritorySummary.uri, paramsTerritory);
  }

  PopulateTerritoryParams(detailReportParams: any, pccName: string, getOnlyAcrData = false) {
    let paramsTerritory = CustomHttpParams.getInstance();
    paramsTerritory = paramsTerritory.append('FiscalYear', detailReportParams[0].FiscalYear);
    paramsTerritory = paramsTerritory.append('Period', detailReportParams[0].Period);
    paramsTerritory = paramsTerritory.append('ParticipationId', detailReportParams[0].ParticipationId);
    paramsTerritory = paramsTerritory.append('PccName', pccName);
    paramsTerritory = paramsTerritory.append('IsSummaryReport', 'true');
    paramsTerritory = paramsTerritory.append('PersonnelNumber', detailReportParams[0].PersonnelNumber);
    paramsTerritory = paramsTerritory.append('FiscalQuarterId', detailReportParams[0].FiscalQuarterId);
    paramsTerritory = paramsTerritory.append('StepId', detailReportParams[0].StepId);
    paramsTerritory = paramsTerritory.append('ParticipationEndDate', detailReportParams[0].ParticipationEndDate);
    if (getOnlyAcrData) {
      paramsTerritory = paramsTerritory.append('GetOnlyAcrData', 'true');
    }
    return paramsTerritory;
  }

  getRevenueByAccount(pccName: string): Observable<any> {
    let paramsRevenue = CustomHttpParams.getInstance();

    paramsRevenue = paramsRevenue.append('PccName', pccName);
    paramsRevenue = paramsRevenue.append('IsSummaryReport', 'true');
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.RevenueByAccountSummary.uri, paramsRevenue, null);
    // return of(JSON.parse(result));
  }

  exportRevenueByProduct(): Observable<any> {
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.ExportProductsSummary.uri, this.endpoint.ExportProductsSummary.params, null, 'arraybuffer');
  }

  exportRevenueByTerritory(): Observable<any> {
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.ExportTerritorySummary.uri, this.endpoint.ExportTerritorySummary.params, null, 'arraybuffer');
  }
}
