import { Component, OnInit, Inject } from '@angular/core';
import { DemoService } from './demo.service';
import * as Ng1Services from '../core/hybrid/ng1-upgraded-providers';
import { Router } from '@angular/router';
import { ImpersonationService } from '../core/impersonation/impersonation.service';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: []
})
export class DemoComponent implements OnInit {
  list: any[];

  constructor(private demoService: DemoService, private impersonationService: ImpersonationService, private route: Router) {
  }

  ngOnInit() {
    this.demoService.getDemoData().subscribe(result => {
      this.list = result;
    });
  }

  startImpersonationByClicking(alias) {
    this.route.navigateByUrl('', { skipLocationChange: true });
    const x = this.impersonationService.startImpersonation(alias, alias, false, '', '', '');
  }
}
