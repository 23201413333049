import { Action } from '@ngrx/store';
import { TMeasureTypeMapping } from '../types/measure-type-mapping.type';

export enum MeasureTypeMappingActionTypes {
  LoadMeasureTypeMapping = '[MeasureTypeMapping] Load Measure Type Mapping',
}

export class LoadMeasureTypeMapping implements Action {
  readonly type = MeasureTypeMappingActionTypes.LoadMeasureTypeMapping;
  constructor(public payload: { mapping: TMeasureTypeMapping[] }) {}
}

export type MeasureTypeMappingAction = LoadMeasureTypeMapping;
