export interface IFilterModel {
    title: string;
    label: string;
    list: Array<any>;
    selected: Array<any>;
    count: number;
}
export class FilterModel implements IFilterModel {
    title: string;
    label: string;
    list: Array<any>;
    selected: Array<any>;
    count: number;

    constructor(title, label) {
        this.title = title;
        this.label = label;
        this.list = [];
        this.selected = [];
        this.count = 0;
    }
}
