import { BaseComponent } from './../base.component';
import { Component, OnInit, Inject, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ToggleSwitchConfig } from '../toggle-switch/toggle-switch-config';
import { SoftCapApprovalStatus } from '../../dashboard/manager-dashboard/models/soft-cap-approval-status.enum';
import { LocalStorageService } from '@mint-libs/network';
import { FiltersService } from '../../filters/filters.service';
import { LoggerService } from '@mint-libs/logging';
import { UserProfileService } from '../../core/context/user-profile.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from '../../core/navigation/navigation.service';
import { ImpersonationService } from 'src/app/core/impersonation/impersonation.service';
import { InitializationService } from 'src/app/core/initialization/initialization.service';
import { UserSessionService } from 'src/app/core/session/user-session-service';

@Component({
  selector: 'mint-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() toggleSwitchConfig: ToggleSwitchConfig;
  @Output() updateTitle = new EventEmitter<any>();
  togglecssclass: string;
  titleRight: string;
  isChecked: boolean;
  constructor(
    public initializationService: InitializationService,
    public navigationService: NavigationService,
    public impersonationService: ImpersonationService,
    public sharedContextState: Store<SharedContext>,
    public userProfileService: UserProfileService,
    public rolefeatureService: RoleFeatureService,
    public filterService: FiltersService,
    public localStorage: LocalStorageService,
    private loggingService: LoggerService,
    public userSessionService: UserSessionService,
    router: Router
  ) {
    super(initializationService, navigationService, impersonationService, sharedContextState, userProfileService, rolefeatureService, filterService, localStorage, router, userSessionService);
    this.toggleSwitchConfig = new ToggleSwitchConfig();
    this.togglecssclass = 'toggle__btn__lbl';

    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  ngOnInit() {
    this.titleRight = this.toggleSwitchConfig.titleRight;
    this.isChecked = this.toggleSwitchConfig.isChecked;
  }

  ngAfterViewInit(): void {}

  navigateToTarget() {
    if (this.toggleSwitchConfig.navigateOnToggle) {
      this.titleRight = this.isChecked ? SoftCapApprovalStatus[0] : SoftCapApprovalStatus[1];
      this.isChecked = !this.isChecked;
      this.updateTitle.emit({ title: this.titleRight });
    } else {
      this.router.navigateByUrl('/' + this.toggleSwitchConfig.target);
      this.isChecked = !this.isChecked;
    }
  }

  getApprovalType(id: number) {
    return SoftCapApprovalStatus[id];
  }
}
