import { Injectable, Inject } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@mint-libs/common';
import { Observable, Subject } from 'rxjs';
import { MsalBroadcastService, MsalCustomNavigationClient, MsalService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { EventMessage, EventType, InteractionStatus, SilentRequest } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  envConfig: any;
  constructor(@Inject(ENVIRONMENT_CONFIG) envConfig: any, public msalService: MsalService, private msalBroadcastService: MsalBroadcastService, private router: Router, private location: Location) {
    this.envConfig = envConfig;
  }

  init() {
    this.envConfig.authConfig.redirectUri = window.location.origin;
    this.setCustomNavigationClient();
    this.msalService.handleRedirectObservable().subscribe();
  }

  // Custom navigation set for client-side navigation.
  // See performance doc for details: https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular/docs/v2-docs/performance.md
  private setCustomNavigationClient() {
    const customNavigationClient = new MsalCustomNavigationClient(this.msalService, this.router, this.location);
    this.msalService.instance.setNavigationClient(customNavigationClient);
  }

  login(): Observable<void> {
    return this.msalService.loginRedirect();
  }

  logOut() {
    this.msalService.logout();
  }

  isAuthenticated() {
    const account = this.checkAndSetActiveAccount();
    let isAuthenticated = false;
    if (account) {
      isAuthenticated = true;
    }
    return isAuthenticated;
  }

  acquireToken(request: SilentRequest) {
    this.checkAndSetActiveAccount();
    return this.msalService.acquireTokenSilent(request);
  }

  getLoginCallback(destroying$: Subject<void>) {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(destroying$)
    );
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.getActiveAccount();
    const allAccounts = this.msalService.instance.getAllAccounts();
    if (!activeAccount && allAccounts && allAccounts.length > 0) {
      this.msalService.instance.setActiveAccount(allAccounts[0]);
      activeAccount = allAccounts[0];
    }
    return activeAccount;
  }

  getActiveAccount() {
    const activeAccount = this.msalService.instance.getAllAccounts().find(x => x.username.endsWith(`${this.envConfig.graphDomain}`));
    return activeAccount;
  }

  getUserInfo() {
    const activeAccount = this.getActiveAccount();
    if (!activeAccount) {
      return null;
    }
    const userInfo = {
      profile: {
        given_name: activeAccount.name?.split(' ')[0],
        family_name: activeAccount.name?.split(' ')[1]
      },
      name: activeAccount.name,
      userName: activeAccount.username
    };
    return userInfo;
  }

  unsubscribe(destroying$: Subject<void>) {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }
}
