import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserProfileService } from '../core/context/user-profile.service';
import { CustomHttpParams } from '../core/network/custom-http-params';
import { MqchService } from './mqch.service';
import MQCHCampaign from '../ocv-feedback/MQCHCampaign';
import { OcvFloodgateService } from '../ocv-feedback/ocv-floodgate.service';
import { pcgsConstant, timelineDataConstant, detailViewDataConstant } from './mqch-constants';
import { PageTour } from '@pagetour/sdk';
import { environment } from '../../environments/environment';
import { MessagesService } from '@mint-libs/context';
import { FiltersService } from '../filters/filters.service';
import { LoggerService } from '@mint-libs/logging';
import { selectSharedContextState, SharedContext, smallLetterKeys } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-mqch',
  templateUrl: './mqch.component.html',
  styleUrls: ['./mqch.component.scss']
})
export class MqchComponent implements OnInit {
  mqchMessage: any;
  public sharedContext: SharedContext = new SharedContext();
  constructor(
    private datePipe: DatePipe,
    public userProfileService: UserProfileService,
    public mqchService: MqchService,
    private filterService: FiltersService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private loggingService: LoggerService,
    public sharedContextState: Store<SharedContext>,
    private floodgateService: OcvFloodgateService,
    private messageService: MessagesService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }
  selectedFiscalYear: any;
  selectedStandardTitle: any;
  fiscalYearList: any;
  standardTitleList: any;
  isFormDirty: boolean;
  @ViewChild('mqchPopTemplate') public mqchPopTemplateRef: TemplateRef<any>;
  modalRef: any;
  pcglist: any;
  selectedPcg: any;
  timelineData: any;
  detailViews: any;
  popupAttributes: any;
  popupHeader: string[];
  fiscalYearMonthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  timelineMonths: any;
  activeIds = [];
  activePccIds = [];
  page = 1;
  pageSize = 10;
  isMCHEnabled = false;
  filterConfig = {
    isFilter: false,
    isSearch: false,
    isFiscalYear: true,
    isTitle: true,
    isPartition: false,
    showSearch: false,
    showFilter: true
  };

  ngOnInit() {
    this.loggingService.trackPageView('QuotaChangeHistory', null);
    this.isFormDirty = false;
    this.populateFiscalYearList();
    this.populateStandardTitleList();
    this.bindPcgdetails();
    this.floodgateService.ActivateSurvey(MQCHCampaign);

    if (environment.isPageTourEnabled) {
      const pageTour = PageTour.GetInstance();
      const pageContext = pageTour.getPageContext();

      pageTour.autoPlayByContext(pageContext, 1000, false).then(
        () => {
          console.log('auto play started');
        },
        () => {
          console.log('auto play ended');
        }
      );
    }

    this.getMqchMessage();
  }
  getMqchMessage() {
    if (this.filterService.selectedFiscalYear === 2021) {
      this.mqchMessage = 'For FY21, only quota changes logged after January 1, 2021 are captured in My Change History.';
    } else if (this.filterService.selectedFiscalYear === 2022) {
      this.messageService.get('MQCHFY22Message').subscribe(response => {
        this.mqchMessage = response;
      });
    } else {
      this.messageService.get('MQCHFY23Message').subscribe(response => {
        this.mqchMessage = response;
      });
    }
  }

  updateFiscalyear(fiscalyear) {
    this.isFormDirty = true;
  }

  updateParticpation(participation) {
    this.isFormDirty = true;
  }

  checkForDemoMode() {
    return this.sharedContext.isDemoMode3;
  }

  bindPcgdetails() {
    let params = CustomHttpParams.getInstance();
    params = params.append('FiscalYear', this.filterService.selectedFiscalYear.toString());
    params = params.append('ParticipationID', this.filterService.selectedStandardTitle.ParticipationID);
    params = params.append('PersonnelNumber', this.filterService.personnelNumber.toString());
    params = params.append('IsQuotaTraceability', 'true');
    params = params.append('PlanName', this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.PlanName : null);
    this.mqchService.getPCGMetrics(params).subscribe(pcgDetails => {
      this.bindPcgDetailsData(pcgDetails);
    });
  }

  bindPcgDetailsData(pcgDetails: any) {
    console.log('pcg details ', pcgDetails);
    const pcgids = [];
    this.pcglist = [];
    pcgDetails.forEach(pcg => {
      if (pcgids.indexOf(pcg.Id) < 0) {
        pcgids.push(pcg.Id);
        this.pcglist.push(pcg);
      }
    });
    if (this.pcglist && this.pcglist.length > 0) {
      this.selectedPcg = this.pcglist[0];
      this.bindCombinedView(this.selectedPcg);
    }
  }

  bindCombinedView(pcg, fiscalMonthId = 0) {
    this.spinner.show();
    this.activeIds = [];
    this.activePccIds = [];

    let timelineParams = CustomHttpParams.getInstance();
    timelineParams = timelineParams.append('FiscalYear', this.filterService.selectedFiscalYear.toString());
    timelineParams = timelineParams.append('ParticipationID', this.filterService.selectedStandardTitle.ParticipationID);
    timelineParams = timelineParams.append('PCGID', pcg.Id);

    let detailParams = CustomHttpParams.getInstance();
    detailParams = detailParams.append('FiscalYear', this.filterService.selectedFiscalYear.toString());
    detailParams = detailParams.append('ParticipationID', this.filterService.selectedStandardTitle.ParticipationID);
    detailParams = detailParams.append('PCGID', pcg.Id);
    if (!fiscalMonthId) {
      const nowDate = new Date();
      const current_date = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate();
      detailParams = detailParams.append('CurrentDate', current_date);
    } else {
      detailParams = detailParams.append('FiscalMonthId', fiscalMonthId.toString());
    }

    this.mqchService.getTimelineAndDetailedView(timelineParams, detailParams).subscribe(([timelineDetails, detailView]) => {
      this.bindCombinedViewData(timelineDetails, detailView, fiscalMonthId);
    });
  }

  bindCombinedViewData(timelineDetails: any, detailView: any, fiscalMonthId) {
    const startMonth = this.getFiscalYearMonth(new Date(this.filterService.selectedStandardTitle.StartDate).getMonth());
    const endMonth = this.getFiscalYearMonth(new Date(this.filterService.selectedStandardTitle.EndDate).getMonth());
    const currentMonth = this.getFiscalYearMonth(new Date().getMonth());
    this.timelineMonths = JSON.parse(JSON.stringify(timelineDetails));
    this.timelineData = timelineDetails;
    this.timelineMonths = this.timelineMonths.reverse();
    this.detailViews = detailView.filter(dv => !!dv.PCGQuota);
    this.detailViews.forEach(dv => {
      const dateStr = new Date(dv.EffectiveDate)
        .toDateString()
        .split(' ')
        .join('_');
      dv['date_id'] = 'date_' + dateStr;
      dv.DeltaPcc.forEach(pcc => {
        pcc['id'] = 'panel_' + dateStr + '_' + pcc.PCCId;
        this.activePccIds.push(pcc['id']);
      });
    });
    this.timelineMonths.forEach((month, index) => {
      month['inParticipation'] =
        this.getFiscalYearMonth(new Date(month.FiscalMonthName).getMonth()) >= startMonth &&
        this.getFiscalYearMonth(new Date(month.FiscalMonthName).getMonth()) <= endMonth &&
        !month.FiscalMonthName.includes('2020');
      if (!fiscalMonthId) {
        if (month.FiscalMonthName.startsWith(this.fiscalYearMonthsLong[new Date().getMonth()])) {
          month['isActive'] = true;
          this.activeIds.push('custom_panel_' + month.FiscalMonthId);
        } else {
          month['isActive'] = false;
        }
      } else {
        if (month.FiscalMonthId === fiscalMonthId) {
          month['isActive'] = true;
          this.activeIds.push('custom_panel_' + month.FiscalMonthId);
        } else {
          month['isActive'] = false;
        }
      }
    });
    console.log('timeline and detailview ', this.timelineMonths, detailView);
    this.spinner.hide();
    setTimeout(() => {
      const el = document.getElementById(this.activeIds[0]);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500);
  }

  getFiscalYearMonth(month) {
    return month >= 6 ? month - 6 : month + 6;
  }

  pcgUpdate(pcg) {
    this.timelineData = null;
    this.timelineMonths = null;
    this.detailViews = null;
    this.bindCombinedView(pcg, 0);
  }

  applyFilters() {
    this.isFormDirty = false;
    this.bindPcgdetails();
    this.getMqchMessage();
  }

  onFilterChange(event: any) {
    this.isFormDirty = true;
  }
  populateParticipation(participation) {
    console.log(participation);
  }

  populateFiscalYearList() {
    this.fiscalYearList = [
      {
        title: 'FY21',
        value: 2021
      }
    ];
    this.mqchService.selectedFiscalYear = this.fiscalYearList[0].value;
  }

  updateMonthData(event) {
    this.detailViews = null;
    this.timelineMonths = null;
    this.bindCombinedView(this.selectedPcg, event.FiscalMonthId);
  }

  checkForPopup(reason) {
    if (reason.AttributeParams && reason.AttributeParams[0] && Object.keys(reason.AttributeParams[0]).every(key => reason.AttributeParams[0][key] !== '')) {
      return true;
    }
    return false;
  }

  checkAttributeChanged(reason) {
    if (this.checkForPopup(reason)) {
      return reason.AttributeChanged.substr(0, reason.AttributeChanged.length - 10);
    }
    return reason.AttributeChanged;
  }

  openModal(mqchPopTemplate: TemplateRef<any>, reason) {
    this.popupAttributes = reason.AttributeParams;
    this.popupHeader = Object.keys(reason.AttributeParams[0]);
    this.modalRef = this.modalService.open(mqchPopTemplate, {
      ariaLabelledBy: 'modal-basic-title',
      backdropClass: 'mint-modal-backdrop',
      backdrop: 'static',
      windowClass: 'mint-modal-window-lg-mqch'
    });
  }

  closeModal(event: any) {
    this.modalRef.close();
  }

  populateStandardTitleList() {
    const allStandardTitles = smallLetterKeys(this.userProfileService.getStandardTitleList());
    console.log(this.standardTitleList);

    const applicableStandardTitles = allStandardTitles.filter(title => {
      return title.fiscalYear.key === this.filterService.selectedFiscalYear;
    });

    applicableStandardTitles.sort((a, b) => {
      // sort by date descending
      if (new Date(a.startDate) < new Date(b.startDate)) {
        return 1;
      } else if (new Date(a.startDate) > new Date(b.startDate)) {
        return -1;
      } else {
        return 0;
      }
    });

    this.standardTitleList = applicableStandardTitles.map(title => {
      const startDate = this.datePipe.transform(title.startDate);
      const endDate = this.datePipe.transform(title.endDate);
      if (title.participationID > 0) {
        return {
          value: title,
          text: title.name + ' (' + startDate + ' - ' + endDate + ')'
        };
      } else {
        return {
          value: title,
          text: title.name
        };
      }
    });
    this.mqchService.selectedStandardTitle = this.standardTitleList[0];
    console.log('standard title ', this.mqchService.selectedStandardTitle);
  }
}
