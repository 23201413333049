import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-product-group-renderer',
  styles: [
    `
      .pg-attn {
        line-height: 16px;
      }
      .att-val {
        font-size: 11px;
        color: #707070;
      }
    `
  ],
  template: `
    <span style="display: block; margin-top:5px;" *ngIf="params.value">
      <span style="font-size: 14px;" *ngIf="params.value.Name"> {{ params.value.Name }}</span> <br />
      <div style="" class="pg-attn">
        <span style="" class="att-val" *ngIf="params.value.attainment">Metric Attainment: {{ params.value.attainment }} %</span> <br />
        <span style="" class="att-val" *ngIf="params.value.weight">Metric Weight: {{ params.value.weight }} %</span>
      </div>
    </span>
  `
})
export class ProductGroupRendererComponent implements ICellRendererAngularComp {
  params: any;
  thresholdInfo: any;
  constructor() {}
  agInit(params): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
