import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import { NetworkService, EndpointService } from '@mint-libs/network';
import { ConfigurationActionTypes, LoadConfigurationSuccess, LoadConfigurationFailure } from './configuration.actions';

@Injectable()
export class ConfigurationEffects {
  @Effect()
  loadConfigurations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConfigurationActionTypes.LoadConfigurations),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        this.networkService.get(this.endpoints.GetConfiguration.uri, null).pipe(
          map((data: any) => new LoadConfigurationSuccess(data.result)),
          catchError(error => of(new LoadConfigurationFailure({ error })))
        )
      )
    );
  });

  constructor(private actions$: Actions, private networkService: NetworkService, private endpoints: EndpointService) {}
}
