import { TBonusConfig, TBucketAwardsHeader, TBonusConfigResponse, TGrainItemSection, TAwardsSummaryHeader } from '../types/bonus-config.type';

export class BonusConfig {
  bonusProgramName: string;
  isEnabled: boolean;
  programDescription: string;
  programInfoLink: string;
  bucketAwardsHeaderConfig: TBucketAwardsHeader;
  awardsSummaryHeaderConfig: TAwardsSummaryHeader;
  grainItemSectionsConfig: {
      weightedSection?: TGrainItemSection,
      bonusSection: TGrainItemSection,
      excellenceBonusSection?: TGrainItemSection
  };

  constructor(config: TBonusConfig) {
    this.bonusProgramName = config.bonusProgramName;
    this.isEnabled = config.isEnabled;
    this.programDescription = config.programDescription;
    this.programInfoLink = config.programInfoLink;
    this.bucketAwardsHeaderConfig = config.bucketAwardsHeaderConfig;
    this.awardsSummaryHeaderConfig = config.awardsSummaryHeaderConfig;
    this.grainItemSectionsConfig = {
      weightedSection: config.grainItemSectionsConfig.weightedSection,
      bonusSection: config.grainItemSectionsConfig.bonusSection,
      excellenceBonusSection: config.grainItemSectionsConfig.excellenceBonusSection,
    };
  }

  toConfigType(): TBonusConfig {
    return {
      bonusProgramName: this.bonusProgramName,
      isEnabled: this.isEnabled,
      programDescription: this.programDescription,
      programInfoLink: this.programInfoLink,
      grainItemSectionsConfig: this.grainItemSectionsConfig,
      bucketAwardsHeaderConfig: this.bucketAwardsHeaderConfig,
      awardsSummaryHeaderConfig: this.awardsSummaryHeaderConfig,
    };
  }
}

export class BonusDocumentConfig {
  id: string;
  bonusProgramDisplayName: string; 
  configuration: BonusConfig;

  constructor(bonusConfigResponse: TBonusConfigResponse) {
    this.id = bonusConfigResponse.id;
    this.bonusProgramDisplayName = bonusConfigResponse.bonusProgramDisplayName;
    this.configuration = new BonusConfig(bonusConfigResponse.configuration);
  }
  
  toBonusConfigType(): TBonusConfigResponse {
    return {
      id: this.id,
      bonusProgramDisplayName: this.bonusProgramDisplayName,
      configuration: this.configuration.toConfigType()
    }
  }
}

