import { Component, OnInit, Output, Input } from '@angular/core';
import { CagrService } from '../cagr.service';

@Component({
  selector: 'mint-cagr-chart-view',
  templateUrl: './cagr-chart-view.component.html',
  styleUrls: ['./cagr-chart-view.component.scss']
})
export class CagrChartViewComponent implements OnInit {
  returnVal: any;
  accounts: any;
  isLoaded = false;
  arr: any;
  constructor(private cagrService: CagrService) { }
  @Input() accountDetails;
  @Input() totalCloudGrowthChart;
  @Input() participationEndYear;
  @Input() participationStartYear;
  ngOnInit() {
    if (this.accountDetails != undefined && this.totalCloudGrowthChart != undefined) {
      this.isLoaded = true;
    }
  }
}
