import { Pipe, PipeTransform } from '@angular/core';
import { CustomNumberPipe } from './custom-number.pipe';

@Pipe({ name: 'percentage' })
export class PercentagePipe extends CustomNumberPipe implements PipeTransform {
  transform(value: any): any {
    if (value && typeof value === 'string' && value.indexOf('%') > -1) {
      return value;
    }
    if (isNaN(value) && !value && value !== 0) {
      return '';
    }
    let formatted = super.transform(value); // Call base CustomNumber pipe to format the value with 2 decimal places
    formatted = `${formatted}%`;
    return formatted;
  }
}
