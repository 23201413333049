import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../core/network/data.service';
import { Observable } from 'rxjs/internal/Observable';
import { CustomHttpParams } from '../core/network/custom-http-params';
import { CachingService } from '../core/caching/caching.service';

@Injectable({
  providedIn: 'root'
})
export class ManageNotificationService {
  constructor(private endpoint: EndpointService, private dataService: DataService, private cachingService: CachingService) {}

  GetAdminNotificationsFilterData(): Observable<any> {
    let params = CustomHttpParams.getInstance();
    params = params.append('camelcase', 'true');
    return this.dataService.getCamelCase<any>(this.endpoint.GetAdminNotificationFilterData.uri, params);
  }

  GetAdminNotifications(): Observable<any> {
    this.cachingService.clearCache(this.endpoint.GetAdminNotifications.uri);
    let params = CustomHttpParams.getInstance();
    params = params.append('camelcase', 'true');
    return this.dataService.getCamelCase<any>(this.endpoint.GetAdminNotifications.uri, params);
  }

  clearCache() {
    this.cachingService.clearCache(this.endpoint.GetAdminNotifications.uri);
  }

  saveAdminNotification(currNotification): Observable<any> {
    return this.dataService.postWithGlobalFilters<any>(this.endpoint.SetAdminNotifications.uri, currNotification);
  }
}
