<app-progress-bar class="sticky__progress__bar"></app-progress-bar>
<div class="note-container"></div>
<div class="reports-container">
  <div class="search-filter-container" *ngIf="isLargeUser">
    <mint-advance-search-filter
      [searchSuggestion]="searchSuggestion"
      [reportType]="revenueReportType"
      [isFilterTabActive]="activeTabId === filterTabId"
      (search)="search($event)"
      (filter)="filterAccounts($event)"
    ></mint-advance-search-filter>
    <mint-report-tabs [tabs]="tabs" [activeTabId]="activeTabId" [reportType]="revenueReportType" (tabsChange)="tabsChange($event)"></mint-report-tabs>
  </div>
  <div class="charts-container">
    <div class="metric-chart-container vr-line">
      <div *ngIf="selectedPccChart" class="chart-title">Value of Commitments Landed and Commitments Quota by Metric</div>

      <mint-content-loader-double-bar-chart-single *ngIf="!chartDataPccs"></mint-content-loader-double-bar-chart-single>
      <mint-metric-multibar-chart *ngIf="chartDataPccs" class="multibar__chart" containerId="metric_chart" [dataset]="chartDataPccs" [isBarClickable]="true" (chartClick)="filterSelectedPcc($event)">
      </mint-metric-multibar-chart>
    </div>
    <div class="revenue-chart-container">
      <div *ngIf="selectedPccChart" class="chart-title">{{ selectedPccChart.desc }}</div>
      <mint-content-loader-double-bar-chart-multiple *ngIf="!chartDataRevenue"></mint-content-loader-double-bar-chart-multiple>
      <mint-metric-multibar-chart *ngIf="chartDataRevenue" class="multibar__chart" containerId="revenue_chart" [dataset]="chartDataRevenue"> </mint-metric-multibar-chart>
    </div>
  </div>
  <div class="legend">
    <div class="legend__item">
      <div class="legend__item__clr legend__item__clr-non"></div>
      <div class="legend__item__text">Value of Commitments Landed</div>
    </div>
    <div class="legend__item">
      <div class="legend__item__clr legend__item__clr-tgt"></div>
      <div class="legend__item__text">Commitments Quota</div>
    </div>
  </div>
  <hr />
  <div class="revenue-grid-container">
    <mint-report-grid
      *ngIf="revenueData?.length"
      [columns]="revenueGridColumns"
      [pageSize]="gridPageSize"
      [data]="revenueData"
      [gridTitle]="'Value of Commitments Landed Grid'"
      (exportToExcel)="onRevenueExcelExport($event)"
      [excelDownloadStatus]="revenueExcelConfigs.excelDownloadStatus"
      [excelDownloadMessage]="revenueExcelConfigs.excelDownloadMessage"
      [excelTitle]="revenueReportType"
    ></mint-report-grid>
    <mint-content-loader-grid *ngIf="!revenueData?.length"></mint-content-loader-grid>
  </div>
  <div class="target-grid-container">
    <mint-report-grid
      *ngIf="targetData?.length"
      [columns]="targetGridColumns"
      [pageSize]="gridPageSize"
      [data]="targetData"
      [gridTitle]="'Commitments Quota Grid'"
      (exportToExcel)="onTargetExcelExport($event)"
      [excelDownloadStatus]="targetExcelConfigs.excelDownloadStatus"
      [excelDownloadMessage]="targetExcelConfigs.excelDownloadMessage"
      [excelTitle]="revenueReportType"
    ></mint-report-grid>
    <mint-content-loader-grid *ngIf="!targetData?.length"></mint-content-loader-grid>
  </div>
</div>
