import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as Ng1Services from '../hybrid/ng1-upgraded-providers';
import { DataService } from './data.service';
import { NavigationService } from '../navigation/navigation.service';

@Injectable()
export class DownTimeInterceptor implements HttpInterceptor {
  constructor(public navigationService: NavigationService, private dataService: DataService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.checkForDowntime(req, next);
  }

  checkForDowntime(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const data = event.body;
          if (data && data.MaintainanceMode) {
            const localOutageStartTime = this.ConvertToLocalTime(data.OutageStartTime);
            const localOutageEndTime = this.ConvertToLocalTime(data.OutageEndTime);
            const currentDate = new Date();
            if (currentDate >= localOutageStartTime && currentDate <= localOutageEndTime) {
              this.dataService.MaintainanceMode.On(localOutageStartTime, localOutageEndTime, data.DownTimeMessage, data.DownTimeTitle);
              this.navigationService.clearHistoryAndNavigateTo('maintainance');
            }
          }
        }
      })
    );
  }
  ConvertToLocalTime(utcDate) {
    const date = new Date(utcDate);
    return date;
  }
}
