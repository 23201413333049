import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { InitActions, InitializationActionTypes } from './initialization.actions';
import { InitializationService } from '../initialization.service';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { NavigationService } from '../../navigation/navigation.service';
import { UserSessionService } from '../../session/user-session-service';
import { Store } from '@ngrx/store';

@Injectable()
export class InitializationEffects {
  @Effect()
  refreshInitialization$ = this.actions$.pipe(
    ofType(InitializationActionTypes.PerformInitRefresh),
    concatMap(async action => this.userSessionService.refresh(action.clearCache))
  );
  @Effect()
  saveFilterState$ = this.actions$.pipe(
    ofType(InitializationActionTypes.SaveFilterStateAndRefresh),
    concatMap(async () => {
      this.userSessionService.saveFilterState();
      this.userSessionService.resetFilters();
    })
  );
  @Effect()
  loadFilterState$ = this.actions$.pipe(
    ofType(InitializationActionTypes.LoadFilterState),
    concatMap(async action => {
      this.userSessionService.loadOldFilterState();

      this.userSessionService
        .initialize(
          action.skipAuthorization,
          (this.sharedContext.loggedInUserInfo.isFCA === true || this.sharedContext.loggedInUserInfo.isSupportAdmin === true) && !this.sharedContext.isDemoMode ? false : true,
          false,
          true
        )
        .then(response => {
          // $rootScope.$broadcast('isRefreshBannerNotif');

          if (this.sharedContext.isAdmin || this.sharedContext.loggedInUserInfo.isFCA) {
            //  $state.reload();
          } else {
            this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, toggleView: 1 }));
            //  $state.reload();
          }
          this.navigationService.navigateTo('login');
          // NavigationFactory.navigateTo('login');
        });
    })
  );
  sharedContext = new SharedContext();

  constructor(private actions$: Actions<InitActions>, private sharedContextState: Store<SharedContext>, private navigationService: NavigationService, private userSessionService: UserSessionService) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }
}
