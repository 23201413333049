import { Metric } from 'src/app/shared/models/metric.model';
import { QuotaTogoMetric } from './quota-togo-metric';
import { AzureIncentiveMetric } from './azure-incentive-metric';
import { DirectPPACompliance } from './direct-ppa-compliance';
import { DirectQACompliance } from './direct-qa-compliance';

export class Direct {
  name: string;
  firstName: string;
  lastName: string;
  role: string;
  alias: string;
  personnelNumber: number;
  planId: number;
  optedOut: boolean;
  incentiveType: string;
  isQAAccepted: boolean;
  isPPAComplete: boolean;
  numberOfReportees: number;
  totalWeightedAttainment: number;
  currentParticipation: number;
  isACREligible: boolean;
  isACREligibleInHierarchy: boolean;
  quotaDocumentName: string;

  missingBusinesses: string;
  missingSubsidiaries: string;
  ppaComplianceCountries: Array<DirectPPACompliance>;
  qaComplianceDates: Array<DirectQACompliance>;
  pcgMetrics: Array<Metric>;
  pccMetrics: Array<QuotaTogoMetric>;
  azureIncentivesMetrics: AzureIncentiveMetric;

  //Manager Information
  managerAlias: string;

  // Error flags
  isManager: boolean;
  isFCAHasAccess: boolean;
  isPlanOnboarded: boolean;
  isQaFcaApproved: boolean;
  isAuthorized: boolean;

  // viewmodel
  isSubDirectsVisibleForAttainment = false;
  isSubDirectsVisibleForQuotaToGo = false;
  isSubDirectsVisibleForAzureIncetives = false;
  showSubDirectsLoader = false;
  isFiltered = false;
  viewReporteesFlag = 'View';
  level = 1;

  // Softcap model
  softCapInfo: any;

  isRBIPersona: boolean;
  isUBIPersona: boolean;
  isPPIPersona: boolean;

  // UBI attainment
  ubiAttainmentMetrics: any;

  // UBI attainment
  ppiAttainmentMetrics: any;

  directs: Array<Direct>;
  ubiRunRate: number;
  ubiActiveAttainmentPercentage: number;
}
