import { Pipe, PipeTransform } from '@angular/core';
import { Formatter } from '../utilities/formatter';

@Pipe({ name: 'customCurrency' })
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private formatter: Formatter) {}
  transform(value: any, currencyCode: string): string {
    const formatted = this.formatter.formatWithCurrencyAndRoundOff(value, currencyCode);
    return formatted;
  }
}
