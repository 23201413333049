<mint-content-loader-icon-text *ngIf="!isAccountsLoaded"></mint-content-loader-icon-text>
<div class="cagr__account__container" *ngIf="isAccountsLoaded">
  <div class="cagr__account" *ngFor="let item of accounts">
    <div class="cagr__account__logo">
      <!-- <ngx-avatar name="account logo" initialsSize="2" size="35" [round]="true" src="data:image/jpeg;base64,{{ item.LogoSource }}" textSizeRatio="2.5"></ngx-avatar> -->
      <!-- <img src="{{ item.LogoSource }}" alt="account logo" /> -->
      <svg style="display: flex; align-items: center;" width="66" height="66" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.7336 37.403H6.6001V21.5042L22.7336 15.0508V37.403ZM8.06678 35.9363H21.2669V17.2215L8.06678 22.5015V35.9363Z" fill="#00AE56" />
        <path d="M37.4001 37.4019H21.2666V6.60156H37.4001V37.4019ZM22.7333 35.9352H35.9334V8.06824H22.7333V35.9352Z" fill="#00AE56" />
        <path d="M25.6671 9.53516H24.2004V12.4685H25.6671V9.53516Z" fill="#00AE56" />
        <path d="M28.6 9.53516H27.1333V12.4685H28.6V9.53516Z" fill="#00AE56" />
        <path d="M31.5336 9.53516H30.0669V12.4685H31.5336V9.53516Z" fill="#00AE56" />
        <path d="M34.4674 9.53516H33.0007V12.4685H34.4674V9.53516Z" fill="#00AE56" />
        <path d="M25.6671 13.9375H24.2004V16.8709H25.6671V13.9375Z" fill="#00AE56" />
        <path d="M28.6 13.9375H27.1333V16.8709H28.6V13.9375Z" fill="#00AE56" />
        <path d="M31.5336 13.9375H30.0669V16.8709H31.5336V13.9375Z" fill="#00AE56" />
        <path d="M34.4674 13.9375H33.0007V16.8709H34.4674V13.9375Z" fill="#00AE56" />
        <path d="M18.3332 37.3998H10.9998V30.0664H18.3332V37.3998ZM12.4664 35.9331H16.8665V31.5331H12.4664V35.9331Z" fill="#00AE56" />
        <path d="M33.0006 37.3977H25.6672V28.5977H33.0006V37.3977ZM27.1339 35.9311H31.534V30.0643H27.1339V35.9311Z" fill="#00AE56" />
      </svg>
    </div>
    <div class="cagr__account__details" *ngIf="showAccount360">
      <h2 class="cagr__account__name" *ngIf="!isDemoMode3">{{ item.AccountName }} ({{ item.AccountId }})</h2>
      <h2 class="cagr__account__name" *ngIf="isDemoMode3">Contoso Account (12345678)</h2>
      <div class="cagr__account__info" *ngIf="accounts.length == 1" >
        <div class="cagr__account__link">
          <a *ngIf="!isDemoMode3" href="https://msxportal.microsoft.com/a360/{{ item.AccountId }}" target="_blank"> View Account360 <span class="icon icon-openInNewWindow"></span> </a>
          <a *ngIf="isDemoMode3" href="https://msxportal.microsoft.com/a360/" target="_blank"> View Account360 <span class="icon icon-openInNewWindow"></span> </a>
        </div>
        <div class="cagr__account__note"><i>Note: The Account360 view in MSXPortal provides an overall view of the account and is not tied to the specifics of this incentive program.</i></div>
      </div> 
    </div>
    <div class="cagr__account__bonus" *ngIf="showBonusPayoutInfo" >
       <h4 class="cagr__account__bonus__txt">Your Bonus payment is available to view in your 
      <a
      (click)="viewReport()"
      (keyup.enter)="viewReport()"
      analytics-tracker
      [tracker]="'Widget_Action_ViewReport_Payout'"
      [type]="'Click'"
      [element]="'Hyperlink'"
      >
        Payout Details Report</a> 
       </h4>
    </div>
  </div>
</div>
<div class="cagr__views" *ngIf="isAwardsUnlockedLoaded && accountsCount == 1">
  <div class="app__nav app__nav-views">
    <mint-tabs [tabs]="chartGridViewTab" (tabChange)="onTabChange($event)" [activeId]="activeTabId" [tracker]="'Cagr_ChartGridView'" [source]="'Cagr'"></mint-tabs>
  </div>
  <div class="tab__container">
    <mint-content-loader-bar-chart-horizontal *ngIf="!isattainmentDetailsLoaded"> </mint-content-loader-bar-chart-horizontal>
    <mint-cagr-chart-view
      *ngIf="!gridView && isattainmentDetailsLoaded"
      [accountDetails]="attainmentDetails"
      [totalCloudGrowthChart]="totalCloudGrowthChart"
      [participationStartYear]="participationStartYear"
      [participationEndYear]="participationEndYear"
    ></mint-cagr-chart-view>
    <mint-cagr-grid-view
      *ngIf="gridView && isattainmentDetailsLoaded"
      [accountDetailsGrid]="attainmentDetailsGrid"
      [totalCloudGrowthGrid]="totalCloudGrowthGrid"
      [gridLabel]="'Meet target in at least one cloud'"
    ></mint-cagr-grid-view>
  </div>
</div>
<!-- <div class="cagr__views" *ngIf="accountsCount>1">
    <h4 style="margin-bottom: 20px;">Revenue and Target by Account </h4>
    <div class="app__nav app__nav-views" style="justify-content:flex-start" *ngIf="isMultipleAccountLoaded">
      <ngb-tabset #t="ngbTabset" (tabChange)="onTabChangeRevNtarget($event)" type="pills" [activeId]="activeTabIdRevNtarget">
        <ngb-tab *ngFor="let item of multipleAccountData" [id]="item.Name" analytics-tracker [tracker]="" [type]="'Click'" [element]="'Tab'"
          [source]="">
          <ng-template ngbTabTitle style="font-family: Segoe UI;font-size: 14px;">
            <span>
            </span>
            {{item.Name}}
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
    <div class="tab__container" *ngFor="let item of multipleAccountData">
      <mint-revenue-target-by-account *ngIf="item.Name === activeTabIdRevNtarget" [item]="item"></mint-revenue-target-by-account>
    </div>
  </div> -->
<mint-awards-unlocked *ngIf="isAwardsUnlockedLoaded && accountsCount == 1" [awardsDataSet]="awardsDataSet"> </mint-awards-unlocked>
<mint-content-loader-large *ngIf="!isProductsServicesLoaded"></mint-content-loader-large>
<div class="cagr__views" *ngIf="isProductsServicesLoaded">
  <div class="prd_services_header"><b>Products/Services</b></div>
  <mint-products-services [productsServicesData]="productsServicesData"> </mint-products-services>
</div>
