import { Pipe, PipeTransform } from '@angular/core';
import { CustomNumberPipe } from './custom-number.pipe';

@Pipe({ name: 'shortNumber' })
export class ShortNumberPipe extends CustomNumberPipe implements PipeTransform {
  transform(number: any): any {
    let exp,
      suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];

    if (Number.isNaN(number)) {
      return null;
    }
    if (number < 1000 && number > -1000) {
      return number;
    }
    const isNegative = number < 0;
    if (isNegative) {
      number = number * -1;
    }
    exp = Math.floor(Math.log(number) / Math.log(1000));
    if (isNegative) {
      return super.transform((number * -1) / Math.pow(1000, exp)) + suffixes[exp - 1];
    } else {
      return super.transform(number / Math.pow(1000, exp)) + suffixes[exp - 1];
    }
  }
}
