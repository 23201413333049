<!--UBI Verbiage-->
<div id="products-summary_1" class="app__tile__header" *ngIf="loadSummaryReportHeading">
  <div id="products-summary_2" class="app__tile__heading">YTD Actuals by Products</div>
  <div analytics-tracker="" class="app__tile__report" id="plan_summary_4">
    <mint-export-excel
      id="products-summary_3"
      [title]="'Export Actuals by Product to Excel'"
      [reportFileName]="'CompActualsByProduct'"
      [downloadInactive]="showExportLoader"
      (notifyDownloadClick)="export($event)"
      class="app__export__excel"
    ></mint-export-excel>
  </div>
</div>

<!--RBI Verbiage-->
<div id="products-summary_4" class="tab__content__header" *ngIf="incentiveType === 'RBI'">
  <div id="products-summary_5" class="tab__content__subhead">Showing all available products in your plan</div>
</div>

<div id="products-summary_6" *ngIf="!loaded" class="content__loader content__loader-chart">
  <mint-content-loader-bar-chart id="territory-summary_19"></mint-content-loader-bar-chart>
</div>

<mint-metric-chart
  id="products-summary_7"
  [dataset]="productsDataset"
  [chartType]="chartType"
  class="pcg__item__chart"
  [chartHelpText]="chartHelpText"
  [containerId]="'product_summary_bar_chart'"
  [detailsummaryreport]="'true'"
></mint-metric-chart>

<div id="products-summary_8" *ngIf="lastRefreshDate != null && incentiveType === 'UBI'">
  <div id="products-summary_9" class="legend__item top-margin-legend">
    <div id="products-summary_10" class="legend__item__clr legend__item__clr-actuals"></div>
    <div id="products-summary_11" class="legend__item__text">Revenue</div>
  </div>
  <br id="products-summary_12" />
  <div id="products-summary_13" class="legend__item">
    <div id="products-summary_14" class="legend__item__text">Updated {{ lastRefreshDate }}</div>
  </div>
</div>
