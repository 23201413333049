<div id="dashboard_1" class="filters-tab">
  <div id="dashboard_2">
    <ul id="dashboard_3" class="nav nav-tabs" role="tablist">
      <li id="dashboard_4" role="presentation" class="uib-tab nav-item" *ngIf="isMyInformationApplicable" [class.active]="tabset == 0">
        <a
          id="tab-myinfo"
          [attr.aria-selected]="tabset === 0"
          class="nav-link"
          role="tab"
          tabindex="0"
          (click)="toggleView(0)"
          (keyup.enter)="toggleView(0)"
          analytics-tracker
          [tracker]="'My_Information_View'"
          [type]="'Click'"
          [element]="'HyperLink'"
          [source]="'DashboardV2'"
          >My Information</a
        >
      </li>
      <li id="dashboard_5" role="presentation" class="uib-tab nav-item" *ngIf="isMyTeamTeamApplicable" [class.active]="tabset === 1">
        <a
          id="tab-myteam"
          [attr.aria-selected]="tabset === 1"
          class="nav-link"
          role="tab"
          tabindex="0"
          (click)="toggleView(1)"
          (keyup.enter)="toggleView(1)"
          analytics-tracker
          [tracker]="'My_Team_View'"
          [type]="'Click'"
          [element]="'HyperLink'"
          [source]="'DashboardV2'"
          >My Team
        </a>
      </li>
    </ul>
    <mint-splash [isAdmin]="false"></mint-splash>
  </div>
  <mint-toggle-switch *ngIf="showYTD && tabset === 0" [toggleSwitchConfig]="toggleSwitchConfigYtd" class="ytd__toggle"> </mint-toggle-switch>
  <app-tour *ngIf="showYTD && tabset === 0" [tourName]="'YTD Dashboard'" id="ytd-dashboard-tour-start" style="margin-left:-20px;"></app-tour>
  <!-- <global-filter id="dashboard_6" [config]="filterConfig" (apply)="applyFilter($event)"></global-filter> -->
  <mint-filters id="dashboard_6" [config]="filterConfig" (apply)="applyFilter($event)"></mint-filters>
</div>
<app-progress-bar id="dashboard_8" class="sticky__progress__bar"></app-progress-bar>
<div *ngIf="isHalfYearlyQuotaSupported && h2DashboardMessage" class="h2quota_banner_dashboard">
  <span>{{ h2DashboardMessage }}</span>
</div>
<mint-seller-dashboard *ngIf="tabset === 0"></mint-seller-dashboard>
<mint-manager-dashboard *ngIf="tabset === 1"></mint-manager-dashboard>
