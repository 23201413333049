import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BonusComponent } from './bonus.component';
import { AvatarModule } from 'ngx-avatar';
import { HttpClientModule } from '@angular/common/http';

const routes: Routes = [
  {
    path: '',
    component: BonusComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), HttpClientModule, AvatarModule],
  exports: [RouterModule]
})
export class BonusRoutingModule {}
