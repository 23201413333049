import { SharedContext } from './models/shared-context.model';
import { SharedContextActions, SharedContextActionTypes } from './shared-context.actions';

export const sharedContextFeatureKey = 'sharedContext';

const initialState: SharedContext = new SharedContext();

export function sharedContextReducer(state = initialState, action: SharedContextActions): SharedContext {
  switch (action.type) {
    case SharedContextActionTypes.UpdateSharedContext:
      return { ...action.payload };

    default:
      return state;
  }
}
