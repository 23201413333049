import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-target-renderer',
  styles: [
    `
      .pg-attn {
        line-height: 16px;
      }
      .att-val {
        font-size: 11px;
        color: #707070;
      }
    `
  ],
  template: `
    <span>
      <span style="font-size: 14px; text-align: right; color: #4F4F4F;" >
        <b>{{ params.data.target }} {{ params.data.currency }} </b>
      </span>
      <br />
      <div style="line-height: 16px;">
        <span style="font-size: 12px; color: #707070; line-height: 16px; text-align: right;" *ngIf="params.data.startFiscalYr"
          >3 Year Target ({{ params.data.startFiscalYr }}-{{ params.data.endFiscalYr }})</span
        >
      </div>
    </span>
  `
})
export class TargetRendererComponent implements ICellRendererAngularComp {
  params: any;
  thresholdInfo: any;
  constructor() {}
  agInit(params): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
