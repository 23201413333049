import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mint-bonus-chart-horizontal',
  templateUrl: './bonus-chart-horizontal.component.html',
  styleUrls: ['./bonus-chart-horizontal.component.scss']
})
export class BonusChartHorizontalComponent implements OnInit {
  @Input() item: any;
  isLoaded = false;
  constructor() { }

  ngOnInit() {
    if (this.item != undefined) {
      this.isLoaded = true;
    }
  }

  showTooltip(e, bonusAttribute) {

    if(bonusAttribute.isBingo())
      return;
      
    // Get the tooltip element
    const tooltipElem: HTMLElement = document.querySelector('#cloud_perf' + bonusAttribute.PlanComponentId);
    tooltipElem.style.display = 'block';
    // Position tooltip
    tooltipElem.style.left = `${e.clientX - 48 - 125}px`;
    tooltipElem.style.top = `${e.clientY - 48 + 10}px`;
    // console.log(`coordinates: (${e.clientX},${e.clientY})`);
  }

  hideTooltip(bonusAttribute) {
    if(bonusAttribute.isBingo())
      return;
    const tooltipElem: HTMLElement = document.querySelector('#cloud_perf' + bonusAttribute.PlanComponentId);
    tooltipElem.style.display = 'none';
  }
  calculateColor(item: any) {
    if (item.multiPcc) {
      item.pCC.forEach(element => {
        if (element.attainment >= 100) {
          return '6px solid #26873A';
        }
      });
    }
    return item.Attainment >= 100 ? '6px solid #26873A' : '6px solid #333333';
  }
}
