import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CagrComponent } from './cagr.component';
import { CagrChartViewComponent } from './cagr-chart-view/cagr-chart-view.component';
import { CagrGridViewComponent } from './cagr-grid-view/cagr-grid-view.component';
import { AwardsUnlockedComponent } from './awards-unlocked/awards-unlocked.component';
import { AgGridModule } from 'ag-grid-angular';
import { CustomHeaderComponent } from '../shared/grid-components/custom-header/custom-header.component';
import { CagrRoutingModule } from './cagr-routing.module';
import { ChartHorizontalComponent } from './chart-horizontal/chart-horizontal.component';
import { AwardsUnlockedBoxComponent } from './awards-unlocked/awards-unlocked-box/awards-unlocked-box.component';
import { ProductsServicesComponent } from './products-services/products-services.component';
import { RevenueTargetByAccountComponent } from './revenue-target-by-account/revenue-target-by-account.component';
import { FeedbackModule } from '../feedback/feedback.module';
// import {BonusSharedModule} from '../bonus-shared/bonus-shared.module'
import { CagrSellerComponent } from './cagr-seller/cagr-seller.component';
import { FiltersModule } from '../filters/filters.module';

@NgModule({
  imports: [CommonModule, NgbModule, SharedModule, AgGridModule.withComponents([CustomHeaderComponent]), CagrRoutingModule, FeedbackModule, FiltersModule],
  declarations: [
    CagrComponent,
    CagrChartViewComponent,
    CagrGridViewComponent,
    AwardsUnlockedComponent,
    ChartHorizontalComponent,
    AwardsUnlockedBoxComponent,
    ProductsServicesComponent,
    RevenueTargetByAccountComponent,
    CagrSellerComponent
  ],
  exports: [ CagrComponent,
    CagrChartViewComponent,
    CagrGridViewComponent,
    AwardsUnlockedComponent,
    ChartHorizontalComponent,
    AwardsUnlockedBoxComponent,
    ProductsServicesComponent,
    RevenueTargetByAccountComponent,
    CagrSellerComponent],
  providers: []
})
export class CagrModule {}
