<!--UBI Verbiage-->
<div id="territory-summary_1" class="app__tile__header" *ngIf="loadSummaryReportHeading">
  <div id="territory-summary_2" class="app__tile__heading">YTD Targets And Actuals by Incentive Territories</div>
  <div analytics-tracker="" class="app__tile__report" id="excel_summary_4">
    <mint-export-excel
      id="territory-summary_3"
      [title]="'Export Target And Actuals by Territory to Excel'"
      [reportFileName]="'CompActualsByIncentiveTerritory'"
      [downloadInactive]="showExportLoader"
      (notifyDownloadClick)="export($event)"
      class="app__export__excel"
    ></mint-export-excel>
  </div>
</div>

<!--RBI Verbiage-->
<div id="territory-summary_4" class="tab__content__header">
  <div id="territory-summary_5" *ngIf="!isADXResponsse && loaded && incentiveType === 'RBI'" class="tab__content__subhead">
    Showing all available territories in your plan
  </div>

  <mint-detail-dropdown
    id="territory-summary_6"
    class="dropdown__cmp"
    [itemCaption]="itemCaption"
    [subItemCaption]="subItemCaption"
    [items]="dropDownItems"
    [selectedItem]="selectedItem"
    [selectedSubItem]="selectedSubItem"
    (applyFilter)="onApplyFilter($event)"
  >
  </mint-detail-dropdown>
</div>
<div id="territory-summary_7" *ngIf="isADXResponsse && incentiveType === 'RBI'" class="tab__content__subhead">
  Disclaimer : This is a summary view providing either the top 100 performing accounts or top 10 performing territories that you are assigned to.
</div>
<div id="territory-summary_8" *ngIf="showRBQLegend" class="text-danger pod-quota-text">Note: This Metric also contains a Role-Based Quota (RBQ) of {{ podQuotaDisplayValue }}</div>
<mint-metric-multibar-chart *ngIf="loaded" id="territory-summary_4_1" class="multibar__chart" [dataset]="territoriesDataset" [chartHelpText]="chartHelpText" [containerId]="containerId">
</mint-metric-multibar-chart>
<div id="territory-summary_9" *ngIf="!loaded" class="content__loader content__loader-chart">
  <mint-content-loader-bar-chart id="territory-summary_19"></mint-content-loader-bar-chart>
</div>
<div id="territory-summary_10" class="legend">
  <div id="territory-summary_11" class="legend__item">
    <div id="territory-summary_12" class="legend__item__clr legend__item__clr-non"></div>
    <div id="territory-summary_13" class="legend__item__text">Revenue</div>
  </div>
  <!-- <div id="territory-summary_9" class="legend__item">
    <div id="territory-summary_10" class="legend__item__clr legend__item__clr-cus"></div>
    <div id="territory-summary_11" class="legend__item__text">Customer Adds Revenue</div>
  </div> -->
  <div id="territory-summary_14" class="legend__item">
    <div id="territory-summary_15" class="legend__item__clr legend__item__clr-tgt"></div>
    <div id="territory-summary_16" class="legend__item__text">Target</div>
  </div>
  <div id="territory-summary_17" class="legend__item" *ngIf="showRBQLegend">
    <div id="territory-summary_18" class="legend__item__text"><strong>RBQ: </strong>Role-Based Quota</div>
  </div>
</div>
<br id="territory-summary_19" />
<div id="territory-summary_20" class="legend__item" *ngIf="lastRefreshDate != null && incentiveType === 'UBI'">
  <div id="territory-summary_21" class="legend__item__text">Updated {{ lastRefreshDate }}</div>
</div>
