import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TBonusSummary } from '@mint-libs/context';

@Component({
  selector: 'div[mint-bonus-buckets-summary-header]',
  templateUrl: './bonus-buckets-summary-header.component.html',
  styleUrls: ['./bonus-buckets-summary-header.component.scss']
})
export class BonusBucketsSummaryHeaderComponent implements OnInit, OnChanges {
  @Input()
  bonusSummary: TBonusSummary;


  areLockBadgesEnabled: boolean = false;
  notes: string = '';
  isPercentageType: boolean = false;

  totalUnlocked: number = 0;
  currencyCode: string = '';
  bonusItems: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.bonusSummary.configuration && this.bonusSummary.configuration.bucketsSummary) {
      this.areLockBadgesEnabled = this.bonusSummary.configuration.bucketsSummary.areLockBadgesEnabled;
      this.notes = this.bonusSummary.configuration.bucketsSummary.notes;
      this.isPercentageType = this.bonusSummary.configuration.bucketsSummary.type === 'Percentage';
    }
    if (this.bonusSummary.attribute) {
      this.currencyCode = this.bonusSummary.attribute.currencyCode;
      this.bonusItems = this.bonusSummary.attribute.bonusItems;
      
      if (this.bonusSummary.attribute.totalUnlockedItems) {
        if (this.isPercentageType) {
          this.totalUnlocked = this.bonusSummary.attribute.totalUnlockedItems.percentValue;
        } else {
          this.totalUnlocked = this.bonusSummary.attribute.totalUnlockedItems.countValue;
        }
      }
    }

  }
}
