import { Pipe, PipeTransform } from '@angular/core';
import { Formatter } from '../utilities/formatter';

@Pipe({ name: 'ToLocalDate' })
export class ToLocalDatePipe implements PipeTransform {
  constructor(private formatter: Formatter) {}
  transform(value: any): string {
    const formatted = this.formatter.transformToLocalDateTz(value, 'MMM D, YYYY', false);
    return formatted;
  }
}
