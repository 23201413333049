import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
// import * as Ng1Services from '../../../core/hybrid/ng1-upgraded-providers';

@Component({
  selector: 'link-cell',
  template: `
    <a
      id="payoutSummary_link_{{ codePeriod }}"
      role="link"
      id="payoutSummary_link_1"
      role="link"
      href="javascript:void(0);"
      tabindex="0"
      (click)="invokeParentMethodToNavigate()"
      (keyup.enter)="invokeParentMethodToNavigate()"
      analytics-tracker
      [tracker]="'Payout_Summary_ViewReport'"
      [type]="'Click'"
      [element]="'Hyperlink'"
      [source]="'New_Dashboard_Payout_Summary_Widget'"
      class="ng-binding ag-grid__link__cell"
    >
      {{ codePeriod }}</a
    >
  `
})
export class PayoutReportLinkRenderer_Old implements ICellRendererAngularComp {
  params: any;

  codePeriod: any;

  agInit(params: any): void {
    this.params = params;
    this.codePeriod = params.data.codePeriod;
  }

  constructor() {}

  public invokeParentMethodToNavigate() {
    this.params.context.componentParent.navigateToDetailPayout(this.params.data);
  }

  refresh(): boolean {
    return false;
  }
}
