import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component';
import { SharedModule } from '../shared/shared.module';
import { ReportsRoutingModule } from './reports-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuotaDetailsReportComponent } from './quota-details/quota-details.component';
import { ReportsService } from './reports.service';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ClickOutsideModule } from 'ng-click-outside';
import { ProductReportComponent } from './product/product-report.component';
import { AccountReportComponent } from './account/account-report.component';
import { TerritoryReportComponent } from './territory/territory-report.component';
import { AttainmentByBucketComponent } from './attainment-by-bucket/attainment-by-bucket.component';
import { AttainmentByBucketGridComponent } from './attainment-by-bucket/grid/attainment-by-bucket-grid.component';
import { ReportsSharedModule } from './reports-shared/reports-shared.module';
import { AccountDynamicsCommittedGrowthComponent } from './account-dynamics-committed-growth/account-dynamics-committed-growth.component';
import { PayoutReportModule } from './payout/payout-report.module';

import { FiltersModule } from '../filters/filters.module';
@NgModule({
    imports: [CommonModule, ClickOutsideModule, FormsModule, SharedModule, ReportsRoutingModule, NgbModule, MatProgressSpinnerModule, MatIconModule, ReportsSharedModule, PayoutReportModule, FiltersModule],
  declarations: [
    ReportsComponent,
    QuotaDetailsReportComponent,
    TerritoryReportComponent,
    ProductReportComponent,
    AccountReportComponent,
    AttainmentByBucketComponent,
    AttainmentByBucketGridComponent,
    AccountDynamicsCommittedGrowthComponent
  ],
  exports: [ReportsComponent, MatProgressSpinnerModule],
  providers: [ReportsService]
})
export class ReportsModule {}
