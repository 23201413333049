<app-progress-bar id="account-report_0" class="sticky__progress__bar"></app-progress-bar>
<div id="account-report_1" class="note-container">
  <div id="account-report_2" *ngIf="isLargeUser" class="note">
    <strong id="account-report_3">Note: </strong> We are unable to process the large number of accounts you carry.
    Please select our recommended filters or select a few accounts or territories to
    begin
  </div>

  <div id="account-report_4" *ngIf="reportDisclaimer && this.filterService.selectedStandardTitle.FiscalYear.Key < 2024"
    class="note"><strong id="account-report_5">Note: </strong> Target and actuals received by MINT don’t contain
    account-level data for the UCR metric. To add the target and actuals, MINT has functionality to place these values
    against some accounts, which are randomly selected. Please rest assured that despite this, the total target and
    actuals are accurate, and your attainment and payout are correctly calculated. </div>
</div>
<div id="account-report_6" class="report-container">
  <div class="pull-right">
    <app-tour id="revenueTerritoryDetail_49_tour" [tourName]="'Compensation Actuals by Account Report'"></app-tour>
  </div>
  <div id="account-report_7" class="row" *ngIf="isLargeUser">
    <div id="account-report_8_a" class="col-xs-12">
      <mint-advance-search-filter id="account-report_9_a" [searchSuggestion]="searchSuggestion"
        [reportType]="revenueReportType" [isFilterTabActive]="activeTabId === filterTabId" (search)="search($event)"
        (filter)="filterAccounts($event)"></mint-advance-search-filter>
    </div>
    <div id="account-report_8_b" class="col-xs-12">
      <mint-report-tabs id="account-report_9_b" [tabs]="tabs" [activeTabId]="activeTabId"
        [reportType]="revenueReportType" (tabsChange)="tabsChange($event)"></mint-report-tabs>
    </div>
  </div>
  <div id="account-report_10" class="row mt-20">
    <div id="account-report_11" class="col-xs-12 col-md-6 vr-line">
      <div id="account-report_12" *ngIf="selectedPccChart" class="chart-title">Compensation Actuals by Metric</div>
      <div id="account-report_13" class="chart-container">
        <mint-metric-chart id="account-report_14" *ngIf="chartDataPccs" [dataset]="chartDataPccs"
          [doNotShowToolTip]="true" [chartHelpText]="'Revenue by Metric bar chart X axis is Metric Y axis is Revenue'"
          class="pcg__item__chart" [containerId]="'account-report-pcc-chart'" (chartClick)="filterSelectedPcc($event)">
        </mint-metric-chart>
        <mint-content-loader-bar-chart-vertical id="account-report_15" *ngIf="!chartDataPccs">
        </mint-content-loader-bar-chart-vertical>
      </div>
    </div>

    <div id="account-report_16" class="col-xs-12 col-md-6">
      <div id="account-report_17" *ngIf="selectedPccChart" class="chart-title">{{ selectedPccChart.desc }}</div>
      <div id="account-report_18" class="chart-container">
        <mint-metric-chart id="account-report_19" *ngIf="chartDataRevenue" [dataset]="chartDataRevenue"
          [doNotShowToolTip]="true"
          [chartHelpText]="'Revenue by accounts for ' + selectedPccChart.desc + ' bar chart X axis is accounts Y axis is Revenue'"
          class="pcg__item__chart" [containerId]="'account-report-actuals-chart'"></mint-metric-chart>
        <mint-content-loader-bar-chart-vertical id="account-report_20" *ngIf="!chartDataRevenue">
        </mint-content-loader-bar-chart-vertical>
      </div>
    </div>
  </div>
  <hr id="account-report_22" class="hr-line" />
  <div id="account-report_23" class="row">
    <mint-report-grid *ngIf="revenueData?.length" [columns]="columns" [data]="revenueData"
      (exportToExcel)="onRevenueExcelExport($event)" [excelDownloadStatus]="revenueExcelConfigs.excelDownloadStatus"
      [excelDownloadMessage]="revenueExcelConfigs.excelDownloadMessage" [excelTitle]="revenueReportType">
    </mint-report-grid>
    <div id="account-report_25" *ngIf="!revenueData?.length" class="content__loader">
      <mint-content-loader-grid id="account-report_26"></mint-content-loader-grid>
    </div>
  </div>
</div>