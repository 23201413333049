import { Action } from '@ngrx/store';
import { SharedContext } from './models/shared-context.model';

export enum SharedContextActionTypes {
  UpdateSharedContext = '[SharedContext] Update Shared Context'
}

export class UpdateSharedContext implements Action {
  readonly type = SharedContextActionTypes.UpdateSharedContext;
  constructor(public payload: SharedContext) {}
}

export type SharedContextActions = UpdateSharedContext;
