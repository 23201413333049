<div id="reports_1" class="reports_dropdown" style="border-bottom: 1px solid #a7a9ac; align-items: center">
  <div id="reports_2" class="pull-left">
    <!-- <div
      id="report-nav-menu"
      aria-label="report nav"
      class="btn-group"
      aria-expanded="false"
      aria-haspopup="true"
      role="combobox"
      [ngClass]="{ open: isDropdownOpen }"
      (click)="expand()"
      (keyup.enter)="expand()"
    >
      <button id="report-nav" type="button" class="btn btn__select" aria-label="activeTab.tabText combobox" style="display: flex;">
        <div id="reports_3" class="btn__select__txt">{{ activeTab.tabText }}</div>
        <div id="reports_4" class="icon icon-chevron-down"></div>
      </button>
      <ul id="reports_5" class="dropdown-menu" uib-dropdown-menu="" aria-labelledby="report-nav" (keydown)="onTabKeydown($event)">
        <li *ngFor="let widget of tabs; let i = index" [ngClass]="{ active: widget.active }" [attr.id]="'reports_6_' + i">
          <a
            [id]="'reports_7_' + i"
            class="dropdown__link"
            tabindex="-1"
            role="listitem"
            aria-setsize="tabs.length"
            aria-posinset="$index+1"
            (click)="loadReport(widget.id)"
            (keyup.enter)="loadReport(widget.id)"
            (keydown)="checkFocus($event, widget.id)"
            title="{{ widget.tabText }}"
            >{{ widget.tabText }} <span *ngIf="widget.active" class="icon icon-check"></span>
          </a>
        </li>
      </ul>
    </div> -->
    <div id="report-nav-menu" ngbDropdown class="d-inline-block" aria-controls="reports_5">
      <button role="button" type="button" class="btn btn__select" id="report-nav" ngbDropdownToggle>
        <div id="reports_3" role="textbox" aria-label="text box" class="btn__select__txt">{{ activeTab.tabText }}</div>
        <div id="reports_4" class="icon icon-chevron-down"></div>
      </button>
      <div id="reports_5" ngbDropdownMenu aria-labelledby="Reports dropdown" style="width:auto">
        <div *ngFor="let widget of tabs; let i = index" id="'reports_7_' + i" class="btn btn__hover"
          (click)="loadReport(widget.id)" (keydown.enter)="loadReport(widget.id)" [ngClass]="{ active: widget.active }"
          ngbDropdownItem analytics-tracker tabindex="-1" role="option" [tracker]="'Reports_Submenu_' + widget.tabText"
          [type]="'Click'" [element]="'dropdown-menu'" [source]="'RBIReports'">
          {{ widget.tabText }}
        </div>
      </div>
    </div>
  </div>
  <!-- <global-filter class="global__filter" id="reports_8" [config]="filterConfig" (apply)="applyFilter($event)"> </global-filter> -->
  <mint-filters class="global__filter" id="reports_8" [config]="filterConfig" (apply)="applyFilter($event)">
  </mint-filters>
</div>
<div id="reports_9" style="padding: 0 20px">
  <div [ngSwitch]="reportIndex">
    <app-progress-bar id="reports_11" class="sticky__progress__bar"></app-progress-bar>
    <mint-attainment-by-bucket-report *ngIf="reportIndex == 0"></mint-attainment-by-bucket-report>
    <mint-payout-report *ngIf="reportIndex == 1"></mint-payout-report>
    <mint-product-report *ngIf="reportIndex == 2"></mint-product-report>
    <mint-territory-report *ngIf="reportIndex == 3"></mint-territory-report>
    <mint-account-report *ngIf="reportIndex == 4"></mint-account-report>
    <mint-quota-detail-report *ngIf="reportIndex == 6"></mint-quota-detail-report>
    <mint-account-dynamics-committed-growth *ngIf="reportIndex == 7"></mint-account-dynamics-committed-growth>
  </div>
  <!-- <app-territory id="reports_10" *ngIf="reportIndex == 2"></app-territory> -->
</div>
<!-- <app-progress-bar id="Ubidashboard_8" class="sticky__progress__bar"></app-progress-bar>
<div id="reports_9" class="content-wrapper">
  <ng-container><mint-employee-info id="compensationPlan_emp_info" [tourName]="'Employee Information tour'"></mint-employee-info></ng-container>
  <div>
    <div style="float: left; height: 24px; vertical-align: middle;" (click)="hideTerritory = !hideTerritory">
      <span class="icon icon-plus" title="Open" *ngIf="hideTerritory"></span> <span class="icon icon-minus" title="Collapse" *ngIf="!hideTerritory"></span>
    </div>
    <div style="background-color:#6d6d6d;color: #fff;height: 24px; margin-left:20px; padding-left: 4px;">Incentive Territories By Metric</div>
  </div>
  <mint-plan-territory-details [hidden]="hideTerritory" id="compensationPlan_territorydetails" [hideProductsData]="true"></mint-plan-territory-details>
  <br />
  <div>
    <div style="float: left; height: 24px; vertical-align: middle;" (click)="hidePayoutDetails = !hidePayoutDetails">
      <span class="icon icon-plus" title="Open" *ngIf="hidePayoutDetails"></span> <span class="icon icon-minus" title="Collapse" *ngIf="!hidePayoutDetails"></span>
    </div>
    <div style="background-color:#6d6d6d;color: #fff;height: 24px; margin-left:20px; padding-left: 4px;">Revenue and Quota Details By Metric</div>
  </div>
  <payout-details [hidden]="hidePayoutDetails"></payout-details>
</div> -->