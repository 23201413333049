import { CagrRouteGuard } from './core/authentication/cagr-route-guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardV2RouteGuard } from './core/authentication/dashboard-v2-route-gaurd';
import { AIRouteGuard } from './core/authentication/azureincentives-route-guard';
import { FY19RouteGuard } from './core/authentication/fy19-route-gaurd';
import {
  rbiDashboardMatcher,
  ubiDashboardMatcher,
  ppiDashboardMatcher,
  ppiCompPlanMatcher,
  commonCompPlanMatcher,
  ubiReportsMatcher,
  ppiReportsMatcher,
  rbiReportsMatcher
} from './core/routing/url-matchers';
import { DemoComponent } from './demo/demo.component';
import { NotificationsComponent } from './notifications/notifications-all/notifications.component';
import { Mic2RouteGuard } from './core/authentication/mic2-route-gaurd';
import { DisclaimersComponent } from './disclaimers/disclaimers.component';
import { MqchRouteGuard } from './mqch/mqch-route-guard';
import { PlanActivationComponent } from './admin/plan-activation/plan-activation.component';
import { BonusRouteGuard } from './core/authentication/bonus-route-guard';
import { ConsolidatedBonusGuard } from './core/authentication/consolidated-bonus-guard';
import { HelpComponent } from './help/help.component';
import { SettingsComponent } from './settings/settings.component';
import { ENVIRONMENT_CONFIG } from '@mint-libs/common';
import { environment } from '../environments/environment';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'demo',
        component: DemoComponent,
        data: {
          title: 'Demo'
        }
      },
      {
        path: 'help',
        component: HelpComponent,
        data: {
          title: 'Help'
        }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
          title: 'Settings'
        }
      },
      {
        matcher: rbiDashboardMatcher,
        component: DashboardComponent,
        canActivate: [DashboardV2RouteGuard],
        data: {
          title: 'Dashboard'
        }
      },
      {
        matcher: ubiDashboardMatcher,
        loadChildren: () => import('./ubi-dashboard/ubi-dashboard.module').then(m => m.UbiDashboardModule),
        canActivate: [DashboardV2RouteGuard],
        data: {
          title: 'Dashboard'
        }
      },
      {
        matcher: ppiDashboardMatcher,
        loadChildren: () => import('./PPI/ppi-dashboard/ppi-dashboard.module').then(m => m.PpiDashboardModule),
        canActivate: [DashboardV2RouteGuard],
        data: {
          title: 'Dashboard'
        }
      },
      {
        matcher: rbiReportsMatcher,
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        data: {
          title: 'Detail Reports'
        }
      },
      {
        matcher: ubiReportsMatcher,
        loadChildren: () => import('./ubi-reports/ubi-reports.module').then(m => m.UbiReportsModule),
        data: {
          title: 'Detail Reports'
        }
      },
      {
        matcher: ppiReportsMatcher,
        loadChildren: () => import('./PPI/reports/ppi-reports.module').then(m => m.PpiReportsModule),
        data: {
          title: 'Detail Reports'
        }
      },
      {
        matcher: rbiReportsMatcher,
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        data: {
          title: 'Detail Reports'
        }
      },
      {
        matcher: commonCompPlanMatcher,
        loadChildren: () => import('./compensation-plan/compensation-plan.module').then(m => m.CompensationPlanModule),
        canActivate: [FY19RouteGuard],
        data: {
          title: 'My Compensation Plan'
        }
      },
      {
        matcher: ppiCompPlanMatcher,
        loadChildren: () => import('./PPI/ppi-compensation-plan/ppi-compensation-plan.module').then(m => m.PpiCompensationPlanModule),
        canActivate: [FY19RouteGuard],
        data: {
          title: 'My Compensation Plan'
        }
      },
      {
        path: 'accelerateazure',
        loadChildren: () => import('./accelerate-azure/accelerate-azure.module').then(m => m.AccelerateAzureModule),
        data: {
          title: 'Accelerate Azure'
        },
        canActivate: [AIRouteGuard]
      },
      {
        path: 'ytdDashboard',
        loadChildren: () => import('./ytd-dashboard/ytd-dashboard.module').then(m => m.YtdDashboardModule),
        data: {
          title: 'YTD Dashboard'
        }
      },
      {
        path: 'complianceDashboard',
        loadChildren: () => import('./compliance/compliance.module').then(m => m.ComplianceModule),
        canActivate: [FY19RouteGuard],
        data: {
          title: 'Compliance Dashboard'
        }
      },
      {
        path: 'ppiCompensationPlan',
        loadChildren: () => import('./PPI/ppi-compensation-plan/ppi-compensation-plan.module').then(m => m.PpiCompensationPlanModule),
        canActivate: [FY19RouteGuard],
        data: {
          title: 'Compensation Plan'
        }
      },
      {
        path: 'ppiDashboard',
        loadChildren: () => import('./PPI/ppi-dashboard/ppi-dashboard.module').then(m => m.PpiDashboardModule),
        canActivate: [FY19RouteGuard],
        data: {
          title: 'Dashboard'
        }
      },
      {
        path: 'cagr',
        loadChildren: () => import('./cagr/cagr.module').then(m => m.CagrModule),
        canActivate: [CagrRouteGuard],
        data: {
          title: 'Three Year Microsoft Cloud Growth Bonus'
        }
      },
      {
        path: 'bonus',
        canActivate: [BonusRouteGuard],
        loadChildren: () => import('./bonus/bonus.module').then(m => m.BonusModule),
        data: {
          title: 'Priorities Bonus'
        }
      },
      {
        path: 'bonusv2',
        canActivate: [ConsolidatedBonusGuard],
        loadChildren: () => import('./bonus-v2/bonus-v2.module').then(m => m.BonusV2Module),
        data: {
          title: 'My Bonus'
        }
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        data: {
          title: 'Notifications'
        }
      },
      {
        path: 'mic2Reports',
        loadChildren: () => import('./mic2-reports/mic2-reports.module').then(m => m.Mic2ReportsModule),
        canActivate: [Mic2RouteGuard],
        data: {
          title: 'Mic2 Reports'
        }
      },
      {
        path: 'planActivation',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        data: {
          title: 'Plan Activation'
        }
      },
      {
        path: 'featureAdmin',
        loadChildren: () => import('./feature-admin/feature-admin.module').then(m => m.FeatureAdminModule),
        data: {
          title: 'Feature Admin'
        }
      },
      {
        path: 'mqch',
        loadChildren: () => import('./mqch/mqch.module').then(m => m.MqchModule),
        canActivate: [MqchRouteGuard],
        data: {
          title: 'My Change History'
        }
      },
      {
        path: 'manageNotification',
        loadChildren: () => import('./manage-notification/manage-notification.module').then(m => m.ManageNotificationModule),
        data: {
          title: 'Manage Notification'
        }
      }
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'disclaimers',
    component: DisclaimersComponent,
    data: {
      title: 'Disclaimer'
    }
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: environment.graphDomain.includes('fed') })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
