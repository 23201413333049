<!-- <app-progress-bar id="dashboard_8" class="sticky__progress__bar"></app-progress-bar> -->

<div class="header__container" *ngIf="!toggleAdd">
  <div class="header__container-title">Manage Notifications</div>
  <div class="header__container-btn">
    <button class="header__container-btn-color" (click)="toggleNotificationPane('Add')">Create New Notification</button>
  </div>
</div>
<div class="breadcrumb_container" *ngIf="toggleAdd">
  <div class="breadcrumb-wrap">
    <span class="breadcrumb">
      <button (click)="toggleNotificationPane()" class="btn btn_breadcrumb btn_breadcrumb-manage">Manage Notifications</button>
    </span>
  </div>
  <div class="breadcrumb-wrap">
    <span class="breadcrumb-wrap-sep"><i class="icon icon-chevron-right"></i></span>
    <span class="breadcrumb"> <button class="btn_breadcrumb" [ngClass]="{ active__bold: toggleAdd }">Create New Notification</button> </span>
  </div>
</div>
<div class="tablecontainer" *ngIf="!toggleAdd">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">
          <div>
            Actions
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Quick Actions
            <button class="btn_icon" >
              <span class="icon" ></span>
            </button>
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Notification Type
            <button class="btn_icon" >
              <span class="icon" ></span>
            </button>
          </div>
        </th>
        <th scope="col">
          Status
          <button class="btn_icon" ><span class="icon"></span></button>
        </th>
        <th scope="col">
          <div class="div__md">
            Start Date
            <button class="btn_icon" >
              <span class="icon" ></span>
            </button>
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            End Date
            <button class="btn_icon" >
              <span class="icon" ></span>
            </button>
          </div>
        </th>
        <th scope="col">Added By (Alias)</th>
        <th scope="col">
          <div class="div__lg">Notification </div>
        </th>
        <th scope="col">
          <div class="div__lg">
            List Of Aliases
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Roles
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Plans
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Standard Titles
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Country(s)
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Business Segments
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Qualifier (1x)
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Qualifier (2x)
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let notify of allNotifications | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize; index as i"
        [ngClass]="{'expired_greyout': isNotificationExpired(notify)}"
      >
        <td>
          <button class="btn_icon" (click)="openModal(template, 'Delete', notify, i)"><span class="icon icon-delete"></span></button>
          <button class="btn_icon" (click)="editNotification(notify)"><span class="icon icon-edit"></span></button>
          <button class="btn_icon" (click)="cloneNotification(notify)"><span class="icon icon-copy"></span></button>
        </td>
        <td>
          <button class="btn-expire" [ngClass]="{'expired_greyout_icons disable-btn': isNotificationExpired(notify)}" (click)="openModal(template, 'Expire', notify, i)">Expire</button>
        </td>
        <td>
          <div style="margin-left: 0px;">
            <div class="inlineBlock" *ngIf="notify.notificationType != 0 && !isNotificationExpired(notify)">
              <svg width="16" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3944 19.2758H0.676147L10.5353 0.375L20.3944 19.2758ZM9.87802 7.9353V14.2356H11.1926V7.9353H9.87802ZM9.87802 16.7557H11.1926V15.4956H9.87802V16.7557Z" fill="#00AE56"/>
              </svg>
            </div>
            <div class="inlineBlock" *ngIf="notify.notificationType == 0 && !isNotificationExpired(notify)">
              <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#00AE56"/>
                </svg>
            </div>
            <div class="inlineBlock" *ngIf="notify.notificationType != 0 && isNotificationExpired(notify)">
              <svg width="16" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3944 19.2758H0.676147L10.5353 0.375L20.3944 19.2758ZM9.87802 7.9353V14.2356H11.1926V7.9353H9.87802ZM9.87802 16.7557H11.1926V15.4956H9.87802V16.7557Z" fill="#707070"/>
              </svg>
            </div>
            <div class="inlineBlock" *ngIf="notify.notificationType == 0 && isNotificationExpired(notify)">
              <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#707070"/>
                </svg>
            </div>
            <div style="display: inline-block;">
              {{notificationType[notify.notificationType]}}
            </div>
          </div>
        </td>
        <td >{{ notify.currentStatus }}</td>
        <td>{{ getLocalDate(notify.notificationStartDate) | date: 'MMM d, y, h:mm a' }}</td>
        <td>{{ getLocalDate(notify.notificationEndDate) | date: 'MMM d, y, h:mm a' }}</td>
        <td>{{ notify.createdBy }}</td>
        <td>{{ notify.notificationText | rowShortner: 100 }}</td>
        <td>{{ notify.listOfAlias | rowShortner: 100 }}</td>
        <td>{{ notify.roleNames | rowShortner: 100 }}</td>
        <td>{{ notify.planNames | rowShortner: 100 }}</td>
        <td>{{ notify.standardTitleNames | rowShortner: 100 }}</td>
        <td>{{ notify.countryNames | rowShortner: 100 }}</td>
        <td>{{ notify.businessSegments | rowShortner: 100 }}</td>
        <td>{{ notify.qualifier1Names | rowShortner: 100 }}</td>
        <td>{{ notify.qualifier2Names | rowShortner: 100 }}</td>
      </tr>
    </tbody>
  </table>
  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="allNotifications.length"></ngb-pagination>
</div>
<div *ngIf="toggleAdd" class="feature__container feature__container--outer">
  <div class="addnotificationHeader">Add New Notification</div>
  <div class="feature__container">
    <div>
      <div class="dropdown__container">
        <div class="dropdown__name">Notification Type</div>
         <div>
           <select name="notification type" id="notification_type" [disabled] = "isEditOn" [(ngModel)]="currNotificaiton.notificationType" class="form-control select-uwp">
              <option [ngValue]="0">Information</option>
              <option [ngValue]="1">Warning</option>
              <option [ngValue]="2">Error</option>
              <option [ngValue]="3">Alert</option>
              <option [ngValue]="4">Banner</option>
           </select>
         </div>
       </div>
      <div>
        <div class="date-input">
          <label class="input-header">Start Date & Time<span class="color__red">*</span> </label>
          <input class="input-field" [(ngModel)]="currNotificaiton.notificationStartDate" (change)="validateInputs(currNotificaiton)" type="datetime-local" />
        </div>
        <div class="date-input date-input-last">
          <label class="input-header">End Date & Time<span class="color__red">*</span></label>
          <input class="input-field" [(ngModel)]="currNotificaiton.notificationEndDate" (change)="validateInputs(currNotificaiton)" type="datetime-local" />
        </div>
      </div>
      <div>
        <div>
          <label class="input-header">Notification Message <span class="color__red">*</span></label>
        </div>
        <textarea name="notification message" id="notification_message_text" [disabled] = "isEditOn" [(ngModel)]="currNotificaiton.notificationText" (change)="validateInputs(currNotificaiton)" cols="180" rows="4"></textarea>
      </div>
      <div>
        <label class="input-header">Add URL Linking to your notification </label>
        <input class="input-field" [(ngModel)]="currNotificaiton.urlLink" [disabled] = "isEditOn" (change)="validateInputs(currNotificaiton)" type="text" />
      </div>
    </div>
  </div>
  <div  class="feature__container">
    <div >Recipients</div>
    <div>
      <div class="checkmark_container">
        <input class="checkmarked" type="checkbox" (change)="aliasCheckboxChange($event)" [checked]="showAlias" [disabled] = "isEditOn" />
        <label class="group_header">LIST OF ALIASES <span>( ; separated )</span> </label>
      </div>
      <div class="col-lg-12">
        <textarea
          rows="4"
          cols="50"
          class="input-field"
          [(ngModel)]="currNotificaiton.listOfAlias"
          [disabled]="!showAlias || isEditOn"
          placeholder="Enter semicolon seperated alias like 'jotap;artalukd;'. For all aliases use 'ALL'"
        >
        </textarea>
      </div>
      <div class="input-header">Note: all user aliases specified above will recieve popup.</div>
      <br />
      <div class="checkmark_container">
        <input class="checkmarked" [checked]="detailsFilter" [disabled] = "isEditOn" (change)="detailsFilterChange($event)" type="checkbox" /> 
        <label class="group_header">GROUPS</label>
      </div>
      </div>

    <div class="flex_row">
      <div>
        <label>{{ businessSegmentFilter.label | titlecase }} : </label>
      <ng-multiselect-dropdown
        [ngClass]="{'disable-btn': showAlias || isEditOn}"
        [placeholder]="businessSegmentFilter.title"
        [disabled]="showAlias || isEditOn"
        [settings]="dropdownSettings"
        [data]="businessSegmentFilter.list"
        [(ngModel)]="businessSegmentFilter.selected"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event, 'businessSegmentFilter')"
      >
      </ng-multiselect-dropdown>
      </div>
      <div>
       <label >{{ qualifier1Filter.label | titlecase }} : </label>
      <ng-multiselect-dropdown
        [ngClass]="{'disable-btn': showAlias || isEditOn}"
        [placeholder]="qualifier1Filter.title"
        [disabled]="showAlias || isEditOn"
        [settings]="dropdownSettings"
        [data]="qualifier1Filter.list"
        [(ngModel)]="qualifier1Filter.selected"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
      </div>
    </div>

    <div class="flex_row">
      <div>
        <label >{{ planFilter.label | titlecase }} : </label>
      <ng-multiselect-dropdown
        [placeholder]="planFilter.title"
        [ngClass]="{'disable-btn': showAlias || isEditOn}"
        [disabled]="showAlias || isEditOn"
        [settings]="dropdownSettings"
        [data]="planFilter.list"
        [(ngModel)]="planFilter.selected"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
      </div>
      <div>
        <label >{{ qualifier2Filter.label | titlecase }} : </label>
      <ng-multiselect-dropdown
        [placeholder]="qualifier2Filter.title"
        [ngClass]="{'disable-btn': showAlias || isEditOn}"
        [disabled]="showAlias || isEditOn"
        [settings]="dropdownSettings"
        [data]="qualifier2Filter.list"
        [(ngModel)]="qualifier2Filter.selected"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
      </div>
    </div>

    <div class="flex_row">
      <div>
        <label >{{ roleFilter.label | titlecase }} : </label>
      <ng-multiselect-dropdown
        [placeholder]="roleFilter.title"
        [ngClass]="{'disable-btn': showAlias || isEditOn}"
        [disabled]="showAlias || isEditOn"
        [settings]="dropdownSettings"
        [data]="roleFilter.list"
        [(ngModel)]="roleFilter.selected"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
      </div>
      <div>
        <label >{{ countryFilter.label | titlecase }} : </label>
      <ng-multiselect-dropdown
        [placeholder]="countryFilter.title"
        [ngClass]="{'disable-btn': showAlias || isEditOn}"
        [disabled]="showAlias || isEditOn"
        [settings]="dropdownSettings"
        [data]="countryFilter.list"
        [(ngModel)]="countryFilter.selected"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
      </div>
    </div>
      
    <div class="flex_row">
      <div>
        <label >{{ standardTitleFilter.label | titlecase }} : </label>
      <ng-multiselect-dropdown
        [placeholder]="standardTitleFilter.title"
        [ngClass]="{'disable-btn': showAlias || isEditOn}"
        [disabled]="showAlias || isEditOn"
        [settings]="dropdownSettings"
        [data]="standardTitleFilter.list"
        [(ngModel)]="standardTitleFilter.selected"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
      </div>
    </div>

    <div>
      <span>Note: All users matching all above category will be notified</span>
    </div>

    <div class="footer__splash">
      <button class="btn control_button control_button-primary" (click)="saveNotification(currNotificaiton)">{{ saveNotificationTitle }}</button>
      <button class="btn control_button" (click)="cancelSave()">Cancel</button>
    </div>
  </div>
</div>


<ng-template #template let-modal>
  <div class="modal-header">
    <button type="button" class="modal__close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg id="detail-dropdown_8" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          id="detail-dropdown_9"
          d="M9.70398 0.851639L5.561 5.00004L9.70398 9.14844L9 9.85164L4.85199 5.70904L0.703979 9.85164L0 9.14844L4.14297 5.00004L0 0.851639L0.703979 0.148438L4.85199 4.29104L9 0.148438L9.70398 0.851639Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <div class="modal-body" class="modal_body">
    {{modalMessage}}
  </div>
  <div class="modal-footer">
    <button class="footer__btn footer__btn-primary" (click)="actionConfirm(currNotificaiton)">Confirm</button>
    <button class="footer__btn footer__btn-cancel" (click)="modal.close('Cancel click')">Cancel</button> 
  </div>
</ng-template>