import { Params } from '@angular/router';
import { AccessibilityHelpComponent } from './accessibility-help/accessibility-help.component';
import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../core/network/data.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { CustomQueryEncoderHelper } from '../core/network/encode';
import { CachingService } from '../core/caching/caching.service';

@Injectable()
export class HelpService {
  featureHead: any;
  featureBody: any;

  Type = {
    //enum
    FAQ: 1,
    FAQVIDEOS: 2,
    TRAININGMATERIAL: 3,
    GLOSSARY: 4,
    AccessibilityHelp: 5
  };

  constructor(private servicesEndpoint: EndpointService, private dataService: DataService, private cachingService: CachingService) {}

  getHelpDetails(incentiveType) {
    var endPoint = this.servicesEndpoint.GetHelpDetails;
    endPoint.params = {
      incentiveType: incentiveType
    };
    return this.dataService.get(endPoint.uri, endPoint.params);
  }

  getFeatureIntroductionItems() {
    var endPoint = this.servicesEndpoint.GetFeatureIntroductionItems;
    return this.dataService.getWithGlobalFilters(endPoint.uri, endPoint.params);
  }

  addHelpContent(contents) {
    var endPoint = this.servicesEndpoint.AddHelpContent;
    endPoint.params = {
      contents: contents
    };
    return this.dataService.getWithGlobalFilters(endPoint.uri, endPoint.params);
    //this.clearCache(promise);
    //return promise;
  }

  addorUpdateFeatureIntroductionItems(featureIntroductionItems, configPairItems) {
    // var endPoint = this.servicesEndpoint.AddFeatureIntroductionItems;
    // endPoint.params = {
    //   FeatureIntroductionItems: featureIntroductionItems,
    //   ConfigPairItems: configPairItems,
    //   AppId: 1,
    //   ConfigKey: null
    // };
    // return this.dataService.postWithGlobalFilters(endPoint.uri, endPoint.params);
    //this.clearCache(promise);
    //return promise;
  }

  deleteFeatureIntroductionItem(id) {
    // var endPoint = this.servicesEndpoint.DeleteFeatureIntroductionItem;
    // endPoint.params = {
    //     id : id
    //   };
   
    // return this.dataService.delete(endPoint.uri, endPoint.params);
    //this.clearCache(promise);
    // return promise;
  }

  deleteHelpContent(id) {
    var endPoint = this.servicesEndpoint.DeleteHelpContent;
    endPoint.params = {
        id: id
    }
   
    return this.dataService.delete(endPoint.uri, endPoint.params);
   
  }

  clearCache(promise) {
    promise.then(function() {
      //Invalidate cache
      this.cachingService.clear();
    });
  }

  getType(type) {
    return this.Type[type];
  }

  setFeatureFlashItems(result) {
    (this.featureHead = result.FeatureHead), (this.featureBody = result.FeatureBody);
  }

  getFeatureFlashItems() {
    var endPoint = this.servicesEndpoint.GetFeatureIntroductionItems;
    return this.dataService.getWithGlobalFilters(endPoint.uri, endPoint.params);
  }

  getQuestionsByFeature(featureId: number) {
    let params = new HttpParams({ encoder: new CustomQueryEncoderHelper() });
    if (featureId != null) {
      params = params.append('featureId', featureId.toString());
    }
    return this.dataService.getWithGlobalFilters(this.servicesEndpoint.GetQuestionsByFeature.uri, params, null);
  }

  saveAnswersForFeature(featureId: number, questionWithAns: any[]) {
    const content = {
      FeatureId: featureId,
      FeedbackAnswers: questionWithAns
    };

    return this.dataService.postWithGlobalFilters(this.servicesEndpoint.SaveAnswersForFeature.uri, content);
  }
}
