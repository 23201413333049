export class DeterminerDetail {
  text: string;
  isApplicable: boolean;
  conditions: DeterminerCondition[];
}

export class DeterminerCondition {
  paylineSegment: string;
  text: string;
  rule: string;
  value: string;
  highlight: boolean;
  warn: boolean;
}
