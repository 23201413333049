import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CorrelatorService {
  currentId = '';
  sessionId = '';
  constructor() {
    this.currentId = Guid.create();
    this.sessionId = Guid.create();
   }

  getCorrelationId(): string {
    return this.currentId;
  }

  setCorrelationId (newId) {
    this.currentId = newId;
  }

  renewCorrelationId(): string {
    const tmp = Guid.create();
    this.setCorrelationId(tmp);
    return this.currentId;
  }

  getSessionId(): string {
    return this.sessionId;
  }
}


class Guid {
     /// format: N - 32 digits: 00000000000000000000000000000000
    ///        D - 32 digits separated by hyphens: 00000000-0000-0000-0000-000000000000 (default)
    static create(format = 'D'): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        const tmpGuid = (format === 'N')
                    ? s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4()
                    : s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        return tmpGuid;
    }

    static empty(format): string {
        if (format === 'N') {
            return '00000000000000000000000000000000';
        } else {
            return '00000000-0000-0000-0000-000000000000';
        }
    }
}
