import { Directive, ElementRef, HostListener, Input, Inject } from '@angular/core';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { LoggerService } from '@mint-libs/logging';
import { UserProfileService } from '../../core/context/user-profile.service';

@Directive({
  selector: '[analytics-tracker]'
})
export class AnalyticsTrackerDirective {
  @Input() tracker: string;
  @Input() type: string;
  @Input() element: string;
  @Input() source: string;
  @Input() value: string;
  loggingService: LoggerService;
  parameters: any;
  public sharedContext: SharedContext = new SharedContext();

  constructor(private el: ElementRef, loggingService: LoggerService, public userProfileService: UserProfileService, public sharedContextState: Store<SharedContext>) {
    this.loggingService = loggingService;
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  @HostListener('click') onClick() {
    this.parameters = { Value: this.value, Type: this.type, Element: this.element, Source: this.source };
    if (this.sharedContext && this.sharedContext.incentiveType) {
      this.parameters['IncentiveType'] = this.sharedContext.incentiveType;
      this.parameters['CompGroup'] = this.sharedContext.segment;
      this.parameters['StandardTitle'] = this.sharedContext.DefaultFilter.StandardTitle.Name;
      this.parameters['PlanName'] = this.sharedContext.DefaultFilter.StandardTitle.PlanName;
      this.parameters['IncentivePlanType'] = this.sharedContext.incentivePlanType;
      this.parameters['Country'] = this.sharedContext.country;
      this.parameters['PPASigned'] = this.sharedContext.isPPASigned;
      this.parameters['QuotaAcknowledged'] = this.sharedContext.isQuotaAcknowledged;
    }
    this.loggingService.trackClickEvent(this.tracker, this.parameters);
  }
}
