import { Injectable, Inject } from '@angular/core';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { FiltersService } from '../../filters/filters.service';
import { IncentiveType } from '../models/incentive-type.enum';
@Injectable({
  providedIn: 'root'
})
export class LegendService {
  legends = {
    neutral: '#00AE56',
    belowThreshold: '#EB5757',
    belowTarget: '#FFB900 ',
    belowExcellence: '#27AE60',
    aboveExcellence: '#2F80ED',
    rubiNeutral: '#000000',
    ocpMetric: '#999999'
  };

  legendDetails = {
    neutral: {
      text: 'Neutral',
      color: this.legends.neutral,
      cssClass: 'neutral'
    },
    belowThreshold: {
      text: 'Below Threshold',
      color: this.legends.belowThreshold,
      cssClass: 'blw__th'
    },
    belowTarget: {
      text: 'Above Threshold and Below Target',
      color: this.legends.belowTarget,
      cssClass: 'abv__th'
    },
    belowExcellence: {
      text: 'Above Target and Below Excellence Point',
      color: this.legends.belowExcellence,
      cssClass: 'abv__tgt'
    },
    aboveExcellence: {
      text: 'Above Excellence Point',
      color: this.legends.aboveExcellence,
      cssClass: 'abv__exc'
    },
    rubiNeutral: {
      test: 'RUBI_Neutral',
      color: this.legends.rubiNeutral,
      cssClass: 'rubi_neutral'
    },
    ocpMetric: {
      test: 'OCP_Metric',
      color: this.legends.ocpMetric,
      cssClass: 'ocp_metric'
    }
  };

  constructor(private filterService: FiltersService) {}

  getText(payline) {
    return this.getValueByPayline(payline, 'text');
  }
  getColor(payline) {
    return this.getValueByPayline(payline, 'color');
  }

  getCssClass(payline: any) {
    return this.getValueByPayline(payline, 'cssClass');
  }

  getValueByPayline(payline, valueType) {
    // If attainmentClass is present then simply return it
    const value = this.getAttainmentStatusForBucket(payline, valueType);
    if (value) {
      return value;
    }
    if (payline.isNeutral) {
      // Check to change color of OCP PPM metric on YTD dashboard screen
      return payline.weight === 0 ? this.legendDetails.ocpMetric[valueType] : this.legendDetails.neutral[valueType];
    }
    if ((!this.filterService.isFSA() && payline.incentiveType === IncentiveType.RUBI) || payline.incentiveType === IncentiveType.UBI) {
      return this.legendDetails.rubiNeutral[valueType];
    }
    if (payline.incentiveType === 'RBI' && payline.weight === 0) {
      return this.legendDetails.ocpMetric[valueType];
    }

    return this.getValue(payline.attainment, payline.threshold, payline.target, payline.excellence, payline.weightage, valueType);
  }

  getValue(attainment, threshold, target, excellence, weightage, valueType) {
    let value = this.legendDetails.belowThreshold[valueType]; // Default below threshold

    // Check on zero weight to assign OCP PPM Metric css class
    if (weightage === 0) {
      value = this.legendDetails.ocpMetric[valueType];
    } else if (attainment < threshold) {
      value = this.legendDetails.belowThreshold[valueType];
    } else if (attainment < target) {
      value = this.legendDetails.belowTarget[valueType];
    } else if (attainment < excellence) {
      value = this.legendDetails.belowExcellence[valueType];
    } else if (attainment >= excellence) {
      value = this.legendDetails.aboveExcellence[valueType];
    }

    return value;
  }

  getNeutralColor() {
    return this.legendDetails.neutral.color;
  }

  getNeutralClass() {
    return this.legendDetails.neutral.cssClass;
  }

  // TODO: Currently duplicated, to be refactored with dedicated effort
  getAttainmentStatusForBucket(payline, valueType) {
    if (payline.bucketType === 'Payout Adjustment Bucket') {
      if (Number(payline.payoutImpact.replace('%', '')) < 0) {
        return this.legendDetails.belowThreshold[valueType];
      } else {
        return this.legendDetails.belowExcellence[valueType];
      }
    } else if (payline.bucketType === 'Payout Gate Bucket') {
      if (Number(payline.attainment) < Number(payline.threshold)) {
        return this.legendDetails.belowThreshold[valueType];
      } else {
        return this.legendDetails.belowExcellence[valueType];
      }
    }
  }
}
