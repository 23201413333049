<div class="customHeaderLabel ag-header-cell-text" role="columnheader" [id]="'custom-header_1_' + params.column.colDef.field">
  {{ params.displayName }}
  <a
    href="javascript:void(0)"
    [attr.aria-label]="params.displayName"
    role="tooltip"
    [id]="'custom-header_2_' + params.column.colDef.field"
    *ngIf="params.column.colDef.tooltipField"
    [attr.data-text]="params.column.colDef.tooltipField"
  >
    <span [id]="'custom-header_3_' + params.column.colDef.field" class="icon icon-info icon-style"></span>
  </a>
</div>
