<div id="legends_1" [ngClass]="vertical ? 'legends-vertical' : 'legend'">
  <div id="legends_2" class="legend__item">
    <div id="legends_3" class="legend__item__clr"></div>
    <div id="legends_4" class="legend__item__text">Below threshold</div>
  </div>
  <div id="legends_5" class="legend__item">
    <div id="legends_6" class="legend__item__clr"></div>
    <div id="legends_7" class="legend__item__text">Above threshold and below target</div>
  </div>
  <div id="legends_8" class="legend__item">
    <div id="legends_9" class="legend__item__clr"></div>
    <div id="legends_10" class="legend__item__text">
      Above target and below excellence point
    </div>
  </div>
  <div id="legends_11" class="legend__item">
    <div id="legends_12" class="legend__item__clr"></div>
    <div id="legends_13" class="legend__item__text">Above excellence point</div>
  </div>
  <div id="legends_14" class="legend__item" *ngIf="showOCPLegend">
    <div id="legends_15" class="legend__item__clr"></div>
    <div id="legends_16" class="legend__item__text">No Paylines Associated</div>
  </div>
  <div id="legends_17" class="legend__item" *ngIf="showEstimatedEarningsLegend">
    <div id="legends_18" class="legend__item__clr"></div>
    <div id="legends_19" class="legend__item__text">Estimated Earnings</div>
  </div>
</div>
