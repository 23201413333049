import { Action } from '@ngrx/store';

// export const loadConfigurations = createAction('[Configuration] Load Configurations');

// export const loadConfigurationsSuccess = createAction('[Configuration] Load Configurations Success', props<{ data: any }>());

// export const loadConfigurationsFailure = createAction('[Configuration] Load Configurations Failure', props<{ error: any }>());

// export const getConfigData = createAction('[Configuration] Get Configuration on Success');

export enum ConfigurationActionTypes {
  LoadConfigurations = '[Configuration] Load Configurations',
  LoadConfigurationSuccess = '[Configuration] Load Configurations Success',
  LoadConfigurationFailure = '[Configuration] Load Configurations Failure',
  GetConfigData = '[Configuration] Get Configuration on Success'
}

export class LoadConfigurations implements Action {
  readonly type = ConfigurationActionTypes.LoadConfigurations;
  constructor() {}
}

export class LoadConfigurationSuccess implements Action {
  readonly type = ConfigurationActionTypes.LoadConfigurationSuccess;
  constructor(public data: any) {}
}

export class LoadConfigurationFailure implements Action {
  readonly type = ConfigurationActionTypes.LoadConfigurationFailure;
  constructor(public error: any) {}
}

export class GetConfigData implements Action {
  readonly type = ConfigurationActionTypes.GetConfigData;
  constructor() {}
}

export type ConfigurationActions = LoadConfigurations | LoadConfigurationSuccess | LoadConfigurationFailure | GetConfigData;
