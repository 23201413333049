<div class="quota-pcc">
  <h2 class="quota__header">Quota by Metric</h2>
  <!-- col-xs-4 col-xs-8 -->
  <div class=" text-right">
    <!-- &nbsp; -->
    <div *ngIf="isExportApplicable" _ngcontent-c5="" analytics-tracker="ExporttoExcelQuota" analytics-type="Click" class="app__tile__report" id="quotaAcknowledgement_export_1" tabindex="0">
      <mint-export-excel [title]="'Export Quota by Metric to Excel'" [reportFileName]="'QA'" (notifyDownloadClick)="exportToExcel($event, true)"> </mint-export-excel>
    </div>
  </div>
</div>
<div id="quotaAcknowledgement_45" class="table-responsive rbi-grid">
  <table id="quotaAcknowledgement_46" class="table app-table rbi-table" aria-label="Shows Quota for Plan Component Combinations data">
    <thead id="quotaAcknowledgement_47" role="rowgroup">
      <tr id="quotaAcknowledgement_48" class="rbi-table-rows" role="row">
        <th role="columnheader" id="qa-pcgnameitem" class="text-left myCompliance-header w_20" aria-label="Bucket">Bucket</th>
        <th role="columnheader" id="qa-pccnameitem" class="text-left myCompliance-header w_20" aria-label="Metric">Metric</th>
        <th role="columnheader" id="qa-pcctypeitem" class="text-left myCompliance-header w_10" aria-label="Quota Type">
          Quota Type
          <mint-tooltip id="qa-quotatypetooltip" [tipText]="'Baseline quota is based on a Q4 run rate.'"> </mint-tooltip>
        </th>
        <th role="columnheader" id="qa-pccitem{{ i }}" class="text-right myCompliance-header w_10" *ngFor="let title of titles; index as i">{{ title.Name }} {{ title.Text }}</th>
        <th *ngIf="isHalfYearlyQuotaSupported && !isHalfYearlyParticipation" role="columnheader" id="qa-pccitem-h2" class="text-right myCompliance-header w_10">H2 (Jan - Jun)</th>
      </tr>
    </thead>
    <tbody id="quotaAcknowledgement_49" role="rowgroup">
      <tr id="quotaAcknowledgement_50" *ngIf="notAvailable" class="app-table-rows rbi-table-rows">
        <td id="quotaAcknowledgement_51" colspan="7">No data available!</td>
      </tr>
      <tr id="quotaAcknowledgement_52-tr-{{ i }}" *ngFor="let pcc of PCCs; index as i" class="app-table-rows rbi-table-rows" role="row">
        <!--Display Name-->
        <th
          scope="row"
          role="rowheader"
          id="pcg-r-{{ i }}"
          class="text-left compliance-data th-left"
          *ngIf="pcc.PCGRowSpan != -1"
          [attr.rowspan]="pcc.PCGRowSpan"
          [attr.aria-label]="pcc.PCGName + 'Bucket'"
        >
          <span id="quotaAcknowledgement_53" [hidden]="!pcc.IsPIT" class="text-light">(<span id="quotaAcknowledgement_54" class="text-danger">*</span>) </span>{{ pcc.PCGName }}
        </th>
        <th scope="row" role="rowheader" id="pcc-r-{{ i }}" class="text-left compliance-data th-left" *ngIf="pcc.RowSpan == 2" [attr.rowspan]="pcc.RowSpan" [attr.aria-label]="pcc.Name + 'Metric'">
          <span id="quotaAcknowledgement_55" [hidden]="!pcc.IsPIT" class="text-light">(<span id="quotaAcknowledgement_56" class="text-danger">*</span>) </span>{{ pcc.Name
          }}<span id="quotaAcknowledgement_57" [hidden]="!pcc.isQuotaMultiplier" class="quota-multiplier"> ++</span>
        </th>
        <th scope="row" role="rowheader" id="pcc-r-{{ i }}" class="text-left compliance-data th-left" *ngIf="pcc.RowSpan != 2 && pcc.RowSpan != -1" [attr.aria-label]="pcc.Name + 'Metric'">
          <span id="quotaAcknowledgement_58" [hidden]="!pcc.IsPIT" class="text-light">(<span id="quotaAcknowledgement_59" class="text-danger">*</span>) </span>{{ pcc.Name
          }}<span id="quotaAcknowledgement_60_{{ i }}" [hidden]="!pcc.isQuotaMultiplier" class="quota-multiplier"> ++</span>
        </th>
        <!--Display metric type-->
        <th scope="row" role="rowheader" id="quotaAcknowledgement_61-td-{{ i }}" class="text-left compliance-data th-left" [attr.aria-label]="pcc.MetricType">{{ pcc.MetricType }}</th>
        <!--Display the metrics-->
        <td role="gridcell" tabindex="-1" id="quotaAcknowledgement_62-{{ i }}-{{ j }}" class="text-right compliance-data" *ngFor="let metric of pcc.Metrics; index as j">{{ metric.Value }}</td>
        <!--Display H2 quota not supported message-->
        <td *ngIf="isHalfYearlyQuotaSupported && !isHalfYearlyParticipation" class="text-right">
          <mint-half-yearly-quota-section></mint-half-yearly-quota-section>
        </td>
      </tr>
    </tbody>
  </table>

  <!--Export to excel table-->
  <div id="quotaAcknowledgement_68" class="col-xs-12 hide">
    <div id="quotatables_temp1" #quotatables_rbi_temp1>
      <table id="quotaAcknowledgement_69" class="table mic-table">
        <caption id="quotaAcknowledgement_70" class="sr-only">
          Quota for Metric data
        </caption>
        <thead id="quotaAcknowledgement_71">
          <tr id="quotaAcknowledgement_72">
            <th scope="col" id="qa-xl-pcgnameitem">Bucket</th>
            <th scope="col" id="qa-xl-pccnameitem">Metric</th>
            <th scope="col" id="qa-xl-pcctypeitem">Quota Type</th>
            <th scope="col" id="qa-xl-pccitem{{ i }}" *ngFor="let title of titles; index as i">{{ title.Name }} {{ title.Text }}</th>
            <th scope="col" id="qa-xl-pccqmitem" *ngIf="hasQuotaMultiplier">FY Quota Multiplier Amount</th>
            <th scope="col" id="qa-xl-pccitemunit">Units</th>
          </tr>
        </thead>
        <tbody id="quotaAcknowledgement_73">
          <tr id="quotaAcknowledgement_74" *ngFor="let pcc of PCCs; index as i">
            <!--Display Name-->
            <td scope="row" id="pcg-r-xl-{{ i }}" *ngIf="pcc.PCGRowSpan != -1" [attr.rowspan]="pcc.PCGRowSpan">{{ pcc.PCGName }}</td>
            <td scope="row" id="pcg-r-xl-{{ i }}" *ngIf="pcc.PCGRowSpan == -1"></td>
            <td scope="rowgroup" id="pcc-r-xl-{{ i }}" *ngIf="pcc.RowSpan == 2" [attr.rowspan]="pcc.RowSpan">{{ pcc.Name }}</td>
            <td scope="row" id="pcc-r-xl-{{ i }}" *ngIf="pcc.RowSpan != 2 && pcc.RowSpan != -1">{{ pcc.Name }}</td>
            <td scope="row" id="pcc-r-xl-{{ i }}" *ngIf="pcc.RowSpan == -1"></td>
            <!--Display metric type-->
            <td scope="row" id="quotaAcknowledgement_75" class="text-left">{{ pcc.MetricType }}</td>
            <!--Display the metrics-->
            <td scope="row" id="quotaAcknowledgement_76" class="text-right" *ngFor="let metric of pcc.Metrics">{{ metric.ValueNumeric }}</td>
            <!--Display Quota Multiplier-->
            <td scope="row" id="pcc-r-xl-{{ i }}" class="text-left" *ngIf="hasQuotaMultiplier">
              {{ pcc.MetricType == 'Total' ? pcc.totalQuotaMultiplier : pcc.MetricType == 'Recurring' ? pcc.recurringQuotaMultiplier : (pcc.nonRecurringQuotaMultiplier | customNumber) }}
            </td>

            <!--Display units-->
            <td id="pcc-r-xl-{{ i }}" class="text-left">{{ pcc.Units }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <p id="quotaAcknowledgement_63" [hidden]="!hasPIT" class="note-size">(<span class="text-danger">*</span>) Note: {{ pitInfo }}</p>

  <div class="mqchcontent" *ngIf="isMqchvisible">
    <div class="mqchcontent-heading">My Change History</div>
    <div class="mqchcontent-text">
      To track the changes to your quota and for additional details on changes, leverage the
      <a (click)="navigateToMqch()">My Change History feature here.</a>
    </div>
  </div>
</div>
