<div class="grid-container">
  <kendo-grid
    [data]="view"
    [skip]="skip"
    [pageSize]="pageSize"
    [sort]="sort"
    [filter]="gridFilters"
    filterable="menu"
    [sortable]="true"
    [pageable]="true"
    [navigable]="true"
    (pageChange)="pageChange($event)"
    (sortChange)="sortChange($event)"
    (filterChange)="filterChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <mint-export-excel
        *ngIf="exportToExcelAvailable"
        [title]="'Export Your ' + excelTitle + ' report to Excel'"
        class="pull-right"
        [downloadInactive]="excelDownloadStatus"
        (notifyDownloadClick)="onExcelExport($event)"
        [labelText]="excelDownloadMessage"
      ></mint-export-excel>
      <div class="grid-title">{{ gridTitle }}</div>
    </ng-template>
    <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [format]="col.format" [width]="col.width" [filterable]="col.filterable" [sortable]="col.sortable">
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <div [outerHTML]="col.columnName"></div>
      </ng-template>
      <ng-template *ngIf="col.filterable" kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
      </ng-template>
      <ng-template *ngIf="col.applyCustomPipe" kendoGridCellTemplate let-dataItem let-column="column">
        {{ deepValueAccess(dataItem, column.field) | customNumber }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
