import { Injectable, Output, EventEmitter, Inject } from '@angular/core';
import { DeterminerCondition, DeterminerDetail } from '../determiner-detail.model';
import { Payline } from 'src/app/shared/models/payline.model';
import { CustomNumberPipe } from '@mint-libs/common';
import { PaylineDeterminersDetail } from './payline-determiners-detail.model';
import { PaylineRange } from 'src/app/shared/models/payline-range.enum';
import { EarningPotentialService } from '../../../earning-potential.service';
import { Observable } from 'rxjs';
import { UserProfileService } from 'src/app/core/context/user-profile.service';
import { FiltersService } from '../../../../../filters/filters.service';


@Injectable()
export class DeterminerDetailHelper {
  @Output() acceleration: string;
  @Output() determinerAvailable = new EventEmitter<DeterminerDetail>();
  selectedSegment;
  constructor(private customNumberPipe: CustomNumberPipe, private earningPotentialService: EarningPotentialService,
    public userProfileService: UserProfileService,
    private filterService:FiltersService) {}

  getDeterminers(payline: Payline, selectedSegment = '') {
    this.selectedSegment = selectedSegment;
    const observable = new Observable(observer => {
      this.earningPotentialService.getEarningAttributes([payline.pcgId]).subscribe(earningAttributes => {
        const paylineDeterminersDetail = this.getPaylineDeterminersDetail(payline, earningAttributes);
        observer.next(paylineDeterminersDetail);
      });

    });
    return observable;
  }

  getPaylineDeterminersDetail(payline: Payline, earningAttributes: any[]) {
    const paylineDeterminersDetail = new PaylineDeterminersDetail();
    const paylineAttributes = earningAttributes.find(attribute => attribute.PCGId === payline.pcgId);
    if (!paylineAttributes) {
      return;
    }
    paylineDeterminersDetail.hurdleCapDetail = this.getHurdleCapDetail(payline, paylineAttributes);
    paylineDeterminersDetail.determinerThresholdToTarget = this.getDeterminerDetail(payline, paylineAttributes, PaylineRange.BetweenThresholdAndTarget);
    paylineDeterminersDetail.determinerTargetToExcellence = this.getDeterminerDetail(payline, paylineAttributes, PaylineRange.BetweenTargetAndExcellence);
    this.determinerAvailable.emit(paylineDeterminersDetail.determinerTargetToExcellence);
    return paylineDeterminersDetail;
  }

  private getHurdleCapDetail(payline: Payline, paylineAttributes: any): DeterminerDetail {
    let hurdleCapDetail;
    if (payline.isHurdleCapApplicable && paylineAttributes.HurdleCapList) {
      hurdleCapDetail = new DeterminerDetail();
      hurdleCapDetail.isApplicable = false;
      payline.setupLevel = '';
      hurdleCapDetail.conditions = [];
      paylineAttributes.HurdleCapList.forEach(hurdleCap => {
        const hurdleCapCondition: DeterminerCondition = new DeterminerCondition();
        const rule = this.getHurdleCapRuleText(hurdleCap.PCCName, hurdleCap.PCCAttainment);
        // Ignoring duplicates
        const exists = hurdleCapDetail.conditions.some(c => c.rule === rule);
        if (!exists) {
          hurdleCapCondition.rule = rule;
          hurdleCapCondition.value = hurdleCap.Status ? 'Met' : 'Not Met';
          hurdleCapCondition.warn = !hurdleCap.Status;
          if (hurdleCap.Status === false) {
            hurdleCapDetail.isApplicable = true;
          }
          hurdleCapDetail.conditions.push(hurdleCapCondition);
          payline.setupLevel = hurdleCap.DeterminingPCC_IdPCC !== null ? 'Metric' : 'Bucket';
        }
      });
      hurdleCapDetail.text = `Caps setup at ${payline.setupLevel} level. Your payout % in ${payline.pcgName} is capped at ${payline.hurdleCap} till you meet the following criteria:`;
    }
    return hurdleCapDetail;
  }

  getHurdleCapRuleText(pccName, pccAttainment) {
    const text = `${pccName} Attainment % > ${pccAttainment}%`;
    return text;
  }

  private getDeterminerDetail(payline: Payline, paylineAttributes: any, range: PaylineRange) {
    let determinerDetail;
    if (paylineAttributes.PCGPointData) {
      paylineAttributes.PCGPointData.forEach(pcgPoint => {
        if (pcgPoint.PointName === range) {
          determinerDetail = this.populateDetermineDetail(pcgPoint, payline);
        }
      }, this);
    }
    return determinerDetail;
  }

  private populateDetermineDetail(pcgPoint: any, payline: Payline): DeterminerDetail {
    const determinerDetail: DeterminerDetail = new DeterminerDetail();
    determinerDetail.isApplicable = pcgPoint.HasDeterminer;
    determinerDetail.text = this.getDeterminerText(payline.pcgName, pcgPoint.DepPCCName, pcgPoint.Type);
    if (pcgPoint.HasDeterminer && pcgPoint.DeterminerTable) {
      if (this.userProfileService.getIncentiveType() === 'RBI' && this.filterService.selectedFiscalYear >= 2022) {
      determinerDetail.conditions = this.getDeterminerLatestConditions(pcgPoint.DeterminerTable, pcgPoint.Type, pcgPoint.DepPCCName);
      determinerDetail.text = this.getLatestDeterminerText(pcgPoint.DeterminerTable);
    } else {
      determinerDetail.conditions = this.getDeterminerConditions(pcgPoint.DeterminerTable, pcgPoint.Type, pcgPoint.DepPCCName);
      }
    }
    return determinerDetail;
  }

  getDeterminerLatestConditions(determinerConditions, type, determinerPCC) {
    const Conditions = [];
    const determinerTable = {};
    determinerConditions.forEach((determinerRow, index, list) => {
        const segmentName = determinerRow['SegmentName'];
        if (!determinerTable[segmentName]) {
          determinerTable[segmentName] = [determinerRow];
        } else {
          determinerTable[segmentName].push(determinerRow);
        }
    });
    const keys = Object.keys(determinerTable).sort();
    let count = 0;
    for (const segment of keys) {
      const condition = new DeterminerCondition();
      let rule = '';
      const size = determinerTable[segment].length;
      determinerTable[segment].forEach((row, index, list) => {
        rule += row['DeterminerPCC'] +
        (count > 0 ? (' >= ' + this.customNumberPipe.transform(row['MinThreshold'] * 100) + '%') : (' < ' + this.customNumberPipe.transform(row['MaxThreshold'] * 100)) + '%');
        rule += (index !== size - 1) ? (' ' + row['Operator'] + ' ') : '';
        condition.value = this.customNumberPipe.transform(row.Multiplier);
      });
      condition.text = rule;
      condition.rule = rule;
      condition.paylineSegment = segment;
      count++;
    Conditions.push(condition);
    }
    return Conditions;
  }
  
  getDeterminerConditions(determinerTable, type, determinerPCC) {
    const Conditions = [];
    determinerTable.forEach((determinerRow, index, list) => {
      const condition = new DeterminerCondition();
      condition.paylineSegment = determinerRow.SegmentName;
      const ruleInfo = this.getDeterminerRuleInfo(determinerRow, list[index + 1], type, determinerPCC);
      condition.rule = ruleInfo.determinerRule;
      condition.text = ruleInfo.text;
      condition.value = this.customNumberPipe.transform(determinerRow.Multiplier);
      if (determinerRow.IsSelected) {
        this.acceleration = condition.value;
        condition.value += ' (Current)';
      }
      condition.highlight = determinerRow.IsSelected;
      Conditions.push(condition);
    });
    return Conditions;
  }

  getLatestDeterminerText(determinerDetail) {
    let determiner = 'metric(s) ';
      const determinerPCCs = new Set();
      determinerDetail.forEach(row => {
          if (!determinerPCCs.has(row['DeterminerPCC'])) {
            determinerPCCs.add(row['DeterminerPCC']);
            determiner += ' ' + row['DeterminerPCC'] + ',';
          }
      });
      determiner = determiner.substr(0, determiner.length - 1);
    const determinerText = `Your performance in this payline range is subject to attainment % in ${determiner}. The ratio listed here in the table is based on your current attainment and will not change, even while browsing other possible payline charts.`;
    return determinerText;
  }

  getDeterminerText(paylinePcgName, determinerPCCName: string, type) {
    let determiner = '';
    if (type === 1) {
      // Indicates determination is based on attainment of another PCC
      determiner = `"${determinerPCCName}" metric`;
    } else {
      // Indicates determination happens based on no. of PCCs having certain attainment under same PCG
      determiner = `metric(s) under "${paylinePcgName}"`;
    }
    const determinerText = `Your performance in this payline range is subject to attainment % in ${determiner}. The ratio listed here in the table is based on your current attainment and will not change, even while browsing other possible payline charts.`;
    return determinerText;
  }

  getDeterminerRuleInfo(determinerRow, nextDeterminerRow, type, determinerPCC) {
    let determinerRule = '';
    let text = 'attainment for ';
    let fragment = '';
    if (type === 1) {
      // Indicates determination is based on attainment of another PCC
      determinerRule = determinerPCC;
      text += determinerPCC + ' %verb% ';
      if (nextDeterminerRow) {
        if (determinerRow.PCCAttainment > 0) {
          fragment = ' >= ' + determinerRow.PCCAttainment + '% to ';
          determinerRule += fragment;
          text += fragment;
        }
        fragment = '< ' + nextDeterminerRow.PCCAttainment + '%';
        determinerRule += fragment;
        text += fragment;
      } else {
        fragment = determinerPCC + ' >= ' + determinerRow.PCCAttainment + '%';
        determinerRule = fragment;
        text += fragment;
      }
    } else {
      // Indicates determination happens based on no. of PCCs having certain attainment under same PCG
      determinerRule = determinerRow.NoOfPCC + ' Metrics having > ' + determinerRow.PCCAttainment + '% of Attainment';
      text = determinerRow.NoOfPCC + ' Metrics must be having > ' + determinerRow.PCCAttainment + '% of Attainment';
    }
    return { determinerRule, text };
  }
}
