<app-progress-bar class="sticky__progress__bar"></app-progress-bar>
<div class="header__container">
  <p class="header__container-title">
    Please select and add/remove the plans to activate/deactivate. Activated plans will be enabled in My Incentives
    application and the users associated with these plan(s) will have access to relevant
    data.
  </p>
</div>

<div class="filter__container">
  <input class="form-control filter__container-input" placeholder="Search Plans" [(ngModel)]=searchText
    (input)="onSearchTextChange($event.target.value)" />
  <select class="form-control filter__container-dropdown btnMargin" [(ngModel)]="selectedAction"
    placeholder="Select Action">
    <option *ngFor="let key of actionKeys" [value]="actions[key]" [label]="actions[key]"></option>
  </select>
  <button class="btn btn-dark btnMargin" (click)="onApply()" title="Apply" analytics-tracker="plan_activation"
    analytics-type="Click" analytics-element="Button" analytics-source="plan_activation_apply_button">
    Apply
  </button>
  <button class="btn filter__container-viewChanges btnPrimary" role="button" tabindex="0"
    (keyup.enter)="viewChanges(template)" (click)="viewChanges(template)"
    [disabled]="!modifiedPlans || modifiedPlans.length === 0" analytics-tracker="plan_activation" analytics-type="Click"
    analytics-element="Button" analytics-source="plan_activation_viewChanges_button">
    Preview {{modifiedPlans.length}} Changes and Save
  </button>
  <!-- <button class="btn filter__container-primary" (click)="onSave()" title="Save"
    [disabled]="!modifiedPlans || modifiedPlans.length === 0" analytics-tracker="plan_activation" analytics-type="Click"
    analytics-element="Button" analytics-source="plan_activation_save_button">
    Save
  </button> -->
</div>

<div class="tablecontainer">
  <kendo-grid [kendoGridBinding]="filteredPlans" [selectable]="selectableSettings" [kendoGridSelectBy]="getRowItem"
    [selectedKeys]="selectedPlans" (selectedKeysChange)="onSelectedKeysChange()" [height]="850">
    <kendo-grid-column [headerClass]="{ 'text-center': true }" [style]="{'text-align': 'center'}" [width]="60"
      [resizable]="false" [columnMenu]="false" showSelectAll="true">
      <ng-template kendoGridHeaderTemplate>
        <input type="checkbox" kendoGridSelectAllCheckbox [state]="selectionState"
          (selectAllChange)="onSelectAllChange($event)" />
      </ng-template>
      <ng-template kendoGridCellTemplate let-idx="rowIndex">
        <input [kendoGridSelectionCheckbox]="idx" />
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="planName" title="Plan" [width]="600"></kendo-grid-column>
    <kendo-grid-column field="fiscalYear" title="Fiscal Year" [width]="180"></kendo-grid-column>
    <kendo-grid-column title="Enable/Disable Plan" [width]="180">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          <div class="toggle__btn__container">
            <div role="button" class="toggle__btn" (keyup.enter)="toggleEnablePlan(dataItem)"
              (keyup.space)="toggleEnablePlan(dataItem)" tabindex="0">
              <input style="display: none;" class="toggle__btn__inp" type="checkbox" [checked]="dataItem.isActive"
                (click)="toggleEnablePlan(dataItem)" />
              <label class="toggle__btn__lbl" (click)="toggleEnablePlan(dataItem)"></label>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Enable/Disable Revenue" [width]="180">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          <div class="toggle__btn__container">
            <div role="button" class="toggle__btn" (keyup.enter)="toggleEnableRevenue(dataItem)"
              (keyup.space)="toggleEnableRevenue(dataItem)" tabindex="0">
              <input style="display: none;" class="toggle__btn__inp" type="checkbox" [checked]="dataItem.isRollOver"
                (click)="toggleEnableRevenue(dataItem)" />
              <label class="toggle__btn__lbl" (click)="toggleEnableRevenue(dataItem)"></label>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<ng-template #template let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Preview Changes ({{modifiedPlans.length}})</h4>
  </div>
  <div class="modal-body">
    <kendo-grid [kendoGridBinding]="modifiedPlans" [height]="600">
      <kendo-grid-column field="planName" title="Plan" [width]="450"></kendo-grid-column>
      <kendo-grid-column field="isActiveInitial" title="IsActive Initial" [width]="150"></kendo-grid-column>
      <kendo-grid-column field="isActive" title="IsActive Current" [width]="150"></kendo-grid-column>
      <kendo-grid-column field="isRollOverInitial" title="IsRollOver Initial" [width]="150"></kendo-grid-column>
      <kendo-grid-column field="isRollOver" title="IsRollOver Current" [width]="150"></kendo-grid-column>
      <kendo-grid-column [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button class="btn btn-cancel" (click)="removeItem(dataItem)">Remove</button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark btnMargin" title="Back" (click)="onBack()">Back</button>
    <button class="btn btnPrimary" (click)="onSave()" title="Save"
      [disabled]="!modifiedPlans || modifiedPlans.length === 0" analytics-tracker="plan_activation"
      analytics-type="Click" analytics-element="Button" analytics-source="plan_activation_save_button">Save
      Changes</button>
  </div>
</ng-template>