export const notificationSvgIcons = {
  iconError:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path fill="none" d="M0 0h24v24H0V0z"/>' +
    '<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"',
  iconErrorHeader:
    '<p style="font-family: Segoe UI; font-size: 14px; font-weight: 500; line-height: 1.1; display: block; margin-block-start: 5px; margin-left: 10px; margin-top: 5px; margin-bottom: 5px;"> Error </p>',
  iconAlert:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"',
  iconAlertHeader:
    '<p style="font-family: Segoe UI; font-size: 14px; font-weight: 500; line-height: 1.1; display: block; margin-block-start: 5px; margin-left: 10px; margin-top: 5px; margin-bottom: 5px;"> Alert </p>',
  iconInfo:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path fill="none" d="M0 0h24v24H0V0z"/>' +
    '<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8h-2V7h2v2z"',
  iconInfoHeader:
    '<p style="font-family: Segoe UI; font-size: 14px; font-weight: 500; line-height: 1.1; display: block; margin-block-start: 5px; margin-left: 10px; margin-top: 5px; margin-bottom: 5px;"> Information </p>',
  iconWarning:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"',
  iconWarningHeader:
    '<p style="font-family: Segoe UI; font-size: 14px; font-weight: 500; line-height: 1.1; display: block; margin-block-start: 5px; margin-left: 10px; margin-top: 5px; margin-bottom: 5px;"> Warning </p>',
  iconBanner:
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" width="24" height="24">' +
    '<defs><path id="a" d="M0 0h24v24H0V0z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath>' +
    '<path clip-path="url(#b)" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm5 16H7v-2h10v2zm-6.7-4L7 10.7l1.4-1.4 1.9 1.9 5.3-5.3L17 7.3 10.3 14z"',
  iconBannerHeader:
    '<p style="font-family: Segoe UI; font-size: 14px; font-weight: 500; line-height: 1.1; display: block; margin-block-start: 5px; margin-left: 10px; margin-top: 5px; margin-bottom: 5px;"> Banner </p>',
  iconColorGreen: 'fill="#00AE56"/></svg>',
  iconColorGrey: 'fill="#707070"/></svg>'
};
