import { Inject, Injectable } from '@angular/core';
import { EventName, TelemetryService } from './telemetry.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
// import { selectAuthInfo, selectUserRoleInfo } from '@mint-libs/authentication';
import { ContextState, selectUserProfileState, CorrelatorService } from '@mint-libs/context';
import { SharedContext, selectSharedContextState, ENVIRONMENT_CONFIG } from '@mint-libs/common';
// import { selectUserProfileState } from '../user-profile/state/user-profile.selectors';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  userAlias: string;
  isSeller: boolean;
  isNonParticipantManager: boolean;
  isManager: boolean;
  isFCA: boolean;
  isAdministrator: boolean;
  isSuperAdmin: boolean;
  incentiveType: string;

  constructor(@Inject(ENVIRONMENT_CONFIG) private envConfig: any, protected telemetry: TelemetryService, protected contextState: Store<ContextState>,
    protected sharedContext: Store<SharedContext>, protected router: Router, private correlator: CorrelatorService) {
    // this.appState.select(selectAuthInfo).subscribe(authInfo => {
    //   if (!authInfo || !authInfo.userInfo) {
    //     return;
    //   }
    //   const userName: string = authInfo.userInfo.userName;
    //   this.userAlias = userName.substring(0, userName.indexOf('@'));
    // });
    // this.getRoleInfo();
    // if (!this.router.url.includes('/admin')) {
    this.getUserProfileData();
    // }
  }

  trackPageView(name: string = null, props: any = null) {
    const featureName = name || this.router.url.substring(1);
    props = props || {};
    props.name = featureName;
    props.url = window.location.origin;
    props = this.getProps(props);

    this.telemetry.trackPageview(featureName, props);
  }

  trackException(message: string, stack: string = null, severity = null, props: any = null) {
    const error = { name: 'exception', message: message, stack: stack };
    this.trackError(error, severity, props);
  }

  logInformation(message) {
    try {
      message.sessionID = this.correlator.getSessionId();
      this.telemetry.trackEvent('event', 'info', message);
    } catch (e) {
      //swallow the exception
    }
  }

  trackError(error: Error, severity: string = null, props: any = null) {
    props = this.getProps(props);
    this.telemetry.trackException(error, severity, props);
  }

  trackEvent(eventName: string, props: any = null, isLogUtp?: boolean) {
    props = this.getProps(props);
    const featureName = this.router.url.substring(1);
    this.telemetry.trackEvent(featureName, eventName, props, isLogUtp);
  }

  trackClickEvent(name: string, props: any = null) {
    props = this.getProps(props);
    const featureName = this.router.url.substring(1);
    const eventName = this.getUtpEventNameByElement(props.Element);
    this.telemetry.trackClickEvent(featureName, name, eventName, props);
  }

  startTrackPage(name) {
    const featureName = this.router.url.substring(1);
    this.telemetry.startTrackPage(featureName, name, this.getProps({}));
  }

  stopTrackPage(name) {
    const featureName = this.router.url.substring(1);
    this.telemetry.stopTrackPage(featureName, name, this.getProps({}));
  }

  getUtpEventNameByElement(element): EventName {
    let utpEventName = EventName.ButtonClick;

    if (element === 'Hyperlink') {
      utpEventName = EventName.LinkClicked;
    } else if (element === 'Tab') {
      utpEventName = EventName.TabChange;
    } else if (element === 'Dropdown') {
      utpEventName = EventName.DropdownChange;
    }

    return utpEventName;
  }

  getProps(props: any = null) {
    props = props || {};
    Object.assign(props, {
      UserAlias: this.userAlias,
      IsFCA: this.isFCA,
      IsAdministrator: this.isAdministrator,
      IsSuperAdmin: this.isSuperAdmin
    });
    if (!this.router.url.includes('/admin')) {
      Object.assign(props, {
        IsSeller: this.isSeller,
        IncentiveType: this.incentiveType,
        IsManager: this.isManager,
        IsNonParticipantManager: this.isNonParticipantManager
      });
    }
    Object.assign(props, {
      XCV: this.correlator.getCorrelationId(),
      SessionId: this.correlator.getSessionId(),
      ComponentId: '2f537d0c-93ec-4270-9ec6-54d82bf62f93',
      ComponentName: 'Mint - Web',
      Service: 'Seller Experience',
      ServiceOffering: 'Finance',
      ServiceLine: 'Incentive Compensation',
      IctoId: '12863',
      EnvironmentName: this.envConfig.environment,
      AiAppKey: this.envConfig.appInsightsKey,
    });
    return props;
  }

  // getRoleInfo() {
  //   this.appState.select(selectUserRoleInfo).subscribe(roleInfo => {
  //     if (!roleInfo) {
  //       return;
  //     }
  //     this.isFCA = roleInfo.isFCA;
  //     this.isAdministrator = roleInfo.isAdminPersona();
  //     this.isSuperAdmin = roleInfo.isSuperAdmin;
  //   });
  // }

  getUserProfileData() {
    const userProfileState = this.contextState.select(selectUserProfileState);
    userProfileState.subscribe(state => {
      if (!state) {
        return;
      }
      this.userAlias = state.userAlias;
      this.incentiveType = state.incentiveType;
      this.isSeller = state.isSeller;
      this.isManager = state.isManager;
      this.isNonParticipantManager = state.isNonParticipantManager;
    });
    this.sharedContext.select(selectSharedContextState).subscribe((state: SharedContext) => {
      if (!state) {
        return;
      }
      this.isAdministrator = state.isAdmin;
      this.isSuperAdmin = state.isSuperAdmin;
      this.isFCA = state.IsFCA;
    });
  }
}
