<app-progress-bar id="account-report_0" class="sticky__progress__bar"></app-progress-bar>
<div id="attainment-by-bucket_1" class="report-container">
  <div id="attainment-by-bucket-grid_4" class="pull-right">
    <app-tour id="attainment-by-bucket-grid_5" [tourName]="'Attainment by Bucket Report'"></app-tour>
  </div>
  <div id="attainment-by-bucket_2" class="row mt-20">
    <div id="attainment-by-bucket_3" class="col-xs-12 col-md-6 vr-line pos-int">
      <div id="attainment-by-bucket_4" *ngIf="selectedPcg" class="chart-title">Attainment by Bucket</div>
      <div id="attainment-by-bucket_5" class="chart-container">
        <mint-metric-chart
          id="attainment-by-bucket_6"
          role="application"
          *ngIf="chartDataPcgs"
          [dataset]="chartDataPcgs"
          [yAxisMaxLimit]="pcgChartYmax"
          [doNotShowToolTip]="false"
          [pcgTooltipDimension]="pcgTooltipDimension"
          [chartType]="chartType"
          [chartHelpText]="'Attainment by Bucket bar chart. X axis is Bucket Y axis is Attainment'"
          class="pcg__item__chart"
          [containerId]="'attainment-by-bucket-report-pcg-chart'"
          (chartClick)="filterSelectedPcg($event)"
        ></mint-metric-chart>
        <mint-content-loader-bar-chart-vertical id="attainment-by-bucket_7" *ngIf="!chartDataPcgs"></mint-content-loader-bar-chart-vertical>
      </div>
      <mint-legends id="attainment-by-bucket_8" [showOCPLegend]="showOCPLegend"></mint-legends>
    </div>

    <div id="attainment-by-bucket_9" class="col-xs-12 col-md-6 pos-int">
      <div id="attainment-by-bucket_10" *ngIf="selectedPcg" class="chart-title">Attainment by Metric of {{ selectedPcg.desc }}</div>
      <div id="attainment-by-bucket_11" class="chart-container">
        <mint-metric-chart
          id="attainment-by-bucket_12"
          *ngIf="chartDataPccs"
          [dataset]="chartDataPccs"
          [yAxisMaxLimit]="pcgChartYmax"
          [doNotShowToolTip]="false"
          [pcgTooltipDimension]="pccTooltipDimension"
          [chartType]="chartType"
          [chartHelpText]="'Attainment by Metric for ' + selectedPcg.desc + ' bar chart. X axis is Metric Y axis is Attainment'"
          class="pcg__item__chart"
          [containerId]="'attainment-by-bucket-report-pcc-chart'"
        ></mint-metric-chart>
        <mint-content-loader-bar-chart-vertical id="attainment-by-bucket_13" *ngIf="!chartDataPccs"></mint-content-loader-bar-chart-vertical>
      </div>
    </div>
  </div>
  <hr id="attainment-by-bucket_15" class="hr-line" />
  <div id="attainment-by-bucket_16" class="grid-container">
    <div id="attainment-by-bucket_17" class="grid-toolbar">
      <div id="attainment-by-bucket_18" class="pull-left">
        <div id="attainment-by-bucket_19" class="grid-title">
          Detailed Bucket Attainment
        </div>
      </div>
      <div id="attainment-by-bucket_20" class="pull-right">
        <mint-export-excel
          id="attainment-by-bucket_21"
          [title]="'Export Your Attainment by bucket report to Excel'"
          class="pull-right"
          [downloadInactive]="showDownloadProgressLoader"
          (notifyDownloadClick)="onExcelExport()"
          [labelText]="exportToExcelLabel"
        ></mint-export-excel>
      </div>
    </div>
    <div id="attainment-by-bucket_22" >
        <button id="attainment-by-bucket_24" class="clear-btn" (click)="toggleExpandAll()">{{ isExpandAll ? 'Collapse All' : 'Expand All' }}</button>
        <ng-container *ngFor="let pcg of gridData; let i = index">
          <div id="attainment-by-bucket_25_{{ i }}" class="collapsible-row">
           <div class="collapsible-row-header">
              <button
                id="attainment-by-bucket_26_{{ i }}"
                [attr.aria-expanded]="pcg.isPCGRowExpanded"
                [attr.aria-label]="pcg.pcgName"
                class="clear-btn"
                (click)="pcg.isPCGRowExpanded = !pcg.isPCGRowExpanded"
              >
                <div id="attainment-by-bucket_27_{{ i }}" class="icon icon-chevron-right" *ngIf="pcg.isPCGRowExpanded"></div>
                <div id="attainment-by-bucket_28_{{ i }}" class="icon icon-chevron-down" *ngIf="!pcg.isPCGRowExpanded"></div>
              </button>
              <div id="attainment-by-bucket_29_{{ i }}">{{ pcg.pcgName }}
                <mint-tooltip
                  *ngIf="pcg.weightage == 0"
                  id="attainment-by-bucket_100_{{ i }}"
                  [tipText]="determinersText"
                  [orientation]="determinerTipOrientation"
                  [size]="tooltipSize"
                ></mint-tooltip>
              </div>
           </div>
            <div id="attainment-by-bucket_29_{{ i }}" [ngClass]="{'show': pcg.isPCGRowExpanded, 'hide': !pcg.isPCGRowExpanded}">
              <mint-attainment-by-bucket-grid id="attainment-by-bucket_30" [columns]="pcg.columns" [data]="pcg.pcgGridData"></mint-attainment-by-bucket-grid>
            </div>
          </div>
        </ng-container>
    </div>
    <div id="attainment-by-bucket_18" *ngIf="!gridData?.length" class="content__loader">
      <mint-content-loader-grid id="attainment-by-bucket_19"></mint-content-loader-grid>
    </div>
  </div>
</div>
