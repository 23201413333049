<div id="timeline_9" class="container-fluid" *ngIf="timelineList?.length > 0">
  <div id="timeline_10" class="row">
    <div id="timeline_11" class="col-md-12">
      <!-- <app-month-view id="timeline_12_1" [isYTD]="false" (monthClick)="scrollSmoothTo($event)"></app-month-view> -->
      <div id="monthView_1" class="month__view" #monthView>
        <div class="month__list__container" id="monthView_4" #monthListContainer>
          <ul class="listInline month__list" id="monthView_5" #monthList>
            <li class="month__item" id="monthView_{{ month.id }}" *ngFor="let month of months">
              <a
                role="button"
                tabindex="0"
                class="month__link"
                id="monthView_href_{{ month.id }}"
                (click)="monthEventClick(month)"
                (keyup.enter)="monthEventClick(month)"
                [ngClass]="{ active: month.isActive, inActiveBelow: !month.isBelowCurrentMonth }"
                analytics-tracker
                [tracker]="'MQCHMonth_Click'"
                [type]="'Click'"
                [element]="'Hyperlink'"
                [source]="month"
              >
                {{ month.monthName }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div id="timeline_37" *ngIf="timelineList?.length > 0"></div>
      <div class="timeline__container" id="timeline__container" #timeline__container>
        <div class="timeline__connector" id="timeline__connector" #timeline__connector></div>
        <div class="timeline__item" *ngFor="let item of timelineList" id="{{ item.id }}" [ngClass]="{ 'timeline__item-event': !item.isMonth }">
          <div
            class="timeline__up"
            (click)="moveToDate(item)"
            [ngClass]="{ 'timeline__up-month': item.isUpSideMonth, 'timeline__up-count': item.isUpSideCount, dateActive: !item.isMonth && item.isActive, inActive: !item.isActive }"
          >
            <div [ngClass]="{ timeline__month: item.isUpSideMonth, timeline__count: item.isUpSideCount, active: item.isActive }" *ngIf="item.isMonth">{{ item.monthDataUp }}</div>
            <div class="timeline__event__list" *ngIf="!item.isMonth && item.eventReason.length > 0 && item.isEventUp">
              <div class="timeline__date" [ngClass]="{ 'active-date': item.isActive }">{{ item.eventDate }}</div>
              <ul>
                <li *ngFor="let reason of item.eventReason">{{ reason }}</li>
              </ul>
            </div>
          </div>
          <div
            class="timeline__down"
            (click)="moveToDate(item)"
            [ngClass]="{ 'timeline__down-count': item.isUpSideMonth, 'timeline__down-month': item.isUpSideCount, dateActive: !item.isMonth && item.isActive, inActive: !item.isActive }"
          >
            <div [ngClass]="{ timeline__count: item.isUpSideMonth, timeline__month: item.isUpSideCount, active: item.isActive }" *ngIf="item.isMonth">{{ item.monthDataDown }}</div>
            <div class="timeline__event__list" *ngIf="!item.isMonth && item.eventReason.length > 0 && !item.isEventUp">
              <div class="timeline__date" [ngClass]="{ 'active-date': item.isActive }">{{ item.eventDate }}</div>
              <ul>
                <li *ngFor="let reason of item.eventReason">{{ reason }}</li>
              </ul>
            </div>
          </div>
          <div class="timeline__badge" [ngClass]="{ activebadge: item.isActive }"></div>
        </div>
      </div>
    </div>
  </div>
</div>
