export class TooltipTextPayoutSummary_Old {
  CodePeriod =
    'The fiscal quarter of payout. There may be multiple line items if you changed roles or if an additional payment was made by exception. ' +
    'If there are multiple payments in a quarter, you’ll be able to see multiple records on expanding the quarter expand icon.';
  UBICodePeriod =
    'The semester of payout. There may be multiple line items if you changed roles within UBI or if an additional payment was made by exception. ' +
    "If there are multiple payments in a semester, you'll be able to see multiple records by clicking on the expansion icon.";
  PPICodePeriod =
    'The semester of payout. There may be multiple line items if you changed roles within PPI or if an additional payment was made by exception. ' +
    "If there are multiple payments in a semester, you'll be able to see multiple records by clicking on the expansion icon.";
  YTDEarnings = 'YTD amount earned before deducting previous payments, adjustments, or recoveries.';
  PrevQuarterPayout = 'Includes all core incentive plan payments paid in previous quarters.';
  UBIPrevPayout = 'Includes UBI payments already paid this fiscal year.';
  PPIPrevPayout = 'Includes PPI payments already paid this fiscal year.';
  YtdPaymentAdjustmentAndRecoveries = 'Total amount of adjustments and recoveries for the given role and for the quarter specified.';
  UBIYtdAdj = 'Total amount of adjustments and recoveries for the given role and for the semester specified (H1 or H2).';
  PPIYtdAdj = 'Total amount of adjustments and recoveries for the given role and for the semester specified (H1 or H2).';
  NetPaymentAmount = 'YTD Earnings less any YTD adjustments and recoveries. This will be marked as “projected” in the event that the quarter’s payment cycle has not yet completed.';
  UBIPPINetPaymentAmount = 'YTD Earnings less any YTD adjustments and recoveries. This will be marked as “projected” in the event that the semester’s payment cycle has not yet completed.';
  CalcDate =
    'The date the calculation is locked for submitting to local payroll.  When a date is provided, it signifies a payment is approved and ready to be issued. Actual payout dates follow local subsidiary schedules. ' +
    'When this field shows "Pending payment issuance," it signifies that a payout has not been made or due to 0 payment for the quarterly advances. ' +
    'Other reasons maybe due to incompliance with unsigned PPA, residing in a subsidiary that follows a semi-annual payment schedule, or other eligibility requirements as outlined in the RBI Business Rules.';
  UBICalcDate =
    'The date the calculation is locked for submitting to local payroll.  When a date is provided, it signifies a payment is approved and ready to be issued. Actual payout dates follow local subsidiary schedules. ' +
    'When this field shows "Pending payment issuance," it signifies that a payout has not been made or due to 0 payment for the quarterly advances. ' +
    'Other reasons maybe due to incompliance with unsigned PPA, residing in a subsidiary that follows a semi-annual payment schedule, or other eligibility requirements as outlined in the UBI Business Rules.';
  PPICalcDate =
    'The date the calculation is locked for submitting to local payroll.  When a date is provided, it signifies a payment is approved and ready to be issued. Actual payout dates follow local subsidiary schedules. ' +
    'When this field shows "Pending payment issuance," it signifies that a payout has not been made or due to 0 payment for the quarterly advances. ' +
    'Other reasons maybe due to incompliance with unsigned PPA, residing in a subsidiary that follows a semi-annual payment schedule, or other eligibility requirements as outlined in the PPI Business Rules.';
}
