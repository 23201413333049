<div class="tooltip__container" id="tooltip_container_{{ id }}">
  <button class="icon icon-info tooltip__trigger" aria-label="info" [attr.aria-describedby]="'tooltip__content-hover_' + id"></button>
  <div [ngClass]="[tooltipSizeClass, tooltipOrientedClass]" class="tooltip__content tooltip__content-hover" aria-hidden="true" role="tooltip" id="tooltip__content-hover_{{ id }}">
    <p class="tooltip__text" [innerHTML]="tipText" *ngIf="tipText"></p>
    <!-- In case of transclusion -->
    <ng-content class="tooltip__text" select="[tip-body]"></ng-content>
  </div>
  <div [ngClass]="[tooltipSizeClass, tooltipOrientedClass]" class="tooltip__content tooltip__content-focus" role="tooltip" [id]="'tiptext_' + id">
    <p class="tooltip__text" [innerHTML]="tipText" *ngIf="tipText"></p>
    <!-- <ng-template *ngTemplateOutlet="tipContent"></ng-template> -->
    <ng-content class="tooltip__text" select="[tip-body1]"></ng-content>
  </div>
</div>
