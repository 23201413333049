import { BaseModel } from '../../models/base.model';

export class DetailDropdownSelectedItem extends BaseModel {

    constructor(item: string, subItem: string) {
        super();

        this.item = item;
        this.subItem = subItem;
    }

    item: string;
    subItem: string;
}
