import { SafeHtml } from '@angular/platform-browser';
import { DataSource } from './data-source.model';
import { BaseModel } from './base.model';
import { Expose, deserializeArray, plainToClass, Type } from 'class-transformer';
import { BonusAttainmentDetails } from './bonus-account-details.model';
import { DatePipe } from '@angular/common';
import { Formatter } from '@mint-libs/common';


export class BonusParticipationDetails extends BaseModel {
  formatter:Formatter = new Formatter();
  datePipe: DatePipe = new DatePipe("en-US");

  @Expose({ name: 'id' }) id: string;
  @Expose({ name: 'FiscalYearId' }) FiscalYearId: number;
  @Expose({ name: 'ParticipationId' }) ParticipationId: number;
  @Expose({ name: 'BonusProgramName' }) BonusProgramName: string;
  @Expose({ name: 'ProgramStartDate' }) ProgramStartDate: Date;
  @Expose({ name: 'ProgramEndDate' }) ProgramEndDate: Date;
  @Expose({ name: 'EarningsStartDate' }) EarningsStartDate: Date;
  @Expose({ name: 'EarningsEndDate' }) EarningsEndDate: Date;
  @Expose({ name: 'TotalBonusAmount' }) TotalBonusAmount: number;
  @Expose({ name: 'CurrencyCode' }) CurrencyCode: string;
  
  @Expose({ name: 'BonusAttributes' }) 
  @Type(() => BonusAttainmentDetails)
  BonusAttributes: Array<BonusAttainmentDetails>;

  TotalBonusAmountStr(){
    return this.formatter.formatAndRoundOff(this.TotalBonusAmount);
  }

  EarningsStartDateStr(){
    return  this.datePipe.transform(this.EarningsStartDate, 'MMM d, yyyy');
  }

  EarningsEndDateStr(){
    return  this.datePipe.transform(this.EarningsEndDate, 'MMM d, yyyy');
  }

 
  
  deserialize(from: any): BonusParticipationDetails[] {
    const cagrAccountDetailsList = deserializeArray<BonusParticipationDetails>(BonusParticipationDetails, JSON.stringify(from), this.classTransformOptions);
    return cagrAccountDetailsList;
  }
  deserializeSingle(from: object): BonusParticipationDetails {
    const cagrAccountDetails = plainToClass<BonusParticipationDetails, object>(BonusParticipationDetails, from, this.classTransformOptions);
    return cagrAccountDetails;
  }
}
