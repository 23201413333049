import { Component, OnInit, Inject, Input, EventEmitter, Output, AfterViewChecked, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Formatter } from '@mint-libs/common';
import { LoggerService } from '@mint-libs/logging';
import { MetricCardConfig } from './../metric-card/metric-card-config';
import { MetricPCG } from '../models/metric-pcg.model';
import { MetricGridDataHelperService } from '../metric-grid/metric-grid-data-helper.service';
import { LegendService } from '../legends/legend.service';
import { Payline } from '../models/payline.model';
import { UserProfileService } from '../../core/context/user-profile.service';
import { PeriodStatus } from '../models/period-status.enum';
import { PlanHelper } from '../../shared/utilities/plan-helper';
import { BucketParams } from '../models/bucket-params.model';
import { IncentiveType } from '../models/incentive-type.enum';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { TooltipSize } from '../tooltip/tooltip-size.enum';
import { ChartType } from '../metric-chart/chartType.enum';
import { environment } from '../../../environments/environment';
import { FiltersService } from '../../filters/filters.service';
import { MeasureTypeMappingState, selectMeasureTypeMappingState, MeasureType } from '@mint-libs/context';
import { Store } from '@ngrx/store';

const MAX_DISPLAY_ATTAINMENT = 300;

@Component({
  selector: 'app-metric-pcg-block',
  templateUrl: './metric-pcg-block.component.html',
  styleUrls: ['./metric-pcg-block.component.scss']
})
export class MetricPcgBlockComponent implements OnInit, OnChanges, AfterViewChecked {
  ytdPCGs: Array<{ pcg: MetricPCG }> = [];
  isSinglePccInPcg: boolean;
  isMultiplePccInPcg: boolean;
  chartHelpText = 'Shows Target, attainment and actuals by Metrics, X axis List of Metrics, Y axis Attainment percentage.';
  yScaleMax: number;

  @Input() isYTD: boolean;
  @Input() selectedMonth: number;
  @Input() metricCardConfig: MetricCardConfig;
  @Input() chartType: ChartType;
  @Input() containerId: string;
  @Input() bucketType: string = null;
  @Input() neutralLegend = false;
  @Input() periodStatus = PeriodStatus.Active;
  @Input() caUtilization: any;
  @Input() caView = false;
  @Input() incentiveType?: IncentiveType = null;
  @Input() timeSpanLabel = '';
  @Input() showOCPLegend = false;
  @Output() totalWeightedAttainmentData = new EventEmitter();
  @Output() dataLoaded = new EventEmitter();

  isLoaded = false;
  metricDataFetched: boolean = false;
  performanceData: any;
  tooltipSize: string;
  bucketCCoCCNameRbi = 'Consulting Cost of Commercial Cloud %';
  bucketCCoCCNamePpi = 'PPI - Consulting Cost of Commercial Cloud %';
  tooltipHeader = 'Metric performance';
  tooltipHeaderDynamics = 'Dynamics Commitment Performance';
  measureTypeMapping: MeasureTypeMappingState = new MeasureTypeMappingState([]);
  payLinesResult: any;
  refreshTimeResult: any;
  targetSummaryResult: any;
  constructor(
    private metricGridDataHelperService: MetricGridDataHelperService,
    private datePipe: DatePipe,
    private formatter: Formatter,
    private legendService: LegendService,
    private planHelper: PlanHelper,
    public userProfileService: UserProfileService,
    private filterService: FiltersService,
    private logger: LoggerService,
    private measureState: Store<MeasureTypeMappingState>
  ) {
    this.getPCGMeasureType();
  }

  ngOnInit() {
    this.fetchMetricData();
  }

  fetchMetricData() {
    if (this.isYTD) {
      this.getYtdMetricData(this.selectedMonth);
    } else {
      if (!this.userProfileService.isNPM()) {
        this.getMetricData();
      }
    }
  }

  getPCGMeasureType() {
    this.measureState.select(selectMeasureTypeMappingState).subscribe(measureTypeMapping => {
      this.measureTypeMapping = measureTypeMapping;
      if (this.measureTypeMapping.isFetched && this.metricDataFetched) {
        this.setCards(this.payLinesResult, this.refreshTimeResult, this.targetSummaryResult);
      }
    });
  }

  private getMetricData() {
    const bucketParams = new BucketParams();
    bucketParams.bucketType = this.bucketType;
    bucketParams.periodStatus = this.periodStatus;
    bucketParams.incentiveType = this.incentiveType;
    this.performanceData = this.metricGridDataHelperService.getPerformanceData(bucketParams);
    this.isLoaded = false;
    const t1 = performance.now();
    this.performanceData.subscribe(
      ([targetSummaryResult, payLinesResult, refreshTimeResult]) => {
        this.targetSummaryResult = targetSummaryResult;
        this.payLinesResult = payLinesResult;
        this.refreshTimeResult = refreshTimeResult;

        if (targetSummaryResult && payLinesResult && refreshTimeResult) {
          this.metricDataFetched = true;
          this.ytdPCGs = new Array<{
            pcg: MetricPCG;
          }>();
          this.setCards(payLinesResult, refreshTimeResult, targetSummaryResult);
          this.isLoaded = true;
          this.dataLoaded.emit(this.isLoaded);
          this.logTTE(t1, 'PerfByBucket-Loaded');
        }
      },
      error => {
        this.logTTE(t1, 'PerfByBucket-Failed');
        this.metricDataFetched = false;
      }
    );
  }

  logTTE(t1: number, key: string) {
    const t2 = performance.now();
    const duration = (t2 - t1) / 1000; // In seconds
    const alias = this.userProfileService.getUserAlias();
    this.logger.trackEvent(key, { TotalTimeElapsed: duration, Alias: alias });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.periodStatus && changes.periodStatus.currentValue !== changes.periodStatus.previousValue) {
      if (!this.isYTD) {
        this.getMetricData();
      }
    }
  }

  ngAfterViewChecked() {
    const ofItems = document.querySelectorAll('.metric__stat-right__val');
    let ofFlag = true;
    ofItems.forEach(element => {
      if (element.clientHeight > 26) {
        ofFlag = ofFlag && false;
      }
    });
    if (!ofFlag) {
      ofItems.forEach(element => {
        element.parentElement.parentElement.classList.add('text__overflow');
      });
    }
  }

  getYtdMetricData(selectedMonth) {
    this.isLoaded = false;
    this.ytdPCGs = [];
    this.metricGridDataHelperService.getMetricGridYtdData(selectedMonth).subscribe(([targetSummaryResult, payLinesResult, refreshTimeResult]) => {
      this.payLinesResult = payLinesResult;
      this.targetSummaryResult = targetSummaryResult;
      this.refreshTimeResult = refreshTimeResult;
      this.setCards(payLinesResult, refreshTimeResult, targetSummaryResult);
      this.isLoaded = true;
      this.dataLoaded.emit(this.isLoaded);
    });
  }

  setCards(allPayLines: any, refreshTime: any, targetSummary: any) {
    const self = this;
    this.yScaleMax = 0;

    if (allPayLines && refreshTime && targetSummary) {
      self.ytdPCGs = [];

      this.totalWeightedAttainmentData.emit(targetSummary.TotalWeightedAttainment);

      allPayLines.Paylines.forEach(function(payline) {
        if (self.yScaleMax < payline.Excellence) {
          self.yScaleMax = payline.Excellence;
        }

        const metricPCG: MetricPCG = {};

        metricPCG.pcgName = payline.PcgName;
        metricPCG.pcgId = payline.PCGId;
        const utilizationPercentage = self.caView && self.caUtilization?.UtilizationPercentage ? self.caUtilization?.UtilizationPercentage : 0;
        metricPCG.attainmentPercent = self.caView ? utilizationPercentage?.toFixed(2) : payline?.Attainment?.toFixed(2);
        metricPCG.currencyCode = payline.PCGCurrency;
        // Check for OCP PPM Metric in metric card
        const isZeroWeight = false;
        if (payline.Weightage === 0 && this.userProfileService.isRBIPersona()) {
          this.isZeroWeight = true;
          this.showOCPLegend = true;
        }
        // Setting PCGEarning value with Active, TA and LOA
        const currentPCGs = targetSummary.PlanComponentGroups.filter(pcg => pcg.Name === payline.PcgName);
        payline.PCGEarnings = 0;
        currentPCGs.forEach(function(pcg: any) {
          payline.PCGEarnings = payline.PCGEarnings + pcg.Metrics.Earnings;
        });
        metricPCG.earningsBeforeCap = self.formatter.formatWithCurrencyAndRoundOff(payline.PCGEarnings, payline.PCGCurrency, this.isZeroWeight);
        metricPCG.pcgWeight = (payline.Weightage * 100).toFixed(0);
        metricPCG.incentiveType = payline.IncentiveType;
        metricPCG.pcgTitle = self.planHelper.getBucketTitle(payline.IncentiveType);
        metricPCG.attainmentTitle = payline.IncentiveType != null && payline.IncentiveType === 'UBI' ? 'Utilization' : 'Attainment';
        metricPCG.earningsTitle = 'Earnings';
        metricPCG.IsDynamicBucket = false;

        if (currentPCGs.length > 0) {
          metricPCG.determines = currentPCGs[0].determines;
          metricPCG.determinings = currentPCGs[0].determinings;

          if (currentPCGs[0].IsDynamicBucket) {
            metricPCG.IsDynamicBucket = true;
          }
        }

        const paylineModel = new Payline().deserializeSingle(payline);
        metricPCG.attainmentStatus = self.getAttainmentStatusLegend(paylineModel);

        metricPCG.chartData = { descText: '', dataSet: [] };
        metricPCG.pccData = [];

        metricPCG.earningsHelpText = self.getEarningsHelpText(payline.IncentiveType);
        metricPCG.attainmentHelpText = self.getAttainmentHelpText(metricPCG.pcgName, paylineModel.incentiveType);
        const currentPCG = currentPCGs.length > 0 ? currentPCGs[0] : null;
        if (currentPCG) {
          // console.log(JSON.stringify(currentPCG));
          currentPCG.PlanComponentCombinations.forEach(function(pcc: any) {
            const measureType = self.measureTypeMapping.getMeasureType(pcc.Id);
            const currentPCC = {
              totalTarget: currentPCG.IsDynamicBucket
                ? self.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.TotalTarget, pcc.Metrics.PccUnit)
                : self.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.TotalTarget - pcc.Metrics.TotalRecurringTarget, pcc.Metrics.PccUnit),
              totalActual: currentPCG.IsDynamicBucket
                ? self.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.Actuals, pcc.Metrics.PccUnit)
                : self.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.Actuals - pcc.Metrics.TotalRecurringTarget, pcc.Metrics.PccUnit),
              target: self.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.TotalTarget, pcc.Metrics.PccUnit),
              actual: self.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.Actuals, pcc.Metrics.PccUnit),
              totalRecurring: pcc.Metrics.TotalRecurringTarget > 0 ? self.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.TotalRecurringTarget, pcc.Metrics.PccUnit) : null,
              quotaToGo: self.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.QuotaToGo, pcc.Metrics.PccUnit),
              measuringUnit: pcc.MeasuringUnits,
              weightage: pcc.Weightage * 100,
              refreshDates: [],
              IsDynamicBucket: currentPCG.IsDynamicBucket
            };

            const pccDataSources = refreshTime.filter(rt => rt.PCCName === pcc.Name);
            const UtcDate = self.formatter.getUtcDate();
            if (pccDataSources.length > 0) {
              pccDataSources[0].DataSource.forEach(function(source: any) {
                currentPCC.refreshDates.push({
                  dataSource: source.SourceName,
                  lastRefresh: source.SourceLastRefreshTime === null ? 'Not available.' : self.formatter.transformToLocalDateTz(source.SourceLastRefreshTime, 'MMM D, YYYY, h:mm A ', true),
                  nextRefresh:
                    source.SourceNextRefreshTime === null
                      ? source.SourceName === 'ODS'
                        ? 'Coming soon.'
                        : 'Not available.' // Need to remove once next refresh gets populated for FY21_DeliveryValue
                      : self.formatter.getDate(new Date(source.SourceNextRefreshTime)) < UtcDate
                      ? self.formatter.transformToLocalDateTz(source.SourceNextRefreshTime, 'MMM D, YYYY ', true) + ' (In Progress)'
                      : self.formatter.transformToLocalDateTz(source.SourceNextRefreshTime, 'MMM D, YYYY ', true)
                });
              });
            }

            const toolTipHtml = self.pcgTipHtml(currentPCC, metricPCG.incentiveType, metricPCG.attainmentPercent, metricPCG.pcgName, measureType);
            if (self.yScaleMax < pcc.Metrics.StraightAttainment) {
              self.yScaleMax = pcc.Metrics.StraightAttainment;
            }
            metricPCG.chartData.dataSet.push({
              desc: pcc.Name,
              val: self.caView ? utilizationPercentage?.toFixed(2) : pcc.Metrics.StraightAttainment.toFixed(2),
              toolTipHtml: toolTipHtml
            });

            metricPCG.chartData.descText += pcc.Name + ' has revenue of ' + currentPCC.actual + ' has target of ' + currentPCC.target + ' has quota to go of ' + currentPCC.quotaToGo + ', ';

            metricPCG.pccData.push(currentPCC);
            // console.log(JSON.stringify(metricPCG));
          });
        }

        self.ytdPCGs.push({ pcg: metricPCG });
      }, this);

      const notUBI = self.ytdPCGs[0].pcg.incentiveType !== IncentiveType.UBI && self.ytdPCGs[0].pcg.incentiveType !== IncentiveType.RUBI;
      self.isSinglePccInPcg = self.ytdPCGs.length === 1 && self.ytdPCGs[0].pcg.pccData.length === 1 && notUBI;
      self.isMultiplePccInPcg = self.ytdPCGs.length === 1 && self.ytdPCGs[0].pcg.pccData.length !== 1 && notUBI;

      self.yScaleMax = Math.min(MAX_DISPLAY_ATTAINMENT, Math.round((self.yScaleMax + 15.0) / 10) * 10);
    }
  }

  capitlizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  isUBIPersona(paylineIncentiveType: string): boolean {
    const isFSA = this.filterService.isFSA();
    let ubiPersona = this.userProfileService.isUBIPersona();
    if (isFSA && paylineIncentiveType === IncentiveType.RUBI) {
      ubiPersona = false;
    }
    return ubiPersona;
  }
  getAttainmentStatusLegend(paylineModel: Payline): string {
    if (this.neutralLegend || this.isYTD) {
      return paylineModel.weightage === 0 ? 'ocp_metric' : 'neutral';
    }
    const ubiPersona = this.isUBIPersona(paylineModel.incentiveType);
    if (ubiPersona) {
      return 'rubi_neutral';
    }
    if (ubiPersona && this.periodStatus === PeriodStatus.TA) {
      return 'rubi_neutral';
    }
    return this.legendService.getCssClass(paylineModel);
  }
  getEarningsHelpText(paylineIncentiveType) {
    if (paylineIncentiveType === IncentiveType.UBI) {
      return (
        'This is the calculated earnings based on the attainment for the metric. The amount displayed is determine by your attainment and payline,' +
        ' but actual payment amount may vary due to payment restrictions listed in the UBI business rules (learn more on the Incentive Compensation Guide at https://aka.ms/icguide).' +
        environment.corpUrlMessage
      );
    }

    if (this.filterService.isSelectedFiscalYear2021()) {
      return (
        'YTD earnings based on attainment of the individual bucket. Payments are capped at 100% in Q1 in accordance with the RBI ' +
        'Business Rules and 115% for Q2 and Q3 as part of the FY21 Accelerate H1 program. More information on payout calculations are available on the Payout Details page.'
      );
    }

    return 'YTD earnings based on attainment of the individual bucket. Payments are capped at 100%. More information on payout calculations are available on the Payout Details page.';
  }

  getAttainmentHelpText(bucketTitle: string = '', incentiveType: string) {
    // Default attainment Forumula for RBI and PPI
    let rbiAttainmentFormula = 'Actuals/Target';
    let ppiAttainmentFormula = 'Actuals/Target';

    if (bucketTitle === this.bucketCCoCCNameRbi || bucketTitle === this.bucketCCoCCNamePpi) {
      // Formula to be changed only incase of CCoCC bucket
      rbiAttainmentFormula = '1-(Actuals-Budget)/Budget';
      ppiAttainmentFormula = '1-(Actuals-Budget)/Budget';
    }

    if (this.userProfileService.isRBIPersona() || (this.filterService.isFSA() && incentiveType === IncentiveType.RUBI)) {
      return (
        'Attainment % = ' +
        rbiAttainmentFormula +
        '. For billed revenue metrics in Enterprise and SMC, attainment is calculated as ' +
        '((Total Actuals – Recurring Target)/(Total Target – Recurring Target)) and for Azure Consumption metrics, attainment is calculated as ' +
        '((Total Actuals – Baseline)/(Total Target – Baseline)).'
      );
    } else if (this.userProfileService.isUBIPersona()) {
      return (
        'Attainment is calculated as total actual logged hours/target of your assigned Enterprise accounts and timezone. ' +
        'To understand which hours are measured, refer to your plan summary on the My Compensation Plan page. ' +
        'Target and Actuals included in the attainment calculation include logged and approved hours in upstream tools through the last full fiscal week.'
      );
    } else if (this.userProfileService.isPPIPersona()) {
      this.tooltipSize = TooltipSize.ExtraLarge;
      return (
        `Attainment % = ` +
        ppiAttainmentFormula +
        ` This reflects YTD actuals against H1 or full-year target, depending on the time period selection in the upper right-hand corner of the dashboard.
      This attainment is subject to the Balanced Performance cap, whereas the attainment you see in the bar graph directly below is uncapped.
      The Balance Performance cap is measured and applied for each bucket. This represents the maximum attainment per bucket that can attribute to the total weighted attainment for your plan.
      To see the Balanced Performance caps for your plan and learn how attainment impacts your final payout,
      visit the Earning Potential tab of your My Compensation Plan page and review the FY20 PPI Learning tool.`
      );
    }
  }
  pcgTipHtml(pccData: any, incentiveType: any, attainment: any, bucketName: string = '', measureType: MeasureType) {
    let refreshDatesHtml = '';
    pccData.refreshDates.forEach(function(dataSource: any) {
      refreshDatesHtml +=
        '<div class="data__source__item">' +
        '<div class="data__source__header">' +
        dataSource.dataSource +
        '</div>' +
        '<div class="data__source__value">Last Refresh: ' +
        dataSource.lastRefresh +
        '</div>' +
        '<div class="data__source__value">Next Refresh: ' +
        dataSource.nextRefresh +
        '</div>' +
        '</div>';
    });

    if (refreshDatesHtml === '') {
      refreshDatesHtml += `
      <div class="data__source__item">
        No Data Available
      </div>
      `;
    }

    // Default attainment Forumula for RBI and PPI
    let numerator = 'Total Actuals';
    let denominator = 'Total Target';

    let targets, actuals, quotaToGo;

    if (this.userProfileService.isUBIPersona() && this.caView) {
      const actualHours = this.caView && this.caUtilization?.ActualHours ? this.caUtilization?.ActualHours : 0;
      const targetHours = this.caView && this.caUtilization?.TargetHours ? this.caUtilization?.TargetHours : 0;
      const actual = parseFloat(actualHours.toString().replace(',', ''));
      actuals = actual.toFixed(2) + ' Hours';
      const target = parseFloat(targetHours.toString().replace(',', ''));
      targets = target.toFixed(2) + ' Hours';
      quotaToGo = (actual >= target ? 0 : (target - actual).toFixed(2)) + ' Hours';
    }

    let html =
      '<div class="bar__tooltip__container bar__tooltip__container-pcg">' +
      '<div class="bar__tooltip__left">' +
      '<div class="bar__tooltip__header">' +
      (pccData.IsDynamicBucket ? this.tooltipHeaderDynamics : this.tooltipHeader) +
      '</div>' +
      '<div class="perf__container">' +
      '<div class="perf__data">' +
      '<div class="perf__data__text">' +
      (pccData.IsDynamicBucket ? 'Commitments Quota (A): ' : 'Total Target: ') +
      '</div>' +
      '<div class="perf__data__val"> ' +
      (this.caView ? targets : pccData.target) +
      '</div>' +
      '</div>';

    if (pccData.totalRecurring !== null) {
      let dataText = 'Recurring Target';
      if (measureType === MeasureType.Baseline) {
        numerator = 'Total Actuals-Baseline';
        denominator = 'Total Target-Baseline';
        dataText = 'Baseline';
      } else {
        numerator = 'Total Actuals-Recurring Target';
        denominator = 'Total Target-Recurring Target';
      }

      if (!pccData.IsDynamicBucket) {
        html += '<div class="perf__data">' + '<div class="perf__data__text">' + `${dataText}: ` + '</div>' + '<div class="perf__data__val"> ' + pccData.totalRecurring + '</div>' + '</div>';
      }
    }

    html +=
      '<div class="perf__data">' +
      '<div class="perf__data__text">' +
      (pccData.IsDynamicBucket ? 'Total Value of Commitments Landed (B): ' : 'Total Actuals: ') +
      '</div>' +
      '<div class="perf__data__val"> ' +
      (this.caView ? actuals : pccData.actual) +
      '</div>' +
      '</div>' +
      '<div class="perf__data">' +
      '<div class="perf__data__text">';

    if (incentiveType === 'RBI') {
      html += pccData.IsDynamicBucket ? '' : 'Quota to go:';
    } else {
      html += 'Target Remaining:';
    }

    html +=
      '</div>' +
      '<div class="perf__data__val"> ' +
      (this.caView ? quotaToGo : pccData.IsDynamicBucket ? '' : pccData.quotaToGo) +
      '</div>' +
      '</div>' +
      '<div class="perf__data">' +
      '<div class="perf__data__text">Weight: </div>' +
      '<div class="perf__data__val"> ' +
      pccData.weightage +
      '%</div>' +
      '</div>' +
      '<div class="formula">';
    if (incentiveType === 'UBI') {
      html += '<div>Utilization%</div>' + '<div>=</div>' + '<div class="formula__right">' + attainment + '%</div>';
    } else if (pccData.IsDynamicBucket) {
      html +=
        '<div>Attainment%</div>' +
        '<div class="formula-smallLeftPadding"> = </div>' +
        '<div class="formula__right">' +
        '<div class="formula__right-nume">' +
        'Total Value of Commitments Landed (B)' +
        '</div>' +
        '<hr />' +
        '<div class="formula__center">' +
        'Commitments Quota (A)' +
        '</div>' +
        '</div>';
    } else if (bucketName === this.bucketCCoCCNameRbi || bucketName === this.bucketCCoCCNamePpi) {
      html +=
        '<div>Attainment%</div>' +
        '<div class="formula-smallLeftPadding"> = 1 -</div>' +
        '<div class="formula__right">' +
        '<div class="formula__right-nume">' +
        'Actuals-Budget' +
        '</div>' +
        '<hr />' +
        '<div class="formula__center">' +
        'Budget' +
        '</div>' +
        '</div>';
    } else {
      html +=
        '<div>Attainment%</div>' +
        '<div>=</div>' +
        '<div class="formula__right">' +
        '<div class="formula__right-nume">' +
        numerator +
        '</div>' +
        '<hr />' +
        '<div>' +
        denominator +
        '</div>' +
        '</div>';
    }
    html +=
      '</div>' +
      '</div>' +
      '</div>' +
      '<div class="bar__tooltip__right">' +
      '<div class="bar__tooltip__header">' +
      'Data Source' +
      '</div>' +
      '<div class="data__source__container">' +
      refreshDatesHtml +
      '</div>' +
      '</div>' +
      '<div class="bar__tooltip__close">' +
      '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M9.85242 0.851639L5.70944 5.00004L9.85242 9.14844L9.14844 9.85164L5.00043 5.70904L0.852417 9.85164L0.148438 9.14844L4.29141 5.00004L0.148438 0.851639L0.852417 0.148438L5.00043 4.29104L9.14844 0.148438L9.85242 0.851639Z"' +
      'fill="#333333" />' +
      '</svg>' +
      '</div>' +
      '</div>';
    return html;
  }
}
