import { Action } from '@ngrx/store';

export enum UserProfileActionTypes {
  LoadUserProfile = '[UserProfile] Load UserProfile',
  LoadUserProfileSuccess = '[UserProfile] Load UserProfile Success',
  LoadUserProfileFailure = '[UserProfile] Load UserProfile Failure',
  ResetUserProfile = '[UserProfile] Reset UserProfile',
  GetGraphDetails = '[UserProfile] Get Graph Details'
}

export class LoadUserProfile implements Action {
  readonly type = UserProfileActionTypes.LoadUserProfile;
  constructor(public participationid: string) {}
}

export class LoadUserProfileSuccess implements Action {
  readonly type = UserProfileActionTypes.LoadUserProfileSuccess;
  constructor(public payload: { data: any }) {}
}

export class LoadUserProfileFailure implements Action {
  readonly type = UserProfileActionTypes.LoadUserProfileFailure;
  constructor(public payload: { error: any }) {}
}

export class ResetUserProfile implements Action {
  readonly type = UserProfileActionTypes.ResetUserProfile;
}

export class GetGraphDetails implements Action {
  readonly type = UserProfileActionTypes.GetGraphDetails;
  constructor(public userAlias: string) {}
}

export type UserProfileActions = LoadUserProfile | LoadUserProfileSuccess | LoadUserProfileFailure | ResetUserProfile | GetGraphDetails;
