import { Action } from '@ngrx/store';

export enum InitializationActionTypes {
  PerformInitRefresh = '[Initialization] Perform Initialization Refresh',
  SaveFilterStateAndRefresh = '[Initialization] Save Filter State',
  LoadFilterState = '[Initialization] Load Filter State',
}

export class PerformInitRefresh implements Action {
  readonly type = InitializationActionTypes.PerformInitRefresh;
  constructor(public clearCache: boolean = true) {}
}


export class SaveFilterStateAndReset implements Action {
  readonly type = InitializationActionTypes.SaveFilterStateAndRefresh;
  constructor(public skipAuthorization: boolean) {}
}
export class LoadFilterState implements Action {
  readonly type = InitializationActionTypes.LoadFilterState;
  constructor(public skipAuthorization: boolean) {}
}

export type InitActions = PerformInitRefresh | LoadFilterState | SaveFilterStateAndReset;
