import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../core/network/data.service';
import { CustomHttpParams } from '../core/network/custom-http-params';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { FeatureGroup } from '../shared/models/feature-group.model';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FeatureAdminService {
  constructor(private endpoint: EndpointService, private dataService: DataService) { }

  GetAllFeatureIntroductions() {
    const params = CustomHttpParams.getInstance();
    const a = this.dataService.get<any>(this.endpoint.GetUserSplash.uri, params);
    return a;
  }

  DeleteFeatureGroup(id) {
    let params = new HttpParams();
    params = params.append('id', id);
    const a = this.dataService.delete<any>(this.endpoint.DeleteFeatureGroup.uri, params);
    return a;
  }
  DeleteFeatureIntroduction(ids) {
    let params = new HttpParams();
    ids.forEach(id => {
      params = params.append('ids', id);
    });
    const a = this.dataService.delete<any>(this.endpoint.DeleteFeatureIntroductions.uri, params);
    return a;
  }
  AddOrUpdateFeatureGroup(fgrp) {
    const params = fgrp;
    const a = this.dataService.post<any>(this.endpoint.AddorUpdateFeatureGroups.uri, params);
    return a;
  }
  AddOrUpdateFeatureIntroduction(fintro) {
    const params = fintro;
    const a = this.dataService.post<any>(this.endpoint.AddorUpdateFeatureIntroductions.uri, params);
    return a;
  }
  GetAllFeatureGroups() {
    let params = CustomHttpParams.getInstance();
    params = params.append('camelcase', 'true');
    return this.dataService.getCamelCase<any>(this.endpoint.GetAllFeatureGroups.uri, params).pipe(
      map(result => {
        return new FeatureGroup().deserialize(result);
      })
    );
  }

  GetAdminNotificationsBindings(): Observable<any> {
    let params = CustomHttpParams.getInstance();
    params = params.append('camelcase', 'true');
    return this.dataService.getCamelCase<any>(this.endpoint.GetAdminNotificationFilterData.uri, params);
  }

  UploadFeatureImage(formData): Observable<any> {
    return this.dataService.post<any>(this.endpoint.UploadFeatureImage.uri, formData);
  }
}
