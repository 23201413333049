import { Component, Inject } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { RowArgs, SelectableSettings, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { PlanActivationService } from '../plan-activation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanActivationActionType } from './plan-activation-action-type';
import { Store } from '@ngrx/store';
import { ConfigurationState, selectConfigurationState } from '@mint-libs/configuration';
import { LoggerService } from '@mint-libs/logging';
import { CommonFactory } from '@mint-libs/common';

@Component({
  selector: 'mint-planactivation',
  templateUrl: './plan-activation.component.html',
  styleUrls: ['./plan-activation.component.scss']
})
export class PlanActivationComponent {
  plans;
  filteredPlans;
  selectedPlans = [];
  modifiedPlans: any[] = [];
  selectionState: SelectAllCheckboxState = 'unchecked';
  selectableSettings: SelectableSettings = {
    enabled: true,
    checkboxOnly: true,
    mode: 'multiple'
  };
  searchText: string;
  actions = PlanActivationActionType;
  actionKeys;
  selectedAction = 'Activate';
  showCurrentFiscalYearPlans = false;
  previewChangesDialog: any;
  currentFiscalYearFromConfig: any;
  fetchingPlans = false;

  constructor(
    private commonFactory: CommonFactory,
    private loggingService: LoggerService,
    private planActivationService: PlanActivationService,
    private configStore: Store<ConfigurationState>,
    private ngbModal: NgbModal
  ) {
    this.loggingService.trackPageView('plan_activation');
    this.actionKeys = Object.keys(this.actions);
    if (!this.showCurrentFiscalYearPlans) {
      this.fetchPlansBasedOnConfig();
    } else {
      this.getPlans();
    }
  }

  fetchPlansBasedOnConfig() {
    this.configStore.select(selectConfigurationState).subscribe(configurations => {
      if (configurations && configurations.configs && !this.fetchingPlans) {
        this.fetchingPlans = true;
        this.currentFiscalYearFromConfig = configurations.configs.latestFiscalYear;
        if (!this.currentFiscalYearFromConfig) {
          this.showCurrentFiscalYearPlans = true;
        }
        this.getPlans();
      }
    });
  }

  //#region datagrid & other events
  getRowItem(context: RowArgs) {
    return context.dataItem;
  }

  onSelectedKeysChange() {
    this.setSelectionState();
  }

  onSelectAllChange(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.selectedPlans = [...this.filteredPlans]; // Copying array and not its reference..
    } else {
      this.selectedPlans = [];
    }

    this.setSelectionState();
  }

  onSearchTextChange(inputValue: any) {
    inputValue = inputValue ?? this.searchText;
    const countBeforeSearch = this.filteredPlans.length;

    if (inputValue && inputValue.length > 2) {
      this.filteredPlans = process(this.plans, {
        filter: {
          logic: 'or',
          filters: [
            {
              field: 'planName',
              operator: 'contains',
              value: inputValue
            }
          ]
        }
      }).data;
    } else {
      this.filteredPlans = this.plans;
    }

    // Resetting selection only when count changes and not for every search input.
    if (countBeforeSearch !== this.filteredPlans.length) {
      this.clearSelectionState();
    }
  }

  onApply() {
    if (this.selectedPlans && this.selectedPlans.length > 0) {
      this.selectedPlans.forEach(plan => {
        if (this.selectedAction === PlanActivationActionType.Activate) {
          plan.isActive = true;
        } else if (this.selectedAction === PlanActivationActionType.Deactivate) {
          plan.isActive = false;
        } else if (this.selectedAction === PlanActivationActionType.EnableRevenue) {
          plan.isRollOver = true;
        } else if (this.selectedAction === PlanActivationActionType.DisableRevenue) {
          plan.isRollOver = false;
        }

        // Add to modified collection when not present.
        if (!this.modifiedPlans.find(t => t.planName === plan.planName)) {
          this.modifiedPlans.push(plan);
        }
      });

      this.clearUnModified();
    } else {
      this.commonFactory.addNotification({
        name: 'warning',
        message: 'Please select entries to apply!',
        dismiss: 5000
      });
    }
  }

  viewChanges(content) {
    this.previewChangesDialog = this.ngbModal.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      backdropClass: 'mint-modal-backdrop',
      windowClass: 'mint-modal-window-enlarge'
    });
  }

  removeItem(plan: any) {
    this.modifiedPlans.splice(this.modifiedPlans.indexOf(plan), 1);

    // resetting values.
    plan.isActive = plan.isActiveInitial;
    plan.isRollOver = plan.isRollOverInitial;
  }

  onBack() {
    this.closePreviewChangesDialog();
  }

  onSave() {
    this.closePreviewChangesDialog();

    if (this.modifiedPlans && this.modifiedPlans.length > 0) {
      this.planActivationService.updatePlans(this.modifiedPlans).subscribe(
        result => {
          this.commonFactory.addNotification({
            name: 'success',
            message: 'Selected plans are updated!',
            dismiss: 5000
          });

          this.resetState();
        },
        error => {
          this.commonFactory.addNotification({
            name: 'error',
            message: 'There was an error while updating the plans. Please try again!',
            dismiss: 5000
          });
        }
      );
    } else {
      this.commonFactory.addNotification({
        name: 'warning',
        message: 'No modified plans to save!',
        dismiss: 5000
      });
    }
  }

  toggleEnablePlan(plan: any) {
    this.togglePlan(plan, true);
  }

  toggleEnableRevenue(plan: any) {
    this.togglePlan(plan, false);
  }
  //#endregion

  //#region helper methods
  getPlans() {
    let currentFiscalYear = null;
    if (this.showCurrentFiscalYearPlans) {
      currentFiscalYear = this.getCurrentFinancialYear();
    } else {
      currentFiscalYear = this.currentFiscalYearFromConfig;
    }

    this.planActivationService.getPlans(currentFiscalYear).subscribe(
      result => {
        if (result && result.length > 0) {
          result.forEach(plan => {
            plan.isActiveInitial = plan.isActive;
            plan.isRollOverInitial = plan.isRollOver;
          });
        }
        this.filteredPlans = this.plans = result.filter(plan => plan.fiscalYear == currentFiscalYear);
      },
      error => {
        this.commonFactory.addNotification({
          name: 'error',
          message: 'There was an error while retrieving the plans!',
          dismiss: 5000
        });
      }
    );
  }

  togglePlan(plan: any, togglePlan) {
    if (this.filteredPlans) {
      var modifiedPlan = this.modifiedPlans ? this.modifiedPlans.find(t => t.planName === plan.planName) : undefined;
      if (!modifiedPlan) {
        modifiedPlan = this.filteredPlans.find(t => t.planName === plan.planName);
        this.modifiedPlans.push(modifiedPlan);
      }

      if (togglePlan === true) {
        modifiedPlan.isActive = !modifiedPlan.isActive;
      } else {
        modifiedPlan.isRollOver = !modifiedPlan.isRollOver;
      }

      this.clearUnModified();
    }
  }

  setSelectionState() {
    const len = this.selectedPlans.length;

    if (len === 0) {
      this.selectionState = 'unchecked';
    } else if (len > 0 && len < this.filteredPlans.length) {
      this.selectionState = 'indeterminate';
    } else {
      this.selectionState = 'checked';
    }
  }

  getCurrentFinancialYear() {
    const today = new Date();
    let fiscalyear = today.getFullYear() + 1;
    if (today.getMonth() + 1 <= 7) {
      fiscalyear = today.getFullYear();
    }

    return fiscalyear;
  }

  clearSelectionState() {
    this.selectedAction = 'Activate';
    this.selectedPlans = [];
    this.setSelectionState();
  }

  resetState() {
    this.modifiedPlans = [];
    this.searchText = '';
    this.clearSelectionState();
    this.getPlans();
  }

  clearUnModified() {
    const modifiedPlansColl = [...this.modifiedPlans]; // Copying to new array and removing from the originial one.
    if (modifiedPlansColl && modifiedPlansColl.length > 0) {
      modifiedPlansColl.forEach(plan => {
        if (plan.isActive === plan.isActiveInitial && plan.isRollOver === plan.isRollOverInitial) {
          const index = this.modifiedPlans.indexOf(plan);
          this.modifiedPlans.splice(index, 1);
        }
      });
    }
  }

  closePreviewChangesDialog() {
    this.previewChangesDialog.close();
  }
  //#endregion
}
