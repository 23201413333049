import { IncentiveType } from './../shared/models/incentive-type.enum';
import { Component, OnInit } from '@angular/core';
import { HelpService } from './help.service';
import { UserProfileService } from '../core/context/user-profile.service';

@Component({
  selector: 'mint-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
// Commented out most of the code as we are now showing a static content for users which redirects to IC guide. Retaining code, so that if required we can consume it back.
export class HelpComponent implements OnInit {
  //helpData: any = null;
  icGuideUrl = 'https://aka.ms/icguide';

  constructor(private helpService: HelpService, public userProfileService: UserProfileService) {
    // this.userProfileService = this.userProfileService.userProfileService;
    // if (this.userProfileService.incentiveType === 'PPI') this.helpTabs = this.helpTabs.filter(o => o.index === 0);

    // this.helpService.getHelpDetails('').subscribe((response): any => {
    //   this.helpData = response;
    // });

    // if (this.userProfileService.isPPIPersona()) {
    //   this.icGuideUrl = 'https://microsoft.sharepoint.com/teams/IncentiveCompensationGuide/SitePages/Mint/MintforPPI.aspx?xsdata=MDN8MDF8fGRhMjA0NzM2NzRjYzQxOTU4N2FmZWZmMzNjMzIxMzExfDcyZjk4OGJmODZmMTQxYWY5MWFiMmQ3Y2QwMTFkYjQ3fDF8MHw2Mzc3MjE1MzM5NTIwOTkzMzR8R29vZHxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaUlpd2lRVTRpT2lJaUxDSlhWQ0k2TVRKOQ%3D%3D&sdata=Qlo2ZEFEdjhwbjNjMkNMSHo5UlNvMHI5NTJobkk5SXd4WldCanJPNjZCQT0%3D&ovuser=72f988bf-86f1-41af-91ab-2d7cd011db47%2Cnagasv%40microsoft.com';
    // }
  }

  ngOnInit() { }

  // helpTabs = [
  //   {
  //     index: 0,
  //     id: 'help_Faq',
  //     key: this.helpService.Type.FAQ,
  //     title: 'FAQs'
  //   },
  //   {
  //     index: 1,
  //     id: 'help_Glossary',
  //     key: this.helpService.Type.GLOSSARY,
  //     title: 'Glossary'
  //   }
  //   // These are tabs which are no lnger required, keeping for now if business comes back. 
  //   // {
  //   //   index: 3,
  //   //   id: 'help_AccessibilityHelp',
  //   //   key: this.helpService.Type.AccessibilityHelp,
  //   //   title: 'Accessibility Help'
  //   // }
  //   // {
  //   //   index: 1,
  //   //   id: 'help_TrainingMaterial',
  //   //   key: this.helpService.Type.TRAININGMATERIAL,
  //   //   title: 'Training Materials'
  //   // },
  // ];

  //selectedTab = 0;

  //activeTabId = this.helpTabs.filter(t => t.index === this.selectedTab)[0].id;

  // onTabChange(event) {
  //   this.helpTabs.forEach(x => {
  //     if (x.id === event.nextId) {
  //       this.selectedTab = x.index;
  //     }
  //   });
  // }
}
