import { Inject, Injectable } from '@angular/core';
import { Location as AngularLocation } from '@angular/common';
import * as _ from 'underscore';
import { FiltersService } from 'src/app/filters/filters.service';
import { NavigationService } from '../navigation/navigation.service';
import { Code } from 'src/app/app.constants';
import { CachingService } from '../caching/caching.service';
import { UserProfileService } from '../context/user-profile.service';
import { AuthorizationService } from '../authorization/authorization.service';
import { ConfigurationService, ConfigurationState, LoadConfigurationSuccess } from '@mint-libs/configuration';
import { LoggerService } from '@mint-libs/logging';
import { FilterState, GraphService, LoadUserProfile, LoadUserProfileSuccess, ResetUserProfile, SetFilters, UserPreference, UserProfile, UserProfileState } from '@mint-libs/context';
import { RoleFeatureService } from '@mint-libs/context';
import { DemoContext } from '../context/demo.context';

import { environment } from '../../../environments/environment';
import { DisclaimersService } from 'src/app/disclaimers/disclaimers.service';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { CommonFactory } from '@mint-libs/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '@mint-libs/authentication';
import { Store } from '@ngrx/store';

@Injectable()
export class UserSessionService {
  public sharedContext = new SharedContext();
  constructor(
    private commonFactory: CommonFactory,
    private spinner: NgxSpinnerService,
    public sharedContextState: Store<SharedContext>,
    private filterService: FiltersService,
    private navigationFactory: NavigationService,
    private authorizationService: AuthorizationService,
    private userProfile: UserProfileService,
    private code: Code,
    private configurationService: ConfigurationService,
    private cachingService: CachingService,
    private loggingService: LoggerService,
    private graphService: GraphService,
    private rolefeatureService: RoleFeatureService,
    private demoContext: DemoContext,
    private location: AngularLocation,
    private disclaimerService: DisclaimersService,
    private authenticationService: AuthenticationService,
    private configurationState: Store<ConfigurationState>,
    private userProfileState: Store<UserProfileState>
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  private isReload = false;
  private authToken = '';
  private alreadyForcedAuthenticated = false;
  private isMsSalesAuthorized = false;

  private pages = {
    disclaimer: 'disclaimer',
    complianceDashboard: '/complianceDashboard',
    dashboardV1: '/dashboard',
    impersonation: '/admin/impersonate',
    compPlanV1: 'home.compensationPlan',
    compPlan: '/compensationPlan',
    error: '/error',
    superAdmin: '/admin/impersonate',
    dashboard: '/dashboard',
    ytdDashboard: '/ytdDashboard',
    demo: '/demo',
    notifications: '/notifications',
    mic2Reports: '/mic2Reports',
    disclaimers: '/disclaimers'
  };

  private v1Pages = {
    [this.pages.dashboardV1]: this.pages.dashboardV1,
    [this.pages.compPlanV1]: this.pages.compPlanV1,
    [this.pages.compPlanV1]: this.pages.compPlanV1
  };

  public error = {
    showFilter: false,
    authenticationFailed: {
      message: 'Unable to authenticate. Please try again.'
    },
    unAuthorized: {
      message: 'Not Authorized'
    },
    configurationLoad: {
      message: 'Failed to retrieve required configuration. Please try again.'
    },
    userLoad: {
      message: 'Failed to load user profile.'
    },
    authorizeAndUserLoad: {
      message: 'Failed to authorize and load user profile.'
    },
    qaFcaApprovalPending: {
      message: 'Your compliance documents for this participation are not yet available. Please check back later or contact your manager.',
      code: 'QAStatusSeller'
    },
    filterError: {
      message: 'No data available for the current user based on current filters.<br/> Please try again later',
      code: 'FilterError'
    },
    ppaSignedMessage: {
      message:
        'You have not signed your Plan Participation document yet. We have sent you a link via email. Please sign the document to proceed. It may take some time for the status to reflect in MINT',
      code: 'PPASingedStatus'
    }
  };
  public refresh(clearCahe) {
    if (clearCahe === true) {
      this.cachingService.clear();
    }

    this.configurationService.init();

    if (this.sharedContext.isImpersonating) {
      var alias = '';
      var participationid = '';
      if (this.userProfile && this.sharedContext.alias) {
        alias = this.sharedContext.alias;
        if (this.filterService.selectedStandardTitle) {
          participationid = this.filterService.selectedStandardTitle.ParticipationID;
        }
      }

      var authorizePromise = this.authorizationService.authorizeImpersonation(alias, alias, participationid, null, null).subscribe(result => {
        if (result.IsAuthorized) {
          this.initialize(true, true, true, true);
        } else {
          this.navigationFactory.clear();
          this.navigationFactory.navigateTo(['error', 'error'], {
            showFilter: true,
            qaFcaApprovalPending: this.error.qaFcaApprovalPending
          });
          console.error('Authorization failed for impersonated profile on changing filter.');
        }
      });
      this.spinner.show('Loading ...');
    } else {
      this.initialize(false, true, true, true);
    }
  }

  public initialize(skipAuthorization, reload, doNotResetFilter, refresh = false, forceFilterSet = false, doNotShowDisclaimer = false) {
    this.loggingService.startTrackPage('Initialization');
    this.isReload = reload;
    // Chain all load process -- TODO

    var authPromise = this.getConfiguration()
      .then(config => {
        return this.authorize(skipAuthorization);
      })
      .then(profile => {
        if (profile) {
          this.userProfile.setProfile(profile);
          this.setUserProfileSettings(profile);
          this.userProfileState.dispatch(new ResetUserProfile());
          this.userProfileState.dispatch(new LoadUserProfileSuccess({ data: profile }));
          return Promise.resolve(profile);
        } else {
          return this.loadUserProfileSettings()
            .then(profile => {
              this.userProfileState.dispatch(new ResetUserProfile());
              this.userProfileState.dispatch(new LoadUserProfileSuccess({ data: profile }));
              return this.setUserProfileSettings(profile);
            })
            .then(settings => {
              if (this.sharedContext.isImpersonating) {
                this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, isRollOver: true }));
              }
              return Promise.resolve(settings);
            })
            .catch(error => {
              return Promise.reject(error);
            });
        }
      })
      .then(() => {
        this.setFilters(forceFilterSet || (doNotResetFilter === true ? false : skipAuthorization));
        this.setDataRefresh();
      });

    return authPromise
      .then(result => {
        if (this.sharedContext.IsNonWwicAndMic2Eligible === true) {
          this.TimeoutAndNavigate(this.pages.mic2Reports, null);
        }

        this.redirect(null, null, reload, refresh, doNotShowDisclaimer);
      })
      .then(result => {
        return Promise.resolve(result);
      })
      .catch(error => this.handleError(error))
      .finally(() => {
        //initPageTour();
        // pageTour.init();
        this.spinner.hide();
        this.loggingService.stopTrackPage('Initialization');
      });
  }

  public loadPageFeatureMasterData(configuration) {
    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        Pages: configuration.Pages,
        Features: configuration.Features,
        isQuotaAckEnabled: configuration.Configs.QAAckEnabled === 'True',
        businessSwitchEnabledFeatures: configuration.Configs.BusinessSwitchEnabledFeatures
      })
    );
  }

  public redirect(url, clearHistory, reload, refresh, doNotShowDisclaimer) {
    var currentPage = this.location.path();
    if (clearHistory === true || this.sharedContext.impersonationBeingStopped) {
      this.navigationFactory.clear();
    }

    var redirection = this.getRedirectUrl(url, doNotShowDisclaimer);

    // Handling Mic2 redirects.
    if (this.filterService.isMic2Profile() && !this.location.isCurrentPathEqualTo('mic2Reports')) {
      this.TimeoutAndNavigate(this.pages.mic2Reports, null);
      return;
    }
    if (!this.filterService.isMic2Profile() && this.location.isCurrentPathEqualTo('mic2Reports')) {
      this.TimeoutAndNavigate(redirection.url, redirection.state);
      return;
    }

    //Reload if:
    if (
      (this.location.isCurrentPathEqualTo('mic2Reports') && this.filterService.isMic2Profile()) ||
      (redirection != null && currentPage === redirection.url && reload) || //From & To URL are same and it's reload
      (refresh && currentPage != this.pages.error)
    ) {
      //OR it's page refresh but current page is not error
      this.demoContext.isDemotoggled = false;
      this.location.go(this.location.path());
      // $state.transitionTo($state.current, $stateParams, {
      //   reload: true,
      //   inherit: false,
      //   notify: true
      // });
      this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, impersonationBeingStopped: false }));
      return;
    }
    this.demoContext.isDemotoggled = false;
    if (redirection == null) {
      return;
    }

    if (redirection.clearHistory === true) {
      return this.navigationFactory.clearHistoryAndNavigateTo(redirection.url, redirection.state);
    } else {
      if (redirection.url == this.pages.complianceDashboard) {
        return this.navigationFactory.navigateToSubView(this.pages.complianceDashboard, {
          name: 'My Compliance',
          active: false,
          index: 0
        });
      }

      if (!redirection.url) return;
      return this.navigationFactory.navigateTo(redirection.url, redirection.state);
    }
  }

  private getRedirectUrl(url, doNotShowDisclaimer) {
    var url;
    var isSupportAdmin = this.sharedContext.loggedInUserInfo.isSupportAdmin === true;
    var isSuperAdmin = this.sharedContext.loggedInUserInfo.isSuperAdmin === true;
    var IsFCA = this.sharedContext.IsFCA == true;
    var settings = this.sharedContext.Settings;
    var isDemoAdmin = settings.IsDemoAdmin;

    if (!this.sharedContext.isImpersonating && this.sharedContext.isDemoMode3) {
      return this.redirection(this.pages.demo, false);
    }

    if (this.sharedContext.isImpersonating && !this.sharedContext.isRollOver && !this.sharedContext.IsNonParticipantManager && this.sharedContext.incentiveType !== 'PPI') {
      url = this.pages.compPlan;
    } else if (this.filterService.isFilterUpdated) {
      this.filterService.isFilterUpdated = false;
      url = this.checkForV1Page(this.sharedContext.current.name);
    } else {
      //landing page logic
      // url = url ? url : landing();
      if (!url) {
        if (isSupportAdmin || isSuperAdmin || IsFCA) {
          //admin persona
          if (this.sharedContext.isImpersonating) {
            url = this.checkForV1Page(this.pages.dashboard);
          } else {
            //if i,personation is OFF
            if (isSuperAdmin) {
              if (this.location.path().startsWith('/admin') || this.location.path().startsWith('/demo') || this.location.path().startsWith('/settings') || this.location.path().startsWith('/help')) {
                url = this.location.path();
              } else {
                url = this.pages.superAdmin;
              }
            } else {
              //supportAdmin and FCA
              url = this.pages.impersonation;
            }
          }
        } else {
          url = this.checkForV1Page(this.pages.dashboard);
        }
      }
    }

    if (
      (this.filterService.selectedFiscalYear >= 2019 && this.sharedContext.IsNonParticipantManager) ||
      this.sharedContext.IsNonParticipantManagerForced ||
      (this.sharedContext.isManager && (!this.sharedContext.isQuotaAcknowledged || !this.sharedContext.areAllTrainingsCompleted) && !this.sharedContext.isImpersonating)
    ) {
      url = this.pages.dashboard;
    }

    //If support admin is loaded with impersonation mode then no further navigation required
    if (this.sharedContext.isImpersonating === true) {
      this.sharedContextState.dispatch(
        new UpdateSharedContext({
          ...this.sharedContext,
          impersonatedParticipant: this.sharedContext.firstName + ' ' + this.sharedContext.lastName,
          impersonatedParticipantInitials: this.userProfile.getUserInitials(this.sharedContext.firstName, this.sharedContext.lastName)
        })
      );
      this.addUserToList(this.sharedContext.alias, this.sharedContext.impersonatedParticipant, this.sharedContext.impersonatedParticipantInitials, this.sharedContext.incentiveType);

      return this.redirection(url, false);
    }

    if (isDemoAdmin) {
      return this.redirection(this.pages.demo, false);
    }
    if (doNotShowDisclaimer) {
      this.sharedContext.Settings.Disclaimer.AcceptedDate = Date.now();
    }
    //For support admin or in demo mode, or web, always start with Disclaimer
    const isPPADisclaimerAvailable = this.sharedContext.Settings.Disclaimer && this.sharedContext.Settings.Disclaimer.DisclaimerId == 3;
    const isDisclaimerEnabled = this.sharedContext.Settings.Disclaimer && this.sharedContext.Settings.Disclaimer.DisclaimerId != 3 && this.sharedContext.Settings.Disclaimer.AcceptedDate == null;
    if (isDisclaimerEnabled || (this.sharedContext.isDemoMode2 && !this.disclaimerService.getDemoModeFlag()) || ((isSupportAdmin || isSuperAdmin || IsFCA) && isDisclaimerEnabled)) {
      //if (!IsFCA) {
      //    this.sharedContext.Settings.Disclaimer.AcceptedDate = Date.now();
      //}
      if (this.sharedContext.isDemoMode2) {
        this.disclaimerService.setDemoModeFlag(true);
      }
      return this.redirection(this.pages.disclaimers, false, url);
    } else {
      if (settings.Disclaimer && settings.Disclaimer.AcceptedDate == null && settings.Disclaimer.DisclaimerId != 3) {
        //General Disclaimer
        return this.redirection(this.pages.disclaimers, false, url);
      } else if (isPPADisclaimerAvailable) {
        //Evergreen PPA Disclaimer
        return this.redirection(this.pages.disclaimers, false, url);
      } else if (
        /*commenting ppa
                  else if ((ppaSignedStatus == null || ppaSignedStatus === 0) && !(this.sharedContext.IsNonParticipantManager || isSupportAdmin || IsFCA)) //PPA
                  {
                      showUnAuthorizedMessage(error.ppaSignedMessage.message);
                      return;// this.redirection(this.pages.error, true, null, error.ppaSignedMessage);
                  }*/
        this.sharedContext.isViewAs ||
        ((!this.sharedContext.isQuotaAcknowledged || !this.sharedContext.isPPASigned || !this.sharedContext.areAllTrainingsCompleted) &&
          !(this.sharedContext.IsNonParticipantManager || isSupportAdmin || isSuperAdmin || IsFCA))
      ) {
        //QA
        if (this.sharedContext.Compliance != null && this.userProfile.isSeller) {
          var qaCompliance = _.find(this.sharedContext.Compliance, d => {
            return d.Name == 'QA';
          });

          if (qaCompliance != null && qaCompliance.SignStatusId === 0 && !this.sharedContext.isPPAAvailable) {
            // If Both Compliance Status is FCA not approved and PPANotAvailable then show error page
            this.error.showFilter = true;
            this.sharedContext.isRollOver = true;
            return this.redirection(this.pages.error, false, null, this.error.qaFcaApprovalPending);
          }
        }
        if (this.sharedContext.isQuotaAckPostponed === true || (this.sharedContext.isQuotaAcknowledged && this.sharedContext.isRollOver == false && this.sharedContext.areAllTrainingsCompleted)) {
          // If Quota is Postponed or (QA is acknowledged and  isRollover is false) then show comp plan
          return this.sharedContext.incentiveType === 'PPI' ? this.redirection(this.pages.complianceDashboard, true, url) : this.redirection(this.pages.compPlan, false, url);
        } else if (!(this.sharedContext.isQuotaAcknowledged && this.sharedContext.isRollOver && this.sharedContext.areAllTrainingsCompleted)) {
          // Either QA is NOT acknowledged or isRollver is false then show compliance
          url = this.pages.complianceDashboard;
          return this.redirection(url);
        } else if (
          (this.sharedContext.isManager && this.sharedContext.QuotaFCAApprovalPending) ||
          (this.sharedContext.isQuotaAcknowledged && this.sharedContext.isRollOver && this.sharedContext.areAllTrainingsCompleted)
        ) {
          // Either user is Manager but his QA FCA Aproval pending OR (user has acknowledged Quota and is Rollver is true) the show dashboard
          if (this.filterService != null && this.filterService.selectedFiscalYear < 2019) {
            url = this.pages.dashboardV1;
          } else {
            url = this.pages.dashboard;
          }
          return this.redirection(url);
        } else {
          // Show compliance Dashboard
          return this.redirection(this.pages.complianceDashboard, false, url);
        }
      } else {
        if (this.userProfile.isSeller() === true && (this.filterService.selectedFiscalYear == null || this.filterService.selectedStandardTitle == null || this.filterService.selectedStepId == null)) {
          return this.redirection(this.pages.error, true, null, this.error.filterError);
        } else if (url === this.pages.impersonation || this.sharedContext.isRollOver || this.sharedContext.IsNonParticipantManager || isSupportAdmin || isSuperAdmin) {
          return this.redirection(url);
        }
        //if rollover is false redirecting to compplan as dashboard will not be available
        else {
          return this.redirection(this.pages.compPlan, false, url);
        }
      }
    }
  }

  public checkForV1Page(fallback): string {
    const page = this.location.path();
    let retPage;
    if (this.v1Pages[page]) {
      if (this.filterService != null && this.filterService.selectedFiscalYear < 2019) {
        retPage = this.v1Pages[page];
      } else {
        retPage = this.pages[page];
      }
    } else if (this.filterService.selectedFiscalYear == 2018) {
      retPage = this.v1Pages['dashboard'];
    } else {
      retPage = fallback;
    }
    return retPage;
  }

  public redirection(url, clearHistory = false, nextUrl = null, state = null) {
    return {
      url,
      clearHistory: clearHistory === true ? clearHistory : false,
      nextUrl,
      state
    };
  }

  public getConfiguration(): Promise<any> {
    this.setUserContext();

    return this.configurationService.getConfiguration(environment.mintapiBaseUrl, true).then(configResponse => {
      this.loadPageFeatureMasterData(configResponse);
      // this.configurationState.dispatch(new LoadConfigurationSuccess(configResponse));
      return configResponse;
    });
  }

  public setDataRefresh() {
    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        msSalesRefreshDateTime: this.sharedContext.msSalesRefreshDate ? (this.sharedContext.msSalesRefreshDate as any).toLocaleDateString() : 'N.A.'
      })
    );
  }

  public handleError(err) {
    this.spinner.hide();
    //TODO-WEB: better way?
    if (err != 'AlreadyLoggedIn') {
      if (err && err.MaintainanceMode == true) {
        return;
      } else {
        if (err && err.error == this.error.unAuthorized) {
          this.showUnAuthorizedMessage(err.state);
        } else {
          this.showError(err);
        }
      }
    }
  }

  public showUnAuthorizedMessage(state) {
    console.error(state);
    return this.navigationFactory.navigateTo(['error', 'unauthorized'], state);
  }

  public showError(err) {
    console.error(err);
    var message = null;
    if (err && err.message) {
      message = err.message;
    } else if (err && err.error && err.error.message) {
      message = err.error.message;
    }
    if (err && err.error && err.error.message && err.error.message == Code.ConfigNotFound) {
      this.navigationFactory.navigateTo(['error', 'unauthorized'], {
        message: message
      });
    } else {
      this.navigationFactory.navigateTo(['error', 'error'], {
        message: message
      });
    }
  }

  public resetFilters() {
    this.filterService.selectedStepId = null;
    this.filterService.selectedFiscalYear = null;
    this.filterService.selectedManagerViewFiscalYear = null;
    this.filterService.selectedStandardTitle = null;
    this.filterService.fiscalYearList = null;
    this.filterService.standardTitleList = null;
    this.filterService.managerDashboardPartitionsList = null;
    this.filterService.managerViewFiscalYears = null;
    this.filterService.payeeId = null;
    this.filterService.personnelNumber = null;
    this.filterService.selectedFiscalQuarterId = null;
    this.filterService.selectedFiscalSemesterId = null;
    this.filterService.isFilterUpdated = false;
    this.filterService.selectedManagerViewStepId = null;
    this.resetNgFilters();
  }

  public resetNgFilters() {
    this.filterService.selectedStepId = null;
    this.filterService.selectedFiscalYear = null;
    this.filterService.selectedManagerViewFiscalYear = null;
    this.filterService.selectedStandardTitle = null;
    this.filterService.fiscalYearList = null;
    this.filterService.standardTitleList = null;
    this.filterService.managerDashboardPartitionsList = null;
    this.filterService.managerViewFiscalYears = null;
    this.filterService.payeeId = null;
    this.filterService.personnelNumber = null;
    this.filterService.selectedFiscalQuarterId = null;
    this.filterService.selectedFiscalSemesterId = null;
    this.filterService.isFilterUpdated = false;
    this.filterService.selectedManagerViewStepId = null;
  }

  public saveFilterState() {
    const f = this.filterService;
    f.oldState = {};
    f.oldState.selectedStepId = f.selectedStepId;
    f.oldState.selectedFiscalYear = f.selectedFiscalYear;
    f.oldState.selectedManagerViewFiscalYear = f.selectedManagerViewFiscalYear;
    f.oldState.selectedStandardTitle = f.selectedStandardTitle;
    f.oldState.fiscalYearList = f.fiscalYearList;
    f.oldState.standardTitleList = f.standardTitleList;
    f.oldState.payeeId = f.payeeId;
    f.oldState.personnelNumber = f.personnelNumber;
    f.oldState.selectedFiscalQuarterId = f.selectedFiscalQuarterId;
    f.oldState.selectedFiscalSemesterId = f.selectedFiscalSemesterId;
    f.oldState.selectedManagerViewStepId = f.selectedManagerViewStepId;
    this.saveNgFilterState();
  }

  public saveNgFilterState() {
    const f = this.filterService;
    f.oldState = {};
    f.oldState.selectedStepId = f.selectedStepId;
    f.oldState.selectedFiscalYear = f.selectedFiscalYear;
    f.oldState.selectedManagerViewFiscalYear = f.selectedManagerViewFiscalYear;
    f.oldState.selectedStandardTitle = f.selectedStandardTitle;
    f.oldState.fiscalYearList = f.fiscalYearList;
    f.oldState.standardTitleList = f.standardTitleList;
    f.oldState.payeeId = f.payeeId;
    f.oldState.personnelNumber = f.personnelNumber;
    f.oldState.selectedFiscalQuarterId = f.selectedFiscalQuarterId;
    f.oldState.selectedFiscalSemesterId = f.selectedFiscalSemesterId;
    f.oldState.selectedManagerViewStepId = f.selectedManagerViewStepId;
  }

  public loadOldFilterState() {
    const f = this.filterService;

    f.selectedStepId = f.oldState.selectedStepId;
    f.selectedFiscalYear = f.oldState.selectedFiscalYear;
    f.selectedManagerViewFiscalYear = f.oldState.selectedManagerViewFiscalYear;
    f.selectedStandardTitle = f.oldState.selectedStandardTitle;
    f.fiscalYearList = f.oldState.fiscalYearList;
    f.standardTitleList = f.oldState.standardTitleList;
    f.payeeId = f.oldState.payeeId;
    f.personnelNumber = f.oldState.personnelNumber;
    f.selectedFiscalQuarterId = f.oldState.selectedFiscalQuarterId;
    f.selectedFiscalSemesterId = f.oldState.selectedFiscalSemesterId;
    f.selectedManagerViewStepId = f.oldState.selectedManagerViewStepId;
    this.filterService.isManagerViewPartitionLoaded = false;
    this.loadOldNgFilterState();
  }

  public loadOldNgFilterState() {
    const f = this.filterService;

    f.selectedStepId = f.oldState.selectedStepId;
    f.selectedFiscalYear = f.oldState.selectedFiscalYear;
    f.selectedManagerViewFiscalYear = f.oldState.selectedManagerViewFiscalYear;
    f.selectedStandardTitle = f.oldState.selectedStandardTitle;
    f.fiscalYearList = f.oldState.fiscalYearList;
    f.standardTitleList = f.oldState.standardTitleList;
    f.payeeId = f.oldState.payeeId;
    f.personnelNumber = f.oldState.personnelNumber;
    f.selectedFiscalQuarterId = f.oldState.selectedFiscalQuarterId;
    f.selectedFiscalSemesterId = f.oldState.selectedFiscalSemesterId;
    f.selectedManagerViewStepId = f.oldState.selectedManagerViewStepId;
    this.filterService.isManagerViewPartitionLoaded = false;
  }

  public setFilters(forceLoad) {
    let sharedContext = {
      ...this.sharedContext,
      appliedFilter: JSON.parse(JSON.stringify(this.sharedContext.appliedFilter)),
      FiscalYears: this.sharedContext.FiscalYears ? [...this.sharedContext.FiscalYears].sort() : null,
      ManagerViewFiscalYears: this.sharedContext.ManagerViewFiscalYears ? [...this.sharedContext.ManagerViewFiscalYears].sort() : null
    };

    if (!this.isReload || forceLoad === true) {
      //Do not set, if already
      this.filterService.fiscalYearList = sharedContext.FiscalYears == null ? null : sharedContext.FiscalYears;
      this.filterService.standardTitleList = sharedContext.StandardTitles;
      this.filterService.managerDashboardPartitionsList = sharedContext.managerDashboardPartitionsList;
      this.filterService.managerViewFiscalYearsList = sharedContext.ManagerViewFiscalYears == null ? null : sharedContext.ManagerViewFiscalYears;

      //TODO: To be removed once shared.appliedFilter usage is cleaned up from my comp. plan, reports
      sharedContext.appliedFilter.fiscalYear = Number(sharedContext.DefaultFilter.FiscalYear);
      sharedContext.appliedFilter.standardTitle = sharedContext.DefaultFilter.StandardTitle;
      sharedContext.appliedFilter.stepId = sharedContext.DefaultFilter.StepId;
      sharedContext.paylineViewType = sharedContext.DefaultFilter.StandardTitle ? sharedContext.DefaultFilter.StandardTitle.PaylineViewType : 0;

      this.filterService.selectedFiscalYear = Number(sharedContext.DefaultFilter.FiscalYear);

      if (this.filterService.managerViewFiscalYearsList && this.filterService.managerViewFiscalYearsList.length > 0) {
        this.filterService.selectedManagerViewFiscalYear = Number(this.filterService.managerViewFiscalYearsList[0].Key);
      }

      this.filterService.selectedStandardTitle = sharedContext.DefaultFilter.StandardTitle;
      this.filterService.selectedStepId = sharedContext.DefaultFilter.StepId;
      this.filterService.selectedFiscalQuarterId = sharedContext.DefaultFilter.FiscalQuarterId;
      this.filterService.selectedFiscalSemesterId = sharedContext.DefaultFilter.FiscalSemesterId;
      this.filterService.payeeId = sharedContext.DefaultFilter.StandardTitle ? sharedContext.DefaultFilter.StandardTitle.PayeeId : null;
      this.filterService.personnelNumber = sharedContext.personnelNumber;
      this.filterService.selectedManagerViewStepId = sharedContext.DefaultFilter.StepId;
    }

    if (this.filterService.oldState && sharedContext.isImpersonating === false && sharedContext.isViewAs === false) {
      this.filterService.selectedFiscalYear = this.filterService.oldState.selectedFiscalYear;
      this.filterService.selectedStandardTitle = this.filterService.oldState.selectedStandardTitle;
      this.filterService.selectedStepId = this.filterService.oldState.selectedStepId;
      this.filterService.selectedFiscalQuarterId = this.filterService.oldState.selectedFiscalQuarterId;
      this.filterService.selectedFiscalSemesterId = this.filterService.oldState.selectedFiscalSemesterId;
      this.filterService.selectedManagerViewStepId = this.filterService.oldState.selectedManagerViewStepId;
      this.filterService.selectedManagerViewFiscalYear = this.filterService.oldState.selectedManagerViewFiscalYear;

      this.filterService.oldState = null;
    }

    this.sharedContextState.dispatch(new UpdateSharedContext(sharedContext));
    // this.setNgFilters(forceLoad);
  }

  public authorize(skipAuthorization) {
    if (skipAuthorization) {
      return Promise.resolve(null);
    }

    var promise = this.authorizationService.authorizeAndGetParticipant();
    this.spinner.show('Authorizing...');

    return promise
      .then(profile => {
        var authResult = profile.Access;
        this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, Settings: profile.Settings }));

        // Setting up data i.e consumed by SNOW popup only when user is a valid AD user but invalid PE user.
        this.userProfile.setSNOWProfile(profile);

        if (authResult && (authResult.IsAuthorized === true || authResult.ErrorCause == 'DisclaimerNotAccepted')) {
          this.isMsSalesAuthorized = true;
          return Promise.resolve(profile);
        } else {
          this.isMsSalesAuthorized = false;
          return Promise.reject({
            error: this.error.unAuthorized,
            state: authResult
          });
        }
      })
      .catch(error => {
        this.isMsSalesAuthorized = false;
        return Promise.reject({
          error: this.error.authorizeAndUserLoad,
          state: error
        });
      });
  }

  public setUserContext() {
    let userInfo = this.authenticationService.getUserInfo();
    let alias = userInfo.userName.split('@')[0];
    const vm = this;
    let thumbnailPhoto = this.graphService.getThumbnailPhoto(alias).subscribe(thumbnailPhoto => {
      vm.sharedContextState.dispatch(
        new UpdateSharedContext({
          ...vm.sharedContext,
          userProfileInfo: {
            ...vm.sharedContext.userProfileInfo,
            thumbnailPhoto
          }
        })
      );
    });

    var firstName = userInfo.profile.given_name;
    var lastName = userInfo.profile.family_name;

    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        userProfileInfo: {
          ...userInfo,
          userInitials: this.userProfile.getUserInitials(firstName, lastName)
        }
      })
    );

    // Setting up data i.e consumed by SNOW popup only when user is a valid AD user but invalid PE user.
    this.userProfile.setSNOWProfile({
      FirstName: firstName,
      LastName: lastName,
      UserAlias: alias
    });
  }

  public loadUserProfileSettings() {
    var promise = this.userProfile
      .loadProfile({ ...this.sharedContext })
      .then(result => {
        this.userProfile.loadSettings({ ...this.sharedContext });
        return result;
      })
      .then(profile => {
        if (profile == null) {
          throw 'User profile not found';
        }
        return profile;
      })
      .catch(a => {
        return Promise.reject({
          error: this.error.userLoad,
          state: a
        });
      });

    this.spinner.show();

    return promise;
  }

  public setUserProfileSettings(profile) {
    let settings = this.sharedContext.Settings;

    let sharedContext = { ...this.sharedContext, loggedInUserInfo: JSON.parse(JSON.stringify(this.sharedContext.loggedInUserInfo)) };

    if (sharedContext.IsFCA) {
      sharedContext.loggedInUserInfo.isFCA = sharedContext.IsFCA;
    }

    if (settings.IsSupportAdmin) {
      sharedContext.loggedInUserInfo.isSupportAdmin = settings.IsSupportAdmin;
      if (sharedContext.userProfileInfo !== null) {
        //var uniqueName = (__isUWP__ && !__isUWPHosted__ ? sharedContext.userInfo.unique_name : sharedContext.userInfo.profile.unique_name);
        var uniqueName = sharedContext.userProfileInfo.userName;
        sharedContext.loggedInUserInfo.userAlias = uniqueName.split('@')[0];
        sharedContext.loggedInUserInfo.userName = sharedContext.userProfileInfo.name;
      }
      if (settings.IsSuperAdmin) {
        sharedContext.loggedInUserInfo.isSuperAdmin = settings.IsSuperAdmin;
      }
    } else if (sharedContext.IsNonParticipantManager) {
      sharedContext.toggleView = 1;
    }
    //Only super admins can create tours
    sharedContext.canCreateTour = sharedContext.loggedInUserInfo.isSuperAdmin;
    this.navigationFactory.handleOptOut();
    this.sharedContextState.dispatch(new UpdateSharedContext(sharedContext));
    return Promise.resolve(settings);
  }

  public TimeoutAndNavigate(url, state) {
    var milliseconds = 2000;
    if (url === this.pages.complianceDashboard) {
      setTimeout(() => {
        this.navigationFactory.navigateToSubView(this.pages.complianceDashboard, { name: 'My Compliance', active: false, index: 0 });
      }, milliseconds);
    } else {
      setTimeout(() => {
        this.navigationFactory.navigateTo(url, state);
      }, milliseconds);
    }
  }

  public addUserToList(alias, fullName, nameInitials, incentiveType) {
    if (alias != null && alias.startsWith('Test')) {
      return;
    }

    this.readFileAndDontCreateIfNotExists().then(response => {
      var listToShow = response;
      var aliasList: string[] = [];
      var user = {};
      for (const a of listToShow) {
        aliasList.push(a.alias);
      }
      var doesExist = aliasList.includes(this.sharedContext.alias);
      if (!doesExist) {
        if (listToShow.length === 10) {
          listToShow.pop();
        }
        this.graphService
          .getThumbnailPhoto(alias)
          .toPromise()
          .then(
            data => {
              user = {
                alias: alias,
                thumbnailPhoto: data,
                fullName: fullName,
                nameInitials: nameInitials,
                incentiveType: incentiveType
              };
              listToShow.unshift(user);
              this.writeUserObjectToLocalFile(listToShow);
            },
            error => {
              user = {
                alias: alias,
                thumbnailPhoto: null,
                fullName: fullName,
                nameInitials: nameInitials,
                incentiveType: incentiveType
              };
              listToShow.unshift(user);
              this.writeUserObjectToLocalFile(listToShow);
              return new Promise(() => listToShow);
            }
          );
      } else {
        var index = aliasList.indexOf(alias);
        user = listToShow[index];
        listToShow.splice(index, 1);
        listToShow.unshift(user);
        this.writeUserObjectToLocalFile(listToShow);
        new Promise(() => listToShow);
      }
    });
  }

  private writeUserObjectToLocalFile(listToShow) {
    if (listToShow.length === 0) {
      this.deleteAliasHistory();
    } else {
      localStorage.setItem(this.sharedContext.fileName, JSON.stringify(listToShow));
    }
  }

  public deleteAliasHistory() {
    localStorage.removeItem(this.sharedContext.fileName);
  }

  private readFileAndDontCreateIfNotExists(): Promise<any[]> {
    var userList = [];
    var fileContent = localStorage.getItem(this.sharedContext.fileName);

    if (fileContent && fileContent.length > 0) {
      userList = JSON.parse(fileContent);
    }
    return Promise.resolve(userList);
  }
}
