<div id="grid-container_{{containerId}}" class="table-responsive">
  <table id="grid-table_{{containerId}}" class="table">
    <caption id="grid-caption_{{containerId}}" class="sr-only">
      {{gridCaption}}
    </caption>
    <thead id="grid-header_{{containerId}}">
      <tr id="grid-header-row_{{containerId}}">
        <ng-container *ngFor="let col of visibleColumns; let i = index">
          <th id="grid-header-cell_{{containerId}}_{{ i }}" [id]="col.field" *ngIf="col.isVisible" [style.width]="col.width ? col.width + 'px' : 'auto'" class="text-center column-header">
            {{ col.title }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody id="grid-body{{containerId}}">
      <ng-container *ngFor="let row of data; let i = index">
        <tr id="grid-data-row{{containerId}}" [ngClass]="row.cssClasses">
          <ng-container *ngFor="let col of visibleColumns; let j = index">
            <td id="grid-data-cell_{{containerId}}" *ngIf="!row.isRowHiddenForRowSpan[col.field]" [attr.rowspan]="row.rowspan[col.field] ? row.rowspan[col.field] : 1">
              {{row.data[col.field]}}
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>