import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleFeatureService } from '@mint-libs/context';

import * as Ng1Services from '../hybrid/ng1-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class ConsolidatedBonusGuard implements CanActivate {
  constructor(private rolefeatureService: RoleFeatureService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.rolefeatureService.isPageApplicable(this.rolefeatureService.pages.ConsolidatedBonus) !== undefined;
  }
}
