<div [id]="'metric-tile_1_' + componentId" class="stat__icon" *ngIf="tileData?.svg" [innerHTML]="tileData?.svg"></div>
<div [id]="'metric-tile_2_' + componentId" class="stat__details">
  <div [id]="'metric-tile_3_' + componentId" *ngIf="tileData?.amount" [ngClass]="[tileDataValueClass]" class="stat__details-value">{{ tileData?.amount }}</div>
  <div [id]="'metric-tile_3_' + componentId" *ngIf="!tileData?.amount" [ngClass]="[tileDataAlternateClass]" class="stat__details-alternate">{{ tileData?.alternate }}</div>
  <div [id]="'metric-tile_4_' + componentId" class="stat__details-desc">
    <div [id]="'metric-tile_5_' + componentId" [ngClass]="[tileDataTextClass]" class="stat__details-desc__text">{{ tileData?.amountTitle }}</div>
    <mint-tooltip *ngIf="tileData?.toolTipText" [tipText]="tileData?.toolTipText" [size]="tileData?.toolTipSize" [id]="'metric-tile_6_' + componentId"> </mint-tooltip>
    <!-- <div [id]="'metric-tile_6_'+componentId" *ngIf="tileData.toolTipText" class="stat__info">
      <div [id]="'metric-tile_7_'+componentId" class="icon icon-info"></div>
      <div [id]="'metric-tile_8_'+componentId" class="stat__tooltip">
        <div [id]="'metric-tile_9_'+componentId" class="stat__tooltip__header">{{ tileData.toolTipHeading }}</div>
        <p [id]="'metric-tile_10_'+componentId" class="stat__tooltip__text">{{ tileData.toolTipText }}</p>
      </div>
    </div> -->
  </div>
</div>
