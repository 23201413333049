import { Injectable, Inject, Attribute } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../core/network/data.service';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Formatter } from '@mint-libs/common';
import { plainToClass, ClassTransformOptions } from 'class-transformer';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { CustomHttpParams } from '../core/network/custom-http-params';
import { ContextState } from '@mint-libs/context';
import { selectConfigurationState } from '@mint-libs/configuration';
import { select, Store } from '@ngrx/store';
import { BonusParticipationDetails } from '../shared/models/bonus-participation-details.model';
import { BonusApiDataService } from  '../core/network/bonus-api-data.service'
import { UserProfileService } from '../core/context/user-profile.service';

// import { DetailDataService } from '../../core/network/detail-data.service';
@Injectable({ providedIn: 'root' })
export class BonusService {
  latestFiscalYear: any;

  constructor(
    private endpoint: EndpointService,
    private dataService: DataService,
    private bonusDataService: BonusApiDataService,
    private formatter: Formatter,
    public userProfileService: UserProfileService,
    private contextState: Store<ContextState>
  ) {
    this.getConfigDetails();
  }

  // // getCagrAccounts() {
  // //   return this.dataService.getWithGlobalFilters<any>(this.endpoint.GetCagrAccounts.uri, null);
  // // }
  // // getCagrAccountsDetails(AccountId: any) {
  // //   return this.dataService.getWithGlotebalFilters<any>(this.endpoint.GetCagrAccountsDetails.uri, null);
  // // }

  getConfigDetails() {
    this.contextState
      .pipe(
        select(selectConfigurationState),
        filter(val => val !== undefined)
      )
      .subscribe(state => {
        this.latestFiscalYear = state.configs.latestFiscalYear;
      });
  }

  getBonusAttainmentDetails(participationId): Observable<any> {
   this.endpoint.GetBonusDetails.params["ParticipationId"]=participationId;
    const response = this.bonusDataService.get<any[]>(this.endpoint.GetBonusDetails.uri, this.endpoint.GetBonusDetails.params);

    return response.pipe(
      map(response => {

        // 1. Map Bingo fields
          this.mapBingoColumns(response);
        // 2. Sort All Bonus Attributes Aplhabatically
        this.sortBonusAttributes(response);

        // 3. Set bingo Attainment like 3 / 5 (where 3 is total unlocked and 5 is total components)
        this.setBingoComponentAttainment(response);

        // 4. Set Tooptip for Bonus field
        this.setBonusTooltip(response);

        // 5. Increase Attainment to 100% if component is unlocked but the attainment is 0%
        // This was particularly done for Unified component Task 8333673
        this.setAdjustmentForUnifiedComponentAttainment(response);
       return response;
      })
    );

  }

  mapBingoColumns(response) :void {

    response.forEach(bonusParticipation => {

      bonusParticipation.BonusAttributes.forEach(bonusAttribute => {
        if(bonusAttribute.CalculationType == "All") {
          bonusAttribute.GrainBonusAmount= bonusAttribute.BingoBonusAmount;
          bonusAttribute.PercentageValue=bonusAttribute.BingoBonusPercentage;
          // bonusAttribute.Actual= '';
          // bonusAttribute.Quota= '';
        }
      });
    });
   }

   sortBonusAttributes(response):void {

    response.forEach(bonusParticipation => {
        if(bonusParticipation.BonusAttributes != undefined && bonusParticipation.BonusAttributes.length > 0) {
          bonusParticipation.BonusAttributes = bonusParticipation.BonusAttributes.sort((a,b)=> {
            if(a.PlanComponentName == undefined && b.PlanComponentName != undefined ) {
              return 1;
            } else if (a.PlanComponentName != undefined && b.PlanComponentName == undefined) {
              return -1;
            } else if(a.PlanComponentName == undefined && b.PlanComponentName == undefined) {
              return 0;
            } else {
              return a.PlanComponentName.localeCompare(b.PlanComponentName);
            }
         });
         }
    });



   }

   setBingoComponentAttainment(response):void {
    response.forEach(bonusParticipation => {

      const allNonBingoAttributes = bonusParticipation.BonusAttributes.filter(x=> x.CalculationType !="All");

      const unlockedAttributes = allNonBingoAttributes.filter(x => x.LockStatus == false);

      const bingoAttributes = bonusParticipation.BonusAttributes.filter(x=> x.CalculationType =="All");

      bingoAttributes.forEach(bingoAttribute => {
        bingoAttribute["BingoAttainment"] = " " + unlockedAttributes.length + "/" + allNonBingoAttributes.length;

        if(bingoAttribute["Attainment"]) {
         bingoAttribute["Attainment"] = unlockedAttributes.length / allNonBingoAttributes.length * 100;
        }
      });

  });

   }

   setAdjustmentForUnifiedComponentAttainment(response):void {
    response.forEach(bonusParticipation => {

      const allNonBingoAttributes = bonusParticipation.BonusAttributes.filter(x=> x.CalculationType !="All");

      const allUnlockedAttributesWithZeroAttainment = allNonBingoAttributes.filter(x => x.LockStatus == false && x.Attainment == 0);

      allUnlockedAttributesWithZeroAttainment.forEach(attribute => {
        attribute.Attainment=100;
      });

  });

   }

   setBonusTooltip(response):void {

    response.forEach(bonusParticipation => {

      bonusParticipation.BonusAttributes.forEach(bonusAttribute => {

        let percentageValue = 0;
        if(bonusAttribute.CalculationType =='All') {
          percentageValue = bonusAttribute['BingoBonusPercentage'];
        } else {
          percentageValue = bonusAttribute['PercentageValue'];
        }

        if(bonusAttribute.LockStatus === true) {

          bonusAttribute['BonusTooltip'] = 'The bonus is currently locked. To unlock ' + percentageValue  +  '% of your ITA  you  need  to  top  the target   or   threshold  of   this   product (whichever is higher)';
        } else {
          bonusAttribute['BonusTooltip'] = 'Congratulations! on achieving ' + percentageValue  +  '% of your ITA';
        }
      });
    });

   }


  }
