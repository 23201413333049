<div id="impersonation-notification_1" class="notification-alert impersonation-notification alert-outline" role="alert">
  <div id="impersonation-notification_2" class="row alert-message">
    <div id="impersonation-notification_3" class="col-xs-12">
      <p id="impersonation-notification_4" aria-live="polite">
        <span id="impersonation-notification_45" *ngIf="!sharedContext.isDemoMode3">
          <!-- <strong id="impersonation-notification_5" *ngIf="impersonationService.isNameAvailable"
            >Hi, {{ sharedContext.loggedInUserInfo.userName }}! You are impersonating as {{ impersonationService.impersonatedParticipant }}
            <span id="impersonation-notification_6" class="text-lowercase">({{ impersonationService.impersonatingAlias }})</span></strong
          > -->
          <strong id="impersonation-notification_7">You are {{bannerText}} as <span id="impersonation-notification_8"
              class="text-lowercase">{{ sharedContext.impersonatingAlias }}</span></strong>
          <strong id="impersonation-notification_9" *ngIf="isFCAQuotaApprovalPendingError"
            class="text-danger">(Quota)approval is pending</strong>
          <strong id="impersonation-notification_10" *ngIf="isFCAQuotaApprovalPending"
            class="text-danger">(Quota)approval is pending</strong>
        </span>
        <span id="impersonation-notification_13" *ngIf="sharedContext.isDemoMode3"> <strong
            id="impersonation-notification_14"> Demo Mode </strong> </span>
        <button class="icon icon-close stop-impersonation" id="impersonation-notification_12" type="button"
          title="Stop Impersonation" attr.aria-label="Stop Impersonating {{sharedContext.impersonatingAlias}}"
          (click)="impersonationService.stopImpersonation(true)"></button>
      </p>
    </div>
  </div>
</div>