<div class="disclaimer-notf">
<app-impersonation-notification *ngIf="impersonationService.isImpersonatingOrViewAs()" class="impersonation" tabindex="0" ariaLabelledBy="title" analytics-tracker [tracker]="'User_Impersonation'">
</app-impersonation-notification>
</div>
<div id="disclaimer_1" class="disclaimer-container">
  <div class="no-focus" *ngIf="showDisclaimer" id="disclaimer-info" tabindex="-1">
    <h3 id="disclaimer_4" style="font-size: x-large; padding-bottom: 20px"><b id="disclaimer_5">Disclaimer</b></h3>

    <p id="disclaimer_6" [innerHtml]="disclaimerText"></p>
    <br id="disclaimer_7" />

    <div id="disclaimer_11" class="disclaimer-btn-container">
      <button
        id="disclaimer_12"
        type="button"
        class="btn btn-dark"
        name="I Accept"
        title="I Accept"
        (click)="onAcceptance(disclaimer)"
        analytics-tracker="Disclaimer_I_Accept"
        analytics-type="Click"
        analytics-element="Button"
        analytics-source="Disclaimer"
      >
        I Accept
      </button>
    </div>
  </div>
</div>
