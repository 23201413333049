import { Component, OnInit, ElementRef, OnChanges, Input } from '@angular/core';
import * as d3 from 'd3';
import { Formatter } from '@mint-libs/common';
import * as _ from 'underscore';

@Component({
  selector: 'mint-metric-stack-chart',
  templateUrl: './metric-stack-chart.component.html',
  styleUrls: ['./metric-stack-chart.component.scss']
})
export class MetricStackChartComponent implements OnInit, OnChanges {
  constructor(private element: ElementRef, private formatter: Formatter) {}

  @Input() dataSet: any;
  @Input() chartHelpText: string;
  @Input() containerId: string;

  ngOnInit() {}

  ngOnChanges() {
    if (this.dataSet && this.dataSet.length > 0) {
      this.buildStackchart();
    } else if (this.dataSet && this.dataSet.length === 0) {
      d3.select(this.element.nativeElement).html('No data available');
    }
  }

  buildStackchart() {
    const target = d3
      .select(this.element.nativeElement)
      .node()
      .getBoundingClientRect();
    // DATA
    // var data = [
    //   {
    //     category: "test1",
    //     type1: 10,
    //     type2: 20
    //   },
    //   {
    //     category: "test2",
    //     type1: 30,
    //     type2: 40
    //   }
    // ];
    const keys = Object.keys(this.dataSet[0]).filter(k => k !== 'category' && k !== 'toolTipData');

    // INITIAL SETUP
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const barWidthBuffer = 90;
    const calcWidth = this.dataSet.length * barWidthBuffer > target.width - margin.left - margin.right ? this.dataSet.length * barWidthBuffer : target.width - margin.left - margin.right;
    const width = calcWidth;
    const height = 268 - margin.top - margin.bottom;
    const barwidth = 40;
    let descText = '';
    let yTicks = 7;
    const formatValue = d3.format('.3~s');
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const pccTooltipWidth = 280;
    const pccTooltipHeight = 270;
    const sideBarWidth = 50;

    const isPercentMetric = this.dataSet && this.dataSet.length > 0 && this.dataSet[0].toolTipData && this.dataSet[0].toolTipData.metric === 'Percent';


    if (isPercentMetric) {
      yTicks = 10;
    }

    const getToolTipData = toolTipData => {
      console.log('tooltip data', toolTipData);
      // const formatter: Formatter = new Formatter();
      let html = `<div class="data__source__item">
        <div class="perf__container">
        <div class="perf__data">
        <div class="perf__data__text">Target: </div>
        <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.totalTarget, toolTipData.metric)}</div>
        </div>
        <div class="perf__data">
        <div class="perf__data__text">Source Revenue (A): </div>
        <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.totalActual, toolTipData.metric)}</div>
        </div>
        <div class="perf__data">
        <div class="perf__data__text">Manual Adjustments (B): </div>
        <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.actualAdjustmentAmount, toolTipData.metric)}</div>
        </div>
        <div class="perf__data">
        <div class="perf__data__text">Modifier (C): </div>
        <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.actualModifier, toolTipData.metric)}</div>
        </div>
        <div class="perf__data">
        <div class="perf__data__text">Total Revenue (A+B+C): </div>
        <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.totalRevenue, toolTipData.metric)}</div>
        </div>
        </div>`;

      if (toolTipData.hasCustomerTag > 0) {
        html += `<div class="data__revenue">
          <div class="data__revenue__txt">This is an under-penetrated account</div>
          </div>
          </div>`;
      } else {
        html += `</div>`;
      }
      return html;
    };

    const toolTipHtml = (d: any) => {
      return (
        '<div class="bar__tooltip__container bar__tooltip__container-pccAcc">' +
        '<div class="bar__tooltip__header">' +
        '<div class="bar__tooltip__header__txt">' +
        d.data.category +
        '</div>' +
        '<div class="bar__tooltip__close">' +
        '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        `<path d="M9.85242 0.851639L5.70944 5.00004L9.85242 9.14844L9.14844 9.85164L5.00043 5.70904L0.852417 9.85164L0.148438
        9.14844L4.29141 5.00004L0.148438 0.851639L0.852417 0.148438L5.00043 4.29104L9.14844 0.148438L9.85242 0.851639Z" fill="#333333" />` +
        '</svg>' +
        '</div>' +
        '</div>' +
        '<div class="data__source__container">' +
        '<div class="data__source__item">' +
        '<div class="perf__container">' +
        getToolTipData(d.data.toolTipData) +
        // '<div class="perf__data">' +
        // '<div class="perf__data__text">Target: </div>' +
        // '<div class="perf__data__val">100,200,000 USD</div>' +
        // '</div>' +
        // '<div class="perf__data">' +
        // '<div class="perf__data__text">Source Revenue(A): </div>' +
        // '<div class="perf__data__val"> 100,000,000 USD</div>' +
        // '</div>' +
        // '<div class="perf__data">' +
        // '<div class="perf__data__text">Manual Adjustments (B): </div>' +
        // '<div class="perf__data__val">100,000 USD</div>' +
        // '</div>' +
        // '<div class="perf__data">' +
        // '<div class="perf__data__text">Modifier (C): </div>' +
        // '<div class="perf__data__val">100,000 USD</div>' +
        // '</div>' +
        // '<div class="perf__data">' +
        // '<div class="perf__data__text">Total Revenue ( A+B+C): </div>' +
        // '<div class="perf__data__val">100,200,000 USD</div>' +
        // '</div>' +
        // '</div>' +
        // '<div class="data__revenue">' +
        // '<div class="data__revenue__txt">Customer Adds Revenue</div>' +
        // '<div class="data__revenue__val">100,000 USD</div>' +
        // '</div>' +
        '</div>' +
        '</div>' +
        '</div>'
      );
    };

    d3.select(this.element.nativeElement).html('');

    const graphContainer = d3
      .select(this.element.nativeElement)
      .append('div')
      .attr('class', 'graph__container');

    const graphSvg = graphContainer
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('role', 'img')
      .attr('tabindex', '0')
      .attr('focusable', 'true')
      .attr('aria-labelledby', this.containerId + '-title' + '  ' + this.containerId + '-multi-desc')
      .attr('class', 'stacked__bar__chart');

    _.each(this.dataSet, function(bar) {
      descText += bar.category + ' has revenue of ' + (bar.type1 + bar.type2) + ' , ';
    });

    graphSvg
      .append('title')
      .attr('id', this.containerId + '-title')
      .text(this.chartHelpText);

    graphSvg
      .append('desc')
      .attr('id', this.containerId + '-multi-desc')
      .text(descText);

    const graph = graphSvg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // Tool Tip
    const div = d3
      .select(this.element.nativeElement)
      .append('div')
      .attr('class', 'bar__tooltip');
    // Tool Tip for click
    const div2 = d3
      .select(this.element.nativeElement)
      .append('div')
      .attr('class', 'bar__tooltip bar__tooltip-click');

    // SCALES
    const x = d3
      .scaleBand()
      .domain(
        this.dataSet.map(function(d) {
          return d.category;
        })
      )
      .range([0, width]);

    const y = d3.scaleLinear().range([height, 0]);

    const z = d3.scaleOrdinal().range(['#00AE56', '#023900']);
    // 0B6A0B
    // AXES
    const xAxis = d3.axisBottom(x).tickSizeOuter(0);
    const xAxisG = graph
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);
    xAxisG.selectAll('.tick text').attr('style', 'display:none');
    xAxisG.selectAll('.tick line').attr('style', 'display:none');
    xAxisG.selectAll('path.domain').attr('style', 'display:none');

    // Formatting bar label
    xAxisG
      .selectAll('g')
      .append('svg:foreignObject')
      .attr('x', () => {
        return -x.bandwidth() / 2;
      })
      .attr('width', x.bandwidth())
      .attr('height', 24)
      .append('xhtml:div')
      .attr('title', (i: any) => i)
      .attr('class', 'wrap__text')
      .html(function(i: any) {
        return `<div>${i}</div>`;
      });

    let yAxis: any;
    if (isPercentMetric) {
      yAxis = d3
        .axisLeft(y)
        .tickSizeOuter(0)
        .ticks(yTicks)
        .tickSize(-width)
        .tickPadding(10);
    } else {
      yAxis = d3
        .axisLeft(y)
        .tickSizeOuter(0)
        .ticks(yTicks)
        .tickSize(-width)
        .tickPadding(10)
        .tickFormat(formatValue);
    }

    z.domain(keys);

    // PREPARING STACK
    const stack = d3
      .stack()
      .keys(keys)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    const layers = stack(this.dataSet);

    y.domain([0, 1.15 * d3.max(layers[layers.length - 1], (d: any) => d[1])]);
    const yAxisG = graph
      .append('g')
      .attr('class', 'y-axis')
      .call(yAxis);

    yAxisG.select('path').attr('style', 'display:none');

    yAxisG.selectAll('.tick line').attr('style', 'stroke: #E0E0E0');

    // Handle mouse events

    const handleOnClick = (d: any) => {
      const diffWidth = vw - d3.event.pageX - sideBarWidth;
      div.style('display', 'none');

      div2.html(toolTipHtml(d));
      div2.select('.bar__tooltip__close').on('click', function() {
        div2.style('display', 'none');
      });
      div2.style('top', d3.event.pageY - pccTooltipHeight + 'px');

      // To check if tip going out of the window
      if (diffWidth > pccTooltipWidth / 2) {
        div2.style('left', d3.event.pageX - pccTooltipWidth / 2 + 'px');
      } else {
        div2.style('left', d3.event.pageX - (pccTooltipWidth - diffWidth) + 'px');
      }

      div2
        .transition()
        .duration(200)
        .style('display', 'block');
    };

    const handleMouseover = (d: any) => {
      const diffWidth = vw - d3.event.pageX - sideBarWidth;

      div.html(toolTipHtml(d));
      div.style('top', d3.event.pageY - pccTooltipHeight + 'px');

      // To check if tip going out of the window
      if (diffWidth > pccTooltipWidth / 2) {
        div.style('left', d3.event.pageX - pccTooltipWidth / 2 + 'px');
      } else {
        div.style('left', d3.event.pageX - (pccTooltipWidth - diffWidth) + 'px');
      }
      div
        .transition()
        .duration(200)
        .style('display', 'block');
    };

    const handleMouseout = () => {
      div
        .transition()
        .duration(500)
        .style('display', 'none');
    };

    const layer = graph
      .selectAll('.layer')
      .data(layers)
      .enter()
      .append('g')
      .attr('class', 'layer')
      .style('fill', function(d: any) {
        return z(d.key);
      });

    layer
      .selectAll('rect')
      .data(function(d: any) {
        return d;
      })
      .enter()
      .append('rect')
      .attr('class', 'bar-placeholder')
      .attr('x', function(d: any) {
        return x(d.data.category) + x.bandwidth() / 2 - 20;
      })
      .attr('height', function(d: any) {
        return height;
      })
      .attr('width', barwidth)
      .on('click', function(d: any) {
        handleOnClick(d);
      })
      .on('mouseover', function(d: any) {
        handleMouseover(d);
      })
      .on('mouseout', function() {
        handleMouseout();
      });

    layer
      .selectAll('bar-placeholder')
      .data(function(d: any) {
        return d;
      })
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', function(d: any) {
        return x(d.data.category) + (x.bandwidth() - barwidth) / 2;
      })
      .attr('y', function(d: any) {
        return y(d[1]);
      })
      .attr('height', function(d: any) {
        return y(d[0]) - y(d[1]);
      })
      .attr('width', barwidth)
      .on('click', function(d: any) {
        handleOnClick(d);
      })
      .on('mouseover', function(d: any) {
        handleMouseover(d);
      })
      .on('mouseout', function() {
        handleMouseout();
      });

    // BAR LABELS
    layer
      .selectAll('text')
      .data(function(d: any) {
        return d;
      })
      .enter()
      .append('text')
      .attr('class', 'bar__label')
      .attr('x', function(d: any) {
        return x(d.data.category) + x.bandwidth() / 2;
      })
      .attr('y', function(d: any) {
        return y(d[1]) - 5;
      })
      .text(function(d: any) {
        // To check empty values
        if (d[1] !== d[0]) {
          if (isPercentMetric) {
            return (d.data.type1 + d.data.type2).toFixed(2);
          } else {
          return d3
            .format(formatValue)(d.data.type1 + d.data.type2)
            .replace('G', 'B');
        }
      }
      });
  }
}
