import { Component, OnInit, ElementRef, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { Formatter } from '@mint-libs/common';
import * as _ from 'underscore';

@Component({
  selector: 'mint-metric-multibar-chart',
  templateUrl: './metric-multibar-chart.component.html',
  styleUrls: ['./metric-multibar-chart.component.scss']
})
export class MetricMultibarChartComponent implements OnInit, OnChanges {
  @Input() dataset: any = null;
  @Input() chartHelpText: string;
  @Input() containerId: string;
  @Input() isBarClickable = false;

  @Output() chartClick = new EventEmitter<any>();
  @Output() chartFocused = new EventEmitter<any>();

  constructor(private element: ElementRef, private formatter: Formatter) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.dataset && this.dataset.length > 0) {
      this.buildMultiBarChart();
    } else if (this.dataset && this.dataset.length === 0) {
      d3.select(this.element.nativeElement).html('No data available');
    }
  }

  buildMultiBarChart() {
    // ToDo: Check and remove 'target' property as I do not see it being used anywhere
    // const target = d3.select(this.element.nativeElement).node().getBoundingClientRect();

    const groupData = d3
      .nest()
      .key(function(d: any) {
        return d.columnType + d.desc;
      })
      .rollup(function(v: any) {
        return {
          ...v[0],
          total: v[0].val + v[0].caddsval + v[0].qval
        };
      })
      .entries(this.dataset)
      .map(function(d: any) {
        return { ...d.value };
      });

    const margin = { top: 20, right: 20, bottom: 30, left: 40 },
      height = 268 - margin.top - margin.bottom,
      barWidth = 40;
    const isPercentMetric = this.dataset && this.dataset.length > 0 && this.dataset[0].metric === 'Percent';
    let yTicks = 7;

    if (isPercentMetric) {
      yTicks = 10;
    }

    const barWidthBuffer = 80;
    const width = groupData.length * barWidthBuffer - margin.left - margin.right;
    let descText = '';
    const formatValue = d3.format('.3~s');
    const pccTooltipWidth = 280;
    const pccTooltipHeight = 250;
    const sideBarWidth = 50;
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const scope = this;

    const getToolTipData = toolTipData => {
      let html = `<div class="data__source__item">
        <div class="perf__container">`;

      if (toolTipData.showText) {
        html += `<div class="perf__data">
          <div class="perf__data__text">Target: </div>
          <div class="perf__data__val">${toolTipData.textVal}</div>
          </div>`;
      } else {
        html += `<div class="perf__data">
        <div class="perf__data__text">Target: </div>
        <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.target, toolTipData.metric)}</div>
        </div>`;
      }

      if (toolTipData.showRevenueBreakup) {
        html += `<div class="perf__data">
          <div class="perf__data__text">Source Revenue (A): </div>
          <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.revenue, toolTipData.metric)}</div>
          </div>
          <div class="perf__data">
          <div class="perf__data__text">Manual Adjustments (B): </div>
          <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.revenueAdjustment, toolTipData.metric)}</div>
          </div>
          <div class="perf__data">
          <div class="perf__data__text">Modifier (C): </div>
          <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.revenueModifier, toolTipData.metric)}</div>
          </div>
          <div class="perf__data">
          <div class="perf__data__text">Total Revenue (A+B+C): </div>
          <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.totalRevenue, toolTipData.metric)}</div>
          </div>
          </div>`;
      } else {
        html += `<div class="perf__data">
            <div class="perf__data__text">Source Revenue: </div>
            <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.revenue, toolTipData.metric)}</div>
            </div>
            </div>`;
      }

      if (toolTipData.cAddsRevenue > 0) {
        html += `<div class="data__revenue>
          <div class="data__revenue__txt">Portion of Total Revenue from Customer Adds</div>
          <div class="data__revenue__val">${this.formatter.formatWithCurrencyAndRoundOff(toolTipData.cAddsRevenue, toolTipData.metric)}</div>
          </div>
          </div>`;
      } else {
        html += `</div>`;
      }
      return html;
    };

    const toolTipHtml = function(d: any) {
      return (
        '<div class="bar__tooltip__container bar__tooltip__container-pccAcc">' +
        '<div>' +
        '<div class="bar__tooltip__header">' +
        '<div class="bar__tooltip__header__txt">' +
        d.data.desc +
        '</div>' +
        '<div class="bar__tooltip__close">' +
        '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        `<path d="M9.85242 0.851639L5.70944 5.00004L9.85242 9.14844L9.14844 9.85164L5.00043 5.70904L0.852417 9.85164L0.148438
        9.14844L4.29141 5.00004L0.148438 0.851639L0.852417 0.148438L5.00043 4.29104L9.14844 0.148438L9.85242 0.851639Z" fill="#333333" />` +
        '</svg>' +
        '</div>' +
        '</div>' +
        '<div class="data__source__container">' +
        getToolTipData(d.data.toolTip) +
        '</div>' +
        '</div>' +
        '</div>'
      );
    };

    d3.select(this.element.nativeElement).html('');

    const graphContainer = d3
      .select(this.element.nativeElement)
      .append('div')
      .attr('class', 'graph__container');

    const graphSvg = graphContainer
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('role', 'img')
      .attr('aria-labelledby', this.containerId + '-title' + '  ' + this.containerId + '-multi-desc');

    graphSvg
      .append('title')
      .attr('id', this.containerId + '-title')
      .text(this.chartHelpText);

    _.each(this.dataset, function(bar) {
      descText += bar.desc + ' has a revenue of ' + bar.val + bar.metric + ' and target of ' + bar.qval + bar.metric + ' , ';
    });

    graphSvg
      .append('desc')
      .attr('id', this.containerId + '-multi-desc')
      .text(descText);

    const graph = graphSvg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // Tool Tip
    const div = d3
      .select(this.element.nativeElement)
      .append('div')
      .attr('class', 'bar__tooltip');

    // Tool Tip for click
    const div2 = d3
      .select(this.element.nativeElement)
      .append('div')
      .attr('class', 'bar__tooltip bar__tooltip-click');

    const x0 = d3
      .scaleBand()
      .rangeRound([0, width])
      .paddingInner(0.1);

    const x1 = d3.scaleBand().paddingOuter(0.5);

    const y = d3.scaleLinear().range([height, 0]);

    const z = d3.scaleOrdinal().range(['#00AE56', '#0B6A0B', '#3354A5']);

    // Adjust padding for 2 groups
    if (groupData.length <= 2) {
      x1.paddingOuter(0.1);
    } else if (groupData.length <= 4) {
      x1.paddingOuter(0.15);
    }

    x0.domain(
      this.dataset.map(function(d) {
        return d.desc;
      })
    );
    x1.domain(
      this.dataset.map(function(d) {
        return d.columnType;
      })
    ).range([0, x0.bandwidth()]);

    z.domain(Object.keys(this.dataset[0]).filter(x => x === 'val' || x === 'caddsval' || x === 'qval'));
    const keys = z.domain(); // ["type1", "type2", "type3"];

    const stackData = d3.stack().keys(keys)(groupData);

    y.domain([
      0,
      1.15 *
        d3.max(groupData, function(d: any) {
          return d.total;
        })
    ]);

    // AXES
    const xAxis = d3.axisBottom(x0).tickSizeOuter(0);
    const xAxisG = graph
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);
    xAxisG.selectAll('.tick text').attr('style', 'display:none');
    xAxisG.selectAll('.tick line').attr('style', 'display:none');
    xAxisG.selectAll('path.domain').attr('style', 'display:none');

    // Formatting bar label
    xAxisG
      .selectAll('g')
      .append('svg:foreignObject')
      .attr('x', () => {
        return -x0.bandwidth() / 2;
      })
      .attr('width', x0.bandwidth())
      .attr('height', 24)
      .append('xhtml:div')
      .attr('title', (i: any) => i)
      .attr('class', 'wrap__text')
      .html(function(i: any) {
        return `<div>${i}</div>`;
      });

    let yAxis: any;
    if (isPercentMetric) {
      yAxis = d3
        .axisLeft(y)
        .tickSizeOuter(0)
        .ticks(yTicks)
        .tickSize(-width)
        .tickPadding(10);

      //.tickFormat(formatValue)
      // .tickFormat(function(d: any) {
      //   return formatValue(d).replace('G', 'B');
      // });
    } else {
      yAxis = d3
        .axisLeft(y)
        .tickSizeOuter(0)
        .ticks(yTicks)
        .tickSize(-width)
        .tickPadding(10)
        .tickFormat(formatValue)
        .tickFormat(function(d: any) {
          return formatValue(d).replace('G', 'B');
        });
    }

    const yAxisG = graph
      .append('g')
      .attr('class', 'y-axis')
      .call(yAxis);

    yAxisG.select('path').attr('style', 'display:none');

    yAxisG.selectAll('.tick line').attr('style', 'stroke: #E0E0E0');

    // Handle mouse events

    const handleOnClick = (d: any, idx: any) => {
      this.chartClick.emit(d.data);
      this.element.nativeElement.querySelector('#' + this.containerId + '_bar_placeholder_' + idx).focus();
      div.style('display', 'none');
      const diffWidth = vw - d3.event.pageX - sideBarWidth;

      div2.html(toolTipHtml(d));
      div2.select('.bar__tooltip__close').on('click', function() {
        div2.style('display', 'none');
      });
      div2.style('top', d3.event.pageY - pccTooltipHeight + 'px');

      // To check if tip going out of the window
      if (diffWidth > pccTooltipWidth / 2) {
        div2.style('left', d3.event.pageX - pccTooltipWidth / 2 + 'px');
      } else {
        div2.style('left', d3.event.pageX - (pccTooltipWidth - diffWidth) + 'px');
      }

      div2
        .transition()
        .duration(200)
        .style('display', 'block');
    };

    const handleFocus = function(d: any, context) {
      this.chartFocused.emit(d);
    };

    const handleMouseover = (d: any) => {
      const diffWidth = vw - d3.event.pageX - sideBarWidth;

      div.html(toolTipHtml(d));
      div.style('top', d3.event.pageY - pccTooltipHeight + 'px');

      // To check if tip going out of the window
      if (diffWidth > pccTooltipWidth / 2) {
        div.style('left', d3.event.pageX - pccTooltipWidth / 2 + 'px');
      } else {
        div.style('left', d3.event.pageX - (pccTooltipWidth - diffWidth) + 'px');
      }

      div
        .transition()
        .duration(200)
        .style('display', 'block');
    };

    const handleMouseout = () => {
      div
        .transition()
        .duration(500)
        .style('display', 'none');
    };

    const layer = graph
      .selectAll('.layer')
      .data(stackData)
      .enter()
      .append('g')
      .attr('class', 'layer')
      .attr('fill', function(d: any) {
        return z(d.key);
      });

    layer
      .selectAll('rect')
      .data(function(d: any) {
        return d;
      })
      .enter()
      .append('rect')
      .attr('class', 'bar-placeholder' + (this.isBarClickable ? ' bar-clickable' : ''))
      .attr('tabindex', '0')
      .attr('id', function(d: any, idx: any) {
        return scope.containerId + '_bar_placeholder_' + idx;
      })
      .attr('transform', function(d: any) {
        return 'translate(' + x0(d.data.desc) + ',0)';
      })
      .attr('x', function(d: any) {
        return x1(d.data.columnType) + (x1.bandwidth() - barWidth) / 2;
      })
      .attr('height', function(d: any) {
        return height;
      })
      .attr('width', barWidth)
      .on('click', function(d: any, idx: any) {
        handleOnClick(d, idx);
      })
      .on('mouseover', function(d: any) {
        handleMouseover(d);
      })
      .on('mouseout', function() {
        handleMouseout();
      });

    layer
      .selectAll('bar-placeholder')
      .data(function(d: any) {
        return d;
      })
      .enter()
      .append('rect')
      .attr('class', 'layer-rect' + (this.isBarClickable ? ' bar-clickable' : ''))
      .attr('transform', function(d: any) {
        return 'translate(' + x0(d.data.desc) + ',0)';
      })
      .attr('x', function(d: any) {
        return x1(d.data.columnType) + (x1.bandwidth() - barWidth) / 2;
      })
      .attr('y', function(d: any) {
        return y(d[1]);
      })
      .attr('height', function(d: any) {
        return y(d[0]) - y(d[1]);
      })
      .attr('width', barWidth)
      .on('click', function(d: any, idx: any) {
        handleOnClick(d, idx);
      })
      .on('mouseover', function(d: any) {
        handleMouseover(d);
      })
      .on('mouseout', function() {
        handleMouseout();
      });

    // BAR LABELS
    layer
      .selectAll('text')
      .data(function(d: any) {
        return d;
      })
      .enter()
      .append('text')
      .attr('class', 'bar__label')
      .attr('transform', function(d: any) {
        return 'translate(' + x0(d.data.desc) + ',0)';
      })
      .attr('x', function(d: any) {
        return x1(d.data.columnType) + x1.bandwidth() / 2;
      })
      .attr('y', function(d: any) {
        return y(d[1]) - 5;
      })
      .text(function(d: any) {
        if (d.data.columnType === 'quota' && d.data.showText) {
          return d.data.textVal;
        }
        // To check empty values
        if (d[1] !== d[0]) {
          if (isPercentMetric) {
            return d.data.total.toFixed(2);
          } else {
            return d3
              .format(formatValue)(d.data.total)
              .replace('G', 'B');
          }
        }
      });
  }
}
