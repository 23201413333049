<div id="notifications_container_1" class="notifications-container">
  <div id="notifications_container_inner_1" class="notifications-container__inner">
    <div id="notifications_2" class="row">
      <div id="notifications_3" class="col-xs-12"><h3 id="notifications_4" class="pull-left" tabindex="0">Notifications</h3></div>
    </div>
    <div id="notifications_4" class="clearfix"></div>
    <div id="notifications_5" *ngIf="loading" tabindex="0">Loading ...</div>
    <div class="notification-container-2 mat-elevation-z8">
      <mat-table [dataSource]="dataSource" matSort matSortActive="NotificationStartDate" matSortDirection="desc" matSortDisableClear>
        <ng-container matColumnDef="NotificationType">
          <mat-header-cell *matHeaderCellDef>
            <div tabindex="0">Notification Type</div>
            <div>
              <mat-select [(ngModel)]="selectedValue" (selectionChange)="filterNotifications($event.value)" tabindex="0">
                <mat-option *ngFor="let entry of notificationTypes | keyvalue" [value]="entry.key" tabindex="0"> {{ entry.value }} </mat-option>
              </mat-select>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" [innerHTML]="row.Icon" tabindex="0"></mat-cell>
        </ng-container>

        <ng-container matColumnDef="NotificationText">
          <mat-header-cell *matHeaderCellDef> Notifications ({{ sharedContext.totalCount }})</mat-header-cell>
          <mat-cell *matCellDef="let row" tabindex="0"> {{ row.NotificationText }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="NotificationStartDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Activation Date </mat-header-cell>
          <mat-cell *matCellDef="let row" tabindex="0"> {{ row.NotificationStartDate | date:'medium' }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="NotificationEndDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Expiry Date </mat-header-cell>
          <mat-cell *matCellDef="let row" [style.color]="row.color" tabindex="0"> {{ row.NotificationEndDate | date:'medium' }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="notification-header"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" [style.background-color]="row.IsActive ? '#FFFFFF' : '#F2F2F2'"></mat-row>
      </mat-table>
      <mat-paginator pageSize="15" tabindex="0"></mat-paginator>
    </div>
  </div>
</div>
