import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/dashboard.service';

@Injectable()
export class DashboardV2RouteGuard implements CanActivate {
  constructor(private dashboardService: DashboardService, private _router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dashboardService.isNewDashboardApplicable()) {
      return true;
    }

    return false;
  }
}
