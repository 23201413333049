import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mint-content-loader-grid',
  templateUrl: './content-loader-grid.component.html'
})
export class ContentLoaderGridComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
