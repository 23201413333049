import { Expose } from 'class-transformer';

export class OptoutDetails {
  @Expose()
  isEligible: boolean | null;
  @Expose()
  wasEligibleInPreviousCountry: boolean | null;
  @Expose()
  isEligibleInNextCountry: boolean | null;
  @Expose()
  isOptOut: boolean | null;
  @Expose()
  isPreviousParticipation: boolean | null;
  @Expose()
  partcipationEndDate: Date | string | null;
  @Expose()
  optOutStartDate: Date | string | null;
  @Expose()
  optOutEndDate: Date | string | null;
  @Expose()
  nextOptOutStartDate: Date | string | null;
  @Expose()
  nextOptOutEndDate: Date | string | null;
  @Expose()
  optOutDate: Date | string | null;
  @Expose()
  didOptOutInPreviousCountry: boolean | null;
}
