import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { NetworkService } from '../../../../network/src/lib/network.service';
// import { EndpointService } from '../../../../network/src/lib/endpoint.service';
// import { CustomHttpParams } from '../../../../network/src/lib/custom-http-params';
import { NetworkService, EndpointService, CustomHttpParams } from '@mint-libs/network';
import { ENVIRONMENT_CONFIG } from '@mint-libs/common';
import { ConfigurationState, selectConfigurationState } from '@mint-libs/configuration';
// import { Filters, FilterState, selectFiltersState } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { Filters } from '../filters/filters.model';
import { FilterState } from '../filters/state/filters.reducer';
import { UserProfileState } from '../user-profile/state/user-profile.reducer';
import { TMeasureTypeMapping } from '../measure-type-mapping/types/measure-type-mapping.type';
import { DataService } from './data.service';
import { MeasureTypeMappingState } from '../measure-type-mapping/models/measure-type-mapping.model';
import { selectUserProfileState } from '../user-profile/state/user-profile.selectors';
@Injectable({
  providedIn: 'root'
})
export class MeasureTypeMappingService extends DataService {
  mappingState: MeasureTypeMappingState = new MeasureTypeMappingState([]);
  environment: any;
  userProfile: any;

  constructor(
    networkService: NetworkService,
    filterState: Store<FilterState>,
    userProfileState: Store<UserProfileState>,
    configState: Store<ConfigurationState>,
    private endpoint: EndpointService,
    @Inject(ENVIRONMENT_CONFIG) env: any
  ) {
    super(networkService, filterState, configState, userProfileState);
    this.environment = env;
    userProfileState.select(selectUserProfileState).subscribe(userProfile => {
      if (!userProfile) {
        return;
      }
      this.userProfile = userProfile;
    });
  }

  private fetchMeasureTypeMappingFromApi(): Observable<TMeasureTypeMapping[]> {
    const uri = this.endpoint.GetMeasureTypeMapping.uri;
    // Check if there are consume measures for UBI users for only measure type mapping calls
    if (!this.userProfile) {
      return new Observable(s => s.next([]));
    }
    const incentiveType = this.userProfile.incentiveType === 'UBI' ? 'RUBI' : this.userProfile.incentiveType;
    return this.getWithGlobalFilters<TMeasureTypeMapping[]>(uri, new HttpParams().append('IncentiveType', incentiveType));
  }

  getMeasureTypeMapping(): Observable<TMeasureTypeMapping[] | undefined> {
    return this.fetchMeasureTypeMappingFromApi();
  }
}
