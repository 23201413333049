import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MqchComponent } from './mqch.component';
import { MqchRoutingModule } from './mqch-routing.module';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MqchService } from './mqch.service';
import { FormsModule } from '@angular/forms';
import { TimelineComponent } from './timeline/timeline.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MqchRouteGuard } from './mqch-route-guard';
import { FiltersModule } from '../filters/filters.module';

@NgModule({
  declarations: [MqchComponent, TimelineComponent],
  imports: [CommonModule, SharedModule, MqchRoutingModule, NgbModule, FormsModule, NgxSpinnerModule, FiltersModule],
  providers: [MqchService, MqchRouteGuard]
})
export class MqchModule {}
