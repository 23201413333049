<app-optout-notification id="Payout_summary_opt_out" class="optout" [isBanner]="false"></app-optout-notification>
<div id="payout-summary_1" *ngIf="rowData">
  <div id="payout-summary_2">
    <ag-grid-angular id="myGrid" class="ag-theme-balham" [columnDefs]="columnDefs" [rowData]="rowData"
      [treeData]="false" [animateRows]="true" [context]="context" [frameworkComponents]="frameworkComponents"
      [groupDefaultExpanded]="groupDefaultExpanded" [getDataPath]="getDataPath"
      [autoGroupColumnDef]="autoGroupColumnDef" [gridOptions]="gridOptions" (onGridReady)="onGridReady($event)"
      [domLayout]="domLayout">
    </ag-grid-angular>
  </div>
  <!-- <button id="payout-summary_3" (click)="autoSizeAll()">Auto-Size All</button> -->
</div>
<div id="payout-summary_4" *ngIf="!rowData" class="content__loader">
  <mint-content-loader-grid id="payout-summary_5"></mint-content-loader-grid>
</div>
<!-- (firstDataRendered) = "autoSizeAll()" -->