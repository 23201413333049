import { Component, OnInit, Input } from '@angular/core';
import { ManagerDashboardHelper } from '../manager-dashboard.helper';
import { Formatter } from '@mint-libs/common';
import { QuotaTogoMetric } from '../models/quota-togo-metric';

@Component({
  selector: 'mint-pcc-metric-attainment',
  templateUrl: './pcc-metric-attainment.component.html',
  styleUrls: ['./pcc-metric-attainment.component.scss']
})
export class PccMetricAttainmentComponent implements OnInit {
  @Input() metrics: Array<QuotaTogoMetric>;

  slideConfig;

  constructor(private managerDashboardHelper: ManagerDashboardHelper, private formatter: Formatter) {
    this.slideConfig = this.managerDashboardHelper.getQuotaToGoSlickConfig();
  }

  ngOnInit() {}
}
