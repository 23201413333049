import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mint-report-tabs',
  templateUrl: './report-tabs.component.html',
  styleUrls: ['./report-tabs.component.scss']
})
export class ReportTabsComponent implements OnChanges {

  public filterTab: any;
  @Input() reportType: string;
  @Input() activeTabId: any = 1;
  @Input() tabs = [];
  @Output() tabsChange = new EventEmitter<any>();
  constructor() { }

  ngOnChanges() {
  }

  tabChange(tabId) {
    this.activeTabId = tabId;
    this.tabsChange.emit(this.activeTabId);
  }

}
