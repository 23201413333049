import { ITooltipTextGrid } from './tooltip-text-grid';

export class TooltipTextGridRBI implements ITooltipTextGrid {
    constructor(private isSelectedFiscalYear2021: boolean) {
        if (isSelectedFiscalYear2021) {
            this.MetriclevelYTDEarnings = 'YTD earnings based on attainment of the individual bucket. Payments are capped at 100% in Q1 in accordance with the RBI ' +
            'Business Rules and 115% for Q2 and Q3 as part of the FY21 Accelerate H1 program. More information on payout calculations are available on the Payout Details page.';
        }
    }
  Metric = `As per your plan, your RBI award consists of one or more bucket(s) on which your performance is measured.
      Each of them have a weight and a payline graph which is described in your Compensation plan page.`;
  Attainment = `Attainment % = Actuals/Target. For billed revenue bucket in Enterprise and SMC, attainment is calculated as ((Total Actuals – Recurring Target)/(Total Target – Recurring Target)) and for Azure Consumption, attainment is calculated as ((Total Actuals – Baseline)/(Total Target – Baseline)).`;
  MetriclevelYTDEarnings = `YTD earnings based on attainment of the individual bucket. Payments are capped at 100%. More information on payout calculations are available on the Payout Details page.`;
  ProductGroup = `Each bucket consists of one or more metrics. Attainment by Metric is shown to better understand performance within the bucket.`;
  YTDQuota = `Year-to-date quota reflected for the selected role, inclusive of modifiers, multipliers, and adjustments.`;
  YTDActuals = `Year-to-date actuals reflected for the selected role, inclusive of modifiers, multipliers, and adjustments.
      Note that some adjustments are not processed until the payout window following the close of the quarter.`;
  YTDActualsDMY = `YTD (Year-to-Date) actuals are reflected for applicable buckets for the selected role, inclusive of modifiers, multipliers and adjustments.

  The Committed Growth for D365 bucket does not have YTD actuals. Instead, you will see value of commitments landed since the start of your plan. For this bucket, whenever you land a multi-year deal, actuals up to 3 years from that plan's start that are either billed, scheduled or estimated, are used in the attainment formula.
  
  Note: Some adjustments are not processed until the payout window following quarter close.`;
  QuotaToGo = `Year-to-date remaining quota to reach Target`;
  DataSource = `Data Source Information`;
}
