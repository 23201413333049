import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { ManagerDashboardHelper } from '../manager-dashboard.helper';
import { Direct } from '../models/direct';
import * as _ from 'underscore';

@Component({
  selector: 'mint-ubi-advance-filter',
  templateUrl: './ubi-advance-filter.component.html',
  styleUrls: ['./ubi-advance-filter.component.scss']
})
export class UBIManagerAdvanceFilterComponent implements OnInit {
  @Input() directs: Array<Direct>;
  @Input() roles: string[];
  @Output() advanceFilteredDirects: EventEmitter<Array<Direct>> = new EventEmitter<Array<Direct>>();

  isFilterLoaded = false;
  isUnsignedPPA = false;
  isSignedPPA = false;
  isUnsignedQA = false;
  isSignedQA = false;
  isUtilizationLess = false;
  isUtilizationGreater = false;
  selectedRoles = [];
  filteredDirects: Array<Direct>;
  isDataSortedDsc = false;
  isClearAll = false;

  constructor(public managerDashboardHelper: ManagerDashboardHelper) {
    this.filteredDirects = this.directs;
  }

  ngOnInit() {
    this.showAll();
  }

  onFilterClicked() {
    this.isFilterLoaded = !this.isFilterLoaded;
  }

  onFilterSelected($event) {
    if ($event.srcElement.id === 'unsignedPPA') {
      this.isUnsignedPPA = !this.isUnsignedPPA;
      if (this.isUnsignedPPA) {
        this.isSignedPPA = false;
      }
    }
    if ($event.srcElement.id === 'signedPPA') {
      this.isSignedPPA = !this.isSignedPPA;
      if (this.isSignedPPA) {
        this.isUnsignedPPA = false;
      }
    }
    if ($event.srcElement.id === 'unsignedQA') {
      this.isUnsignedQA = !this.isUnsignedQA;
      if (this.isUnsignedQA) {
        this.isSignedQA = false;
      }
    }
    if ($event.srcElement.id === 'signedQA') {
      this.isSignedQA = !this.isSignedQA;
      if (this.isSignedQA) {
        this.isUnsignedQA = false;
      }
    }
    if ($event.srcElement.id === 'utilizationLess') {
      this.isUtilizationLess = !this.isUtilizationLess;
      if (this.isUtilizationLess) {
        this.isUtilizationGreater = false;
      }
    }
    if ($event.srcElement.id === 'utilizationGreater') {
      this.isUtilizationGreater = !this.isUtilizationGreater;
      if (this.isUtilizationGreater) {
        this.isUtilizationLess = false;
      }
    }

    const isPPAFilterSelected = this.isSignedPPA || this.isUnsignedPPA ? true : false;
    const isQAFilterSelected = this.isSignedQA || this.isUnsignedQA ? true : false;
    const isUtilizationFilterSelected = this.isUtilizationLess || this.isUtilizationGreater ? true : false;

    this.filteredDirects = this.directs.filter(
      dir =>
        (this.selectedRoles && this.selectedRoles.length > 0 ? this.selectedRoles.includes(dir.role) : dir) &&
        (isPPAFilterSelected ? dir.isPPAComplete === this.isSignedPPA && dir.currentParticipation !== 0 : dir) &&
        (isQAFilterSelected ? dir.isQAAccepted === this.isSignedQA && dir.currentParticipation !== 0 : dir) &&
        (isUtilizationFilterSelected && dir.currentParticipation !== 0 ? (this.isUtilizationLess ? dir.ubiActiveAttainmentPercentage < 100 : dir.ubiActiveAttainmentPercentage >= 100) : dir)
    );

    if (this.isUtilizationGreater || this.isUtilizationLess || this.isSignedQA || this.isUnsignedQA || this.isSignedPPA || this.isUnsignedPPA) {
      this.isClearAll = true;
    } else {
      this.isClearAll = false;
    }

    this.advanceFilteredDirects.emit(this.filteredDirects);
  }

  onFilterCloseIcon($event) {
    if ($event.srcElement.id.includes('filter_signed_ppa')) {
      this.isSignedPPA = false;
    }
    if ($event.srcElement.id.includes('filter_unsigned_ppa')) {
      this.isUnsignedPPA = false;
    }
    if ($event.srcElement.id.includes('filter_signed_ta')) {
      this.isSignedQA = false;
    }
    if ($event.srcElement.id.includes('filter_unsigned_ta')) {
      this.isUnsignedQA = false;
    }
    if ($event.srcElement.id.includes('filter_utilization_gt')) {
      this.isUtilizationGreater = false;
    }
    if ($event.srcElement.id.includes('filter_utilization_lt')) {
      this.isUtilizationLess = false;
    }

    const isPPAFilterSelected = this.isSignedPPA || this.isUnsignedPPA ? true : false;
    const isQAFilterSelected = this.isSignedQA || this.isUnsignedQA ? true : false;
    const isUtilizationFilterSelected = this.isUtilizationLess || this.isUtilizationGreater ? true : false;

    this.filteredDirects = this.directs.filter(
      dir =>
        (this.selectedRoles && this.selectedRoles.length > 0 ? this.selectedRoles.includes(dir.role) : dir) &&
        (isPPAFilterSelected ? dir.isPPAComplete === this.isSignedPPA && dir.currentParticipation : dir) &&
        (isQAFilterSelected ? dir.isQAAccepted === this.isSignedQA && dir.currentParticipation : dir) &&
        (isUtilizationFilterSelected && dir.currentParticipation !== 0 ? (this.isUtilizationLess ? dir.ubiActiveAttainmentPercentage < 100 : dir.ubiActiveAttainmentPercentage >= 100) : dir)
    );

    if (this.isUtilizationGreater || this.isUtilizationLess || this.isSignedQA || this.isUnsignedQA || this.isSignedPPA || this.isUnsignedPPA) {
      this.isClearAll = true;
    } else {
      this.isClearAll = false;
    }

    this.advanceFilteredDirects.emit(this.filteredDirects);
  }

  onSelectAllRoles($event) {
    this.selectedRoles = $event;
    if (!this.selectedRoles || !this.selectedRoles.length) {
      this.assignFilteredDirects(this.managerDashboardHelper.filterDirects(this.filteredDirects, this.roles, true));
    } else {
      this.assignFilteredDirects(this.managerDashboardHelper.filterDirects(this.filteredDirects, this.selectedRoles, true));
    }
  }

  onSelectRole($event) {
    if (!this.selectedRoles || !this.selectedRoles.length) {
      this.showAll();
    } else {
      this.assignFilteredDirects(this.managerDashboardHelper.filterDirects(this.filteredDirects, this.selectedRoles, true));
    }
  }

  showAll() {
    this.assignFilteredDirects(this.directs);
    this.managerDashboardHelper.resetFilterFlags(this.filteredDirects);
  }

  assignFilteredDirects(directs: Array<Direct>) {
    this.filteredDirects = _.sortBy(directs, 'name');
    this.advanceFilteredDirects.emit(this.filteredDirects);
  }

  closeDropdown() {
    if (this.isFilterLoaded) {
      this.isFilterLoaded = false;
    }
  }

  onShortingClicked() {
    this.isDataSortedDsc = !this.isDataSortedDsc;
    if (this.isDataSortedDsc) {
      this.filteredDirects = _.sortBy(this.filteredDirects, 'name').reverse();
    } else {
      this.filteredDirects = _.sortBy(this.filteredDirects, 'name');
    }

    this.advanceFilteredDirects.emit(this.filteredDirects);
  }

  onClearAllClicked() {
    this.isUtilizationGreater = false;
    this.isUtilizationLess = false;
    this.isSignedQA = false;
    this.isUnsignedQA = false;
    this.isSignedPPA = false;
    this.isUnsignedPPA = false;
    this.isClearAll = false;

    const isPPAFilterSelected = this.isSignedPPA || this.isUnsignedPPA ? true : false;
    const isQAFilterSelected = this.isSignedQA || this.isUnsignedQA ? true : false;
    const isUtilizationFilterSelected = this.isUtilizationLess || this.isUtilizationGreater ? true : false;

    this.filteredDirects = this.directs.filter(
      dir =>
        (this.selectedRoles && this.selectedRoles.length > 0 ? this.selectedRoles.includes(dir.role) : dir) &&
        (isPPAFilterSelected ? dir.isPPAComplete === this.isSignedPPA && dir.currentParticipation : dir) &&
        (isQAFilterSelected ? dir.isQAAccepted === this.isSignedQA && dir.currentParticipation : dir) &&
        (isUtilizationFilterSelected && dir.currentParticipation !== 0 ? (this.isUtilizationLess ? dir.ubiActiveAttainmentPercentage < 100 : dir.ubiActiveAttainmentPercentage >= 100) : dir)
    );

    this.advanceFilteredDirects.emit(this.filteredDirects);
  }
}
