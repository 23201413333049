import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { DetailDataService } from '../core/network/detail-data.service';
import { EndpointService } from '@mint-libs/network';

@Injectable({
  providedIn: 'root'
})
export class MqchService {
  selectedFiscalYear: any;
  selectedStandardTitle: any;

  constructor(private endpoint: EndpointService, private dataService: DetailDataService) {}

  getPCGMetrics(params: any): Observable<any> {
    return this.dataService.get<any[]>(this.endpoint.GetPCGMetrics.uri, params);
  }

  getTimelineView(params: any): Observable<any> {
    return this.dataService.get<any[]>(this.endpoint.GetTimelineView.uri, params);
  }

  getDetailedView(params: any): Observable<any> {
    return this.dataService.get<any[]>(this.endpoint.GetDetailedView.uri, params);
  }

  getTimelineAndDetailedView(timelineParams, detailViewParams) {
    return forkJoin([this.getTimelineView(timelineParams), this.getDetailedView(detailViewParams)]);
  }
}
