import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-grid-data-source-renderer',
    styles: [`
    .pg-attn{
        line-height: 16px;
    }
    .att-val{
        font-size: 11px; 
        color: #707070;
    }
    `],
    template: `
    <span *ngIf="params.value">
        <span style="font-size: 14px;">{{params.value.DataSourceName}}</span>
    <br/>
        <div style="line-height: 16px;">
            <span style="font-size: 11px; color: #707070;">Last Refresh: {{params.value.LastRefresh}}</span>
            <br/>
            <span style="font-size: 11px; color: #707070;">Next Refresh: {{params.value.NextRefresh}}</span>
        </div>
    </span>
        `
})
export class DataSourceRendererComponent implements ICellRendererAngularComp {
    params: any;
    thresholdInfo: any;
    constructor() { }
    agInit(params): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }
}
