import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { HttpParams } from '@angular/common/http';
import { DataService } from '../network/data.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  constructor(private dataService: DataService, private endpoints: EndpointService) {}

  get(errorCode: string, language = 'en') {
    let params = new HttpParams();
    params = params.append('languageCode', language);
    return this.dataService.get(this.endpoints.GetMessages.uri, params).pipe(
      map(response => {
        return this.getMessageByErrorCode(errorCode, response)[0]['text'];
      })
    );
  }

  getFullMessage(errorCode: string, language = 'en') {
    let params = new HttpParams();
    params = params.append('languageCode', language);
    return this.dataService.get(this.endpoints.GetMessages.uri, params).pipe(
      map(response => {                                                                     
        return this.getMessageByErrorCode(errorCode, response);
      })
    );
  }

  private getMessageByErrorCode(errorCode, data) {
    return (<any>data).filter(m => {
      return m.code === errorCode;
    });
  }
}
