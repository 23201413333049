import { Component, Inject } from '@angular/core';
import { ReportType } from './report-type.enum';
import { ReportsService } from './reports.service';
import { MessagesService } from '@mint-libs/context';
import { Code } from '../app.constants';
import * as Ng1Services from '../core/hybrid/ng1-upgraded-providers';
import { ExcelConfigs } from '../shared/excel-export/excel-config.model';
import { FiltersService } from '../filters/filters.service';

@Component({
  template: ''
})
export abstract class BaseRevenueComponent {
  public reportDisclaimer: any;
  public isLargeUser: any;
  public lastRefreshTime: any;
  public selectedPccChart: any;
  public PCCs: any = [];
  public chartDataPccs: any;
  public chartDataRevenue: any;
  public searchSuggestion = [];
  public revenueReportType: string;

  excelDownloadMessage = '';
  excelDownloadStatus = false;

  revenueExcelConfigs: ExcelConfigs = new ExcelConfigs();

  constructor(public reportsService: ReportsService, public messageService: MessagesService, public filterService: FiltersService) {}

  abstract filterSelectedPcc(selectedPccChart);

  public initPayoutDisclaimer() {
    this.messageService.get(Code.ReportDisclaimer).subscribe(response => {
      this.reportDisclaimer = response;
    });
  }

  public initTargetSummary(...filterFunction: ((pcc: any) => boolean)[]) {
    this.reportsService.getTargetSummary(this.revenueReportType).subscribe(response => {
      response.PlanComponentGroups.forEach(pcg => {
        pcg.PlanComponentCombinations.filter(function(pcc) {
          let isFiltered = true;
          filterFunction.forEach(f => {
            isFiltered = isFiltered && f(pcc);
          });
          return isFiltered;
        }).forEach(pcc => {
          this.PCCs.push({ ...pcc, PCGWeight: pcg.Weightage });
        });
      });
      const selectedPcc = this.PCCs.length > 0 ? this.PCCs[0] : undefined;
      this.PCCs = this.PCCs.sort((a, b) => b.PCGWeight - a.PCGWeight);
      this.selectedPccChart = {
        id: selectedPcc?.Id,
        desc: `${selectedPcc?.Name} (${selectedPcc?.Parent})`,
        isPointInTime: selectedPcc?.IsPointInTime || selectedPcc?.CalcType === 'PIT'
      };
      this.initPccChart(this.PCCs, this.selectedPccChart, this.revenueReportType);
    });
  }

  public getPccMetricChartData(PCCs: any[]) {
    const chartDataPccs = [];
    PCCs.forEach(pcc => {
      const chartObject = {
        id: pcc.Id,
        desc: `${pcc.Name} (${pcc.Parent})`,
        val: pcc.Metrics.Actuals,
        PCGWeight: pcc.PCGWeight,
        isPointInTime: pcc.IsPointInTime || pcc.CalcType === 'PIT'
      };
      chartDataPccs.push(chartObject);
    });

    return chartDataPccs;
  }

  public getPccMetricMultibarChartData(PCCs: any[]) {
    let chartDataPccs = [],
      pccActuals = [],
      pccQuota = [];
    PCCs.forEach(pcc => {
      pccActuals.push({
        id: pcc.Id,
        desc: `${pcc.Name} (${pcc.Parent})`,
        columnType: 'actuals',
        val: pcc.Metrics.Actuals,
        caddsval: 0,
        qval: 0,
        isPointInTime: pcc.IsPointInTime || pcc.CalcType === 'PIT'
      });
      pccQuota.push({
        id: pcc.Id,
        desc: `${pcc.Name} (${pcc.Parent})`,
        columnType: 'quota',
        val: 0,
        caddsval: 0,
        qval: pcc.Metrics.TotalTarget,
        isPointInTime: pcc.IsPointInTime || pcc.CalcType === 'PIT'
      });
    });
    chartDataPccs = [...pccActuals, ...pccQuota];

    return chartDataPccs;
  }

  public removeDynamicsPcc(pcc: any): boolean {
    return !pcc?.IsDynamicMetric;
  }

  initPccChart(PCCs, selectedPccChart, reportType) {
    this.chartDataPccs = reportType === ReportType.AccountDynamicsCommittedGrowthReport ? this.getPccMetricMultibarChartData(PCCs) : this.getPccMetricChartData(PCCs);
    this.filterSelectedPcc(selectedPccChart);
  }

  public searchDimNames(queryText: string, reportType: ReportType) {
    this.reportsService.getDimNames(queryText, reportType).subscribe(suggestions => {
      this.searchSuggestion = suggestions;
    });
  }

  groupBy(xs, f) {
    if (xs?.length === 0) {
      return xs;
    }
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  getPITMonth() {
    const partition = this.filterService.selectedStandardTitle.ApplicablePartitions.find(p => p.StepId === this.filterService.selectedStepId);
    const applicablePitDate =
      new Date(partition.Duration.EndDate) < new Date(this.filterService.selectedStandardTitle.EndDate)
        ? new Date(partition.Duration.EndDate)
        : new Date(this.filterService.selectedStandardTitle.EndDate);

    return applicablePitDate.toLocaleString('default', { month: 'long' });
  }
}
