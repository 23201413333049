import { Injectable, Inject } from '@angular/core';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import * as _ from 'underscore';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { PageTour, Tutorial } from '@pagetour/sdk';
import { environment } from 'src/environments/environment';
import { PageContext } from '@pagetour/sdk/dist/types/models/pagecontext';
import { UserProfileService } from '../../core/context/user-profile.service';
import { PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '@mint-libs/authentication';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class PageTourService {
  adalService: any;
  url: any;
  public sharedContext: SharedContext = new SharedContext();
  // componentName: any;

  constructor(
    private route: Router,
    private router: Router,
    public userProfileService: UserProfileService,
    public sharedContextState: Store<SharedContext>,
    public authService: AuthenticationService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  pageTourInit = callbackOnTourEnd => {
    PageTour.init({
      applicationName: 'Demo App',
      showCopyUrl: false,
      tourStartDelayInMs: 1000,
      apiUrl: environment.pageTourConfig.apiUrl,
      tenantId: environment.pageTourConfig.tenantId,
      resourceId: environment.pageTourConfig.resourceId,
      showPreviousStep: true,
      theme: {
        isRounded: false,
        primaryColor: '#26873a',
        secondaryColor: '#fdfdfd',
        navigationButtonColor: '#26873a',
        textColor: '#231f20'
      },
      tags: {
        excludedTags: [],
        includedTags: []
      },
      navigator: {
        navigateToContext: (context: PageContext) => {
          if (context && context.url && context.url.includes('V2')) {
            context.url = context.url.substr(0, context.url.length - 2);
          }
          const url = `${location.origin}${context.url}`;
          if (location.href.startsWith(url)) {
            return;
          }
          const newUrl = window.location.protocol + '//' + window.location.host + context.url;
          if (window.location.href !== newUrl) {
            window.location.href = newUrl;
          }
        },
        getCurrentPageContext: pageobj => {
          const stateName = '';
          const pageContext = window.location.href.replace(window.location.host, '').replace(window.location.protocol + '//', '');

          const url = pageContext.split('?')[0];

          return {
            state: stateName,
            url
          };
        },
        callbackBeforeTourStart: () => {
          console.log('Callback after tour start');
        },
        callbackOnTourSaved: (tour: Tutorial) => {
          console.log('Tour saved callback ' + tour.id);
        },
        callbackOnTourExported: (tour: Tutorial) => {
          console.log('Tour exported callback ' + tour.id);
        },
        callbackOnAuthoringStart: () => {
          console.log('Authoring callback');
        },
        callbackAfterTourEnd: (tour: Tutorial) => {
          callbackOnTourEnd(tour);
        }
      },
      tokenProvider: {
        acquireToken: (appId, callback) => {
          this.authService.checkAndSetActiveAccount();
          const silentRequest: SilentRequest = {
            scopes: ['user.read']
          };
          return this.authService.acquireToken(silentRequest).subscribe(token => callback(token.accessToken));
        }
      },
      userInfo: {
        usePermissionsApi: false,
        getCurrentUser: () => {
          return this.sharedContext.alias;
        },
        getCurrentUserPermissions: () => ['get', 'list', 'update', 'create', 'import', 'export', 'delete']
      }
    });
    this.router.events.subscribe(async (val: any) => {
      const baseUrls = ['', '/'];
      if (val instanceof NavigationStart) {
        const url = val.url;
        if (baseUrls.indexOf(url) > -1) {
          this.url = url;
        }
      }
      if (val instanceof NavigationEnd) {
        document.dispatchEvent(new Event(PageTour.ContextChangeEvent));
        const url = val.url;
        if (baseUrls.indexOf(url) > -1) {
          if (this.url && baseUrls.indexOf(this.url) <= -1) {
            await this.router.navigate([this.url]);
          } else {
            await this.router.navigate(['/home']);
          }
        }
      }
    });
  };

  authorPageTour() {
    const pageTour = PageTour.GetInstance();
    pageTour.openPageTourAuthorDialog();
  }

  managePageTour() {
    const pageTour = PageTour.GetInstance();
    pageTour.openPageTourManageDialog();
  }

  // <---------------------------------- Old PageTour start ------------------------>

  getTours(tours) {
    _.find(
      tours,
      function(tour) {
        if (tour.title === this.tourName) {
          this.observable.next(tour);
          this.observable.complete();
        }
      },
      this
    );
  }

  // getComponentTour(componentName) {
  //   const pagetour = this.pageTour.getInstance().pagetourService;
  //   const observable = new BehaviorSubject('');
  //   const context = {
  //     tourName: componentName,
  //     observable: observable
  //   };
  //   const callback = this.getTours.bind(context);
  //   pagetour.getTours('', callback);
  //   return observable;
  // }

  checkTourExistence() {
    var pageTour = PageTour.GetInstance();
    const hasTour = false;
    const pageContext = window.location.href.replace(window.location.host, '').replace(window.location.protocol + '//', '');
    return pageTour.getTours(pageContext, []).then(tours => {
      return tours.length > 0;
    });
  }

  // <---------------------------------- Old PageTour end ------------------------>
}
