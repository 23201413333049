<div>
  <div *ngIf="preFY22Report">
    <mint-employee-info [enableTour]="false" [fromPayout]="true"></mint-employee-info>
    <button id="payoutDetailedMetrics_30" type="button" name="ExporttoExcel" class="btn btn-dark pull-right"
      title="Export to Excel" [hidden]="!old_data.PcgList || old_data.PcgList.length === 0"
      (click)="oldExportToExcel('#payout-tables_temp', '#payout-tables_temp_QR', 'PayoutReport', true, _old_pcg_manypcc_list )"
      (keyup.enter)="oldExportToExcel()">
      {{loadingReport ? "Downloading..." : "Export To Excel"}}
    </button>
    <table #payout_tables_temp [hidden]="true">
      <caption id="payoutDetailedMetrics_322">
        Payout Summary Report
      </caption>
      <tbody id="payoutDetailedMetrics_323">
        <tr id="payoutDetailedMetrics_324">
          <th scope="col" id="payout-title">Bucket Target RBI (X*Y)</th>
          <th *ngFor="let pcg of _old_pcglist; index as i" scope="col" id="payout-pcc-{{i}}">
            {{pcg}}
          </th>
        </tr>
      </tbody>
      <!-- attainment start -->
      <tbody id="payoutDetailedMetrics_325">
        <tr id="payoutDetailedMetrics_326" class="main-level brd-top">
          <td id="payout-attper" headers="payout-title" scope="row">Attainment % (B/A)</td>
          <td id="payoutDetailedMetrics_327" headers="payout-pcc-{{i}} payout-attper"
            [attr.colspan]="pcg.PlanComponentCombinations.length" *ngFor="let pcg of old_data.PcgList">
            {{pcg.Metrics.WeightedAttainment | number: '1.2-2'}}%
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_328" class="level-1" *ngIf="old_showPCC">
          <td id="payout-pcc" headers="payout-title" scope="row">Metrics</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_330" headers="payout-pcc-{{i}} payout-pcc"
              *ngFor="let pcc of pcg.PlanComponentCombinations">{{pcc.Name}}</td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_332" class="sub-level" ng-class="{'no-brd-top': !old_showPCC}">
          <td id="payout-bastrt" headers="payout-title" scope="row">Total Target - Total Recurring Target (A)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_334" headers="payout-pcc-{{i}} payout-bastrt"
              *ngFor="let pcc of pcg.PlanComponentCombinations">
              {{pcc.IsDynamicMetric ? (pcc.Metrics.Target | number: '1.2-2') : ((pcc.Metrics.Target -
              pcc.Metrics.TotalRecurringTarget) | number: '1.2-2')}}
            </td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_336" class="sub-level-item">
          <td id="payout-bastar" headers="payout-title" scope="row">Baseline Target (C)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_338" headers="payout-pcc-{{i}} payout-bastar"
              *ngFor="let pcc of pcg.PlanComponentCombinations">{{pcc.Metrics.BaselineTarget | number: '1.2-2'}}</td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_340" class="sub-level-item">
          <td id="payout-tadj" headers="payout-title" scope="row">Target Adjustment (D)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_342" headers="payout-pcc-{{i}} payout-tadj"
              *ngFor="let pcc of pcg.PlanComponentCombinations">{{pcc.Metrics.TargetAdjustment | number: '1.2-2'}}</td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_346" class="sub-level-item">
          <td id="payout-tadj" headers="payout-title" scope="row">Target Modifer (E)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_348" headers="payout-pcc-{{i}} payout-tadj"
              *ngFor="let pcc of pcg.PlanComponentCombinations">{{pcc.Metrics.TargetModifier | number: '1.2-2'}}</td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_458" *ngIf="old_isMinimumQuotaThresholdEligibile" class="sub-level-item">
          <td id="payout-tadj" headers="payout-title" scope="row">Minimum Target Adjustment (F)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_460" headers="payout-pcc-{{i}} payout-tadj"
              *ngFor="let pcc of pcg.PlanComponentCombinations">
              {{pcc.Metrics.MinimumTargetAdjustment | number: '1.2-2'}}
            </td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_350" class="sub-level-item">
          <td id="payout-tat" *ngIf="old_isMinimumQuotaThresholdEligibile" headers="payout-title" scope="row">Total
            Target (C+D+E+F)</td>
          <td id="payout-tat" *ngIf="!old_isMinimumQuotaThresholdEligibile" headers="payout-title" scope="row">Total
            Target (C+D+E)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_352" headers="payout-pcc-{{i}} payout-tat"
              *ngFor="let pcc of pcg.PlanComponentCombinations">{{pcc.Metrics.Target | number: '1.2-2'}}</td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_354" class="sub-level-item" *ngIf="old_showRecurring">
          <td id="payout-rectar" headers="payout-title" scope="row">Recurring Target (G)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_356" headers="payout-pcc-{{i}} payout-rectar"
              *ngFor="let pcc of pcg.PlanComponentCombinations">{{ pcc.IsDynamicMetric? 'N/A':
              (pcc.Metrics.TargetRecurring | number: '1.2-2')}}</td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_358" class="sub-level-item" *ngIf="old_showRecurring">
          <td id="payout-recuadj" headers="payout-title" scope="row">Recurring Adjustment (H)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_360" headers="payout-pcc-{{i}} payout-recuadj"
              *ngFor="let pcc of pcg.PlanComponentCombinations">
              {{pcc.Metrics.TargetRecurringAdjustment | number: '1.2-2'}}
            </td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_364" class="sub-level-item" *ngIf="old_showRecurring">
          <td id="payout-recuadj" headers="payout-title" scope="row">Recurring Modifier (I)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_366" headers="payout-pcc-{{i}} payout-recuadj"
              *ngFor="let pcc of pcg.PlanComponentCombinations">
              {{pcc.Metrics.TargetRecurringModifier | number: '1.2-2'}}
            </td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_368" class="sub-level-item" *ngIf="old_showRecurring">
          <td id="payout-trt2" headers="payout-title" scope="row">Total Recurring Target (G+H+I)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_370" headers="payout-pcc-{{i}} payout-trt2"
              *ngFor="let pcc of pcg.PlanComponentCombinations">{{pcc.IsDynamicMetric? 'N/A':
              (pcc.Metrics.TotalRecurringTarget | number: '1.2-2')}}</td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_372" class="sub-level">
          <td id="payout-trt" headers="payout-title" scope="row">Total Actuals - Total Recurring Target (B)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_374" headers="payout-pcc-{{i}} payout-trt"
              *ngFor="let pcc of pcg.PlanComponentCombinations">
              {{pcc.IsDynamicMetric ? (((pcc.Metrics.Actuals - pcc.Metrics.TotalRecurringTarget) | number: '1.2-2') +
              '\n\n (Value of Commitments Landed)') : ((pcc.Metrics.Actuals - pcc.Metrics.TotalRecurringTarget) |
              number: '2.2-2') }}
            </td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_376" class="sub-level-item">
          <td id="payout-basact" headers="payout-title" scope="row">Baseline Actuals (J)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_378" headers="payout-pcc-{{i}} payout-basact"
              *ngFor="let pcc of pcg.PlanComponentCombinations">
              {{pcc.IsDynamicMetric ? ((pcc.Metrics.BaselineActuals - pcc.Metrics.TotalRecurringTarget) | number:
              '1.2-2') :
              ((pcc.Metrics.BaselineActuals - (pcc.Metrics.AIACRActualCredit + pcc.Metrics.AIACRActualMultiplier)) |
              number: '1.2-2')}}
            </td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_380" class="sub-level-item">
          <td id="payout-baseline" headers="payout-title" scope="row">Baseline Actuals Adjustment (K)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_382" headers="payout-pcc-{{i}} payout-baseline"
              *ngFor="let pcc of pcg.PlanComponentCombinations">{{pcc.Metrics.ActualsAdjustment | number: '1.2-2'}}</td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_467" *ngIf="old_isIndividualAITargetMet" class="sub-level-item">
          <td id="payout-baseline" headers="payout-title" scope="row">Accelerate Azure Revenue Credit (L)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_469" headers="payout-pcc-{{i}} payout-baseline"
              *ngFor="let pcc of pcg.PlanComponentCombinations">{{pcc.Metrics.AIACRActualCredit | number: '1.2-2'}}</td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_476" *ngIf="old_isMSAITargetMet" class="sub-level-item">
          <td id="payout-baseline" headers="payout-title" scope="row">Accelerate Azure Multiplier Credit (M)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_478" headers="payout-pcc-{{i}} payout-baseline"
              *ngFor="let pcc of pcg.PlanComponentCombinations">
              {{pcc.Metrics.AIACRActualMultiplier | number: '1.2-2'}}
            </td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_386" class="sub-level-item">
          <td id="payout-baseline" headers="payout-title" scope="row">Baseline Actuals Modifier (N)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_388" headers="payout-pcc-{{i}} payout-baseline"
              *ngFor="let pcc of pcg.PlanComponentCombinations">{{pcc.Metrics.ActualsModifier | number: '1.2-2'}}</td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_390" class="sub-level-item">
          <td id="payout-tact" *ngIf="old_isIndividualAITargetMet && !old_isMSAITargetMet" headers="payout-title"
            scope="row">Total Actuals (J+K+L+N)</td>
          <td id="payout-tact" *ngIf="old_isMSAITargetMet" headers="payout-title" scope="row">Total Actuals
            (J+K+L+M+N)</td>
          <td id="payout-tact" *ngIf="!old_isIndividualAITargetMet && !old_isMSAITargetMet" headers="payout-title"
            scope="row">Total Actuals (J+K+N)</td>
          <ng-container *ngFor="let pcg of old_data.PcgList">
            <td id="payoutDetailedMetrics_392" headers="payout-pcc-{{i}} payout-tact"
              *ngFor="let pcc of pcg.PlanComponentCombinations">{{pcc.IsDynamicMetric ? ((pcc.Metrics.Actuals -
              pcc.Metrics.TotalRecurringTarget) | number: '1.2-2') :(pcc.Metrics.Actuals | number: '1.2-2')}}</td>
          </ng-container>
        </tr>
        <tr id="payoutDetailedMetrics_394" class="main-level brd-top">
          <td id="payout-before" headers="payout-title" scope="row">Payout % Before Applying Caps</td>
          <td id="payoutDetailedMetrics_395" headers="payout-pcc-{{i}} payout-before"
            [attr.colspan]="pcg.PlanComponentCombinations.length" *ngFor="let pcg of old_data.PcgList">
            {{pcg.calc.PayoutBeforeCaps | number: '1.2-2'}}%
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_396">
          <td id="payout-till" headers="payout-title" scope="row">Payout % Until Threshold</td>
          <td id="payoutDetailedMetrics_397" headers="payout-pcc-{{i}} payout-till"
            [attr.colspan]="pcg.PlanComponentCombinations.length" *ngFor="let pcg of old_data.PcgList">
            {{pcg.calc.PayoutAtThreshold}}
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_398">
          <td id="payout-threshold" headers="payout-title" scope="row">Payout % Between Threshold and Target</td>
          <td id="payoutDetailedMetrics_399" headers="payout-pcc-{{i}} payout-threshold"
            [attr.colspan]="pcg.PlanComponentCombinations.length" *ngFor="let pcg of old_data.PcgList">
            {{pcg.calc.PayoutThresholdToTarget}}
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_400">
          <td id="payout-between" headers="payout-title" scope="row">Payout % Between Target and Excellence</td>
          <td id="payoutDetailedMetrics_401" headers="payout-pcc-{{i}} payout-between"
            [attr.colspan]="pcg.PlanComponentCombinations.length" *ngFor="let pcg of old_data.PcgList">
            {{pcg.calc.PayoutTargetToExcellence}}
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_402">
          <td id="payout-excel" headers="payout-title" scope="row">Payout Above Excellence</td>
          <td id="payoutDetailedMetrics_403" headers="payout-pcc-{{i}} payout-excel"
            [attr.colspan]="pcg.PlanComponentCombinations.length" *ngFor="let pcg of old_data.PcgList">
            {{pcg.calc.PayoutBeyondExcellence}}
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_404" class="sub-level">
          <td id="payout-advance" headers="payout-title" scope="row">Caps Applied</td>
          <td id="payoutDetailedMetrics_405" headers="payout-pcc-{{i}} payout-advance"
            [attr.colspan]="pcg.PlanComponentCombinations.length" *ngFor="let pcg of old_data.PcgList">
            {{pcg.UsedCap | number: '1.2-2'}}%
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_406" class="main-level">
          <td id="payout-after" headers="payout-title" scope="row">Payout % After Applying Caps</td>
          <td id="payoutDetailedMetrics_407" headers="payout-pcc-{{i}} payout-after"
            [attr.colspan]="pcg.PlanComponentCombinations.length" *ngFor="let pcg of old_data.PcgList">
            {{pcg.calc.PayoutAfterCaps | number: '1.2-2'}}%
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_408" class="main-level">
          <td id="payout-total-inc" headers="payout-title" scope="row">Total Prorated Incentive Target Amount (X)</td>
          <td id="payoutDetailedMetrics_409" headers="payout-total-inc"
            [attr.colspan]="old_data.PcgList ? old_data.PcgList[0].PlanComponentCombinations.length : 1">
            {{old_TotalIncentiveTargetFullYear | number: '1.2-2'}}
          </td>
        </tr>
        <ng-container *ngIf="old_data.ActivePeriodItaCount > 1">
          <tr id="payoutDetailedMetrics_410" class="main-level" *ngFor="let UserITA of old_data.UserITAs">
            <td id="payoutDetailedMetrics_411" headers="payout-title" scope="row"
              *ngIf="UserITA.PeriodStatus == 'Active'">
              {{UserITA.FromDateTarget | date : "MMM dd,y"}} - {{UserITA.ToDateTarget | date : "MMM dd,y"}}
            </td>
            <td id="payoutDetailedMetrics_412" scope="row" *ngIf="UserITA.PeriodStatus == 'Active'">{{UserITA.ITA |
              number: '1.2-2'}}</td>
          </tr>
        </ng-container>
        <tr id="payoutDetailedMetrics_452" class="main-level" *ngIf="old_totalParticipationDays < 365">
          <td id="payout-total-inc" headers="payout-title" scope="row">Total Prorated Incentive Target Amount</td>
          <td id="payoutDetailedMetrics_453" headers="payout-total-inc"
            [attr.colspan]="old_data.PcgList ? old_data.PcgList[0].PlanComponentCombinations.length : 1">
            {{old_data.TotalIncentiveTarget | number: '1.2-2'}}
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_413" class="sub-level no-brd-top">
          <td id="payout-rbi" headers="payout-title" scope="row">Metric Weight % (Y)</td>
          <td id="payoutDetailedMetrics_414" headers="payout-pcc-{{i}} payout-rbi"
            [attr.colspan]="pcg.PlanComponentCombinations.length" *ngFor="let pcg of old_data.PcgList">
            {{pcg.Weightage* 100 | number: '1.2-2'}}%
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_415" class="main-level">
          <td id="payout-Incentive-Target" headers="payout-title" scope="row">
            {{old_payoutTitle}} [ Total Target RBI (X) x Bucket Weight (Y) x ( Number of days in role / Total Days in
            Year) x Payout % ]
          </td>
          <td id="payoutDetailedMetrics_416" headers="payout-pcc-{{$index}} payout-Incentive-Target"
            [attr.colspan]="pcg.PlanComponentCombinations.length" *ngFor="let pcg of old_data.PcgList">
            {{pcg.ActiveEarningsCalc}}
          </td>
        </tr>
      </tbody>
      <tbody id="payoutDetailedMetrics_417">
        <tr id="payoutDetailedMetrics_418" class="main-level" *ngIf="old_showTARow">
          <td id="payoutDetailedMetrics_419" scope="row">Transition Allowance Prorated Target [ Total Target RBI (X) x
            Bucket Weight (Y) x ( TA Days / Total Days in Year) x TA Payout % ]</td>
          <td id="payoutDetailedMetrics_420" [attr.colspan]="pcg.PlanComponentCombinations.length"
            *ngFor="let pcg of old_data.PcgList">{{ pcg.TAEarningsCalc }}</td>
        </tr>

        <tr id="payoutDetailedMetrics_421" class="main-level" *ngIf="old_showLOARow">
          <td id="payoutDetailedMetrics_422" headers="payout-title" scope="row">
            Paid Leave of Absence Prorated Target [ Total Target RBI (X) x Bucket Weight (Y) x ( LOA Days / Total Days
            in Year ) x LOA Payout % ]
          </td>
          <td id="payoutDetailedMetrics_423" [attr.colspan]="pcg.PlanComponentCombinations.length"
            *ngFor="let pcg of old_data.PcgList">{{ pcg.LOAEarningsCalc }}</td>
        </tr>
      </tbody>
      <tbody id="payoutDetailedMetrics_424" *ngIf="old_showProjectedEarningsSinceLastPayout">
        <tr id="payoutDetailedMetrics_425" class="empty-space" aria-hidden="true">
          <td id="payoutDetailedMetrics_426"></td>
        </tr>
        <tr id="payoutDetailedMetrics_427" class="highlight-row">
          <td scope="row" headers="earning-title" id="payout-projected-sincelastpayout">Projected Earnings Since Last
            Payout</td>
          <td id="payoutDetailedMetrics_429" headers="payout-projected-sincelastpayout"
            [attr.colspan]="old_data.PcgList[0].PlanComponentCombinations.length">
            {{old_data.ProjectedEarningsSinceLastPayout | number: '1.2-2'}}
          </td>
        </tr>
      </tbody>
      <tr id="payoutDetailedMetrics_430" class="main-level">
        <td id="payout-PCG-Incentive" headers="payout-title" scope="row">Bucket Incentive Award (Bucket Target RBI *
          Payout %)</td>
        <td id="payoutDetailedMetrics_431" headers="payout-pcc-{{i}} payout-PCG-Incentive"
          [attr.colspan]="pcg.PlanComponentCombinations.length" *ngFor="let pcg of old_data.PcgList">
          {{ pcg.IncentiveAward | number: '1.2-2'}}
        </td>
      </tr>
      <tbody id="payoutDetailedMetrics_432" *ngIf="old_data.NetPaymentAmount==undefined">
        <tr id="payoutDetailedMetrics_433" class="main-level">
          <td id="payout-net" headers="payout-title" scope="row">Projected YTD Earning for Current Participation</td>
          <td id="payoutDetailedMetrics_434" headers="payout-net">{{old_data.TotalRBIAward | number: '1.2-2'}}</td>
        </tr>
      </tbody>
      <tbody id="payoutDetailedMetrics_480" *ngIf="old_data.IsQRApplied">
        <tr id="payoutDetailedMetrics_482" class="highlight-row">
          <td id="payout-net-hidden" headers="payout-title" scope="row" aria-label="Quota Relief">Quota Relief Impact
            (Already included in Net Payment)</td>
          <td id="payoutDetailedMetrics_485" headers="payout-net"
            [attr.colspan]="old_data.PcgList[0].PlanComponentCombinations.length">{{old_data.QRAmount | number:
            '1.2-2'}}</td>
        </tr>
      </tbody>
      <tbody id="payoutDetailedMetrics_435" *ngIf="old_data.NetPaymentAmount">
        <tr id="payoutDetailedMetrics_436" class="main-level">
          <td id="payout-net" headers="payout-title" scope="row">Net Payment (O-P+Q+R)</td>
          <td id="payoutDetailedMetrics_437" headers="payout-net">{{old_data.NetPaymentAmount | number: '1.2-2'}}</td>
        </tr>

        <tr id="payoutDetailedMetrics_438">
          <td id="payout-RBI" headers="payout-title" scope="row">Total RBI Award (O)</td>
          <td id="payoutDetailedMetrics_439" headers="payout-RBI">{{old_data.TotalRBIAward | number: '1.2-2'}}</td>
        </tr>
        <tr id="payoutDetailedMetrics_440">
          <td id="payout-prevpay" headers="payout-title" scope="row">Previous Payment (P)</td>
          <td id="payoutDetailedMetrics_441" headers="payout-prevpay">{{old_data.PreviousPayment | number: '1.2-2'}}
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_442">
          <td id="payout-payrol" headers="payout-title" scope="row">Payroll Adjustments (Q)</td>
          <td id="payoutDetailedMetrics_443" headers="payout-payrol">{{old_data.PayrollAdjustment | number: '1.2-2'}}
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_444" class="main-level">
          <td id="payout-total-inc" headers="payout-title" scope="row">Recovery Amount (R)</td>
          <td id="payoutDetailedMetrics_445" headers="payout-total-inc">{{old_data.RecoveryAmount | number: '1.2-2'}}
          </td>
        </tr>
        <tr id="payoutDetailedMetrics_452" class="main-level">
          <td id="payout-total-inc" headers="payout-title" scope="row">Allowances (Included in Total RBI Award)</td>
          <td id="payoutDetailedMetrics_453" headers="payout-total-inc">{{old_data.TotalAllowanceEarning | number:
            '1.2-2'}}</td>
        </tr>
      </tbody>

      <tbody id="payoutDetailedMetrics_446" *ngIf="old_data.ShowPayoutCurencyData">
        <tr id="payoutDetailedMetrics_447" class="sub-level">
          <td id="payout-curency" headers="payout-title" scope="row">
            Exchange Rate ({{old_data.PreviousCurrencyCode}} to {{old_data.TransferredCurrencyCode}}) as on
            {{old_data.ExchangeDate | ToLocalDate}}
          </td>
          <td id="payoutDetailedMetrics_448" headers="payout-curency">{{old_data.ExchangeRate}}</td>
        </tr>
      </tbody>
      <tbody id="payoutDetailedMetrics_449" *ngIf="old_data.ShowPayoutCurencyData">
        <tr id="payoutDetailedMetrics_450" class="sub-level">
          <td id="payout-curency" headers="payout-title" scope="row">Final Payout (in
            {{old_data.TransferredCurrencyCode}})</td>
          <td id="payoutDetailedMetrics_451" headers="payout-curency">{{old_data.TransferredCurrencyAmount | number:
            '1.2-2'}}</td>
        </tr>
      </tbody>
    </table>
    <table #payout_tables_temp_QR *ngIf="old_data.IsQRApplied" [hidden]="true">
      <tbody id="Qr_Header">
        <tr id="planSummary_100">
          <th id="pcg-title">Bucket</th>
          <th id="pcc-title">Actuals</th>
          <th id="pcc-target">Target before relief</th>
          <th id="pcc-target">Attainment before relief</th>
          <th id="pcc-target">Target after relief</th>
          <th id="pcc-target">Attainment after relief</th>
        </tr>
      </tbody>
      <tbody id="planSummary_101">
        <tr id="planSummary_102" *ngFor="let pcgQR of old_data.qrPCGLevelDetails">
          <td id="planSummary_103">{{ pcgQR.Name }}</td>
          <td id="planSummary_104">{{ pcgQR.Metrics.Actuals }} USD</td>
          <td id="planSummary_105">{{ pcgQR.Metrics.TargetNonQR }} USD</td>
          <td id="planSummary_106">{{ pcgQR.Metrics.AttainmentNonQR }}%</td>
          <td id="planSummary_107">{{ pcgQR.Metrics.Target }} USD</td>
          <td id="planSummary_108">{{ pcgQR.Metrics.Attainment }}%</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="!preFY22Report && isLoaded" class="payout-header">
    <div class="payout-header-section1">
      <div id="employee-header-section">
        <div class="employee-header-background">
          <div class="employee-info">{{ employeeInfo?.getUserName() }}</div>
        </div>
      </div>
      <mint-tabs [tabs]="payoutFilterTabs" [activeId]="selectedTab" class="payout-tabs"
        (tabChange)="onTabChange($event)"></mint-tabs>
      <!-- <div class="payout-toggle"><mint-toggle-switch [toggleSwitchConfig]="toggleSwitchConfigPayout" class="ytd__toggle" (click)="switchReports()"> </mint-toggle-switch></div> -->
    </div>
    <div class="payout-header-section2">
      <div #employee_avatar class="payout-header-section2-background">
        <ngx-avatar id="{{ employeeInfo?.getUserName() }}" name="{{ employeeInfo?.getUserName() }}" initialsSize="2"
          size="60" [round]="true" borderColor="white" alt="Employee Pic" src="{{ thumbnailPhoto }}" textSizeRatio="2">
        </ngx-avatar>
        <div *ngIf="showBonusPayoutInfo && selectedPayoutTab && selectedPayoutTab[0].PublicationDate"
          class="publication-date-info">Calculation
          Date: {{ maxPublicationDate | date: 'mediumDate' }}</div>
        <div *ngIf="!showBonusPayoutInfo && selectedPayoutTab && selectedPayoutTab.PublicationDate"
          class="publication-date-info">Calculation
          Date: {{ selectedPayoutTab.PublicationDate | date: 'mediumDate' }}</div>
      </div>
      <div class="export-to-excel">
        <mint-export-excel class="margin-right10" [id]="'PayoutReport'" [downloadInactive]="showPayoutReportLoader"
          [reportFileName]="'PayoutReport'" (notifyDownloadClick)="exportPayoutReport($event)"
          [labelText]="payoutReportLabelText"></mint-export-excel>
      </div>
    </div>
  </div>

  <mint-payout-report-section *ngIf="payoutInfo && showBonusPayoutInfo" [payoutInfoArray]="selectedPayoutTab">
  </mint-payout-report-section>
  <mint-payout-backup-report-section *ngIf="payoutInfo && !showBonusPayoutInfo" [payoutInfo]="selectedPayoutTab">
  </mint-payout-backup-report-section>
</div>
<div *ngIf="!isLoaded" class="content__loader">
  <mint-content-loader-large></mint-content-loader-large>
</div>