import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { EndpointService } from './endpoint.service';
import { map } from 'rxjs/operators';
import { Response } from 'selenium-webdriver/http';
import { ENVIRONMENT_CONFIG } from '@mint-libs/common';
import { AnyFn } from '@ngrx/store/src/selector';
import { CustomHttpParams } from './custom-http-params';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  environmentConfig: any;
  constructor(private http: HttpClient, @Inject(ENVIRONMENT_CONFIG) envConfig: any) {
    this.environmentConfig = envConfig;
  }

  public get<T>(action: string, params: HttpParams | { [key: string]: any }): Observable<T> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // if (params == null) {
    //   params = CustomHttpParams.getInstance();
    // }
    if (params == null) {
      params = CustomHttpParams.getInstance();
    }
    else if (!(params instanceof HttpParams)) {
      let tempParams = CustomHttpParams.getInstance();
      for (const row in params) {
        tempParams = tempParams.append(row, params[row]);
      }
      params = tempParams;
    }
    if (!params.has('CamelCase')) {
      params = params.append('CamelCase', 'true');
    }
    params = params.append('ExtendFilters', 'true');
    const endpointUri = this.getEndpointUri(action);
    return this.http
      .get<any>(endpointUri, {
        headers: headers,
        params: params
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  public post<T>(action: string, body: JSON): Observable<T> {
    const urlBody = body;
    const endpointUri = this.getEndpointUri(action);
    return this.http.post<T>(endpointUri, urlBody);
  }

  getEndpointUri(action: string): string {
    // defaulted to csa
    const environment = this.environmentConfig;
    if (action && action.startsWith('http')) { // We have a base url already 
      return action;
    }
    const endpointUri = environment.mintapiBaseUrl + action;

    return endpointUri;
  }
}
