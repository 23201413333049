<div class="direct__utilization__quota">
  <div class="metric__type"><div class="metric__type__desc">Non-Recurring or Non-Baseline/Total Quota</div></div>
  <div class="pcg__items__container">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem class="metric__detail slide" *ngFor="let metric of metrics">
        <div class="metric__name">{{ metric.name }}</div>
        <div class="metric__weight">{{ metric.nonRecurringQuota | customCurrency: metric.unit }}</div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
<div class="direct__utilization__quota">
  <div class="metric__type"><div class="metric__type__desc">Quota To Go</div></div>
  <div class="pcg__items__container">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem class="metric__detail slide" *ngFor="let metric of metrics">
        <div class="metric__name">{{ metric.name }}</div>
        <div class="metric__weight">{{ metric.quotaToGo | customCurrency: metric.unit }}</div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
