import { Component, OnInit, Inject, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PayoutReportService } from '../../../payout-report.service';
import * as Ng1Services from '../../../../../core/hybrid/ng1-upgraded-providers';
import { MeasureTypeMappingState, selectMeasureTypeMappingState, MeasureType } from '@mint-libs/context';
import { Store } from '@ngrx/store';
import { LoggerService } from '@mint-libs/logging';

@Component({
  selector: 'mint-payout-report-metric-details',
  templateUrl: './metric-details.component.html',
  styleUrls: ['./metric-details.component.scss']
})
export class MetricDetailsComponent implements OnInit {
  hasTargetAdjusmentDetails: any;
  hasActualsAdjusmentDetails: any;
  hasRecurringTargetAdjusmentDetails: any;
  targetAdjustmentDetails = [];
  recurringTargetAdjustmentDetails = [];
  actualsAdjustmentDetails = [];
  adjustmentDetailsInfo = [];
  viewActualsAdjustmentDetails = false;
  viewTargetAdjustmentDetails = false;
  viewRecurringTargetAdjustmentDetails = false;
  @Input() selectedMetric: any;
  measureTypeMapping: MeasureTypeMappingState = new MeasureTypeMappingState([]);

  totalRecurringStr: string = 'Total Recurring';
  recurringTargetStr: string = 'Recurring Target';

  constructor(
    private modalService: NgbModal,
    public payoutReportService: PayoutReportService,
    private loggingService: LoggerService,
    protected measureTypeMappingState: Store<MeasureTypeMappingState>
  ) {
    this.measureTypeMappingState.select(selectMeasureTypeMappingState).subscribe(measureTypeMapping => {
      this.measureTypeMapping = measureTypeMapping;
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    this.getAdjustmentDetails();
  }

  getAdjustmentDetails() {
    const isBaseline = this.measureTypeMapping.getMeasureType(this.selectedMetric.PCCID);
    if (isBaseline) {
      this.totalRecurringStr = 'Total Baseline';
      this.recurringTargetStr = 'Baseline';
    } else {
      this.totalRecurringStr = 'Total Recurring';
      this.recurringTargetStr = 'Recurring Target';
    }
    this.hasTargetAdjusmentDetails = false;
    this.hasActualsAdjusmentDetails = false;
    this.hasRecurringTargetAdjusmentDetails = false;
    this.targetAdjustmentDetails = [];
    this.actualsAdjustmentDetails = [];
    this.recurringTargetAdjustmentDetails = [];
    if (this.selectedMetric && this.selectedMetric.PCCAdjustment) {
      this.selectedMetric.PCCAdjustment.forEach(adjustment => {
        if (adjustment.QuotaAdjustment !== 0) {
          this.hasTargetAdjusmentDetails = true;
          this.populateAdjustmentDetails(this.targetAdjustmentDetails, adjustment.QuotaAdjustment, adjustment);
          this.targetAdjustmentDetails = this.sortAdjustmentDetails(this.targetAdjustmentDetails);
        }
        if (adjustment.ActualAdjustment !== 0) {
          this.hasTargetAdjusmentDetails = true;
          this.populateAdjustmentDetails(this.actualsAdjustmentDetails, adjustment.ActualAdjustment, adjustment);
          this.actualsAdjustmentDetails = this.sortAdjustmentDetails(this.actualsAdjustmentDetails);
        }
        if (adjustment.QuotaRecurringAdjustment !== 0) {
          this.hasRecurringTargetAdjusmentDetails = true;
          this.populateAdjustmentDetails(this.recurringTargetAdjustmentDetails, adjustment.QuotaRecurringAdjustment, adjustment);
          this.recurringTargetAdjustmentDetails = this.sortAdjustmentDetails(this.recurringTargetAdjustmentDetails);
        }
      });
    }
  }

  populateAdjustmentDetails(adjustmentDetailslist: any, adjustmentAmount: number, adjustmentData: any) {
    adjustmentDetailslist.push({
      AdjustmentDate: adjustmentData.AdjusmentCreatedDate,
      AdjustmentReason: adjustmentData.AdjustmentReason,
      AdjustmentType: adjustmentData.AdjustmentType,
      AdjustmentCodePeriod: adjustmentData.CodePeriod,
      AdjustmentAmount: adjustmentAmount
    });
  }

  sortAdjustmentDetails(adjustmentDetailslist: any) {
    return adjustmentDetailslist.slice().sort((a, b) => {
      return <any>new Date(a.AdjustmentDate) - <any>new Date(b.AdjustmentDate);
    });
  }

  toggleAdjustmentDetails(data: any, adjustmentDetailName: string) {
    if (adjustmentDetailName === 'actualsAdjustmentDetails') {
      this.viewActualsAdjustmentDetails = !this.viewActualsAdjustmentDetails;
      this.viewRecurringTargetAdjustmentDetails = false;
      this.viewTargetAdjustmentDetails = false;
    } else if (adjustmentDetailName === 'recurringTargetAdjustmentDetails') {
      this.viewRecurringTargetAdjustmentDetails = !this.viewRecurringTargetAdjustmentDetails;
      this.viewActualsAdjustmentDetails = false;
      this.viewTargetAdjustmentDetails = false;
    } else {
      this.viewTargetAdjustmentDetails = !this.viewTargetAdjustmentDetails;
      this.viewActualsAdjustmentDetails = false;
      this.viewRecurringTargetAdjustmentDetails = false;
    }
    this.adjustmentDetailsInfo = data;
    if (this.viewTargetAdjustmentDetails || this.viewActualsAdjustmentDetails || this.viewRecurringTargetAdjustmentDetails) {
      this.loggingService.trackEvent('PayoutReport_OpenAdjustmentDetails', { SelectedAdjustmentDetailName: adjustmentDetailName, AdjustmentInfo: this.adjustmentDetailsInfo }, true);
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
