<div class="direct__info__container">
  <div class="direct__img" title="Reportee Image">
    <ngx-avatar id="employeeInformation_6 + {{ direct.name }}" name="{{ direct.name }}" initialsSize="2" size="60" [round]="true" src="{{ thumbnailPhoto }}" textSizeRatio="2"></ngx-avatar>
  </div>
  <div class="direct__info">
    <div class="direct__name">{{ direct.name }}</div>
    <div class="direct__role">{{ direct.role }} ({{ direct.alias }})</div>
    <div class="direct__reportees" *ngIf="isShowDirects === true && !direct.isFiltered">
      <a class="direct__link" href="javascript:void(0)" (click)="viewDirects()" tabindex="0">{{ direct.viewReporteesFlag }} {{ direct.numberOfReportees }} Reportees</a>
    </div>
    <div class="direct__compliance" *ngIf="showComplianceInfo">
      <!--PPA Info-->
      <div class="compliance__sign" *ngIf="direct.ppaComplianceCountries && direct.ppaComplianceCountries.length === 1">
        <span class="icon__ms icon-ms {{ direct.ppaComplianceCountries[0].status === 2 ? 'icon-compliance-signed' : 'icon-compliance-notsigned' }}"> </span>PPA{{
          direct.ppaComplianceCountries[0].status === 2 ? direct.ppaComplianceCountries[0].countryString : ''
        }}
      </div>

      <!--For mutliple PPAs-->
      <div class="compliance__sign" *ngIf="direct.ppaComplianceCountries && direct.ppaComplianceCountries.length > 1">
        <span class="icon__ms icon-ms {{ direct.ppaComplianceCountries[0].status === 2 ? 'icon-compliance-signed' : 'icon-compliance-notsigned' }}"> </span>
        <div>PPA ({{ direct.ppaComplianceCountries.length }})</div>
        <span
          role="button"
          tabindex="0"
          (click)="isPPADetailsPopUpOpen = !isPPADetailsPopUpOpen"
          (keyup.enter)="isPPADetailsPopUpOpen = !isPPADetailsPopUpOpen"
          title="Click to get PPA details"
          class="{{ !isPPADetailsPopUpOpen ? 'glyphicon glyphicon-triangle-bottom' : 'glyphicon glyphicon-triangle-top' }}"
        ></span>
        <div class="multi__ppa__qa__popup" *ngIf="isPPADetailsPopUpOpen">
          <div class="multi__ppa__qa__item" *ngFor="let ppaComplianceItem of direct.ppaComplianceCountries" tabindex="0">
            <span class="icon__ms icon-ms {{ ppaComplianceItem.status === 2 ? 'icon-compliance-signed' : 'icon-compliance-notsigned' }}"> </span>PPA{{
              ppaComplianceItem.status === 2 ? ppaComplianceItem.countryString : ''
            }}
          </div>
        </div>
      </div>
      <!--For mutliple PPAs-->
      <!--PPA Info-->

      <!--QA Info-->
      <div class="compliance__sign" *ngIf="direct.qaComplianceDates && direct.qaComplianceDates.length === 1">
        <span class="icon__ms icon-ms {{ direct.qaComplianceDates[0].status === 2 ? 'icon-compliance-signed' : 'icon-compliance-notsigned' }}"></span>
        {{ direct.quotaDocumentName }}{{ direct.qaComplianceDates[0].status === 2 ? direct.qaComplianceDates[0].dateRangeString : '' }}
      </div>

      <!--For mutliple QAs-->
      <div class="compliance__sign" *ngIf="direct.qaComplianceDates && direct.qaComplianceDates.length > 1">
        <span class="icon__ms icon-ms {{ direct.qaComplianceDates[0].status === 2 ? 'icon-compliance-signed' : 'icon-compliance-notsigned' }}"></span>
        <div>{{ direct.quotaDocumentName }} ({{ direct.qaComplianceDates.length }})</div>
        <span
          role="button"
          tabindex="0"
          (click)="isQADetailsPopUpOpen = !isQADetailsPopUpOpen"
          (keyup.enter)="isQADetailsPopUpOpen = !isQADetailsPopUpOpen"
          title="Click to get {{ direct.quotaDocumentName }} details"
          class="{{ !isQADetailsPopUpOpen ? 'glyphicon glyphicon-triangle-bottom' : 'glyphicon glyphicon-triangle-top' }}"
        ></span>
        <div class="multi__ppa__qa__popup" *ngIf="isQADetailsPopUpOpen">
          <div class="multi__ppa__qa__item" *ngFor="let qaComplianceItem of direct.qaComplianceDates" tabindex="0">
            <span class="icon__ms icon-ms {{ qaComplianceItem.status === 2 ? 'icon-compliance-signed' : 'icon-compliance-notsigned' }}"> </span>{{ direct.quotaDocumentName
            }}{{ qaComplianceItem.status === 2 ? qaComplianceItem.dateRangeString : '' }}
          </div>
        </div>
      </div>
      <!--For mutliple QAs-->
      <!--QA Info-->
    </div>
    <div class="direct__optout" *ngIf="showComplianceInfo && direct.optedOut">
      <div class="direct__optout__icon">
        <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.0214844 22.1878L1.06327 23L6.53171 16.9234V17.0383H11.9235V15.4225H7.88246L9.16806 13.9939H15.0701V12.3781H10.6222L12.122 10.7115H15.0701V9.09563H13.5761L20.9464 0.905713L19.9514 0L14.8448 5.6851H9.50821V7.30093H13.3935L11.7814 9.09563H6.53171V10.7115H10.33L8.83297 12.3781H6.53171V13.9939H7.38158L0.0214844 22.1878ZM3.23067 1.08096H16.7959L15.3713 2.6436H4.70596V14.343L3.23067 15.9614V1.08096ZM18.3308 6.38226L16.7687 8.0785V20.3472H5.47023L4.11211 21.8219H18.3308V6.38226Z"
            fill="#4F4F4F"
          />
        </svg>
      </div>
      <div class="direct__optout__txt">Opted out</div>
    </div>
    <div class="direct__runrate" *ngIf="direct.isUBIPersona">
      <div class="direct__runrate__icon">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 3H14V7H13V4.71094L8.5 9.20312L6.5 7.20312L1 12.7109V13H14V14H0V0H1V11.2891L6.5 5.79688L8.5 7.79688L12.2891 4H10V3Z" fill="#707070" />
        </svg>
      </div>
      <div class="direct__runrate__txt">
        Run rate to reach 100% utilization: <span class="direct__runrate__txt__ag">{{ direct.ubiRunRate | number: '1.2-2' }} Hrs</span>
      </div>
    </div>
    <div class="direct__more__info">
      <div class="direct__incentive__type" *ngIf="direct.incentiveType">{{ direct.incentiveType }}</div>
      <div class="direct__impersonate">
        <button
          (click)="impersonateDirect()"
          *ngIf="showComplianceInfo && direct.isFCAHasAccess"
          aria-label="Impersonate"
          [disabled]="sharedContext.isImpersonating || anyQaAvailable == false"
          analytics-tracker
          [tracker]="'Impersonate_Direct'"
          [type]="'Click'"
          [element]="'Button'"
          [source]="'New Manager Dashboard'"
        >
          Impersonate
          <div class="icon icon__arrow__right">
            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 5.5L6.47047 11L5.94166 10.4739L10.5663 5.87407H0V5.12593H10.5663L5.94166 0.526091L6.47047 0L12 5.5Z" fill="#0078D7" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
