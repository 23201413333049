import { Component, Inject } from '@angular/core';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { ImpersonationService } from '../core/impersonation/impersonation.service';

@Component({
  selector: 'app-impersonation-notification',
  templateUrl: './impersonation-notification.component.html',
  styleUrls: ['./impersonation-notification.component.scss']
})
export class ImpersonationNotificationComponent {
  bannerText = 'impersonating';
  isFCAQuotaApprovalPendingError = false;
  isFCAQuotaApprovalPending = false;
  isTerminationReasonNull = true;
  public sharedContext: SharedContext = new SharedContext();

  constructor(public impersonationService: ImpersonationService, public sharedContextState: Store<SharedContext>) {
    this.impersonationService = impersonationService;

    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      if (this.sharedContext.isImpersonating) {
        this.isFCAQuotaApprovalPendingError = this.sharedContext.showFCAQuotaApprovalPendingError;
        this.isFCAQuotaApprovalPending = this.sharedContext.fcaQuotaApprovalPendingButAllowAccess && !this.sharedContext.terminationReason;
        this.isTerminationReasonNull = this.sharedContext.terminationReason === null ? true : false;
      }

      if (this.sharedContext.isViewAs) {
        this.bannerText = 'viewing';
      }
    });
  }
}
