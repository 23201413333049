import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mint-content-loader-double-bar-chart-multiple',
  templateUrl: './content-loader-double-bar-chart-multiple.component.html'
})
export class ContentLoaderDoubleBarChartMultipleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
