import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@mint-libs/authentication';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterModel, IFilterModel } from './filter-model';
import { ManageNotificationService } from './manage-notification.service';

@Component({
  selector: 'mint-manage-notification',
  templateUrl: './manage-notification.component.html',
  styleUrls: ['./manage-notification.component.scss']
})
export class ManageNotificationComponent implements OnInit {
  toggleAdd = false;
  currNotificaiton = {};
  dropdownSettings = {};
  filters = {};
  businessSegmentFilter: IFilterModel;
  planFilter: IFilterModel;
  roleFilter: IFilterModel;
  standardTitleFilter: IFilterModel;
  qualifier1Filter: IFilterModel;
  qualifier2Filter: IFilterModel;
  countryFilter: IFilterModel;
  allNotifications: any = [];
  notificationType = ['Information', 'Warning', 'Error', 'Alert', 'Banner'];
  page = 1;
  pageSize = 10;
  newNotification: any = {};
  adminAlias: any;
  saveNotificationTitle: string;
  modalMessage: string;
  modalRef: any;
  expireDialogMessage = 'Do you want to expire this notification ? You can always re-activate by editing the same.';
  deleteDialogMessage = 'Do you want to delete this notification ? This action cannot be reversed.';
  actionidx: any;
  actionNotification: any;
  actionType: any;
  showAlias = true;
  detailsFilter = false;
  eventType = 'Add';
  isEditOn: boolean;

  constructor(
    private manageNotificationService: ManageNotificationService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
  ) {
    this.initiliazeFilters();
  }

  ngOnInit() {
    var userInfo = this.authService.getUserInfo();
    this.adminAlias = userInfo.userName;
    this.adminAlias = this.adminAlias.substr(0, this.adminAlias.lastIndexOf('@'));
    this.getAllNotifications();
    this.getFiltersData();
  }

  getAllNotifications() {
    this.allNotifications = [];
    this.manageNotificationService.GetAdminNotifications().subscribe(
      (response: any) => {
        console.log('all notifications', response);
        this.allNotifications = response.notifications;
        this.allNotifications.sort((notify1, notify2) => {
          if (notify1['currentStatus'] === 'Expired' && notify2['currentStatus'] !== 'Expired') {
            return 1;
          }
          if (notify1['currentStatus'] !== 'Expired' && notify2['currentStatus'] === 'Expired') {
            return -1;
          }
          return new Date(notify2.notificationModifiedDate).getTime() - new Date(notify1.notificationModifiedDate).getTime();
        });
      }
    );
  }

  getLocalDate(date) {
    return new Date(date + 'Z');
  }

  initiliazeFilters() {
    this.businessSegmentFilter = new FilterModel('Select Business Segments', 'Business Segments');
    this.planFilter = new FilterModel('Select Plans', 'Plans');
    this.roleFilter = new FilterModel('Select Roles', 'Roles');
    this.standardTitleFilter = new FilterModel('Select Standard Titles', 'Standard Titles');
    this.qualifier1Filter = new FilterModel('Select Qualifier 1s', 'Qualifier 1s');
    this.qualifier2Filter = new FilterModel('Select Qualifier 2s', 'Qualifier 2s');
    this.countryFilter = new FilterModel('Select Countries', 'Countries');
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'idNumber',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  toggleNotificationPane(eventType: string) {
    this.toggleAdd = (eventType === 'Add' || eventType === 'Edit') ? true : false;
    if (eventType === 'Add') {
      this.initializeNewNotification();
    }
    this.eventType = eventType;
    this.isEditOn = eventType === 'Edit';
    this.saveNotificationTitle = `${eventType} Notification`;
  }

  editNotification(notification) {
    this.toggleNotificationPane('Edit');
    this.currNotificaiton = notification;
    this.currNotificaiton['notificationStartDate'] = this.formatDate(this.getLocalDate(this.currNotificaiton['notificationStartDate']));
    this.currNotificaiton['notificationEndDate'] = this.formatDate(this.getLocalDate(this.currNotificaiton['notificationEndDate']));
    if (this.currNotificaiton['listOfAlias']) {
      this.showAlias = true;
      this.detailsFilter = false;
    }
    else {
      this.showAlias = false;
      this.detailsFilter = true;
    }
    this.bindDropDownFilter(notification);
  }

  cloneNotification(notification) {
    this.toggleNotificationPane('Add');
    this.currNotificaiton = notification;
    this.currNotificaiton['notificationStartDate'] = this.formatDate(this.getLocalDate(this.currNotificaiton['notificationStartDate']));
    this.currNotificaiton['notificationEndDate'] = this.formatDate(this.getLocalDate(this.currNotificaiton['notificationEndDate']));
    this.currNotificaiton['createdBy'] = this.adminAlias;

    if (this.currNotificaiton['listOfAlias']) {
      this.showAlias = true;
      this.detailsFilter = false;
    }
    else {
      this.showAlias = false;
      this.detailsFilter = true;
    }
    this.bindDropDownFilter(notification);
  }

  bindDropDownFilter(notification) {
    console.log('edit notification ', notification);
    this.getFiltersData();

    if (notification.businessSegments === 'ALL') {
      this.businessSegmentFilter.selected = this.businessSegmentFilter.list;
    } else {
      const selectedBusinessSegments = notification.businessSegments.split(',');
      selectedBusinessSegments.forEach(selectedBusinessSegment => {
        const selectedItem = this.businessSegmentFilter.list.find(x => x.name.trim().toLowerCase() === selectedBusinessSegment.trim().toLowerCase());
        if (selectedItem) {
          this.businessSegmentFilter.selected.push(selectedItem);
        }
      });
    }

    if (notification.planNames === 'ALL') {
      this.planFilter.selected = this.planFilter.list;
    } else {
      const selectedPlanSegments = notification.planNames.split(',');
      selectedPlanSegments.forEach(selectedPlanSegment => {
        const selectedItem = this.planFilter.list.find(x => x.name.trim().toLowerCase() === selectedPlanSegment.trim().toLowerCase());
        if (selectedItem) {
          this.planFilter.selected.push(selectedItem);
        }
      });
    }

    if (notification.qualifier1Names === 'ALL') {
      this.qualifier1Filter.selected = this.qualifier1Filter.list;
    } else {
      const selectedQualifier1Names = notification.qualifier1Names.split(',');
      selectedQualifier1Names.forEach(selectedqualifier1Name => {
        const selectedItem = this.qualifier1Filter.list.find(x => x.name.trim().toLowerCase() === selectedqualifier1Name.trim().toLowerCase());
        if (selectedItem) {
          this.qualifier1Filter.selected.push(selectedItem);
        }
      });
    }

    if (notification.qualifier2Names === 'ALL') {
      this.qualifier2Filter.selected = this.qualifier2Filter.list;
    } else {
      const selectedQualifier2Names = notification.qualifier2Names.split(',');
      selectedQualifier2Names.forEach(selectedqualifier2Name => {
        const selectedItem = this.qualifier2Filter.list.find(x => x.name.trim().toLowerCase() === selectedqualifier2Name.trim().toLowerCase());
        if (selectedItem) {
          this.qualifier2Filter.selected.push(selectedItem);
        }
      });
    }

    if (notification.roleNames === 'ALL') {
      this.roleFilter.selected = this.roleFilter.list;
    } else {
      const selectedRoleNames = notification.roleNames.split(',');
      selectedRoleNames.forEach(selectedRoleName => {
        const selectedItem = this.roleFilter.list.find(x => x.name.trim().toLowerCase() === selectedRoleName.trim().toLowerCase());
        if (selectedItem) {
          this.roleFilter.selected.push(selectedItem);
        }
      });
    }

    if (notification.countryNames === 'ALL') {
      this.countryFilter.selected = this.countryFilter.list;
    } else {
      const selectedCountryNames = notification.countryNames.split(',');
      selectedCountryNames.forEach(selectedCountryName => {
        const selectedItem = this.countryFilter.list.find(x => x.name.trim().toLowerCase() === selectedCountryName.trim().toLowerCase());
        if (selectedItem) {
          this.countryFilter.selected.push(selectedItem);
        }
      });
    }

    if (notification.standardTitleNames === 'ALL') {
      this.standardTitleFilter.selected = this.standardTitleFilter.list;
    } else {
      const selectedstandardTitleNames = notification.standardTitleNames.split(',');
      selectedstandardTitleNames.forEach(selectedstandardTitleName => {
        const selectedItem = this.standardTitleFilter.list.find(x => x.name.trim().toLowerCase() === selectedstandardTitleName.trim().toLowerCase());
        if (selectedItem) {
          this.standardTitleFilter.selected.push(selectedItem);
        }
      });
    }

  }

  actionConfirm() {
    if (this.actionType === 'Delete') {
      this.allNotifications.splice(this.actionidx, 1);
      this.actionNotification['action'] = 'Delete';
    } else {
      this.actionNotification['action'] = 'Update';
      this.actionNotification['notificationEndDate'] = new Date();
    }

    this.manageNotificationService.saveAdminNotification(this.actionNotification).subscribe(response => {
      console.log('after action confirm execution ', response);
      this.manageNotificationService.clearCache();
      this.getAllNotifications();
      this.modalRef.close();
      this.actionNotification = null;
      this.actionType = null;
      this.actionidx = -1;
    });

  }
  cancelSave() {
    this.toggleAdd = false;
  }

  isNotificationExpired(notification) {
    return notification['currentStatus'] === 'Expired';
  }

  openModal(content, type, notify, idx) {
    this.actionNotification = notify;
    this.actionidx = idx;
    this.actionType = type;
    this.modalMessage = type === 'Expire' ? this.expireDialogMessage : this.deleteDialogMessage;
    this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'mint-modal-backdrop', windowClass: 'mint-modal-window' });
  }

  initializeNewNotification() {
    const currentDate = new Date();
    this.currNotificaiton['notificationId'] = 0;
    this.currNotificaiton['notificationCreatedDate'] = currentDate;
    this.currNotificaiton['notificationModifiedDate'] = currentDate;
    this.currNotificaiton['notificationStartDate'] = currentDate;
    this.currNotificaiton['notificationEndDate'] = currentDate;
    this.currNotificaiton['notificationText'] = '';
    this.currNotificaiton['urlLink'] = '';
    this.currNotificaiton['notificationType'] = 0;
    this.currNotificaiton['currentStatus'] = 'Active';
    this.currNotificaiton['listOfAlias'] = '';
    this.currNotificaiton['createdBy'] = this.adminAlias;

    this.currNotificaiton['businessSegments'] = '';
    this.currNotificaiton['planId'] = '';
    this.currNotificaiton['planName'] = '';
    this.currNotificaiton['planNames'] = '';
    this.currNotificaiton['roleIds'] = '';
    this.currNotificaiton['roleNames'] = '';
    this.currNotificaiton['countryIds'] = '';
    this.currNotificaiton['countryNames'] = '';
    this.currNotificaiton['qualifier1Ids'] = '';
    this.currNotificaiton['qualifier1Names'] = '';
    this.currNotificaiton['qualifier2Ids'] = '';
    this.currNotificaiton['qualifier2Names'] = '';
    this.currNotificaiton['standardTitleIds'] = '';
    this.currNotificaiton['standardTitleNames'] = '';

  }

  validateInputs(currNotificaiton) {
    // if (currNotificaiton['notificationStartDate'])

    return false;
  }

  saveNotification(currNotificaiton) {
    if (this.validateInputs(currNotificaiton)) {
      return;
    }
    this.setDropdownValues();
    console.log('curr notification ', currNotificaiton);
    this.currNotificaiton['action'] = this.eventType === 'Add' ? 'Add' : 'Update';
    if (this.eventType !== 'Add') {
      this.currNotificaiton['notificationId'] = currNotificaiton.notificationId;
    }
    this.currNotificaiton['notificationCreatedDate'] = new Date(this.currNotificaiton['notificationCreatedDate']).toISOString();
    this.currNotificaiton['notificationModifiedDate'] = new Date(this.currNotificaiton['notificationModifiedDate']).toISOString();
    this.currNotificaiton['notificationStartDate'] = new Date(this.currNotificaiton['notificationStartDate']).toISOString();
    this.currNotificaiton['notificationEndDate'] = new Date(this.currNotificaiton['notificationEndDate']).toISOString();

    //Clear contents of Groups or Aliasas depending on checkbox selection
    this.clearNonSelectedValues();

    this.manageNotificationService.saveAdminNotification(currNotificaiton).subscribe(response => {
      this.manageNotificationService.clearCache();
      this.getAllNotifications();
      this.toggleNotificationPane('');
    });
  }

  clearNonSelectedValues() {
    if (!this.showAlias) {
      this.currNotificaiton['listOfAlias'] = '';
    } else {
      this.currNotificaiton['businessSegments'] = '';
      this.currNotificaiton['planNames'] = '';
      this.currNotificaiton['roleIds'] = '';
      this.currNotificaiton['roleNames'] = '';
      this.currNotificaiton['countryIds'] = '';
      this.currNotificaiton['countryNames'] = '';
      this.currNotificaiton['qualifier1Ids'] = '';
      this.currNotificaiton['qualifier1Names'] = '';
      this.currNotificaiton['qualifier2Ids'] = '';
      this.currNotificaiton['qualifier2Names'] = '';
      this.currNotificaiton['standardTitleIds'] = '';
      this.currNotificaiton['standardTitleNames'] = '';

    }
  }

  setDropdownValues() {
    if (this.businessSegmentFilter.selected.length === this.businessSegmentFilter.list.length) {
      this.currNotificaiton['businessSegments'] = 'ALL';
    } else {
      let selectedBusinessSegments = '';
      this.businessSegmentFilter.selected.forEach((val, idx) => {
        selectedBusinessSegments += (val.name + (idx === this.businessSegmentFilter.selected.length - 1 ? '' : ','));
      });
      this.currNotificaiton['businessSegments'] = selectedBusinessSegments;
    }

    if (this.planFilter.selected.length === this.planFilter.list.length) {
      this.currNotificaiton['planNames'] = 'ALL';
    } else {
      let selectedPlanNames = '';
      this.planFilter.selected.forEach((val, idx) => {
        selectedPlanNames += (val.name + (idx === this.planFilter.selected.length - 1 ? '' : ','));
      });
      this.currNotificaiton['planNames'] = selectedPlanNames;
    }

    if (this.roleFilter.selected.length === this.roleFilter.list.length) {
      this.currNotificaiton['roleIds'] = 'ALL';
      this.currNotificaiton['roleNames'] = 'ALL';
    } else {
      let selectedRoleIds = '', selectedRoleNames = '';
      this.roleFilter.selected.forEach((val, idx) => {
        selectedRoleIds += (val.idNumber.split('_')[1] + (idx === this.roleFilter.selected.length - 1 ? '' : ','));
        selectedRoleNames += (val.name + (idx === this.roleFilter.selected.length - 1 ? '' : ','));
      });
      this.currNotificaiton['roleIds'] = selectedRoleIds;
      this.currNotificaiton['roleNames'] = selectedRoleNames;
    }

    if (this.countryFilter.selected.length === this.countryFilter.list.length) {
      this.currNotificaiton['countryIds'] = 'ALL';
      this.currNotificaiton['countryNames'] = 'ALL';
    } else {
      let selectedCountryIds = '', selectedCountryNames = '';
      this.countryFilter.selected.forEach((val, idx) => {
        selectedCountryIds += (val.idNumber.split('_')[1] + (idx === this.countryFilter.selected.length - 1 ? '' : ','));
        selectedCountryNames += (val.name + (idx === this.countryFilter.selected.length - 1 ? '' : ','));
      });
      this.currNotificaiton['countryIds'] = selectedCountryIds;
      this.currNotificaiton['countryNames'] = selectedCountryNames;
    }

    if (this.qualifier1Filter.selected.length === this.qualifier1Filter.list.length) {
      this.currNotificaiton['qualifier1Ids'] = 'ALL';
      this.currNotificaiton['qualifier1Names'] = 'ALL';
    } else {
      let selectedQualifier1Ids = '', selectedQualifier1Names = '';
      this.qualifier1Filter.selected.forEach((val, idx) => {
        selectedQualifier1Ids += (val.idNumber.split('_')[1] + (idx === this.qualifier1Filter.selected.length - 1 ? '' : ','));
        selectedQualifier1Names += (val.name + (idx === this.qualifier1Filter.selected.length - 1 ? '' : ','));
      });
      this.currNotificaiton['qualifier1Ids'] = selectedQualifier1Ids;
      this.currNotificaiton['qualifier1Names'] = selectedQualifier1Names;
    }

    if (this.qualifier2Filter.selected.length === this.qualifier2Filter.list.length) {
      this.currNotificaiton['qualifier2Ids'] = 'ALL';
      this.currNotificaiton['qualifier2Names'] = 'ALL';
    } else {
      let selectedQualifier2Ids = '', selectedQualifier2Names = '';
      this.qualifier2Filter.selected.forEach((val, idx) => {
        selectedQualifier2Ids += (val.idNumber.split('_')[1] + (idx === this.qualifier2Filter.selected.length - 1 ? '' : ','));
        selectedQualifier2Names += (val.name + (idx === this.qualifier2Filter.selected.length - 1 ? '' : ','));
      });
      this.currNotificaiton['qualifier2Ids'] = selectedQualifier2Ids;
      this.currNotificaiton['qualifier2Names'] = selectedQualifier2Names;
    }

    if (this.standardTitleFilter.selected.length === this.standardTitleFilter.list.length) {
      this.currNotificaiton['standardTitleIds'] = 'ALL';
      this.currNotificaiton['standardTitleNames'] = 'ALL';
    } else {
      let selectedStandardTitleIds = '', selectedStandardTitleNames = '';
      this.standardTitleFilter.selected.forEach((val, idx) => {
        selectedStandardTitleIds += (val.idNumber.split('_')[1] + (idx === this.standardTitleFilter.selected.length - 1 ? '' : ','));
        selectedStandardTitleNames += (val.name + (idx === this.standardTitleFilter.selected.length - 1 ? '' : ','));
      });
      this.currNotificaiton['standardTitleIds'] = selectedStandardTitleIds;
      this.currNotificaiton['standardTitleNames'] = selectedStandardTitleNames;
    }
  }

  onItemSelect(filter) {
  }

  onSelectAll(filter, filterName) {
  }

  getFiltersData() {
    this.manageNotificationService.GetAdminNotificationsFilterData().subscribe(
      (response: any) => {
        this.mapFilterData(response);
      }
    );
  }

  aliasCheckboxChange(evt) {
    this.showAlias = !this.showAlias;
    this.detailsFilter = !this.showAlias;
    this.setDetailFilters();
  }

  detailsFilterChange(evt) {
    this.detailsFilter = !this.detailsFilter;
    this.showAlias = !this.detailsFilter;
    this.setDetailFilters();
  }

  setDetailFilters() {
    if (!this.showAlias) {
      this.businessSegmentFilter.selected = Object.assign([], this.businessSegmentFilter.list);
      this.planFilter.selected = Object.assign([], this.planFilter.list);
      this.roleFilter.selected = Object.assign([], this.roleFilter.list);
      this.standardTitleFilter.selected = Object.assign([], this.standardTitleFilter.list);
      this.qualifier1Filter.selected = Object.assign([], this.qualifier1Filter.list);
      this.qualifier2Filter.selected = Object.assign([], this.qualifier2Filter.list);
      this.countryFilter.selected = Object.assign([], this.countryFilter.list);
    } else {
      this.businessSegmentFilter.selected = [];
      this.planFilter.selected = [];
      this.roleFilter.selected = [];
      this.standardTitleFilter.selected = [];
      this.qualifier1Filter.selected = [];
      this.qualifier2Filter.selected = [];
      this.countryFilter.selected = [];
    }
  }

  mapFilterData(response: any) {
    this.businessSegmentFilter.list = response.businessSegments.map((item, index) => {
      return {
        idNumber: index,
        name: item,
      };
    });

    this.planFilter.list = response.plans.map((item, index) => {
      return {
        idNumber: index,
        name: item,
      };
    });

    this.roleFilter.list = response.roles.map((item, index) => {
      return {
        idNumber: index + '_' + item.id,
        name: item.name,
        id: item.id
      };
    });

    this.standardTitleFilter.list = response.standardTitles.map((item, index) => {
      return {
        idNumber: index + '_' + item.id,
        name: item.name,
        id: item.id
      };
    });

    this.qualifier1Filter.list = response.qualifier1s.map((item, index) => {
      return {
        idNumber: index + '_' + item.id,
        name: item.name,
        id: item.id
      };
    });

    this.qualifier2Filter.list = response.qualifier2s.map((item, index) => {
      return {
        idNumber: index + '_' + item.id,
        name: item.name,
        id: item.id
      };
    });

    this.countryFilter.list = response.countries.map((item, index) => {
      return {
        idNumber: index + '_' + item.id,
        name: item.name,
        id: item.id
      };
    });

  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes())
      ].join(':')
    );
  }
}


