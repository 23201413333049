import { DataSourceData } from './data-source';
import { ProductGroup } from './product-group';
import { Metric } from '../models/metric.model';

export class MetricGridData {
  public Metric: Metric;
  public PcgRowSpan: number;
  public PccRowSpan: number;
  public YTDQuota: string;
  public YTDActuals: string;
  public QuotaToGo: string;
  public MetriclevelYTDEarnings: string;
  public ProductGroup: ProductGroup;
  public DataSource: DataSourceData;
}
