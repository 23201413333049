import { Injectable, EventEmitter, Output, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import 'node_modules/@ms-ofb/officebrowserfeedback/scripts/officebrowserfeedback.min.js';
import { UserProfileService } from '../core/context/user-profile.service';
import { environment } from '../../environments/environment';
import { JsonFormatter } from 'tslint/lib/formatters';
import { TelemetryContext } from '@mint-libs/context';
import { NavigationService } from '../core/navigation/navigation.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';

@Injectable()
export class OcvFeedbackService {
  appId;
  env;
  userEmail;
  officeBrowserFeedbackObject;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    public router: Router,
    public userProfileService: UserProfileService,
    public telemetryContext: TelemetryContext,
    public sharedContextState: Store<SharedContext>,
    public navigationService: NavigationService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.appId = environment.ocvAppId;
    this.env = 0; // this.env = environment.production ? 0 : 1;
  }

  public initOcv() {
    (<any>window).OfficeBrowserFeedback = (<any>window).OfficeBrowserFeedback || {};
    this.officeBrowserFeedbackObject = (<any>window).OfficeBrowserFeedback;

    this.officeBrowserFeedbackObject.initOptions = {
      appId: this.appId,
      stylesUrl: 'src/assets/thirdparty/ocvfeedback/styles/officebrowserfeedback_vertical.css',
      intlUrl: 'src/assets/thirdparty/ocvfeedback/intl/',
      environment: this.env,
      primaryColour: '#26873a',
      secondaryColour: '#26873a',
      sessionId: '',
      onError: function onError(err) {},
      onDismiss: function onDismiss(flag) {
        // Invokes after the ocv feedback popup is closed.
        if (flag) {
          // Flag ===  true implies that the feedback is submitted successfully;
        }
      },
      telemetryGroup: {
        featureArea: 'Mint Web'
      },
      screenshot: true,
      bugForm: false,
      isShowThanks: true,
      webGroup: {
        sourcePageURI: window.location.href,
        sourcePageName: this.navigationService.current()
      },
      categories: {
        show: false
      }
    };

    // Assigning useremail.
    this.officeBrowserFeedbackObject.initOptions.userEmail = this.getUserEmail();

    (<any>window).OfficeBrowserFeedback = this.officeBrowserFeedbackObject;
  }

  public showFeedbackPopup(): void {
    this.initOcv();

    const applicationGroupData = {
      appData: JSON.stringify({ sourcePageURI: window.location.href, sourcePageName: this.navigationService.current() })
    };
    const webGroupData = this.officeBrowserFeedbackObject.initOptions.webGroup;
    webGroupData.sourcePageURI = window.location.href;
    webGroupData.sourcePageName = this.navigationService.current();

    this.officeBrowserFeedbackObject.initOptions.webGroup = webGroupData;

    let launchOptions = {
      applicationGroup: applicationGroupData,
      webGroup: webGroupData,
      telemetryGroup: null,
      categories: null
    };

    const promise = this.officeBrowserFeedbackObject.multiFeedback(launchOptions);
    promise
      .then(function onFulfilled() {
        // This line emulates the click behavior on 'I Like Something'.
        document.getElementById('obf-OverallSmileText').click();
      })
      .catch(function onRejected(err) {});
    return promise;
  }

  protected getUserEmail() {
    let emailId = '';

    if (this.telemetryContext.userAlias === this.sharedContext.alias && this.sharedContext.emailId) {
      emailId = this.sharedContext.emailId;
    } else {
      if (environment.useTelemetryCxtForUserAlias) {
        emailId = this.telemetryContext.userAlias + '@' + environment.graphDomain;
      }
    }

    return emailId;
  }
}
