<div class="award__item" [ngClass]="{ active: item.locked == false }">
  <div class="award__status__icon">
    <svg *ngIf="!item.locked" width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7V16H0V7H9V4C9 3.573 8.92202 3.177 8.76602 2.812C8.61502 2.448 8.40403 2.133 8.13303 1.867C7.86703 1.596 7.55202 1.385 7.18802 1.234C6.82302 1.078 6.427 1 6 1C5.573 1 5.17702 1.078 4.81302 1.234C4.44802 1.385 4.13002 1.596 3.86002 1.867C3.59402 2.133 3.38302 2.448 3.22702 2.812C3.07602 3.177 3 3.573 3 4H2C2 3.432 2.10202 2.906 2.30502 2.422C2.50802 1.932 2.78902 1.508 3.14902 1.148C3.50802 0.789003 3.93 0.508 4.414 0.305C4.904 0.102 5.432 0 6 0C6.568 0 7.094 0.102 7.578 0.305C8.068 0.508 8.49202 0.789003 8.85202 1.148C9.21102 1.508 9.49201 1.932 9.69501 2.422C9.89901 2.906 10 3.432 10 4V7H12ZM11 8H1V15H11V8Z"
        fill="#333333"
      />
    </svg>
    <svg *ngIf="item.locked" width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7V16H0V7H2V4.0781C2 3.5156 2.10199 2.987 2.30499 2.4922C2.50799 1.9974 2.789 1.5651 3.149 1.1953C3.508 0.825497 3.93 0.533897 4.414 0.320297C4.904 0.106797 5.432 0 6 0C6.568 0 7.094 0.106797 7.578 0.320297C8.068 0.533897 8.49199 0.825497 8.85199 1.1953C9.21099 1.5651 9.49199 1.9974 9.69499 2.4922C9.89899 2.987 10 3.5156 10 4.0781V7H12ZM3 7H9V4.0781C9 3.6562 8.925 3.2604 8.774 2.8906C8.623 2.5156 8.41199 2.1875 8.14099 1.9062C7.87499 1.625 7.557 1.4036 7.188 1.2422C6.823 1.0807 6.427 1 6 1C5.573 1 5.17499 1.0807 4.80499 1.2422C4.43999 1.4036 4.12299 1.625 3.85199 1.9062C3.58599 2.1875 3.37799 2.5156 3.22699 2.8906C3.07599 3.2604 3 3.6562 3 4.0781V7ZM11 8H1V15H11V8Z"
        fill="#7E7E7E"
      />
    </svg>
  </div>
  <div class="award__count">
    <b>{{ item.itemNumber }}</b> out of <b>3</b>
  </div>
  <div class="award__pay__percent">{{ item.pay_percentage }}%</div>
  <div class="award__pay__txt">of your annual bonus eligible salary</div>
  <div class="award__pay__amount">{{ item.currency }}{{ item.pay_amount }}</div>
</div>
