<div class="report-header">
  <div class="width80 header-text">Report Name</div>
  <div class="width20 header-text">Download</div>
</div>
<div class="break-containers"></div>

<div>
  <div *ngIf="showPerformanceReport && isRbiDirects">
    <mint-export-excel
      *ngIf="showPerformanceReport && isRbiDirects"
      class="margin-right10"
      [id]="'PerformanceReport'"
      [title]="'Export Team Performance Report to Excel'"
      [downloadInactive]="showPerformanceReportLoader"
      (notifyDownloadClick)="exportPerformanceReport($event)"
      [labelText]="performanceReportLabelText"
    ></mint-export-excel>
    <div class="break-containers"></div>
  </div>

  <div *ngIf="payoutShowTeamPayouteport">
    <div class="item-container">
      <div class="width80">
        <div class="text-lable">Team Payout Report</div>
        <div class="text-note">
          This report gives the detailed overview of payout information for <span>{{ this.semesterText }}</span> for all your reportees
        </div>
      </div>
      <div class="width20">
        <mint-export-excel
          *ngIf="payoutShowTeamPayouteport"
          class="margin-right10"
          [id]="'TeamPayouteport'"
          [title]="'Export Team Payout Report to Excel'"
          [downloadInactive]="showPayoutReportLoader"
          (notifyDownloadClick)="exportPayoutReport($event)"
          [labelText]=""
        ></mint-export-excel>
      </div>
    </div>
    <div class="break-containers"></div>
  </div>

  <div *ngIf="showTeamQuotaeport">
    <div class="item-container">
      <div class="width80">
        <div class="text-lable">{{ this.quotaReportLabelText }}</div>
        <div class="text-note">
          This report gives the detailed overview of quota information for all your reportees
        </div>
      </div>
      <div class="width20">
        <mint-export-excel
          class="margin-right10"
          *ngIf="showTeamQuotaeport"
          [id]="'TeamQuotaeport'"
          [title]="'Export Team Target Report to Excel'"
          [reportFileName]="'ManagerQuotaReviewReport'"
          [downloadInactive]="showQuotaReportLoader"
          (notifyDownloadClick)="exportQuotaReport($event)"
          [labelText]=""
        ></mint-export-excel>
      </div>
    </div>
    <div class="break-containers"></div>
  </div>

  <div *ngIf="payoutShowTerritoryReport">
    <div class="item-container">
      <div class="width80">
        <div class="text-lable">Team Territory Report</div>
        <div class="text-note">
          This report gives the detailed overview of territory information for <span>{{ this.semesterText }}</span> for all your reportees
        </div>
      </div>
      <div class="width20">
        <mint-export-excel
          *ngIf="payoutShowTerritoryReport"
          class="margin-right10"
          [id]="'payoutShowTerritoryReport'"
          [title]="'Export Team Territory Report for Payouts to Excel'"
          [downloadInactive]="showPayoutTerritoryLoader"
          (notifyDownloadClick)="exportTerritoryPayoutReport($event)"
          [labelText]=""
        ></mint-export-excel>
      </div>
    </div>
    <div class="break-containers"></div>
  </div>
</div>
