import { Expose, Type } from 'class-transformer';
import { Duration } from './duration.model';

export class Partition {
  @Expose()
  stepId: number;
  @Expose()
  fiscalQuarterId: number;
  @Expose()
  fiscalSemesterId: number;
  @Expose()
  key: string;
  @Type(() => Duration)
  @Expose()
  duration: Duration;
  @Expose()
  codePeriod: string;
}
