import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mint-awards-unlocked-box',
  templateUrl: './awards-unlocked-box.component.html',
  styleUrls: ['./awards-unlocked-box.component.scss']
})
export class AwardsUnlockedBoxComponent implements OnInit {
  @Input() item: any;
  constructor() {}

  ngOnInit() {}
}
