import { Formatter } from '@mint-libs/common';
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'mint-grid-bonus-amount-renderer',
  styles: [
    `
    .pg-attn {
      line-height: 16px;
    }
    .att-val {
      font-size: 11px;
      color: #707070;
    }`
  ],
  template: `
    <span> 
      <span style="font-size: 14px; text-align: right; color: #4F4F4F;"  >
        <b>{{ params.value }} </b>
      </span>
      <br />   
    </span>
  `
})
export class BonusAmountRendererComponent implements ICellRendererAngularComp {
  params: any;
  thresholdInfo: any;
  public shouldShowThresholdInfo = false;
  public data: number;
  constructor(private formatter: Formatter) {}

  agInit(params): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
