import { Component, OnInit, Input, Inject } from '@angular/core';
import { Formatter } from '@mint-libs/common';
import { FiltersService } from '../../../filters/filters.service';

const UBI_MetricAttainmentTAToolTipText =
  // tslint:disable-next-line: max-line-length
  'Employees brand new to UBI incentive plans are eligible to receive Transition Allowance for the first 60 days in role. Attainment, target, and actuals will be calculated separately for time on Transition Allowance versus regular active time in role.';
const UBI_MetricAttainmentPaidLOAToolTipText =
  // tslint:disable-next-line: max-line-length
  'Employees on approved paid leave of absence will receive 100% of prorated UBI target incentive for the number of days on paid leave, indicated here. The number of days here will be reflected for the H1 or full-year time period, depending on the duration selected in the upper right-hand corner of the dashboard.';
const UBI_MetricAttainmentUnpaidLOAToolTipText =
  // tslint:disable-next-line: max-line-length
  'Employees on unpaid leave of absence will not receive any UBI target incentive for the number of days on unpaid leave, indicated here. The number of days here will be reflected for the H1 or full-year time period, depending on the duration selected in the upper right-hand corner of the dashboard.';

@Component({
  selector: 'mint-ubi-metric-attainment',
  templateUrl: './ubi-metric-attainment.component.html',
  styleUrls: ['./ubi-metric-attainment.component.scss']
})
export class UBIMetricAttainmentComponent implements OnInit {
  @Input() ubiAttainmentMetric: any;

  isPeriodStatusActive: Boolean;
  isPeriodStatusTA: Boolean;
  isPeriodStatusPaid: Boolean;
  isPeriodStatusUnpaid: Boolean;
  isToolTipEnable: Boolean;
  ubiMetricAttainmentTitle = '';
  ubiMetricAttainmentToolTipText = '';
  ubiMetricTooltipId = 'ubiMetricTooltipId';
  totalDays = 0;
  displayConsumptionMetric = false;

  constructor(private formatter: Formatter, private filterService: FiltersService) {
    this.displayConsumptionMetric = this.filterService.selectedManagerViewFiscalYear > 2020 ? true : false;
  }

  ngOnInit() {
    // Initiatize and transform data
    this.initTransform();

    // create UbiMetricAttainmentTitle
    this.createUbiMetricAttainmentTitle();
  }

  initTransform() {
    // Initiatize the PeriodStatus variables and transform data
    this.isPeriodStatusActive = this.ubiAttainmentMetric.PeriodStatus.toLowerCase() === 'active';
    this.isPeriodStatusTA = this.ubiAttainmentMetric.PeriodStatus.toLowerCase() === 'ta';
    this.isPeriodStatusPaid = this.ubiAttainmentMetric.PeriodStatus.toLowerCase() === 'paid';
    this.isPeriodStatusUnpaid = this.ubiAttainmentMetric.PeriodStatus.toLowerCase() === 'unpaid';

    if (this.isPeriodStatusTA) {
      this.ubiAttainmentMetric.PeriodStatus = 'Transition Allowance';
      this.ubiMetricAttainmentToolTipText = UBI_MetricAttainmentTAToolTipText;
    } else if (this.isPeriodStatusPaid) {
      this.ubiAttainmentMetric.PeriodStatus = 'Paid Leave of Absence';
      this.ubiMetricAttainmentToolTipText = UBI_MetricAttainmentPaidLOAToolTipText;
    } else if (this.isPeriodStatusUnpaid) {
      this.ubiAttainmentMetric.PeriodStatus = 'Unpaid Leave of Absence';
      this.ubiMetricAttainmentToolTipText = UBI_MetricAttainmentUnpaidLOAToolTipText;
    }
  }

  // create title with Period Status and number of days
  createUbiMetricAttainmentTitle() {
    // sum the total number of days for specific Period status
    // this.ubiAttainmentMetric.Periods.forEach(element => {
    //   this.totalDays = this.totalDays + element.TotalDays;
    // });
    this.ubiMetricAttainmentTitle = this.ubiAttainmentMetric.PeriodStatus + ' - ' + this.ubiAttainmentMetric.TotalDays + ' Days';
  }
}
