import { Action } from '@ngrx/store';
import { UserPreference } from '../../user-profile/models/user-preference.model';

export enum FilterActionTypes {
    SetFilters = '[Filters] Set Filters',
    UpdateFilters = '[Filters] Update Filters',
    ResetFilters = '[Filters] Reset Filters',
}

export class SetFilters implements Action {
    readonly type = FilterActionTypes.SetFilters;
    constructor(public payload: { userProfile: any; selectedFilters?: UserPreference }) {}
}

export class UpdateFilters implements Action {
    readonly type = FilterActionTypes.UpdateFilters;
    constructor(public payload: { selectedFilters?: UserPreference; route?: string }) {}
}

export class ResetFilters implements Action {
    readonly type = FilterActionTypes.ResetFilters;
    constructor() {}
}
  
export type FilterActions = SetFilters | UpdateFilters | ResetFilters;
