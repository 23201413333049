import { Component, Inject, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, from } from 'rxjs';
import { Formatter, UpdateSharedContext } from '@mint-libs/common';
import { Router } from '@angular/router';
import { UserProfileService } from '../core/context/user-profile.service';
import { MessagesService } from '@mint-libs/context';
import { Code } from '../app.constants';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { NavigationService } from '../core/navigation/navigation.service';
import { ImpersonationService } from '../core/impersonation/impersonation.service';

@Component({
  selector: 'app-optout-notification',
  templateUrl: './optout-notification.component.html',
  providers: [DatePipe, Code],
  styleUrls: ['./opt-out-notification.component.scss']
})
export class OptoutNotificationComponent implements OnInit {
  @Input() isBanner;
  @Output() closeEvent = new EventEmitter<any>();
  isOptOutEligibleFlag = false;
  isMessageLoaded = false;
  isOptoutBannerClosed = false;
  topBannerMessage1: any;
  topBannerMessage2: any;
  userProfileChangeSubscription: any;
  isRBI: any;
  isUBI: any;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private code: Code,
    public sharedContextState: Store<SharedContext>,
    private messageService: MessagesService,
    private navigationService: NavigationService,
    private impersonationService: ImpersonationService,
    private formatter: Formatter,
    private router: Router,
    public userProfileService: UserProfileService,
    private datePipe: DatePipe
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.messageService = messageService;

    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  ngOnInit() {
    this.isOptOutEligible();

    // Subscribe to event so that if any change happens in user profile due to compliance doc acknowledge or any thing
    // then reverify the visiblity of menus
    this.isOptOutEligibleFlag = this.isOptOutEligibleFlagMethod();
    this.userProfileChangeSubscription = this.userProfileService.onUserProfileChange.subscribe(() => {
      this.isOptOutEligible();
    });
    this.isRBI = this.isIncentiveTypeActive('RBI');
    this.isUBI = this.isIncentiveTypeActive('UBI');
  }

  isIncentiveTypeActive(fragment) {
    return this.sharedContext.incentiveType === fragment;
  }

  isOptOutEligibleFlagMethod() {
    if (this.userProfileService.isOptoutEligibleAndInWindow()) {
      return true;
    }
    return false;
  }

  optoutDemoMode() {
    if (!this.sharedContext.isDemoMode3) {
      return true;
    }
    if (this.sharedContext.isDemoMode3 && this.sharedContext.isImpersonating === false) {
      return false;
    }
    return true;
  }

  isOptOutEligible() {
    if (this.userProfileService.isOptoutEligibleAndInWindow() && this.sharedContext.optoutDetails && this.sharedContext.optoutDetails.isOptOut === false) {
      if (!this.isMessageLoaded) {
        this.isMessageLoaded = true;
        this.messageService.get(Code.AppControllerOptOutMessages).subscribe(response => {
          const optOutMessage = JSON.parse(<any>response);
          this.topBannerMessage1 =
            optOutMessage['TopBannerMessage1'] +
            this.formatter.transformToDateFormat(this.sharedContext.optoutDetails.optOutStartDate, 'mediumDate', 'en-US') +
            ' to ' +
            this.datePipe.transform(this.sharedContext.optoutDetails.optOutEndDate, 'MMM d, y, h:mm:ss a') +
            ' UTC' +
            '. Click ';
          this.topBannerMessage2 = optOutMessage['TopBannerMessage2'];
        });
      }
    } else {
      this.sharedContextState.dispatch(
        new UpdateSharedContext({
          ...this.sharedContext,
          isOptOutBannerClosed: true
        })
      );
    }
  }

  navigateToOptout() {
    if (!this.navigationService.compSubmenuList.some(ct => ct.name === 'Opt Out')) {
      this.navigationService.compSubmenuList.push({ name: 'Opt Out', active: false, index: 3 });
    }
    this.navigationService.navigateToOptout();
    this.router.navigate(['complianceDashboard'], {
      queryParams: { id: 3 }
    });
  }

  closeOptOutBanner() {
    this.closeEvent.emit('closeOptOutBannerEvent');
  }
}
