<div class="card-header">
    <h5>Awards Summary</h5>
</div>
<div class="card-body">
    <div class="currency-result">
        <div class="result-highlight">
            <h2>{{ totalUnlockedItems | number: '1.0-2' }}</h2>
            <div class="sub-text">
                <strong>{{ currencyCode }}</strong>
                <span>Unlocked Award</span>
            </div>
        </div>
        <div class="awardSummary">
            <div class="sub-result">
                <ul>
                    <li class="text-center" *ngIf="isBucketUnlockCountEnabled">
                        <label>Products Unlocked</label>
                        <h4 class="value"> {{ unlockedProductsCount }} /
                            {{ totalProductsCount }}</h4>
                    </li>
                    <li *ngIf="isPotentialAwardEnabled">
                        <label>Potential Award</label>
                        <h4 class="value"> {{ potentialAwardTotal | number: '1.0-2' }}
                            <small>{{ currencyCode }}</small></h4>
                    </li>
                    <li *ngIf="isPercentPotentialAwardEnabled">
                        <label>Percentage of Potential Award Unlocked </label>
                        <h4 class="value">
                            {{ percentagePotentialUnlocked | number: '1.0-2' }}%</h4>
                    </li>
                    <li *ngIf="isCurrentITAEnabled">
                        <label>Current ITA </label>
                        <h4 class="value"> {{ derivedValue | number: '1.0-2' }}
                            <small>{{ currencyCode }}</small></h4>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <p class="txt-note" *ngIf="notes">{{ notes }}</p>
</div>