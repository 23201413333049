<div class="filter-container">
  <div class="search-container">
    <input #searchBox class="search-box" (input)="onInput($event)" aria-label="search" placeholder="Search" />
    <mat-icon class="search-icon">search</mat-icon>
  </div>
  <div>
    <fieldset class="content__right">
      <div>
        <div class="radio__btn__grp" *ngFor="let item of currentData; let i = index">
          <label class="radio__btn__lbl">
            <div
              #itemElement
              class="radio__btn__new "
              role="checkbox"
              tabindex="0"
              [ngClass]="{ checked: isItemSelected(item) }"
              [attr.aria-checked]="isItemSelected(item)"
              [attr.aria-label]="item"
              (click)="onSelectionChange(item)"
              (keyup.Space)="onSelectionChange(item)"
            >
              <mat-icon class="checkbox-tick">check</mat-icon>
            </div>
            {{ item }}
          </label>
        </div>
      </div>
    </fieldset>
    <div class="selection-text">{{ value.length }} selected</div>
  </div>
</div>
