import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EndpointService } from '@mint-libs/network';
import { map } from 'rxjs/operators';
import { CustomHttpParams } from './custom-http-params';
import { Response } from 'selenium-webdriver/http';
import { FiltersService } from '../../filters/filters.service';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { InitializationService } from '../initialization/initialization.service';
import { UserSessionService } from '../session/user-session-service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class DetailDataService {
  sharedContext: SharedContext = new SharedContext();
  constructor(
    private http: HttpClient,
    private endpointService: EndpointService,
    private filterService: FiltersService,
    public sharedContextState: Store<SharedContext>,
    private initializationService: InitializationService,
    private userSessionService: UserSessionService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }
  IsMaintainanceMode = false;

  public MaintainanceMode = {
    message: '',
    title: '',
    setMessage: function(startTime, endTime, message) {
      if (startTime !== '' && endTime !== '') {
        startTime = this.filterService('ToLocalTime')(startTime);
        endTime = this.filterService('ToLocalTime')(endTime);
        this.message = message
          .replace('{0}', startTime)
          .split('{1}')
          .join(endTime);
      }
    },
    setTitle: function(title) {
      this.title = title;
    },
    On: function(startTime, endTime, message, title) {
      this.IsMaintainanceMode = true;
      this.setMessage(startTime, endTime, message);
      this.setTitle(title);
    },
    Off: function() {
      this.IsMaintainanceMode = false;
    }
  };

  public get<T>(uri: string, params: HttpParams): Observable<Response> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http
      .get<any>(this.getApiUri(uri), {
        headers: headers,
        params: params
      })
      .pipe(
        map(response => {
          this.refreshCache(response);
          return response.Result;
        })
      );
  }

  public delete<T>(uri: string, params: HttpParams): Observable<Response> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http
      .delete<any>(this.getApiUri(uri), {
        headers: headers,
        params: params
      })
      .pipe(
        map(response => {
          this.refreshCache(response);
          return response.Result;
        })
      );
  }

  public getCamelCase<T>(uri: string, params: HttpParams): Observable<Response> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http
      .get<any>(this.getApiUri(uri), {
        headers: headers,
        params: params
      })
      .pipe(
        map(response => {
          this.refreshCache(response);
          return response.result;
        })
      );
  }

  public post<T>(uri: string, body: JSON): Observable<T> {
    const urlBody = body;
    return this.http.post<T>(this.getApiUri(uri), urlBody);
  }

  public getWithGlobalFilters<T>(uri: string, params: HttpParams, headers: HttpHeaders = null): Observable<any> {
    if (params == null) {
      params = CustomHttpParams.getInstance();
    }
    params = this.setGlobalFilterParameters(params);

    if (headers == null) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');

    return this.http
      .get<any>(this.getApiUri(uri), {
        headers: headers,
        params: params
      })
      .pipe(
        map(response => {
          this.refreshCache(response);
          return response.Result;
        })
      );
  }

  public refreshCache(response) {
    if (response && response.Version != null && response.Version.length > 0) {
      if (this.sharedContext.fiscalYearVersion[response.FiscalYear] == null) {
        let sharedContext = { ...this.sharedContext, fiscalYearVersion: { ...this.sharedContext.fiscalYearVersion } };
        sharedContext.fiscalYearVersion[response.FiscalYear] = response.Version;
        this.sharedContextState.dispatch(new UpdateSharedContext(sharedContext));
      } else if (this.sharedContext.fiscalYearVersion[response.FiscalYear] !== response.Version) {
        let sharedContext = { ...this.sharedContext, fiscalYearVersion: { ...this.sharedContext.fiscalYearVersion } };
        sharedContext.fiscalYearVersion[response.FiscalYear] = response.Version;
        if (this.sharedContext.totalCacheCount < this.sharedContext.totalCacheLimit) {
          this.userSessionService.refresh(true);
          sharedContext.totalCacheCount++;
        }
        this.sharedContextState.dispatch(new UpdateSharedContext(sharedContext));
      }
    }
  }

  public postWithGlobalFilters<T>(uri: string, body?: any, headers?: HttpHeaders, responseType?): Observable<T> {
    let jsonBody = JSON.parse(JSON.stringify(body != null ? body : {}));
    jsonBody = this.setGlobalFilterParametersForJson(jsonBody);

    if (headers == null) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');
    if (responseType != null && responseType.toLowerCase() === 'arraybuffer') {
      headers = headers.set('Accept', 'application/octet-stream');
    }

    return this.http.post<T>(this.getApiUri(uri), jsonBody, {
      headers: headers,
      responseType: responseType ? responseType : 'json'
    });
  }

  // Todo: To be removed once new demo mode implementation is ready. Base method to be used instead which also include Month.
  public getWithGlobalFiltersAndMonth<T>(uri: string, params: HttpParams, headers: HttpHeaders = null): Observable<any> {
    if (this.filterService.selectedFiscalMonthNumber) {
      if (params == null) {
        params = CustomHttpParams.getInstance();
      }
      params = params.append('FiscalMonthNumber', this.filterService.selectedFiscalMonthNumber ? this.filterService.selectedFiscalMonthNumber.toString() : null);
    }

    return this.getWithGlobalFilters(uri, params, headers);
  }

  getApiUri(url: string): string {
    // defaulted to csa
    let apiUrl = environment.mintapiBaseUrl + url.replace('/v1', '');

    if (environment.isDemoMode2) {
      apiUrl = environment.demoapiBaseUrl + url.replace('/v1', '');
    } else if (environment.enableISA !== false) {
      const endpointFilterRes = Object.values(this.endpointService).filter(function(t) {
        return t.uri === url;
      });
      if (endpointFilterRes != null && endpointFilterRes.length > 0) {
        const endPoint = endpointFilterRes[0];
        if (endPoint.forceRedirect !== false && (endPoint.forceRedirect || (this.filterService != null && this.filterService.selectedFiscalYear >= 2019))) {
          apiUrl = environment.mintapiDetailBaseUrl + url;
        }
      }
    }

    return apiUrl;
  }

  setGlobalFilterParameters(params: HttpParams): HttpParams {
    params = params.append('FiscalYear', this.filterService.selectedFiscalYear ? this.filterService.selectedFiscalYear.toString() : null);
    params = params.append('ManagerFiscalYear', this.filterService.selectedManagerViewFiscalYear ? this.filterService.selectedManagerViewFiscalYear.toString() : null);
    params = params.append('StepId', this.filterService.selectedStepId ? this.filterService.selectedStepId.toString() : null);
    params = params.append('Period', this.filterService.selectedCodePeriod ? this.filterService.selectedCodePeriod : null);
    params = params.append('ManagerViewPeriod', this.filterService.selectedManagerViewCodePeriod ? this.filterService.selectedManagerViewCodePeriod : null);
    params = params.append('PersonnelNumber', this.filterService.personnelNumber ? this.filterService.personnelNumber.toString() : null);
    params = params.append('FiscalQuarterId', this.filterService.selectedFiscalQuarterId ? this.filterService.selectedFiscalQuarterId.toString() : null);
    params = params.append('PlanId', this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.PlanId : null);
    params = params.append('PlanName', this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.PlanName : null);
    if (!params.has('ParticipationID')) {
      params = params.append('ParticipationID', this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.ParticipationID : null);
    }
    params = params.append('PayeeId', this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.PayeeId : null);
    params = params.append('ParticipationStartDate', this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.StartDate : null);
    params = params.append('ParticipationEndDate', this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.EndDate : null);
    params = params.append('ManagerViewStepId', this.filterService.selectedManagerViewStepId ? this.filterService.selectedManagerViewStepId.toString() : null);

    return params;
  }

  setGlobalFilterParametersForJson(jsonObject: any): any {
    jsonObject['FiscalYear'] = this.filterService.selectedFiscalYear ? this.filterService.selectedFiscalYear : null;
    jsonObject['ManagerFiscalYear'] = this.filterService.selectedManagerViewFiscalYear ? this.filterService.selectedManagerViewFiscalYear : null;
    jsonObject['StepId'] = this.filterService.selectedStepId ? this.filterService.selectedStepId : null;
    jsonObject['Period'] = this.filterService.selectedCodePeriod ? this.filterService.selectedCodePeriod : null;
    jsonObject['ManagerViewPeriod'] = this.filterService.selectedManagerViewCodePeriod ? this.filterService.selectedManagerViewCodePeriod : null;
    jsonObject['PersonnelNumber'] = this.filterService.personnelNumber ? this.filterService.personnelNumber : null;
    jsonObject['FiscalQuarterId'] = this.filterService.selectedFiscalQuarterId ? this.filterService.selectedFiscalQuarterId : null;
    jsonObject['PlanId'] = this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.PlanId : null;
    jsonObject['PlanName'] = this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.PlanName : null;
    jsonObject['ParticipationID'] = this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.ParticipationID : null;
    jsonObject['PayeeId'] = this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.PayeeId : null;
    jsonObject['ParticipationStartDate'] = this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.StartDate : null;
    jsonObject['ParticipationEndDate'] = this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.EndDate : null;
    jsonObject['ManagerViewStepId'] = this.filterService.selectedManagerViewStepId ? this.filterService.selectedManagerViewStepId : null;

    return jsonObject;
  }
}
