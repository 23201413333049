import { SafeHtml } from '@angular/platform-browser';

export class MetricTile {
  amount?: string;
  amountUnformatted?: number;
  alternate?: string;
  amountTitle?: string;
  currencyCode?: string;
  fiscalQuarter?: string;
  svg?: SafeHtml;

  toolTipHeading?: string;
  toolTipText?: string;
  toolTipSize?: string;

  isMobile?: boolean;
}
