import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ENVIRONMENT_CONFIG } from '@mint-libs/common';
import { AuthenticationService } from './authentication.service';

@NgModule({
  imports: [HttpClientModule],
  declarations: [],
  exports: []
})
export class MintAuthenticationModule {
  static forRoot(envConfig: any) {
    return {
      ngModule: MintAuthenticationModule,
      providers: [
        {
          provide: ENVIRONMENT_CONFIG,
          useValue: envConfig
        },
        AuthenticationService
      ]
    };
  }
}
