import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FiltersService } from '../../filters/filters.service';
import { UserProfileService } from '../context/user-profile.service';
import * as Ng1Services from '../hybrid/ng1-upgraded-providers';

@Injectable()
export class Mic2RouteGuard implements CanActivate {
  public sharedContext = new SharedContext();
  constructor(private _router: Router, public sharedContextState: Store<SharedContext>, public filterService: FiltersService) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const mic2Profiles = this.sharedContext.StandardTitles.filter(function(title) {
      return title.isMic2Profile === true;
    });

    if (mic2Profiles && mic2Profiles.length > 0 && this.filterService.isMic2Profile()) {
      return true;
    }

    return false;
  }
}
