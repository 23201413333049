<table mat-table [dataSource]="adjustmentInfo" class="mat-elevation-z8">
  <!-- Adjustment Name Column -->
  <ng-container matColumnDef="AdjustmentName">
    <th mat-header-cell *matHeaderCellDef>Adjustment</th>
    <td mat-cell *matCellDef="let element">{{ element.AdjustmentName }}</td>
  </ng-container>
  <!-- Adjustment Reason Column -->
  <ng-container matColumnDef="Reason">
    <th mat-header-cell *matHeaderCellDef>Reason</th>
    <td mat-cell *matCellDef="let element">{{ element.Reason }}</td>
  </ng-container>
  <!-- Adjustment Comment Column -->
  <ng-container matColumnDef="Comments">
    <th mat-header-cell *matHeaderCellDef>Comment</th>
    <td mat-cell *matCellDef="let element">{{ element.Comments ? element.Comments : 'N/A' }}</td>
  </ng-container>
  <!-- Adjustment Date Column -->
  <ng-container matColumnDef="AdjustmentEffectiveDate">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">{{ element.AdjustmentEffectiveDate | date: 'mediumDate' }}</td>
  </ng-container>
  <!-- Adjustment Amount Column -->
  <ng-container matColumnDef="AdjustmentAmount">
    <th mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let element">{{ payoutReportService.formatCurrency(element.AdjustmentAmount) }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
