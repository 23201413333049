export class TooltipTextBonusPayoutSummary {
    TimeFrame_Quarter="The fiscal quarter of bonus payout. There may be multiple line items if you changed roles or if an additional bonus payment was made by exception. "
            +"If there are multiple payments in a quarter, you’ll be able to see multiple records on expanding the quarter expand icon."
    TimeFrame_Semester="The fiscal semester of bonus payout. There may be multiple line items if you changed roles or if an additional bonus payment was made by exception. "
            +"If there are multiple payments in a semester, you’ll be able to see multiple records on expanding the semester expand icon."
    YTDBonusEarnings = 'YTD bonus earned before deducting previous payments, adjustments, or recoveries.';
    PreviousQuarterPayout = "Includes all bonus payments paid in previous quarters."
    PreviousPayout_UBI_PPI = 'Includes all bonus payments already paid this fiscal year.';
    YtdPaymentAdjustmentAndRecoveries = 'Total amount of adjustments and recoveries made to your bonus payout for the quarter specified.';
    YtdPaymentAdjustmentAndRecoveries_UBI_PPI = 'Total amount of adjustments and recoveries made to your bonus payout for the semester specified (H1 or H2).';
    NetPaymentAmount = 'YTD bonus less any YTD bonus adjustments and recoveries.'
        +' This will be marked as “projected” in the event that the quarter’s payment cycle has not yet completed.';
    NetPaymentAmount_UBI_PPI = 'YTD Earnings less any YTD adjustments and recoveries. This will be marked as “projected” in the event that the semester’s payment cycle has not yet completed.';
    CalculationDate =
        'The date the calculation is locked for submitting to local payroll.  When a date is provided, it signifies a payment is approved and ready to be issued. Actual payout dates follow local subsidiary schedules. ' +
        'When this field shows "Pending payment issuance," it signifies that a payout has not been made or due to 0 payment for the quarterly advances. ' +
        'Other reasons maybe due to incompliance with unsigned PPA, residing in a subsidiary that follows a semi-annual payment schedule, or other eligibility requirements as outlined in the RBI Business Rules.';
    CalculationDate_UBI =
        'The date the calculation is locked for submitting to local payroll.  When a date is provided, it signifies a payment is approved and ready to be issued. Actual payout dates follow local subsidiary schedules. ' +
        'When this field shows "Pending payment issuance," it signifies that a payout has not been made or due to 0 payment for the quarterly advances. ' +
        'Other reasons maybe due to incompliance with unsigned PPA, residing in a subsidiary that follows a semi-annual payment schedule, or other eligibility requirements as outlined in the UBI Business Rules.';
    CalculationDate_PPI =
        'The date the calculation is locked for submitting to local payroll.  When a date is provided, it signifies a payment is approved and ready to be issued. Actual payout dates follow local subsidiary schedules. ' +
        'When this field shows "Pending payment issuance," it signifies that a payout has not been made or due to 0 payment for the quarterly advances. ' +
        'Other reasons maybe due to incompliance with unsigned PPA, residing in a subsidiary that follows a semi-annual payment schedule, or other eligibility requirements as outlined in the PPI Business Rules.';
    }
  