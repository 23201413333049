import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../core/network/data.service';
import { DemoContext } from '../core/context/demo.context';
import { CachingService } from '../core/caching/caching.service';
import { MatDialog } from '@angular/material/dialog';
import { DemoComponent } from './demo.component';
import { InitializationService } from '../core/initialization/initialization.service';
import { UserSessionService } from '../core/session/user-session-service';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class DemoService {
  sharedContext: SharedContext = new SharedContext();
  constructor(
    private endpointService: EndpointService,
    private dataService: DataService,
    private demoContext: DemoContext,
    private cachingService: CachingService,
    private modalService: MatDialog,
    private initializationService: InitializationService,
    public sharedContextState: Store<SharedContext>,
    private userSessionService: UserSessionService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  private toggleState: boolean = false;
  private rolesList: { [key: string]: string }[] = [
    //{ value: 'CCGParticipant', text: 'CCG Participant' },
    //{ value: 'CCGParticipantManager', text: 'CCG Participant Manager' },
    // { value: 'EPGParticipant', text: 'EPG Participant' },
    //{ value: 'EPGParticipantManager', text: 'EPG Participant Manager' },
    //{ value: 'SMSPParticipant', text: 'SMSP Participant' },
    //{ value: 'SMSPParticipantManager', text: 'SMSP Participant Manager' },
    //{ value: 'PSParticipant', text: 'PS Participant' },
    //{ value: 'PSParticipantManager', text: 'PS Participant Manager' },
    { value: 'Participant Manager', text: 'Enterprise - Participant Manager' },
    { value: 'TechnicalAccountManager', text: 'Enterprise - Technology Account Manager' },
    { value: 'TechnologySolutionProfessional', text: 'Enterprise - Technology Solution Professional' }
  ];
  private selectedRole = this.rolesList[0];

  modalRef: any;

  private matDialogConfig = { ariaLabelledBy: 'modal_title', width: '668px', maxHeight: '90vh', position: { top: '2%' } };

  getDemoData(): Observable<any> {
    return this.dataService.get(this.endpointService.GetDemoUsers.uri, this.endpointService.GetDemoUsers.params);
  }

  startDemo() {
    this.dataService.sharedContext;
    this.dataService.sharedContext.isDemoMode = true;
    this.demoContext.isDemotoggled = true;
    this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, toggleView: 0 }));
    this.cachingService.clear();
    this.userSessionService.initialize(true, true, false, false);
  }

  toggleDemo(toggle: boolean) {
    if (toggle) {
      this.toggleState = true;
    }
    setTimeout(function() {
      if (this.toggleState) {
        this.modalRef = this.modalService.open(null, this.matDialogConfig);
      } else {
        this.stopDemo();
      }
    });
  }

  stopDemo() {
    location.reload();
  }
}
