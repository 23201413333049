<div class="direct">
  <mint-direct-detail class="direct__detail" [direct]="direct" (toggleViewDirects)="onToggleViewDirects()" [isShowDirects]="isShowDirects" [showImage]="showImage"></mint-direct-detail>

  <div class="utilization__quota__container" *ngIf="isInRBIAttainmentMode"><mint-metric-attainment [direct]="direct"></mint-metric-attainment></div>

  <div class="utilization__quota__container utilization__quota__container__ubi" *ngIf="isInUBIAttainmentMode">
    <div *ngFor="let ubiAttainmentMetric of direct.ubiAttainmentMetrics">
      <mint-ubi-metric-attainment [ubiAttainmentMetric]="ubiAttainmentMetric"></mint-ubi-metric-attainment>
    </div>
  </div>

  <div class="utilization__quota__container" *ngIf="isInPPIAttainmentMode"><mint-metric-attainment [direct]="direct"></mint-metric-attainment></div>

  <div class="utilization__quota__container" *ngIf="isInQuotaToGoMode"><mint-pcc-metric-attainment [metrics]="direct.pccMetrics"></mint-pcc-metric-attainment></div>

  <div class="utilization__quota__container" *ngIf="isInAzureIncentivesMode"><mint-azure-incentives-metric [direct]="direct"></mint-azure-incentives-metric></div>

  <div class="utilization__quota__container ineligible__container" *ngIf="errorMessage">
    <div class="ineligible__circle"></div>
    <div class="ineligible__cross"></div>
    <div class="ineligible__textContainer"><p class="ineligible__text" [innerHTML]="errorMessage"></p></div>
  </div>
</div>
