<div id="incentive-summary-tiles_1" class="app__tile">
  <div id="incentive-summary-tiles_2" class="app__tile__header">
    <div id="incentive-summary-tiles_3" class="app__tile__heading">My Payout Details</div>
    <app-tour id="incentive-summary-tiles_tour" [tourName]="'Payout details tour'"></app-tour>
    <mint-contextual-video [featureId]="'68'" *ngIf="isRBIUser"> </mint-contextual-video>
    <a
      id="incentive-summary-tiles_4"
      role="button"
      tabindex="0"
      class="app__tile__report"
      (click)="viewReport()"
      (keyup.enter)="viewReport()"
      analytics-tracker
      [tracker]="'Widget_Action_ViewReport_Payout'"
      [type]="'Click'"
      [element]="'Hyperlink'"
      [source]="'Widget_Header_More_DropdownV2'"
    >
      View detailed report
    </a>
  </div>
  <div *ngIf="hasBonusPrograms" class="tab__content__subhead pt-4">
    To view select bonus programs you are eligible for, go to the <a href="https://msit.powerbi.com/groups/me/reports/5d07a363-33a3-4837-9f2a-759b9384cf5b?ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&pbi_source=linkShare">Seller Bonus Program Dashboard</a>. This includes PPI Customer Adds, Data & AI Management Excellence, Data & AI Cosmos Priority, and 3YMC.
  </div>
  <div *mintPayoutUnavailable id="incentive-summary-tiles_5" class="app__nav">
    <mint-tabs *ngIf="loadTemplate" [tabs]="incentiveSummaryTabs" (tabChange)="onTabChange($event)" [activeId]="incentiveSummaryTabs[selectedTab].id"  [tracker]="'IncentiveReportsTab'" [source]="'DashboardV2'"></mint-tabs>
    <div *ngIf="loadTemplate" id="incentive-summary-tiles_8" class="app_nav">
      <mint-payout-summary id="incentive-summary-tiles_9" *ngIf="selectedTab===0" [corePayoutSummaryResult]="corePayoutSummary">Text</mint-payout-summary>
      <mint-bonus-payout-summary id="incentive-summary-tiles_10" *ngIf="selectedTab===1" [bonusPayoutSummaryResult]="bonusPayoutSummary"></mint-bonus-payout-summary>
    <!-- /// To do remove tabset and use ngtabset while we implement this features -->
    <!-- <tabset id="incentive-summary-tiles_6" type="pills">
      <tab id="incentive-summary-tiles_7" heading="Payouts" analytics-tracker [tracker]="'DetailReportsTaab'" [type]="'Click'" [element]="'Tab'" [source]="'DashboardV2'">
        <mint-payout-summary id="incentive-summary-tiles_8"></mint-payout-summary>
      </tab> -->
    <!-- <tab id="incentive-summary-tiles_9" heading="Quota Changes">
        <app-quota-changes id="incentive-summary-tiles_10"></app-quota-changes>
      </tab> -->
    <!-- <tab id="incentive-summary-tiles_11" heading="Revenue Recognition">
        <app-revenue-recognition id="incentive-summary-tiles_12"></app-revenue-recognition>
      </tab> -->
    <!-- </tabset> -->
    <!-- /// To do remove tabset and use ngtabset while we implement this features -->
    </div>
  </div>
    <div *ngIf="isPayoutUnavailable" id="payout-tile-unavailable">
      <span>{{ payoutUnavailableMessage.Title }}</span>
      <span>{{ payoutUnavailableMessage.Text }}</span>
    </div>
  
</div>
