import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { NotificationService } from '../notifications.service';

@Component({
  selector: 'mint-banner-notification',
  templateUrl: './banner-notification.component.html',
  styleUrls: ['./banner-notification.component.scss']
})
export class BannerNotificationComponent implements OnInit, OnChanges {
  bannerNotifications: any = [];
  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    this.getNotifications();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getNotifications();
  }

  getNotifications() {
    this.notificationService.GetNotifications(true).subscribe((response: any) => {
      this.bannerNotifications = response.Notifications.filter(notificaiton => notificaiton.NotificationType === 4);
      this.bannerNotifications.forEach(notification => {
        notification['isBannerDismissed'] = false;
      });
      console.log('banner notification ', this.bannerNotifications);
    });
  }

  removeBanner(notification) {
    this.bannerNotifications.forEach(x => {
      if (notification.NotificationId === x.NotificationId) {
        x['isBannerDismissed'] = true;
      }
    });
  }
}
