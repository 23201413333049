import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../core/network/data.service';
import { CustomHttpParams } from '../core/network/custom-http-params';

@Injectable()
export class NotificationService {
  constructor(private endpoint: EndpointService, private dataService: DataService) {}

  GetNotifications(onlyActive: boolean) {
    let params = CustomHttpParams.getInstance();
    //params = params.append('AppId', '1');
    params = params.append('isActiveOnly', String(onlyActive));
    return this.dataService.get<any[]>(this.endpoint.GetUserNotifications.uri, params);
  }
}
