import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvanceSearchFilterComponent } from './advance-search-filter/advance-search-filter.component';
import { ReportGridComponent } from './report-grid/report-grid.component';
import { ReportTabsComponent } from './report-tabs/report-tabs.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { SharedModule } from '../../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { MulticheckFilterComponent } from './multicheck-filter/multicheck-filter.component';

@NgModule({
  
  imports: [
    CommonModule,
    GridModule,
    SharedModule,
    MatIconModule,
    NgbModule,
    FormsModule,
    ClickOutsideModule
  ],
  declarations: [ 
    AdvanceSearchFilterComponent,
    ReportGridComponent,
    ReportTabsComponent,
    MulticheckFilterComponent
  ],
  exports: [
    AdvanceSearchFilterComponent,
    ReportGridComponent,
    ReportTabsComponent,
    MulticheckFilterComponent
  ]
})
export class ReportsSharedModule { }
