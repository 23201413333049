import { Component, OnInit, Inject, Input, ElementRef } from '@angular/core';
import { CustomHeaderComponent } from '../../shared/grid-components/custom-header/custom-header.component';
import { MetricRendererComponent } from '../../shared/metric-grid/renderers/metric-renderer.component';
import { AttainmentRendererComponent } from '../../shared/metric-grid/renderers/attainment-renderer.component';
import { ProductGroupRendererComponent } from '../../shared/metric-grid/renderers/product-group-renderer.component';
import { DataSourceRendererComponent } from '../../shared/metric-grid/renderers/data-source-renderer.component';
import { CellClassParams } from 'ag-grid-community';
import { BaseGridComponent } from '../../shared/metric-grid/base-grid.component';
import { LegendService } from '../../shared/legends/legend.service';
import { ITooltipTextGrid } from '../../shared/metric-grid/tooltip-text-grid';
import { TargetRendererComponent } from '../../shared/metric-grid/renderers/target-renderer.component';
import { CagrAttainmentRendererComponent } from '../../shared/metric-grid/renderers/cagr-attainment-rendered.component';
import { CagrMetricRendererComponent } from '../../shared/metric-grid/renderers/cagr-metric-renderer.component';
import { CagrPccRendererComponent } from '../../shared/metric-grid/renderers/cagr-pcc-renderer.component';

@Component({
  selector: 'mint-cagr-grid-view',
  templateUrl: './cagr-grid-view.component.html',
  styleUrls: ['./cagr-grid-view.component.scss']
})
export class CagrGridViewComponent extends BaseGridComponent implements OnInit {
  rowData1: any;
  isLoaded = false;
  rowData1Loaded = false;
  rowDataLoaded = false;

  constructor(protected legendService: LegendService, protected element: ElementRef) {
    super(legendService, 105, element);
  }
  @Input() accountDetailsGrid;
  @Input() totalCloudGrowthGrid;

  ngOnInit(): void {
    if (this.accountDetailsGrid != undefined && this.totalCloudGrowthGrid != undefined) {
      this.isLoaded = true;
      this.columnDefs = [
        {
          field: 'name',
          lockPosition: true,
          headerName: 'Metric',
          cellRenderer: 'metricRenderer',
          rowSpan: this.pCGRowSpan,
          cellClassRules: {
            'cell-span-unlocked': function(params) {
              return params.data.attainment >= 100;
            },
            'cell-span-locked': function(params) {
              return params.data.attainment < 100;
            }
          },
          suppressNavigable: true,
          minWidth: 150
        },
        {
          field: 'pccNameGrid',
          lockPosition: true,
          headerName: ' ',
          cellClassRules: {
            'cell-span': this.cellSpan
          },
          cellRenderer: 'pccRenderer',
          suppressNavigable: true,
          minWidth: 150
        },
        {
          field: 'attainment',
          lockPosition: true,
          headerName: 'Attainment',
          cellRenderer: 'attainmentRenderer',
          cellClassRules: {
            'cell-span': this.cellSpan
          },
          suppressNavigable: true,
          minWidth: 116
        },
        {
          field: 'locked',
          lockPosition: true,
          headerName: 'Status',
          valueGetter: function(params) {
            if (params.data.locked === undefined || params.data.locked === null) {
              return 'Error. Something went wrong.';
            }
            return params.data.locked ? 'Locked' : 'Unlocked';
          },
          cellClassRules: {
            'cell-span': this.cellSpan
          },
          suppressNavigable: true,
          minWidth: 150
        },
        {
          field: 'target',
          lockPosition: true,
          headerName: 'Target',
          cellRenderer: 'targetRenderer',
          cellClassRules: {
            'cell-span': this.cellSpan
          },
          suppressNavigable: true,
          minWidth: 90
        }
      ];
      this.rowData = this.accountDetailsGrid;
      this.rowData1 = this.totalCloudGrowthGrid;
    }

    this.frameworkComponents = {
      agColumnHeader: CustomHeaderComponent,
      metricRenderer: CagrMetricRendererComponent,
      pccRenderer: CagrPccRendererComponent,
      attainmentRenderer: CagrAttainmentRendererComponent,
      targetRenderer: TargetRendererComponent
    };
  }
  pCGRowSpan(params) {
    if (params.data.pCGRowSpan !== -1) {
      return params.data.pCGRowSpan;
    }
    return 1;
  }
  cellSpan(params) {
    return params.data.pCGRowSpan !== -1 || params.data.pCGRowSpan !== 1;
  }
}
