import { BaseModel } from 'src/app/shared/models/base.model';
import { Expose, deserializeArray, plainToClass } from 'class-transformer';

export class DataSource extends BaseModel {
  @Expose({ name: 'SourceName' }) sourceName: string;
  @Expose({ name: 'SourceLastRefreshTime' }) sourceLastRefreshTime: string;
  @Expose({ name: 'RefreshCycle' }) refreshCycle: string;
  @Expose({ name: 'RevenueFreezeActive' }) isRevenueFreeze: boolean;
  @Expose({ name: 'SourceNextRefreshTime' }) sourceNextRefreshTime: string;

  deserialize(from: any): DataSource[] {
    const pccRefreshTimeList = deserializeArray<DataSource>(DataSource, JSON.stringify(from), this.classTransformOptions);
    return pccRefreshTimeList;
  }
  deserializeSingle(from: object): DataSource {
    const metricPcc = plainToClass<DataSource, object>(DataSource, from, this.classTransformOptions);
    return metricPcc;
  }
}
