import { MeasureTypeMappingState } from "../models/measure-type-mapping.model";
import { TMeasureTypeMapping } from "../types/measure-type-mapping.type";
import { MeasureTypeMappingAction, MeasureTypeMappingActionTypes } from "./measure-type-mapping.actions";

export const measureTypeMappingFeatureKey = 'measureTypeMapping';

const initialState: MeasureTypeMappingState = new MeasureTypeMappingState([]);

export function measureTypeMappingReducer(state = initialState, action: MeasureTypeMappingAction): MeasureTypeMappingState {
  switch (action.type) {
    case MeasureTypeMappingActionTypes.LoadMeasureTypeMapping:
      return new MeasureTypeMappingState(action.payload.mapping, true);

    default:
      return state;
  }
}
