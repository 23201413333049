<article id="impersonation_1" class="vertical-center s-h-100" role="alert">
  <div id="impersonation_2" class="impersonate-user" aria-live="polite" tabindex="-1">
    <div id="impersonation_14" class="m-t-20" style="padding-top: 5px; width:330px">
      <div>
        <h4 style="font-weight: 500;margin-bottom: 0">Demo</h4>
        <p style="font-weight: 500;margin-bottom: 15px;">Impersonate as:</p>
      </div>
      <div id="impersonation_17" style="max-height: 500px; min-height: 150px; overflow-y: auto;">
        <div id="impersonation_18" class="impersonate-head" style="display: block" *ngFor="let user of list">
          <div id="impersonation_19" style="display: block" role="button" tabindex="0"
            (click)="startImpersonationByClicking(user.Alias)" title="{{ user.Alias }}">
            <div id="impersonation_22" title="{{ user.fullName }}">
              <p id="impersonation_23" style="margin: 0" class="text-light ellipsis" title="{{ user.Title }}">
                {{ user.Title }}</p>
              <!-- <small id="impersonation_24" class="text-lowercase">({{user.alias}})</small> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</article>