import { Component, OnInit, Input } from '@angular/core';
import { Direct } from '../models/direct';

@Component({
  selector: 'mint-azure-incetives',
  templateUrl: './azure-incetives.component.html',
  styleUrls: ['./azure-incetives.component.scss']
})
export class AzureIncentivesComponent implements OnInit {
  @Input() directs: Array<Direct>;
  @Input() isLoaded: boolean;

  constructor() {}

  ngOnInit() {}

  getClassByLevel(direct: Direct): string {
    const classByLevel = `direct__itemLevel${direct.level}`;
    return classByLevel;
  }
}
