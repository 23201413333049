<app-progress-bar id="dashboard_8" class="sticky__progress__bar"></app-progress-bar>
<ngx-spinner>
  <h4 style="color: white;">{{ saveStatus }}</h4>
</ngx-spinner>
<mint-splash [isAdmin]="true"></mint-splash>
<div class="header__container" *ngIf="!toggleAdd">
  <div class="header__container-title">Manage Feature Introductions</div>
  <div class="header__container-btn">
    <button class="header__container-btn-color" (click)="addNewFeatureIntro()">Create New Feature Introductions</button>
  </div>
</div>
<div class="tablecontainer" *ngIf="!toggleAdd">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">
          <div>
            Actions
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Start Date
            <button class="btn_icon" (click)="sortByCol('startTime')">
              <span class="icon" [ngClass]="{ 'icon-chevron-down': sortCols.startTimeAsc, 'icon-chevron-up': !sortCols.startTimeAsc }"></span>
            </button>
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            End Date
            <button class="btn_icon" (click)="sortByCol('endTime')">
              <span class="icon" [ngClass]="{ 'icon-chevron-down': sortCols.endTimeAsc, 'icon-chevron-up': !sortCols.endTimeAsc }"></span>
            </button>
          </div>
        </th>
        <th scope="col">
          Status
          <button class="btn_icon" (click)="sortByCol('status')"><span class="icon" [ngClass]="{ 'icon-chevron-down': sortCols.endTimeAsc, 'icon-chevron-up': !sortCols.endTimeAsc }"></span></button>
        </th>
        <th scope="col">Added By (Alias)</th>
        <th scope="col">
          <div class="div__lg">Feature Introduction ({{ fgData.length }})</div>
        </th>
        <th scope="col">
          <div class="div__lg">
            List Of Aliases
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Roles
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Plans
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Standard Titles
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Country(s)
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Business Segments
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Qualifier (1x)
          </div>
        </th>
        <th scope="col">
          <div class="div__md">
            Qualifier (2x)
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let fg of fgData | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize; index as i"
        [ngClass]="getStatus(fg) == statusCodes[10] ? 'expired' : fg.status == 2 ? 'published' : 'draft'"
      >
        <td>
          <button class="btn_icon" (click)="deleteFeatureGroup(fg, i)"><span class="icon icon-delete"></span></button>
          <button class="btn_icon" (click)="previewFeatureGroup(fg)"><span class="icon icon-eye"> </span></button>
          <button class="btn_icon" (click)="editFeatureGroup(fg)"><span class="icon icon-edit"></span></button>
        </td>
        <td>{{ fg.startTime | date: 'MMM d, y, h:mm a' }}</td>
        <td>{{ fg.endTime | date: 'MMM d, y, h:mm a' }}</td>
        <td [ngClass]="getStatus(fg) == statusCodes[2] ? 'bold_status' : ''">{{ getStatus(fg) }}</td>
        <td>{{ fg.createdBy }}</td>
        <td>{{ fg.featureHeader | rowShortner: 100 }}</td>
        <td>{{ fg.aliasList | rowShortner: 100 }}</td>
        <td>{{ fg.roles | rowShortner: 100 }}</td>
        <td>{{ fg.planNames | rowShortner: 100 }}</td>
        <td>{{ fg.standardTitles | rowShortner: 100 }}</td>
        <td>{{ fg.countryNames | rowShortner: 100 }}</td>
        <td>{{ fg.businessSegment | rowShortner: 100 }}</td>
        <td>{{ fg.qualifier1Names | rowShortner: 100 }}</td>
        <td>{{ fg.qualifier2Names | rowShortner: 100 }}</td>
      </tr>
    </tbody>
  </table>
  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="fgData.length"></ngb-pagination>
</div>
<!-- <mint-feature-introduction *ngIf="toggleAdd"></mint-feature-introduction> -->
<div *ngIf="toggleAdd" class="feature__container feature__container--outer">
  <div>
    <div class="breadcrumb-wrap">
      <span class="breadcrumb">
        <button (click)="addNewFeatureIntro()" class="btn btn_breadcrumb btn_breadcrumb-manage">Manage Feature Introduction</button>
      </span>
    </div>
    <div class="breadcrumb-wrap">
      <span class="breadcrumb-wrap-sep"><i class="icon icon-chevron-right"></i></span>
      <span class="breadcrumb"> <button class="btn_breadcrumb" [ngClass]="{ active__bold: toggleAdd }">Create Feature Introduction</button> </span>
    </div>
  </div>
  <span> Please add/edit upto 5 new features for Mint. This will be shown when a users logs into the application. Editing will make the popup re-appear for the user. </span>
  <div *ngIf="!submenu" class="feature__container">
    <div>
      <div class="breadcrumb-wrap">
        <span class="breadcrumb">
          <button class="btn btn_breadcrumb" [ngClass]="{ active__bold: !submenu }">
            Add New Feature Introductions
          </button>
        </span>
      </div>
      <div class="breadcrumb-wrap">
        <span class="breadcrumb-wrap-sep"><i class="icon icon-chevron-right"></i></span>
        <span class="breadcrumb"> <button class="btn btn_breadcrumb breadcrumb_create" (click)="toggleSubmenu()">Recipients</button> </span>
      </div>
      <div class="preview_feture"><button class="btn" (click)="previewFeatureGroup(currFG)">Preview</button></div>
    </div>
    <div>
      <div>
        <div class="date-input">
          <label class="input-header">Start Date & Time in UTC <span class="color__red">*</span> </label>
          <input class="input-field" [(ngModel)]="currFG.startTime" (change)="validateInputs(currFG)" type="datetime-local" />
        </div>
        <div class="date-input date-input-last">
          <label class="input-header">End Date & Time in UTC <span class="color__red">*</span></label>
          <input class="input-field" [(ngModel)]="currFG['endTime']" (change)="validateInputs(currFG)" type="datetime-local" />
        </div>
      </div>
      <div>
        <label class="input-header">Header Title <span class="color__red">*</span></label>
        <input class="input-field" [(ngModel)]="currFG.featureHeader" (change)="validateInputs(currFG)" type="text" />
      </div>

      <div class="validation_error">{{ validationError1 }}</div>
      <div>
        <div id="static-1" *ngFor="let fintro of currFG['featureIntroductions']; let i = index">
          <div>
            <h4>
              <span role="button" (click)="removeFeatureIntroduction(currFG, i)" class="feature_intro_close"> X </span>
              <span role="button" (click)="openFeature(i)" class="feature_intro_labels">Feature - {{ i + 1 }} {{ i === openPosition ? '&#x25B2;' : '&#x25BC;' }} </span>
            </h4>
          </div>
          <div *ngIf="openPosition === i">
            <div class="col-lg-12 feature_intro_box">
              <label class="input-header">Feature Title</label> <input class="input-field" [(ngModel)]="fintro.featureHead" type="text" /> <label class="input-header">Feature Message</label>
              <!-- <ckeditor [(ngModel)]="fintro.featureBody"></ckeditor> -->
              <angular-editor id="editorrich" [placeholder]="'Enter text here...'" [(ngModel)]="fintro.featureBody" [config]="config"></angular-editor>

              <label class="input-header">Feature Link</label>
              <select [(ngModel)]="fintro.pageLink" placeholder="Add Page Link to MINT Feature" class="input-field">
                <option value="">No Link Selected</option>
                <option value="Settings">Settings</option>
                <option value="ThreeYrThreeCloud">Three Yr Three Cloud</option>
                <option value="AccelerateAzure">Accelerate Azure</option>
                <option value="MyCompensationPlan">MyCompensation Plan</option>
                <option value="Estimator">Estimator</option>
                <option value="Dashboard">Dashboard</option>
                <option value="PlanParticipationAgreement">Plan Participation Agreement</option>
                <option value="QuotaAcknowledgement">Quota Acknowledgement</option>
                <option value="MyCompliance">My Compliance</option>
                <option value="UBIUtilization">UBI Utilization</option>
                <option value="ParticipantPlanPerformance">Participant PlanPerformance</option>
                <option value="QuotaDetails">Quota Details</option>
                <option value="ActualsAnalytics">Actuals Analytics</option>
                <option value="CompensationActualsbyAccount">Compensation Actuals by Account</option>
                <option value="CompensationActualsbyProduct">Compensation Actuals by Product</option>
                <option value="TargetandActualsbyIncentiveTerritory">Target and Actuals by Incentive Territory</option>
                <option value="PayoutDetails">Payout Details</option>
                <option value="AttainmentByBucket">Attainment By Bucket</option>
                <option value="AccessibilityHelp">Accessibility Help</option>
                <option value="Glossary">Glossary</option>
                <option value="TrainingMaterials">Training Materials</option>
                <option value="FAQs">FAQs</option>
              </select>
              <label class="input-header">Image Link <span> ( maximum width size 800 pixels )</span></label>
              <input class="input-field" [(ngModel)]="fintro.imageLink" type="text" />
              <label class="input-header">Video Link <span> ( maximum width size 800 pixels )</span> </label>
              <input class="input-field" [(ngModel)]="fintro.videoLink" type="text" />
            </div>
          </div>
        </div>
      </div>
      <div class="new-line-btn"><span role="button" (click)="addFeatureIntroduction(currFG)" class="control_button green_text">Add line item + </span></div>
      <div>
        <button class="btn control_button" (click)="saveFG(currFG, 0)" [disabled]="currFG['status'] === 2">Save As Draft</button>
        <button class="btn control_button" (click)="toggleSubmenu()">Next</button>
      </div>
    </div>
  </div>
  <div *ngIf="submenu" class="feature__container">
    <div>
      <div class="breadcrumb-wrap">
        <span class="breadcrumb"> <button (click)="toggleSubmenu()" class="btn btn_breadcrumb breadcrumb_create">Add New Feature Introductions</button> </span>
      </div>
      <div class="breadcrumb-wrap">
        <span class="breadcrumb-wrap-sep"><i class="icon icon-chevron-right"></i></span>
        <span class="breadcrumb">
          <button class="btn btn_breadcrumb" [ngClass]="{ active__bold: submenu }">
            Recipients
          </button>
        </span>
      </div>
      <div class="preview_feture"><button class="btn" (click)="previewFeatureGroup(currFG)">Preview</button></div>
    </div>
    <div>
      <div>
        <input class="checkmarked" type="checkbox" [(ngModel)]="showAlias" /><label class="group_header">LIST OF ALIASES</label>
        <span>( ; separated )</span>
      </div>
      <div class="col-lg-12">
        <textarea
          rows="4"
          cols="50"
          class="input-field"
          [(ngModel)]="filters.aliasList"
          [disabled]="!showAlias"
          placeholder="Enter semicolon seperated alias like 'jotap;artalukd;'. For all aliases use 'ALL'"
        >
        </textarea>
      </div>
      <div class="input-header">Note: all user aliases specified above will recieve popup.</div>
      <br />
      <div><input class="checkmarked" [checked]="!showAlias" (change)="showAlias = !showAlias" type="checkbox" /> <label class="group_header">GROUPS</label></div>
    </div>
    <div *ngFor="let f of filters.filters" class="row">
      <br /><label class="col-lg-2">{{ filters[f].label | titlecase }} : </label>
      <ng-multiselect-dropdown
        class="col-lg-10"
        [placeholder]="filters[f].title"
        [disabled]="showAlias"
        [settings]="dropdownSettings"
        [data]="filters[f].list"
        [(ngModel)]="filters[f].selected"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>

    <div class="footer__splash">
      <button class="btn control_button" (click)="saveFG(currFG, 0)" [disabled]="currFG['status'] === 2">Save As Draft</button>
      <button class="btn control_button" (click)="toggleSubmenu()">Back</button>
      <div class="publish_feature"><button class="btn" (click)="open(template)">Publish</button></div>
    </div>
  </div>
</div>

<ng-template #template let-modal>
  <div class="modal-header">
    <button type="button" class="modal__close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg id="detail-dropdown_8" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          id="detail-dropdown_9"
          d="M9.70398 0.851639L5.561 5.00004L9.70398 9.14844L9 9.85164L4.85199 5.70904L0.703979 9.85164L0 9.14844L4.14297 5.00004L0 0.851639L0.703979 0.148438L4.85199 4.29104L9 0.148438L9.70398 0.851639Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <div class="modal-body">
    Are you sure you want to publish this? Please preview your changes if not already done.
  </div>
  <div class="modal-footer">
    <button class="footer__btn footer__btn-cancel" (click)="modal.close('Cancel click')">Cancel</button> <button class="footer__btn footer__btn-primary" (click)="saveFG(currFG, 2)">Publish</button>
  </div>
</ng-template>
