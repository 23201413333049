import { Expose, plainToClass } from 'class-transformer';
import { BaseModel } from '../models/base.model';
import { IDeserializable } from 'src/app/shared/models/iDeserializable.interface';

export class EmployeeInfo extends BaseModel implements IDeserializable<EmployeeInfo> {
  filterService: any;
  @Expose({ name: 'FirstName' }) firstName: string;
  @Expose({ name: 'LastName' }) lastName: string;
  @Expose({ name: 'Alias' }) alias: string;
  @Expose({ name: 'Qualifier1Name' }) qualifier1Name: string;
  @Expose({ name: 'Qualifier2Name' }) qualifier2Name: string;
  @Expose({ name: 'PlanName' }) planName: string;
  @Expose({ name: 'OrganizationName' }) organizationName: string;
  @Expose({ name: 'IncentiveType' }) incentiveType: string;
  @Expose({ name: 'ParticipationStartDate' }) participationStartDate: Date;
  @Expose({ name: 'ParticipationEndDate' }) participationEndDate: Date;
  @Expose({ name: 'CompensationGroup' }) compensationGroup: string;
  @Expose({ name: 'ManagerFirstName' }) managerFirstName: string;
  @Expose({ name: 'ManagerLastName' }) managerLastName: string;
  @Expose({ name: 'ManagerAlias' }) managerAlias: string;
  @Expose({ name: 'PersonnelNumber' }) personnelNumber: string;

  getUserName() {
    return `${this.firstName} ${this.lastName}`;
  }

  getRoleDuration() {
    return `${this.formatter.transformToDateFormat(this.participationStartDate, 'mediumDate', 'en-US')} - ${this.formatter.transformToDateFormat(this.participationEndDate, 'mediumDate', 'en-US')}`;
  }

  getEffectiveDateDuration() {
    return `${this.formatter.transformToDateFormat(this.participationStartDate, 'mediumDate', 'en-US')} - ${this.formatter.transformToDateFormat(this.participationEndDate, 'mediumDate', 'en-US')}`;
  }

  getManagerName() {
    return `${this.managerFirstName} ${this.managerLastName} (${this.managerAlias})`;
  }

  deserialize(from: object): EmployeeInfo {
    const employeeInfo = plainToClass<EmployeeInfo, object>(EmployeeInfo, from, this.classTransformOptions);
    return employeeInfo;
  }
}
