export enum MeasureType {
  DefaultRecurring = 0,
  Baseline = 1
}

export type TMeasureTypeMapping = {
  pccid: number;
  pcgid: number;
  quotaMeasureType: MeasureType;
};
