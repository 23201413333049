import { Component, Inject, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { Router } from '@angular/router';
import { PageTour } from '@pagetour/sdk';
import { SupportHelperService } from './../../support/support-helper.service';
import { OcvFeedbackService } from './../../ocv-feedback/ocv-feedback.service';
import { PageTourService } from '../../shared/training/pagetour.service';
import { DemoService } from '../../demo/demo.service';
import { UserProfileService } from '../../core/context/user-profile.service';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from '../../core/navigation/navigation.service';
import { ImpersonationService } from 'src/app/core/impersonation/impersonation.service';
import { InitializationService } from 'src/app/core/initialization/initialization.service';
import { UserSessionService } from 'src/app/core/session/user-session-service';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggleClickEvent = new EventEmitter<any>();
  adalService: any;
  demoService: DemoService;
  support = false;
  showNotes = false;
  unReadCount = 0;
  isDemoEnabled = false;
  isDemoMode2 = false;
  isRead: boolean;
  isOptoutBannerClosed = false;
  canCreateTour: boolean;
  isPreviewUser: boolean;
  isOcvVisible: boolean;

  @Input() title: string;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private navigationService: NavigationService,
    public sharedContextState: Store<SharedContext>,
    private router: Router,
    public rolefeatureService: RoleFeatureService,
    private initializationService: InitializationService,
    demoService: DemoService,
    public userProfileService: UserProfileService,
    private impersonationService: ImpersonationService,
    private supportHelperService: SupportHelperService,
    private ocvFeedbackService: OcvFeedbackService,
    private pageTourService: PageTourService,
    private userSessionService: UserSessionService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.demoService = demoService;
  }

  ngOnInit() {
    if (environment.demoAppUrl && environment.demoAppUrl !== 'https://mint.microsoftfederal.com/?demoMode=true' && !environment.isDemoMode2) {
      this.isDemoEnabled = true;
    }
    this.isDemoMode2 = environment.isDemoMode2;

    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        hasTour: false
      })
    );
    this.canCreateTour = this.sharedContext.loggedInUserInfo.isSuperAdmin;
    this.isPreviewUser = this.sharedContext.isPreviewUser;
    this.isOcvVisible = environment.isOcvEnabled;
  }

  isImpersonatingOrViewAs() {
    return this.impersonationService.isImpersonatingOrViewAs();
  }

  openDemoApp() {
    window.open(environment.demoAppUrl, '_blank');
  }

  isNotificationFeatureShown() {
    if (!this.rolefeatureService.isFeatureApplicable(this.rolefeatureService.pages.Dashboard, this.rolefeatureService.features.Notifications)) {
      return false;
    }
    return true;
  }

  enableBot() {
    return (
      this.sharedContext.incentiveType === 'RBI' && this.rolefeatureService.isPageApplicable(this.rolefeatureService.pages.Bot)
      // &&
      // this.sharedContext.isImpersonating &&
      // (this.adalService.userInfo.userName === 'absiddik@microsoft.com' ||
      //   this.adalService.userInfo.userName === 'shaktisa@microsoft.com' ||
      //   this.adalService.userInfo.userName === 'kemulh@microsoft.com' ||
      //   this.adalService.userInfo.userName === 'rvasudev@microsoft.com' ||
      //   this.adalService.userInfo.userName === 'damaye@microsoft.com')
    );
  }
  refresh() {
    if (environment.isDemoMode2) {
      return;
    }

    this.userSessionService.initialize(true, true, false);
    this.router.navigateByUrl(this.router.url, { skipLocationChange: true });

    // Showing optout banner on page refresh
    this.isOptoutBannerClosed = false;
  }

  showInAppFeedbackPopup() {
    this.ocvFeedbackService.showFeedbackPopup();
  }

  skipMainContent() {
    // if ($("#impersonate-as").is(':visible')) {
    //     $("#impersonate-as").focus();
    // }
    //     else if ($("#tab-myinfo").is(':visible')) {
    //     $("#tab-myinfo").focus();
    // }
    //     else if ($("#report-nav").is(':visible')) {
    //     $("#report-nav").focus();
    // }
    //     else if ($("#estimator-toggle").is(':visible')) {
    //     $("#estimator-toggle").focus();
    // }
    //     else if ($("#tab-qa").is(':visible')) {
    //     $("#tab-qa").focus();
    // }
    //     else if ($("#toggle-filter").is(':visible')) {
    //     $("#toggle-filter").focus();
    // }
    //     else if ($("#tab-help").is(':visible')) {
    //     $("#tab-help").focus();
    // }
    const mainElem = document.getElementById('mainContent');
    mainElem.focus();
  }

  toggleSidebar() {
    this.toggleClickEvent.emit('toggleEvent');
  }

  navigateToMintInsiders() {
    if (this.sharedContext.isSuperAdmin && !this.sharedContext.isImpersonating) {
      this.navigationService.navigateTo('/admin/settings');
    } else {
      this.navigationService.navigateTo(['settings']);
    }
  }

  navigateToSettings() {
    this.navigationService.navigateTo(['settings']);
  }

  // old PageTour

  // authorPageTour = function() {
  //   this.pageTour.openPageTourAuthorDialog();
  // };

  // managePageTour = function() {
  //   this.pageTour.openPageTourManageDialog();
  // };

  // playPageTour = function() {
  //   this.pageTourService.getTour();
  // };

  // New Page tour package

  authorPageTour() {
    this.pageTourService.authorPageTour();
  }

  managePageTour() {
    this.pageTourService.managePageTour();
  }

  onPaneClosed() {
    this.showNotes = false;
  }

  openSupportModal() {
    this.supportHelperService.openSupportModal();
  }
}
