import { Component, Inject, OnInit } from '@angular/core';
import { BaseRevenueComponent } from '../base-revenue.component';
import { ReportType } from '../report-type.enum';
import { ReportsService } from '../reports.service';
import { MessagesService } from '@mint-libs/context';
import { Formatter } from '@mint-libs/common';
import { Code } from '../../app.constants';
import { RevenueAccount } from '../reports-shared/models/revenue-account.model';
import { ReportGrid } from '../reports-shared/models/report-grid.model';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { UserProfileService } from '../../core/context/user-profile.service';
import { ExportToExcelService } from '../../shared/excel-export/export-to-excel.service';
import { FiltersService } from '../../filters/filters.service';
import { LoggerService } from '@mint-libs/logging';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-account-report',
  templateUrl: './account-report.component.html',
  styleUrls: ['./account-report.component.scss'],
  providers: [Code]
})
export class AccountReportComponent extends BaseRevenueComponent implements OnInit {
  public defaultTabId = 2;
  public activeTabId = 2;
  public filterTabId = 4;

  public filterTab: any;
  public tabs = [
    {
      id: 1,
      title: 'All Accounts',
      rowCount: null,
      disabled: true,
      dimList: null
    },
    {
      id: 2,
      title: 'Top ',
      rowCount: 100,
      disabled: false,
      dimList: null
    },
    {
      id: 3,
      title: 'Top ',
      rowCount: 200,
      disabled: false,
      dimList: null
    },
    {
      id: 4,
      title: 'Search Results ',
      rowCount: 0,
      disabled: true,
      dimList: []
    }
  ];
  columns: ReportGrid[] = [
    {
      field: 'name',
      columnName: 'Account Name',
      width: 180,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'type',
      columnName: 'Account Type',
      width: 150,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'pcgName',
      columnName: 'Bucket Name',
      width: 200,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'pccName',
      columnName: 'Metric Name',
      width: 180,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.month',
      columnName: 'Fiscal Month',
      format: '{0: MMMM, yyyy}',
      width: 150,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    },
    {
      field: 'revenue.actualAmount',
      columnName: 'Actual',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.actualAdjustmentAmount',
      columnName: 'Bulk Actuals Adjustment',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.actualModifier',
      columnName: 'Actual Modifier',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.RNYB',
      columnName: 'Reserved Actual',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.totalActual',
      columnName: 'Total Actual',
      width: 120,
      filterable: false,
      sortable: true,
      applyCustomPipe: true
    },
    {
      field: 'revenue.metric',
      columnName: 'Unit',
      width: 120,
      filterable: true,
      sortable: true,
      applyCustomPipe: false
    }
  ];
  revenueData: RevenueAccount[] = [];

  IsDynamicsDisclaimerVisible = false;
  public sharedContext: SharedContext = new SharedContext();
  constructor(
    public reportService: ReportsService,
    private formatter: Formatter,
    public messageService: MessagesService,
    public exportToExcelService: ExportToExcelService,
    public userProfileService: UserProfileService,
    public loggingService: LoggerService,
    public filterService: FiltersService,
    public sharedContextState: Store<SharedContext>
  ) {
    super(reportService, messageService, filterService);
    this.revenueReportType = ReportType.AccountReport;
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  ngOnInit() {
    this.loggingService.trackPageView('Report-Revenue By Accounts', null);
    this.filterTab = this.tabs.find(t => t.id === this.filterTabId);
    this.IsDynamicsDisclaimerVisible = this.sharedContext.IsDMYEligible;

    this.initPayoutDisclaimer();
    this.initTargetSummary(this.removeDynamicsPcc);
    this.tabsChange(this.activeTabId);
  }

  private initDetailedAccounts(dimListName, rowCount) {
    this.reportService.getDetailedAccounts(dimListName, rowCount).subscribe(response => {
      this.isLargeUser = response.isLargeUser;
      this.revenueData = new RevenueAccount().deserialize(response?.data);
      this.initPccChart(this.PCCs, this.selectedPccChart, this.revenueReportType);
    });
  }

  public initRevenueChart(revenueData: RevenueAccount[]) {
    this.chartDataRevenue = [];
    const groupedByDimNameData = this.groupBy(revenueData, (r: RevenueAccount) => r.name);
    for (const dimName in groupedByDimNameData) {
      const revenueBarChart = {
        desc: dimName,
        val: 0
      };
      groupedByDimNameData[dimName].forEach(r => (revenueBarChart.val += r.revenue.totalActual));
      this.chartDataRevenue.push(revenueBarChart);
    }
    this.chartDataRevenue = this.chartDataRevenue.sort((a, b) => b.val - a.val);
  }

  public filterSelectedPcc(selectedPccChart) {
    this.selectedPccChart = selectedPccChart;
    let revenueDataFilteredByPcc = this.revenueData.filter(revenue => revenue.pccID === selectedPccChart?.id);
    if (selectedPccChart.isPointInTime) {
      revenueDataFilteredByPcc = revenueDataFilteredByPcc.filter(revenue => this.formatter.transformToDateFormat(revenue.revenue.month, 'MMMM, yyyy', 'en-US').split(',')[0] === this.getPITMonth());
    }
    this.initRevenueChart(revenueDataFilteredByPcc);
  }

  public search(queryText: string) {
    this.searchDimNames(queryText, ReportType.AccountReport);
  }

  public filterAccounts(selectedfilters) {
    this.filterTab.dimList = selectedfilters;
    this.filterTab.rowCount = selectedfilters?.length;

    if (selectedfilters?.length === 0 || !selectedfilters) {
      this.activeTabId = this.defaultTabId;
      this.tabsChange(this.activeTabId);
      this.filterTab.disabled = true;
      return;
    }
    this.activeTabId = this.filterTabId;
    this.filterTab.disabled = false;
    this.tabsChange(this.activeTabId);
  }

  public tabsChange(tabId) {
    this.activeTabId = tabId;
    const activeTab = this.tabs.find(t => t.id === this.activeTabId);
    this.initDetailedAccounts(activeTab.dimList, activeTab.rowCount);
  }

  onRevenueExcelExport(postBody) {
    this.revenueExcelConfigs.fileName = this.exportToExcelService.getFileName('CompActualsByAccount', true);
    const $result = this.reportService.exportRevenueAccountDetail(postBody);

    this.exportToExcelService.handleExcelDownload($result, this.revenueExcelConfigs);
  }
}
