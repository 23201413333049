import { NgModule } from '@angular/core';
import { ImpersonationNotificationComponent } from './impersonation-notification.component';
import { DemoNotificationComponent } from '../demo/demoNotification.component';
import { OptoutNotificationComponent } from './optout-notification.component';
import { RollOverNotificationComponent } from './rollover-notification.component';
// import { NotiFicationPaneDirective } from '../core/hybrid/notificationPane.directive';
// import { BannerNotificationDirective } from '../core/hybrid/bannerNotification.directive';
import { ComplianceNotificationComponent } from './compliance-notification.component';
// import { NotificationBarDirective } from '../core/hybrid/notificationBar.directive';
import { NotificationService } from './notifications.service';
import { CommonModule } from '@angular/common';
import { NotificationsPaneComponent } from './notifications-pane/notifications-pane.component';
import { SharedModule } from '../shared/shared.module';
import { NotificationsComponent } from './notifications-all/notifications.component';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BannerNotificationComponent } from './banner-notification/banner-notification.component';
import { FiltersModule } from '../filters/filters.module';

@NgModule({
  imports: [CommonModule, SharedModule, MatIconModule, MatPaginatorModule, MatSelectModule, MatSortModule, MatTableModule, FormsModule, ReactiveFormsModule, FiltersModule],
  declarations: [
    // NotiFicationPaneDirective,
    // BannerNotificationDirective,
    ImpersonationNotificationComponent,
    DemoNotificationComponent,
    RollOverNotificationComponent,
    ComplianceNotificationComponent,
    // NotificationBarDirective,
    NotificationsPaneComponent,
    NotificationsComponent,
    BannerNotificationComponent
  ],
  exports: [
    // NotiFicationPaneDirective,
    // BannerNotificationDirective,
    ImpersonationNotificationComponent,
    DemoNotificationComponent,
    RollOverNotificationComponent,
    ComplianceNotificationComponent,
    // NotificationBarDirective,
    NotificationsPaneComponent,
    NotificationsComponent,
    BannerNotificationComponent
  ],
  bootstrap: [NotificationsComponent],
  providers: [NotificationService]
})
export class NotificationModule {}
