import { Component, OnInit, Input, ViewChild, TemplateRef, Inject } from '@angular/core';
import * as _ from 'underscore';
import { MatAccordion } from '@angular/material/expansion';
import { PayoutReportService } from '../payout-report.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Ng1Services from '../../../core/hybrid/ng1-upgraded-providers';
import { LoggerService } from '@mint-libs/logging';

@Component({
  selector: 'mint-payout-backup-report-section',
  templateUrl: './payout-report-section.component.html',
  styleUrls: ['./payout-report-section.component.scss']
})
export class PayoutReportBackupSectionComponent implements OnInit {
  fromPayout = true;
  graphService: any;
  thumbnailPhoto: any;
  showAdjustmentDetails: any;
  showCoreBucketDetails: boolean;
  paymentDetails: any;
  modalRef;
  selectedMetric: any;

  @Input() payoutInfo: any;
  @ViewChild('firstAccordion') firstAccordion: MatAccordion;
  @ViewChild('secondAccordion') secondAccordion: MatAccordion;

  constructor(public payoutReportService: PayoutReportService, private modalService: NgbModal, private loggingService: LoggerService) {}
  ngOnInit() {
    this.getHeightForBlade();
  }

  ngOnChanges() {
    this.payoutReportService.getSelectedCoreBucket();
  }

  getHeightForBlade() {
    const element = document.querySelector<HTMLElement>('.payout-report-blade1').offsetHeight;
    document.getElementById('payout-report-blade2').style.height = element.toString() + 'px';
  }

  closeAllOtherAccordians(accordion: MatAccordion, accordionName: string, data: any) {
    this.showAdjustmentDetails = false;
    this.showCoreBucketDetails = false;
    if (accordionName == 'firstAccordion') {
      this.loggingService.trackEvent('PayoutReport_FirstBlade', { SelectedMenuItem: data.AdjustmentName }, true);
      this.showAdjustmentDetails = true;
    } else {
      this.loggingService.trackEvent('PayoutReport_FirstBlade', { SelectedMenuItem: data.PCG }, true);
      this.showCoreBucketDetails = true;
    }
    accordion.closeAll();
  }

  openGroup(e) {
    const element = document.querySelector<HTMLElement>('.mat-expanded').offsetTop;
    if (element) {
      document.getElementById('selection-pointer').style.top = (element - 305).toString() + 'px';
    }
    document.getElementById('selection-pointer').style.visibility = 'visible';
    this.getHeightForBlade();
  }

  openModal(template: TemplateRef<any>, pcg, pccId) {
    this.selectedMetric = pcg.PCCDetails.find(p => p.PCCID === pccId);
    this.loggingService.trackEvent('PayoutReport_ChosenMetric', { SelectedMenuItem: this.selectedMetric.PCCName }, true);
    this.modalRef = this.modalService.open(template, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'mint-modal-backdrop', windowClass: 'mint-modal-window-center' });
  }
}
