import { Component, Inject, OnInit } from '@angular/core';
import { LegendService } from '../../shared/legends/legend.service';
import { ChartType } from '../../shared/metric-chart/chartType.enum';
import { Payline } from '../../shared/models/payline.model';
import { ReportsService } from '../reports.service';
import { Formatter, CustomNumberPipe, PercentagePipe } from '@mint-libs/common';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { AttainmentByBucketViewHelperService } from './attainment-by-bucket-view-helper.service';
import { TooltipOrientation } from '../../shared/tooltip/tooltip-orientation.enum';
import { ExportToExcelService } from '../../shared/excel-export/export-to-excel.service';
import { LoggerService } from '@mint-libs/logging';

@Component({
  selector: 'mint-attainment-by-bucket-report',
  templateUrl: './attainment-by-bucket.component.html',
  styleUrls: ['./attainment-by-bucket.component.scss'],
  providers: [AttainmentByBucketViewHelperService]
})
export class AttainmentByBucketComponent implements OnInit {
  isExpandAll = false;
  gridData = [];
  showDownloadProgressLoader = false;
  exportToExcelLabel = '';
  isAttainmentNoteShown = false;
  chartDataPcgs: any;
  selectedPcg: any;
  pcgChartYmax = 300;
  pccDataList = [];
  showOCPLegend = false;
  chartDataPccs: any;
  chartType = ChartType.Attainment;
  pcgTooltipDimension = { width: 175, height: 200 };
  pccTooltipDimension = { width: 250, height: 250 };
  determinerTipOrientation = TooltipOrientation.Right;
  determinersText = "This is a 0% weightage determiner bucket and therefore there wouldn't be any payout assocaited with this bucket";
  constructor(
    private viewHelperService: AttainmentByBucketViewHelperService,
    private reportsService: ReportsService,
    private legendService: LegendService,
    public loggingService: LoggerService,
    private exportToExcelServiceNew: ExportToExcelService
  ) {}

  ngOnInit() {
    this.loggingService.trackPageView('Report-Attainment By PCG', null);
    this.initPcgChartData();
    this.initPccChartData();
    this.initPcgGridData();
  }

  private initPcgGridData() {
    this.reportsService.getAttainmentByPCGDetail().subscribe(response => {
      let PCGs = response.PCGs.sort((a, b) => b.Weightage - a.Weightage);
      PCGs = PCGs.filter((pcg, i, pcgs) => pcgs.findIndex(p => p.Name === pcg.Name) === i);

      this.populatePcgGridData(PCGs);
    });
  }

  private populatePcgGridData(PCGs: any) {
    PCGs.forEach(pcg => {
      const pcgGridData = [];
      pcg.PlanComponentCombinations.forEach((pcc, index) => {
        const row = this.viewHelperService.getGridRowData(pcg, pcc, index);
        if (row !== null) {
          pcgGridData.push(row);
        }
      });

      this.gridData.push({
        pcgName: pcg.Name,
        weightage: pcg.Weightage,
        isPCGRowExpanded: false,
        columns: this.viewHelperService.getColumnDefs(pcg),
        pcgGridData: pcgGridData
      });
    });
  }

  private initPcgChartData() {
    this.reportsService.getAllPaylines().subscribe(response => {
      this.chartDataPcgs = [];
      const isAllPcgArePrimary = response.Paylines.reduce((a, b) => {
        return { IsPrimary: a.IsPrimary && b.IsPrimary };
      });
      response.Paylines.forEach(payline => {
        this.pcgChartYmax = Math.max(this.pcgChartYmax, payline.ExcellencePayout);
        const pcgName = isAllPcgArePrimary ? payline.PcgName : payline.PcgName + '(Primary)';
        const pcgAttainment = payline.Attainment.toFixed(2);
        const paylineModel = new Payline().deserializeSingle(payline);
        const barChartCss = paylineModel.weightage === 0 ? this.legendService.legendDetails.ocpMetric.cssClass : this.legendService.getCssClass(paylineModel);
        const pcgChartObj = {
          desc: pcgName,
          val: pcgAttainment,
          cssClass: barChartCss,
          toolTipHtml: this.viewHelperService.pcgTooltipHtml(paylineModel),
          a11yText: pcgAttainment + ' percentage for ' + pcgName + ' with ' + this.viewHelperService.getPcgTooltipDesc(paylineModel)
        };

        if (payline.Weightage === 0) {
          this.showOCPLegend = true;
        }
        this.chartDataPcgs.push(pcgChartObj);
      });
    });
  }

  private initPccChartData() {
    let primaryPcg;
    this.reportsService.getTargetSummary().subscribe(response => {
      response?.PlanComponentGroups.forEach(pcg => {
        const Pccs = pcg.PlanComponentCombinations.sort((a, b) => {
          return b.Weightage - a.Weightage;
        });
        Pccs.forEach(pcc => {
          const chartDataPccObj = {
            desc: pcc.Name,
            val: pcc.Metrics.StraightAttainment,
            pcgName: pcg.Name,
            toolTipHtml: this.viewHelperService.pccTooltipHtml(pcc),
            a11yText: pcc.Metrics.StraightAttainment + ' percentage for ' + pcc.Name + ' with ' + this.viewHelperService.getPccTooltipDesc(pcc)
          };
          this.pccDataList.push(chartDataPccObj);
        });
        if (!primaryPcg && pcg.MetricType === 'Primary') {
          primaryPcg = { desc: pcg.Name };
        }
      });
      // to select first pcg if primary doesn't exists
      primaryPcg = primaryPcg ? primaryPcg : { desc: response?.PlanComponentGroups[0]?.Name };
      this.filterSelectedPcg(primaryPcg);
    });
  }

  public filterSelectedPcg(pcg) {
    this.selectedPcg = pcg;
    this.chartDataPccs = this.pccDataList.filter(pcc => pcc.pcgName === pcg.desc);
  }

  public toggleExpandAll() {
    this.isExpandAll = !this.isExpandAll;
    this.gridData.forEach(pcg => {
      pcg.isPCGRowExpanded = this.isExpandAll;
    });
  }

  public onExcelExport() {
    this.showDownloadProgressLoader = true;
    this.exportToExcelLabel = 'Downloading...';
    const fileName = this.exportToExcelServiceNew.getFileName('AttainmentByBucket', true);
    this.reportsService.exportAttainmentByPCGDetail().subscribe(
      response => {
        this.exportToExcelServiceNew.downloadOpenFile(response, fileName);
        this.showDownloadProgressLoader = false;
        this.exportToExcelLabel = '';
      },
      err => {
        this.showDownloadProgressLoader = false;
        this.exportToExcelLabel = 'Download Failed';
      }
    );
  }
}
