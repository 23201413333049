<div>
  <div class="myteam__filteritem">
    <div class="myteam__filteritem__text">Filtered By:</div>
    <div class="myteam__filteritem__filterbox" *ngIf="isSignedPPA" tabindex="0" aria-label="Signed PPA">
      <div class="myteam__filteritem__filterbox__innertext">Signed PPA</div>
      <div class="myteam__filteritem__filterbox__closeicon" id="filter_signed_ppa1" tabindex="0" (click)="onFilterCloseIcon($event)" (keyup.enter)="onFilterCloseIcon($event)">
        <svg id="filter_signed_ppa2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.5332 5L9.57617 9.04883L9.04883 9.57617L5 5.5332L0.951172 9.57617L0.423828 9.04883L4.4668 5L0.423828 0.951172L0.951172 0.423828L5 4.4668L9.04883 0.423828L9.57617 0.951172L5.5332 5Z"
            fill="#171717"
          />
        </svg>
      </div>
    </div>
    <div class="myteam__filteritem__filterbox" *ngIf="isUnsignedPPA" tabindex="0" aria-label="Unsigned PPA">
      <div class="myteam__filteritem__filterbox__innertext">Unsigned PPA</div>
      <div class="myteam__filteritem__filterbox__closeicon" id="filter_unsigned_ppa1" tabindex="0" (click)="onFilterCloseIcon($event)" (keyup.enter)="onFilterCloseIcon($event)">
        <svg id="filter_unsigned_ppa2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.5332 5L9.57617 9.04883L9.04883 9.57617L5 5.5332L0.951172 9.57617L0.423828 9.04883L4.4668 5L0.423828 0.951172L0.951172 0.423828L5 4.4668L9.04883 0.423828L9.57617 0.951172L5.5332 5Z"
            fill="#171717"
          />
        </svg>
      </div>
    </div>

    <div class="myteam__filteritem__filterbox" *ngIf="isSignedQA" tabindex="0" aria-label="Signed QA/TA">
      <div class="myteam__filteritem__filterbox__innertext">Signed QA/TA</div>
      <div id="filter_signed_ta1" class="myteam__filteritem__filterbox__closeicon" tabindex="0" (click)="onFilterCloseIcon($event)" (keyup.enter)="onFilterCloseIcon($event)">
        <svg id="filter_signed_ta2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.5332 5L9.57617 9.04883L9.04883 9.57617L5 5.5332L0.951172 9.57617L0.423828 9.04883L4.4668 5L0.423828 0.951172L0.951172 0.423828L5 4.4668L9.04883 0.423828L9.57617 0.951172L5.5332 5Z"
            fill="#171717"
          />
        </svg>
      </div>
    </div>
    <div class="myteam__filteritem__filterbox" *ngIf="isUnsignedQA" tabindex="0" aria-label="Unsigned QA/TA">
      <div class="myteam__filteritem__filterbox__innertext">Unsigned QA/TA</div>
      <div class="myteam__filteritem__filterbox__closeicon" id="filter_unsigned_ta1" tabindex="0" (click)="onFilterCloseIcon($event)" (keyup.enter)="onFilterCloseIcon($event)">
        <svg id="filter_unsigned_ta2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.5332 5L9.57617 9.04883L9.04883 9.57617L5 5.5332L0.951172 9.57617L0.423828 9.04883L4.4668 5L0.423828 0.951172L0.951172 0.423828L5 4.4668L9.04883 0.423828L9.57617 0.951172L5.5332 5Z"
            fill="#171717"
          />
        </svg>
      </div>
    </div>

    <div class="myteam__filteritem__filterbox" *ngIf="isUtilizationGreater" tabindex="0" aria-label="Utilization greater than hundred percentage">
      <div class="myteam__filteritem__filterbox__innertext">Utilization &gt; 100%</div>
      <div class="myteam__filteritem__filterbox__closeicon" id="filter_utilization_gt1" tabindex="0" (click)="onFilterCloseIcon($event)" (keyup.enter)="onFilterCloseIcon($event)">
        <svg id="filter_utilization_gt2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.5332 5L9.57617 9.04883L9.04883 9.57617L5 5.5332L0.951172 9.57617L0.423828 9.04883L4.4668 5L0.423828 0.951172L0.951172 0.423828L5 4.4668L9.04883 0.423828L9.57617 0.951172L5.5332 5Z"
            fill="#171717"
          />
        </svg>
      </div>
    </div>
    <div class="myteam__filteritem__filterbox" *ngIf="isUtilizationLess" tabindex="0" aria-label="Utilization lesser than hundred percentage">
      <div class="myteam__filteritem__filterbox__innertext">Utilization &lt; 100%</div>
      <div class="myteam__filteritem__filterbox__closeicon" id="filter_utilization_lt1" tabindex="0" (click)="onFilterCloseIcon($event)" (keyup.enter)="onFilterCloseIcon($event)">
        <svg id="filter_utilization_lt2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.5332 5L9.57617 9.04883L9.04883 9.57617L5 5.5332L0.951172 9.57617L0.423828 9.04883L4.4668 5L0.423828 0.951172L0.951172 0.423828L5 4.4668L9.04883 0.423828L9.57617 0.951172L5.5332 5Z"
            fill="#171717"
          />
        </svg>
      </div>
    </div>
    <div class="myteam__filteritem" *ngIf="isClearAll" tabindex="0" aria-label="Clear All">
      <span class="myteam__filteritem__filterbox__clearAll" role="button" (click)="onClearAllClicked()" (keyup.enter)="onClearAllClicked()" tabindex="0" aria-label="Clear All">Clear All</span>
    </div>
  </div>
  <div class="myteam__overfilter">
    <div class="myteam__overfilter__filterbtn" role="button" (click)="onFilterClicked()" tabindex="0" aria-label="Advance Filters" (clickOutside)="closeDropdown()" (keyup.enter)="onFilterClicked()">
      <!-- <span class="myteam__overfilter__filterbtn__icon icon-filter"></span> -->
      <svg class="myteam__overfilter__filterbtn__icon" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 0V1.71094L10 7.71094V14H6V7.71094L0 1.71094V0H16ZM15 1H1V1.28906L7 7.28906V13H9V7.28906L15 1.28906V1Z" fill="#171717" />
      </svg>
    </div>

    <div class="myteam__overfilter__filterbtn" tabindex="0" role="button" aria-label="Sort Results" (click)="onShortingClicked()" (keyup.enter)="onShortingClicked()">
      <!-- <span class="myteam__overfilter__filterbtn__icon icon-filter"></span> -->
      <svg class="myteam__overfilter__filterbtn__icon" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5 7H12.5L10 12H12.5V13H8.5L11 8H8.5V7ZM3 11.7969L4.27344 10.5234L4.97656 11.2266L2.5 13.7109L0.0234375 11.2266L0.726562 10.5234L2 11.7969V0H3V11.7969ZM9.33594 5L9 6H8L10 0H11L13 6H12L11.6641 5H9.33594ZM10.5 1.5L9.66406 4H11.3359L10.5 1.5Z"
          fill="#171717"
        />
      </svg>
    </div>

    <div class="myteam__filter">
      <mint-multi-select-dropdown
        [placeholder]="'All'"
        [data]="roles"
        [(selectedItems)]="selectedRoles"
        (selectChange)="onSelectRole($event)"
        (selectAllChange)="onSelectAllRoles($event)"
      ></mint-multi-select-dropdown>
    </div>
  </div>
</div>

<div class="myteam__overfilterdropdown" *ngIf="isFilterLoaded">
  <div class="myteam__filterdropdown">
    <ul>
      <li id="unsignedPPA" tabindex="0" (click)="onFilterSelected($event)" (keyup.enter)="onFilterSelected($event)">
        <svg *ngIf="isUnsignedPPA" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3516 1.35156L5 9.71094L0.648438 5.35156L1.35156 4.64844L5 8.28906L12.6484 0.648438L13.3516 1.35156Z" fill="#171717" />
        </svg>

        <svg
          [ngClass]="isUnsignedPPA ? 'myteam__filterdropdown__marginleft11' : 'myteam__filterdropdown__marginleft26'"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.2109 13.5L14.9766 15.2734L14.2734 15.9766L12.5 14.2109L10.7266 15.9844L10.0234 15.2734L11.7969 13.5L10.0234 11.7266L10.7266 11.0234L12.5 12.7969L14.2734 11.0234L14.9766 11.7344L13.2109 13.5ZM1 15H9V16H0V0H8.71094L13 4.28906V10H12V5H8V1H1V15ZM9 4H11.2891L9 1.71094V4Z"
            fill="#0078D7"
          />
        </svg>
        Unsigned PPA
      </li>
      <li id="signedPPA" tabindex="0" (click)="onFilterSelected($event)" (keyup.enter)="onFilterSelected($event)">
        <svg *ngIf="isSignedPPA" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3516 1.35156L5 9.71094L0.648438 5.35156L1.35156 4.64844L5 8.28906L12.6484 0.648438L13.3516 1.35156Z" fill="#171717" />
        </svg>
        <svg
          [ngClass]="isSignedPPA ? 'myteam__filterdropdown__marginleft11' : 'myteam__filterdropdown__marginleft26'"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.2109 13.5L14.9766 15.2734L14.2734 15.9766L12.5 14.2109L10.7266 15.9844L10.0234 15.2734L11.7969 13.5L10.0234 11.7266L10.7266 11.0234L12.5 12.7969L14.2734 11.0234L14.9766 11.7344L13.2109 13.5ZM1 15H9V16H0V0H8.71094L13 4.28906V10H12V5H8V1H1V15ZM9 4H11.2891L9 1.71094V4Z"
            fill="#0078D7"
          />
        </svg>
        Signed PPA
      </li>
      <div class="myteam__filterdropdown__line"></div>
      <li id="unsignedQA" tabindex="0" (click)="onFilterSelected($event)" (keyup.enter)="onFilterSelected($event)">
        <svg *ngIf="isUnsignedQA" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3516 1.35156L5 9.71094L0.648438 5.35156L1.35156 4.64844L5 8.28906L12.6484 0.648438L13.3516 1.35156Z" fill="#171717" />
        </svg>
        <svg
          [ngClass]="isUnsignedQA ? 'myteam__filterdropdown__marginleft11' : 'myteam__filterdropdown__marginleft26'"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.2109 13.5L14.9766 15.2734L14.2734 15.9766L12.5 14.2109L10.7266 15.9844L10.0234 15.2734L11.7969 13.5L10.0234 11.7266L10.7266 11.0234L12.5 12.7969L14.2734 11.0234L14.9766 11.7344L13.2109 13.5ZM1 15H9V16H0V0H8.71094L13 4.28906V10H12V5H8V1H1V15ZM9 4H11.2891L9 1.71094V4Z"
            fill="#0078D7"
          />
        </svg>
        Unsigned QA/TA
      </li>
      <li id="signedQA" tabindex="0" (click)="onFilterSelected($event)" (keyup.enter)="onFilterSelected($event)">
        <svg *ngIf="isSignedQA" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3516 1.35156L5 9.71094L0.648438 5.35156L1.35156 4.64844L5 8.28906L12.6484 0.648438L13.3516 1.35156Z" fill="#171717" />
        </svg>
        <svg
          [ngClass]="isSignedQA ? 'myteam__filterdropdown__marginleft11' : 'myteam__filterdropdown__marginleft26'"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.2109 13.5L14.9766 15.2734L14.2734 15.9766L12.5 14.2109L10.7266 15.9844L10.0234 15.2734L11.7969 13.5L10.0234 11.7266L10.7266 11.0234L12.5 12.7969L14.2734 11.0234L14.9766 11.7344L13.2109 13.5ZM1 15H9V16H0V0H8.71094L13 4.28906V10H12V5H8V1H1V15ZM9 4H11.2891L9 1.71094V4Z"
            fill="#0078D7"
          />
        </svg>
        Signed QA/TA
      </li>
      <div class="myteam__filterdropdown__line"></div>
      <li id="utilizationLess" tabindex="0" (click)="onFilterSelected($event)" (keyup.enter)="onFilterSelected($event)">
        <svg *ngIf="isUtilizationLess" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3516 1.35156L5 9.71094L0.648438 5.35156L1.35156 4.64844L5 8.28906L12.6484 0.648438L13.3516 1.35156Z" fill="#171717" />
        </svg>
        <svg
          [ngClass]="isUtilizationLess ? 'myteam__filterdropdown__marginleft11' : 'myteam__filterdropdown__marginleft26'"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.2109 13.5L14.9766 15.2734L14.2734 15.9766L12.5 14.2109L10.7266 15.9844L10.0234 15.2734L11.7969 13.5L10.0234 11.7266L10.7266 11.0234L12.5 12.7969L14.2734 11.0234L14.9766 11.7344L13.2109 13.5ZM1 15H9V16H0V0H8.71094L13 4.28906V10H12V5H8V1H1V15ZM9 4H11.2891L9 1.71094V4Z"
            fill="#0078D7"
          />
        </svg>
        Utilization &lt; 100%
      </li>
      <li id="utilizationGreater" tabindex="0" (click)="onFilterSelected($event)" (keyup.enter)="onFilterSelected($event)">
        <svg *ngIf="isUtilizationGreater" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3516 1.35156L5 9.71094L0.648438 5.35156L1.35156 4.64844L5 8.28906L12.6484 0.648438L13.3516 1.35156Z" fill="#171717" />
        </svg>
        <svg
          [ngClass]="isUtilizationGreater ? 'myteam__filterdropdown__marginleft11' : 'myteam__filterdropdown__marginleft26'"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.2109 13.5L14.9766 15.2734L14.2734 15.9766L12.5 14.2109L10.7266 15.9844L10.0234 15.2734L11.7969 13.5L10.0234 11.7266L10.7266 11.0234L12.5 12.7969L14.2734 11.0234L14.9766 11.7344L13.2109 13.5ZM1 15H9V16H0V0H8.71094L13 4.28906V10H12V5H8V1H1V15ZM9 4H11.2891L9 1.71094V4Z"
            fill="#0078D7"
          />
        </svg>
        Utilization &gt; 100%
      </li>
    </ul>
  </div>
</div>
