import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '@mint-libs/network';

@Injectable()
export class NotificationServiceFactory {
  constructor(private http: HttpClient, private localStorage: LocalStorageService) {}

  private FileName = 'UserNotifications.txt';

  public MarkAsAllRead(data) {
    this.localStorage.addToLocalStorage(this.FileName, JSON.stringify(data));
  }

  //This method compares the respose from the service and contents in the file
  //if both match it would set the flag "IsNotificationRead" to "true" so all the messages would be displayed as read
  //if any object does not match it sets the flag to false to display it on UI as unread message and pushes
  //the content to file.
  private VerifyMessages(svcUserNotification, fileContents, markAsRead) {
    var localMessages: any[] = JSON.parse(fileContents) ?? [];
    if (localMessages.findIndex(messages => messages.UserAlias == svcUserNotification.UserAlias) > -1) {
      return this.CheckUserMessages(localMessages, svcUserNotification);
    } else if (markAsRead) {
      localMessages.push(this.UserMessageInfo(svcUserNotification));
      this.localStorage.addToLocalStorage(this.FileName, JSON.stringify(localMessages));
      return svcUserNotification;
    } else {
      return svcUserNotification;
    }
  }

  private UserMessageInfo(svcMessages) {
    return {
      UserAlias: svcMessages.UserAlias,
      MessageIds: this.ServiceSetIds(svcMessages.Notifications).join()
    };
  }

  private CheckUserMessages(localMessages, svcMessages) {
    var serviceSet = this.ServiceSetIds(svcMessages.Notifications);
    for (const message of localMessages) {
      if (message.UserAlias == svcMessages.UserAlias) {
        var localSet: any[] = this.localSetIds(message.MessageIds);
        // localSet.d
        var unreadMessages = this.difference(serviceSet, localSet); //  _.difference(serviceSet, localSet);

        for (const _notification of svcMessages.Notifications) {
          const result = localSet.find(notificationId => notificationId == _notification.NotificationId);
          if (result != undefined) {
            _notification.IsNotificationRead = true;
          }
        }
        message.MessageIds = localSet.concat(localSet, unreadMessages).join();
        this.localStorage.addToLocalStorage(this.FileName, JSON.stringify(localMessages));
      }
    }
    return svcMessages;
  }

  private difference = (array1, array2) => array1.filter(item1 => !array2.some(item2 => item2 === item1));

  public ReadLocalMessages(userNotificationInfo, markAsRead) {
    var arr: any = [];
    const fileContents = this.localStorage.getValueFromLocalStorage(this.FileName);
    if (fileContents == '' && markAsRead == true) {
      arr.push(this.UserMessageInfo(userNotificationInfo));
      this.localStorage.addToLocalStorage(this.FileName, JSON.stringify(arr));
      return arr;
    } else {
      return this.VerifyMessages(userNotificationInfo, fileContents, markAsRead);
    }
  }

  private localSetIds(fileContents) {
    return fileContents.split(',').map(Number);
  }
  private ServiceSetIds(svcMessages: any) {
    const setIds: any = [];
    for (const message of svcMessages) {
      setIds.push(message.NotificationId);
    }

    return setIds;
  }

  public getJSON(): Observable<any> {
    return this.http.get(this.FileName);
  }

  public ReadLocalMessage(response, markAsRead) {
    return this.ReadLocalMessages(response, markAsRead);

    // .create((observer) => {
    //   observer.next(1);
    //   observer.next(2);
    //   observer.next(3);
    //   setTimeout(() => observer.next(4), 1000);
    // });
    // return this.ReadLocalMessages(response, markAsRead);
  }
}
