import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mint-tabs',
  templateUrl: './mint-tabs.component.html',
  styleUrls: ['./mint-tabs.component.scss']
})
export class MintTabsComponent implements OnInit {

  @Input() tabs: any;
  @Input() activeId: any;
  @Output() tabChange = new EventEmitter<any>();
  @Input() tracker: string;
  @Input() source: string;
  constructor() { }

  ngOnInit() {
  }

  emmitTabChangeEvent(event) {
    this.tabChange.emit(event);
  }
}
