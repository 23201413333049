import { Component, Inject, OnInit } from '@angular/core';
import { DetailSummaryTilesService } from '../detail-summary-tiles.service';
import * as _ from 'underscore';
import { DetailDropdownSelectedItem } from 'src/app/shared/detail-dropdown/models/detail-dropdown-selected-item';
import { UserProfileService } from '../../../core/context/user-profile.service';
import { SharedService } from '../../../shared/shared.service';
import * as Ng1Services from '../../../core/hybrid/ng1-upgraded-providers';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.scss']
})
export class AccountSummaryComponent implements OnInit {
  public sharedContext = new SharedContext();
  constructor(
    private detailSummaryTilesService: DetailSummaryTilesService,
    public userProfileService: UserProfileService,
    private sharedService: SharedService,
    public sharedContextState: Store<SharedContext>
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  itemCaption = 'Metric';
  subItemCaption = 'Available Account Type';
  dropDownItems;
  selectedItem;
  selectedSubItem;
  previousSelectedItem;
  previousSelectedSubItem;

  reportType = 'Account';
  chartHelpText = 'Lists all Accounts in your plan by revenue';

  noData = false;
  errorOccurred = false;
  lastRefreshDate: Date = null;
  territoriesDataset: any = null;

  graphData: any;
  loaded = false;

  groupedData = [];
  showLegends = false;
  isADXResponsse = false;
  IsDynamicsDisclaimerVisible = false;
  IsDmyDltrAvailable = false;

  ngOnInit() {
    this.detailSummaryTilesService.getPCCInfoForDetailReports(this.reportType).subscribe(resultPCC => {
      this.dropDownItems = resultPCC
        .filter(pcc => {
          return !pcc?.IsDynamicMetric;
        })
        .map(pccItem => {
          return {
            text: pccItem.Name,
            subItems: pccItem.ApplicableTypeDimensions
          };
        });

      // ToDo: These values need to be set as part of user preferences
      // below two properties will be returned as part of user preferences stored in PE DB
      if (this.dropDownItems && this.dropDownItems.length > 0) {
        this.previousSelectedItem = this.selectedItem = this.dropDownItems[0].text;
        if (this.dropDownItems[0].subItems && this.dropDownItems[0].subItems.length > 0) {
          this.previousSelectedSubItem = this.selectedSubItem = this.dropDownItems[0].subItems[0];
        }
      }
      this.getRevenuebyAccountSummary({ selectedPCC: this.selectedItem, selectedDimension: this.selectedSubItem });
    });

    this.IsDynamicsDisclaimerVisible = this.sharedContext.IsDMYEligible;
  }

  onApplyFilter(selectedItem: DetailDropdownSelectedItem) {
    this.loaded = false;
    if (this.previousSelectedItem !== selectedItem.item) {
      this.getRevenuebyAccountSummary({ selectedPCC: selectedItem.item, selectedDimension: selectedItem.subItem });
    } else if (this.previousSelectedSubItem !== selectedItem.subItem) {
      this.groupByTypeForGraph(selectedItem.subItem);
    }

    this.previousSelectedItem = selectedItem.item;
    this.previousSelectedSubItem = selectedItem.subItem;
  }

  getRevenuebyAccountSummary(selection: any) {
    this.detailSummaryTilesService.getRevenueByAccount(selection.selectedPCC).subscribe(result => {
      this.showLegends = result.ShowLegends;
      this.isADXResponsse = result.ISADX;

      this.groupedData = this.grouping(result.data, 'AccountName');
      this.groupByTypeForGraph(selection.selectedDimension);
    });
  }

  groupByTypeForGraph(selectedDimension) {
    const dimName = selectedDimension.replace(' ID', '').toLowerCase();
    this.graphData = this.groupedData.filter(item => item.Type.trim().toLowerCase() === dimName);
    console.log('graph data ', this.graphData);
    this.graphData = this.graphData.map(item => {
      const obj = {};
      obj['category'] = item.label;
      obj['type1'] = item.totalActualsValue == null || item.totalActualsValue === undefined || item.totalActualsValue < 0 ? 0 : item.totalActualsValue;
      if (item.hasCustomerTag) {
        obj['type2'] = obj['type1'];
        obj['type1'] = 0;
      } else {
        obj['type2'] = 0;
      }
      obj['toolTipData'] = {
        metric: item.metric,
        totalTarget: item.totalTarget,
        totalActual: item.actualsValue,
        actualAdjustmentAmount: item.actualAdjustmentAmount,
        actualModifier: item.actualModifier,
        totalRevenue: item.totalActualsValue,
        hasCustomerTag: item.hasCustomerTag
      };

      return obj;
    });
    console.log('graph data 2', this.graphData);
    this.graphData.sort((a, b) => b.type1 - a.type1);
    this.loaded = true;
  }

  uniques(array) {
    // return [for (x of new Set(array)) x];
  }

  grouping(data, name) {
    const groupByType = _.groupBy(data, function(item) {
      return item.Type;
    });
    let dataset = [];
    _.each(_.toArray(groupByType), function(oneType) {
      const groupByName = _.groupBy(oneType, function(item) {
        return item[name];
      });
      const tempDataset = _.map(_.toArray(groupByName), function(item) {
        const label = item[0][name];
        const type = item[0].Type;
        const metric = item[0].Revenue.Metric;
        const hasCustomerTag = item[0].HasCustomerTag;

        let actualsValue = 0;
        let totalActualsValue = 0;
        let actualAdjustmentAmount = 0;
        let actualModifier = 0;
        let qvalue = 0;

        _.each(item, function(x) {
          actualsValue += x.Revenue.ActualAmount;
          actualAdjustmentAmount += x.Revenue.ActualAdjustmentAmount;
          actualModifier += x.Revenue.ActualModifier;
          totalActualsValue += x.Revenue.TotalActual;
          qvalue = qvalue + x.Revenue.TotalTarget;
        });
        return {
          label: label,
          Type: type,
          metric: metric,
          qvalue: qvalue,
          totalTarget: qvalue,
          actualsValue: actualsValue,
          actualAdjustmentAmount: actualAdjustmentAmount,
          actualModifier: actualModifier,
          totalActualsValue: totalActualsValue,
          hasCustomerTag: hasCustomerTag
        };
      });
      dataset = dataset.concat(tempDataset);
    });
    return dataset;
  }

  navigateToDynamicsAccountReport() {
    this.sharedService.ngViewReport({
      DetailView: 'accountDynamicsCommittedGrowth'
    });
  }
}
