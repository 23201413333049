<!-- <mint-content-loader-grid *ngIf="!isLoaded"></mint-content-loader-grid> -->
<!-- <div *ngIf="isLoaded" id="metric-grid_1" class="grid-display"> -->
<div *ngIf="isLoaded" class="pcg__header">
  <div class="pcg__header__txt">Priority Solution Target Attainment</div> 
</div>
<br />

<div id="metric-grid_254" style=" overflow: hidden; flex-grow: 1; margin-bottom: 30px;" *ngIf="rowData != undefined">
  <ag-grid-angular
    id="cagrgrid"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    [frameworkComponents]="frameworkComponents"
    [defaultColDef]="defaultColDef"
    [domLayout]="domLayout"
  >
  </ag-grid-angular>
</div>

<br />
<br />
<div class="pcg__header">
  <div class="pcg__header__txt">Priorities+ Excellence Bonus</div>
  
</div>
<br />
<div id="metric-grid_234" style=" overflow: hidden; flex-grow: 1">
  <ag-grid-angular
    id="cagrgridtotal"
    class="ag-theme-balham"
    [rowData]="rowData1"
    [columnDefs]="columnDefsBingoGrid"
    [gridOptions]="gridOptions"
    [frameworkComponents]="frameworkComponents"
    [defaultColDef]="defaultColDef"
    [domLayout]="domLayout"
  >
  </ag-grid-angular>
</div>
<!-- </div> -->
