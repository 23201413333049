import { Component, OnInit, Input, Inject } from '@angular/core';
import { Direct } from '../models/direct';
import { ManagerDashboardHelper } from '../manager-dashboard.helper';
import { FiltersService } from '../../../filters/filters.service';

@Component({
  selector: 'mint-azure-incentives-metric',
  templateUrl: './azure-incentive-metric.component.html',
  styleUrls: ['./azure-incentive-metric.component.scss']
})
export class AzureIncentivesMetricComponent implements OnInit {
  @Input() direct: Direct;

  slideConfig;

  quarterText: string;

  constructor(private managerDashboardHelper: ManagerDashboardHelper,
    private filterService: FiltersService) {
    this.slideConfig = this.managerDashboardHelper.getAzureIncentivesSlickConfig();
    this.quarterText = `Q${this.filterService.selectedStepId} Discrete ACR`;
  }

  ngOnInit() {}
}
