import { ActivatedRoute, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { Component, ViewChild } from '@angular/core';
import { SidebarService } from '../layout/sidebar/user-sidebar/sidebar.service';

@Component({
  template: ''
})
export class BaseContainerComponent {
  title: string;
  // sidebarItemList: SidebarItem[];
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = false;

  constructor(public sidebarService: SidebarService, protected activeRoute: ActivatedRoute) {}

  openSideNav(data: string) {
    this.toggleSideNav();
  }

  toggleSideNav() {
    this.sidebarService.isSidebarActive = !this.sidebarService.isSidebarActive;
    const elm = document.getElementById('hamburger-menu');
    elm.setAttribute('aria-label', 'Navigation button ' + (this.sidebarService.isSidebarActive ? 'expanded' : 'collapsed'));
  }

  changeOfRoutes() {
    this.activeRoute.firstChild.data.subscribe(t => (this.title = t['title']));
  }

  getActiveUrl(activatedRoute: ActivatedRoute) {
    return activatedRoute.snapshot['_routerState'].url;
  }
}
