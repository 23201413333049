import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from '@mint-libs/network';
import { Store } from '@ngrx/store';
import { from } from 'rxjs/internal/observable/from';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { ParticipantDetails, selectAllParticipantsState, loadAllParticipants } from '@mint-libs/context';
import { AuthenticationService } from '@mint-libs/authentication';
import { CommonFactory } from '@mint-libs/common';
import { ImpersonationService } from 'src/app/core/impersonation/impersonation.service';
import { GraphService } from '@mint-libs/context';
import { NavigationService } from 'src/app/core/navigation/navigation.service';

@Component({
  selector: 'mint-impersonation',
  templateUrl: './impersonation.component.html',
  styleUrls: ['./impersonation.component.scss']
})
export class ImpersonationComponent implements OnInit {
  userInfoList: [];
  slideConfig: any;
  userProfileInfo: any;
  impersonationForm: FormGroup;
  search = '';
  dataList = '';
  isLoaded = false;
  isViewAsEnabled = false;
  buttonText = 'Impersonate';
  isChecked = false;
  public sharedContext = new SharedContext();

  constructor(
    private localStorage: LocalStorageService,
    private authService: AuthenticationService,
    private impersonationService: ImpersonationService,
    private commonFactory: CommonFactory,
    private sharedContextState: Store<SharedContext>,
    private router: Router,
    private formBuilder: FormBuilder,
    private allParticipantsState: Store<ParticipantDetails>,
    private graphService: GraphService,
    private navigationService: NavigationService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => (this.sharedContext = sharedContext));
    this.slideConfig = this.getSlickSlideConfig();
  }

  ngOnInit() {
    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        pageTitle: 'Impersonation'
      })
    );
    const list = this.localStorage.getValueFromLocalStorage(this.sharedContext.fileName);
    if (list === undefined || list === null) {
      this.userInfoList = [];
    } else {
      this.userInfoList = JSON.parse(list);
      if (this.userInfoList.length > 5) {
        this.slideConfig.centerMode = false;
      }
    }
    this.userProfileInfo = this.authService.getUserInfo();
    if (this.userProfileInfo != null) {
      const alias = this.userProfileInfo.userName.split('@')[0];
      this.graphService
        .getThumbnailPhoto(alias)
        .toPromise()
        .then(x => {
          this.userProfileInfo.thumbnailPhoto = x;
        });
    }
    this.impersonationForm = this.formBuilder.group({
      impersonatedAlias: [this.userInfoList.length === 0 ? null : '', [Validators.required]]
    });
    this.getAllParticipants();
  }

  getAllParticipants() {
    this.impersonationForm.disable();
    this.allParticipantsState.dispatch(loadAllParticipants(null));
    this.allParticipantsState.select(selectAllParticipantsState).subscribe(participantList => {
      if (!participantList) {
        return;
      }
      this.dataList = JSON.parse(JSON.stringify(participantList.participantDetails));
      this.isLoaded = true;
      if (this.impersonationForm) {
        this.impersonationForm.enable();
      }
    });
  }

  getSlickSlideConfig() {
    return {
      slidesToShow: 5,
      slidesToScroll: 5,
      infinite: false,
      dots: false,
      accessibility: false,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1310,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1260,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1210,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1160,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1110,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        }
      ]
    };
  }

  selectedTempStatic(item) {
    this.search = item.userAlias;
    this.startImpersonationByClicking(item.userAlias);
  }

  onTextChange($event) {
    if ($event.target.value && $event.target.value.length) {
      this.impersonationForm.enable();
    }
  }

  toggleAdminModes() {
    this.isViewAsEnabled = !this.isViewAsEnabled;
    this.isChecked = !this.isChecked;
    this.buttonText = this.isViewAsEnabled ? 'View As' : 'Impersonate';
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  dragStart() {
    console.log('clicked in');
  }
  dragEnd() {
    console.log('clicked out');
  }

  onImpersonate() {
    let x = this.impersonationForm.value['impersonatedAlias'];
    if (x.indexOf('(') !== -1) {
      x = this.commonFactory.formatSmartSearchInput(x);
    }

    if (x.length > 0) {
      this.impersonationService.startImpersonation(x, x, false, null, null, null, this.isViewAsEnabled).then(result => {
        // this.impersonationService.redi;
      });
    }
  }

  startImpersonationByClicking(alias) {
    this.impersonationForm.value['impersonatedAlias'] = alias;
    this.onImpersonate();
  }
}
