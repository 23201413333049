import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { DirectComponent } from './direct/direct.component';
import { DirectDetailComponent } from './direct-detail/direct-detail.component';
import { MetricAttainmentComponent } from './metric-attainment/metric-attainment.component';
import { ManagerDashboardComponent } from './manager-dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilizationMetricAttainmentComponent } from './utilization-metric-attainment/utilization-metric-attainment.component';
import { SoftCapComponent } from './soft-cap/soft-cap.component';
import { QuotaToGoComponent } from './quota-to-go/quota-to-go.component';
import { MetricSoftCapComponent } from './soft-cap/metric-soft-cap/metric-soft-cap.component';
import { FeedbackModule } from '../../feedback/feedback.module';
import { SharedModule } from '../../shared/shared.module';
import { CustomHeaderComponent } from '../../shared/grid-components/custom-header/custom-header.component';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PccMetricAttainmentComponent } from './pcc-metric-attainment/pcc-metric-attainment.component';
import { AvatarModule } from 'ngx-avatar';
import { ManagerDashboardHelper } from './manager-dashboard.helper';
import { ManagerDashboardService } from './manager-dashboard.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AzureIncentivesComponent } from './azure-incentives/azure-incetives.component';
import { AzureIncentivesMetricComponent } from './azure-incentives-metric/azure-incentive-metric.component';
import { ManagerDashboardExportComponent } from './manager-dashboard-export/manager-dashboard-export';
import { UBIMetricAttainmentComponent } from './ubi-metric-attainment/ubi-metric-attainment.component';
import { UBIManagerAdvanceFilterComponent } from './ubi-advance-filter/ubi-advance-filter.component';
import { MyTeamTilesComponent } from './myteam-tiles/myteam-tiles.component';
import { ManagerDashboardReportComponent } from './manager-dashboard-report/manager-dashboard-report.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { FiltersModule } from '../../filters/filters.module';

// accessibility fix as default colors didn't had required contrast ratio
const avatarColors = ['#FF4D64', '#2C3E50', '#7A8F90', '#CE8209', '#17A689'];
@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    FeedbackModule,
    SharedModule,
    AgGridModule.withComponents([CustomHeaderComponent]),
    ContentLoaderModule,
    SlickCarouselModule,
    AvatarModule.forRoot({
      colors: avatarColors
    }),
    NgMultiSelectDropDownModule.forRoot(),
    ClickOutsideModule,
    FiltersModule
  ],
  exports: [ManagerDashboardComponent],
  declarations: [
    ManagerDashboardComponent,
    DirectComponent,
    MetricAttainmentComponent,
    UBIMetricAttainmentComponent,
    PccMetricAttainmentComponent,
    DirectDetailComponent,
    UtilizationMetricAttainmentComponent,
    AzureIncentivesMetricComponent,
    SoftCapComponent,
    QuotaToGoComponent,
    AzureIncentivesComponent,
    MetricSoftCapComponent,
    ManagerDashboardExportComponent,
    UBIManagerAdvanceFilterComponent,
    MyTeamTilesComponent,
    ManagerDashboardReportComponent
  ],
  providers: [ManagerDashboardService, ManagerDashboardHelper]
})
export class ManagerDashboardModule {}
