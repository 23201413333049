import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';


@Component({
  selector: 'mint-advance-search-filter',
  templateUrl: './advance-search-filter.component.html',
  styleUrls: ['./advance-search-filter.component.scss']
})
export class AdvanceSearchFilterComponent implements OnChanges, OnInit, AfterContentChecked{
  public searchText = '';
  public searchTextChange = new Subject<string>();
  public suggestionDictionary = {} as any;
  public selectedfilters = [];
  public maxFilterSelectionExceeded = false;
  public isAdditionalFilterVisible = false;
  public isSuggestionDropdownVisible = false;
  public isSearching = false;
  @Input() reportType: string;
  @Input() searchSuggestion: any = [];
  @Input() maxDisplaySuggestion = 10;
  @Input() maxFilterSelection = 10;
  @Input() maxDisplayFilter = 10;
  @Input() minCharForSearch = 2;
  @Input() isFilterTabActive = false;
  @Output() search = new EventEmitter<string>();
  @Output() filter = new EventEmitter<any>();
  constructor(private element: ElementRef) {
    this.searchTextChange.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(
      text => {
        this.searchText = text.toLocaleLowerCase();
        // to show existing suggestion if contains the text
        this.searchSuggestion = this.searchSuggestion.filter(f => f.toLocaleLowerCase().includes(this.searchText));
        if (text?.length >= this.minCharForSearch) {
          this.search.emit(this.searchText);
          this.isSearching = true;
        }
        this.checkForDropdownVisibility();
      }
    );
  }

  ngOnInit() {
  }

  ngAfterContentChecked() {
    const ngbTabs = this.element.nativeElement.querySelectorAll('.nav-link');
    ngbTabs.forEach( ele => {
      ele.removeAttribute('href');
      ele.removeAttribute('aria-controls');
      if (ele.getAttribute('aria-disabled') === 'false') {
        ele.setAttribute('tabindex', 0);
      }
    });
  }

  ngOnChanges() {
    this.checkForDropdownVisibility();
    this.isSearching = false;
  }

  onInput(text: string) {
    this.searchTextChange.next(text);
  }

  checkForDropdownVisibility() {
    if (this.searchSuggestion.length >= 0 && this.searchText.length >= this.minCharForSearch) {
      this.isSuggestionDropdownVisible = true;
      return;
    }
    this.isSuggestionDropdownVisible = false;
  }

  checkIfSelected(suggestion) {
    return this.selectedfilters.includes(suggestion);
  }

  closeSuggestionDropdown() {
    this.isSuggestionDropdownVisible = false;
  }

  toggleAdditionalFilter() {
    this.isAdditionalFilterVisible = !this.isAdditionalFilterVisible;
  }

  closeAdditionalFilter() {
    this.isAdditionalFilterVisible = false;
  }

  addFilter(filterText: string) {
    if (this.selectedfilters.length > this.maxFilterSelection) {
      this.maxFilterSelectionExceeded = true;
      return;
    }
    this.isSuggestionDropdownVisible = false;
    this.maxFilterSelectionExceeded = false;
    this.selectedfilters.push(filterText);
    this.filter.emit(this.selectedfilters);
  }

  removeFilter(filterText: string) {
    this.maxFilterSelectionExceeded = false;
    const index = this.selectedfilters.indexOf(filterText);
    this.selectedfilters.splice(index, 1);
    this.filter.emit(this.selectedfilters);
  }
}
