import { Inject, Injectable } from '@angular/core';
import { Location as AngularLocation } from '@angular/common';
import * as _ from 'underscore';
import { LoggerService } from '@mint-libs/logging';
import { environment } from '../../../environments/environment';
import { FiltersService } from 'src/app/filters/filters.service';
import { NavigationService } from '../navigation/navigation.service';
import { UserProfileService } from '../context/user-profile.service';
import { RoleFeatureService } from '@mint-libs/context';
import { Code } from 'src/app/app.constants';
import { GraphService } from '@mint-libs/context';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { env } from 'process';

export interface Redirection {
  url: string;
  clearHistory: boolean;
  nextUrl: string;
  state: any;
}

@Injectable({
  providedIn: 'root'
})
export class InitializationService {
  sharedContext = new SharedContext();
  constructor(
    public sharedContextState: Store<SharedContext>,
    private loggingService: LoggerService,
    private filterService: FiltersService,
    private navigationFactory: NavigationService,
    private userProfile: UserProfileService,
    private rolefeatureService: RoleFeatureService,
    private code: Code,
    private graphService: GraphService,
    private location: AngularLocation
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    if (this.sharedContext.isDemoMode2 !== environment.isDemoMode2) {
      this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, isDemoMode2: environment.isDemoMode2 }));
    }
    // this.sharedContext.environment = environment;
  }

  private isMsSalesAuthorized = false;

  public msSalesAuthorized() {
    return this.isMsSalesAuthorized;
  }

  public showError(err) {
    console.error(err);
    var message = null;
    if (err && err.message) {
      message = err.message;
    } else if (err && err.error && err.error.message) {
      message = err.error.message;
    }
    if (err && err.error && err.error.message && err.error.message == Code.ConfigNotFound) {
      this.navigationFactory.navigateTo('/error/unauthorized', {
        message: message
      });
    } else {
      this.navigationFactory.navigateTo('/error/error', {
        message: message
      });
    }
  }

  public Logger() {
    return this.loggingService;
  }

  public getQASignedStatus(compliance) {
    for (var i = 0; i < compliance.length; i++) {
      if (compliance[i].Name === 'QA') {
        return compliance[i].SignStatusId;
      }
    }
    return null;
  }

  public getPPASignedStatus(compliance) {
    for (var i = 0; i < compliance.length; i++) {
      if (compliance[i].Name === 'PPA') {
        return compliance[i].SignStatusId;
      }
    }
    return null;
  }
}
