import { Component, OnInit, Input } from '@angular/core';
import { PayoutReportService } from '../../../payout-report.service';

@Component({
  selector: 'mint-payout-report-payout-percentage-tile',
  templateUrl: './payout-percentage.component.html',
  styleUrls: ['./payout-percentage.component.scss']
})
export class PayoutPercentageComponent implements OnInit {
  modalRef;
  canShowCurrentAttainment = true;
  @Input() selectedCoreBucket: any;

  constructor(public payoutReportService: PayoutReportService) {}

  ngOnInit() {}

  ngOnChanges() {}
}
