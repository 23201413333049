import { Expose, Type } from 'class-transformer';
import { Disclaimer } from './disclaimer.model';
import { Widget } from './widgets.model';

export class UserSettings {
  // userPreference: UserPreference;
  // role: EmployeeType;
  @Expose() isSupportAdmin: boolean;
  @Expose() isSuperAdmin: boolean;
  @Expose() canSignOnBehalf: boolean;
  @Type(() => Disclaimer)
  @Expose()
  disclaimer: Disclaimer;
  @Expose() paylineViewType: string;
  // userRolePermissions: UserRolePermission[];

  // We don't use widgets from angularjs anymore 
  // (we don't use it from Angular as well but should come from context if needed)
  @Type(() => Widget)
  @Expose()
  widgets: Widget[];
  @Type(() => Widget)
  @Expose()
  widgetsRole: Widget[];

  @Expose() supportedAppVersions: string[];
  @Expose() isEnrolledIntoGroup: boolean;
  @Expose() releaseGroupId: number;
  @Expose() releaseGroupName: string;
}
