import { Injectable } from '@angular/core';
import { Formatter, CustomNumberPipe, PercentagePipe } from '@mint-libs/common';
import { Payline } from '../../shared/models/payline.model';

@Injectable()
export class AttainmentByBucketViewHelperService {
  gridColumns = [
    {
      field: 'PCGAttainment',
      title: 'Bucket Attainment %',
      isVisible: true,
      width: 100
    },
    {
      field: 'PCCName',
      title: 'Metric',
      isVisible: true,
      width: 200
    },
    {
      field: 'PCCAttainment',
      title: 'Metric Attainment %',
      isVisible: true,
      width: 100
    },
    {
      field: 'FiscalMonth',
      title: 'Fiscal Month',
      isVisible: true,
      width: 150
    },
    {
      field: 'TotalTarget',
      title: 'Total Target',
      isVisible: true
    },
    {
      field: 'TotalActuals',
      title: 'Total Actual',
      isVisible: true
    },
    {
      field: 'TotalRecurringTarget',
      title: 'Total Recurring Target or Baseline',
      isVisible: true
    },
    {
      field: 'NonRecurringTarget',
      title: 'Total Target - Recurring Target or Total Target - Baseline',
      isVisible: true
    },
    {
      field: 'NonRecurringActual',
      title: 'Total Actuals - Recurring Target or Total Actuals - Baseline',
      isVisible: true
    },
    {
      field: 'QuotaToGo',
      title: 'Quota To Go',
      isVisible: true
    },
    {
      field: 'Units',
      title: 'Units',
      isVisible: true
    }
  ];
  gridColumnsForDynamics = [
    {
      field: 'PCGAttainment',
      title: 'Bucket Attainment %',
      isVisible: true,
      width: 100
    },
    {
      field: 'PCCName',
      title: 'Metric',
      isVisible: true,
      width: 200
    },
    {
      field: 'PCCAttainment',
      title: 'Metric Attainment %',
      isVisible: true,
      width: 100
    },
    {
      field: 'FiscalMonth',
      title: 'Fiscal Month',
      isVisible: true,
      width: 150
    },
    {
      field: 'TotalTarget',
      title: 'New Commitment Quota',
      isVisible: true
    },
    {
      field: 'TotalActuals',
      title: 'Commitments Landed',
      isVisible: true
    },
    {
      field: 'Units',
      title: 'Units',
      isVisible: true
    }
  ];
  constructor(private formatter: Formatter, private customnumberPipe: CustomNumberPipe, private percentagePipe: PercentagePipe) {}

  public getColumnDefs(pcg) {
    if (pcg.IsDynamicBucket) return this.gridColumnsForDynamics;
    return this.gridColumns;
  }

  getGridRowData(pcg: any, pcc: any, index: number) {
    if (pcg.IsDynamicBucket) return this.getGridRowDataForDynamicMetric(pcg, pcc, index);
    return this.getGridRowDataForGeneralMetric(pcg, pcc, index);
  }
  getGridRowDataForDynamicMetric(pcg: any, pcc: any, index: number) {
    if (pcc.IsQuarterData) return null;

    let quaterPcc = pcg.PlanComponentCombinations.find(p => p.Name === pcc.Name && p.IsQuarterData);
    return {
      isRowHiddenForRowSpan: {
        PCGAttainment: index !== 0,
        PCCAttainment: index !== 0,
        TotalActuals: index !== 0,
        PCCName: index !== 0
      },
      rowspan: {
        PCGAttainment: index === 0 ? pcg.PlanComponentCombinations.length - 1 : 0,
        PCCAttainment: index === 0 ? pcg.PlanComponentCombinations.length - 1 : 0,
        TotalActuals: index === 0 ? pcg.PlanComponentCombinations.length - 1 : 0,
        PCCName: index === 0 ? pcg.PlanComponentCombinations.length - 1 : 0
      },
      data: {
        PCGAttainment: this.percentagePipe.transform(pcg.Metrics.Attainment),
        PCCName: pcc.Name,
        PCCAttainment: this.percentagePipe.transform(quaterPcc.Metrics.Attainment),
        FiscalMonth: pcc.FiscalMonth,
        TotalTarget: this.customnumberPipe.transform(pcc.Metrics.Target),
        TotalActuals: this.customnumberPipe.transform(pcc.Metrics.Actuals),
        QuotaToGo: this.customnumberPipe.transform(pcc.Metrics.QuotaToGo),
        Units: pcc.MeasuringUnits
      }
    };
  }
  getGridRowDataForGeneralMetric(pcg: any, pcc: any, index: number) {
    return {
      cssClasses: [pcc.IsQuarterData ? 'highlight-row' : ''],
      isRowHiddenForRowSpan: {
        PCGAttainment: index !== 0
      },
      rowspan: {
        PCGAttainment: index === 0 ? pcg.PlanComponentCombinations.length : 0
      },
      data: {
        PCGAttainment: this.percentagePipe.transform(pcg.Metrics.Attainment),
        PCCName: pcc.Name,
        PCCAttainment: pcc.IsQuarterData ? this.percentagePipe.transform(pcc.Metrics.StraightAttainment) : '',
        IsQuarterData: pcc.IsQuarterData,
        FiscalMonth: pcc.FiscalMonth,
        TotalTarget: this.customnumberPipe.transform(pcc.Metrics.Target),
        TotalRecurringTarget: this.customnumberPipe.transform(pcc.Metrics.TotalRecurringTarget),
        TotalActuals: this.customnumberPipe.transform(pcc.Metrics.Actuals),
        NonRecurringTarget: this.customnumberPipe.transform(pcc.Metrics.TotalTarget - pcc.Metrics.TotalRecurringTarget),
        NonRecurringActual: this.customnumberPipe.transform(pcc.Metrics.Actuals - pcc.Metrics.TotalRecurringTarget),
        QuotaToGo: this.customnumberPipe.transform(pcc.Metrics.QuotaToGo),
        Units: pcc.MeasuringUnits
      }
    };
  }

  pcgTooltipHtml(payline: Payline) {
    const tooltipHtml = `<div class="bar__tooltip__container">
    <div>
       ${this.getPcgTooltipDesc(payline)}
    </div>
  </div>
    `;
    return tooltipHtml;
  }

  getPcgTooltipDesc(payline: Payline) {
    return 'Earnings:' + (payline.weightage === 0 ? 'NA' : this.formatter.formatWithCurrencyAndRoundOff(payline.pcgEarnings ? payline.pcgEarnings : 0, payline.pcgCurrency));
  }

  pccTooltipHtml(pcc: any) {
    let descText = '';
    this.getPccTooltipDesc(pcc).forEach(item => (descText = descText + `${item} <br>`));
    const tooltipHtml = `<div class="bar__tooltip__container">
    ${descText}
  </div>
    `;
    return tooltipHtml;
  }

  getPccTooltipDesc(pcc: any) {
    const tooltipDesc = [];
    let targetTitle = 'Target',
      actualsTitle = 'Actuals',
      quotaToGoTitle = 'Quota To Go';
    if (pcc.IsDynamicMetric) {
      actualsTitle = 'Commitments Landed';
      tooltipDesc.push(`${targetTitle}: ${this.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.Target, pcc.MeasuringUnits)}`);
      tooltipDesc.push(`${actualsTitle}: ${this.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.Actuals, pcc.MeasuringUnits)}`);
      return tooltipDesc;
    }
    tooltipDesc.push(`${targetTitle}: ${this.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.Target, pcc.MeasuringUnits)}`);
    tooltipDesc.push(`${actualsTitle}: ${this.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.Actuals, pcc.MeasuringUnits)}`);
    tooltipDesc.push(`${quotaToGoTitle}: ${this.formatter.formatWithCurrencyAndRoundOff(pcc.Metrics.QuotaToGo, pcc.MeasuringUnits)}`);

    return tooltipDesc;
  }
}
