<div>
  <div id="settings_tabs" class="margin-20">
    <mint-tabs [tabs]="settingsTabs" (tabChange)="onTabChange($event)" [activeId]="settingsTabs[selectedTab].id" [tracker]="'SettingsTab'" [source]="'Settings'"></mint-tabs>
    <div id="settings-tiles">
      <div id="settings_1" *ngIf="selectedTab === 0">
        <h2 id="settings_2" class="text-green">My Incentives</h2>
        <div id="settings_3">
          <small id="settings_4">Mint is a reporting application which shows Incentive Compensation data to Microsoft Sales workforce.</small><br id="settings_5" />
          <small id="settings_6">Microsoft Corporation</small><br id="settings_7" />
          <small id="settings_10">Copyright © 2018 Microsoft Corporation. All Rights Reserved.</small>
        </div>
        <ul id="settings_11" class="about-links">
          <li id="settings_12"><a id="settings_13" href="http://go.microsoft.com/fwlink/?LinkId=518021" target="_blank">Microsoft Data Privacy Notice</a></li>
          <li id="settings_14"><a id="settings_15" href="https://www.microsoft.com/en/servicesagreement/" target="_blank">Terms of Use</a></li>
          <!-- <li id="settings_16" ng-if="settings.isUserVoiceVisible">
            <a id="settings_17" href="https://microsoftit.uservoice.com/forums/596023-mint-incentive-compensation" target="_blank">User Voice: See and vote for submitted feedbacks</a>
          </li> -->
          <li id="settings_18"><a id="settings_19" role="button" tabindex="0" ng-click="app.showSupportModal()" ng-keypress="$event.keyCode === 13 && app.showSupportModal()">Support</a></li>
        </ul>
      </div>

      <div id="settings_19" class="m-t-20" *ngIf="selectedTab === 1">
        <div id="settings_20" class="disclaimer-container" *ngIf="disclaimer">
            <p id="settings_21"      class="text-justify line-height-175" [innerHTML]="disclaimerText"></p>
           
                <small id="settings_22">Disclaimer Signed Date</small><br id="settings_23" />
              <time id="settings_24">{{disclaimer.AcceptedDate | ToLocalDate}}</time>
    
        </div>
    </div>
    <div id="settings_25" class="m-t-20" *ngIf="selectedTab === 2">
      <mint-third-party-software></mint-third-party-software>
    </div>
      
    </div>

  </div>
</div>
