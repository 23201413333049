import { DataService } from './../core/network/data.service';
import { EndpointService } from '@mint-libs/network';
import { CustomHttpParams } from './../core/network/custom-http-params';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SidebarService } from '../layout/sidebar/user-sidebar/sidebar.service';
import { FiltersService } from '../filters/filters.service';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from '../core/navigation/navigation.service';

@Injectable()
export class SharedService {
  navigation: any;

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private endpoint: EndpointService,
    private dataService: DataService,
    private filterService: FiltersService,
    public rolefeatureService: RoleFeatureService,
    private sidebarService: SidebarService
  ) {}

  // viewReport(widget) {
  //   this.navigationService.navigateTo('reports', { id: widget.DetailView });
  // }

  ngViewReport(id, params = null, payoutTab = null) {
    this.navigationService.resetActiveParamsOfAllMenus();
    this.navigationService.getNgReportsSubMenuList().forEach(item => {
      if (item.index === id) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    // window.location.href = 'reports';
    const data = { id: params, payoutReportId: payoutTab };
    this.router
      .navigate(['reports'], {
        queryParams: {
          ...data
        },
        queryParamsHandling: 'merge'
      })
      .then(result => {
        this.sidebarService.setFeatureActivation();
      });
  }

  getPlanSummary(excludeTerritory = false, bucketType: string = null, fromPayout: boolean = false) {
    let params = CustomHttpParams.getInstance();
    params = params.append('StartDate', this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.StartDate : null);
    params = params.append('EndDate', this.filterService.selectedStandardTitle ? this.filterService.selectedStandardTitle.EndDate : null);
    params = params.append('ExcludeTerritory', excludeTerritory.toString());
    if (bucketType !== null) {
      params = params.append('BucketType', bucketType);
    }
    if (fromPayout) {
      params = params.append('FromVersionSnapShot', String(fromPayout));
    }

    return this.dataService.getWithGlobalFilters(this.endpoint.GetPlanDetails.uri, params);
  }
}
