<div id="bonus_1" class="bonus_dropdown" style="border-bottom: 1px solid #a7a9ac; align-items: center"
    *ngIf="activeTab">
    <div id="bonus_2" class="pull-left">
        <div id="report-nav-menu" ngbDropdown class="d-inline-block" aria-controls="report_5">
            <button role="none" type="button" class="btn btn__select" id="report-nav" ngbDropdownToggle>
                <div id="bonus_3" role="textbox" aria-label="text box" class="btn__select__txt">{{ activeTab.tabText }}
                </div>
                <div id="bonus_4" role="none" aria-label="icon down" class="icon icon-chevron-down"></div>
            </button>
            <div id="bonus_5" ngbDropdownMenu aria-labelledby="Bonus dropdown" style="width:auto">
                <div *ngFor="let widget of tabs; let i = index" id="'bonus_7_' + i" class="btn btn__hover"
                    (click)="selectBonusDocument(widget.id)" (keydown.enter)="selectBonusDocument(widget.id)"
                    [ngClass]="{ active: widget.active }" ngbDropdownItem analytics-tracker tabindex="-1" role="option"
                    [tracker]="'Bonus_Submenu_' + widget.tabText" [type]="'Click'" [element]="'dropdown-menu'"
                    [source]="'RBIBonusV2'">
                    {{ widget.tabText }}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="app__container">
    <mint-content-loader-icon-text *ngIf="!isDataLoaded"></mint-content-loader-icon-text>
    <mint-content-loader-bar-chart-horizontal *ngIf="!isDataLoaded"></mint-content-loader-bar-chart-horizontal>
    <div *ngIf="isDataLoaded && activeBonusDetails">
        <div class="programDuration">
            <u> Program Duration: <i> {{ activeBonusDetails.bonusData.programStartDate }} -
                    {{ activeBonusDetails.bonusData.programEndDate }}</i></u>
        </div>
        <div>
            <div>
                <span [innerHtml]="activeBonusDetails.bonusConfig.configuration.programDescription | safeHtml" ></span>
                <a href="{{ activeBonusDetails.bonusConfig.configuration.programInfoLink }}" target="_blank"
                    title="Bonus Guide"> Program Information Guide.</a>
            </div>
            <br />
            <mint-bonus-summary-header class="pcg__items" [bonusSummary]="bonusSummaryDetails">
            </mint-bonus-summary-header>
        </div>
        <div>
            <mint-bonus-detail-view [bonusDetails]="bonusAttributeDetails" [bonusSummary]="bonusSummaryDetails"
                [bonusData]="activeBonusDetails.bonusData">
            </mint-bonus-detail-view>
        </div>
    </div>
</div>