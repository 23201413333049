import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mint-export-icon',
  templateUrl: './export-icon.component.html',
  styleUrls: ['./export-icon.component.scss']
})
export class ExportIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
