<div class="prod__serv__info">
  <div *ngFor="let pcgItem of productsServicesData.PcgList">
    <div class="pcg__header" *ngIf="pcgItem.PccCount > 1">{{ pcgItem.Name }}</div>
  </div>
  <div class="pcc__list__container">
    <div class="pcc__list__item" *ngFor="let pccItem of productsServicesData.PccProductsList">
      <div class="pcc__list__item__header">
        <b>{{ pccItem.Name }}</b>
      </div>
      <div class="prod__serv__list__container">
        <ul class="prod__serv__list">
          <li *ngFor="let item of pccItem.ProductsList">{{ item }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>