import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent implements OnInit {
  params: any;

  agInit(params): void {
      this.params = params;
  }
  constructor() { }

  ngOnInit() {
  }

}
