import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mint-content-loader-icon-text',
  templateUrl: './content-loader-icon-text.component.html'
})
export class ContentLoaderIconTextComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
