import { Injectable, Inject } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../core/network/data.service';
import { CustomHttpParams } from '../core/network/custom-http-params';
import { CachingService } from '../core/caching/caching.service';
import { UserProfileService } from '../core/context/user-profile.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Injectable()
export class ComplianceService {
  sharedContext = new SharedContext();
  constructor(
    private endpoint: EndpointService,
    private dataService: DataService,
    public userProfileService: UserProfileService,
    private cacheService: CachingService,
    public sharedContextState: Store<SharedContext>
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  // QUOTA Acknowledgement related service functions
  getQuotaDetails(isReportView: boolean) {
    let params = CustomHttpParams.getInstance();
    params = params.append('AppId', '1');
    params = params.append('IsReportView', isReportView.toString());
    if (this.sharedContext && this.sharedContext.IsDMYEligible) {
      params = params.append('IsDMYElligible', this.sharedContext.IsDMYEligible.toString());
    }

    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetQuotaDetails.uri, params);
  }

  exportPPIQuotaAcknowledgementDetail(isReportView: boolean, isHalfYearlyQuotaSupported: boolean) {
    const jsonParam = { AppId: 1, IsReportView: isReportView.toString(), IsHalfYearlyQuotaSupported: isHalfYearlyQuotaSupported.toString() };
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.ExportPPIQuotaAcknowledgementDetail.uri, jsonParam, null, 'arraybuffer');
  }

  acknowledgeQuota(complianceInfo: any) {
    const promise = this.dataService.postWithGlobalFilters<any[]>(this.endpoint.AcknowledgeQuotaDocument.uri, complianceInfo, null, null);
    this.cacheService.clear();
    return promise;
  }

  markQuotaAsViewed() {
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.MarkQuotaAsViewed.uri, null, null, null);
  }

  getComplianceStatus() {
    let params = CustomHttpParams.getInstance();
    params = params.append('AppId', '1');
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetComplianceStatus.uri, params);
  }

  getTrainingLinksForUser() {
    let params = CustomHttpParams.getInstance();
    params = params.append('AppId', '1');
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetTrainingLinksForUser.uri, params);
  }

  addTrainingLinks(updatedTraining) {
    const params = {
      Alias: updatedTraining.Alias,
      TrainingId: updatedTraining.TrainingId,
      DateAccepted: null
    };
    const promise = this.dataService.postWithGlobalFilters<any[]>(this.endpoint.AddTrainingLinks.uri, params, null, null);
    // var promise = dataService.invokeWithGlobalFiltersForPost(servicesEndpoint.BaseUri + endPoint.uri, endPoint.params);
    this.cacheService.clear();
    return promise;
  }

  getPPAList() {
    const params = CustomHttpParams.getInstance();

    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetPPAList.uri, params);
  }

  getDocusignReceipientViewUrl(participationId, envelopeId, documentStatusId) {
    let params = CustomHttpParams.getInstance();
    params = params.append('AppId', '1');
    // params = params.append('ParticipationID', participationId);
    params = params.append('envelopeId', envelopeId);
    params = params.append('documentSignedStatusID', documentStatusId);
    const promise = this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetPPAReceipientViewUrl.uri, params);
    this.cacheService.clear();
    return promise;
  }
}
