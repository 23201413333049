<div class="modal-header">
  <span class="modal-title text-green" id="modal_title">Popular Solutions</span>
  <button type="button" mat-dialog-close class="close text-green" aria-label="Popular Solutions dialog close">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modal-body">
  <div class="accordion-container">
    <div class="accordion" id="support_commonIssue_accordion">
      <div class="card" *ngFor="let issue of commonIssues; index as i">
        <div class="card-header" [id]="'support_commonIssue_heading_' + i">
          <button
            class="accordion-btn"
            type="button"
            data-toggle="collapse"
            data-target="#{{ 'support_commonIssue_body_' + i }}"
            [attr.aria-expanded]="issue.isExpanded"
            [attr.aria-controls]="'support_commonIssue_body_' + i"
            (click)="toggleAccordion(issue)"
          >
            <div class="title">
              <mat-icon>{{ issue.icon }}</mat-icon>
              <span class="pl-10">{{ issue.title }}</span>
            </div>
            <mat-icon *ngIf="issue.isExpanded">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!issue.isExpanded">keyboard_arrow_down</mat-icon>
          </button>
        </div>

        <div
          [id]="'support_commonIssue_body_' + i"
          class="collapse"
          [ngClass]="{ show: issue.isExpanded }"
          [attr.aria-labelledby]="'support_commonIssue_heading_' + i"
          data-parent="#support_commonIssue_accordion"
        >
          <div class="card-body" [innerHTML]="issue.content"></div>
        </div>
      </div>
    </div>
    <div id="support_link_title">
      <b>
        Need more help?
      </b>
    </div>
    <div *ngIf="isServiceNowEnabled">
      <div *ngIf="!isUBIPersona">
        <span>If you are a PPI or RBI seller or manager <b>with existing MSX access</b>, please submit your support request through: </span>
        <a
          id="support_link"
          aria-label="MSX Home Page - Dynamics 365 Help + Support"
          [href]="msxSupportUrl"
          target="_blank"
          analytics-tracker
          [tracker]="'Support_Link'"
          [type]="'Click'"
          [element]="'HyperLink'"
          [source]="'Support'"
        >
          MSX Home Page - Dynamics 365 Help + Support
          <mat-icon class="small-icon">open_in_new</mat-icon>
        </a>
        <ul>
          <li>
            The above link opens MSX in the Help + Support panel. Click “Contact Support”. A panel will open with the Workspace set to Earnings.
            <ul>
              <li>
                Important: Please ensure the Workspace is always set to “Earnings” for Incentive Compensation questions. The enables you to view related help topics and ensure the ticket is routed to
                WWIC
              </li>
            </ul>
          </li>
        </ul>
        <br />
      </div>
      <div>
        <b>
          <div *ngIf="!isUBIPersona; else ubiMessage">
            If you are using MCAPSHelp instead of MSX Earnings workspace to find answers and raise tickets with WWIC Support, please note that MCAPSHelp has recently undergone changes to the dashboard!
          </div>
          <ng-template #ubiMessage>
            You may submit your support requests through MCAPSHelp. Please note that MCAPSHelp has recently undergone changes to the dashboard!
          </ng-template>
        </b>
        <ul>
          <li>
            The first time you access the 
            <a
              id="support_link"
              aria-label="MCAPSHelp - PowerApps"
              [href]="paSupportUrl"
              target="_blank"
              analytics-tracker
              [tracker]="'Support_Link'"
              [type]="'Click'"
              [element]="'HyperLink'"
              [source]="'Support'"
            >
              MCAPSHelp - PowerApps
              <mat-icon class="small-icon">open_in_new</mat-icon>
            </a>, you’ll be asked to fill out your preferences. You <u>must</u> select either <b>Seller</b> or <b>Sales Manager</b> (even for UBI roles) in the <b>Role</b> dropdown to be able to add the WWIC Services tile to your workspace.
          </li>
          <li>
            Next, a pop-up will appear asking you to grant permission to sign you in and access your data. Click the <b>Accept</b> button to move forward.
          </li>
          <li>
            Last, make sure you add the <b>WWIC Services</b> tile to your My Help workspace.
          </li>
        </ul>
        <b>IMPORTANT:</b> Before engaging with Iris Copilot, <b>make sure you click ‘Start’ on the WWIC Services tile</b> to ensure the support you receive is related to incentive compensation, and any tickets you file are routed to the WWIC team. You must do this each time you access MCAPSHelp.
        <br />
        <br />
        For a detailed breakdown of these requirements, and how to successfully access WWIC Support via MCAPSHelp, check out the WWIC Support page on the
        <a
          id="support_link"
          aria-label="Incentive Compensation Guide"
          [href]="incentiveCompensationGuideUrl"
          target="_blank"
          analytics-tracker
          [tracker]="'Support_Link'"
          [type]="'Click'"
          [element]="'HyperLink'"
          [source]="'Support'"
        >
          Incentive Compensation Guide.
          <mat-icon class="small-icon">open_in_new</mat-icon>
        </a>
        <br />
        <br />
      </div>
      <!-- Added Support Link for MS federal employee -->
      <div>
        <b>If you are a United States MS Federal employee, please submit your question to:</b>
      </div>
      <div>
        <a href="mailto:svc-FedICSup@microsoftfederal.com" target="_blank" analytics-tracker [tracker]="'Support_Link_Fed'" [type]="'Click'" [element]="'HyperLink'" [source]="'Support'">
          svc-FedICSup@microsoftfederal.com
        </a>
      </div>
    </div>
    <div *ngIf="!isServiceNowEnabled">
      <div>
        <b>Please send an email to</b>
      </div>
      <div>
        <a href="mailto:svc-FedICSup@microsoftfederal.com" target="_blank" analytics-tracker [tracker]="'Support_Link_Fed'" [type]="'Click'" [element]="'HyperLink'" [source]="'Support'">
          svc-FedICSup@microsoftfederal.com
        </a>
      </div>
    </div>
  </div>
</div>
