export class DirectPPACompliance {
  status: number;
  country: string;
  countryString: string;
  string;

  constructor(ppaStatus: number, ppaCountry: string, ppaCountryString: string) {
    this.status = ppaStatus;
    this.country = ppaCountry;
    this.countryString = ppaCountryString;
  }
}
