import { Component, Inject, Input, OnChanges } from '@angular/core';
@Component({
  selector: 'mint-attainment-by-bucket-grid',
  templateUrl: './attainment-by-bucket-grid.component.html',
  styleUrls: ['./attainment-by-bucket-grid.component.scss']
})
export class AttainmentByBucketGridComponent implements OnChanges {
  @Input() columns: Array<any>;
  @Input() gridCaption: string;
  @Input() containerId: string;
  @Input() data: any;

  visibleColumns: Array<any>;
  constructor() {}

  ngOnChanges() {
    this.visibleColumns = this.columns.filter(col => col.isVisible);
  }
}
