import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
//import { resetImpersonation, startImpersonation } from '../../../core/context/impersonation/state/impersonation.actions';
//import { ImpersonationState } from '../../../core/context/impersonation/state/impersonation.state';

@Component({
  selector: 'mint-impersonation-avatar',
  templateUrl: './impersonation-avatar.component.html',
  styleUrls: ['./impersonation-avatar.component.scss']
})
export class ImpersonationAvatarComponent implements OnInit {
  userInfoList: any[];
  hideElement = false;
  constructor() {
    //this.impersonationState.dispatch(resetImpersonation());
  }

  @Input() userInfo;
  thumbnailPhoto = '';
  ngOnInit() {
    if (this.userInfo && this.userInfo.thumbnailPhoto) {
      this.thumbnailPhoto = `data:image/jpeg;base64,${this.userInfo.thumbnailPhoto}`;
    }
  }
}
