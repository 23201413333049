<mint-payout-payment-details [paymentDetails]="payoutInfoArray"></mint-payout-payment-details>
<mint-payout-bonus-payment-details *ngIf="hasBonusData(payoutInfoArray)" [paymentDetails]="payoutInfoArray" ></mint-payout-bonus-payment-details>
<div id="payout-report-body">
  <div class="payout-report-blade1">
    <mat-accordion>
      <ng-container *ngFor="let payoutInfo of payoutInfoArray; let k = index">
        <div *ngIf="payoutInfo.EarningDetails" class="payout-report-blade1-tile">
          <div class="payout-report-blade1-subsection">
            <div>Core Buckets YTD Award</div>
            <div>
              {{ payoutReportService.formatCurrency(payoutInfo.YTDEarnings) }} <span>{{ payoutInfo.CurrencyCode
                }}</span>
            </div>
          </div>
          <div>
            <div *ngFor="let payoutEarningDetail of payoutInfo.EarningDetails; let j = index">
              <div class="role-summary-info">{{ payoutEarningDetail.RoleSummary }}</div>
              <mat-expansion-panel *ngFor="let pcg of payoutEarningDetail.PCGList; first as isFirst; let i = index"
                [expanded]="isFirst && j === 0 && k === 0" (opened)="openAccordionElement(payoutInfo, 'PCG', pcg, payoutEarningDetail)"
                hideToggle>  <!-- (afterExpand)="openGroup(i)"> -->
                <mat-expansion-panel-header>
                  <mat-panel-title><span style="color: transparent; width: 0;">{{ pcg.PCGID }}</span>{{ pcg.PCG }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div>
                      {{ payoutReportService.formatCurrency(pcg.IncentiveAward) }} <span
                        class="payout-report-body-subtitles">{{ payoutInfo.CurrencyCode }}</span>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div *ngFor="let pcc of pcg.PCCDetails; let i = index"
                  class="payout-report-blade1-subsection-sublist metric-name-link"
                  (click)="openModal(metricdetailstemplate, pcg, pcc.PCCID)">
                  {{ pcc.PCCName }}<mat-divider *ngIf="i !== pcg.PCCDetails.length - 1"></mat-divider>
                </div>
              </mat-expansion-panel>
            </div>
          </div>
        </div>
        <div *ngIf="payoutInfo.GroupedAdjustments" class="payout-report-blade1-tile">
          <div class="payout-report-blade1-subsection">
            <div>Adjustments</div>
            <div>
              {{ payoutReportService.formatCurrency(payoutInfo.TotalPayrollAdjustments) }} <span>{{
                payoutInfo.CurrencyCode }}</span>
            </div>
          </div>
          <div>
            <mat-expansion-panel *ngFor="let adjustment of payoutInfo.GroupedAdjustments" hideToggle
            (opened)="openAccordionElement(payoutInfo, 'Adjustment', adjustment)"> <!-- (afterExpand)="openGroup(i)" > -->
              <mat-expansion-panel-header>
                <mat-panel-title>{{ adjustment.AdjustmentName }} </mat-panel-title>
                <mat-panel-description>
                  <div>
                    {{ payoutReportService.formatCurrency(adjustment.AdjustmentAmount) }} <span
                      class="payout-report-body-subtitles">{{ payoutInfo.CurrencyCode }}</span>
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="payout-report-blade1-subsection-sublist">
                <div>Date: {{ adjustment.AdjustmentEffectiveDate | date: 'mediumDate' }}</div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
        <div *ngIf="payoutInfo.BonusEarningDetails" class="payout-report-blade1-tile">
          <div class="payout-report-blade1-subsection">
            <div>Total Bonus</div>
            <div>
              {{ payoutReportService.formatCurrency(reduceTotalBonus(payoutInfo)) }} <span>{{
                payoutInfo.CurrencyCode }}</span>
            </div>
          </div>
          <div>
            <mat-expansion-panel *ngFor="let bonus of payoutInfo.BonusEarningDetails" hideToggle
            (opened)="openAccordionElement(payoutInfo, 'Bonus', bonus)">   <!-- (afterExpand)="openGroup(i)" > -->
              <mat-expansion-panel-header>
                <mat-panel-title>{{ bonus.RevmapName }} </mat-panel-title>
                <mat-panel-description>
                  <div>
                    {{ payoutReportService.formatCurrency(bonus.BonusEarningAmount) }} <span
                      class="payout-report-body-subtitles">{{ bonus.CurrencyCode }}</span>
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </div>
        </div>
      </ng-container>
    </mat-accordion>
  </div>
  <div class="payout-report-blade2" id="payout-report-blade2">
    <mint-payout-core-bucket-details *ngIf="showCoreBucketDetails" 
       [selectedPCG]="selectedPCG" [selectedPayoutEarningDetail]="selectedPayoutEarningDetail"
       [currency]="selectedPayoutInfo.CurrencyCode"
       [selectedFiscalYear]="selectedPayoutInfo.FiscalYearId"
      ></mint-payout-core-bucket-details>
    <mint-payout-report-adjustment-details *ngIf="showAdjustmentDetails"
      [adjustmentInfo]="selectedPayoutInfo.PayrollAdjustments"></mint-payout-report-adjustment-details>
    <mint-payout-report-bonus-details *ngIf="showBonusDetails" 
      [bonusInfo]="[selectedBonus]" [publicationDate]="selectedPayoutInfo.PublicationDate"></mint-payout-report-bonus-details>
  </div>
</div>

<ng-template #metricdetailstemplate let-modal>
  <div role="dialog" aria-label="Metric Details Dialog" tabindex="0">
    <mint-payout-report-metric-details [selectedMetric]="selectedMetric"></mint-payout-report-metric-details>
  </div>
</ng-template>