import { BaseModel } from 'src/app/shared/models/base.model';
import { deserializeArray, plainToClass } from 'class-transformer';
import { FeatureIntro } from './feature-intro.model';
import { IDeserializable } from './iDeserializable.interface';
export class FeatureGroup extends BaseModel implements IDeserializable<FeatureGroup[]> {
  featureGroupId: number;
  featureHeader: string;
  startTime: Date;
  endTime: Date;
  createdBy: string;
  modifiedBy: string;
  modifiedOn: Date;
  aliasList: string;
  countryNames: string;
  planNames: string;
  businessSegment: string;
  roles: string;
  standardTitles: string;
  qualifier1Names: string;
  qualifier2Names: string;
  status: number;
  featureIntroductions: FeatureIntro[];

  deserialize(from: object): FeatureGroup[] {
    const featureGroups = deserializeArray<FeatureGroup>(FeatureGroup, JSON.stringify(from));
    return featureGroups;
  }

  deserializeSingle(from: object): FeatureGroup {
    const featureGroup = plainToClass<FeatureGroup, object>(FeatureGroup, from, this.classTransformOptions);
    return featureGroup;
  }
}
