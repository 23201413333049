import { Component, OnInit, Inject, TemplateRef, Input } from '@angular/core';
import { ManagerDashboardService } from '../manager-dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Ng1Services from '../../../core/hybrid/ng1-upgraded-providers';
import { Direct } from '../models/direct';
import { SoftCapData } from '../models/soft-cap-data.enum';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CachingService } from '../../../core/caching/caching.service';
import { EndpointService } from '@mint-libs/network';
import { ManagerDashboardHelper } from '../manager-dashboard.helper';
import { MessagesService } from '@mint-libs/context';
import { Code } from '../../../app.constants';
import { FiltersService } from '../../../filters/filters.service';
import { UserProfileService } from '../../../core/context/user-profile.service';
import { ImpersonationService } from 'src/app/core/impersonation/impersonation.service';
import { CommonFactory } from '@mint-libs/common';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-soft-cap',
  templateUrl: './soft-cap.component.html',
  styleUrls: ['./soft-cap.component.scss'],
  providers: [Code]
})
export class SoftCapComponent implements OnInit {
  @Input() directs: Direct[];
  @Input() isLoaded = false;
  @Input() isSubmitted = false;

  modalRef;
  filterService: any;
  directSoftCapChangesCount = 0;
  directsSoftCapData: any[];
  openConfirmationPopup = true;
  isConfirmed = false;
  softCapSuccess: Observable<any>;
  softCapFailure: Observable<any>;
  softCapSuccessMessage: string;
  softCapFailureMessage: string;
  softCapTitleText: string;
  softCapTitleTextBody: string;
  videoData: any;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private managerDashboardService: ManagerDashboardService,
    private managerDashboardHelper: ManagerDashboardHelper,
    private modalService: NgbModal,
    private router: Router,
    private cachingService: CachingService,
    private endpoints: EndpointService,
    public userProfileService: UserProfileService,
    public sharedContextState: Store<SharedContext>,
    filterService: FiltersService,
    private commonFactory: CommonFactory,
    private messageService: MessagesService,
    private impersonationService: ImpersonationService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.filterService = filterService;
  }

  ngOnInit() {
    this.getSoftCapMessages();

    this.softCapTitleText = `For a quick overview on how to use this dashboard see the`; // <a href="https://msit.microsoftstream.com/video/251fa1ff-0400-a936-ed23-f1eaa9b75366" target="_blank">Mint Minute: Soft Cap Release in Mint</a>
    this.softCapTitleTextBody = `<b style="font-size: 14px">About the soft cap review by metric</b>
    For <b>RBI, How Mint decides who should exceed their soft cap</b>
    Sellers who meet the below criteria will automatically be flagged with a recommendation to approve:
• Exceed the soft cap designated within their incentive compensation plan
• For billed revenue metrics, the metric quota is at least $100,000 (including Azure Consumed Revenue)
• Are actively employed in the same role for at least 8 months
• Aren’t aligned to broad territories, such as segment, subsidiary, or EOU level
• Have an aggregate payout of 100% or more

For <b>UBI</b>
<b><u>Utilization metric:</u></b>  UBI participants who meet the below criteria will automatically be flagged with a recommendation to “approve”:
• Exceed the soft cap designated within their incentive compensation plan
• Active in a UBI role on June 30
• Actively employed at Microsoft at the time of processing (mid-July)

<b><u>Consumption metric:</u></b>  Mint will flag with a recommendation to “decline” for all consumption performance for UBI participants.
• If a UBI participant has demonstrated impact on consumption in the fiscal year, you can change the prepopulated recommendation by clicking the toggle and providing a business justification. See example below.
`;
  }

  directSoftCapChangesCountChange(value: boolean) {
    if (value) {
      this.directSoftCapChangesCount++;
    } else {
      this.directSoftCapChangesCount--;
    }
  }

  openModal(softCapSubmitConfirmation: TemplateRef<any>, softCapInfofordirects: any[]) {
    this.directsSoftCapData = [];
    this.openConfirmationPopup = true;
    this.directs.forEach(direct => {
      if (direct.softCapInfo.softCapPCGDetails) {
        direct.softCapInfo.softCapPCGDetails.forEach(pcg => {
          const directsData = new SoftCapData();
          (directsData.FiscalYear = this.filterService.selectedManagerViewFiscalYear),
            (directsData.ParticipationID = direct.currentParticipation),
            (directsData.PersonnelNumber = direct.personnelNumber),
            (directsData.PCGID = pcg.PCGID),
            (directsData.ManagerApproval = pcg.ManagerApproval != null ? pcg.ManagerApproval : pcg.SystemApproval),
            (directsData.Comments = direct.softCapInfo.businessJustification),
            (directsData.UpdatedBy = this.sharedContext.alias),
            this.directsSoftCapData.push(directsData);
        }, this);
      }
      if (direct.softCapInfo.directsPCGSoftCapChangesCount > 0 && (direct.softCapInfo.businessJustification === null || direct.softCapInfo.businessJustification === undefined)) {
        direct.softCapInfo.isBusinessJustificationValidationMandate = true;
        const missingJustificationElement = document.getElementsByClassName('mandatory')[0];
        missingJustificationElement.scrollIntoView();
        this.openConfirmationPopup = false;
      }
    }, this);
    if (this.openConfirmationPopup) {
      this.modalRef = this.modalService.open(softCapSubmitConfirmation, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'mint-modal-backdrop', windowClass: 'mint-modal-window' });
    }
  }

  getSoftCapMessages() {
    this.messageService.get(Code.SoftCapSuccess).subscribe(response => {
      this.softCapSuccessMessage = response;
    });
    this.messageService.get(Code.SoftCapFailure).subscribe(response => {
      this.softCapFailureMessage = response;
    });
  }

  closeModal(event: any) {
    this.isConfirmed = true;
    this.managerDashboardService.updateDirectSoftCapDetails(this.directsSoftCapData, this.sharedContext.isViewAs).subscribe(
      (response: any) => {
        this.modalRef.close();
        this.isSubmitted = true;
        this.isConfirmed = false;
        this.commonFactory.addNotification({
          name: 'success',
          message: this.softCapSuccessMessage,
          dismiss: 4000
        });
        this.cachingService.clearCache(this.endpoints.GetDirectsSoftCap.uri);
        this.router.navigateByUrl(this.router.url, { skipLocationChange: true });
      },
      error => {
        this.isConfirmed = false;
        this.modalRef.close();
        this.commonFactory.addNotification({
          name: 'error',
          message: this.softCapFailureMessage,
          dismiss: 4000
        });
      }
    );
  }

  dismissModal(event: any) {
    this.modalRef.close();
  }
}
