import { HostListener, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SupportComponent } from './support.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SupportHelperService {
modalRef: any;
matDialogConfig = { ariaLabelledBy: 'modal_title', width: '668px', maxHeight: '90vh', position : { top: '2%' } };
constructor(private modalService: MatDialog) { }

  openSupportModal() {
    if (environment.isDemoMode2) {
      return;
    }
    this.modalRef = this.modalService.open(SupportComponent, this.matDialogConfig);
  }

  closeSupportModal() {
    this.modalRef.close();
  }
}
