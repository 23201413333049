import { IEnvironment } from './iEnvironment';

const clientId = '490d8e2d-4831-41fd-bf0f-991e5f9eb9ae';
const aadResource = 'http://ParticipantExperienceService';
const aadInstance = 'https://login.microsoftonline.com/';
const aadTenant = 'microsoft.onmicrosoft.com';

const endpoints = {
  serviceBaseUrl: 'https://mintapi.incentivecompensation.microsoft.com/',
  mintApiBaseUrl: 'https://mint-core.prod.mint.microsoft.com/',
  mintApiDetailBaseUrl: 'https://mint-core.prod.mint.microsoft.com/',
  revenueserviceBaseUrl: 'https://mintdetailapi.incentivecompensation.microsoft.com/',
  demoApiBaseUrl: 'https://mintdemo2.icmintprod.p.azurewebsites.net/',
  bonusApiUrl: 'https://bonus.prod.mint.microsoft.com/',
  payoutApiUrl: 'https://payout.prod.mint.microsoft.com/'
};

export const environmentProd: IEnvironment = {
  spoofUser: '',

  production: true,
  uat: false,
  environment: 'PROD',
  serviceBaseUrl: endpoints.serviceBaseUrl,
  mintapiBaseUrl: endpoints.mintApiBaseUrl,
  mintapiDetailBaseUrl: endpoints.mintApiDetailBaseUrl,
  revenueserviceBaseUrl: endpoints.revenueserviceBaseUrl,
  demoapiBaseUrl: endpoints.demoApiBaseUrl,
  demoAppUrl: '',
  bonusApiUrl: endpoints.bonusApiUrl,
  payoutApiUrl: endpoints.payoutApiUrl,
  appInsightsKey: '42e6092a-2891-48c2-a2dd-efa5df51937d',
  appInsightsEndpoint: 'https://dc.services.visualstudio.com/v2/track',
  utpResourceId: 'b2406a33-9114-4fa4-b366-ff1935cc625f',
  aadResource: aadResource,
  aadClientId: clientId,
  aadInstance: aadInstance,
  aadTenant: aadTenant,
  isDemoMode2: false,
  enableISA: true,
  ocvEnvironment: 0,
  isOcvEnabled: true,
  isUserVoiceEnabled: true,
  isPageTourEnabled: true,
  isBotEnabled: true,
  isServiceNowEnabled: true,
  enablePWA: false,
  ocvAppId: 2461,
  adobeOriginUri: 'https://secure.na3.adobesign.com',
  // pagetour config
  pageTourConfig: {
    apiUrl: 'https://pagetourservice.trafficmanager.net/api/v1/',
    resourceId: 'fd723931-d8c8-4535-bc98-50e50f472583',
    tenantId: '4e09fcdc-ece7-452f-b0dd-15cfef8e0b26'
    // resourceId: '97c43e67-181d-44a9-9124-aec04ee9a844'
  },
  // Configuration for authentication
  authConfig: {
    tenant: aadTenant,
    clientId: clientId,
    instance: aadInstance,
    extraQueryParameter: 'nux=1',
    popUp: false,
    navigateToLoginRequestUrl: true,
    cacheLocation: 'sessionStorage',
    endpoints: {
      [endpoints.serviceBaseUrl]: aadResource,
      [endpoints.mintApiBaseUrl]: aadResource,
      [endpoints.mintApiDetailBaseUrl]: aadResource,
      [endpoints.revenueserviceBaseUrl]: aadResource,
      [endpoints.demoApiBaseUrl]: aadResource,
      [endpoints.bonusApiUrl]: aadResource,
      [endpoints.payoutApiUrl]: aadResource
    }
  },
  corpUrlMessage: '',
  showMintInsider: true,
  graphResource: 'https://graph.microsoft.com',
  graphDomain: 'microsoft.com',
  supportHref: 'https://aka.ms/wwichelp',
  redirectHomeUrl: 'https://aka.ms/mint',
  showAccount360: true,
  useUserVoice: true,
  useTelemetryCxtForUserAlias: true,
  appBaseUrl: 'mint.microsoft.com',
  showVideoInPopup: true,
  complianceRedirectCorpUrl: false,
  corpBaseUrl: 'https://mint.microsoft.com',
  showBonusPayoutInfo: true,
  isHardTransferFeatureEnabled: true
};
