import { Component, Inject, OnInit, Input } from '@angular/core';
import { ManagerDashboardService } from '../manager-dashboard.service';
import { UserProfileService } from 'src/app/core/context/user-profile.service';
import { ExportToExcelService } from '../../../shared/excel-export/export-to-excel.service';
import { FiltersService } from '../../../filters/filters.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { RoleFeatureService } from '@mint-libs/context';
import { PivotConfiguration, PageField } from '../models/PivotConfirguration';
@Component({
  selector: 'mint-manager-dashboard-export',
  templateUrl: './manager-dashboard-export.html',
  styleUrls: ['./manager-dashboard-export.scss']
})
export class ManagerDashboardExportComponent implements OnInit {
  showPerformanceReport = false;
  showPerformanceReportLoader = false;
  performanceReportLabelText = ' Team Performance Report';
  showPayoutReportLoader = false;
  showPayoutTerritoryLoader = false;
  payoutReportLabelText = 'Team Payout Report';
  showQuotaReportLoader = false;
  quotaReportLabelText = 'Team Quota Report';
  territoryReportLabelText = 'Team Territory Report (Payout)';
  payoutShowTerritoryReport = false;
  showTeamQuotaeport = true;
  payoutShowTeamPayouteport = true;
  settings:{PivotConfiguration:PivotConfiguration};
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private managerDashboardService: ManagerDashboardService,
    public sharedContextState: Store<SharedContext>,
    private rolefeatureService: RoleFeatureService,
    private filterService: FiltersService,
    public userProfileService: UserProfileService,
    private exportToExcelServiceNew: ExportToExcelService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    // TODO: Why this the fiscalyear is hardcoded here
    this.payoutShowTerritoryReport =
      this.filterService.selectedManagerViewStepId && this.filterService.selectedManagerViewFiscalYear
        ? (this.filterService.selectedManagerViewStepId >= 2 && this.filterService.selectedManagerViewFiscalYear === 2020) || this.filterService.selectedManagerViewFiscalYear > 2020
        : false;
  }

  @Input() isRbiDirects = false;
  @Input() isUbiDirects = false;

  ngOnInit() {
    const performanceReportFeatureQuery = this.sharedContext.businessSwitchEnabledFeatures?.filter(t => t.FeatureId === this.rolefeatureService.features.MyTeamPerformanceReport);
    this.settings = this.createPivotConfiguration();
    this.showPerformanceReport =
      this.filterService.selectedManagerViewFiscalYear < 2021 && performanceReportFeatureQuery && performanceReportFeatureQuery.length > 0 && performanceReportFeatureQuery[0].IsActive ? true : false;
    if (!this.sharedContext.isRollOver) {
      this.payoutShowTeamPayouteport = false;
    }
  }

  createPivotConfiguration(){
    var rowFields = ["Employee Name", "Total Weighted Attainment", "Bucket", "Bucket Attainment", "Caps Applied" , "Payout % Before Caps", "Payout % After Caps"]
    var pageFields = new Array<PageField>();
    pageFields.push(new PageField("Role Summary"))
    pageFields.push(new PageField("Current Manager Name"))
    var dataFields = ["Total Target-Recurring Target or Baseline","Total Actuals-Recurring Target or Baseline"]
    return {PivotConfiguration:new PivotConfiguration(rowFields, [], pageFields, dataFields)};
  }

  exportPayoutReport(fileName: string) {
    this.showPayoutReportLoader = true;
    this.payoutReportLabelText = 'Downloading...';
    var settings = JSON.stringify(this.settings);
           this.managerDashboardService.getDirectsPayoutDetails('TeamPayoutReport', true, true,settings).subscribe(result => {
      if (this.isUbiDirects) {
        fileName = this.exportToExcelServiceNew.getFileName('TeamPayout', false, true);
      } else {
        fileName = this.exportToExcelServiceNew.getFileName('TeamPayout', true, true);
      }
      this.exportToExcelServiceNew.downloadOpenFile(result, fileName);
      this.showPayoutReportLoader = false;
      this.payoutReportLabelText = 'Team Payout Report';
    });
  }

  exportPerformanceReport(fileName: string) {
    this.showPerformanceReportLoader = true;
    this.performanceReportLabelText = 'Downloading...';
    this.managerDashboardService.getDirectsPayoutDetails('TeamPerformanceReport').subscribe(result => {
      fileName = this.exportToExcelServiceNew.getFileName('TeamPerformance', true);
      this.exportToExcelServiceNew.downloadOpenFile(result, fileName);
      this.showPerformanceReportLoader = false;
      this.performanceReportLabelText = 'Team Performance Report';
    });
  }

  exportQuotaReport(fileName: string) {
    this.showQuotaReportLoader = true;
    this.quotaReportLabelText = 'Downloading...';
    this.managerDashboardService.getDirectsQuotaDetails().subscribe(result => {
      this.exportToExcelServiceNew.downloadOpenFile(result, fileName);
      this.showQuotaReportLoader = false;
      this.quotaReportLabelText = 'Team Quota Report';
    });
  }

  exportTerritoryPayoutReport(fileName: string) {
    this.showPayoutTerritoryLoader = true;
    this.territoryReportLabelText = 'Downloading...';
    this.managerDashboardService.getDirectsTerritoryDetails(this.sharedContext.alias, true).subscribe(result => {
      fileName = this.exportToExcelServiceNew.getFileName('TeamTerritory', true, true);
      this.exportToExcelServiceNew.downloadOpenFile(result, fileName);
      this.showPayoutTerritoryLoader = false;
      this.territoryReportLabelText = 'Team Territory Report (Payout)';
    });
  }
}
