<div class="core-bucket-detail-tile">
  <div class="core-bucket-detail-header-title">Bucket Actuals and Bucket Target</div>
  <div class="core-bucket-detail-body" id="bucket-target-actuals-body">
    <table mat-table [dataSource]="selectedCoreBucket.PCCDetails" class="mat-elevation-z8">
      <!-- Metric Name Column -->
      <ng-container matColumnDef="PCCName">
        <th mat-header-cell *matHeaderCellDef>Metrics</th>
        <td mat-cell *matCellDef="let metric;let i = index" (click)="openModal(metricdetailstemplate, metric)" class="name-link" tabindex="0" id="name-link_{{i}}">{{ metric.PCCName }}</td>
        <td mat-footer-cell *matFooterCellDef>Bucket Total</td>
      </ng-container>

      <!-- Total Target Column -->
      <ng-container matColumnDef="TotalTarget">
        <th mat-header-cell *matHeaderCellDef>
          <div>Total Target</div>
          <div>(P+Q+R)</div>
        </th>
        <td mat-cell *matCellDef="let metric">{{ payoutReportService.formatCurrency(metric.Metrics.TotalTarget) }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Total Actuals Column -->
      <ng-container matColumnDef="Actuals">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ actualsColumnTitle }}</div>
          <div>(A+B+C)</div>
        </th>
        <td mat-cell *matCellDef="let metric">{{ payoutReportService.formatCurrency(metric.Metrics.Actuals) }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Total Target Recurring Column -->
      <ng-container matColumnDef="TotalRecurringTarget">
        <th mat-header-cell *matHeaderCellDef>Total Recurring Target or Baseline (G+H+I)</th>
        <td mat-cell *matCellDef="let metric">{{ payoutReportService.formatCurrency(metric.Metrics.TotalRecurringTarget) }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Metric Target Column -->
      <ng-container matColumnDef="MetricTarget">
        <th mat-header-cell *matHeaderCellDef>
          <div>Total Target -</div>
          <div>Total Recurring Target or Total Target - </div>
          <div>Baseline</div>
        </th>
        <td mat-cell *matCellDef="let metric">{{ payoutReportService.formatCurrency(metric.Metrics.TotalTarget - metric.Metrics.TotalRecurringTarget) }}</td>
        <td mat-footer-cell *matFooterCellDef>
          <div>{{ payoutReportService.formatCurrency(totalBucketTarget) }}</div>
        </td>
      </ng-container>

      <!-- Metric Actuals Column -->
      <ng-container matColumnDef="MetricActual">
        <th mat-header-cell *matHeaderCellDef>
          <div>Total Actuals -</div>
          <div>Total Recurring Target or Total Actuals - </div>
          <div>Baseline</div>
        </th>
        <td mat-cell *matCellDef="let metric">{{ payoutReportService.formatCurrency(metric.Metrics.Actuals - metric.Metrics.TotalRecurringTarget) }}</td>
        <td mat-footer-cell *matFooterCellDef>
          <div>{{ payoutReportService.formatCurrency(totalBucketActuals) }}</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns" class="bucket-target-actuals-total-row" [class.hidden]="selectedCoreBucket.IsDynamicMetric"></tr>
    </table>
  </div>
</div>

<ng-template #metricdetailstemplate let-modal>
  <div role="dialog" aria-label="Metric Details Dialog" tabindex="0"><mint-payout-report-metric-details [selectedMetric]="selectedMetric"></mint-payout-report-metric-details></div>
</ng-template>
