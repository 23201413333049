import { Component, OnInit, Input } from '@angular/core';
import { PayoutReportService } from '../../payout-report.service';
import { TBonusEarningDetails, TPayoutObject } from '../../types/payout-info-response-type';

@Component({
  selector: 'mint-payout-bonus-payment-details',
  templateUrl: './bonus-payment-details.component.html',
  styleUrls: ['./bonus-payment-details.component.scss']
})
export class BonusPaymentDetailsComponent implements OnInit {
  paymentReportTitle = 'Total Bonus Net Payment';
  paymentPeriod = '';
  bonusDetails: TBonusEarningDetails[];
  filteredPaymentDetails: TPayoutObject[];
  @Input() paymentDetails: TPayoutObject[];

  constructor(public payoutReportService: PayoutReportService) { }

  ngOnInit() {
    
  }

  ngOnChanges() {
    this.filteredPaymentDetails = this.paymentDetails.filter(payment => payment.BonusEarningDetails);
    this.bonusDetails = this.aggregateBonusEarnings(this.filteredPaymentDetails);
    this.paymentPeriod = this.filteredPaymentDetails.length>0 ?  this.filteredPaymentDetails[0].CodePeriod?.replace("-None", "") : '';
  }

  reduceProperty(array: [], key: string): number {
    if (Array.isArray(array)) {
      return array.reduce(
        (previous, current) => previous + current[key],
        0);
    }
    else {
      return 0;
    }
  }

  aggregateBonusEarnings(payouts: TPayoutObject[]): TBonusEarningDetails[] {
    return [].concat(...payouts.map(payout => payout.BonusEarningDetails));
  }
}
