import { Formatter } from '@mint-libs/common';

export class BonusAwardSummary {
  formatter:Formatter = new Formatter();
  totalBonusAmount: number;
  currency: string; 
  totalProductsUnlocked : number;
  totalProducts : number;
  potentialAward: number;
  currentITA: number;

  currentITAStr(){
    return this.formatter.formatAndRoundOff(this.currentITA);
  }

  potentialAwardStr(){
    return this.formatter.formatAndRoundOff(this.potentialAward);
  }

  totalBonusAmountStr(){
    return this.formatter.formatAndRoundOff(this.totalBonusAmount);
  }
}

