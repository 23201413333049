<div class="export" style="display: flex">
  <mint-export-excel *ngIf="showPerformanceReport && isRbiDirects" style="margin-right: 15px"
    class="export__performance" [id]="'PerformanceReport'" [title]="'Export Team Performance Report to Excel'"
    [downloadInactive]="showPerformanceReportLoader" (notifyDownloadClick)="exportPerformanceReport($event)"
    [labelText]="performanceReportLabelText"></mint-export-excel>

  <mint-export-excel style="margin-right: 15px " *ngIf="payoutShowTerritoryReport" class="export__payout"
    [id]="'payoutShowTerritoryReport'" [title]="'Export Team Territory Report for Payouts to Excel'"
    [downloadInactive]="showPayoutTerritoryLoader" (notifyDownloadClick)="exportTerritoryPayoutReport($event)"
    [labelText]="territoryReportLabelText"></mint-export-excel>

  <mint-export-excel style="margin-right: 15px" *ngIf="payoutShowTeamPayouteport" class="export__payout"
    [id]="'TeamPayouteport'" [title]="'Export Team Payout Report to Excel'" [downloadInactive]="showPayoutReportLoader"
    (notifyDownloadClick)="exportPayoutReport($event)" [labelText]="payoutReportLabelText"></mint-export-excel>

  <mint-export-excel class="export__quota" *ngIf="showTeamQuotaeport" [id]="'TeamQuotaeport'"
    [title]="'Export Team Quota Report to Excel'" [reportFileName]="'ManagerQuotaReviewReport'"
    [downloadInactive]="showQuotaReportLoader" (notifyDownloadClick)="exportQuotaReport($event)"
    [labelText]="quotaReportLabelText"></mint-export-excel>
</div>