import { Component, OnInit, Output, Input } from '@angular/core';
import { CagrService } from '../../cagr/cagr.service';

@Component({
  selector: 'mint-bonus-chart-view',
  templateUrl: './bonus-chart-view.component.html',
  styleUrls: ['./bonus-chart-view.component.scss']
})
export class BonusChartViewComponent implements OnInit {
  returnVal: any;
  accounts: any;
  isLoaded = false;
  arr: any;
  constructor(private cagrService: CagrService) { }
  @Input() bonusComponents;
  @Input() bingoComponents;
  ngOnInit() {
    if (this.bonusComponents != undefined) {
      this.isLoaded = true;
    }
  }
}
