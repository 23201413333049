import { UserSettings } from './user-settings.model';
// import { BaseModel } from '../../../models/base/base.model';
// import { IDeserializable} from '../../../models/base/iDeserializable';
import { plainToClass, Expose, Type } from 'class-transformer';
import { FiscalYear } from './fiscal-year.model';
import { StandardTitle } from './standard-title.model';
import { UserPreference } from './user-preference.model';
import { IncentiveType } from './incentive-type.enum';
import { OptoutDetails } from './opt-out-details.model';
import { BaseModel, IDeserializable, smallLetterKeys } from '@mint-libs/common';

export class UserProfile extends BaseModel implements IDeserializable<UserProfile> {
  @Expose() firstName: string;
  @Expose() lastName: string;
  @Expose() userAlias: string;
  @Expose() loggedInAlias: string;
  @Expose() emailId: string;
  @Expose() personnelNumber: number;
  @Expose() roleId: number;
  @Expose() roleName: string;
  @Expose() incentiveType: string;
  @Expose() isRollOver: boolean;
  @Expose() incentivePlanType: string;
  @Type(() => FiscalYear)
  @Expose()
  fiscalYears: FiscalYear[];
  @Type(() => StandardTitle)
  @Expose()
  standardTitles: StandardTitle[];
  @Type(() => UserPreference)
  @Expose()
  defaultFilter: UserPreference;
  //  @Expose()  directs: Participant[];
  //   @Expose() compliance: Agreement[];
  //    @Expose()  notifications: Notification[];
  @Expose()
  isManager: boolean;
  @Expose() isSeller: boolean;
  @Expose() isNonParticipantManager: boolean;
  @Expose() actAs: string;
  @Expose() disclaimerAcceptedDate: Date | string;
  @Expose() country: string;
  @Expose() segment: string;
  @Expose() isQuotaAcknowledged: boolean;
  @Expose() quotaFCAApprovalPending: boolean;
  @Expose() mSSalesRefreshStamp: Date | string | null;
  @Expose() isPlanOnboarded: boolean;
  @Expose() isDirectsPlanOnboarded: boolean;
  @Expose() isNonParticipantManagerForced: boolean;
  @Expose() isFCA: boolean;
  @Expose() isPPASigned: boolean;
  @Expose() isPPAAvailable: boolean;
  @Type(() => UserSettings)
  @Expose()
  settings: UserSettings;
  //  @Expose() access: UserAccess;
  @Expose() currentParticipationId: number;
  @Expose() currentFiscalYear: number | null;
  @Expose() terminationReason: string;
  @Expose() isAuthroziedByHierarchy: boolean;
  @Expose() isSoftCapRemovalEligible: boolean;
  @Expose() areAllTrainingsCompleted: boolean;
  @Expose() isACREligible: boolean;
  @Expose() areCustomerAddsMetricsApplicable: boolean;
  @Expose() isSoftCapReviewWindowOpen: boolean;
  @Expose() isSoftCapReviewAuthorized: boolean;
  @Expose() terminationDate: Date | string | null;
  @Type(() => OptoutDetails)
  @Expose()
  optoutDetails: OptoutDetails;
  // @Expose() aIFilters: StandardTitle[];
  @Expose() isCAGREligible: boolean;
  @Expose() isDMYEligible: boolean;
  deserialize(from: object): UserProfile {
    const smallLetterFrom = smallLetterKeys(from);
    const userProfile = plainToClass(UserProfile, smallLetterFrom, this.classTransformOptions);
    return userProfile;
  }

  private isIncentiveType(incentiveType: string): boolean {
    return this.incentiveType === incentiveType;
  }

  public isUBIPersona() {
    return this.isIncentiveType(IncentiveType.UBI);
  }
  public isRBIPersona() {
    return this.isIncentiveType(IncentiveType.RBI);
  }

  public isPPIPersona() {
    return this.isIncentiveType(IncentiveType.PPI);
  }

  public isNPM() {
    return this.isNonParticipantManager;
  }

  public isRUBIIPersona() {
    return this.isIncentiveType(IncentiveType.RUBI);
  }

  public isQuotaFCAApprovalPending() {
    return this.quotaFCAApprovalPending;
  }

  public isQuotaAcknowledgementPending() {
    return !this.isQuotaAcknowledged;
  }

  public isEvergreenPPAAvailable() {
    return this.settings.disclaimer && this.settings.disclaimer.disclaimerId === 3;
  }

  public isPPASigningPending() {
    return !this.isPPASigned;
  }

  public isPPANotAvailable() {
    return !this.isPPAAvailable;
  }
}
