<div id="rollover-notification_1" class="notification-alert warning rollover-header" role="alert">
  <div id="rollover-notification_2" class="row">
    <div id="rollover-notification_3">
      <p id="rollover-notification_4" aria-live="polite">
        <span id="rollover-notification_5" class="sr-only">Warning message</span> <span id="rollover-notification_6" aria-hidden="true" class="icon icon-notification" title="Warning"></span>
        <strong id="rollover-notification_7">{{ rolloverMessage }}</strong>
      </p>
    </div>
  </div>
</div>
