import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, Input, SimpleChanges, OnChanges, Output, ChangeDetectionStrategy } from '@angular/core';
import { Formatter } from '@mint-libs/common';
import { MqchService } from '../mqch.service';
import { EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() timelineDataList: any;
  @Output() monthClick = new EventEmitter<any>();
  months: any = [];
  fiscalYearMonths = ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN'];
  fiscalYearMonthsLong = ['July', 'August', 'September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June'];
  timelineList: any = [];
  @ViewChild('timeline__container') timeline__container: ElementRef;
  @ViewChild('timeline__connector') timeline__connector: ElementRef;

  constructor(
    private formatter: Formatter,
    private mqchService: MqchService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.processData();
  }

  // Load the function after the view has rendered in angular
  ngAfterViewChecked() {
    if (this.timeline__connector && this.timeline__connector.nativeElement && this.timeline__container && this.timeline__container.nativeElement) {
      this.timeline__connector.nativeElement.style.width = this.timeline__container.nativeElement.scrollWidth + 'px';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['timelineDataList']) { }
    this.processData();
  }

  processData() {
    const startMonth = new Date(this.mqchService.selectedStandardTitle.value.StartDate).getMonth();
    const endMonth = new Date(this.mqchService.selectedStandardTitle.value.EndDate).getMonth();
    if (this.timelineDataList) {
      this.months = [];
      this.timelineList = [];
      this.processMonthViewData(this.getFiscalYearMonth(startMonth), this.getFiscalYearMonth(endMonth));
      this.addRules();
      this.months = this.timelineDataList.filter(x => x.isMonth);
      console.log('timeline top months ', this.months);
      console.log('timeline events ', this.timelineList);
    }
  }

  processMonthViewData(startMonth, endMonth) {
    const currentMonth = this.getFiscalYearMonth(new Date().getMonth());
    const currentYear = new Date().getFullYear();
    this.timelineDataList.forEach((item, index) => {
      const itemMonthNo = this.getFiscalYearMonth(new Date(item.FiscalMonthName).getMonth());
      item.monthName = this.datePipe.transform(item.FiscalMonthName, 'MMM y');
      item.isMonth = true;
      item.id = 'id_' + this.datePipe.transform(item.FiscalMonthName, 'MMM');
      item.monthNo = itemMonthNo;
      item.isActive = itemMonthNo === currentMonth && currentYear === new Date(item.FiscalMonthName).getFullYear();
      item.isBelowCurrentMonth = !item.FiscalMonthName.includes('2020');
    });

    this.timelineDataList.forEach(item => {
      this.timelineList.push(item);
      item.TimeLineEvents.forEach(li => {
        this.timelineList.push(li);
      });
    });
    this.markMonthActive(currentMonth);
  }

  moveToDate(dateEvent) {
    console.log('clicked on date ', dateEvent);
    const dateId = 'date_' + new Date(dateEvent.EffectiveDate).toDateString().split(' ').join('_');
    setTimeout(() => {
      const el = document.getElementById(dateId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  }

  markMonthActive(monthNo) {
    const currentYear = new Date().getFullYear();
    this.timelineDataList.forEach(item => {
      item.isActive = item.monthNo === monthNo && currentYear === new Date(item.FiscalMonthName).getFullYear();
      item.TimeLineEvents.forEach(evnt => {
        evnt.isActive = item.isActive;
      });
    });
  }

  getFiscalYearMonth(month) {
    return month >= 6 ? month - 6 : month + 6;
  }

  addRules() {
    this.timelineList.forEach((item, cnt) => {
      if (item['isMonth']) {
        item.isUpSideMonth = cnt % 2 ? true : false;
        item.isUpSideCount = cnt % 2 ? false : true;
        item.monthDataUp = cnt % 2 ? item.monthName : '';
        item.monthDataDown = cnt % 2 ? '' : item.monthName;
      } else {
        item.isEventUp = cnt % 2;
        item.eventDate = this.formatter.transformToDateSuffix(item['EffectiveDate']);
        item.eventReason = item['ChangeReason'];
      }
    });
  }

  monthEventClick(month) {
    this.markMonthActive(this.getFiscalYearMonth(new Date(month.FiscalMonthName).getMonth()));
    this.scrollSmoothTo(month);
    this.monthClick.emit(month);
  }

  scrollSmoothTo(monthEvent) {
    const scrollWidthElm = document.getElementById(monthEvent.id);
    if (scrollWidthElm) {
      this.timeline__container.nativeElement.scrollTo({ top: 0, left: scrollWidthElm.offsetLeft, behavior: 'smooth' });
    }
  }
}
