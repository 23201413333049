import { Component, TemplateRef, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import * as _ from 'underscore';
import { DetailDropdownItem } from './models/detail-dropdown-item';
import { DetailDropdownSelectedItem } from './models/detail-dropdown-selected-item';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mint-detail-dropdown',
  templateUrl: './detail-dropdown.component.html',
  styleUrls: ['./detail-dropdown.component.scss']
})
export class DetailDropdownComponent implements OnChanges {
  modalRef;

  newSelectedItem: string;
  newSelectedSubItem: string;
  displayText: string;
  subItems: string[] = [];

  @Input() items: DetailDropdownItem[] = [];
  @Input() itemCaption: string;
  @Input() subItemCaption: string;
  @Input() selectedItem: string;
  @Input() selectedSubItem: string;

  @Output() applyFilter = new EventEmitter<DetailDropdownSelectedItem>();

  constructor(private modalService: NgbModal) {
    this.displayText = 'Loading...';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.selectedItem && changes.selectedItem.currentValue) || (changes.selectedSubItem && changes.selectedSubItem.currentValue)) {
      this.setDisplayText();
    }
  }

  private setDisplayText() {
    this.displayText = `${this.selectedItem} -> ${this.selectedSubItem}`;
  }

  openModal(template: TemplateRef<any>) {
    this.newSelectedItem = this.selectedItem;
    this.newSelectedSubItem = this.selectedSubItem;
    const itemText = this.newSelectedItem;
    this.subItems = _.find(this.items, function(item) {
      return item.text === itemText;
    }).subItems;

    this.modalRef = this.modalService.open(template, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'mint-modal-backdrop', windowClass: 'mint-modal-window' });
  }

  selectItem(selectedItem: string) {
    this.newSelectedItem = selectedItem;
    this.subItems = _.find(this.items, function(item) {
      return item.text === selectedItem;
    }).subItems;

    if (this.subItems.length > 0) {
      this.newSelectedSubItem = this.subItems[0];
    }
  }

  applyDetailDropdownFilter() {
    this.modalRef.close();

    this.selectedItem = this.newSelectedItem;
    this.selectedSubItem = this.newSelectedSubItem;
    this.setDisplayText();
    this.applyFilter.emit(new DetailDropdownSelectedItem(this.selectedItem, this.selectedSubItem));
  }

  cancelDetailDropdownFilter() {
    this.modalRef.close();
  }
}
