import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DemoContext {
  public urlMappingArray =  {};
  public selectedDemoRole: any =  {};
  public isDemotoggled =  false;
  public isRecordMode =  false;
  public filePath =  "/dist/app/features/demo/jsonFiles/";
  public recordForRole =  "Technology Solution Professional";
}
