import { EndpointService } from '@mint-libs/network';
import { DataService } from '../../core/network/data.service';
import { Injectable } from '@angular/core';
import { CustomHttpParams } from '../../core/network/custom-http-params';
import { BucketParams } from '../models/bucket-params.model';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserProfileService } from '../../core/context/user-profile.service';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class MetricGridService {
  public sharedContext: SharedContext = new SharedContext();
  constructor(private endpoint: EndpointService, public userProfileService: UserProfileService, private dataService: DataService, public sharedContextState: Store<SharedContext>) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }

  getRefreshTimesOfDataSources() {
    let $dataSourceRefreshTime = this.dataService
      .getWithGlobalFilters<any[]>(this.endpoint.GetRefreshTimesOfDataSources.uri, this.endpoint.GetRefreshTimesOfDataSources.params)
      .pipe(catchError(err => of([])));
    return $dataSourceRefreshTime;
  }

  getTargetSummary(bucketParams: BucketParams = null, fromFreezeSnapshot: boolean = false, publicationId = 0, publicationDate = null, isPccAdjustmentRequired = false) {
    let params = this.getBucketParams(bucketParams);
    if (fromFreezeSnapshot) {
      params = params.append('FromFreezeSnapshot', String(fromFreezeSnapshot));
    }
    params = params.append('PublicationId', String(publicationId));
    params = params.append('PublicationDate', String(publicationDate));
    params = params.append('IsPccAdjustmentRequired', String(isPccAdjustmentRequired));
    let isDMYEligible = this.sharedContext.IsDMYEligible;
    params = params.append('IsDMYElligible', `${isDMYEligible}`);
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetTargetSummary.uri, params);
  }

  getAllPaylines(bucketParams: BucketParams = null) {
    const params = this.getBucketParams(bucketParams);
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetAllPaylines.uri, params);
  }

  getPayoutSummary(fromFreezeSnapshot: boolean = false, useNewPayoutAPI: boolean = false, isOptOut: boolean = false) {
    let params = CustomHttpParams.getInstance();
    if (fromFreezeSnapshot) {
      params = params.append('FromFreezeSnapshot', String(fromFreezeSnapshot));
      if (useNewPayoutAPI) {
        params = params.append('IsOptOut', String(isOptOut));
      }
    }
    params = params.append('IsHardTransferFeatureEnabled', String(environment.isHardTransferFeatureEnabled)); // remove it
    return useNewPayoutAPI
      ? this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetPayoutSummaryInfo.uri, params, null, environment.payoutApiUrl)
      : this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetPayoutSummary.uri, params);
  }

  getRubiPCGMetrics() {
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetRubiPCGMetrics.uri, this.endpoint.GetRubiPCGMetrics.params);
  }

  getBucketParams(bucketParams: BucketParams) {
    let params = CustomHttpParams.getInstance();
    if (!bucketParams) {
      return params;
    }
    if (bucketParams.bucketType) {
      params = params.append('BucketType', bucketParams.bucketType);
    }
    if (bucketParams.periodStatus) {
      params = params.append('PeriodStatus', bucketParams.periodStatus);
    }
    if (bucketParams.incentiveType) {
      params = params.append('IncentiveType', bucketParams.incentiveType);
    }
    return params;
  }
}
