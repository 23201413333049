import { Component, Inject, OnInit, Input, ElementRef, AfterViewChecked } from '@angular/core';
import { CompensationPlanService } from '../../compensation-plan/compensation-plan.service';
import { Formatter } from '@mint-libs/common';
import { EmployeeInfo } from './employee-info.model';
import { from } from 'rxjs';
import { FiltersService } from '../../filters/filters.service';
import { UserProfileService } from '../../core/context/user-profile.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
import { GraphService } from '@mint-libs/context';

@Component({
  selector: 'mint-employee-info',
  templateUrl: './employee-info.component.html',
  styleUrls: ['./employee-info.component.scss'],
  providers: [CompensationPlanService]
})
export class EmployeeInformationComponent implements OnInit, AfterViewChecked {
  isRoleInformationError: any;
  errorMessage = 'An Error Occurred.';
  employeeInfo: EmployeeInfo;
  filterService: any;
  thumbnailPhoto: any;
  userName: any;
  userNameWithRole: any;
  loaded = false;
  hrLastRefreshDate;
  employeeCountry: string;
  @Input()
  enableTour: boolean;
  @Input()
  tourName: string;
  @Input()
  isOptoutView = false;
  @Input()
  fromPayout = false;
  @Input() hideTour = false;
  public sharedContext: SharedContext = new SharedContext();
  constructor(
    private compensationPlanService: CompensationPlanService,
    filterService: FiltersService,
    private graphService: GraphService,
    public userProfileService: UserProfileService,
    public sharedContextState: Store<SharedContext>,
    private formatter: Formatter,
    private element: ElementRef
  ) {
    this.filterService = filterService;
    this.enableTour = true; // by default the tour to be enabled
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
  }
  ngAfterViewChecked(): void {
    // adding alt text to ngx-avatar
    const avtarImg = this.element.nativeElement.querySelector('ngx-avatar img');
    if (avtarImg) {
      avtarImg.setAttribute('alt', this.employeeInfo.getUserName());
    }
  }
  ngOnInit() {
    this.employeeCountry = this.sharedContext.country;
    this.getHrRefreshDate();
    this.getEmployeeInformation();
  }

  getHrRefreshDate() {
    this.compensationPlanService.getNonRevenueDataSourceRefreshTimes('FeedStore', this.fromPayout).subscribe(refreshDate => {
      if (refreshDate) {
        this.hrLastRefreshDate = this.formatter.transformToLocalDateTz(refreshDate, 'MMM D, YYYY, h:MM A ', true);
      }
    });
  }

  getEmployeeInformation() {
    this.compensationPlanService.getEmployeeInformation(this.fromPayout).subscribe(
      result => {
        this.employeeInfo = result;
        this.userNameWithRole = `${this.employeeInfo.getUserName()} (${this.filterService.selectedStandardTitle.Name})`;
        this.loaded = true;

        // Load the Employee Thumbnail photo after Employee Information is retrived
        const graph = from(this.graphService.getThumbnailPhoto(this.employeeInfo.alias));
        graph.subscribe(thumbnailResult => {
          if (thumbnailResult) {
            this.thumbnailPhoto = `data:image/jpeg;base64,${thumbnailResult}`;
          }
        });
      },
      error => {
        this.isRoleInformationError = true;
      }
    );
  }
}
