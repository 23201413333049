import { Component, OnInit, Input } from '@angular/core';
import { PayoutReportService } from '../../payout-report.service';

@Component({
  selector: 'mint-payout-backup-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsBackupComponent implements OnInit {
  paymentReportTitle = 'Projected Earnings';
  paymentAmount: number;
  @Input() paymentDetails: any;

  constructor(public payoutReportService: PayoutReportService) {}

  ngOnInit() {}

  ngOnChanges() {
    this.getPaymentTitle();
  }

  getPaymentTitle() {
    this.paymentReportTitle = 'Projected Earnings';
    this.paymentAmount = this.paymentDetails.ProjectedEarnings;
    if (this.paymentDetails.PaymentStatusId == 4) {
      this.paymentReportTitle = 'Net Payment';
      this.paymentAmount = this.paymentDetails.NetPaymentAmount;
    }
  }
}
