import { Component, OnInit, Output, Input, Inject, OnChanges, EventEmitter } from '@angular/core';
import { ToggleSwitchConfig } from '../../../../shared/toggle-switch/toggle-switch-config';
import * as Ng1Services from '../../../../core/hybrid/ng1-upgraded-providers';
import { from, Observable } from 'rxjs';
import { SoftCapApprovalStatus } from '../../models/soft-cap-approval-status.enum';
import * as _ from 'underscore';
import { SoftCapAction } from '../../models/soft-cap-action.enum';
import { Formatter } from '@mint-libs/common';
import { MessagesService } from '@mint-libs/context';
import { ContextState } from '@mint-libs/context';
import { selectConfigurationState } from '@mint-libs/configuration';
import { select, Store } from '@ngrx/store';
import { Code } from '../../../../app.constants';
import { filter } from 'rxjs/operators';
import { FiltersService } from '../../../../filters/filters.service';
import { UserProfileService } from '../../../../core/context/user-profile.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';

@Component({
  selector: 'mint-metric-soft-cap',
  templateUrl: './metric-soft-cap.component.html',
  styleUrls: ['./metric-soft-cap.component.scss'],
  providers: [Code]
})
export class MetricSoftCapComponent implements OnInit, OnChanges {
  metricLabels = [];
  config: any;
  filterService: any;
  message: Observable<any>;
  softCapEligibleMessage: string;
  isSoftCapRemovalEligibleWindow: boolean;
  enableDisableSubmit = false;
  placeholderText = '';
  softCapTooltipMessages = ['Attainment', 'SoftCapPayoutPercentage', 'SoftCapPayout', 'UnCappedPayoutPercentage', 'UnCappedPayout', 'PayoutAboveCap', 'Utilization'];
  softCapEndDate: any;
  isUBI: boolean;
  utilizationBucket: any;
  consumptionBucket: any;
  @Input() directSoftCapData: any;
  @Input() isSubmittedFlag: boolean;
  @Output() toggleSwitchConfig: ToggleSwitchConfig;
  @Output() directSoftCapChangesCountChange = new EventEmitter<boolean>();
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private messageService: MessagesService,
    public userProfileService: UserProfileService,
    filterService: FiltersService,
    private formatter: Formatter,
    public sharedContextState: Store<SharedContext>,
    private contextState: Store<ContextState>
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.filterService = filterService;
  }

  ngOnInit() {
    this.getSoftCapEligibleMessage();
    this.getSoftCapTooltipMessages();
    this.getWindowEndDate();
    this.isSoftCapRemovalEligibleWindow = this.sharedContext.IsSoftCapReviewWindowOpen;
    const elems = document.getElementsByTagName('textarea');
    if (this.isSubmittedFlag) {
      for (let index = 0; index < elems.length; index++) {
        elems[index].placeholder = '';
      }
    } else {
      for (let index = 0; index < elems.length; index++) {
        elems[index].placeholder = ``;
      }
    }

    this.isUBI = this.directSoftCapData.softCapInfo.softCapPCGDetails[0].PeriodStatus == null ? false : true;
    if (this.isUBI) {
      this.createBucketedList();
    }
  }

  createBucketedList() {
    this.utilizationBucket = [];
    this.consumptionBucket = [];
    this.directSoftCapData.softCapInfo.softCapPCGDetails.forEach(element => {
      if (element.PCGName && element.PCGName.startsWith('Utilization')) {
        const a = {
          Attainment: element.Attainment,
          BusinessJustification: element.BusinessJustification,
          Currency: element.Currency,
          EarningPercentageAfterCap: element.EarningPercentageAfterCap,
          EarningPercentageBeforeCap: element.EarningPercentageBeforeCap,
          EarningsAfterCap: element.EarningsAfterCap,
          PCGID: element.PCGID,
          PCGName: element.PCGName,
          PeriodStatus: element.PeriodStatus,
          EarningsBeforeCap: element.EarningsBeforeCap,
          SystemApproval: element.SystemApproval,
          ManagerApproval: element.ManagerApproval,
          FinalApproval: element.FinalApproval,
          ManagerSubmittedDate: element.ManagerSubmittedDate,
          ICBMManagerSubmittedDate: element.ICBMManagerSubmittedDate,
          softCapEligible: element.softCapEligible
        };
        this.utilizationBucket.push(a);
      } else {
        const a = {
          Attainment: element.Attainment,
          BusinessJustification: element.BusinessJustification,
          Currency: element.Currency,
          EarningPercentageAfterCap: element.EarningPercentageAfterCap,
          EarningPercentageBeforeCap: element.EarningPercentageBeforeCap,
          EarningsAfterCap: element.EarningsAfterCap,
          PCGID: element.PCGID,
          PCGName: element.PCGName,
          PeriodStatus: element.PeriodStatus,
          EarningsBeforeCap: element.EarningsBeforeCap,
          SystemApproval: element.SystemApproval,
          ManagerApproval: element.ManagerApproval,
          FinalApproval: element.FinalApproval,
          ManagerSubmittedDate: element.ManagerSubmittedDate,
          ICBMManagerSubmittedDate: element.ICBMManagerSubmittedDate,
          softCapEligible: element.softCapEligible
        };
        this.consumptionBucket.push(a);
      }
    });
  }

  ngOnChanges() {
    _.each(
      this.directSoftCapData.softCapInfo.softCapPCGDetails,
      function(pcg, index) {
        pcg.softCapEligible = true;
        if (pcg.SystemApproval == null && pcg.ManagerApproval == null && pcg.FinalApproval == null) {
          pcg.softCapEligible = false;
        }
      },
      this
    );
  }

  initializeToggleSwitchConfig(approvalStatus: number, participationid: number, pcgName: string) {
    const toggleSwitchConfig = new ToggleSwitchConfig();
    toggleSwitchConfig.target = 'Softcap';
    toggleSwitchConfig.helpText = 'Press enter to approve/decline';
    toggleSwitchConfig.source = 'Softcap';
    toggleSwitchConfig.isChecked = approvalStatus === 1 ? true : false;
    toggleSwitchConfig.titleRight = this.getApprovalType(approvalStatus);
    toggleSwitchConfig.id = 'softcapApproval_' + participationid + '_' + pcgName.trim();
    toggleSwitchConfig.toggleCssClass = 'softcap__lbl';
    toggleSwitchConfig.navigateOnToggle = true;
    toggleSwitchConfig.tracker = 'Softcap_Approval';
    return toggleSwitchConfig;
  }

  getSoftCapEligibleMessage() {
    this.messageService.get(Code.SoftCapNotEligible).subscribe(response => {
      this.softCapEligibleMessage = response;
    });
  }

  getSoftCapTooltipMessages() {
    // this.messageService.get(this.code.SoftCapTooltipMessages).subscribe(response => {
    //   const text = JSON.parse(<any>response);
    this.softCapTooltipMessages['Attainment'] =
      'Attainment % = Actuals/Target. For billed revenue metrics in Enterprise and SMC, attainment is calculated as ((Total Actuals - Recurring Target)/(Total Target - Recurring Target)) and for Azure Consumption, attainment is calculated as ((Total Actuals – Baseline)/(Total Target – Baseline)).';
    this.softCapTooltipMessages['Utilization'] =
      'Utilization is calculated as total actual logged hours/target of your assigned Enterprise accounts and timezone. To understand which hours are measured, refer to your plan summary on the My Compensation Plan page. Target and Actuals included in the attainment calculation include logged and approved hours in upstream tools through the last full fiscal week.';
    this.softCapTooltipMessages['SoftCapPayoutPercentage'] =
      'The maximum payout earnings percentage for the listed metric that a seller on this plan can earn without any additional approval. The percentage displayed is based on the Payout% during your Active time in role.';
    this.softCapTooltipMessages['SoftCapPayout'] =
      'The maximum payout earnings in local currency, for the listed metric that a seller can earn without any additional approval. The amount shown includes any Earnings received from TA or LOA statuses.';
    this.softCapTooltipMessages['UnCappedPayoutPercentage'] =
      'The actual payout earnings percentage for the listed metric. Sellers can only earn beyond the soft cap payout to the uncapped payout with manager approval and business justification. No seller may earn beyond the designated hard cap for the listed metric. The percentage displayed is based on the Payout% during your Active time in role.';
    this.softCapTooltipMessages['UnCappedPayout'] =
      'The actual payout earnings in local currency, for the listed metric. Sellers can only beyond the soft cap payout amount to the uncapped payout with manager approval and business justification. No seller may earn beyond the designated hard cap for the listed metric. The amount shown includes any Earnings received from TA or LOA statuses.';
    this.softCapTooltipMessages['PayoutAboveCap'] = 'Incremental payout earnings beyond the capped payout amount if the seller is approved to exceed the cap.';
    // });
  }

  getWindowEndDate() {
    const key = 'SoftCapWindowEndDate_FY' + this.filterService.selectedFiscalYear.toString().substr(2, 4);
    this.getConfigDetails();
    this.softCapEndDate = this.config[key];
  }

  getFormattedValue(value: number) {
    return this.formatter.formatAndRoundOff(value);
  }

  getApprovalType(id: number): any {
    return SoftCapApprovalStatus[id];
  }

  getRecommendType(id: number): any {
    return SoftCapAction[id];
  }

  getSubmittedDate(date: Date) {
    return this.formatter.transformToDateFormat(date, 'mediumDate', 'en-US');
  }

  getApprovalId(name: string): any {
    return SoftCapApprovalStatus[name];
  }

  updateSoftCap(event: any, participationid: number, pcgName: string) {
    const direct = this.directSoftCapData;
    const pcg = direct.softCapInfo.softCapPCGDetails.find(d => d.PCGName === pcgName);
    pcg.ManagerApproval = this.getApprovalId(event.title);
    if (pcg.ManagerApproval === pcg.SystemApproval) {
      direct.softCapInfo.directsPCGSoftCapChangesCount--;
      if (direct.softCapInfo.directsPCGSoftCapChangesCount === 0) {
        direct.softCapInfo.isBusinessJustificationValidationMandate = false;
        this.directSoftCapChangesCountChange.emit(false);
      }
    } else {
      direct.softCapInfo.directsPCGSoftCapChangesCount++;
      if (direct.softCapInfo.directsPCGSoftCapChangesCount === 1) {
        this.directSoftCapChangesCountChange.emit(true);
      }
    }
  }

  updateSoftCapUBI(event: any, participationid: number, pcgName: string) {
    const direct = this.directSoftCapData;
    const pcg = direct.softCapInfo.softCapPCGDetails.find(d => d.PCGName === pcgName);
    direct.softCapInfo.softCapPCGDetails.forEach(pcg => {
      pcg.ManagerApproval = this.getApprovalId(event.title);
      if (pcg.ManagerApproval === pcg.SystemApproval) {
        direct.softCapInfo.directsPCGSoftCapChangesCount--;
        if (direct.softCapInfo.directsPCGSoftCapChangesCount === 0) {
          direct.softCapInfo.isBusinessJustificationValidationMandate = false;
          this.directSoftCapChangesCountChange.emit(false);
        }
      } else {
        direct.softCapInfo.directsPCGSoftCapChangesCount++;
        if (direct.softCapInfo.directsPCGSoftCapChangesCount === 1) {
          this.directSoftCapChangesCountChange.emit(true);
        }
      }
    });
  }

  isUtilizationPCG(pcgName) {
    return pcgName && pcgName.startsWith('Utilization');
  }

  private getConfigDetails() {
    this.contextState
      .pipe(
        select(selectConfigurationState),
        filter(val => val !== undefined)
      )
      .subscribe(state => {
        this.config = state.configs;
      });
  }
}
