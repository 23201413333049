import { Injectable, EventEmitter } from '@angular/core';
import { IncentiveType } from 'src/app/shared/models/incentive-type.enum';

import { EndpointService } from '@mint-libs/network';
import { BonusApiDataService } from '../network/bonus-api-data.service';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
// import 'rxjs/add/operator/concatMap'
import { ConfigurationService } from '@mint-libs/configuration';
// import { TelemetryContext, DataService, StandardTitle } from '@mint-libs/context';
import { CachingService } from '../caching/caching.service';
import { ReportType } from '../../reports/report-type.enum';
import { environment } from '../../../environments/environment';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { StandardTitle } from '@mint-libs/context';
import { TelemetryContext } from '@mint-libs/context';
import { DataService } from '../network/data.service';
import { HttpParams } from '@angular/common/http';
import { CustomHttpParams } from '../network/custom-http-params';
import { Store } from '@ngrx/store';
export class ApplicablePartition {
  StepId: string;
}

export class WidgetRole {
  Type: ReportType;
  Settings: string[];
}

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  // isRBIPersona: boolean;
  // isUBIPersona: boolean;
  // isNPM: boolean;

  // If user profile setting changes due to any thing (e.g. Compliance documets (QA and PPA) then
  // Subscriber like sidemenu bar can re-verify the menu items also any other component can also subscribe like global filter in future.
  private envConfig: any;
  public onUserProfileChange: EventEmitter<boolean> = new EventEmitter();
  sharedContext = new SharedContext();

  constructor(
    private endpoint: EndpointService,
    private bonusDataService: BonusApiDataService,
    private configurationService: ConfigurationService,
    private telemetryContext: TelemetryContext,
    public sharedContextState: Store<SharedContext>,
    private dataService: DataService,
    private cachingService: CachingService
  ) {
    this.envConfig = environment;
    this.init();
  }

  init() {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    // this.isRBIPersona = this.isIncentiveType(IncentiveType.RBI);
    // this.isUBIPersona = this.isIncentiveType(IncentiveType.UBI);
    // this.isNPM = ;
    // this.loadProfile();
  }

  private _isSeller = false;
  private standardTitle: StandardTitle = {
    applicablePartitions: [],
    code: '',
    countryName: '',
    duration: {
      endDate: '',
      startDate: ''
    },
    endDate: '',
    fiscalYear: {
      key: -1,
      duration: {
        endDate: '',
        startDate: ''
      }
    },
    isMic2Profile: false,
    isParticipationCompliant: false,
    isPlanActive: false,
    isPlanRollOver: false,
    maxStep: -1,
    mic2Role: '',
    payeeId: 0,
    name: '',
    startDate: '',
    planId: 0,
    planName: '',
    planStartDate: '',
    planEndDate: '',
    participationID: 0,
    minStep: 0
  };

  public getUserAlias() {
    return this.sharedContext.alias;
  }
  private isIncentiveType(incentiveType: string): boolean {
    return this.sharedContext.incentiveType === incentiveType;
  }

  public getIncentiveType() {
    return this.sharedContext.incentiveType;
  }

  public notifyUserProfileChange() {
    this.onUserProfileChange.emit(true);
  }
  public isUBIPersona() {
    return this.isIncentiveType(IncentiveType.UBI);
  }
  public isRBIPersona() {
    return this.isIncentiveType(IncentiveType.RBI);
  }

  public isPPIPersona() {
    return this.isIncentiveType(IncentiveType.PPI);
  }

  public isNPM() {
    return this.sharedContext.IsNonParticipantManager;
  }

  public isNPMForced() {
    return this.sharedContext.IsNonParticipantManagerForced;
  }

  public isParticipantManager() {
    return this.sharedContext.isManager && !this.sharedContext.IsNonParticipantManager;
  }

  public isSeller() {
    return this._isSeller;
  }

  public isRUBIIPersona() {
    return this.isIncentiveType(IncentiveType.RUBI);
  }

  public isCompliant() {
    return this.sharedContext.isQuotaAcknowledged;
  }

  public getStandardTitleList() {
    return this.sharedContext.StandardTitles;
  }

  public getIsDMYEligible() {
    return this.sharedContext.IsDMYEligible;
  }

  getBonusEligiblityStatus(participationId, fiscalYear): Observable<any> {
    return this.configurationService.getVersionConfiguration(fiscalYear).pipe(
      concatMap((response: any) => {
        this.endpoint.GetBonusDetails.params['ParticipationId'] = participationId;
        this.endpoint.GetBonusDetails.params['ActiveVersion'] = response.result.activeVersion;
        return this.bonusDataService.get<any[]>(this.endpoint.GetBonusDetails.uri, this.endpoint.GetBonusDetails.params);
      })
    );
  }

  public getAwardConsideration() {
    return this.sharedContext.awardConsideration;
  }

  public loadProfile(sharedContext) {
    let httpParams = CustomHttpParams.getInstance();
    if (sharedContext.isImpersonating) {
      httpParams = httpParams.append('alias', sharedContext.impersonatingAlias);
    } else {
      httpParams = httpParams.append('alias', sharedContext.alias);
    }
    return this.dataService
      .getWithGlobalFilters(this.envConfig.mintapiBaseUrl + this.endpoint.GetParticipantDetails.uri, httpParams)
      .toPromise()
      .then(result => {
        this.setProfile(result);
        return result;
      });
  }

  public setSNOWProfile(profile) {
    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        alias: profile.UserAlias,
        firstName: profile.FirstName,
        lastName: profile.LastName,
        IsNonWwicAndMic2Eligible: profile.IsNonWwicAndMic2Eligible
      })
    );
  }

  public isOptoutEligibleAndInWindow() {
    var isEligible = false;
    var date = new Date();
    var currentDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    if (
      this.sharedContext.optoutDetails.isEligible &&
      !this.sharedContext.optoutDetails.isPreviousParticipation &&
      !this.sharedContext.optoutDetails.isOptOut &&
      currentDate >= new Date(this.sharedContext.optoutDetails.optOutStartDate) &&
      currentDate <= new Date(this.sharedContext.optoutDetails.optOutEndDate)
    ) {
      isEligible = true;
    }
    return isEligible;
  }

  public isOptoutEligibleWindow() {
    var isEligible = false;
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (
      this.sharedContext.optoutDetails.isEligible &&
      !this.sharedContext.optoutDetails.isPreviousParticipation &&
      currentDate >= new Date(this.sharedContext.optoutDetails.optOutStartDate) &&
      currentDate <= new Date(this.sharedContext.optoutDetails.optOutEndDate)
    ) {
      isEligible = true;
    }
    return isEligible;
  }

  public setOptoutDetails(result, sharedContext) {
    let newSharedContext = { ...sharedContext, optoutDetails: { ...sharedContext.optoutDetails } };
    if (result && result.OptoutDetails) {
      newSharedContext.optoutDetails.isEligible = result.OptoutDetails.IsEligible;
      newSharedContext.optoutDetails.isOptOut = result.OptoutDetails.IsOptOut;
      newSharedContext.optoutDetails.partcipationEndDate = result.OptoutDetails.PartcipationEndDate;
      newSharedContext.optoutDetails.optOutStartDate = result.OptoutDetails.OptOutStartDate;
      newSharedContext.optoutDetails.optOutEndDate = result.OptoutDetails.OptOutEndDate;
      newSharedContext.optoutDetails.nextOptOutStartDate = result.OptoutDetails.NextOptOutStartDate;
      newSharedContext.optoutDetails.nextOptOutEndDate = result.OptoutDetails.NextOptOutEndDate;
      // append 'Z' to the date string to indicate UTC time if the timezone isn't already specified
      if (result.OptoutDetails.OptOutDate && result.OptoutDetails.OptOutDate.indexOf('Z') === -1 && result.OptoutDetails.OptOutDate.indexOf('+') === -1) {
        result.OptoutDetails.OptOutDate += 'Z';
      }
      newSharedContext.optoutDetails.optOutDate = result.OptoutDetails.OptOutDate;
      newSharedContext.optoutDetails.isPreviousParticipation = false;

      var currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (newSharedContext.optoutDetails.partcipationEndDate && currentDate > new Date(newSharedContext.optoutDetails.partcipationEndDate)) {
        newSharedContext.optoutDetails.isPreviousParticipation = true;
      }

      newSharedContext.optoutDetails.wasEligibleInPreviousCountry = result.OptoutDetails.WasEligibleInPreviousCountry;
      newSharedContext.optoutDetails.didOptOutInPreviousCountry = result.OptoutDetails.DidOptOutInPreviousCountry;
      newSharedContext.optoutDetails.isEligibleInNextCountry = result.OptoutDetails.IsEligibleInNextCountry;
    }
    return newSharedContext;
  }

  public setProfile(result) {
    let sharedContext = { ...this.sharedContext };
    sharedContext.alias = result.UserAlias;
    sharedContext.emailId = result.EmailId;
    sharedContext.firstName = result.FirstName;
    sharedContext.lastName = result.LastName;
    sharedContext.personnelNumber = result.PersonnelNumber as number;
    sharedContext.incentivePlanType = result.IncentivePlanType;
    sharedContext.incentiveType = result.IncentiveType;
    sharedContext.segment = result.Segment;
    sharedContext.country = result.Country;
    this._isSeller = result.IsSeller;
    sharedContext.isSeller = this._isSeller;
    sharedContext.isASeller = this._isSeller;
    sharedContext.IsNonParticipantManager = result.IsNonParticipantManager;
    sharedContext.isManager = result.IsManager;
    sharedContext.role = result.RoleId;

    sharedContext.FeatureRole = result.RoleName;
    sharedContext.agreementDetails = result.Compliance;
    sharedContext.isPPASigned = result.IsPPASigned;
    sharedContext.isPPAAvailable = result.IsPPAAvailable;
    sharedContext.isQuotaAcknowledged = result.IsQuotaAcknowledged;
    sharedContext.QuotaFCAApprovalPending = result.QuotaFCAApprovalPending;
    sharedContext.msSalesRefreshDate = result.MSSalesRefreshStamp;
    sharedContext.DefaultFilter = result.DefaultFilter;
    sharedContext.isRollOver = result.IsRollOver;
    sharedContext.FiscalYears = result.FiscalYears;
    sharedContext.Compliance = result.Compliance;
    sharedContext.StandardTitles = result.StandardTitles;
    sharedContext.IsNonParticipantManagerForced = result.IsNonParticipantManagerForced;
    sharedContext.IsFCA = result.IsFCA;
    sharedContext.areAllTrainingsCompleted = result.AreAllTrainingsCompleted;
    sharedContext.aiFilters = result.AIFilters;
    sharedContext.terminationReason = result.TerminationReason;
    sharedContext.isACREligible = result.IsACREligible;
    sharedContext.areCustomerAddsMetricsApplicable = result.AreCustomerAddsMetricsApplicable;
    sharedContext.ArePayoutGatesMetricsApplicable = result.ArePayoutGatesMetricsApplicable;
    sharedContext.IsSoftCapReviewWindowOpen = result.IsSoftCapReviewWindowOpen;
    sharedContext.IsSoftCapReviewAuthorized = result.IsSoftCapReviewAuthorized;
    sharedContext.terminationDate = result.TerminationDate;
    sharedContext.aiInEligibleTerminationDate = result.AiInEligibleTerminationDate;
    sharedContext.IsCagrEligible = result.IsCAGREligible;
    sharedContext.managerDashboardPartitionsList = result.ManagerDashboardPartitionsList;
    sharedContext.ManagerViewFiscalYears = result.ManagerViewFiscalYears;
    sharedContext.IsNonWwicAndMic2Eligible = result.IsNonWwicAndMic2Eligible;
    sharedContext.IsDMYEligible = result.IsDMYEligible;
    sharedContext.awardConsideration = result.AwardConsideration;

    sharedContext = this.setOptoutDetails(result, { ...sharedContext });

    if (result.Settings) {
      sharedContext = this.setSettings(result.Settings, sharedContext);
    }

    if (sharedContext.StandardTitles) {
      this.setStepRangeByStandardTitles(sharedContext);
    }
    this.sharedContextState.dispatch(new UpdateSharedContext(sharedContext));
    this.setTelemetryContext(sharedContext);
  }

  public setStepRangeByStandardTitles(sharedContext) {
    var standardTitles = this.sharedContext.StandardTitles;
    for (var i = 0; i < standardTitles.length; i++) {
      var StandardTitlesItem = standardTitles[i];
      var applicablePartitions = StandardTitlesItem.applicablePartitions;
      if (applicablePartitions) {
        var applicablePartitionsLength = applicablePartitions.length;
        StandardTitlesItem.minStep = applicablePartitions[0].stepId;
        StandardTitlesItem.maxStep = applicablePartitions[applicablePartitionsLength - 1].stepId;
      }
    }
  }

  public resetRoles(sharedContext) {
    sharedContext.isASeller = false;
    sharedContext.IsNonParticipantManager = false;
    sharedContext.isManager = false;
    sharedContext.IsFCA = false;
    return sharedContext;
  }

  public setSettings(result, sharedContext) {
    var oldDisclaimerAcceptedDate = 0;
    //$rootScope.canCreateTour = sharedContext.loggedInUserInfo.isSuperAdmin;

    if (sharedContext.Settings && sharedContext.Settings.Disclaimer && sharedContext.Settings.Disclaimer.AcceptedDate) {
      oldDisclaimerAcceptedDate = sharedContext.Settings.Disclaimer.AcceptedDate;
    }

    sharedContext.Settings = JSON.parse(JSON.stringify(result));
    if (oldDisclaimerAcceptedDate && sharedContext.Settings.Disclaimer) {
      sharedContext.Settings.Disclaimer.AcceptedDate = oldDisclaimerAcceptedDate;
    }

    //If already set to support admin, then do not overwrite it for Admin impersonation loading of diff user
    if (sharedContext.isAdmin != true) {
      sharedContext.isAdmin = sharedContext.Settings.IsSupportAdmin;
    }

    if (sharedContext.Settings) {
      sharedContext.releaseGroupName = sharedContext.Settings.ReleaseGroupName;
      sharedContext.releaseGroupId = sharedContext.Settings.ReleaseGroupId;
      sharedContext.isEnrolledIntoGroup = sharedContext.Settings.IsEnrolledIntoGroup;
      sharedContext.isPreviewUser =
        sharedContext.releaseGroupName == 'betaUsers' && sharedContext.releaseGroupId == 1 && sharedContext.isEnrolledIntoGroup && !sharedContext.isDemoMode2 ? true : false;
      sharedContext.isPreviewUser = sharedContext.isPreviewUser;
    }

    if (sharedContext.Settings.IsSupportAdmin === true || sharedContext.Settings.IsSuperAdmin === true) {
      sharedContext.isAdmin = true;
      sharedContext.isSuperAdmin = true;
      sharedContext.isLoggedInUserAdmin = true;
      sharedContext.canSignOnBehalf = sharedContext.Settings.CanSignOnBehalf;
      sharedContext.isQuotaAcknowledged = true;
      sharedContext = this.resetRoles(sharedContext);
    }
    this.sharedContextState.dispatch(new UpdateSharedContext(sharedContext));
    return sharedContext;
  }

  public setTelemetryContext(sharedContext) {
    //This has to only happen for logged in user, should not get set for impersonated user
    if (!this.telemetryContext.isInitialized) {
      this.telemetryContext.userAlias = sharedContext.alias;
      this.telemetryContext.personnelNumber = `${sharedContext.personnelNumber}`;
      this.telemetryContext.segment = sharedContext.segment;
      this.telemetryContext.StandardTitle = sharedContext.DefaultFilter.StandardTitle != null ? sharedContext.DefaultFilter.StandardTitle.Name : '';
      this.telemetryContext.PlanName = sharedContext.DefaultFilter.StandardTitle != null ? sharedContext.DefaultFilter.StandardTitle.PlanName : '';
      this.telemetryContext.incentivePlanType = sharedContext.incentivePlanType;
      this.telemetryContext.country = sharedContext.country;
      this.telemetryContext.isPPASigned = sharedContext.isPPASigned;
      this.telemetryContext.isQuotaAcknowledged = sharedContext.isQuotaAcknowledged;

      this.telemetryContext.isSeller = sharedContext.isASeller;
      this.telemetryContext.isManager = sharedContext.isManager;
      this.telemetryContext.isNonParticipantManager = sharedContext.IsNonParticipantManager;
      this.telemetryContext.isSupportAdmin = sharedContext.isAdmin;
      this.telemetryContext.isFCA = sharedContext.IsFCA;
      this.telemetryContext.isNonWwicAndMic2Eligible = sharedContext.IsNonWwicAndMic2Eligible;
      this.telemetryContext.role = sharedContext.role;
      this.telemetryContext.featureRole = sharedContext.FeatureRole;
      this.telemetryContext.isInitialized = true;
      this.telemetryContext.incentiveType = sharedContext.incentiveType;
    }
  }

  public loadSettings(sharedContext) {
    var params = this.endpoint.GetParticipantSettings.params;
    params.featureRole = this.sharedContext.FeatureRole;
    params.platformRole = this.sharedContext.role;
    // var promise = this.dataService.invoke(this.endpoint.BaseUri + this.endpoint.GetParticipantSettings.uri, this.endpoint.GetParticipantSettings.params);

    let observable = this.dataService.get(this.envConfig.mintapiBaseUrl + this.endpoint.GetParticipantSettings.uri, this.endpoint.GetParticipantSettings.params);

    let promise = observable.toPromise();

    promise.then(result => {
      this.setSettings(result, sharedContext);
    });

    return promise;
  }

  public getUserInitials(firstName, lastName) {
    var initials = '';
    if (firstName && lastName) {
      initials = firstName.charAt(0) + lastName.charAt(0);
    } else if (firstName && !lastName) {
      initials = firstName.charAt(0);
    } else if (!firstName && lastName) {
      initials = lastName.charAt(0);
    }
    return initials.toUpperCase();
  }

  public isSuperAdmin() {
    return this.sharedContext.Settings && this.sharedContext.Settings.IsSuperAdmin == true;
    //return false;
  }

  public isAdminPersona() {
    var isAdminPersona = this.isSuperAdmin();
    if (!isAdminPersona) {
      isAdminPersona = this.sharedContext.IsFCA || (this.sharedContext.Settings && this.sharedContext.Settings.IsSupportAdmin == true);
    }
    return isAdminPersona;
  }

  public loadOptoutDetails() {
    return this.dataService
      .getWithGlobalFilters(this.envConfig.mintapiBaseUrl + this.endpoint.GetOptOutDetails.uri)
      .toPromise()
      .then(result => this.setOptoutDetails(result, { ...this.sharedContext }));
  }

  public optOut() {
    var optoutContent: { [key: string]: any } = {};
    optoutContent.OptedOutDate = new Date();
    optoutContent.TimeZoneOffset = optoutContent.OptedOutDate.getTimezoneOffset();
    var promise = this.dataService.postWithGlobalFilters(this.envConfig.mintapiBaseUrl + this.endpoint.SetOptOut.uri, optoutContent);
    this.clearCache(promise);

    return promise;
  }

  public clearCache(promise) {
    promise.then(() => {
      // Invalidate cache
      this.cachingService.clear();
    });
  }

  public clearCacheSync() {
    this.cachingService.clear();
  }
  // public getQASignedStatus() {
  //   const compliance = this.Compliance;
  //   if (compliance && compliance.length > 0) {
  //     const qaCompliance = compliance.find(t => t.Name === 'QA');
  //     if (qaCompliance) {
  //       return qaCompliance.SignStatusId;
  //     }
  //   }

  //   return null;
  // }

  // public getPPASignedStatus() {
  //   const compliance = this.Compliance;
  //   if (compliance && compliance.length > 0) {
  //     const ppaCompliance = compliance.find(t => t.Name === 'PPA');
  //     if (ppaCompliance) {
  //       return ppaCompliance.SignStatusId;
  //     }
  //   }

  //   return null;
  // }
}
