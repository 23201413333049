<div id="notifications_pane_1" class="pane-notifications">
  <div id="notifications_pane_container_1" class="pane-notifications__container">
    <div id="notifications_pane_2" class="row">
      <div id="notifications_pane_3" class="col-xs-12">
        <h2 id="notifications_pane_4" title="Notifications" class="pane-notification-text pull-left">Notifications</h2>
        <span id="notifications_pane_5" title="close notification pane" class="icon icon-close pull-right" role="button" (click)="closePanel()" (keydown.enter)="closePanel()" tabindex="0"></span>
      </div>
      <div id="notifications_pane_6" class="clearfix"></div>
      <div id="notifications_pane_7" *ngIf="loading">Loading ...</div>
      <div id="notifications_pane_7" *ngIf="!loading && notes.length === 0">There is no Active notification.</div>
    </div>
    <div id="notifications_pane_8" class="row">
      <div id="notifications_pane_9_{{ i }}" class="col-xs-12" *ngFor="let note of notes; let i = index">
        <div id="notifications_pane_10_{{ i }}" [ngClass]="note.NotificationType === 4 ? 'note-msg-banner' : 'note-msg'">
          <div id="notifications_pane_11_{{ i }}" class="note-msg-icon"><span id="notifications_pane_12_{{ i }}" [innerHTML]="note.icon"></span></div>
          <div id="notifications_pane_16_{{ i }}" class="note-msg-note">
            <span id="notifications_pane_17_{{ i }}" [innerHtml]="note.NotificationText"> </span>
            <a *ngIf="note.UrlLink && note.UrlLink.length > 0" href="{{ note.UrlLink }}" target="_blank">  Click Here</a>
            <time id="notifications_pane_18_{{ i }}" [attr.aria-label]="'Notification Start Date' + note.NotificationStartDate">{{ note.NotificationStartDate | ToLocalDate }}</time>
          </div>
        </div>
      </div>
      <div id="notifications_pane_19" class="col-xs-12">
        <hr class="hr" />
        <div class="anchor">
          <a (click)="navigateToAllNotification()" (keydown.enter)="navigateToAllNotification()" role="button" id="SeeAll" (keydown.tab)="closePanel()" tabindex="0">See All Notifications</a>
        </div>
      </div>
    </div>
  </div>
</div>
