import { Expose } from 'class-transformer';

export class TargetProration {
  @Expose({ name: 'Active' })
  active: number;
  @Expose({ name: 'TA' })
  ta: number;
  @Expose({ name: 'Paid' })
  paid: number;
  @Expose({ name: 'Unpaid' })
  unpaid: number;
  @Expose({ name: 'ActiveDays' })
  activeDays: number;
  @Expose({ name: 'TADays' })
  taDays: number;
  @Expose({ name: 'PaidDays' })
  paidDays: number;
}
