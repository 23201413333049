import { Component, OnInit, Input, TemplateRef, ViewChild, Inject } from '@angular/core';
import { PayoutReportService } from '../../../payout-report.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Ng1Services from '../../../../../core/hybrid/ng1-upgraded-providers';
import { LoggerService } from '@mint-libs/logging';

@Component({
  selector: 'mint-payout-report-bucket-target-actuals-tile',
  templateUrl: './bucket-target-actuals.component.html',
  styleUrls: ['./bucket-target-actuals.component.scss']
})
export class BucketTargetActualsComponent implements OnInit {
  displayedColumns: string[];
  totalBucketTarget: number;
  totalBucketActuals: number;
  modalRef;
  selectedMetric: any;
  actualsColumnTitle: string;
  bucketActuals: number;
  @Input() selectedCoreBucket: any;

  constructor(public payoutReportService: PayoutReportService, private modalService: NgbModal, private loggingService: LoggerService) {}

  ngOnInit() {}

  ngOnChanges() {
    this.actualsColumnTitle = 'Total Actuals';
    this.displayedColumns = ['PCCName', 'TotalTarget', 'Actuals', 'TotalRecurringTarget', 'MetricTarget', 'MetricActual'];
    this.selectedMetric = this.selectedCoreBucket.PCCDetails[0];
    this.totalBucketTarget = this.selectedCoreBucket.Metrics.Target;
    this.totalBucketActuals = this.selectedCoreBucket.Metrics.Actuals;
    this.bucketActuals = this.selectedCoreBucket.Metrics.Actuals;
    if (this.selectedCoreBucket.Metrics.TargetRecurring > 0) {
      this.totalBucketTarget = this.totalBucketTarget - this.selectedCoreBucket.Metrics.TargetRecurring;
      this.totalBucketActuals = this.totalBucketActuals - this.selectedCoreBucket.Metrics.TargetRecurring;
    }
    this.checkDMYBucket();
  }

  checkDMYBucket() {
    if (this.selectedCoreBucket.IsDynamicMetric) {
      this.bucketActuals = this.selectedCoreBucket.Metrics.Actuals - this.selectedCoreBucket.Metrics.TargetRecurring;
      this.actualsColumnTitle = 'Commitments Landed';
      this.displayedColumns = ['PCCName', 'TotalTarget', 'Actuals'];
    }
  }

  openModal(template: TemplateRef<any>, selectedMetricDetails: any) {
    this.selectedMetric = selectedMetricDetails;
    this.loggingService.trackEvent('PayoutReport_ChosenMetric', { SelectedMenuItem: this.selectedMetric.PCCName }, true);
    this.modalRef = this.modalService.open(template, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'mint-modal-backdrop', windowClass: 'mint-modal-window-center' });
  }
}
