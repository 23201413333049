import { Injectable } from '@angular/core';
import { DataService } from '../../core/network/data.service';
import { EndpointService } from '@mint-libs/network';
import { catchError, map } from 'rxjs/operators';
import { CustomHttpParams } from '../../core/network/custom-http-params';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanTerritoryDetailsService {
  constructor(private dataService: DataService, private endpoint: EndpointService) {}

  getDataSourceRefreshTimes(fromPayout: boolean = false) {
    let pccParams = CustomHttpParams.getInstance();
    if (fromPayout) {
      pccParams = pccParams.append('FromVersionSnapShot', String(fromPayout));
    }

    let $dataSourceRefreshTime = this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetRefreshTimesOfDataSources.uri, pccParams).pipe(catchError(err => of([])));

    // Todo: Create model class for the response and return array of the same.
    return $dataSourceRefreshTime;
  }

  getNonRevenueDataSourceRefreshTimes(sourceName, fromPayout: boolean = false) {
    let pccParams = CustomHttpParams.getInstance();
    if (fromPayout) {
      pccParams = pccParams.append('FromVersionSnapShot', String(fromPayout));
    }
    // Todo: Create model class for the response and return array of the same.
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetRefreshTimesOfNonRevenueDataSources.uri, pccParams).pipe(
      map(result => {
        let refreshDate;
        const refreshDateItem = result.find(item => item.SourceName === sourceName);
        if (refreshDateItem) {
          refreshDate = refreshDateItem.SourceLastRefreshTime;
        }
        return refreshDate;
      }),
      catchError(err => of([]))
    );
  }

  getPCCInfoForDetailReports(reportType: string, fromPayout: boolean = false): Observable<any> {
    let pccParams = CustomHttpParams.getInstance();
    pccParams = pccParams.append('IncludeTypeAttributes', 'true');
    pccParams = pccParams.append('IncludeSetupAt', 'true');
    pccParams = pccParams.append('ReportType', reportType);
    if (fromPayout) {
      pccParams = pccParams.append('FromVersionSnapShot', String(fromPayout));
    }

    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetPCCInfoForDetailReports.uri, pccParams);
  }

  getProductsAndModifiers(pcgId, pccId) {
    let params = CustomHttpParams.getInstance();
    params = params.append('PcgId', pcgId);
    params = params.append('PccId', pccId);

    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetProductsAndModifiers.uri, params);
  }

  getPlanSummaryReportWithModifiers() {
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.GetPlanDetailsReportWithModifiers.uri, null, null, 'arraybuffer');
  }
}
