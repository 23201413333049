import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { ExcelConfigs } from './excel-config.model';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../../core/network/data.service';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { FiltersService } from '../../filters/filters.service';

@Injectable({
  providedIn: 'root'
})
export class ExportToExcelService {
  constructor(private endpointService: EndpointService, private dataService: DataService, private filter: FiltersService, private datePipe: DatePipe) {}

  handleExcelDownload($result: Observable<any>, excelConfigs: ExcelConfigs) {
    excelConfigs.excelDownloadStatus = true;
    excelConfigs.excelDownloadMessage = 'Downloading...';

    $result.subscribe(
      response => {
        this.downloadOpenFile(response, excelConfigs.fileName);
        excelConfigs.excelDownloadStatus = false;
        excelConfigs.excelDownloadMessage = '';
      },
      err => {
        excelConfigs.excelDownloadStatus = false;
        excelConfigs.excelDownloadMessage = 'Download Failed';
      }
    );
  }

  // getFileName(fileName, withPeriod, isManagerView = false) {
  //   return this.exportToExcelWrapperService.GetFileName(fileName, withPeriod, isManagerView);
  // }

  getExporttoexcelDetails(htmlTable, reportName, pcg_pcc_List = null, settings = null, reportType = null) {
    const endPoint = this.endpointService.GetExporttoexcelDetails.uri;
    const params = this.endpointService.GetExporttoexcelDetails.params;
    params.HTMLTable = htmlTable;
    params.FileName = reportName || 'Sample';
    params.Pcg_Pcc_List = pcg_pcc_List;
    params.Settings = settings;
    params.ReportType = reportType;

    return this.dataService.push(endPoint, params, null, 'arraybuffer').subscribe(
      data => {
        this.downloadOpenFile(data, reportName);
      },
      function(response) {
        this.showErrorBanner(response);
        //   return deferred.promise;
      }
    );
  }

  downloadOpenFile(wbout, reportName) {
    const data = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, reportName + '.xlsx');
  }

  getFileName(fileName, withPeriod = false, isManagerView = false) {
    const startDate = this.filter && this.filter.selectedStandardTitle?.StartDate ? new Date(this.filter.selectedStandardTitle.StartDate) : null;
    const endDate = this.filter && this.filter.selectedStandardTitle?.EndDate ? new Date(this.filter.selectedStandardTitle.EndDate) : null;
    if (startDate && endDate) {
      return (
        fileName +
        '_' +
        this.getFormattedDate(startDate) +
        '_TO_' +
        this.getFormattedDate(endDate) +
        (withPeriod
          ? isManagerView
            ? this.getSelectedPeriod(this.filter.managerViewPartitions, this.filter.selectedManagerViewStepId)
            : this.getSelectedPeriod(this.filter.selectedStandardTitle.ApplicablePartitions, this.filter.selectedStepId)
          : '')
      );
    } else {
      return fileName;
    }
  }

  pad(n) {
    return n < 10 ? '0' + n : n.toString();
  }

  getFormattedDate(date) {
    return this.pad(date.getDate()) + this.datePipe.transform(date, 'MMM').toUpperCase() + date.getFullYear();
  }

  getSelectedPeriod(partitions, stepId) {
    const partition = partitions.find(x => x.StepId === stepId);
    return '_' + partition.Key.replace('-', '_');
  }

  arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  downloadOpenPDF(wbout, reportName) {
    const data = new Blob([wbout], { type: 'application/pdf' });
    saveAs(data, reportName + '.pdf');
  }

  showErrorBanner(response) {
    console.error('Error occured: ', response);
    // commonFactory.addNotification({
    //   name: 'error',
    //   message: 'Failed to export report. Please try again.',
    //   dismiss: 8000
    // });
  }
}
