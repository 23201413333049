import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TBonusAttributeDetailItem, BonusConstants } from '@mint-libs/context';
import { Formatter } from '@mint-libs/common';

@Component({
  selector: 'mint-bonus-grain-item-chart',
  templateUrl: './bonus-grain-item-chart.component.html',
  styleUrls: ['./bonus-grain-item-chart.component.scss']
})
export class BonusGrainItemChartComponent implements OnInit, OnChanges {
  @Input()
  grainItem: TBonusAttributeDetailItem;

  @Input()
  isBingo: boolean = false;

  bonusName = BonusConstants.BINGO_BONUS_NAME;
  bonusPlanComponentId = '';

  // Attribute fields
  lockStatus: boolean = true;
  bonusAmount: number = 0.0;
  currencyCode: string = '';
  attainmentString: string = '';
  attainmentPercentage: number = 0.0;
  quotaRecurring: number = 0.0;
  pccUnitLongName: string = '';
  quota: number = 0.0;
  actuals: number = 0.0;

  sourceLastRefresh: string = null;
  sourceNextRefresh: string = null;

  // Config fields
  grainItemType: 'Count' | 'Percentage' = 'Count';

  isSellerRoleEnabled: boolean = false;
  isBucketWeightEnabled: boolean = false;
  planComponentSectionNotes: string = '';
  planComponentSectionIsEnabled: boolean = false;

  attainmentSectionIsEnabled: boolean = false;
  attainmentSectionIsLockStatusEnabled: boolean = false;

  attainmentDescriptionSectionIsEnabled: boolean = false;
  attainmentDescriptionSectionIsTargetMetEnabled: boolean = false;
  attainmentDescriptionSectionUnlockedDescription: string = '';
  attainmentDescriptionSectionLockedDescription: string = '';
  attainmentDescriptionSectionUnlockedTooltip: string = '';
  attainmentDescriptionSectionLockedTooltip: string = '';

  capDetailsSectionIsEnabled: boolean = false;
  capDetailsSectionUnlockedDescription: string = '';
  capDetailsSectionLockedDescription: string = '';
  capDetailsSectionUnlockedTooltip: string = '';
  capDetailsSectionLockedTooltip: string = '';
  attainmentSectionIsProgressEnabled: any;

  constructor(private formatter: Formatter) { }

  ngOnInit(): void {
  }

  
  showTooltip(e, bonusAttribute) {
    if(bonusAttribute.calculationType === 'All')
      return;
      
    // Get the tooltip element
    const tooltipElem: HTMLElement = document.querySelector('#cloud_perf' + bonusAttribute.PlanComponentId);
    if (tooltipElem) {
      tooltipElem.style.display = 'block';
      // Position tooltip
      tooltipElem.style.left = `${e.clientX - 48 - 125}px`;
      tooltipElem.style.top = `${e.clientY - 48 + 10}px`;
      // console.log(`coordinates: (${e.clientX},${e.clientY})`);
    }
  }

  hideTooltip(bonusAttribute) {
    if(bonusAttribute.calculationType === 'All')
      return;
    const tooltipElem: HTMLElement = document.querySelector('#cloud_perf' + bonusAttribute.PlanComponentId);
    if (tooltipElem) {
      tooltipElem.style.display = 'none';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.grainItem.configuration) {
      this.grainItemType = this.grainItem.configuration.grainItemValueType;

      if (this.grainItem.configuration.planComponentSection) {
        this.planComponentSectionIsEnabled = this.grainItem.configuration.planComponentSection.isEnabled;
        this.isSellerRoleEnabled = this.grainItem.configuration.planComponentSection.isSellerRoleEnabled;
        this.isBucketWeightEnabled = this.grainItem.configuration.planComponentSection.isBucketWeightEnabled;
        this.planComponentSectionNotes = this.grainItem.configuration.planComponentSection.notes;
      }

      if (this.grainItem.configuration.attainmentSection) {
        this.attainmentSectionIsEnabled = this.grainItem.configuration.attainmentSection.isEnabled;
        this.attainmentSectionIsLockStatusEnabled = this.grainItem.configuration.attainmentSection.isLockStatusEnabled;
        this.attainmentSectionIsProgressEnabled = this.grainItem.configuration.attainmentSection.isProgressEnabled;
        this.attainmentDescriptionSectionIsTargetMetEnabled = this.grainItem.configuration.attainmentSection.isTargetMetEnabled;
      }

      if (this.grainItem.configuration.attainmentDescriptionSection) {
        this.attainmentDescriptionSectionIsEnabled = this.grainItem.configuration.attainmentDescriptionSection.isEnabled;
        this.attainmentDescriptionSectionUnlockedDescription = this.grainItem.configuration.attainmentDescriptionSection.unlockedDescription;
        this.attainmentDescriptionSectionLockedDescription = this.grainItem.configuration.attainmentDescriptionSection.lockedDescription;
        this.attainmentDescriptionSectionUnlockedTooltip = this.grainItem.configuration.attainmentDescriptionSection.unlockedTooltip;
        this.attainmentDescriptionSectionLockedTooltip = this.grainItem.configuration.attainmentDescriptionSection.lockedTooltip;
      }

      if (this.grainItem.configuration.capDetailsSection) {
        this.capDetailsSectionIsEnabled = this.grainItem.configuration.capDetailsSection.isEnabled;
        this.capDetailsSectionUnlockedDescription = this.grainItem.configuration.capDetailsSection.unlockedDescription;
        this.capDetailsSectionLockedDescription = this.grainItem.configuration.capDetailsSection.lockedDescription;
        this.capDetailsSectionUnlockedTooltip = this.grainItem.configuration.capDetailsSection.unlockedTooltip;
        this.capDetailsSectionLockedTooltip = this.grainItem.configuration.capDetailsSection.lockedTooltip;
      }
    }

    if (this.grainItem.attribute) {
      this.lockStatus = this.grainItem.attribute.LockStatus;
      this.bonusAmount = this.grainItem.attribute.GrainBonusAmount;
      this.currencyCode = this.grainItem.attribute.CurrencyCode;

      this.actuals = this.grainItem.attribute.Actual;
      this.quota = this.grainItem.attribute.Quota
      this.pccUnitLongName = this.grainItem.attribute.PCCUnitLongName;
      this.quotaRecurring = this.grainItem.attribute.QuotaRecurring;

      this.bonusName = this.isBingo ? BonusConstants.BINGO_BONUS_NAME : this.grainItem.attribute.PlanComponentName;
      this.bonusPlanComponentId = this.grainItem.attribute.PlanComponentId;
      this.lockStatus = this.grainItem.attribute.LockStatus;
      this.bonusAmount = this.isBingo ? this.grainItem.attribute.BingoBonusAmount : this.grainItem.attribute.GrainBonusAmount;
      this.currencyCode = this.grainItem.attribute.CurrencyCode;

      if (!this.isBingo) {
        this.attainmentPercentage = this.grainItem.attribute.Attainment;
        if (this.grainItemType === 'Count') {
          this.attainmentString = this.formatter.formatTwoDecimalWithCurrency(this.bonusAmount, this.currencyCode);
        } else {
          this.attainmentString = this.formatter.formatTwoDecimalWithPercent(this.attainmentPercentage);
        }
      }

      if (this.grainItem.attribute.SourceDetails && this.grainItem.attribute.SourceDetails.length > 0) {
        this.sourceLastRefresh = this.grainItem.attribute.SourceDetails[0].SourceLastRefreshTime;
        this.sourceNextRefresh = this.grainItem.attribute.SourceDetails[0].SourceNextRefreshTime;
      }
    }

    if (this.grainItem.summary && this.grainItem.summary.attribute) {
      if (this.grainItem.summary.attribute.productsSummary) {
        if (this.isBingo) {
          this.attainmentPercentage = (this.grainItem.summary.attribute.productsSummary.unlockedProductsCount * 100) / this.grainItem.summary.attribute.productsSummary.totalProductsCount;
          if (this.grainItemType === 'Count') {
            this.attainmentString = `${this.grainItem.summary.attribute.productsSummary.unlockedProductsCount} / ${this.grainItem.summary.attribute.productsSummary.totalProductsCount}`;
          } else {
            this.attainmentString = this.formatter.formatTwoDecimalWithPercent(this.attainmentPercentage);
          }
        }
      }
    }
  }
}
