<div class="core-bucket-detail-tile">
  <div class="core-bucket-detail-header-title">
    Bucket Incentive Award<mint-tooltip id="bucket_incentive_award" [orientation]="'right'" [size]="'small'"
      [tipText]="payoutReportService.roundOff(selectedCoreBucket.calc.PayoutAfterCaps) + '% capped Payout Percentage used for all active days worked calculations'">
    </mint-tooltip>
  </div>
  <div class="core-bucket-detail-body">
    <div class="core-bucket-detail-body-section-lhsamount">
      {{ payoutReportService.formatCurrency(selectedCoreBucket.IncentiveAward) }}
      <span class="core-bucket-detail-body-section-subtitles">{{ currency }}</span>
    </div>
    <div>=</div>
    <div class="core-bucket-detail-body-rhssection">
      <div *ngFor="let applicablePeriodStatus of applicablePeriodStatusList; let j = index">
        <div *ngFor="let userITA of getApplicableUserITAs(applicablePeriodStatus.PeriodStatus); let i = index">
          <div>
            <div *ngIf="j !== 0 || i !== 0" class="core-bucket-detail-body-multiple-rows-summation">Plus</div>
            <div class="core-bucket-detail-body-rhs-row">
              <div>
                <div class="core-bucket-detail-body-section-rhsamount">
                  {{ payoutReportService.roundOff(getPayoutCap(userITA.PeriodStatus, selectedCoreBucket)) }}%</div>
                <div class="core-bucket-detail-body-section-subtitles">Capped Payout Percentage</div>
              </div>
              <div>x</div>
              <div>
                <div class="core-bucket-detail-body-section-rhsamount">
                  {{ payoutReportService.formatCurrency(userITA.ITA) }}
                </div>
                <div class="core-bucket-detail-body-section-subtitles">
                  <div>
                    Full Year Incentive Target Amount
                    <mint-tooltip [orientation]="'right'" [size]="'small'"
                      [tipText]="getFormattedDate(userITA.FromDateTarget) + ' - ' + getFormattedDate(userITA.ToDateTarget)">
                    </mint-tooltip>
                  </div>
                </div>
              </div>
              <div>x</div>
              <div>
                <div class="core-bucket-detail-body-section-rhsamount">{{ payoutReportService.roundOff(selectedCoreBucket.Weightage * 100) }}%</div>
                <div class="core-bucket-detail-body-section-subtitles">Bucket Weight</div>
              </div>
              <div>x</div>
              <div>
                <div class="core-bucket-detail-body-section-rhsamount">
                  ({{ userITA.ITADuration }}/{{ totalDaysInTheYear }})</div>
                <div class="bucket-incentive-award-duration">
                  <div>
                    Number of {{ applicablePeriodStatus.PeriodStatus }} <span
                      *ngIf="applicablePeriodStatus.PeriodStatus == 'Paid'">LOA</span> Days in Role<mint-tooltip
                      [orientation]="'left'" [size]="'small'"
                      [tipText]="userITA.ITADuration + ' ' + applicablePeriodStatus.Proratedtitle + ' days in this role'">
                    </mint-tooltip>
                  </div>
                  <mat-divider></mat-divider>
                  <div>Total Days in Year</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>