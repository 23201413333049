<div class="bonus__views">
    <div class="app__nav app__nav-views">
        <mint-tabs [tabs]="chartGridViewTab" (tabChange)="onTabChange($event)" [activeId]="activeTabId"
            [tracker]="'Bonus_ChartGridView'" [source]="'BonusV2'"></mint-tabs>
    </div>
    <div class="tab__container">
        <div *ngFor="let section of sections">
            <h4 class="pcg__header__txt" *ngIf="section.header">{{ section.header }}</h4>
            <div class="txt-note mb-2" *ngIf="section.headerNotes">{{ section.headerNotes }}</div>
            <div *ngIf="activeTabId === 'chart'">
                <mint-bonus-grain-item-chart *ngFor="let item of section.items" [grainItem]="item" [isBingo]="section.isBingo">
                </mint-bonus-grain-item-chart>
            </div>
            <div *ngIf="activeTabId === 'grid'">
                <mint-bonus-grain-item-grid [grainItems]="section.items" [isBingo]="section.isBingo">
                </mint-bonus-grain-item-grid>
            </div>
        </div>
    </div>
</div>
<div style="height:100px;" id="bonus_tootip_content"></div>