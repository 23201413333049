import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DetailSummaryTilesService } from './detail-summary-tiles/detail-summary-tiles.service';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { FeedbackModule } from '../feedback/feedback.module';
import { QuotaChangesComponent } from './incentive-summary-tiles/quota-changes/quota-changes.component';
import { RevenueRecognitionComponent } from './incentive-summary-tiles/revenue-recognition/revenue-recognition.component';
import { SharedModule } from '../shared/shared.module';
import { CustomHeaderComponent } from '../shared/grid-components/custom-header/custom-header.component';
import { PayoutReportLinkRenderer } from './incentive-summary-tiles/payout-summary/payout-report-link-renderer.component';
import { PayoutReportLinkRenderer_Old } from './incentive-summary-tiles-old/payout-summary/payout-report-link-renderer.component';
import { MetricRendererComponent } from '../shared/metric-grid/renderers/metric-renderer.component';
import { AttainmentRendererComponent } from '../shared/metric-grid/renderers/attainment-renderer.component';
import { ProductGroupRendererComponent } from '../shared/metric-grid/renderers/product-group-renderer.component';
import { DataSourceRendererComponent } from '../shared/metric-grid/renderers/data-source-renderer.component';
import { TooltipTextPayoutSummary } from './incentive-summary-tiles/payout-summary/tooltip-text-payout-summary';
import { TooltipTextPayoutSummary_Old } from './incentive-summary-tiles-old/payout-summary/tooltip-text-payout-summary';
import { TooltipTextBonusPayoutSummary } from './incentive-summary-tiles/bonus-payout-summary/tooltip-text-bonus-payout-summary';
import { SellerDashboardComponent } from './seller-dashboard/seller-dashboard.component';
import { ManagerDashboardModule } from './manager-dashboard/manager-dashboard.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TargetRendererComponent } from '../shared/metric-grid/renderers/target-renderer.component';
import { CagrAttainmentRendererComponent } from '../shared/metric-grid/renderers/cagr-attainment-rendered.component';
import { CagrMetricRendererComponent } from '../shared/metric-grid/renderers/cagr-metric-renderer.component';
import { CagrPccRendererComponent } from '../shared/metric-grid/renderers/cagr-pcc-renderer.component';
import { PinnedUBIFiscalYTDRendererComponent } from '../shared/metric-grid/renderers/fiscal-week-total-renderer.component';
import { EstimatorModule } from '../shared/estimator/estimator.module';
import { FiltersModule } from '../filters/filters.module';
// import { SplashComponent } from '../splash/splash.component';

@NgModule({
  imports: [CommonModule, FormsModule, AgGridModule.withComponents([CustomHeaderComponent, PayoutReportLinkRenderer, PayoutReportLinkRenderer_Old]), FeedbackModule, SharedModule, ManagerDashboardModule, NgbModule, EstimatorModule, FiltersModule],
  declarations: [
    DashboardComponent,
    QuotaChangesComponent,
    RevenueRecognitionComponent,
    MetricRendererComponent,
    AttainmentRendererComponent,
    ProductGroupRendererComponent,
    DataSourceRendererComponent,
    PayoutReportLinkRenderer,
    PayoutReportLinkRenderer_Old,
    SellerDashboardComponent,
    TargetRendererComponent,
    CagrAttainmentRendererComponent,
    CagrMetricRendererComponent,
    CagrPccRendererComponent,
    PinnedUBIFiscalYTDRendererComponent
    // SplashComponent
  ],
  providers: [DetailSummaryTilesService, DatePipe, TooltipTextPayoutSummary, TooltipTextBonusPayoutSummary,TooltipTextPayoutSummary_Old],
  exports: [DashboardComponent]
})
export class DashboardModule { }
