import { Action } from '@ngrx/store';
import { TBonusConfigResponse } from '../types/bonus-config.type';
import { TBonusData } from '../types/bonus-data.type';

export enum BonusActionTypes {
  LoadBonusConfig = '[BonusConfig] Load Bonus Config',
  SignalNoChange = '[BonusConfig] Signal No Change',
  SignalEmptyData = '[BonusConfig] Signal Empty Data',
  SelectBonus = '[BonusConfig] Select Bonus',
  ClearSelectedBonus = '[BonusConfig] Clear Selected Bonus',
  AddBonusData = '[BonusConfig] Add Bonus Data',
}

export class SignalNoChange implements Action {
  readonly type = BonusActionTypes.SignalNoChange;
  constructor() {}
}

export class SignalEmptyData implements Action {
  readonly type = BonusActionTypes.SignalEmptyData;
  constructor() {}
}

export class SelectBonus implements Action {
  readonly type = BonusActionTypes.SelectBonus;
  constructor(public payload: { id: string }) {}
}

export class ClearSelectedBonus implements Action {
  readonly type = BonusActionTypes.ClearSelectedBonus;
  constructor() {}
}

export class LoadBonusConfig implements Action {
  readonly type = BonusActionTypes.LoadBonusConfig;
  constructor(public payload: { bonusConfig: TBonusConfigResponse[] }) {}
}

export class AddBonusData implements Action {
  readonly type = BonusActionTypes.AddBonusData;
  constructor(public payload: { bonusData: TBonusData }) {}
}

export type BonusActions = LoadBonusConfig | AddBonusData | SelectBonus | ClearSelectedBonus | SignalNoChange | SignalEmptyData;
