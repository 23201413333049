<mint-content-loader-bar-chart-horizontal *ngIf="!isLoaded"></mint-content-loader-bar-chart-horizontal>
<div *ngIf="isLoaded">
  <div class="pcg__items">
    <div class="pcg__item pcg__item-scale">
      <div class="pcg__heading"></div>
      <div class="pcg__attainment__container">
        <div class="pcg__timeline">
          <div class="pcg__fy">{{participationStartYear}}</div>
          <div class="pcg__fy">{{participationEndYear}}</div>
        </div>
      </div>
      <div class="pcg__target__container"></div>
    </div>
  </div>

  <div class="pcg__header">
    <h4 class="pcg__header__txt">Meet target in at least one cloud</h4>
    <div class="stat__info">
      <div class="icon icon-info"></div>
      <div class="stat__tooltip stat__tooltip-left">
        <!-- <div class="stat__tooltip__header">Some header</div> -->
        <p class="stat__tooltip__text">
          Click on the information icon for each cloud to view the qualification criteria. Meet the target for one cloud
          and be eligible to receive 25% of annual bonus eligible salary. Meet the target
          for two clouds and be eligible to receive 50% of annual bonus eligible salary. Meet the target of all three
          clouds OR meet the total cloud target listed below and be eligible to receive 100%
          of annual bonus eligible salary.
        </p>
      </div>
    </div>
  </div>
  <mint-chart-horizontal [item]="item" class="pcg__items" *ngFor="let item of accountDetails"></mint-chart-horizontal>

  <div class="pcg__header pcg__header-total">
    <h4 class="pcg__header__txt">Alternatively, meet total cloud target</h4>
    <div class="stat__info">
      <div class="icon icon-info"></div>
      <div class="stat__tooltip stat__tooltip-left">
        <!-- <div class="stat__tooltip__header">Some header</div> -->
        <p class="stat__tooltip__text">
          A total cloud target has been identified to address scenarios where customers are more penetrated in one cloud
          versus another. Meet the total three-year cloud target (listed in your dashboard
          here) across all three clouds to be eligible for 100% of annual bonus eligible salary.
        </p>
      </div>
    </div>
  </div>

  <mint-chart-horizontal [item]="totalCloudGrowthChart"></mint-chart-horizontal>
</div>