import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BonusComponent } from './bonus.component';
import { AgGridModule } from 'ag-grid-angular';
import { CustomHeaderComponent } from '../shared/grid-components/custom-header/custom-header.component';
// import { CagrChartViewComponent } from './cagr-chart-view/cagr-chart-view.component';
// import { CagrGridViewComponent } from './cagr-grid-view/cagr-grid-view.component';
// import { AwardsUnlockedComponent } from './awards-unlocked/awards-unlocked.component';
// import { AgGridModule } from 'ag-grid-angular';
// import { CustomHeaderComponent } from '../shared/grid-components/custom-header/custom-header.component';
import { BonusRoutingModule } from './bonus-routing.module';
// import { ChartHorizontalComponent } from './chart-horizontal/chart-horizontal.component';
import { BonusPct3y3cComponent } from './3y3c-bonus-pct/3y3c-bonus-pct.component';
// import {BonusSharedModule} from '../bonus-shared/bonus-shared.module'
import {CagrModule} from '../cagr/cagr.module'
// import { AwardsUnlockedBoxComponent } from './awards-unlocked/awards-unlocked-box/awards-unlocked-box.component';
// import { ProductsServicesComponent } from './products-services/products-services.component';
// import { RevenueTargetByAccountComponent } from './revenue-target-by-account/revenue-target-by-account.component';
// import { FeedbackModule } from '../feedback/feedback.module';
import { BonusSellerComponent } from './bonus-seller/bonus-seller.component';
import { BonusChartViewComponent} from './bonus-chart-view/bonus-chart-view.component'
import {BonusGridViewComponent} from './bonus-grid-view/bonus-grid-view.component'
import { BonusChartHorizontalComponent } from './bonus-chart-horizontal/bonus-chart-horizontal.component';


@NgModule({
  imports: [CommonModule, NgbModule, SharedModule,AgGridModule.withComponents([CustomHeaderComponent]),BonusRoutingModule,CagrModule],
  declarations: [
    BonusComponent,
    BonusPct3y3cComponent,
    BonusSellerComponent,
    BonusChartViewComponent,
    BonusGridViewComponent,
    BonusChartHorizontalComponent
  ],
  exports: [],
  providers: []
})
export class BonusModule {}
