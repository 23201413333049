import { ITooltipTextGrid } from './tooltip-text-grid';

export class TooltipTextGridUBI implements ITooltipTextGrid {
  Metric = `Each bucket may have one or more metrics. Attainment by metric is shown to better understand performance within the bucket.`;
  Attainment = `Utilization attainment is calculated as total logged hours/target of your assigned Enterprise accounts and timezones. Consumption attainment is calculated as Actuals/Target.`;
  MetriclevelYTDEarnings = `YTD earnings are based on attainment of the individual bucket. Payments are capped at 100% in Q1-Q3 in accordance with the UBI Business Rules. More information on payout calculations are available on the Payout Details report.`;
  YTDQuota = `Year-to-date actuals reflected for the selected role, inclusive of modifiers, multipliers, and adjustments.`;
  YTDActuals = `Year-to-date actuals reflected for the selected role, inclusive of modifiers, multipliers, and adjustments.`;
  QuotaToGo = `Year-to-date remaining quota to reach Target.`;
  DataSource = `Data Source Information.`;
}
