<div class="direct__container" *ngIf="isLoaded">
  <div style="display: flex;">
    <div style="white-space: pre-line; margin-bottom: 1%" [innerHTML]="softCapTitleText"></div>
    <mint-contextual-video [featureId]="'64'" style="margin-top: -3px;">
    </mint-contextual-video>
  </div>
  <div style="white-space: pre-line; margin-bottom: 1%" [innerHTML]="softCapTitleTextBody"></div>
  <div class="softcap__direct" *ngFor="let direct of directs">
    <mint-direct-detail class="direct__detail" [direct]="direct" [isShowDirects]="'false'"></mint-direct-detail>
    <mint-metric-soft-cap class="pcg__softcap" [directSoftCapData]="direct" *ngIf="direct.softCapInfo.isSoftCapEligible"
      (directSoftCapChangesCountChange)="directSoftCapChangesCountChange($event)" [isSubmittedFlag]="isSubmitted">
    </mint-metric-soft-cap>
    <div *ngIf="!direct.softCapInfo.isSoftCapEligible" style="margin-top: 2.5%">Not eligible for Soft Cap Review</div>
  </div>
</div>
<div class="softcap__submit" *ngIf="isLoaded">
  <p *ngIf="!isSubmitted" class="softcap__submit__note">
    *Clicking submit will provide your final decisions on any sellers on your team exceeding the soft cap. All decisions
    are considered final and can't be reversed once clicking the submit button
    below.
  </p>
  <div class="softcap__submit__btns">
    <button *ngIf="!isSubmitted" class="btn btn-primary"
      (click)="openModal(softCapSubmitConfirmation, softCapInfofordirects)">Submit</button>
    <!-- <button class="btn btn-outline-primary">Save</button>
    || impersonationService.isImpersonating
    -->
  </div>
</div>

<ng-template #softCapSubmitConfirmation>
  <div class="modal-header" style="border-bottom: unset">
    <button [disabled]="isConfirmed" type="button" class="close" aria-label="Close" (click)="dismissModal($event)"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body">Are you sure? Clicking submit will finalize your decisions for all eligible sellers and can't
    be reversed.</div>
  <div class="modal-footer" style="border-top: unset">
    <button [disabled]="isConfirmed" type="button" class="btn btn-primary"
      (click)="closeModal($event)">Confirm</button><button [disabled]="isConfirmed" type="button"
      class="btn btn-primary" (click)="dismissModal($event)">Back</button>
  </div>
</ng-template>

<div *ngIf="!isLoaded">
  <div class="content__loader">
    <facebook-content-loader class="direct__content__loader"></facebook-content-loader>
    <mint-content-loader-large class="pcg__content__loader"></mint-content-loader-large>
  </div>
  <div class="content__loader">
    <facebook-content-loader class="direct__content__loader"></facebook-content-loader>
    <mint-content-loader-large class="pcg__content__loader"></mint-content-loader-large>
  </div>
  <div class="content__loader">
    <facebook-content-loader class="direct__content__loader"></facebook-content-loader>
    <mint-content-loader-large class="pcg__content__loader"></mint-content-loader-large>
  </div>
</div>