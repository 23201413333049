import { DatePipe } from '@angular/common';
import { Formatter } from '@mint-libs/common';
import * as Ng1Services from '../../../core/hybrid/ng1-upgraded-providers';
import { BonusPayoutSummaryHelperService } from './bonus-payout-helper.service';
import { SharedService } from '../../../shared/shared.service';
import { Component, Input, AfterViewChecked, OnInit, Inject, ElementRef, HostListener } from '@angular/core';
import { Code } from '../../../app.constants';
import { MessagesService } from '@mint-libs/context';
import { Router } from '@angular/router';
import { UserProfileService } from '../../../core/context/user-profile.service';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
import { CustomHeaderComponent } from './../../../shared/grid-components/custom-header/custom-header.component';
import { PayoutReportLinkRenderer } from './../payout-summary/payout-report-link-renderer.component';
import { FiltersService } from '../../../filters/filters.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-bonus-payout-summary',
  templateUrl: './bonus-payout-summary.component.html',
  styleUrls: ['./bonus-payout-summary.component.scss'],
  providers: [BonusPayoutSummaryHelperService, Code]
})
export class BonusPayoutSummaryComponent implements OnInit, AfterViewChecked {
  @Input() bonusPayoutSummaryResult: any;
  filterService: FiltersService;
  planStartMonth: any;
  planEndMonth: any;
  planDuration: any;
  isOptoutEligibleAndInWindow = false;
  optOutMessage: Observable<any>;
  optOutMessageBanner1: string;
  optOutMessageBanner2: string;
  gridApi;
  gridColumnApi;
  columnDefs: any;
  context;
  gridOptions: any;
  rowData: any;
  groupDefaultExpanded;
  getDataPath;
  autoGroupColumnDef;
  frameworkComponents: any;
  domLayout: any;

  payoutSummaryDataset = null;
  final = null;
  helper = {
    prev: null,
    index: 0,
    codePeriod: '',
    adjustment: false
    // earningStartDate: null
  };
  sharedContext: SharedContext = new SharedContext();

  constructor(
    private bonusPayoutsummaryhelperService: BonusPayoutSummaryHelperService,
    private datePipe: DatePipe,
    private formatter: Formatter,
    private sharedService: SharedService,
    filterService: FiltersService,
    public sharedContextState: Store<SharedContext>,
    private messageService: MessagesService,
    private code: Code,
    private router: Router,
    public userProfileService: UserProfileService,
    private element: ElementRef
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.domLayout = 'autoHeight';
    this.filterService = filterService;
  }

  halfYearlyQuotaNotSupportedPartitions = ['Q3', 'Q4', 'H2'];

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (!this.gridOptions.gridApi) {
      return;
    }

    setTimeout(() => {
      this.gridOptions.gridApi.sizeColumnsToFit();
    });
  }

  ngAfterViewChecked(): void {
    let agGrid = this.element.nativeElement.querySelector('ag-grid-angular div[role="grid"]');
    if (agGrid) {
      agGrid.setAttribute('aria-label', 'Bonus Payout Details');
    }
  }
  ngOnInit(): void {
    this.getDataPath = function(data) {
      return data.CodePeriod;
    };

    this.planDuration = this.sharedContext.DefaultFilter.StandardTitle.PlanDuration;

    this.groupDefaultExpanded = -1;
    this.gridOptions = {
      suppressRowTransform: true,
      columnDefs: this.columnDefs,
      suppressCellSelection: true,
      rowData: this.rowData,
      onGridReady: this.onGridReady,
      rowHeight: 67,
      headerHeight: 52,
      ensureDomOrder: true,
      suppressColumnVirtualisation: true
    };

    this.columnDefs = this.bonusPayoutsummaryhelperService.getColumnDefs();
    this.context = { componentParent: this };
    this.frameworkComponents = {
      agColumnHeader: CustomHeaderComponent,
      payoutReportLinkRenderer: PayoutReportLinkRenderer
    };

    if (this.bonusPayoutSummaryResult) {
      //Remove H2 timeframes if H2 Quota is unavailable // WHy are we doing this????
      if (this.filterService.isH2QuotaSupported(this.filterService.selectedFiscalYear)) {
        this.bonusPayoutSummaryResult = this.bonusPayoutSummaryResult.filter(function(val) {
          return this.halfYearlyQuotaNotSupportedPartitions.indexOf(parseInt(val.CodePeriod.split('-')[1]) === -1 ? true : false);
        }, this);
      }
      this.rowData = this.setBonusPayoutData(this.bonusPayoutSummaryResult);
      if (this.gridOptions && this.gridOptions.gridApi) {
        this.gridOptions.gridApi.sizeColumnsToFit();
      }
    }

    this.checkOptEligiblity();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      params.api.sizeColumnsToFit();
    }, 3000);
  }

  checkOptEligiblity() {
    // Checks the OptOut eligiblity and whether the Optout window is open currently
    // Also ensures that optOut start and end date are present
    if (this.userProfileService.isOptoutEligibleAndInWindow() && this.sharedContext.optoutDetails.optOutStartDate && this.sharedContext.optoutDetails.optOutEndDate) {
      this.isOptoutEligibleAndInWindow = true;
    }

    if (this.isOptoutEligibleAndInWindow) {
      this.messageService.get(Code.PayoutTileOptOutMessages).subscribe(response => {
        this.optOutMessageBanner1 = JSON.parse(<any>response)['BannerMessage1'];
        this.optOutMessageBanner2 = JSON.parse(<any>response)['BannerMessage2'];
      });
    }
  }

  navigateToDetailPayout(data) {
    if (this.userProfileService.isRBIPersona() || this.userProfileService.isUBIPersona() || this.userProfileService.isPPIPersona()) {
      this.sharedContext.appliedFilter.fiscalYear = this.filterService.selectedFiscalYear;

      this.sharedContext.appliedFilter.standardTitle = this.filterService.selectedStandardTitle;
      this.sharedContext.appliedFilter.stepId = data.stepId;
      this.sharedContext.isNavigationFromPayoutSummary = true;

      // This so that if user has multiple payouts in a same quarter, then correspoint payout report table will be autoselected
      this.sharedContext.currentTabIndex = data.detailViewTabIndex;

      //this.sharedContext.PublicationId = data.publicationId;

      this.filterService.previousValues = {};
      this.filterService.previousValues.selectedFiscalYear = this.filterService.selectedFiscalYear;
      this.filterService.previousValues.selectedStandardTitle = this.filterService.selectedStandardTitle;
      this.filterService.previousValues.selectedStepId = this.filterService.selectedStepId;

      this.filterService.selectedFiscalYear = this.filterService.selectedFiscalYear;

      this.filterService.selectedStepId = data.stepId;

      if (this.filterService.selectedStandardTitle.StartDate !== data.earningStartDate) {
        // If the user clicked Payout or Project earnings link that does not below to currenlly selected Participation,
        // than select the corresponding participation and then navigate user to Payout Detail report
        _.each(
          this.sharedContext.StandardTitles,
          function(StandardTitlesItem) {
            if (this.filterService.selectedFiscalYear === StandardTitlesItem.FiscalYear.Key && StandardTitlesItem.StartDate === data.earningStartDate) {
              this.filterService.selectedStandardTitle = StandardTitlesItem;
            }
          },
          this
        );
      }
      if (this.userProfileService.isRBIPersona()) {
        this.sharedService.ngViewReport(1, 1);
      } else {
        this.sharedService.ngViewReport(0, 0);
      }
    } else {
      // code period + payment approval Id
      this.sharedService.ngViewReport(1, 1, data.paymentApprovalId);
    }
  }

  setBonusPayoutData(payoutSummaryResult) {
    payoutSummaryResult.sort((a, b) => a.CodePeriod.localeCompare(b.CodePeriod));
    return (this.payoutSummaryDataset = _.map(
      payoutSummaryResult,
      function(payoutSummaryItem) {
        let detailViewTabIndex = 0;

        if (this.planDuration === 5) {
          payoutSummaryItem.CodePeriod = payoutSummaryItem.CodePeriod.replace('Q2', 'H1');
          payoutSummaryItem.CodePeriod = payoutSummaryItem.CodePeriod.replace('Q4', 'H2');
        }

        payoutSummaryItem.CodePeriod = payoutSummaryItem.CodePeriod.replace('-None', '');

        this.helper.codePeriod = payoutSummaryItem.CodePeriod;

        if (this.helper.prev === payoutSummaryItem.CodePeriod) {
          this.helper.adjustment = true;
        } else {
          this.helper.prev = payoutSummaryItem.CodePeriod;
          this.helper.adjustment = false;
          detailViewTabIndex = 0;
        }
        let netPayment = payoutSummaryItem.NetPayment;
        let currencyCode = payoutSummaryItem.CurrencyCode;
        if (payoutSummaryItem.IsCountryChange) {
          netPayment = payoutSummaryItem.TransferredCurrencyAmount;
          currencyCode = payoutSummaryItem.TransferredCurrencyCode;
        }

        const yTDEarnings = this.formatter.formatWithCurrencyAndRoundOff(payoutSummaryItem.YtdEarnings, payoutSummaryItem.CurrencyCode);
        const prevQuarterPayout = this.formatter.formatWithCurrencyAndRoundOff(payoutSummaryItem.PreviousPaymentAmount, payoutSummaryItem.CurrencyCode);

        const ytdPaymentAdjustmentAndRecoveries = this.formatter.formatWithCurrencyAndRoundOff(payoutSummaryItem.YtdAdjustmentsAndRecoveries, payoutSummaryItem.CurrencyCode);

        let publicationDate: any;
        let payoutDone: any;

        if (payoutSummaryItem.NetPayment) {
          netPayment = this.formatter.formatWithCurrencyAndRoundOff(netPayment, currencyCode);
          if (payoutSummaryItem.PublicationDate) {
            publicationDate = this.datePipe.transform(payoutSummaryItem.PublicationDate, 'MMM d, yyyy').toLocaleString();
          }

          payoutDone = true;
        } else {
          netPayment = this.formatter.formatWithCurrencyAndRoundOff(payoutSummaryItem.ProjectedEarnings, payoutSummaryItem.CurrencyCode) + ' (Projected)';
          publicationDate = 'Pending payment issuance';
          payoutDone = false;
        }
        return {
          codePeriod: this.helper.codePeriod,
          adjustment: this.helper.adjustment, //
          yTDBonusEarnings: yTDEarnings,
          prevQuarterPayout: prevQuarterPayout,
          ytdPaymentAdjustmentAndRecoveries: ytdPaymentAdjustmentAndRecoveries,
          netPaymentAmount: netPayment,
          calcDate: publicationDate,
          payoutDone: payoutDone,
          detailViewTabIndex: detailViewTabIndex,
          earningStartDate: payoutSummaryItem.EarningStartDate
        };
      }.bind(this)
    ));
  }
}
