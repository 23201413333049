import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mint-legends',
  templateUrl: './legends.component.html',
  styleUrls: ['./legends.component.scss']
})
export class LegendsComponent implements OnInit {
  @Input() vertical = false;
  @Input() showOCPLegend = false;
  @Input() showEstimatedEarningsLegend = false;
  constructor() {}

  ngOnInit() {}
}
