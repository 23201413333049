<nav id="sidebar_pane" [ngClass]="{ active: getSidebarStatus() }" class="sidebar__menu" role="navigation">
  <ul id="admin-sidebar_2" role="menu" class="menu__top">
    <li class="list__item" id="admin-sidebar_3" role="menuitem" [class.active]="isFalse">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" id="sidebar-impersonate" title="Impersonate"
          (click)="navigateTo(['admin', 'impersonate'], null)"
          (keyup.enter)="navigateTo(['admin', 'impersonate'], null)" aria-label="Impersonate"
          analytics-tracker="Sidebar_Impersonate_Icon" analytics-type="Click" analytics-element="Icon"
          analytics-source="Sidebar">
          <div id="admin-sidebar_4" class="list__item__icon"><em id="admin-sidebar_5"
              class="icon-ms icon-impersonate"></em></div>
          <div id="admin-sidebar_6" class="list__item__text">Impersonate</div>
        </div>
      </div>
    </li>
    <li class="list__item" id="admin-sidebar_7" role="menuitem" [class.active]="isFalse">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" id="sidebar-planactivation" title="Plan Activation"
          analytics-tracker="Sidebar_PlanActivation_Icon" analytics-type="Click" analytics-element="Icon"
          analytics-source="Sidebar" (click)="navigateTo(['admin', 'planActivation'], null)"
          (keyup.enter)="navigateTo(['admin', 'planActivation'], null)" aria-label="Plan Activation">
          <div id="admin-sidebar_8" class="list__item__icon"><em id="admin-sidebar_9"
              class="icon-ms icon-planactivation"></em></div>
          <div id="admin-sidebar_10" class="list__item__text">Plan Activation</div>
        </div>
      </div>
    </li>
    <!-- <li class="list__item" id="admin-sidebar_19" role="menuitem" [class.active]="isFalse">
      <div class="list__item__container">
        <div
          class="list__item__btn"
          tabindex="0"
          id="sidebar-mngNote"
          title="Manage Notifications"
          analytics-tracker="Sidebar_ManageNotification_Icon"
          analytics-type="Click"
          analytics-element="Icon"
          analytics-source="Sidebar"
          (click)="navigateTo('admin.manageNotification', 'sidebar-mngNote', null, null)"
          (keyup.enter)="navigateTo('admin.manageNotification', 'sidebar-mngNote', null, null)"
          aria-label="Manage Notifications"
        >
          <div id="admin-sidebar_20" class="list__item__icon"><em id="admin-sidebar_21" class="icon icon-bell-outline"></em></div>
          <div id="admin-sidebar_22" class="list__item__text">Manage Notifications</div>
        </div>
      </div>
    </li> -->
    <li class="list__item" id="admin-sidebar_19" role="menuitem" [class.active]="isFalse">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" id="sidebar-mngNote" title="Manage Notifications"
          analytics-tracker="Sidebar_ManageNotification_Icon" analytics-type="Click" analytics-element="Icon"
          analytics-source="Sidebar" (click)="navigateTo(['admin', 'manageNotification'], null)"
          (keyup.enter)="navigateTo(['admin', 'manageNotification'], null)" aria-label="Manage Notifications">
          <div id="admin-sidebar_20" class="list__item__icon"><em id="admin-sidebar_21"
              class="icon icon-bell-outline"></em></div>
          <div id="admin-sidebar_22" class="list__item__text">Manage Notifications New</div>
        </div>
      </div>
    </li>

    <li class="list__item" id="admin-sidebar_23" role="menuitem" [class.active]="isFeatureAdminActive">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" id="sidebar-help" title="Feature Admin"
          analytics-tracker="Sidebar_FeatureAdmin_Icon" analytics-type="Click" analytics-element="Icon"
          analytics-source="Sidebar" (click)="navigateTo(['admin', 'featureAdmin'], null)"
          (keyup.enter)="navigateTo(['admin', 'featureAdmin'], null)" aria-selected="Feature Admin">
          <div id="admin-sidebar_24" class="list__item__icon"><em id="admin-sidebar_25" class="icon icon-admin"></em>
          </div>
          <div id="admin-sidebar_26" class="list__item__text">Feature Admin</div>
        </div>
      </div>
    </li>
  </ul>

  <ul class="menu__bottom" role="menu">
    <li class="list__item" id="sidebar_34" role="menuitem">
      <div class="list__item__container">
        <div class="list__item__btn" id="sidebar_35" tabindex="0"
          title="{{ userProfileInfo.name + ' ' + userProfileInfo.userName }}" style="cursor:pointer;">
          <div id="sidebar_36" class="list__item__icon">
            <ngx-avatar id="sidebar_37" name="{{ userProfileInfo.name }}" initialsSize="2" size="35" [round]="true"
              src="data:image/jpeg;base64,{{ userProfileInfo.thumbnailPhoto }}" textSizeRatio="2.5"></ngx-avatar>
          </div>
          <div id="sidebar_38" class="ellipsis list__item__text user__profile__name">
            <span id="sidebar_39">{{ userProfileInfo.name }}</span>
          </div>
        </div>
      </div>
    </li>
    <!-- <li class="list__item" id="sidebar_40" role="menuitem" [class.active]="isSettingsActive">
      <div class="list__item__container">
        <div
          class="list__item__btn"
          tabindex="0"
          id="sidebar-settings"
          title="Settings"
          (click)="navigateTo('settings', 'sidebar-settings', 'General', false)"
          (keyup.enter)="navigateTo('settings', 'sidebar-settings', 'General', false)"
          analytics-tracker
          [tracker]="'Sidebar_Settings_Icon'"
          [type]="'Click'"
          [element]="'Icon'"
          [source]="'SidebarV2'"
        >
          <div id="sidebar_41" class="list__item__icon"><em id="sidebar_42" class="icon icon-setting"></em></div>
          <div id="sidebar_43" class="list__item__text">Settings</div>
        </div>
      </div>
    </li> -->
    <li class="list__item" id="sidebar_44" role="menuitem">
      <div class="list__item__container">
        <div class="list__item__btn" tabindex="0" id="sidebar-logout" title="Logout" (click)="logout()"
          (keyup.enter)="logout()" analytics-tracker [tracker]="'Sidebar_Logout_Icon'" [type]="'Click'"
          [element]="'Icon'" [source]="'SidebarV2'">
          <div id="sidebar_45" class="list__item__icon"><em id="sidebar_46" class="icon icon-logout"></em></div>
          <div id="sidebar_47" class="list__item__text">Logout</div>
        </div>
      </div>
    </li>
  </ul>
</nav>