import { DEFAULT_PLAN_COMPONENT_NAME, emptyDetailView, emptyHeader } from '../bonus.constants';
import { TBonusConfigResponse, TGrainItem, TGrainItemSection } from '../types/bonus-config.type';
import { TBonusAttribute, TBonusAttributeDetailItem, TBonusAttributeDetailSection, TBonusData, TBonusDetailView, TBonusSummary } from '../types/bonus-data.type';
import { BonusConfig, BonusDocumentConfig } from './bonus-config.model';
import { BonusData } from './bonus-data.model';

export interface IBonusState {
  bonusConfig: TBonusConfigResponse[];
  bonusData?: TBonusData[];
  selectedBonusDocumentId?: string;
  isConfigFetched?: boolean;
}

export class BonusState {
  private bonusConfig: BonusDocumentConfig[] = [];
  private bonusData: BonusData[] = [];
  private selectedBonusDocumentId?: string;
  private isConfigFetched: boolean = false;

  constructor(data?: BonusState | IBonusState) {
    if (data instanceof BonusState) {
      this.bonusConfig = data.getBonusConfig().filter((c) => c.configuration.isEnabled);
      this.isConfigFetched = data.getIsConfigFetched();
      this.bonusData = data.getBonusData();
      this.selectedBonusDocumentId = data.selectedBonusDocumentId;
    } else if (data) {
      this.bonusConfig = data.bonusConfig ? data.bonusConfig.filter((c) => c.configuration.isEnabled).map(config => new BonusDocumentConfig(config)) : [];
      this.isConfigFetched = data.isConfigFetched ?? false;
      this.bonusData = data.bonusData ? data.bonusData.map(d => new BonusData(d)) : [];
      this.selectedBonusDocumentId = data.selectedBonusDocumentId;
    }
  }

  getBonusConfig() {
    return this.bonusConfig;
  }

  getBonusData() {
    return this.bonusData;
  }

  getIsConfigFetched() {
    return this.isConfigFetched;
  }

  getSelectedBonusDocumentId() {
    return this.selectedBonusDocumentId;
  }

  toBonusInterface(): IBonusState {
    return {
      bonusConfig: this.bonusConfig.map(config => config.toBonusConfigType()),
      bonusData: this.bonusData.map(data => data.toBonusDataType()),
      isConfigFetched: this.isConfigFetched,
      selectedBonusDocumentId: this.selectedBonusDocumentId
    };
  }

  addBonusData(payload: { bonusData: TBonusData }) {
    let bonusDataCopy = this.bonusData.map(data => data.toBonusDataType());
    if (this.bonusData.findIndex(data => data.id === payload.bonusData.id) < 0) {
      bonusDataCopy.push(payload.bonusData);
    } else {
      bonusDataCopy = bonusDataCopy.filter(b => b.id !== payload.bonusData.id);
      bonusDataCopy.push(payload.bonusData);
    }
    return new BonusState({ ...this.toBonusInterface(), bonusData: bonusDataCopy });
  }

  setSelectedBonus(id?: string) {
    if (!id && this.isConfigFetched) {
      return new BonusState({ ...this.toBonusInterface(), selectedBonusDocumentId: this.bonusConfig[0].id });
    }
    if (this.bonusConfig.find(config => id === config.id)) {
      return new BonusState({ ...this.toBonusInterface(), selectedBonusDocumentId: id });
    }
    return this;
  }

  clearSelectedBonus() {
    return new BonusState({ ...this.toBonusInterface(), selectedBonusDocumentId: null });
  }

  getActiveBonusDetails() {
    if (this.getIsConfigFetched() && this.getSelectedBonusDocumentId()) {
      const activeDocId = this.getSelectedBonusDocumentId();
      const bonusConfig = this.getBonusConfig().find(config => config.id === activeDocId);
      const bonusData = this.getBonusData().find(data => data.id === activeDocId);
      if (!bonusConfig || !bonusData) {
        return;
      }
      return { bonusData, bonusConfig };
    }
  }

  getSummaryDetails(): TBonusSummary | undefined {
    const activeBonusDetails = this.getActiveBonusDetails();
    if (!activeBonusDetails) return;

    const { bonusData, bonusConfig } = activeBonusDetails;
    if (bonusData.bonusAttributes.length <= 0) return;

    // Use the first item as the summary attribute if it exists
    const summaryAttribute = bonusData.bonusAttributes[0];
    if (summaryAttribute) {
      const bonusItems = bonusData.bonusAttributes
        .map(attr => ({
          percentValue: attr.CalculationType === 'GrainItem' ? attr.PercentageValue : attr.BingoBonusPercentage,
          countValue: attr.CalculationType === 'GrainItem' ? attr.GrainBonusAmount : attr.BingoBonusAmount,
          lockStatus: attr.LockStatus
        }))
        .sort((a, b) => (a.lockStatus !== b.lockStatus ? (a.lockStatus ? 1 : -1) : 0));
      const totalUnlockedItems = {
        percentValue: bonusItems.filter(item => !item.lockStatus).reduce((a, b) => a + b.percentValue, 0.0),
        countValue: bonusItems.filter(item => !item.lockStatus).reduce((a, b) => a + b.countValue, 0.0)
      };
      const unlockedProductsCount = bonusData.bonusAttributes.filter(attr => !attr.LockStatus && attr.CalculationType === 'GrainItem').length;
      const totalProductsCount = bonusData.bonusAttributes.filter(attr => attr.CalculationType === 'GrainItem').length;
      const unlockedProductsPercent = totalProductsCount <= 0 ? 0 : (unlockedProductsCount / totalProductsCount) * 100;
      const ita = summaryAttribute.DerivedValue;
      const potentialAwardTotal = bonusItems.length > 0 ? (bonusItems.reduce((prev, curr) => prev + curr.percentValue, 0.0) * ita) / 100.0 : 0.0;
      const percentagePotentialUnlocked = (totalUnlockedItems.countValue * 100.0) / potentialAwardTotal;

      return {
        attribute: {
          bonusItems,
          totalUnlockedItems,
          productsSummary: {
            unlockedProductsCount,
            totalProductsCount,
            unlockedProductsPercent,
          },
          potentialAwardTotal,
          percentagePotentialUnlocked,
          currencyCode: summaryAttribute.CurrencyCode,
          derivedKey: summaryAttribute.DerivedKey,
          derivedValue: ita
        },
        configuration: {
          awardsSummary: bonusConfig.configuration.awardsSummaryHeaderConfig,
          bucketsSummary: bonusConfig.configuration.bucketAwardsHeaderConfig
        }
      };
    }
  }

  public static isBingoBonus(attribute: TBonusAttribute) {
    return attribute.CalculationType === 'All' && attribute.BingoBonusPercentage > 0;
  }

  matchAttributeToConfig(sectionConfig: TGrainItemSection, attributes: TBonusAttribute[]): TBonusAttributeDetailSection {
    if (!sectionConfig) return null;
    const defaultWeightedSection = sectionConfig.sectionItems.find(item => item.planComponentName === DEFAULT_PLAN_COMPONENT_NAME);
    const matchedSection: TBonusAttributeDetailItem[] = attributes.map(attribute => {
      let configuration = defaultWeightedSection;
      if (attribute.PlanComponentName) {
        // If we have a component name try to find it
        configuration = sectionConfig.sectionItems.find(item => item.planComponentName === attribute.PlanComponentName);
      }
      if (!configuration) {
        configuration = defaultWeightedSection;
      }
      const isBingoBonus = BonusState.isBingoBonus(attribute);
      if (isBingoBonus) {
        return { attribute, configuration, isBingoBonus, summary: this.getSummaryDetails() };
      }
      return { attribute, configuration, isBingoBonus };
    });
    const sectionItems = matchedSection.sort((a, b) => a.configuration.priority - b.configuration.priority);
    return { header: sectionConfig.header, sectionItems };
  }

  getBonusAttributeDetails(): TBonusDetailView {
    const activeBonusDetails = this.getActiveBonusDetails();
    if (!activeBonusDetails) return emptyDetailView;

    const { bonusData, bonusConfig } = activeBonusDetails;
    if (bonusData.bonusAttributes.length <= 0) return emptyDetailView;

    const attributes = bonusData.bonusAttributes;
    const weightedSection = this.matchAttributeToConfig(
      bonusConfig.configuration.grainItemSectionsConfig.weightedSection,
      attributes.filter(attribute => attribute.CalculationType === 'All' && !BonusState.isBingoBonus(attribute))
    );
    const bonusSection = this.matchAttributeToConfig(
      bonusConfig.configuration.grainItemSectionsConfig.bonusSection,
      attributes.filter(attribute => attribute.CalculationType === 'GrainItem')
    );
    const excellenceSection = this.matchAttributeToConfig(
      bonusConfig.configuration.grainItemSectionsConfig.excellenceBonusSection,
      attributes.filter(attribute => attribute.CalculationType === 'All' && BonusState.isBingoBonus(attribute))
    );

    return { weightedSection, bonusSection, excellenceSection };
  }
}
