import { Injectable } from '@angular/core';
import { TooltipTextPayoutSummary } from './tooltip-text-payout-summary';
import { UserProfileService } from 'src/app/core/context/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class PayoutSummaryHelperService {
  columnDefs: any;
  constructor(private tooltipTextPayoutSummary: TooltipTextPayoutSummary, public userProfileService: UserProfileService) {
  }

  getColumnDefs() {
    
    this.columnDefs = [
      {
        field: 'codePeriod',
        lockPosition: true,
        suppressNavigable: true,
        headerName: 'Time Frame',
        cellRenderer: 'payoutReportLinkRenderer',

        cellClassRules: {
          'payout-not-done': '!data.payoutDone',
          'payout-done': 'data.payoutDone'
        },
        minWidth: 110,
        tooltipField: this.userProfileService.isUBIPersona()
          ? this.tooltipTextPayoutSummary.UBICodePeriod
          : this.userProfileService.isPPIPersona()
          ? this.tooltipTextPayoutSummary.PPICodePeriod
          : this.tooltipTextPayoutSummary.CodePeriod
      }
    ];
   
    this.columnDefs.push(
      // comment after ag-grid enterprise is setup
      {
        field: 'yTDEarnings',
        lockPosition: true,
        suppressNavigable: true,
        headerName: 'YTD Earnings (A)',
        minWidth: 135,
        tooltipField: this.tooltipTextPayoutSummary.YTDEarnings
      },
      {
        field: 'prevQuarterPayout',
        lockPosition: true,
        suppressNavigable: true,
        headerName: this.userProfileService.isRBIPersona() ? 'Paid in Previous Quarter (B)' : 'Paid Previously',
        minWidth: 190,
        tooltipField: this.userProfileService.isUBIPersona()
          ? this.tooltipTextPayoutSummary.UBIPrevPayout
          :this.userProfileService.isPPIPersona()
          ? this.tooltipTextPayoutSummary.PPIPrevPayout
          : this.tooltipTextPayoutSummary.PrevQuarterPayout
      },
      {
        field: 'ytdPaymentAdjustmentAndRecoveries',
        lockPosition: true,
        suppressNavigable: true,
        headerName: 'YTD Payment Adjustments and Recoveries (C)',
        minWidth: 290,
        tooltipField: this.userProfileService.isUBIPersona()
          ? this.tooltipTextPayoutSummary.UBIYtdAdj
          :this.userProfileService.isPPIPersona()
          ? this.tooltipTextPayoutSummary.PPIYtdAdj
          : this.tooltipTextPayoutSummary.YtdPaymentAdjustmentAndRecoveries
      },
      {
        field: 'netPaymentAmount',
        lockPosition: true,
        suppressNavigable: true,
        headerName: 'Net Payment (A - B + C)',
        minWidth: 180,
        tooltipField: this.userProfileService.isRBIPersona() ? this.tooltipTextPayoutSummary.NetPaymentAmount : this.tooltipTextPayoutSummary.UBIPPINetPaymentAmount
      },
      {
        field: 'calcDate',
        lockPosition: true,
        suppressNavigable: true,
        headerName: 'Calculation Date',
        minWidth: 145,
        tooltipField: this.userProfileService.isUBIPersona()
          ? this.tooltipTextPayoutSummary.UBICalcDate
          :this.userProfileService.isPPIPersona()
          ? this.tooltipTextPayoutSummary.PPICalcDate
          : this.tooltipTextPayoutSummary.CalcDate
      }
    );

    return this.columnDefs;
  }
}
