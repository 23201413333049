<ul>
  <li tabindex="0" (keyup.enter)="toggleProductHighlight(item)" (click)="toggleProductHighlight(item)" title="{{ item }}" class="common common-yes" *ngFor="let item of commonProducts; let i = index"
    [class.active]="highlightedProductName === item">
    <div class="sr-only">{{pcgName}} - {{ item }}</div>
    <div class="icon">
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.58927 2.39795C5.2826 1.30462 4.27727 0.501953 3.08793 0.501953C1.65593 0.501953 0.490601 1.66729 0.490601 3.09929C0.490601 4.28862 1.29327 5.29395 2.3866 5.60062C2.69327 6.69395 3.6986 7.49662 4.88793 7.49662C6.31993 7.49662 7.48527 6.33129 7.48527 4.89929C7.4826 3.70995 6.67993 2.70729 5.58927 2.39795ZM1.10393 3.10195C1.10393 2.00862 1.9946 1.11795 3.08793 1.11795C3.8986 1.11795 4.59727 1.60595 4.90393 2.30462C4.8986 2.30462 4.89327 2.30462 4.88793 2.30462C3.45593 2.30462 2.2906 3.46995 2.2906 4.90195C2.2906 4.90729 2.2906 4.91262 2.2906 4.91795C1.59193 4.60862 1.10393 3.90995 1.10393 3.10195ZM4.88527 6.88329C4.0746 6.88329 3.37593 6.39529 3.06927 5.69662C3.0746 5.69662 3.07993 5.69662 3.08527 5.69662C4.51727 5.69662 5.6826 4.53129 5.6826 3.09929C5.6826 3.09395 5.6826 3.08862 5.6826 3.08329C6.38127 3.38995 6.86927 4.08862 6.86927 4.89929C6.86927 5.99529 5.98127 6.88329 4.88527 6.88329Z"
          fill="#C4C4C4" />
      </svg>
    </div>
    <div class="text">{{ item }}</div>
    <div *ngIf="highlightedProductName !== item; then thenBlock; else elseBlock"></div>
    <ng-template #thenBlock>
      <div class="link">Show common</div>
    </ng-template>
    <ng-template #elseBlock>
      <div class="link">Hide common</div>
    </ng-template>
  </li>

  <li title="{{ item }}" class="common common-no" *ngFor="let item of otherProducts; let i = index">
    <div class="icon icon__circle"></div>
    <div class="text">{{ item }}</div>
  </li>
</ul>