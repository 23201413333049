import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rowShortner' })
export class RowShortner implements PipeTransform {
  transform(value: string, limit?: number): string {
    if (!value) {
      return '';
    }
    limit = isNaN(limit) ? 10 : limit;

    if (value.indexOf(';') > -1) {
      value = value.split(';').join(',');
    }
    if (value.indexOf(',') > -1) {
      value = value.indexOf(',') === 0 ? value.substring(1, value.length) : value;
      value = value.lastIndexOf(',') === value.length - 1 ? value.substring(0, value.length - 1) : value;
    }
    return value.length <= limit ? value : value.substring(0, limit - 3) + '...';
  }
}
