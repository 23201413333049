import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-target-renderer',
  styles: [
    `
      .pg-attn {
        line-height: 16px;
      }
      .att-val {
        font-size: 11px;
        color: #707070;
      }
    `
  ],
  template: `
    <span>
      <span style="font-size: 14px; text-align: right; color: #4F4F4F;" >
        <b>{{ params.data.actualsStrCurrency() }} </b>
      </span>
      <br />     
    </span>
  `
})
export class BonusActualRendererComponent implements ICellRendererAngularComp {
  params: any;
  thresholdInfo: any;
  constructor() {}
  agInit(params): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
