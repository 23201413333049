import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../core/network/data.service';
import { Observable, of } from 'rxjs';
import { EmployeeInfo } from '../shared/employee-info/employee-info.model';
import { catchError, map } from 'rxjs/operators';
import { PayIncentiveMix } from './pay-incentive-mix/models/pay-incentive-mix.model';
import { PayComponent } from './pay-incentive-mix/models/pay-component.model';
import { PayComponentType } from './pay-incentive-mix/models/pay-component-type.enum';
import { Payline } from '../shared/models/payline.model';
import { CustomHttpParams } from '../core/network/custom-http-params';

@Injectable()
export class CompensationPlanService {
  constructor(private endpoint: EndpointService, private dataService: DataService) {}

  getEmployeeInformation(fromPayout: boolean = false): Observable<EmployeeInfo> {
    let params = CustomHttpParams.getInstance();
    if (fromPayout) {
      params = params.append('FromVersionSnapShot', String(fromPayout));
    }
    return this.dataService.getWithGlobalFilters<any>(this.endpoint.GetRoleInformation.uri, params).pipe(
      map(result => {
        return new EmployeeInfo().deserialize(result);
      })
    );
  }

  getPayIncentiveMixDetails(): Observable<PayIncentiveMix> {
    return this.dataService.getWithGlobalFilters<any>(this.endpoint.GetPayIncentiveMixDetails.uri, this.endpoint.GetRoleInformation.params).pipe(
      map(result => {
        return new PayIncentiveMix().deserialize(result);
      })
    );
  }

  getPayComponent(components: PayComponent[], payComponentType: PayComponentType): PayComponent {
    const payComponent = components.find(component => component.name === payComponentType);
    return payComponent;
  }

  getPayIncentiveComponent(components: PayComponent[]) {
    let payComponent;
    // Component other than CBI will be RBI/UBI/PPI component
    payComponent = components.find(component => component.name !== PayComponentType.CBI);
    return payComponent;
  }

  getAllPaylines(): Observable<Payline[]> {
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetAllPaylines.uri, this.endpoint.GetAllPaylines.params).pipe(
      map(result => {
        return new Payline().deserialize(result.Paylines);
      })
    );
  }

  getNonRevenueDataSourceRefreshTimes(sourceName, fromPayout: boolean = false) {
    let pccParams = CustomHttpParams.getInstance();
    if (fromPayout) {
      pccParams = pccParams.append('FromVersionSnapShot', String(fromPayout));
    }
    // Todo: Create model class for the response and return array of the same.
    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetRefreshTimesOfNonRevenueDataSources.uri, pccParams).pipe(
      map(result => {
        let refreshDate;
        const refreshDateItem = result.find(item => item.SourceName === sourceName);
        if (refreshDateItem) {
          refreshDate = refreshDateItem.SourceLastRefreshTime;
        }
        return refreshDate;
      }),
      catchError(err => of([]))
    );
  }

  getPlanSummaryReport() {
    const jsonParam = { ExcludeTerritory: true };
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.GetPlanSummaryReport.uri, jsonParam, null, 'arraybuffer');
  }

  getPayMixReport() {
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.GetPayIncentiveMixDetailsReport.uri, null, null, 'arraybuffer');
  }
}
