import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DisclaimersService } from './disclaimers.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserProfileService } from '../core/context/user-profile.service';
import { NavigationService } from '../core/navigation/navigation.service';
import { ImpersonationService } from '../core/impersonation/impersonation.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mint-disclaimers',
  templateUrl: './disclaimers.component.html',
  styleUrls: ['./disclaimers.component.scss'],
  providers: [DisclaimersService]
})
export class DisclaimersComponent implements OnInit {
  disclaimers: any[];
  showDisclaimer: boolean;
  disclaimer: any;
  disclaimerText: SafeHtml;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private disclaimersService: DisclaimersService,
    private router: Router,
    private sanitizer: DomSanitizer,
    public sharedContextState: Store<SharedContext>,
    private navigationService: NavigationService,
    public userProfileService: UserProfileService,
    public impersonationService: ImpersonationService
  ) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      this.getDisclaimers();
    });
  }

  ngOnInit() {}

  getDisclaimers() {
    this.showDisclaimer = true;
    this.disclaimer = this.sharedContext.Settings.Disclaimer;
    this.disclaimerText = this.sanitizer.bypassSecurityTrustHtml(this.disclaimer.Text);
  }

  onAcceptance(disclaimer) {
    disclaimer = { ...disclaimer, IsViewAs: this.sharedContext.isViewAs };
    if (!this.sharedContext.isViewAs) {
      this.disclaimersService.SetDisclaimerAcceptance(disclaimer).subscribe(response => {
        this.navigateTo();
      });
    } else {
      this.navigationService.navigateTo('complianceDashboard');
    }
  }

  navigateTo() {
    this.navigationService.clear();
    this.navigationService.navigateTo('', null, true);
  }
}
