import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'customNumber' })
export class CustomNumberPipe extends DecimalPipe implements PipeTransform {
  transform(value: any, format = '1.2-2'): any {
    if (!value && value !== 0) {
      return '';
    }
    const formatted = super.transform(value, format); // Call base pipe to format the value with 2 decimal places
    return formatted;
  }
}
