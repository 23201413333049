import { Direct } from '../models/direct';
import { Component, OnInit, Input, EventEmitter, Output, Inject, AfterViewChecked, ElementRef } from '@angular/core';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { FiltersService } from '../../../filters/filters.service';
import { ImpersonationService } from 'src/app/core/impersonation/impersonation.service';
import { GraphService } from '@mint-libs/context';
import { Store } from '@ngrx/store';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';

@Component({
  selector: 'mint-direct-detail',
  templateUrl: './direct-detail.component.html',
  styleUrls: ['./direct-detail.component.scss']
})
export class DirectDetailComponent implements OnInit, AfterViewChecked {
  @Input() direct: Direct;
  @Input() isShowDirects = true;
  @Input() showImage = true;

  @Output() toggleViewDirects = new EventEmitter();

  thumbnailPhoto: any;
  viewReporteesFlag = 'View';
  showComplianceInfo = true;
  anyQaAvailable = false;

  // Flags
  isPPADetailsPopUpOpen = false;
  isQADetailsPopUpOpen = false;

  sharedContext = new SharedContext();

  constructor(
    private graphService: GraphService,
    public impersonationService: ImpersonationService,
    private sharedContextState: Store<SharedContext>,
    private router: Router,
    private filterService: FiltersService,
    private element: ElementRef
  ) { }
  ngAfterViewChecked(): void {
    this.sharedContextState.select(selectSharedContextState).subscribe((sharedContext) => this.sharedContext = sharedContext);
    // adding alt text to ngx-avatar
    const avtarImg = this.element.nativeElement.querySelector('ngx-avatar img');
    if (avtarImg) {
      avtarImg.setAttribute('alt', this.direct.name);
    }
  }

  ngOnInit() {
    if (this.direct.planId === -1 || !this.direct.isPlanOnboarded) {
      this.showComplianceInfo = false;
    }
    this.anyQaAvailable = this.direct.qaComplianceDates && this.direct.qaComplianceDates.length > 0;
    if (this.isShowDirects === true) {
      this.isShowDirects = this.direct.isFCAHasAccess && this.direct.numberOfReportees > 0;
    }

    if(this.showImage){
    const graph = from(this.graphService.getThumbnailPhoto(this.direct.alias));
    graph.subscribe(thumbnailResult => {
      if (thumbnailResult) {
        this.thumbnailPhoto = `data:image/jpeg;base64,${thumbnailResult}`;
      }
    });
  }
  }

  viewDirects() {
    if (this.direct.numberOfReportees > 0) {
      this.toggleViewDirects.emit();
    }
  }

  impersonateDirect() {
    from(this.impersonationService.startImpersonation(this.direct.alias, this.direct.name, false, this.direct.currentParticipation, this.direct.personnelNumber, this.filterService.selectedManagerViewFiscalYear)).subscribe(result => {
      this.router.navigateByUrl(this.router.url + '?force-refresh=true', { skipLocationChange: true })
        .then(x => {
          this.router.navigate([this.router.url]);
        });
    });
  }
}
