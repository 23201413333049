import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FiltersService } from '../../filters/filters.service';

@Injectable()
export class FY19RouteGuard implements CanActivate {
  constructor(private filterService: FiltersService, private _router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Donot allow for fiscal year < 2019
    if (this.filterService.selectedFiscalYear >= 2019) {
      return true;
    }

    return false;
  }
}
