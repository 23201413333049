<div class="direct__utilization__period__status">
  <div >
    {{ ubiMetricAttainmentTitle }}
  </div>
  <!-- <mint-tooltip *ngIf="!isPeriodStatusActive" [tipText]="ubiMetricAttainmentToolTipText" [id]="ubiMetricTooltipId"> </mint-tooltip> -->
</div>
<div class="direct__utilization__quota" *ngIf="isPeriodStatusActive || isPeriodStatusTA">
  <div class="direct__utilization__quota">
    <div class="metric__type">
      <div class="metric__type__desc" >Utilization</div>
      <div class="metric__utilization">
        <div class="metric__type__note" >Weight: {{ ubiAttainmentMetric.UtilizationPCGWeight | percentage }}</div>
        <div class="metric__weight" tabindex="1">{{ ubiAttainmentMetric.AttainmentPercentage | percentage }}</div>
        <div class="metric__progress">
          <div class="metric__progress__placeholder">
            <div [ngClass]="['metric__progress__actual', ubiAttainmentMetric.attainmentClass]" [style.width]="ubiAttainmentMetric.attainmentWidth"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="metric__ubi__info">
      <div class="metric__ubi__container">
        <div class="metric__ubi__info__item">
          <div class="metric__ubi__desc" >YTD Target</div>
          <div class="metric__ubi__val" >{{ ubiAttainmentMetric.TargetHours | number: '1.0-0' }} Hrs</div>
        </div>
        <div class="metric__ubi__info__item">
          <div class="metric__ubi__desc" >YTD Actuals</div>
          <div class="metric__ubi__val" >{{ ubiAttainmentMetric.ActualHours | number: '1.0-0' }} Hrs</div>
        </div>
        <div class="ubi__info__item__detailed">
          <div class="metric__ubi__info__item">
            <div class="metric__ubi__desc" >Billable</div>
            <div class="metric__ubi__val" >{{ ubiAttainmentMetric.BillableHours | number: '1.0-0' }} Hrs</div>
          </div>
          <div class="metric__ubi__info__item">
            <div class="metric__ubi__desc" >Presales</div>
            <div class="metric__ubi__val" >{{ ubiAttainmentMetric.PreSalesHours | number: '1.0-0' }} Hrs</div>
          </div>
          <div class="metric__ubi__info__item">
            <div class="metric__ubi__desc" >NBUE w/o Presales</div>
            <div class="metric__ubi__val" >{{ ubiAttainmentMetric.NBUEPreSalesHours | number: '1.0-0' }} Hrs</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="reverse__row__flex" *ngIf="displayConsumptionMetric">
    <div class="metric__type">
      <div class="metric__type__descpdbtm" >Consumption Metric Attainment</div>
      <div class="metric__utilization">
        <div class="metric__type__note" >Weight: {{ ubiAttainmentMetric.ConsumptionMetricPCGWeight | percentage }}</div>
        <div class="metric__weight" tabindex="1">{{ ubiAttainmentMetric.ConsumptionMetricAttainment | percentage }}</div>
        <div class="metric__progress">
          <div class="metric__progress__placeholder">
            <div [ngClass]="['metric__progress__actual', ubiAttainmentMetric.consumptionMetricAttainmentClass]" [style.width]="ubiAttainmentMetric.consumptionMetricAttainmentWidth"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
