import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { CustomHttpParams } from '../core/network/custom-http-params';
import { DataService } from '../core/network/data.service';

@Injectable()
export class DisclaimersService {
  constructor(private endpoint: EndpointService, private dataService: DataService) { }

  private isShownForDemoMode: boolean;

  SetDisclaimerAcceptance(disclaimer: any) {
    return this.dataService.postWithGlobalFilters<any[]>(this.endpoint.SetDisclaimerAcceptance.uri, disclaimer);
  }

  setDemoModeFlag(val: boolean) {
    this.isShownForDemoMode = val;
  }

  getDemoModeFlag() {
    return this.isShownForDemoMode;
  }
}
