import { Injectable } from '@angular/core';
import { NetworkService, EndpointService } from '@mint-libs/network';
import { ConfigurationState } from './store/configuration.reducer';
import { LoadConfigurations } from './store/configuration.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
//import { CustomQueryEncoderHelper } from '../../../network/src/lib/encode';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(private networkService: NetworkService, private endpoints: EndpointService, private configurationState: Store<{ count: number }>) {}

  init() {
    const action = new LoadConfigurations();
    this.configurationState.dispatch(action);
  }

  private errorMessage = { message: 'Failed to retrieve required configuration. Please try again.' };
  private errorMessages = {
    '500': {
      AuthenticationFailed: 'You have to be a Seller or Seller Manager to use this app'
    }
  };

  private environment = '';
  private enableAppCaching = false;
  private NotificationConfig = {};
  private supportEmailId = '';
  private MaximumNotificationsCount = '';
  private BannerNotificationsLimit = '';
  private appDownloadLink = '';
  private AuthConfigurations: any[] = [];
  private AppAuthConfig = {};
  private Resiliency = {};
  private AppInsightsConfig = {};
  private UserVoiceConfig = {};
  private BotConfig = {};
  private IsMaintainanceMode = false;
  private PollOfflineFrequency = 99999;
  private DetailWidgetTimeoutInterval = 99999;
  private DetailWidgetPollStartInterval = 99999;
  private DetailWidgetPollEndInterval = 99999;
  private featureIntroductionState = false;
  private featureIntroductionPageHeader = '';
  private featureIntroductionLastEditDate = '';
  private loaded = false;
  private PageTourConfig: any;
  private MaintainanceMode = {
    message: '',
    title: '',
    setMessage: function(startTime, endTime, message) {
      if (startTime != '' && endTime != '') {
        this.message = message
          .replace('{0}', startTime)
          .split('{1}')
          .join(endTime);
      }
    },
    setTitle: function(title) {
      this.title = title;
    },
    On: function(startTime, endTime, message, title) {
      this.IsMaintainanceMode = true;
      this.setMessage(startTime, endTime, message);
      this.setTitle(title);
    },
    Off: function() {
      this.IsMaintainanceMode = false;
    }
  };
  private BusinessSwitchEnabledFeaturesConfig = '';
  private attainmentThresholdValue = 99999;
  private isHalfYearlyQuotaSupported = false;
  private isPayoutUnavailable = false;
  private isHRFreeze = false;
  private HRFreezeErrorMessage = '';

  get() {
    this.networkService.get(this.endpoints.GetConfiguration.uri, null).subscribe(result => {
      const v = result;
    });
  }

  getVersionConfiguration(fiscalYear): Observable<any> {
    let params = new HttpParams();

    //let params = this.endpoints.GetVersionConfig.params;
    params = params.append('fiscalYear', fiscalYear);

    return this.networkService.get(this.endpoints.GetVersionConfig.uri, params);
  }

  getConfiguration(baseUri: string, cache: boolean): Promise<any> {
    var params = this.endpoints.GetConfiguration.params;
    params.AppId = 1;
    params.GetFromCache = cache == undefined ? true : cache;
    params.CamelCase = false;

    let promise: Observable<any>;
    var url = baseUri + this.endpoints.GetConfiguration.uri;

    promise = this.networkService.get(url, params) as Observable<any>;

    return promise.toPromise().then(
      response => {
        var result;

        if (!response || !response.Result || !response.Result.Configs) {
          var error = 'Error in fetching configuration data.';
          var maintainanceMode = false;
          if (response.maintainanceMode == true) {
            maintainanceMode = true;
            error = 'Sorry we are currently down for maintainance';
          }
          return Promise.reject({ error: this.errorMessage, state: error, MaintainanceMode: maintainanceMode });
        }
        result = response.Result;
        var configs = result.Configs;
        this.setEnvironmentConfig(result);
        this.setAuthConfigs(configs);
        this.setOtherConfigs(configs);
        this.loaded = true;
        return Promise.resolve(result);
      },
      error => {
        if (error) {
          var message = this.getErrorMessages(error);
          return Promise.reject({ error: message, state: error });
        }
      }
    );
  }

  private getErrorMessages(error) {
    try {
      var errorCode = error.status;
      var errorReason = error.data.Reason.replace(/\s/g, '');
      var _errorMessage = this.errorMessages[errorCode][errorReason];
      return { message: _errorMessage == undefined ? this.errorMessage.message : _errorMessage };
    } catch (e) {
      return this.errorMessage;
    }
  }

  private setAuthConfigs(configs: any) {
    this.AuthConfigurations.push(this.parseJSON(configs.PowerBiAuthConfig));
    this.AuthConfigurations.push(this.parseJSON(configs.AppAuthorizationConfig));
  }

  private setEnvironmentConfig(configs) {
    this.environment = configs.Environment;
    this.enableAppCaching = configs.EnableAppCaching;
    this.supportEmailId = configs.SupportEmailId;
    this.MaximumNotificationsCount = configs.MaximumNotificationsCount;
    this.BannerNotificationsLimit = configs.BannerNotificationsLimit;
    this.appDownloadLink = configs && configs.Configs ? configs.Configs.appDownloadLink : 'softwarecenter:\\';
    this.isHalfYearlyQuotaSupported = configs.IsHalfYearlyQuotaSupported;
    this.isPayoutUnavailable = configs.IsPayoutUnavailable;
    this.isHRFreeze = configs.IsHRFreeze;
    this.HRFreezeErrorMessage = configs.HRFreezeErrorMessage;
  }

  private setOtherConfigs(configs) {
    this.Resiliency = this.parseJSON(configs.Resiliency);
    this.AppInsightsConfig = this.parseJSON(configs.AppInsightsConfig);
    this.UserVoiceConfig = this.parseJSON(configs.UserVoiceConfig);
    this.NotificationConfig = this.parseJSON(configs.NotificationConfig);
    this.BotConfig = configs.BotSource;
    this.featureIntroductionState = configs.FeatureIntroductionToggle == '1' ? true : false;
    this.featureIntroductionPageHeader = configs.FeatureIntroductionPageHeader;
    this.featureIntroductionLastEditDate = configs.FeatureIntroductionLastEdit;

    this.PollOfflineFrequency = parseInt(configs.PollOfflineFrequency);
    this.DetailWidgetTimeoutInterval = parseInt(configs.DetailWidgetTimeoutInterval);
    this.DetailWidgetPollStartInterval = parseInt(configs.DetailWidgetPollStartInterval);
    this.DetailWidgetPollEndInterval = parseInt(configs.DetailWidgetPollEndInterval);
    this.BusinessSwitchEnabledFeaturesConfig = configs.BusinessSwitchEnabledFeatures;
    this.attainmentThresholdValue = configs.AttainmentMessageThresholdValue;
    this.PageTourConfig = this.parseJSON(configs.PageTourConfig);
  }

  private parseJSON(value: any): any {
    if (value) {
      return JSON.parse(value);
    }
    return value;
  }
}
