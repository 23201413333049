<mint-payout-backup-payment-details [paymentDetails]="payoutInfo"></mint-payout-backup-payment-details>
<div id="payout-report-body">
  <div class="payout-report-blade1">
    <div class="payout-report-blade1-tile">
      <div class="payout-report-blade1-subsection">
        <div>Core Buckets YTD Award</div>
        <div>
          {{ payoutReportService.formatCurrency(payoutInfo.YTDEarnings) }} <span>{{ payoutInfo.CurrencyCode }}</span>
        </div>
      </div>
      <div>
        <mat-accordion #firstAccordion="matAccordion">
          <div *ngFor="let payoutEarningDetail of payoutInfo.EarningDetails; let j = index">
            <div class="role-summary-info">{{ payoutEarningDetail.RoleSummary }}</div>
            <mat-expansion-panel
              *ngFor="let pcg of payoutEarningDetail.PCGList; first as isFirst; let i = index"
              [expanded]="isFirst && j === 0"
              (opened)="closeAllOtherAccordians(secondAccordion, 'secondAccordion', pcg)"
              hideToggle
              (afterExpand)="openGroup(i)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title
                  ><span style="color: transparent; width: 0;">{{ pcg.PCGID }}</span
                  >{{ pcg.PCG }}</mat-panel-title
                >
                <mat-panel-description>
                  <div>
                    {{ payoutReportService.formatCurrency(pcg.IncentiveAward) }} <span class="payout-report-body-subtitles">{{ payoutInfo.CurrencyCode }}</span>
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div *ngFor="let pcc of pcg.PCCDetails; let i = index" class="payout-report-blade1-subsection-sublist metric-name-link" (click)="openModal(metricdetailstemplate, pcg, pcc.PCCID)">
                {{ pcc.PCCName }}<mat-divider *ngIf="i !== pcg.PCCDetails.length - 1"></mat-divider>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-accordion>
      </div>
    </div>
    <div *ngIf="payoutInfo.PayrollAdjustments !== null" class="payout-report-blade1-tile">
      <div class="payout-report-blade1-subsection">
        <div>Adjustments</div>
        <div>
          {{ payoutReportService.formatCurrency(payoutInfo.TotalPayrollAdjustments) }} <span>{{ payoutInfo.CurrencyCode }}</span>
        </div>
      </div>
      <div>
        <mat-accordion #secondAccordion="matAccordion">
          <mat-expansion-panel
            *ngFor="let adjustment of payoutInfo.GroupedAdjustments"
            hideToggle
            (afterExpand)="openGroup(i)"
            (opened)="closeAllOtherAccordians(firstAccordion, 'firstAccordion', adjustment)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>{{ adjustment.AdjustmentName }} </mat-panel-title>
              <mat-panel-description>
                <div>
                  {{ payoutReportService.formatCurrency(adjustment.AdjustmentAmount) }} <span class="payout-report-body-subtitles">{{ payoutInfo.CurrencyCode }}</span>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="payout-report-blade1-subsection-sublist">
              <div>Date: {{ adjustment.AdjustmentEffectiveDate | date: 'mediumDate' }}</div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div id="selection-pointer" class="payout-report-selection-pointer"></div>
  <div class="payout-report-blade2" id="payout-report-blade2">
    <mint-payout-core-bucket-details
      *ngIf="showCoreBucketDetails"
      [coreBucketInfo]="payoutInfo.EarningDetails"
      [currency]="payoutInfo.CurrencyCode"
      [selectedPCG]="payoutReportService.getSelectedCoreBucket()"
      [selectedFiscalYear]="payoutInfo.FiscalYearId"
    ></mint-payout-core-bucket-details>
    <mint-payout-report-adjustment-details *ngIf="showAdjustmentDetails" [adjustmentInfo]="payoutInfo.PayrollAdjustments"></mint-payout-report-adjustment-details>
  </div>
</div>

<ng-template #metricdetailstemplate let-modal>
  <div role="dialog" aria-label="Metric Details Dialog" tabindex="0"><mint-payout-report-metric-details [selectedMetric]="selectedMetric"></mint-payout-report-metric-details></div>
</ng-template>
