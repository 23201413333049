import { Injectable } from '@angular/core';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '../../core/network/data.service';
import { CustomHttpParams } from '../../core/network/custom-http-params';
import { environment } from '../../../environments/environment';
import { empty, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncentiveSummaryService {
  constructor(private endpoint: EndpointService, private dataService: DataService) {}

  
  // getting Payout Summary from New Payout Summary API
  getPayoutSummaryData(fromFreezeSnapshot: boolean = false, forSummaryView: boolean = true, isOptOut:boolean=false) {
    let params = CustomHttpParams.getInstance();
    params = params.append('FromFreezeSnapshot', String(fromFreezeSnapshot));
    params = params.append('ForSummaryView', String(forSummaryView));
    params = params.append('IsOptOut', String(isOptOut));
    params = params.append('IsHardTransferFeatureEnabled', String(environment.isHardTransferFeatureEnabled));

    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetPayoutSummaryInfo.uri, params, null, environment.payoutApiUrl);
  }


   // getting Core Payout Summary from old monolith api for PPI and UBI Users (i.e non RBI users)
  getPayoutSummaryDataFromParticipantAPI(isRBIUser:boolean=false, fromFreezeSnapshot: boolean = false, forSummaryView: boolean = true) {
    if(isRBIUser){
      return of(null);
    }
    let params = CustomHttpParams.getInstance();
    params = params.append('FromFreezeSnapshot', String(fromFreezeSnapshot));
    params = params.append('ForSummaryView', String(forSummaryView));

    return this.dataService.getWithGlobalFilters<any[]>(this.endpoint.GetPayoutSummary.uri, params);
  }
}
