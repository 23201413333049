<content-loader [height]="200" [width]="1000">
  <svg:rect x="10" y="10" rx="4" ry="4" width="180" height="30" />
  <svg:rect x="200" y="10" rx="3" ry="3" width="150" height="30" />
  <svg:rect x="360" y="10" rx="3" ry="3" width="210" height="30" />
  <svg:rect x="580" y="10" rx="3" ry="3" width="180" height="30" />
  <svg:rect x="770" y="10" rx="3" ry="3" width="120" height="30" />
  <svg:rect x="900" y="10" rx="3" ry="3" width="100" height="30" />
  <svg:rect x="10" y="50" rx="4" ry="4" width="180" height="30" />
  <svg:rect x="200" y="50" rx="3" ry="3" width="150" height="30" />
  <svg:rect x="360" y="50" rx="3" ry="3" width="210" height="30" />
  <svg:rect x="580" y="50" rx="3" ry="3" width="180" height="30" />
  <svg:rect x="770" y="50" rx="3" ry="3" width="120" height="30" />
  <svg:rect x="900" y="50" rx="3" ry="3" width="100" height="30" />
  <svg:rect x="10" y="90" rx="4" ry="4" width="180" height="30" />
  <svg:rect x="200" y="90" rx="3" ry="3" width="150" height="30" />
  <svg:rect x="360" y="90" rx="3" ry="3" width="210" height="30" />
  <svg:rect x="580" y="90" rx="3" ry="3" width="180" height="30" />
  <svg:rect x="770" y="90" rx="3" ry="3" width="120" height="30" />
  <svg:rect x="900" y="90" rx="3" ry="3" width="100" height="30" />
  <svg:rect x="10" y="130" rx="4" ry="4" width="180" height="30" />
  <svg:rect x="200" y="130" rx="3" ry="3" width="150" height="30" />
  <svg:rect x="360" y="130" rx="3" ry="3" width="210" height="30" />
  <svg:rect x="580" y="130" rx="3" ry="3" width="180" height="30" />
  <svg:rect x="770" y="130" rx="3" ry="3" width="120" height="30" />
  <svg:rect x="900" y="130" rx="3" ry="3" width="100" height="30" />
  <svg:rect x="10" y="170" rx="4" ry="4" width="180" height="30" />
  <svg:rect x="200" y="170" rx="3" ry="3" width="150" height="30" />
  <svg:rect x="360" y="170" rx="3" ry="3" width="210" height="30" />
  <svg:rect x="580" y="170" rx="3" ry="3" width="180" height="30" />
  <svg:rect x="770" y="170" rx="3" ry="3" width="120" height="30" />
  <svg:rect x="900" y="170" rx="3" ry="3" width="100" height="30" />
</content-loader>
