import { OptoutNotificationComponent } from './../notifications/optout-notification.component';
import { ContextualVideoComponent } from './../feedback/contextual-video/contextual-video.component';
import { MetricChartComponent } from './metric-chart/metric-chart.component';
import { MetricGridComponent } from './metric-grid/metric-grid.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomHeaderComponent } from './grid-components/custom-header/custom-header.component';
import { AgGridModule } from 'ag-grid-angular';
import { LegendsComponent } from './legends/legends.component';
import { AttainmentWeightComponent } from './attainment-weight/attainment-weight.component';
import { AnalyticsTrackerDirective } from './directives/analytics-tracker.directive';
import { TourComponent } from './training/tour.component';
import { TourFeedbackDirective } from '../core/hybrid/tourFeedback.directive';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressBarComponent } from './progress/progress-bar/progress-bar.component';
import { MetricTileComponent } from './metric-tile/metric-tile.component';
import { MetricCardComponent } from './metric-card/metric-card.component';
import { MonthViewComponent } from './month-view/month-view.component';
import { MetricPcgBlockComponent } from './metric-pcg-block/metric-pcg-block.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { ProductListComponent } from './metric-card/product-list/product-list.component';
import { DetailDropdownComponent } from './detail-dropdown/detail-dropdown.component';
import { ExcelExportComponent } from './excel-export/excel-export.component';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { ContentLoaderLargeComponent } from './progress/content-loader/content-loader-large.component';
import { ContentLoaderMediumComponent } from './progress/content-loader/content-loader-medium.component';
import { ContentLoaderSmallComponent } from './progress/content-loader/content-loader-small.component';
import { SharedService } from './shared.service';
// import { GlobalFilterDirective } from './hybrid/global-filter.directive';
// import { PayoutDetailsDirective } from './hybrid/payoutDetails.directive';
import { ContentLoaderCardChartComponent } from './progress/content-loader/content-loader-card-chart.component';
import { ContentLoaderGridComponent } from './progress/content-loader/content-loader-grid.component';
import { ContentLoaderIconTextComponent } from './progress/content-loader/content-loader-icon-text.component';
import { ContentLoaderBarChartComponent } from './progress/content-loader/content-loader-bar-chart.component';
import { ContentLoaderBarChartSmallComponent } from './progress/content-loader/content-loader-bar-chart-small.component';
import { EmployeeInformationComponent } from './employee-info/employee-info.component';
import { AvatarModule } from 'ngx-avatar';
import { ContentLoaderSingleComponent } from './progress/content-loader/content-loader-single.component';
import { ApplicableForRBIDirective } from './directives/applicable-for-rbi.directive';
import { EarningPotentialComponent } from './earning-potential/earning-potential.component';
import { EarningPotentialGraphComponent } from './earning-potential/earning-potential-graph/earning-potential-graph.component';
import { EarningPotentialGridComponent } from './earning-potential/earning-potential-grid/earning-potential-grid.component';
import { PaylineAttributesComponent } from './earning-potential/earning-potential-graph/payline-attributes/payline-attributes.component';
import { EarningPotentialChartComponent } from './earning-potential/earning-potential-graph/earning-potential-chart/earning-potential-chart.component';
import { DeterminerDetailComponent } from './earning-potential/earning-potential-graph/payline-attributes/determiner-detail/determiner-detail.component';
import { PaylineSelectorComponent } from './earning-potential/earning-potential-graph/payline-selector/payline-selector.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { DeterminerDetailHelper } from './earning-potential/earning-potential-graph/payline-attributes/determiner-detail/determiner-detail.helper';
import { IncentiveSummaryTilesComponent } from '../dashboard/incentive-summary-tiles/incentive-summary-tiles.component';
import { IncentiveSummaryTilesComponent_Old } from '../dashboard/incentive-summary-tiles-old/incentive-summary-tiles.component'; // Should remove
import { PayoutSummaryComponent_Old } from '../dashboard/incentive-summary-tiles-old/payout-summary/payout-summary.component';
import { PayoutSummaryComponent } from '../dashboard/incentive-summary-tiles/payout-summary/payout-summary.component';
import { BonusPayoutSummaryComponent } from '../dashboard/incentive-summary-tiles/bonus-payout-summary/bonus-payout-summary.component';
import { DetailSummaryTilesComponent } from '../dashboard/detail-summary-tiles/detail-summary-tiles.component';
import { ProductsSummaryComponent } from '../dashboard/detail-summary-tiles/products-summary/products-summary.component';
import { TerritorySummaryComponent } from '../dashboard/detail-summary-tiles/territory-summary/territory-summary.component';
import { AccountSummaryComponent } from '../dashboard/detail-summary-tiles/account-summary/account-summary.component';
import { MetricGridDataHelperService } from './metric-grid/metric-grid-data-helper.service';
import { DashboardGridAndChartViewComponent } from './tabs/dashboard-grid-and-chart-view-tabs/dashboard-grid-and-chart-view-tabs.component';
import { PlanTerritoryDetailsComponent } from './plan-territory-details/plan-territory-details.component';
import { PlanTerritoryDetailsService } from './plan-territory-details/plan-territory-details.service';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ContentLoaderBarChartHorizontalComponent } from './progress/content-loader/content-loader-bar-chart-horizontal.component';
import { ExportIconComponent } from './excel-export/export-icon/export-icon.component';
import { SplashComponent } from '../splash/splash.component';
import { SplashService } from '../splash/splash.service';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { TerritoryDetailsComponent } from './plan-territory-details/territory-details/territory-details/territory-details.component';
import { PayoutUnavailableDirective } from './directives/payout-unavailable.directive';
import { PpiTargetDetailComponent } from './compliance/ppi-target-detail/ppi-target-detail.component';
import { HalfYearlyQuotaSectionComponent } from './halfyearly-quota-section/halfyearly-quota-section.component';
import { AccordionComponent } from './accordion/accordion.component';
import { RbiQuotaDetailsComponent } from './compliance/rbi-quota-details/rbi-quota-details.component';
import { MintCommonModule } from '@mint-libs/common';
import { ContentLoaderBarChartVerticalComponent } from './progress/content-loader/content-loader-bar-chart-vertical.component';
import { MatIconModule } from '@angular/material/icon';
import { MetricMultibarChartComponent } from '../shared/metric-multibar-chart/metric-multibar-chart.component';
import { MetricStackChartComponent } from '../shared/metric-stack-chart/metric-stack-chart.component';
import { MetricMultilineGraphComponent } from './metric-multiline-graph/metric-multiline-graph.component';
import { MintCheckBoxComponent } from './mint-check-box/mint-check-box.component';
import { MintMultiSelectCheckBoxComponent } from './mint-multi-select-check-box/mint-multi-select-check-box.component';
import { UbiTargetDetailsComponent } from './compliance/ubi-target-details/ubi-target-details.component';
import { MintTabsComponent } from './mint-tabs/mint-tabs.component';
import { ContentLoaderDoubleBarChartSingleComponent } from './progress/content-loader/content-loader-double-bar-chart-single.component';
import { ContentLoaderDoubleBarChartMultipleComponent } from './progress/content-loader/content-loader-double-bar-chart-multiple.component';
import { ExportToExcelService } from './excel-export/export-to-excel.service';
import { FiltersModule } from '../filters/filters.module';
import { CommonIconComponent } from './common-icon/common-icon.component';
import { CommonFactory } from '@mint-libs/common';
import { WidgetFactory } from './widgets/widget.factory';
// accessibility fix as default colors didn't had required contrast ratio
const avatarColors = ['#FF4D64', '#2C3E50', '#7A8F90', '#CE8209', '#17A689'];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AgGridModule.withComponents([]),
    NgProgressModule,
    NgProgressHttpModule,
    MatProgressBarModule,
    MatIconModule,
    ClickOutsideModule,
    ContentLoaderModule,
    AvatarModule.forRoot({
      colors: avatarColors
    }),
    NgbModule,
    MintCommonModule,
    FiltersModule
  ],
  declarations: [
    AnalyticsTrackerDirective,
    TourFeedbackDirective,
    CustomHeaderComponent,
    LegendsComponent,
    ExcelExportComponent,
    AttainmentWeightComponent,
    TourComponent,
    MetricGridComponent,
    MetricTileComponent,
    MetricCardComponent,
    ProductListComponent,
    MetricChartComponent,
    MonthViewComponent,
    MetricPcgBlockComponent,
    ToggleSwitchComponent,
    DetailDropdownComponent,
    // GlobalFilterDirective,
    // PayoutDetailsDirective,
    ProgressBarComponent,
    ContentLoaderSmallComponent,
    ContentLoaderMediumComponent,
    ContentLoaderLargeComponent,
    ContentLoaderSingleComponent,
    ContentLoaderCardChartComponent,
    ContentLoaderGridComponent,
    ContentLoaderIconTextComponent,
    ContentLoaderBarChartHorizontalComponent,
    ContentLoaderBarChartVerticalComponent,
    ContentLoaderDoubleBarChartSingleComponent,
    ContentLoaderDoubleBarChartMultipleComponent,
    ContentLoaderBarChartComponent,
    ContentLoaderBarChartSmallComponent,
    EmployeeInformationComponent,
    ApplicableForRBIDirective,
    EarningPotentialComponent,
    EarningPotentialGraphComponent,
    EarningPotentialGridComponent,
    PaylineAttributesComponent,
    EarningPotentialChartComponent,
    DeterminerDetailComponent,
    PaylineSelectorComponent,
    IncentiveSummaryTilesComponent,
    IncentiveSummaryTilesComponent_Old, // Should remove
    PayoutSummaryComponent_Old, // Should remove
    PayoutSummaryComponent,
    BonusPayoutSummaryComponent,
    DetailSummaryTilesComponent,
    ProductsSummaryComponent,
    TerritorySummaryComponent,
    AccountSummaryComponent,
    MetricMultibarChartComponent,
    DashboardGridAndChartViewComponent,
    PlanTerritoryDetailsComponent,
    TooltipComponent,
    ExportIconComponent,
    SplashComponent,
    ContextualVideoComponent,
    MultiSelectDropdownComponent,
    TerritoryDetailsComponent,
    PayoutUnavailableDirective,
    PpiTargetDetailComponent,
    HalfYearlyQuotaSectionComponent,
    OptoutNotificationComponent,
    AccordionComponent,
    RbiQuotaDetailsComponent,
    MetricStackChartComponent,
    MetricMultilineGraphComponent,
    AccordionComponent,
    MintCheckBoxComponent,
    MintMultiSelectCheckBoxComponent,
    UbiTargetDetailsComponent,
    MintTabsComponent,
    CommonIconComponent
  ],
  exports: [
    AnalyticsTrackerDirective,
    CustomHeaderComponent,
    LegendsComponent,
    MintCheckBoxComponent,
    MintMultiSelectCheckBoxComponent,
    ExcelExportComponent,
    AttainmentWeightComponent,
    TourComponent,
    MetricGridComponent,
    MetricTileComponent,
    MetricCardComponent,
    ProductListComponent,
    MetricChartComponent,
    MonthViewComponent,
    MetricPcgBlockComponent,
    ToggleSwitchComponent,
    DetailDropdownComponent,
    // GlobalFilterDirective,
    // PayoutDetailsDirective,
    ProgressBarComponent,
    ContentLoaderSmallComponent,
    ContentLoaderMediumComponent,
    ContentLoaderLargeComponent,
    ContentLoaderSingleComponent,
    ContentLoaderCardChartComponent,
    ContentLoaderBarChartSmallComponent,
    ContentLoaderGridComponent,
    ContentLoaderIconTextComponent,
    ContentLoaderBarChartHorizontalComponent,
    ContentLoaderBarChartVerticalComponent,
    ContentLoaderDoubleBarChartSingleComponent,
    ContentLoaderDoubleBarChartMultipleComponent,
    ContentLoaderBarChartComponent,
    EmployeeInformationComponent,
    ApplicableForRBIDirective,
    EarningPotentialComponent,
    DeterminerDetailComponent,
    IncentiveSummaryTilesComponent,
    IncentiveSummaryTilesComponent_Old, // Should remove
    PayoutSummaryComponent_Old, // Should remove
    PayoutSummaryComponent,
    DetailSummaryTilesComponent,
    ProductsSummaryComponent,
    AccountSummaryComponent,
    TerritorySummaryComponent,
    MetricMultibarChartComponent,
    EarningPotentialGraphComponent,
    EarningPotentialChartComponent,
    DashboardGridAndChartViewComponent,
    PlanTerritoryDetailsComponent,
    TooltipComponent,
    ExportIconComponent,
    TourFeedbackDirective,
    SplashComponent,
    ContextualVideoComponent,
    MultiSelectDropdownComponent,
    PayoutUnavailableDirective,
    PpiTargetDetailComponent,
    HalfYearlyQuotaSectionComponent,
    OptoutNotificationComponent,
    AccordionComponent,
    RbiQuotaDetailsComponent,
    MintCommonModule,
    MetricStackChartComponent,
    MetricMultilineGraphComponent,
    AccordionComponent,
    UbiTargetDetailsComponent,
    MintTabsComponent,
    CommonIconComponent
  ],
  providers: [SharedService, DeterminerDetailHelper, MetricGridDataHelperService, PlanTerritoryDetailsService, SplashService, ExportToExcelService, WidgetFactory, CommonFactory]
})
export class SharedModule {}
