import { Component, ElementRef, EventEmitter, OnChanges, Output } from '@angular/core';
import * as d3 from 'd3';
import { Input } from '@angular/core';
import * as _ from 'underscore';
import { Formatter } from '@mint-libs/common';
import { UserProfileService } from '../../core/context/user-profile.service';
import { PeriodStatus } from '../models/period-status.enum';
import { ChartType } from './chartType.enum';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'mint-metric-chart',
  templateUrl: './metric-chart.component.html',
  styleUrls: ['./metric-chart.component.scss']
})
export class MetricChartComponent implements OnChanges {
  @Input() dataset: any;
  @Input() chartType: ChartType;
  @Input() showYScale = true;
  @Input() yAxisMaxLimit: number = null;
  @Input() doNotShowToolTip: Boolean = false;
  @Input() chartHelpText: string;
  @Input() descriptionText: string;
  @Input() containerId: string;
  @Input() periodStatus = PeriodStatus.Active;
  @Input() detailsummaryreport: Boolean = false;
  @Input() pcgTooltipDimension = { width: 500, height: 290 };
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;

  a11yText: string;

  @Output() chartClick = new EventEmitter<any>();
  @Output() chartFocused = new EventEmitter<any>();

  constructor(private element: ElementRef, private formatter: Formatter, public userProfileService: UserProfileService, public liveAnnouncer: LiveAnnouncer) {}

  ngOnChanges() {
    // Purposeful delay for svg to get all attributes appropriately initialized
    setTimeout(() => {
      if (this.dataset && this.dataset.length > 0) {
        d3.select(this.element.nativeElement).html('');
        this.buildMetricChart();
      } else if (this.dataset && this.dataset.length === 0) {
        d3.select(this.element.nativeElement).html('No data available');
      }
    }, 200);
  }

  cleanUI() {
    let graphElements = this.element.nativeElement.querySelectorAll('div');
    graphElements.forEach(e => {
      if (e.id != 'accessibiityText') {
        e.remove();
      }
    });
  }

  buildMetricChart() {
    const target = d3
      .select(this.element.nativeElement)
      .node()
      .getBoundingClientRect();
    let dataSet: any;

    dataSet = this.dataset;
    if (!dataSet) {
      return;
    }

    const showYScale = this.showYScale;
    const _doNotShowToolTip = this.doNotShowToolTip;

    const barWidth = 40;
    let margin = { top: 25, right: 0, bottom: 58, left: 35 };
    if (!showYScale) {
      margin = { top: 25, right: 0, bottom: 58, left: 0 };
    }
    const barWidthBuffer = 90;
    const calcWidth = dataSet.length * barWidthBuffer > target.width - margin.left - margin.right ? dataSet.length * barWidthBuffer : target.width - margin.left - margin.right;
    const width = calcWidth;
    const height = 268 - margin.top - margin.bottom;
    const yAxisMaxLimit = this.yAxisMaxLimit;
    const checkIfValGreaterThanYmax = val => yAxisMaxLimit && val > yAxisMaxLimit;
    let graph: any;
    let x: any;
    let y: any;
    let yDomainMax: any;
    const yTicks = 8;
    const formatValue = d3.format('.3~s');
    const chartType = this.chartType;
    const barFillColor = this.userProfileService.isUBIPersona() && !this.detailsummaryreport ? 'gray' : '#00AE56';
    let descText = '';
    const chartToolTipHtml = function(d: any) {
      return d.toolTipHtml;
    };
    const pcgTooltipWidth = this.pcgTooltipDimension.width;
    const pcgTooltipHeight = this.pcgTooltipDimension.height;
    const pccTooltipWidth = 360;
    const pccTooltipHeight = 270;
    const revTooltipHeight = 250;
    const sideBarWidth = 50;
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    const scope = this;

    let mouseClick = false;
    // Tool Tip
    const div = d3
      .select(this.element.nativeElement)
      .append('div')
      .attr('class', 'bar__tooltip');

    // Tool Tip for on click
    const div2 = d3
      .select(this.element.nativeElement)
      .append('div')
      .attr('class', 'bar__tooltip bar__tooltip-click');

    // Tool Tip for on focus
    const div3 = d3
      .select(this.element.nativeElement)
      .append('div')
      .attr('class', 'bar__tooltip bar__tooltip-focus');

    const getPCCItems = function(pccs, context) {
      let html = '';
      _.each(
        pccs,
        function(pcc) {
          html += `
        <div class="data__source__item">
          <div class="data__source__header">${pcc.name}</div>
          <div class="perf__container">
            <div class="perf__data">
                <div class="perf__data__text">Source Revenue (A): </div>
                <div class="perf__data__val"> ${this.formatter.formatWithCurrencyAndRoundOff(pcc.revenue, pcc.metric)}
                </div>
            </div>
            <div class="perf__data">
                <div class="perf__data__text">Manual Adjustments (B): </div>
                <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(pcc.revenueAdjustment, pcc.metric)}
                </div>
            </div>
            <div class="perf__data">
                <div class="perf__data__text">Modifier (C): </div>
                <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(pcc.modifier, pcc.metric)}
                </div>
            </div>
            <div class="perf__data">
                <div class="perf__data__text">Total Revenue (A+B+C): </div>
                <div class="perf__data__val">${this.formatter.formatWithCurrencyAndRoundOff(pcc.totalRevenue, pcc.metric)}
                </div>
            </div>
          </div>
        </div>
        `;
        },
        context
      );
      return html;
    };

    const pccTipHtml = function(d: any, context) {
      return (
        '<div class="bar__tooltip__container bar__tooltip__container-pcc">' +
        '<div class="bar__tooltip__header">' +
        '<div class="bar__tooltip__header__txt">' +
        d.desc +
        '</div>' +
        '<div class="bar__tooltip__close"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        `<path d="M9.85242 0.851639L5.70944 5.00004L9.85242 9.14844L9.14844 9.85164L5.00043 5.70904L0.852417 9.85164L0.148438
        9.14844L4.29141 5.00004L0.148438 0.851639L0.852417 0.148438L5.00043 4.29104L9.14844 0.148438L9.85242 0.851639Z" fill="#333333" />` +
        'fill="#333333" />' +
        '</svg>' +
        '</div>' +
        '</div>' +
        '<div class="data__source__container">' +
        getPCCItems(d.pccs, context) +
        '</div>' +
        '</div>'
      );
    };

    const handleOnClick = function(d: any, context, idx: any) {
      scope.chartClick.emit(d);
      scope.element.nativeElement.querySelector('#' + scope.containerId + '_bar_placeholder_' + idx).focus();
      const diffWidth = vw - d3.event.pageX - sideBarWidth;
      mouseClick = true;
      div.style('display', 'none');
      div3.style('display', 'none');
      if (chartType === ChartType.Attainment) {
        if (_doNotShowToolTip === true) {
          return;
        }
        div2.html(chartToolTipHtml(d));
        div2.style('top', d3.event.pageY - pcgTooltipHeight + 'px');
        // To check if tip going out of the window
        if (pcgTooltipWidth / 2 > d3.event.pageX) {
          div2.style('left', '0px');
        } else if (diffWidth > pcgTooltipWidth / 2) {
          div2.style('left', d3.event.pageX - pcgTooltipWidth / 2 + 'px');
        } else {
          div2.style('left', d3.event.pageX - (pcgTooltipWidth - diffWidth) + 'px');
        }
      } else if (chartType === ChartType.RevenueProduct) {
        div2.html(pccTipHtml(d, context));
        div2.style('top', d3.event.pageY - revTooltipHeight + 'px');

        // To check if tip going out of the window
        if (diffWidth > pcgTooltipWidth / 2) {
          div2.style('left', d3.event.pageX - pccTooltipWidth / 2 + 'px');
        } else {
          div2.style('left', d3.event.pageX - (pccTooltipWidth - diffWidth) + 'px');
        }
      } else if (chartType === ChartType.PerfByPcc) {
        div2.html(d.perfByPccToolTipData);
        div2.style('top', d3.event.pageY - pccTooltipHeight + 'px');

        // To check if tip going out of the window
        if (diffWidth > pcgTooltipWidth / 2) {
          div2.style('left', d3.event.pageX - pccTooltipWidth / 2 + 'px');
        } else {
          div2.style('left', d3.event.pageX - (pccTooltipWidth - diffWidth) + 'px');
        }
      }

      div2.select('.bar__tooltip__close').on('click', function() {
        div2.style('display', 'none');
      });
      div2
        .transition()
        .duration(200)
        .style('display', 'block');
    };

    const handleFocus = function(d: any, context) {
      scope.chartFocused.emit(d);
      announceScreenReaderInfo(d);
      if (!mouseClick) {
        div.style('display', 'none');
        div2.style('display', 'none');
        if (chartType === ChartType.Attainment) {
          if (_doNotShowToolTip === true) {
            return;
          }
          div3.html(chartToolTipHtml(d));
        } else if (chartType === ChartType.RevenueProduct) {
          div3.html(pccTipHtml(d, context));
        } else if (chartType === ChartType.PerfByPcc) {
          div3.html(d.perfByPccToolTipData);
        }
        div3.style('top', '50px');
        div3.style('left', '50%');
        div3.style('transform', 'translateX(-50%)');
        div3.select('.bar__tooltip__close').on('click', function() {
          div3.style('display', 'none');
        });
        div3
          .transition()
          .duration(200)
          .style('display', 'block');
      }
      mouseClick = false;
    };

    const announceScreenReaderInfo = function(d: any) {
      const xAxisLabel = scope.xAxisLabel ? scope.xAxisLabel : ' x-axis ';
      const yAxisLabel = scope.yAxisLabel ? scope.yAxisLabel : ' y-axis ';
      const tooltipA11yText = d.tooltipA11yText ? ' with tool tip ' + d.tooltipA11yText : '';
      scope.a11yText = d.a11yText ? d.a11yText : xAxisLabel + d.desc + yAxisLabel + d.val + tooltipA11yText;

      scope.liveAnnouncer.clear();
      scope.liveAnnouncer.announce(scope.a11yText);
    };

    const handleBlur = function() {
      div3
        .transition()
        .duration(500)
        .style('display', 'none');
    };

    const handleMouseover = function(d: any, context) {
      const diffWidth = vw - d3.event.pageX - sideBarWidth;
      if (chartType === ChartType.Attainment) {
        if (_doNotShowToolTip === true) {
          return;
        }
        div.html(chartToolTipHtml(d));
        div.style('top', d3.event.pageY - pcgTooltipHeight + 'px');

        // To check if tip going out of the window
        if (pcgTooltipWidth / 2 > d3.event.pageX) {
          div.style('left', '0px');
        } else if (diffWidth > pcgTooltipWidth / 2) {
          div.style('left', d3.event.pageX - pcgTooltipWidth / 2 + 'px');
        } else {
          div.style('left', d3.event.pageX - (pcgTooltipWidth - diffWidth) + 'px');
        }
      } else if (chartType === ChartType.RevenueProduct) {
        div.html(pccTipHtml(d, context));
        div.style('top', d3.event.pageY - revTooltipHeight + 'px');

        // To check if tip going out of the window
        if (diffWidth > pcgTooltipWidth / 2) {
          div.style('left', d3.event.pageX - pccTooltipWidth / 2 + 'px');
        } else {
          div.style('left', d3.event.pageX - (pccTooltipWidth - diffWidth) + 'px');
        }
      } else if (chartType === ChartType.PerfByPcc) {
        div.html(d.perfByPccToolTipData);
        div.style('top', d3.event.pageY - pccTooltipHeight + 'px');

        // To check if tip going out of the window
        if (diffWidth > pcgTooltipWidth / 2) {
          div.style('left', d3.event.pageX - pccTooltipWidth / 2 + 'px');
        } else {
          div.style('left', d3.event.pageX - (pccTooltipWidth - diffWidth) + 'px');
        }
      }

      div
        .transition()
        .duration(200)
        .style('display', 'block');
    };

    const handleMouseout = function() {
      div
        .transition()
        .duration(500)
        .style('display', 'none');
    };

    const handleFocusOut = function() {
      scope.a11yText = '';
      scope.liveAnnouncer.clear();
    };

    const graphContainer = d3
      .select(this.element.nativeElement)
      .append('div')
      .attr('class', 'graph__container');

    const graphSvg = graphContainer
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom);

    graph = graphSvg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // Scales
    x = d3
      .scaleBand()
      .domain(
        dataSet.map(function(d: any) {
          return d.desc;
        })
      )
      .range([0, width]);
    yDomainMax = yAxisMaxLimit ?? d3.max(dataSet, d => d.val);

    y = d3
      .scaleLinear()
      .domain([0, yDomainMax])
      .range([height, 0]);

    // Axes
    const xAxis = d3.axisBottom(x);
    const xAxisG = graph
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);

    xAxisG.selectAll('.tick text').attr('style', 'display:none');
    xAxisG.selectAll('.tick line').attr('style', 'display:none');
    xAxisG.selectAll('path.domain').attr('style', 'display:none');

    // Formatting bar label
    xAxisG
      .selectAll('g')
      .append('svg:foreignObject')
      .attr('x', () => {
        return -x.bandwidth() / 2;
      })
      .attr('width', x.bandwidth())
      .attr('height', 24)
      .append('xhtml:div')
      .attr('title', (i: any) => i)
      .attr('class', 'wrap__text')
      .html(function(i: any) {
        return `<div>${i}</div>`;
      });

    const yAxis = d3
      .axisLeft(y)
      .ticks(yTicks)
      .tickSize(-width)
      .tickPadding(10)
      .tickSizeOuter(0)
      .tickFormat(function(d: any) {
        return formatValue(d).replace('G', 'B');
      });
    const yAxisG = graph
      .append('g')
      .attr('class', 'y-axis')
      .call(yAxis);

    yAxisG.select('path').attr('style', 'display:none');

    yAxisG.selectAll('.tick line').attr('style', 'stroke: #E0E0E0');

    // Bar graphs
    graph
      .append('g')
      .attr('class', 'graph-placeholder')
      .selectAll('rect')
      .data(dataSet)
      .enter()
      .append('rect')
      .attr('class', 'bar-placeholder')
      .attr('tabindex', '0')
      .attr('fill', '#F2F2F2')
      .attr('height', height)
      .attr('width', barWidth)
      .attr('id', function(d: any, idx: any) {
        return scope.containerId + '_bar_placeholder_' + idx;
      })
      .attr('x', function(d: any) {
        return x(d.desc) + (x.bandwidth() - barWidth) / 2;
      })
      .on('click', function(d: any, idx: any) {
        handleOnClick(d, scope, idx);
      })
      .on('mouseover', function(d: any) {
        handleMouseover(d, scope);
      })
      .on('mouseout', function() {
        handleMouseout();
      })
      .on('focus', (d: any) => {
        handleFocus(d, scope);
      })
      .on('blur', (d: any) => {
        handleBlur();
      })
      .on('keypress', function(e: any, idx: any) {
        if (d3.event.keyCode === 13) {
          handleOnClick(e, scope, idx);
        }
      })
      .on('focusout', (d: any) => {
        handleFocusOut();
      });

    graph
      .selectAll('bar-placeholder')
      .data(dataSet)
      .enter()
      .append('g')
      .attr('class', function(d: any) {
        return d.cssClass ? d.cssClass + ' graph-main' : 'graph-main';
      })
      .append('rect')
      .attr('class', 'bar-val')
      .attr('fill', function(d: any) {
        return d.color ? d.color : barFillColor;
      })
      .attr('x', function(d: any) {
        return x(d.desc) + (x.bandwidth() - barWidth) / 2;
      })
      .attr('y', function(d: any) {
        return checkIfValGreaterThanYmax(d.val) ? y(yAxisMaxLimit) : y(d.val);
      })
      .attr('height', function(d: any) {
        const val = checkIfValGreaterThanYmax(d.val) ? yAxisMaxLimit : Math.max(d.val, 0);
        return height - y(val);
      })
      .attr('width', barWidth)
      .on('click', function(d: any, idx: any) {
        handleOnClick(d, scope, idx);
      })
      .on('mouseover', function(d: any) {
        handleMouseover(d, scope);
      })
      .on('mouseout', function() {
        handleMouseout();
      });

    // Bar labels
    graph
      .append('g')
      .attr('transform', 'translate(' + barWidth / 2 + ',0)')
      .attr('class', 'bar__label')
      .selectAll('text')
      .data(dataSet)
      .enter()
      .append('text')
      .text(function(d: any) {
        if (chartType === ChartType.Attainment || chartType === ChartType.PerfByPcc) {
          return checkIfValGreaterThanYmax(d.val) ? d3.format('.2f')(yAxisMaxLimit) + '% +' : d3.format('.2f')(d.val) + '%';
        } else {
          if (d.val < 1 && d.val > -1) {
            return d3.format('.4~f')(d.val);
          }
          return d3
            .format(formatValue)(d.val)
            .replace('G', 'B');
        }
      })
      .attr('y', function(d: any) {
        const neVal = (d.val < 0) ? 0 : d.val;
        if (d.metric && d.metric === 'Percent') {
          return checkIfValGreaterThanYmax(neVal) ? y(yAxisMaxLimit) : y(neVal);
        } else {
          return (checkIfValGreaterThanYmax(neVal) ? y(yAxisMaxLimit) : y(neVal)) - 5;
        }
      })
      .attr('x', function(d: any) {
        return x(d.desc) + (x.bandwidth() - barWidth) / 2;
      });
  }
}
