import { Injectable, Inject } from '@angular/core';
import { ITooltipTextGrid } from './tooltip-text-grid';
import { UserProfileService } from 'src/app/core/context/user-profile.service';
import { TooltipTextGridRBI } from './tooltip-text-grid-rbi';
import { TooltipTextGridPPIBase } from './tooltip-text-grid-ppi-base';
import { RBIColumnHeaderMap } from './grid-column-header-map-rbi';
import { PPIColumnHeaderMap } from './grid-column-header-map-ppi';
import { IColumnHeaderMap } from './grid-column-header-map';
import { TooltipTextGridPPICustomerAdds } from './tooltip-text-grid-ppi-customer-adds';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { TooltipTextGridUBI } from './tooltip-text-grid-ubi';
import { FiltersService } from '../../filters/filters.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { Store } from '@ngrx/store';
@Injectable()
export class MetricGridViewHelperService {
  columnDefs: any;
  ubiColumnDefs: any;
  rubiColumnDefs: any;
  ppiColumnDefs: any;
  tooltipText: ITooltipTextGrid;
  gridColumnHeaderMap: IColumnHeaderMap;
  isDMYEligible = false;
  public sharedContext: SharedContext = new SharedContext();
  constructor(public userProfileService: UserProfileService, private filterService: FiltersService, public sharedContextState: Store<SharedContext>) {
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      this.gridColumnHeaderMap = new RBIColumnHeaderMap();
      if (userProfileService.isRBIPersona()) {
        this.tooltipText = new TooltipTextGridRBI(this.filterService.isSelectedFiscalYear2021());
        this.isDMYEligible = this.sharedContext.IsDMYEligible;
      } else if (userProfileService.isPPIPersona()) {
        this.tooltipText = new TooltipTextGridPPIBase();
      } else {
        this.tooltipText = new TooltipTextGridUBI();
      }
    });
  }

  getColumnDef(isYTD: boolean, getLegendClassRules, isUBI: boolean, isRUBI: boolean, isPPI: boolean, bucketType: string) {
    if (isRUBI && this.filterService.isSelectedFiscalYear2021()) {
      this.tooltipText = new TooltipTextGridRBI(this.filterService.isSelectedFiscalYear2021());
    }
    const earningsColDef = {
      field: 'MetriclevelYTDEarnings',
      lockPosition: true,
      headerName: 'Bucket level YTD Earnings',
      rowSpan: this.pcgRowspan,
      tooltipField: this.tooltipText.MetriclevelYTDEarnings,
      cellClassRules: {
        'cell-span': this.cellSpan
      },
      suppressNavigable: true,
      minWidth: 180
    };

    this.columnDefs = [
      {
        field: 'Metric',
        lockPosition: true,
        headerName: 'Bucket',
        cellRenderer: 'metricRenderer',
        rowSpan: this.pcgRowspan,
        // tooltipField: this.tooltipText.Metric,
        cellClassRules: getLegendClassRules('Metric'),
        suppressNavigable: true,
        minWidth: 150
      },
      {
        field: 'Metric.attainment',
        lockPosition: true,
        headerName: 'Attainment',
        tooltipField: this.tooltipText.Attainment,
        cellRenderer: 'attainmentRenderer',
        rowSpan: this.pcgRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 116
      },
      {
        field: 'ProductGroup',
        lockPosition: true,
        headerName: 'Metric',
        cellRenderer: 'pgRenderer',
        tooltipField: this.tooltipText.ProductGroup,
        rowSpan: this.pccRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 150
      },
      {
        field: 'YTDQuota',
        lockPosition: true,
        headerName: this.getHeaderName('YTDQuota'),
        rowSpan: this.pccRowspan,
        tooltipField: this.tooltipText.YTDQuota,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 85
      },
      {
        field: 'YTDActuals',
        lockPosition: true,
        headerName: this.getDMYHeader(),
        rowSpan: this.pccRowspan,
        tooltipField: this.getDMYTooltip(),
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 90
      },
      {
        field: 'QuotaToGo',
        lockPosition: true,
        headerName: this.getHeaderName('QuotaToGo'),
        rowSpan: this.pccRowspan,
        tooltipField: this.tooltipText.QuotaToGo,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        minWidth: 85,
        suppressNavigable: true
      },
      {
        field: 'DataSource',
        lockPosition: true,
        headerName: 'Data Source Information',
        minWidth: 225,
        // tooltipField: this.tooltipText.DataSource,
        cellClass: 'cell-wrap-text',
        cellRenderer: 'dataSourceRenderer',
        suppressNavigable: true
      }
    ];

    this.ubiColumnDefs = [
      {
        field: 'Metric',
        lockPosition: true,
        headerName: 'Bucket',
        tooltipField: this.tooltipText.Metric,
        cellRenderer: 'metricRenderer',
        rowSpan: this.pcgRowspan,
        cellClassRules: getLegendClassRules('Metric'),
        suppressNavigable: true,
        minWidth: 150,
        maxWidth: 200
      },
      {
        field: 'Metric.attainment',
        lockPosition: true,
        headerName: 'Utilization',
        tooltipField: this.tooltipText.Attainment,
        cellRenderer: 'attainmentRenderer',
        rowSpan: this.pcgRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 150
      },
      {
        field: 'MetriclevelYTDEarnings',
        lockPosition: true,
        headerName: 'Earnings',
        tooltipField: this.tooltipText.MetriclevelYTDEarnings,
        rowSpan: this.pcgRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 150
      },
      {
        field: 'YTDQuota',
        lockPosition: true,
        headerName: 'Targets',
        tooltipField: this.tooltipText.YTDQuota,
        rowSpan: this.pccRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 145
      },
      {
        field: 'YTDActuals',
        lockPosition: true,
        headerName: 'Actuals',
        tooltipField: this.tooltipText.YTDActuals,
        rowSpan: this.pccRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 145
      },
      {
        field: 'QuotaToGo',
        lockPosition: true,
        headerName: 'Target Remaining',
        tooltipField: this.tooltipText.QuotaToGo,
        rowSpan: this.pcgRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 150
      },
      {
        field: 'DataSource',
        lockPosition: true,
        headerName: 'Data Source Information',
        minWidth: 220,
        maxWidth: 220,
        cellClass: 'cell-wrap-text',
        cellRenderer: 'dataSourceRenderer',
        suppressNavigable: true
      }
    ];

    if (isPPI && bucketType === 'Payout Adjustment Bucket') {
      this.tooltipText = new TooltipTextGridPPICustomerAdds();
    }
    this.ppiColumnDefs = [
      {
        field: 'Metric',
        lockPosition: true,
        headerName: 'Bucket',
        cellRenderer: 'metricRenderer',
        rowSpan: this.pcgRowspan,
        cellClassRules: getLegendClassRules('Metric'),
        suppressNavigable: true,
        minWidth: 150
      },
      {
        field: 'Metric.attainment',
        lockPosition: true,
        headerName: 'Attainment',
        tooltipField: this.tooltipText.Attainment,
        cellRenderer: 'attainmentRenderer',
        rowSpan: this.pcgRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 116
      },
      {
        field: 'PayoutImpact',
        lockPosition: true,
        headerName: 'Impact on payout',
        rowSpan: this.pcgRowspan,
        tooltipField: this.tooltipText.PayoutImpact,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 90
      },
      {
        field: 'Threshold',
        lockPosition: true,
        headerName: 'Threshold',
        rowSpan: this.pcgRowspan,
        // tooltipField: this.tooltipText.Treshold,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 90
      },
      {
        field: 'TotalTarget',
        lockPosition: true,
        headerName: 'YTD Target',
        rowSpan: this.pcgRowspan,
        tooltipField: this.tooltipText.YTDQuota,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 90
      },
      {
        field: 'TotalActuals',
        lockPosition: true,
        headerName: 'YTD Actuals',
        rowSpan: this.pcgRowspan,
        tooltipField: this.tooltipText.YTDActuals,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 90
      },
      {
        field: 'QuotaToGo_Bucket',
        lockPosition: true,
        headerName: 'Target Remaining',
        rowSpan: this.pcgRowspan,
        tooltipField: this.tooltipText.QuotaToGo,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        minWidth: 90,
        suppressNavigable: true
      },
      {
        field: 'DataSource',
        lockPosition: true,
        headerName: 'Data Source Information',
        minWidth: 210,
        cellClass: 'cell-wrap-text',
        cellRenderer: 'dataSourceRenderer',
        suppressNavigable: true
      }
    ];

    this.rubiColumnDefs = [
      {
        field: 'Metric',
        lockPosition: true,
        headerName: 'Bucket',
        cellRenderer: 'metricRenderer',
        rowSpan: this.pcgRowspan,
        cellClassRules: getLegendClassRules('Metric'),
        suppressNavigable: true,
        minWidth: 200,
        maxWidth: 200
      },
      {
        field: 'Metric.attainment',
        lockPosition: true,
        headerName: 'Attainment',
        cellRenderer: 'attainmentRenderer',
        rowSpan: this.pcgRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 180
      },
      {
        lockPosition: true,
        rowSpan: this.pcgRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 180
      },
      {
        field: 'Acceleration',
        lockPosition: true,
        headerName: 'Accelerator',
        rowSpan: this.pcgRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 180
      },
      {
        lockPosition: true,
        rowSpan: this.pcgRowspan,
        cellClassRules: {
          'cell-span': this.cellSpan
        },
        suppressNavigable: true,
        minWidth: 180
      },
      {
        field: 'DataSource',
        lockPosition: true,
        headerName: 'Data Source Information',
        minWidth: 210,
        maxWidth: 210,
        cellClass: 'cell-wrap-text',
        cellRenderer: 'dataSourceRenderer',
        suppressNavigable: true
      }
    ];

    if (!isYTD && !isUBI && !isRUBI && !isPPI) {
      this.columnDefs.splice(2, 0, earningsColDef);
    }

    if (isUBI) {
      this.columnDefs = this.ubiColumnDefs;
    }

    if (isRUBI) {
      // FSA onwards, R part columns are same as RBI grid columns
      if (this.filterService.isFSA()) {
        this.columnDefs.splice(2, 0, earningsColDef);
      } else {
        this.columnDefs = this.rubiColumnDefs;
      }
    }

    if (isPPI && bucketType === 'Payout Adjustment Bucket') {
      // if (isPPI) {
      this.ppiColumnDefs.splice(3, 1);
      this.columnDefs = this.ppiColumnDefs;
    }

    if (isPPI && bucketType === 'Payout Gate Bucket') {
      this.ppiColumnDefs.splice(2, 1);
      this.columnDefs = this.ppiColumnDefs;
    }

    return this.columnDefs;
  }

  getHeaderName(header) {
    return this.gridColumnHeaderMap[header];
  }

  getDMYHeader() {
    if (this.isDMYEligible) {
      return ' YTD Actuals/Value of Commitments Landed';
    }
    return 'YTD Actual';
  }
  getDMYTooltip() {
    if (this.isDMYEligible) {
      return this.tooltipText.YTDActualsDMY;
    }
    return this.tooltipText.YTDActuals;
  }

  pcgRowspan(params) {
    if (params.data.PcgRowSpan !== -1) {
      return params.data.PcgRowSpan;
    }
    return 1;
  }
  pccRowspan(params) {
    if (params.data.PccRowSpan !== -1) {
      return params.data.PccRowSpan;
    }
    return 1;
  }
  cellSpan(params) {
    return params.data.PcgRowSpan !== -1 || params.data.PcgRowSpan !== 1;
  }
}
