import { AfterViewChecked, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CompositeFilterDescriptor, distinct, filterBy, isCompositeFilterDescriptor, orderBy, SortDescriptor, State } from '@progress/kendo-data-query';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ReportGrid } from '../models/report-grid.model';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';
import { UserProfileService } from '../../../core/context/user-profile.service';

@Component({
  selector: 'mint-report-grid',
  templateUrl: './report-grid.component.html',
  styleUrls: ['./report-grid.component.scss'],
  providers: [{ provide: POPUP_CONTAINER, useExisting: ElementRef }, PopupService]
})
export class ReportGridComponent implements AfterViewChecked, OnChanges {
  public view: GridDataResult;
  private gridData: any;
  public loading = true;
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public gridFilters: CompositeFilterDescriptor;
  public distinctColumnData = {} as any;
  @Input() data = [];
  @Input() columns: ReportGrid[] = [];
  @Input() gridTitle = '';
  @Input() exportToExcelAvailable = true;
  @Input() excelDownloadMessage = '';
  @Input() excelDownloadStatus = false;
  @Input() excelTitle = 'report';
  @Input() pageSize = 10;
  @Output() exportToExcel = new EventEmitter<any>();
  constructor(public element: ElementRef, public userProfileService: UserProfileService) {}

  ngAfterViewChecked() {
    const kPopup = this.element.nativeElement.querySelector('kendo-popup.k-animation-container.k-animation-container-shown');
    if (kPopup) {
      this.alignFilterMenu(kPopup);
      }

      const spans = this.element.nativeElement.querySelectorAll('span.k-link span')
      for (let i = 0; i < spans.length; i++) {
          spans[i].setAttribute('role', 'button');
      }

      const colGroups = this.element.nativeElement.querySelectorAll('table colgroup')
      for (let i = 0; i < colGroups.length; i++) {
          colGroups[i].removeAttribute('role');
      }

      const prevButtons = this.element.nativeElement.querySelectorAll('kendo-pager-prev-buttons a span')
      for (let i = 0; i < prevButtons.length; i++) {
          prevButtons[i].setAttribute('role', 'button');
      }

      const nextButtons = this.element.nativeElement.querySelectorAll('kendo-pager-next-buttons a span')
      for (let i = 0; i < nextButtons.length; i++) {
          nextButtons[i].setAttribute('role', 'button');
      }
      
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']?.previousValue != changes['data']?.currentValue) {
      this.gridInit(this.data);
    }
  }

  public gridInit(data: any) {
    this.gridData = data;
    this.view = { data: this.gridData, total: this.gridData.length };
    this.sort = [];
    this.gridFilters = null;
    this.refreshGrid();
    this.loading = false;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.refreshGrid();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.skip = 0;
    this.sort = sort;
    this.refreshGrid();
  }

  public filterChange(gridFilters: CompositeFilterDescriptor): void {
    this.gridFilters = gridFilters;
    this.skip = 0;
    this.refreshGrid();
  }

  public distinctPrimitive(fieldName: string): any {
    if (this.distinctColumnData[fieldName]) {
      return this.distinctColumnData[fieldName];
    }
    this.distinctColumnData[fieldName] = Array.from(new Set(this.gridData.map(item => this.deepValueAccess(item, fieldName))));
    return this.distinctColumnData[fieldName];
  }

  private refreshGrid(): void {
    const filteredData = filterBy(this.gridData, this.gridFilters);
    const orderedData = orderBy(filteredData, this.sort);
    this.view = {
      data: orderedData.slice(this.skip, this.skip + this.pageSize),
      total: orderedData.length
    };
    this.distinctColumnData = {} as any;
  }

  public getGridData() {
    const filteredData = filterBy(this.gridData, this.gridFilters);
    const orderedData = orderBy(filteredData, this.sort);
    return orderedData;
  }

  public getCoumnFilters(columnName) {
    let filterList = [];
    this.gridFilters?.filters?.forEach(columnFilters => {
      if (isCompositeFilterDescriptor(columnFilters)) {
        columnFilters.filters.forEach(f => {
          if (!isCompositeFilterDescriptor(f) && f.field === columnName) {
            filterList.push(f.value);
          }
        });
      }
    });
    return filterList;
  }

  public onExcelExport() {
    this.exportToExcel.emit({
      dimNameList: this.getCoumnFilters('name'),
      typeList: this.getCoumnFilters('type'),
      pccList: this.getCoumnFilters('pccName'),
      fiscalMonthList: this.getCoumnFilters('revenue.month'),
      unitList: this.getCoumnFilters('revenue.metric'),
      fiscalYearList: this.getCoumnFilters('revenue.fiscalYear'),
      IncentiveType: this.userProfileService.getIncentiveType(),
      IncludePivot: true
    });
  }

  public alignFilterMenu(filterMenuElement) {
    const filterMenuWidth = 280;
    const filterMenuHeight = 455;
    const rect = filterMenuElement.getBoundingClientRect();
    if (rect.left + filterMenuWidth >= window.innerWidth) {
      filterMenuElement.style.left = rect.left - filterMenuWidth + 'px';
    }
    if (rect.top + filterMenuHeight >= window.innerHeight) {
      filterMenuElement.style.top = window.innerHeight - filterMenuHeight + 'px';
    }
  }

  public deepValueAccess(dataItem: any, path: string) {
    return path.split('.').reduce((o, p) => o[p], dataItem);
  }
}
