import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { NotificationService } from '../notifications.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NotificationServiceFactory } from '../../core/context/notificationFactory.service';
import { from } from 'rxjs';
import { notificationSvgIcons } from '../../shared/notification.icon.svg';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ContextState } from '@mint-libs/context';
import { selectConfigurationState } from '@mint-libs/configuration';
import { Store } from '@ngrx/store';
import { FiltersService } from '../../filters/filters.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from '../../core/navigation/navigation.service';

@Component({
  selector: 'mint-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  NotificationConfig: any;
  NotificationTimer: any;
  notes = [];
  unreadCount = 0;
  showNotes = false;
  loading = false;
  currentDate?: Date = new Date();
  iconMapActive: Map<number, SafeHtml>;
  iconMapInActive: Map<number, SafeHtml>;
  notificationTypes: Map<number, string>;
  config: any;

  selectedValue: number;
  displayedColumns = ['NotificationType', 'NotificationText', 'NotificationStartDate', 'NotificationEndDate'];
  dataSource: MatTableDataSource<NotificationData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    public filterService: FiltersService,
    private navigationService: NavigationService,
    private notificationServiceFactory: NotificationServiceFactory,
    private notificationService: NotificationService,
    public sharedContextState: Store<SharedContext>,
    private rolefeatureService: RoleFeatureService,
    private sanitizer: DomSanitizer,
    private contextState: Store<ContextState>
  ) {
    this.getConfigDetails();
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
    });
    this.NotificationConfig = this.config.notificationConfig;
    if (!this.NotificationConfig) {
      // Incase the Notification Config is not Set
      this.NotificationConfig = {};
      this.NotificationConfig.NotificationTimer = 3000;
    }

    this.NotificationTimer = parseInt(this.NotificationConfig.NotificationTimer, 10);
    this.initIcons();
    this.initNotificationTypeOptions();
  }

  ngOnInit() {
    this.checkForNewNotification(false);
  }

  private getConfigDetails() {
    this.contextState.select(selectConfigurationState).subscribe(state => {
      if (!state) {
        return;
      }
      this.config = state.configs;
    });
  }

  initIcons() {
    // initializing all Active notification type with icon
    this.iconMapActive = new Map<number, SafeHtml>();
    this.iconMapActive.set(1, this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconWarning + notificationSvgIcons.iconColorGreen + notificationSvgIcons.iconWarningHeader));
    this.iconMapActive.set(2, this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconError + notificationSvgIcons.iconColorGreen + notificationSvgIcons.iconErrorHeader));
    this.iconMapActive.set(3, this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconAlert + notificationSvgIcons.iconColorGreen + notificationSvgIcons.iconAlertHeader));
    this.iconMapActive.set(4, this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconBanner + notificationSvgIcons.iconColorGreen + notificationSvgIcons.iconBannerHeader));
    this.iconMapActive.set(5, this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconInfo + notificationSvgIcons.iconColorGreen + notificationSvgIcons.iconInfoHeader));

    // initializing all InActive notification type with icon
    this.iconMapInActive = new Map<number, SafeHtml>();
    this.iconMapInActive.set(1, this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconWarning + notificationSvgIcons.iconColorGrey + notificationSvgIcons.iconWarningHeader));
    this.iconMapInActive.set(2, this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconError + notificationSvgIcons.iconColorGrey + notificationSvgIcons.iconErrorHeader));
    this.iconMapInActive.set(3, this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconAlert + notificationSvgIcons.iconColorGrey + notificationSvgIcons.iconAlertHeader));
    this.iconMapInActive.set(4, this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconBanner + notificationSvgIcons.iconColorGrey + notificationSvgIcons.iconBannerHeader));
    this.iconMapInActive.set(5, this.sanitizer.bypassSecurityTrustHtml(notificationSvgIcons.iconInfo + notificationSvgIcons.iconColorGrey + notificationSvgIcons.iconInfoHeader));
  }

  initNotificationTypeOptions() {
    // initializing all notification type with name
    this.notificationTypes = new Map<number, string>();
    this.notificationTypes.set(0, 'ALL');
    this.notificationTypes.set(1, 'Warning');
    this.notificationTypes.set(2, 'Error');
    this.notificationTypes.set(3, 'Alert');
    this.notificationTypes.set(4, 'Banner');
    this.notificationTypes.set(5, 'Information');
  }

  checkForNewNotification(markAsRead) {
    this.loading = true;
    // get Only Active notifications
    this.notificationService.GetNotifications(false).subscribe(
      response => {
        // get notification messages from service
        let userNotification = this.notificationServiceFactory.ReadLocalMessage(response, markAsRead);
        this.loading = false;
        this.notes = [];
        this.notes = userNotification.Notifications;
        this.fixNotificationType();
        this.setIconAndIsActive();
        this.createDataSource();
        const unreadNotes = this.notes.filter(note => note.isActive === true);
        this.unreadCount = unreadNotes.length;
        this.sharedContext.unreadCount = unreadNotes.length;

        if (markAsRead === true) {
          this.ResetCounter();
        }
        this.setPanelVisibility(unreadNotes);
      },
      error => {
        // handle error
        this.loading = false;
      }
    );
  }

  // this method resets the message count to 0 after opening the panel.
  ResetCounter() {
    setTimeout(() => {
      this.unreadCount = 0;
      this.sharedContext.unreadCount = 0;
    }, 6000);
  }

  setPanelVisibility(unreadNotes: any[]) {
    const unreadBannerNotes = unreadNotes.filter(note => note.NotificationType === 4);

    const isNotificationsIconVisible = this.rolefeatureService.isFeatureApplicable(this.rolefeatureService.pages.Dashboard, this.rolefeatureService.features.Notifications);

    if (isNotificationsIconVisible && unreadBannerNotes.length > 1) {
      this.showNotes = true;
    } else {
      this.showNotes = false;
    }
  }
  // set NotificationType 5 for information because 0 index is fixed for ALL type
  fixNotificationType() {
    this.notes.forEach(note => {
      if (note.NotificationType === 0) {
        note.NotificationType = 5;
      }
    });
  }

  setIconAndIsActive() {
    this.notes.forEach(note => {
      note.isActive = this.currentDate <= new Date(note.NotificationEndDate) && this.currentDate >= new Date(note.NotificationStartDate);

      if (note.isActive) {
        note.icon = this.iconMapActive.get(note.NotificationType);
      } else {
        note.icon = this.iconMapInActive.get(note.NotificationType);
      }
    });
  }

  createDataSource() {
    console.log('notes length:' + this.notes.length);
    // Assign the data to the data-source for mat-table rendering
    const notificationData: NotificationData[] = [];
    this.notes.forEach(note => {
      notificationData.push(createNotificationData(note.NotificationType, note.NotificationText, note.NotificationStartDate, note.NotificationEndDate, note.icon, note.isActive));
    });
    this.dataSource = new MatTableDataSource(notificationData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (data: NotificationData, filter: string) => {
      // tslint:disable-next-line: triple-equals
      return data.NotificationType.toString() == filter;
    };

    this.dataSource.sort = this.sort;
    this.sharedContext.totalCount = this.dataSource.data.length;
  }
  // mat functions
  filterNotifications(value: string) {
    this.dataSource.filter = value;
  }
}

function createNotificationData(NotificationType: number, NotificationText: string, NotificationStartDate: Date, NotificationEndDate: Date, Icon: SafeHtml, IsActive: boolean): NotificationData {
  const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', timezone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  return {
    NotificationType: NotificationType,
    NotificationText: NotificationText,
    NotificationStartDate: new Date(NotificationStartDate.toString() + 'Z').getTime(),
    NotificationEndDate: new Date(NotificationEndDate.toString() + 'Z').getTime(),
    Icon: Icon,
    IsActive: IsActive
  };
}

export interface NotificationData {
  NotificationType: number;
  NotificationText: string;
  NotificationStartDate: number;
  NotificationEndDate: number;
  Icon: SafeHtml;
  IsActive: Boolean;
}
